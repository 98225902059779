import { FC } from 'react';
import {
  Button,
  Form,
  OutlinedInput as Input,
  Item,
  LoaderIndicator,
} from 'tedooo-web-design-system/ui';

import './signInForm.scss';
import { translations } from 'translations/en';
import { ISignInForm, ServerStatus } from 'types';

const SignInForm: FC<ISignInForm> = ({
  onSubmit,
  isLoading,
  errorMessage,
  username,
  serverStatus,
}) => {
  const isError = serverStatus === ServerStatus.ERROR;

  const onFinish = ({ username: name, password }: any) => {
    onSubmit(name, password);
  };

  const validateStatus = isError ? 'error' : undefined;

  const buttonIcon = isLoading ? <LoaderIndicator color="white" size={14} /> : undefined;

  return (
    <Form onFinish={onFinish} className="sign-in-form">
      <Item
        validateStatus={validateStatus}
        initialValue={username || undefined}
        name="username"
        rules={[{ required: true }]}
      >
        <Input placeholder="Username" className="contact-input" id="username-input" />
      </Item>
      <Item
        help={errorMessage}
        validateStatus={validateStatus}
        name="password"
        rules={[{ required: true }]}
      >
        <Input
          type="password"
          placeholder="Password"
          className="contact-input"
          id="password-input"
        />
      </Item>
      <Item htmlFor="submit" className="button-item">
        <Button
          borderRadius="small"
          suffixIcon={buttonIcon}
          type="primary"
          size="large"
          text={translations.COMMON.LOGIN}
        />
      </Item>
    </Form>
  );
};

export default SignInForm;
