import './nonSignInMenu.scss';
import { TedoooLogo } from 'tedooo-web-design-system/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'tedooo-web-design-system/ui';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'constant';
import { translations } from 'translations/en';
import { useSaveLocation } from 'hooks/useSaveLocation';
import { UserSlicer } from 'store/user/UserActions';
import { NonSignInPopupSources, SignPageOptions } from 'pages/signPage/types';
import MenuButtons from '../menuButtons/MenuButtons';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { nonSignInUsersSupportedRoutes } from '../menuButtons/helper';
import { checkSmallScreen } from 'utils';
import MenuSearch from '../search/MenuSearch';

const isSmallScreen = checkSmallScreen();

const NonSignInMenu = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const onLogoClick = () => navigate(`/${ROUTES.HOME}`);

  const onSignUpClick = useSaveLocation(() => {
    dispatch(UserSlicer.actions.changeSignPhase(SignPageOptions.SignUp));
    navigate(`/${ROUTES.SIGN}`);
  });

  const onSignInClick = useSaveLocation(() => {
    navigate(`/${ROUTES.SIGN}`);
    AnalyticsEvents.onOpenSignupPopup(NonSignInPopupSources.LogIn);
  });

  const selectedRoute = location.pathname.split('/')[1];

  const onButtonClick = (route: string) => {
    if (route === ROUTES.DISCOVER) {
      AnalyticsEvents.onDiscoverSectionClick();
    }
    navigate(`/${route}`);
  };

  const options = nonSignInUsersSupportedRoutes;

  const buttonText = isSmallScreen
    ? translations.COMMON.LOGIN_OR_SIGNUP
    : translations.COMMON.LOGIN;

  return (
    <div className="logged-out-menu">
      <div className="inner-content">
        <TedoooLogo className="tedooo-logo" onClick={onLogoClick} />
        <MenuSearch />
        {!isSmallScreen && (
          <div className="logged-out-menu-buttons-container">
            <div className="logged-out-menu-buttons">
              <MenuButtons
                options={options}
                onClick={onButtonClick}
                selectedRoute={selectedRoute}
                totalUnreadMessages={0}
                totalOrders={0}
              />
            </div>
          </div>
        )}
        <div className="buttons">
          <Button
            className="join-now-button"
            borderRadius="large"
            type="primary"
            onClick={onSignUpClick}
            size="medium"
            text={translations.COMMON.JOIN_NOW}
          />
          <Button
            className="join-login-button"
            borderRadius="large"
            onClick={onSignInClick}
            type="secondary"
            size="medium"
            text={buttonText}
          />
        </div>
      </div>
    </div>
  );
};

export default NonSignInMenu;
