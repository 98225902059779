import { CurrencyCode, mapCurrencyToSymbol } from 'constant/currencies';
import { KEYBOARD_KEYS } from '../constant';

export const toCurrency = (
  value: number,
  currencyCode: CurrencyCode | string,
  minDigit: number = 0,
  currencySymbol?: string,
) => {
  try {
    const symbol = mapCurrencyToSymbol[currencyCode] || currencySymbol;

    const options = {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'code',
      maximumFractionDigits: minDigit,
    };

    const formattedValue = new Intl.NumberFormat('en-US', options).format(value);

    return formattedValue.replace(currencyCode, symbol).replace(/\s/g, '');
  } catch (e) {
    // In case of failure
    return `${toFormat(value)} ${currencyCode}`;
  }
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toFormat = (value: number, minDigit: number = 0) => {
  return Intl.NumberFormat('en-US').format(value);
};

/// converts to a short number format using k for 1000's and m's for millions
export const toShortFormat = (value: number, numDigits: number = 1) => {
  const options = {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: numDigits,
  };

  // @ts-ignore
  return Intl.NumberFormat('en-US', options).format(value);
};

export const calculatePercent = (
  fullPrice: number,
  discount: number,
  fixedDigits: number,
): number => {
  const percent = Number(discount) / 100;

  return Number((fullPrice * (1 - percent)).toFixed(fixedDigits));
};

export const toPrice = (lastKeyPressed: string, price: number) => {
  if (lastKeyPressed === KEYBOARD_KEYS.BACKSPACE) {
    return Math.floor(price / 10) / 100;
  }
  if (Number(lastKeyPressed) < 10 || Number(lastKeyPressed) <= 0) {
    return (price * 10 + Number(lastKeyPressed)) / 100;
  }

  return price / 100;
};

export const getPercentage = (value: number, total: number) => Math.floor((value / total) * 100);
