import { fetcher } from 'api';
import { Method } from 'types';
import { IMenuApi } from './types';

export class MenuReducer implements IMenuApi {
  onSearchChange(text: string): Promise<Array<string>> {
    const search = text.replace(/ /g, '%20');

    return fetcher(`autocomplete?text=${search}`, Method.GET);
  }
}
