import { Middleware } from 'redux';

import {
  facebookLogin,
  finishSignUp,
  googleLogin,
  login,
  refreshToken,
  signUp,
  submitVerifyCode,
  updateAvatar,
  UserSlicer,
} from './UserActions';
import { saveTokenInStorage } from 'api';
import { PopupSlicer } from '../popup/PopupActions';
import { IStore2, PremiumStatus } from 'types';
import { ProfileSlicer } from '../profile/ProfileSlicer';
import { StorageSrv } from 'services/StorageSrv';
import { updateUserDetails } from '../settings/SettingsActions';
import { FeedSlicer } from '../feed/FeedSlicer';
import { unlinkShippo } from '../shipping/ShippingActions';

export const userMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case login.fulfilled.type:
        const { rememberMe } = action.meta.arg;

        const { accessToken } = action.payload;
        saveTokenInStorage(accessToken, rememberMe);
        dispatch(FeedSlicer.actions.resetMainFeed());
        break;

      case facebookLogin.fulfilled.type:
        saveTokenInStorage(action.payload.accessToken, true);
        dispatch(FeedSlicer.actions.resetMainFeed());
        break;

      case unlinkShippo.fulfilled.type:
        dispatch(UserSlicer.actions.updateShippoConnection({ isShippoConnected: false }));
        break;
      case finishSignUp.fulfilled.type:
        dispatch(UserSlicer.actions.updateShouldShowOnboarding({ shouldShow: true }));
        break;

      case submitVerifyCode.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        saveTokenInStorage(action.payload.accessToken, true);
        dispatch(UserSlicer.actions.updateShouldShowOnboarding({ shouldShow: true }));
        StorageSrv.userId.remove();
        break;

      case googleLogin.fulfilled.type:
        saveTokenInStorage(action.payload.accessToken, true);
        dispatch(FeedSlicer.actions.resetMainFeed());
        break;

      case signUp.pending.type:
        dispatch(UserSlicer.actions.reset());
        break;

      case updateAvatar.fulfilled.type:
        const state: IStore2 = getState();

        const { id } = state.user;

        if (state.profile.userId === id) {
          const { avatar } = action.meta.arg;
          dispatch(ProfileSlicer.actions.updateAvatar({ avatar }));
        }
        break;

      case UserSlicer.actions.signOut.type:
        StorageSrv.token.remove();
        break;

      case signUp.fulfilled.type:
        dispatch(PopupSlicer.actions.hide);

        const { _id } = action.payload;
        StorageSrv.userId.set(_id);
        break;

      case updateUserDetails.fulfilled.type:
        const store: IStore2 = getState();

        const { fullName, username } = action.meta.arg;

        const { token } = store.user;
        dispatch(UserSlicer.actions.updateUserDetails({ fullName, username }));
        if (token) {
          dispatch(refreshToken());
        }
        break;

      case UserSlicer.actions.updateUserPremiumStatus.type:
        const { premiumStatus } = action.payload;

        if (premiumStatus === PremiumStatus.Active) {
          dispatch(refreshToken());
        }
        break;

      default:
        break;
    }

    return next(action);
  };
