import { FC } from 'react';

import { Image, Span } from 'components/helpers';
import './noResult.scss';
import EmptyState from 'photos/emptyState/searchEmptyState.png';
import { translations } from 'translations/en';

export interface INoResult {
  searchText: string;
}

export const NoResult: FC<INoResult> = ({ searchText }) => {
  return (
    <div className="no-result-container">
      <Image className="no-result-logo" src={EmptyState} />
      <div className="text-container">
        <Span className="no-result-text" text={`No results for "${searchText}"`} />
        <Span className="sub-text" text={translations.NO_RESULTS.TEXT} />
      </div>
    </div>
  );
};
