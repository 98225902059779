export enum Fonts {
  Heading1 = 'giant-heading',
  Heading2 = 'heading-2',
  Heading3 = 'heading-3',
  Heading4 = 'heading-4',
  Heading5 = 'heading-5',
  ButtonSmall = 'button-small',
  ButtonMedium = 'button-medium-large',
  BodySmall = 'body-small',
  BodyRegular = 'body-regular',
  SubHeading = 'sub-heading',
  SubHeadingLarge = 'sub-heading-large',
}
