import {
  KILO_BYTES,
  MESSAGE_GROUP_PREFIX,
  MESSAGE_POST_PREFIX,
  MESSAGE_SHOP_PREFIX,
} from 'store/chat/constants';
import { FACEBOOK_SHARE_LINK } from '../constant';

export const isEmpty = (str: string) => str.length === 0;

export const toUpperCase = (str: string) => str.toUpperCase();

export const toCapitalize = (str: string) => str[0].toUpperCase() + str.slice(1);

export const getTextWidth = (id: string) => {
  const element = document.getElementById(id);

  const width = element?.clientWidth;

  return width || 0;
};

export const firstNonAlphaNumericIndex = (str: string) => {
  let i = 0;
  while (i < str.length) {
    const char = str.charAt(i);

    if (
      (char >= 'a' && char <= 'z') ||
      (char >= 'A' && char <= 'Z') ||
      (char >= '0' && char <= '9') ||
      char === '_'
    ) {
      i++;
    } else {
      return i;
    }
  }

  return i;
};

export const toBytes = (str: string) => {
  const seperatedSize = str.split(' ');

  const size = Number(seperatedSize[0]);

  return size * KILO_BYTES;
};

export const isPostLink = (text: string): boolean => {
  return text.includes(MESSAGE_POST_PREFIX) && !text.includes(`${MESSAGE_POST_PREFIX} `);
};

export const isGroupLink = (text: string): boolean => {
  return text.includes(MESSAGE_GROUP_PREFIX) && !text.includes(`${MESSAGE_GROUP_PREFIX} `);
};

export const isShopLink = (text: string): boolean => {
  return text.includes(MESSAGE_SHOP_PREFIX) && !text.includes(`${MESSAGE_SHOP_PREFIX} `);
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getFacebookShareLink = (link: string, message: string): string => {
  return FACEBOOK_SHARE_LINK.replace('[link]', link).replaceAll('[message]', message);
};

export const isAllZero = (str: string): boolean => {
  return str.split('').every((char) => char === '0');
};
