import { createAsyncThunk } from '@reduxjs/toolkit';

import { IGetCheckoutDetailsFromTransaction, IStore2 } from 'types';
import { TransactionsApi } from './TransactionsApi';

const api = new TransactionsApi();

export const getCheckoutIdFromTransaction = createAsyncThunk(
  'async/getCheckoutIdFromTransaction',
  async (input: IGetCheckoutDetailsFromTransaction, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { price, receiverId, description, email } = input;

    return api.getCheckoutIdFromTransaction(
      Math.floor(price * 100),
      receiverId,
      description,
      email,
      token,
    );
  },
);

export const getOneTimePaymentInformationFromLink = createAsyncThunk(
  'async/getOneTimePaymentInformationFromLink',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token } = state.user;

    return api.getOneTimePaymentInformationFromLink(id, token);
  },
);
