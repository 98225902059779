import './completeShopBox.scss';
import { FC } from 'react';
import { Button, ProgressBar } from 'tedooo-web-design-system/ui';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Span } from '../helpers';
import { translations } from 'translations/en';
import { ROUTES } from 'constant';
import { AnalyticsEvents } from 'services/AnalyticsEvents';

interface ICompleteShopBox {
  step: number;
  shouldShowLater: boolean;
  size: 'small' | 'large';
  onLaterClick(): void;
}

const TOTAL_STEPS = 7;

const CompleteShopBox: FC<ICompleteShopBox> = ({ step, shouldShowLater, onLaterClick, size }) => {
  const navigate = useNavigate();

  const onContinueEditClick = () => {
    AnalyticsEvents.onDraftClick();
    navigate(`/${ROUTES.CREATE_SHOP}`);
  };

  const percentage = (step / TOTAL_STEPS) * 100;

  return (
    <div className={classNames('complete-shop-box-container', [size])}>
      <div className="progress-container">
        <ProgressBar
          percent={percentage}
          size="large"
          type="line"
          progressColor="linear-gradient(90deg, #60CFCC -44.27%, #8490FE 132.58%)"
          trailColor="white"
          className="progress-bar"
          strokeWidth={12}
        />
        <span>
          {step}/{TOTAL_STEPS}
        </span>
      </div>
      <Span text={translations.CREATE_SHOP.COMPLETE_DRAFT.TITLE} className="title" />
      <Span text={translations.CREATE_SHOP.COMPLETE_DRAFT.DESCRIPTION} className="description" />
      <Button
        type="primary"
        size="medium"
        borderRadius="large"
        text="Continue editing"
        isFullWidth
        onClick={onContinueEditClick}
      />
      {shouldShowLater && (
        <Button
          type="secondary"
          size="medium"
          borderRadius="large"
          text={translations.COMMON.LATER}
          onClick={onLaterClick}
          isFullWidth
          className="later-button"
        />
      )}
    </div>
  );
};

export default CompleteShopBox;
