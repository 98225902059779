import { FC, useEffect, useRef, useState } from 'react';
import './mojoVideoPlayer.scss';
import { PlayIcon } from 'tedooo-web-design-system/icons';

import { IMojoVideoPlayer } from 'types';
import { useSkipFirstEffect } from 'hooks/useSkipFirstEffect';
import MojoSkeleton from 'components/skeletons/mojoSkeleton/MojoSkeleton';
import { secondsToMilliseconds } from 'utils/UnitUtils';
import { getPercentage } from 'utils';

const MojoVideoPlayer: FC<IMojoVideoPlayer> = ({
  video,
  thumbnail,
  isMuted,
  canPause,
  isDisplayed,
  onVideoEnd,
}) => {
  const ref = useRef<HTMLVideoElement | null>(null);

  const isFirstLoop = useRef<boolean>(true);

  const [isLoading, setIsLoading] = useState(true);

  const [isPlaying, setIsPlaying] = useState(isDisplayed);

  const onVideoFinish = () => {
    ref.current?.play().then();
    if (isFirstLoop.current) {
      const videoDuration = secondsToMilliseconds(ref.current?.duration || 0);
      onVideoEnd(videoDuration, 100);

      isFirstLoop.current = false;
    }
  };

  useEffect(() => {
    if (isDisplayed) {
      ref.current?.addEventListener('ended', onVideoFinish);
    }

    return () => {
      ref.current?.removeEventListener('ended', onVideoFinish);
      if (isDisplayed) {
        if (isFirstLoop.current) {
          const videoDuration = secondsToMilliseconds(ref.current?.duration || 0);

          const videoCurrentTime = secondsToMilliseconds(ref.current?.currentTime || 0);

          const percentageCompleted = getPercentage(videoCurrentTime, videoDuration);
          onVideoEnd(videoDuration, percentageCompleted);
        }
      }
    };
  }, [isDisplayed]);

  useEffect(() => {
    if (isDisplayed) {
      isFirstLoop.current = true;
    }
  }, [isDisplayed]);

  const onLoad = () => {
    setIsLoading(false);
  };

  const onClick = () => {
    if (canPause) {
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    setIsPlaying(isDisplayed);
  }, [isDisplayed, isLoading]);

  useSkipFirstEffect(() => {
    if (isPlaying) {
      ref.current?.play().then();
    } else {
      ref.current?.pause();
    }
  }, [isPlaying]);

  return (
    <div
      className="video-container"
      onClick={onClick}
      style={{ backgroundImage: `url(${thumbnail})` }}
    >
      {isLoading && <MojoSkeleton />}
      <video
        playsInline
        poster={thumbnail}
        ref={ref}
        preload="metadata"
        muted={isMuted}
        autoPlay={isDisplayed}
        onClick={onClick}
        onCanPlay={onLoad}
      >
        <source src={video} type="video/mp4" />
      </video>
      {!isPlaying && isDisplayed && (
        <div className="overlay" onClick={onClick}>
          <PlayIcon />
        </div>
      )}
    </div>
  );
};

export default MojoVideoPlayer;
