import { Notification } from 'tedooo-web-design-system/ui';
import { SuccessMessageIcon } from 'tedooo-web-design-system/icons';

import { translations } from 'translations/en';

const RemoveFromBasketMessage = () => {
  return (
    <Notification
      type="primary-success"
      text={translations.BASKET.REMOVE_FROM_BASKET_MESSAGE}
      placement="top"
      icon={<SuccessMessageIcon />}
    />
  );
};

export default RemoveFromBasketMessage;
