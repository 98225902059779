import './footerMenu.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { FC, useMemo } from 'react';
import classNames from 'classnames';

import MenuButtons from '../menuButtons/MenuButtons';
import { IStore2 } from 'types';
import { ChatSelector } from 'store/chat/ChatSelector';
import { UserSelector } from 'store/user/UserSelector';
import { ROUTES } from 'constant';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import {
  menuOptionsOrder,
  mobileMenuOptionsOrder,
  nonSignInUsersSupportedRoutes,
} from '../menuButtons/helper';
import { checkSmallScreen } from 'utils';
import { useTedoooSelector } from 'store/Store';

interface IFooterMenu {
  totalUnreadMessages: number;
  totalOrders: number;
}

const isSmallScreen = checkSmallScreen();

const FooterMenu: FC<IFooterMenu> = ({ totalUnreadMessages, totalOrders }) => {
  const { token } = useTedoooSelector((state) => state.user);

  const location = useLocation();

  const navigate = useNavigate();

  const selectedRoute = location.pathname.slice(1);

  const isMojosPage = selectedRoute === ROUTES.MOJOS;

  const onNavigate = (route: string) => {
    if (route === ROUTES.DISCOVER) {
      AnalyticsEvents.onDiscoverSectionClick();
    }
    navigate(`/${route}`);
  };

  const options = useMemo(() => {
    if (token) {
      return isSmallScreen ? mobileMenuOptionsOrder : menuOptionsOrder;
    }

    return nonSignInUsersSupportedRoutes;
  }, [token]);

  return (
    <div
      className={classNames('footer-menu', {
        'dark-theme': isMojosPage,
        'is-non-sign-in-user': !token,
      })}
    >
      <MenuButtons
        options={options}
        selectedRoute={selectedRoute}
        totalUnreadMessages={totalUnreadMessages}
        totalOrders={totalOrders}
        onClick={onNavigate}
      />
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const totalUnreadMessages = ChatSelector.totalUnreadMessagesSelector(state);

  const totalOrders = UserSelector.totalOrdersSelector(state);

  return { totalUnreadMessages, totalOrders };
};

export default connect(mapStateToProps)(FooterMenu);
