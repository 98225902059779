import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  getCommentItem,
  getCommentsItems,
  getFeedItems,
  getLikesItems,
  getSubPostsItems,
  parseProducts,
  parsePostToRepostsList,
} from '../apiParser';
import {
  addComment,
  addReply,
  createPost,
  deleteComment,
  getComments,
  getPostLikes,
  getPostChildren,
  getReplies,
  onLikeComment,
  onLikePostToggle,
  onPostDelete,
  onPostHide,
  onPostReport,
  getCommentLikes,
  toggleRepost,
  getReposts,
  onHidePostFromActivity,
} from './PostActions';
import {
  addCommentToPost,
  addReplyToComment,
  deleteCommentFromPost,
  getCommentById,
  getFeedShopProfile,
  getFeedUserProfile,
  getMainPostById,
  getPost,
  getSelectedFeed,
  getSelectedPost,
  getSubPostFromPost,
  getUserShopsItems,
  isMainPost,
  onPostLikeToggle,
  onRepostToggle,
  toggleAllRepostsLikes,
  toggleAllReposts,
  addCommentToAllReposts,
  deleteCommentFromAllReposts,
} from '../post/helper';
import { FeedStaticItemsTypes, FeedTypes, IFeedState, IFeedStore } from 'types';
import {
  getMainFeed,
  getUserFeed,
  getShopProfile,
  getUserProfile,
  getUserShops,
  getShopFeed,
} from './FeedActions';
import { getCatalog } from 'store/shop/ShopActions';
import { isEmptyArray } from 'utils';
import { onFollowUserToggle } from 'store/profile/ProfileActions';
import {
  addPostToFeed,
  editPostInFeed,
  parseDiscoverResponse,
  resetFeedProps,
  toCommentItem,
} from './helper';
import { getSearchResultsPosts } from '../searchResult/SearchResultActions';
import { getChatFeed } from '../chat/ChatActions';
import {
  onMojoComment,
  onMojoDelete,
  onMojoDeleteComment,
  onMojoFollowUserToggle,
  onMojoLikeCommentToggle,
  onMojoLikeToggle,
} from '../mojos/mojo/MojoActions';

const INITIAL_FEED: IFeedStore = {
  list: null,
  hasMore: true,
  paginationToken: null,
  isLoading: true,
  selectedFeedBubble: null,
};

const initialState: IFeedState = {
  mainFeed: INITIAL_FEED,
  chatFeed: INITIAL_FEED,
  searchFeed: INITIAL_FEED,
  userFeed: INITIAL_FEED,
  shopFeed: INITIAL_FEED,
  feedBubbles: null,
  repostsFeed: { list: null, hasMore: true },
  impressedPosts: {},
  selectedFeedType: null,
  mapPostIdToSubPosts: {},
  selectedCommentId: null,
  selectedReplyId: null,
  selectedSubPostId: null,
  profile: null,
  createPostDetails: {
    shops: [],
    isLoading: false,
    isCatalogLoading: false,
  },
  selectedPostId: null,
  isLoading: false,
  stories: null,
};

export const FeedSlicer = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    onPostImpression: (state, action) => {
      const { id } = action.payload;
      state.impressedPosts[id] = true;
    },
    updateSelectedFeedType: (state, action) => {
      const { type } = action.payload;
      state.selectedFeedType = type;
    },
    onLikesClose: (state, action) => {
      const { commentId, replyId } = action.payload;

      const post = getSelectedPost(state) || null;
      let selectedComment;
      if (commentId) {
        selectedComment = getCommentById(post, commentId, replyId);
      }

      if (selectedComment) {
        selectedComment.likes = { list: null, hasMore: true };
      }
      if (post) {
        post.likes = { list: null, hasMore: true };
      }
    },
    onChildSet: (state) => {
      const { selectedPostId } = state;

      const feed = getSelectedFeed(state);

      const post = getMainPostById(feed.list, selectedPostId);

      if (post) {
        const subPost = getSubPostFromPost(post);
        state.mapPostIdToSubPosts[post.id] = [subPost];
      }
    },
    resetUserFeed: (state) => {
      resetFeedProps(state);
      if (state.userFeed.list !== null) {
        state.userFeed = INITIAL_FEED;
      }
    },
    resetSearchFeed: (state) => {
      resetFeedProps(state);
      if (state.searchFeed.list !== null) {
        state.searchFeed = INITIAL_FEED;
      }
    },
    resetShopFeed: (state) => {
      resetFeedProps(state);
      if (state.shopFeed.list !== null) {
        state.shopFeed = INITIAL_FEED;
      }
    },
    resetMainFeed: (state) => {
      resetFeedProps(state);
      if (state.mainFeed.list !== null) {
        state.mainFeed = INITIAL_FEED;
      }
    },
    toggleCommentReplies: (state, action) => {
      const { commentId, postId } = action.payload;

      const post = getPost(state, postId) || null;

      const comment = getCommentById(post, commentId);

      if (comment) {
        comment.isRepliesExpanded = !comment.isRepliesExpanded;
      }
    },
    onPostUnselect: (state) => {
      state.selectedSubPostId = null;
      state.selectedCommentId = null;
      state.selectedPostId = null;
      state.selectedReplyId = null;
    },
    onPostSelect: (state, action) => {
      const { subPostId, postId } = action.payload;
      state.selectedSubPostId = subPostId;
      state.selectedPostId = postId;
    },
    onSubPostChange: (state, action) => {
      const { postId } = action.payload;
      state.selectedSubPostId = postId;
    },
    onFeedBubbleSelected: (state, action) => {
      const { id } = action.payload;

      if (state.feedBubbles) {
        state.mainFeed.selectedFeedBubble = id;
      }
    },
    onSelectedCommentChange: (state, action) => {
      const { commentId, replyId, postId } = action.payload;
      state.selectedCommentId = commentId;
      state.selectedReplyId = replyId;
      if (!state.selectedPostId) {
        state.selectedPostId = postId;
      }
    },
    reset: (state) => {
      resetFeedProps(state);

      const selectedFeed = getSelectedFeed(state);

      selectedFeed.list = null;
      selectedFeed.hasMore = true;
    },
    resetReposts: (state) => {
      state.repostsFeed.list = null;
      state.repostsFeed.hasMore = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMainFeed.fulfilled, (state, action) => {
      const { paginationToken } = action.payload;

      const { feedItems, staticItems } = parseDiscoverResponse(
        action.payload,
        action.meta.arg.token,
      );

      if (!state.mainFeed.selectedFeedBubble) {
        const feedFiltersStaticItem = staticItems.find(
          (item) => item.key === FeedStaticItemsTypes.FeedFilters,
        );

        if (feedFiltersStaticItem) {
          const { extras: parsedFeedBubbles } = feedFiltersStaticItem;

          state.feedBubbles = parsedFeedBubbles;
        }
      }

      const feed = state.mainFeed;

      const prevList = feed.list || [];

      feed.paginationToken = paginationToken || '';
      feed.isLoading = false;
      feed.hasMore = !!paginationToken;
      feed.list = [...prevList, ...feedItems].sort((a, b) => a.order - b.order);
    });
    builder.addCase(getUserFeed.fulfilled, (state, action) => {
      const { paginationToken } = action.payload;

      const { feedItems } = parseDiscoverResponse(action.payload, action.meta.arg.token);

      state.userFeed.paginationToken = paginationToken || '';
      state.userFeed.isLoading = false;
      state.userFeed.hasMore = !!paginationToken;
      state.userFeed.list = [...(state.userFeed.list || []), ...feedItems].sort(
        (a, b) => a.order - b.order,
      );
    });
    builder.addCase(getShopFeed.fulfilled, (state, action) => {
      const { paginationToken } = action.payload;

      const { feedItems } = parseDiscoverResponse(action.payload, action.meta.arg.token);

      const feed = state.shopFeed;
      feed.paginationToken = paginationToken || '';
      feed.isLoading = false;
      feed.hasMore = !!paginationToken;
      feed.list = [...(feed.list || []), ...feedItems].sort((a, b) => a.order - b.order);
    });
    builder.addCase(getChatFeed.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const { token } = action.meta.arg;

      const feed = state.chatFeed;

      const prevList = feed.list || [];

      const result = getFeedItems(data, token);

      feed.hasMore = hasMore;

      feed.isLoading = false;

      feed.list = [...prevList, ...result];
    });
    builder.addCase(getSearchResultsPosts.fulfilled, (state, action) => {
      const { items } = action.payload;

      const parsedData = getFeedItems(items, '');

      const currentPosts = state.searchFeed.list || [];
      state.searchFeed.list = [...currentPosts, ...parsedData];
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.profile = getFeedUserProfile(action.payload);
    });
    builder.addCase(getShopProfile.fulfilled, (state, action) => {
      state.profile = getFeedShopProfile(action.payload);
    });
    builder.addCase(getUserShops.fulfilled, (state, action) => {
      state.createPostDetails.shops = getUserShopsItems(action.payload);
    });

    builder.addCase(getComments.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const { postId } = action.meta.arg;

      const post = getPost(state, postId);

      if (post) {
        const prevCommentsList = post.comments.list || [];

        const comments = getCommentsItems(data);
        post.comments.hasMore = hasMore;
        post.isCommentsExpanded = true;
        post.comments.list = [...prevCommentsList, ...comments];
      }
    });

    builder.addCase(getPostChildren.fulfilled, (state, action) => {
      const { selectedPostId } = state;

      const feed = getSelectedFeed(state);

      const data = action.payload;

      const post = getMainPostById(feed.list, selectedPostId);

      if (post && selectedPostId) {
        state.mapPostIdToSubPosts[selectedPostId] = getSubPostsItems(data);
      }
    });

    builder.addCase(getPostLikes.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const likes = getLikesItems(data);

      const { postId } = action.meta.arg;

      const post = getPost(state, postId);

      if (post) {
        const prevLikesList = post.likes.list || [];

        post.likes.list = [...prevLikesList, ...likes];
        post.likes.hasMore = hasMore;
      }
    });

    builder.addCase(getCommentLikes.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      const { commentId, postId, replyId } = action.meta.arg;

      const post = getPost(state, postId) || null;

      const comment = getCommentById(post, commentId, replyId);

      if (comment) {
        const prevLikesList = comment.likes.list || [];

        const likes = getLikesItems(data);

        comment.likes.list = [...prevLikesList, ...likes];
        comment.likes.hasMore = hasMore;
      }
    });

    builder.addCase(getReplies.fulfilled, (state, action) => {
      const data = action.payload;

      const { commentId, actionId } = action.meta.arg;

      const post = getPost(state, actionId) || null;

      const comment = getCommentById(post, commentId);

      if (comment) {
        comment.replies = getCommentsItems(data);
        comment.isRepliesExpanded = true;
      }
    });

    builder.addCase(addComment.fulfilled, (state, action) => {
      const data = action.payload;

      const { selectedSubPostId, selectedPostId } = state;

      const feed = getSelectedFeed(state);

      const { postId } = action.meta.arg;

      const post = getPost(state, postId);

      const comment = getCommentItem(data);

      if (post) {
        if (isMainPost(post)) {
          addCommentToAllReposts(feed.list, post.actionId, comment);
        } else {
          addCommentToPost(post, comment);
        }
      }

      // Add to main post if needed
      if (selectedSubPostId === selectedPostId) {
        const mainPost = getMainPostById(feed.list, selectedPostId);

        if (mainPost) {
          addCommentToAllReposts(feed.list, mainPost.actionId, comment);
        }
      }
    });

    builder.addCase(addReply.fulfilled, (state, action) => {
      const feed = getSelectedFeed(state);

      if (feed.list) {
        const data = action.payload;

        const { commentId, postId } = action.meta.arg;

        const post = getPost(state, postId);

        if (post) {
          const reply = getCommentItem(data);
          addReplyToComment(post, reply, commentId);
        }
      }
    });
    builder.addCase(onMojoComment.fulfilled, (state, action) => {
      const feed = getSelectedFeed(state);

      if (feed.list) {
        const { id } = action.payload;

        const { postId, text, taggedUsers, image, token, parentCommentId } = action.meta.arg;

        const post = getPost(state, postId);

        const comment = toCommentItem(id, text, taggedUsers, image, token || '');

        if (post) {
          if (parentCommentId) {
            addReplyToComment(post, comment, parentCommentId);
          } else {
            addCommentToPost(post, comment);
          }
        }
      }
    });

    builder.addCase(deleteComment.fulfilled, (state) => {
      const { selectedCommentId, selectedReplyId, selectedPostId, selectedSubPostId } = state;

      const feed = getSelectedFeed(state);

      const postId = selectedSubPostId || selectedPostId;

      // delete from selected post
      const post = getPost(state, postId) || null;

      if (selectedCommentId) {
        const comment = getCommentById(post, selectedCommentId) || null;

        if (post && isMainPost(post)) {
          deleteCommentFromAllReposts(
            feed.list,
            post.actionId,
            comment,
            selectedReplyId,
            selectedCommentId,
          );
        } else {
          deleteCommentFromPost(post, comment, selectedReplyId, selectedCommentId);
        }

        // delete comment from main post if exists
        if (selectedSubPostId === selectedPostId) {
          const mainPost = getMainPostById(feed.list, selectedPostId);

          if (mainPost) {
            deleteCommentFromAllReposts(
              feed.list,
              mainPost.actionId,
              comment,
              selectedReplyId,
              selectedCommentId,
            );
          }
        }
      }
    });
    builder.addCase(onMojoDeleteComment.fulfilled, (state) => {
      const { selectedCommentId, selectedReplyId, selectedPostId } = state;

      // delete from selected post
      const post = getPost(state, selectedPostId) || null;

      if (selectedCommentId) {
        const comment = getCommentById(post, selectedCommentId) || null;

        deleteCommentFromPost(post, comment, selectedReplyId, selectedCommentId);
      }
    });
    builder.addCase(getCatalog.pending, (state) => {
      state.createPostDetails.isCatalogLoading = true;
    });
    builder.addCase(getCatalog.fulfilled, (state, action) => {
      const { createPostDetails } = state;

      if (!isEmptyArray(createPostDetails.shops)) {
        const { shopId } = action.meta.arg;

        const selectedShopIndex = createPostDetails.shops.findIndex((shop) => shopId === shop.id);

        if (selectedShopIndex !== -1) {
          createPostDetails.shops[selectedShopIndex].catalog = parseProducts(action.payload);
        }
        createPostDetails.isCatalogLoading = false;
      }
    });

    builder.addCase(getReposts.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getReposts.fulfilled, (state, action) => {
      const { data, hasMore } = action.payload;

      let { selectedPost } = action.meta.arg;

      state.repostsFeed.hasMore = hasMore;

      state.isLoading = false;

      if (selectedPost) {
        if (selectedPost.repostDetails) {
          selectedPost = selectedPost.repostDetails;
        }

        const newPosts = parsePostToRepostsList(data, selectedPost);
        state.repostsFeed.list = [...(state.repostsFeed.list || []), ...newPosts];
      }
    });

    builder.addCase(getMainFeed.pending, (state) => {
      state.mainFeed.isLoading = true;
      state.selectedFeedType = FeedTypes.Feed;
    });
    builder.addCase(getUserFeed.pending, (state) => {
      state.userFeed.hasMore = false;
      state.selectedFeedType = FeedTypes.User;
      state.userFeed.isLoading = true;
    });
    builder.addCase(getShopFeed.pending, (state) => {
      state.shopFeed.hasMore = false;
      state.selectedFeedType = FeedTypes.Shop;
      state.shopFeed.isLoading = true;
    });
    builder.addCase(getChatFeed.pending, (state) => {
      state.chatFeed.isLoading = true;
    });
    builder.addCase(createPost.pending, (state) => {
      state.createPostDetails.isLoading = true;
    });

    builder.addCase(createPost.fulfilled, (state, action) => {
      const { id } = action.payload;

      if (id) {
        addPostToFeed(state, action.meta.arg, id);
      } else {
        editPostInFeed(state, action.meta.arg);
      }
    });
    builder.addCase(getSearchResultsPosts.pending, (state) => {
      state.selectedFeedType = FeedTypes.Search;
    });
    builder.addMatcher(isAnyOf(createPost.fulfilled, createPost.rejected), (state) => {
      state.createPostDetails.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(
        onPostHide.pending,
        onPostReport.pending,
        onHidePostFromActivity.pending,
        onMojoDelete.pending,
        onPostDelete.pending,
      ),
      (state, action) => {
        const { id } = action.meta.arg;

        const feed = getSelectedFeed(state);
        state.selectedSubPostId = null;
        state.selectedPostId = null;
        if (feed.list) {
          feed.list = feed.list.filter((post) => post.actionId !== id);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        onFollowUserToggle.pending,
        onFollowUserToggle.rejected,
        onMojoFollowUserToggle.pending,
        onMojoFollowUserToggle.rejected,
      ),
      (state, action) => {
        const { isFollowing, userId } = action.meta.arg;

        const feed = getSelectedFeed(state);

        const user = feed.list?.find((post) => post.userDetails.userId === userId);

        if (user) {
          user.isFollowing = !isFollowing;
        }
      },
    );

    builder.addMatcher(isAnyOf(toggleRepost.pending, toggleRepost.rejected), (state, action) => {
      const { postId, isReposted, loggedInUsername } = action.meta.arg;

      const feed = getSelectedFeed(state);

      const list = feed.list || [];

      const post = getMainPostById(list, postId);

      if (post) {
        toggleAllReposts(feed, post);
      }

      const { repostsFeed } = state;

      if (repostsFeed.list) {
        repostsFeed.list.forEach((repost) => onRepostToggle(repost));
        if (!isReposted) {
          repostsFeed.list = repostsFeed.list.filter(
            (repost) => repost.action?.username !== loggedInUsername,
          );
        }
      }
    });

    builder.addMatcher(
      isAnyOf(onLikePostToggle.pending, onLikePostToggle.rejected),
      (state, action) => {
        const { postId, actionId } = action.meta.arg;

        const { selectedSubPostId, selectedPostId } = state;

        const feed = getSelectedFeed(state);

        const post = getPost(state, postId);

        if (post) {
          if (isMainPost(post)) {
            toggleAllRepostsLikes(feed.list, post.actionId);
          } else {
            onPostLikeToggle(post);
          }
        }

        if (state.repostsFeed.list) {
          state.repostsFeed.list.forEach((repost) => {
            if (repost.actionId === actionId) {
              onPostLikeToggle(repost);
            }
          });
        }

        if (selectedSubPostId && selectedSubPostId === selectedPostId) {
          const mainPost = getMainPostById(feed.list, postId);
          onPostLikeToggle(mainPost);
          toggleAllRepostsLikes(feed.list, actionId);
        }
      },
    );
    builder.addMatcher(
      isAnyOf(
        onLikeComment.pending,
        onLikeComment.rejected,
        onMojoLikeCommentToggle.pending,
        onMojoLikeCommentToggle.rejected,
      ),
      (state, action) => {
        const { postId, commentId, isLiked, replyId } = action.meta.arg;

        const post = getPost(state, postId) || null;

        const selectedComment = getCommentById(post, commentId, replyId);

        if (selectedComment) {
          selectedComment.isLiked = !selectedComment.isLiked;
          if (isLiked) {
            selectedComment.totalLikes += 1;
          } else {
            selectedComment.totalLikes -= 1;
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(onMojoLikeToggle.pending, onMojoLikeToggle.rejected),
      (state, action) => {
        const { id } = action.meta.arg;

        const post = getPost(state, id);

        if (post) {
          onPostLikeToggle(post);
        }
      },
    );
  },
});
