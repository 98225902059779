import { Notification } from 'tedooo-web-design-system/ui';
import { SuccessMessageIcon } from 'tedooo-web-design-system/icons';
import './vacataionModeMessage.scss';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { ServerStatus } from 'types';
import { translations } from 'translations/en';

const RepostMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text={translations.SETTINGS.POPUPS.VACATION_MODE_MESSAGE}
        placement="top"
        icon={<SuccessMessageIcon />}
        className="vacation-mode-message"
      />
    );
  }

  return null;
};

export default RepostMessage;
