import './onetTimePaymentSkeleton.scss';
import '../oneTimePayment.scss';

import { Skeleton } from 'tedooo-web-design-system/ui';

const OneTimePaymentSkeleton = () => {
  return (
    <div className="one-time-payment-page">
      <div className="one-time-payment-section skeleton-section">
        <Skeleton
          title={false}
          avatar={{ shape: 'circle', size: 68 }}
          className="avatar-skeleton"
        />
        <Skeleton title className="skeleton-title" />
        <Skeleton title className="skeleton-subtitle" />
        <Skeleton title className="skeleton-price" />
        <Skeleton title={false} avatar={{ shape: 'square' }} className="skeleton-input" />
        <Skeleton title={false} avatar={{ shape: 'square' }} className="skeleton-input" />
      </div>
    </div>
  );
};

export default OneTimePaymentSkeleton;
