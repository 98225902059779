import { FC } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'tedooo-web-design-system/ui';

import { IMenuItem } from '../types';
import './menuItem.scss';
import NumberBubble from 'pages/chatv2/sideBar/sidebarList/numberBubble/NumberBubble';

const MenuItem: FC<IMenuItem> = ({ isSelected, text, Icon, totalNotifications, onClick }) => {
  return (
    <Tooltip text={text} placement="bottom">
      <div
        onClick={onClick}
        className={classNames('menu-item', {
          selected: isSelected,
        })}
      >
        {totalNotifications > 0 && <NumberBubble value={totalNotifications} />}
        <Icon />
        <div className="line" />
      </div>
    </Tooltip>
  );
};

export default MenuItem;
