import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IProductsState } from 'types';
import { parseProductsSearchResults, parseSavedProducts } from '../apiParser';
import { getAllProducts, getCategoryProducts } from './ProductsActions';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { updateProductLikes } from '../specificProduct/helper';

const PRODUCTS_OFFSET = 20;

const initialState: IProductsState = {
  list: null,
  totalCount: 0,
  paginationToken: '',
  offset: 0,
  isLoading: false,
  productIndexToScroll: 0,
};

export const ProductsSlicer = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset: (state) => {
      state.list = null;
      state.totalCount = 0;
      state.offset = 0;
      state.paginationToken = '';
    },
    updateProductIndexToScroll: (state, action) => {
      const { index } = action.payload;
      state.productIndexToScroll = index;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      const { products, totalCount, paginationToken } = action.payload;

      const parsedData = parseSavedProducts(products);

      state.isLoading = false;
      if (totalCount) {
        state.totalCount = totalCount;
      } else if (!state.totalCount) {
        state.totalCount = products.length;
      }
      if (paginationToken) {
        state.paginationToken = paginationToken;
      }

      const currentProducts = state.list || [];
      state.list = [...currentProducts, ...parsedData];
    });
    builder.addCase(getCategoryProducts.fulfilled, (state, action: any) => {
      const { total, data = [] } = action.payload;

      const parsedData = parseProductsSearchResults(data || []);

      state.isLoading = false;

      if (total) {
        state.totalCount = total;
      }
      state.offset += PRODUCTS_OFFSET;

      const currentProducts = state.list || [];
      state.list = [...currentProducts, ...parsedData];
    });
    builder.addMatcher(
      isAnyOf(onProductLikeToggle.pending, onProductLikeToggle.rejected),
      (state, action) => {
        const { productId } = action.meta.arg;

        if (state.list) {
          const product = state.list.find((item) => item.id === productId);
          updateProductLikes(product);
        }
      },
    );
    builder.addMatcher(isAnyOf(getAllProducts.pending, getCategoryProducts.pending), (state) => {
      state.isLoading = true;
    });
  },
});
