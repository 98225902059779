import { Notification } from 'tedooo-web-design-system/ui';
import { XIcon } from 'tedooo-web-design-system/icons';
import './chatErrorMessage.scss';
import { FC } from 'react';

import { ChatErrorMessageTypes } from 'types';
import { mapErrorTypeToText } from '../helper';

const ChatErrorMessage: FC<{ type: ChatErrorMessageTypes }> = ({ type }) => {
  return (
    <Notification
      icon={
        <div className="circle-icon">
          <XIcon className="x-icon" />
        </div>
      }
      type="error"
      text={mapErrorTypeToText[type]}
      placement="top"
      className="chat-message-error"
    />
  );
};

export default ChatErrorMessage;
