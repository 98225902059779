import { Middleware } from 'redux';

import { PopupSlicer } from 'store/popup/PopupActions';
import { deleteComment, getComments, getPost } from './SpecificPostActions';
import { checkIsSpecificPostPopup } from './helper';
import { SpecificPostSlicer } from './SpecificPostSlicer';
import { IStore2, popupIdsOptions } from 'types';
import { createPost } from '../feed/PostActions';

export const specificPostMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case getPost.fulfilled.type: {
        const postId = action.payload.notificationDetails._id;
        dispatch(getComments({ postId }));
        break;
      }

      case deleteComment.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case PopupSlicer.actions.hide.type: {
        const state: IStore2 = getState();

        const { ids, commentId, replyId } = state.popup;

        const isFeedPopup = checkIsSpecificPostPopup(ids);

        const isReactionPopup = ids.find((id) => id === popupIdsOptions.postReactionsPopup);

        if (isReactionPopup) {
          dispatch(SpecificPostSlicer.actions.onLikesClose({ commentId, replyId }));
        }

        const isOnePopup = ids.length === 1;

        if (isOnePopup && isFeedPopup) {
          dispatch(SpecificPostSlicer.actions.onPostUnselect());
        }
        break;
      }
      case PopupSlicer.actions.show.type: {
        const { id: updatedId, commentId, replyId } = action.payload;

        if (updatedId === popupIdsOptions.deleteCommentPopup) {
          dispatch(SpecificPostSlicer.actions.onSelectedCommentChange({ commentId, replyId }));
        }

        break;
      }
      case createPost.fulfilled.type:
        const state = getState();

        const { id: editedPostId } = action.meta.arg;

        const { id: specificPostId } = state.specificPost;

        const { token = '' } = state.user;

        if (editedPostId === specificPostId) {
          dispatch(getPost({ id: specificPostId, token }));
        }
        break;

      default:
        break;
    }

    return next(action);
  };
