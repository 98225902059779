import {
  IBasketShippingRate,
  ICustomizationRequest,
  IDiscountDetailsState,
  IGetBasketSupportedItemsResponse,
  ILinkedPaymentOption,
  IPrice,
  IProductsInSearchResults,
  IShopUserDetails,
  ProductAvailabilityItem,
} from 'types';
import {
  CartCountResponse,
  CartResponse,
  CheckoutResponse,
  IdableResponse,
  IdResponse,
  PaginatedItemsResult,
  ShippingEstimationResponse,
  SuccessResponse,
} from 'graphql/generated/graphql';
import { CurrencyCode } from 'constant/currencies';

export interface IBasketState {
  shopBaskets: IShopBasket[] | null;
  buyNowItem: { item: ICheckoutItem; checkoutId: string | null } | null;
  buyNowShopId: string | null;
  instantCheckoutDetails: {
    linkedPaymentOptions: ILinkedPaymentOption[];
    shippingRates: IBasketShippingRate[] | null;
  } | null;
  savedItems: IProductsInSearchResults[] | null;
  totalItems: number;
  source: BasketPageSources | null;
  discountDetailsStateMap: { [key: string]: IDiscountDetailsState | null };
}

export interface IBasketApi {
  getBasket(token: string): Promise<IGetCartResponse>;
  addItemToBasket(
    token: string,
    productId: string,
    quantity: number,
    customizationRequest: ICustomizationRequest,
  ): Promise<IdableResponse>;
  removeItemFromBasket(token: string, cartItemId: string): Promise<SuccessResponse>;
  updateCustomizationRequest(
    token: string,
    cartItemId: string,
    customization: ICustomizationRequest,
  ): Promise<SuccessResponse>;
  getBasketSupportedItems(
    token: string,
    productIds: string[],
  ): Promise<IGetBasketSupportedItemsResponse>;
  getShopBasketShippingRate(token: string, checkoutId: string): Promise<ShippingEstimationResponse>;
  getBasketShippingRates(token: string, checkoutId: string): Promise<CheckoutResponse>;
  getTotalBasketItems(token: string): Promise<CartCountResponse>;
  getCheckoutIdForProduct(
    token: string,
    productId: string,
    quantity: number,
    customization: ICustomizationRequest | null,
  ): Promise<IdResponse>;
}

export interface IGetCartResponse {
  getCart: CartResponse;
  getProductsByListId: PaginatedItemsResult;
}

export interface IShopBasket {
  shopId: string;
  shopName: string;
  userDetails: IShopUserDetails;
  checkoutId: string;
  hasInstantCheckout: boolean;
  subTotalPrice: number;
  currencyCode: CurrencyCode;
  shippingPrice: IPrice | null | undefined;
  items: ICartItem[];
  totalDiscount: number | null;
}

export interface ICartItem {
  cartItemId: string;
  productId: string;
  shopCheckoutId: string;
  title: string;
  price: IPrice;
  originalPrice: IPrice;
  isDigital: boolean;
  convertedFromPrice: IPrice | null;
  quantity: number;
  image: string;
  status: ProductAvailabilityItem;
  customizationRequest: ICustomizationRequest;
}

export interface ICheckoutItem {
  id: string;
  cartItemId: string | null;
  title: string;
  price: IPrice;
  originalPrice: IPrice;
  convertedFromPrice: IPrice | null;
  isDigital: boolean;
  quantity: number;
  image: string;
  status: ProductAvailabilityItem;
  customizationRequest: ICustomizationRequest | null;
}

export interface IAddToBasketDetails {
  id: string;
  quantity: number;
  title: string;
  price: IPrice;
  image: string;
  customizationRequest: ICustomizationRequest | null;
}

export enum BasketPageSources {
  BasketIcon = 'basket_icon',
  AddedToBasketButton = 'added_to_basket_button',
  BasketDrawer = 'basket_drawer',
}
