export const JOIN_CHAT = 'joinweb';

export const CONNECT = 'connect';

export const DELETE_MESSAGE = 'delete';

export const SUBSCRIBE_CHAT = 'subscribe';

export const USER_INFO = 'userInfo';

export const UNSUBSCRIBE_CHAT = 'unsub';

export const SIDEBAR_MESSAGES = 'sidebarMessages';

export const GROUP_MEMBERS = 'roomParticipants2';

export const MESSAGE_RECEIVED = 'message';

export const TYPING_GROUP = 'typing2';

export const TYPING_USER = 'typing';

export const SEND_SINGLE_MESSAGE = 'send';

export const SEND_MESSAGE_BATCH = 'send2';

export const ORDER_UPDATE = 'order_update';

export const VISIONS_CREDIT_CHANGED = 'visions_credit_changed';

export const VISION_OFFER_DELETED = 'vision_offer_deleted';

export const VISION_OFFER_CHANGED = 'vision_offer_changed';

export const VISION_OFFER_APPROVED = 'vision_offer_approved';

export const VISION_REQUEST_DELETED = 'vision_request_deleted';
