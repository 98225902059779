import {
  IChatMedia,
  ICreatePostInitialValues,
  IGroupItemDetails,
  IMessagePostDetails,
  ITaggedUser,
  NotificationsTypes,
  PayoutMethods,
  PaypalErrorResponse,
  IAddressPreview,
  IAddToBasketDetails,
  ICheckoutItem,
  MediaOption,
} from 'types';

export enum popupIdsOptions {
  uploadCsvPopup = 'uploadCsvPopup',
  feedImagesPopup = 'feedImagesPopup',
  postImagesPopup = 'postImagesPopup',
  feedReactionsPopup = 'feedReactionsPopup',
  mojoReactionsPopup = 'mojoReactionsPopup',
  linksNotAllowedPopup = 'linksNotAllowedPopup',
  reportUserPopup = 'reportUserPopup',
  signInPopup = 'signInPopup',
  postReactionsPopup = 'postReactionsPopup',
  reportUserConfirmationPopup = 'reportUserConfirmationPopup',
  feedDeleteCommentPopup = 'feedDeleteCommentPopup',
  mojoDeleteCommentPopup = 'mojoDeleteCommentPopup',
  deleteCommentPopup = 'deleteCommentPopup',
  blockUser = 'blockUser',
  deletePostPopup = 'deletePostPopup',
  deleteMojoPopup = 'deleteMojoPopup',
  createPostPopup = 'createPostPopup',
  editProfilePopup = 'editProfilePopup',
  editBirthdayPopup = 'editBirthdayPopup',
  profileViewsPopup = 'profileViewsPopup',
  editNotificationsPopup = 'editNotificationsPopup',
  deleteAccountPopup = 'deleteAccountPopup',
  blockedUsersPopup = 'blockedUsersPopup',
  changePasswordPopup = 'changePasswordPopup',
  editFullNamePopup = 'editFullNamePopup',
  editEmailPopup = 'editEmailPopup',
  editUsernamePopup = 'editUsernamePopup',
  shippingAddressesPopup = 'shippingAddressesPopup',
  forgetPasswordSettingsPopup = 'forgetPasswordSettingsPopup',
  loggedInViaThirdPartyPopup = 'loggedInViaThirdPartyPopup',
  getTheAppPopup = 'getTheAppPopup',
  createShopPopup = 'createShopPopup',
  editShopExitPopup = 'editShopExitPopup',
  createShopExitPopup = 'createShopExitPopup',
  addListingPopupShop = 'addListingPopupShop',
  addListingChooseDuplicatePopupShop = 'addListingChooseDuplicatePopupShop',
  deleteDraftPopup = 'deleteDraftPopup',
  followersPopup = 'followersPopup',
  searchFiltersPopup = 'searchFiltersPopup',
  reportPostPopup = 'reportPostPopup',
  reportMojoPopup = 'reportMojoPopup',
  sharePostPopup = 'sharePostPopup',
  shareMojoPopup = 'shareMojoPopup',
  paymentMethodHelperPopup = 'paymentMethodHelperPopup',
  legacyPaymentMethodHelperPopup = 'legacyPaymentMethodHelperPopup',
  howToGetMyLinkPopup = 'howToGetMyLinkPopup',
  deleteItemPopup = 'deleteItemPopup',
  shareItemPopup = 'shareItemPopup',
  chatImagePreviewPopup = 'chatImagePreviewPopup',
  deleteMessagePopup = 'deleteMessagePopup',
  forwardMessagesPopup = 'forwardMessagesPopup',
  muteChatPopup = 'muteChatPopup',
  leaveChatPopup = 'leaveChatPopup',
  shareGroupPopup = 'shareGroupPopup',
  sharePostInChatPopup = 'sharePostInChatPopup',
  feedRepostOptionsPopup = 'feedRepostOptionsPopup',
  repostOptionsPopup = 'repostOptionsPopup',
  feedRepostsPopup = 'feedRepostsPopup',
  repostsPopup = 'repostsPopup',
  deactivatePayoutPopup = 'deactivatePayoutPopup',
  onePayoutPopup = 'onePayoutPopup',
  payoutIntroPopup = 'payoutIntroPopup',
  editCurrencyPopup = 'editCurrencyPopup',
  vacationModePopup = 'vacationModePopup',
  csvPopup = 'csvPopup',
  viewTotalActiveOrdersPopup = 'viewTotalActiveOrdersPopup',
  viewPastOrdersPopup = 'viewPastOrdersPopup',
  viewOnTheWayPopups = 'viewOnTheWayPopups',
  viewShopViewsPopup = 'viewShopViewsPopup',
  declineOrderPopup = 'declineOrderPopup',
  declineVisionOfferPopup = 'declineVisionOfferPopup',
  deleteVisionRequestPopup = 'deleteVisionRequestPopup',
  withdrawVisionOfferPopup = 'withdrawVisionOfferPopup',
  didOrderArrivedPopup = 'didOrderArrivedPopup',
  paymentSuccessPopup = 'paymentSuccessPopup',
  paymentFailedPopup = 'paymentFailedPopup',
  orderReviewPopup = 'orderReceivedPopup',
  payOrderPopup = 'payOrderPopup',
  payVisionOfferPopup = 'payVisionOfferPopup',
  payBasketPopup = 'payBasketPopup',
  orderDigitalFilesPopup = 'orderDigitalFilesPopup',
  withdrawOrderPopup = 'withdrawPopup',
  redirectPopup = 'redirectPopup',
  paypalErrorPopup = 'paypalErrorPopup',
  addTrackingNumberPopup = 'addTrackingNumberPopup',
  requestToBuyPopup = 'requestToBuyPopup',
  itemAddedToBasketPopup = 'itemAddedToBasketPopup',
  orderArrivedPopup = 'orderArrivedPopup',
  waitForSellerPopup = 'waitForSellerPopup',
  newOrderPopup = 'newOrderPopup',
  paymentReceivedPopup = 'paymentReceivedPopup',
  paymentIssuePopup = 'paymentIssuePopup',
  trackOrderPopup = 'trackOrderPopup',
  sendFinalPricePopup = 'sendFinalPricePopup',
  contactSellerPopup = 'contactSellerPopup',
  orderDetailsPopup = 'orderDetailsPopup',
  confirmPaymentPopup = 'confirmPaymentPopup',
  createShippingLabelPopup = 'createShippingLabelPopup',
  purchasedLabelPopup = 'purchasedLabelPopup',
  connectShippoPopup = 'connectShippoPopup',
  listingCategoryPopup = 'listingCategoryPopup',
  listingKeywordPopup = 'listingKeywordPopup',
  returnsPolicyPopup = 'returnsPolicyPopup',
  createShippingProfilePopup = 'createShippingProfilePopup',
  shippingProfileConfirmAddressPopup = 'shippingProfileConfirmAddressPopup',
  shippingProfileValidateAddressPopup = 'shippingProfileValidateAddressPopup',
  confirmShippingAddressPopup = 'confirmShippingAddressPopup',
  validateShippingAddressPopup = 'validateShippingAddressPopup',
  validateBuyerShippingAddressPopup = 'validateBuyerShippingAddressPopup',
  invalidShippingAddressPopup = 'invalidShippingAddressPopup',
  selectListingShippingProfilePopup = 'selectListingShippingProfilePopup',
  selectVisionOfferShippingProfilePopup = 'selectVisionOfferShippingProfilePopup',
  shippingProfilesPopup = 'shippingProfilesPopup',
  editWillAffectListingsPopup = 'editWillAffectListingsPopup',
  deleteShippingProfilePopup = 'deleteShippingProfilePopup',
  shippingIntroPopup = 'shippingIntroPopup',
  editBuyerShippingAddressPopup = 'editBuyerShippingAddressPopup',
  confirmBuyerShippingAddressPopup = 'confirmBuyerShippingAddressPopup',
  updateShippingInformationPopup = 'updateShippingInformationPopup',
  upgradeToProPopup = 'upgradeToProPopup',
  subscribeSuccessPopup = 'subscribeSuccessPopup',
  favoritesIntroPopup = 'favoritesIntroPopup',
  newSavedItemPopup = 'newSavedItemPopup',
  discoverCategoriesPopup = 'discoverCategoriesPopup',
  mojosPopup = 'feedMojosPopup',
  patternExpiredPopup = 'patternExpiredPopup',
  buyVisionCreditsPopup = 'buyVisionCreditsPopup',
  buyVisionCreditsSuccessPopup = 'buyVisionCreditsSuccessPopup',
  regenerateVisionPopup = 'regenerateVisionPopup',
  visionsGuidePopup = 'visionsGuidePopup',
  requestToBuyVisionPopup = 'requestToBuyVisionPopup',
  selectShippingAddressPopup = 'selectShippingAddressPopup',
  sendVisionOfferPopup = 'sendVisionOfferPopup',
  hidePotentialVisionRequestPopup = 'hidePotentialVisionRequestPopup',
  newVisionOffersPopup = 'newVisionOffersPopup',
  visionOfferApprovedPopup = 'visionOfferApprovedPopup',
  saveItemsForLaterPopup = 'saveItemsForLaterPopup',
  goToCheckoutPopup = 'goToCheckoutPopup',
  fileSharingRulesPopup = 'fileSharingRulesPopup',
  reportFileMessagePopup = 'reportFileMessagePopup',
  reportFileMessageSuccessPopup = 'reportFileMessageSuccessPopup',
}

export interface IPopupCheckoutItemDetails {
  shopId: string;
  product: ICheckoutItem;
  countries: string[];
  isBuyWithPaypal: boolean;
}

export interface IPopup {
  ids: popupIdsOptions[];
  rememberMe: boolean;
  shouldHidePopupOverlay?: boolean;
  selectedGroup?: IGroupItemDetails;
  shouldOpenImagePicker?: boolean;
  shouldOpenCatalog?: boolean;
  isUserBlocked?: boolean;
  imageIndex?: number;
  productId?: string;
  selectedReviewIndex?: number;
  reviewId?: string;
  isSticky?: boolean;
  postId?: string;
  actionId?: string;
  commentId?: string;
  replyId?: string;
  createPostInitialValues?: ICreatePostInitialValues;
  taggedUsers?: ITaggedUser[];
  isSpecificSection?: boolean;
  isReposted?: boolean;
  messagesId?: string[];
  messageId?: string;
  mediaItem?: IChatMedia;
  postLink?: string;
  postDetails?: IMessagePostDetails;
  notificationType?: NotificationsTypes;
  payoutType?: PayoutMethods;
  link?: string;
  paypalErrorType?: PaypalErrorResponse;
  orderId?: string;
  addressSuggestion?: IAddressPreview;
  shippingProfileId?: string;
  userId?: string;
  shouldBlockUser?: boolean;
  isDuplicate?: boolean;
  checkoutItemDetails?: IPopupCheckoutItemDetails;
  source?: string;
  itemAddedToBasketDetails?: IAddToBasketDetails;
  shopId?: string;
  mojoId?: string;
  index?: number;
  visionId?: string;
  requestId?: string;
  offerId?: string;
  checkoutId?: string;
  image?: string;
  images?: string[];
  isBuyNow?: boolean;
  totalCredits?: number;
  selectedUploadOption?: MediaOption | null;
}
