import { createRef, FC } from 'react';
import classNames from 'classnames';
import { AutoComplete, Input } from 'tedooo-web-design-system/ui';
import { Search as SearchIcon } from 'tedooo-web-design-system/icons';

import './search.scss';
import { ISearch } from './types';

const Search: FC<ISearch> = ({
  onOptionClick,
  placeholder,
  defaultValue,
  options,
  onChange,
  value,
}) => {
  const ref = createRef<any>();

  const onSelect = (selectedValue: string) => {
    ref.current?.blur();

    onOptionClick(selectedValue);
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (value) {
        onOptionClick(value);
      }
    }
  };

  const isRecentOptions = !value;

  return (
    <AutoComplete
      onKeyUp={onKeyPress}
      shouldAutofill
      onChange={onChange}
      value={value}
      options={options}
      onSelect={onSelect}
      defaultValue={defaultValue}
      dropdownClassName={classNames('auto-complete-dropdown', { recent: isRecentOptions })}
    >
      <Input ref={ref} prefix={<SearchIcon />} placeholder={placeholder} />
    </AutoComplete>
  );
};

export default Search;
