import { IComment, IMojo } from 'types';
import { translations } from 'translations/en';

export const deleteCommentFromMojo = (mojo: IMojo, commentId: string) => {
  const selectedComment = mojo.comments.list?.find((comment) => comment.id === commentId);

  if (selectedComment) {
    mojo.totalComments -= 1;
    if (selectedComment.totalReplies > 0) {
      selectedComment.isDeleted = true;
      selectedComment.text = translations.FEED.POST.COMMENTS.DELETED;
    } else {
      mojo.comments.list = mojo.comments.list.filter((comment) => comment.id !== commentId);
    }
  }
};

export const deleteReplyFromComment = (comment: IComment, replyId: string) => {
  comment.totalReplies -= 1;
  comment.replies = comment.replies.filter((reply) => reply.id !== replyId);
};

export const getMojo = (list: IMojo[], id: string) => {
  return list.find((mojo) => mojo.id === id);
};

export const getComment = (mojo: IMojo, commentId: string) => {
  return mojo.comments.list?.find((comment) => comment.id === commentId);
};

export const addCommentToMojo = (mojo: IMojo, comment: IComment) => {
  mojo.comments.list?.push(comment);
  mojo.totalComments += 1;
};

export const addReplyToMojoComment = (mojo: IMojo, commentId: string, reply: IComment) => {
  const selectedComment = mojo.comments.list?.find((comment) => comment.id === commentId);

  if (selectedComment) {
    selectedComment.replies.push(reply);
    selectedComment.totalReplies += 1;
  }
  mojo.totalComments += 1;
};
