import { Countries } from './countries';

export enum CurrencyCode {
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
  CNY = 'CNY',
  NZD = 'NZD',
  EUR = 'EUR',
  SGD = 'SGD',
  JPY = 'JPY',
  CAD = 'CAD',
  AUD = 'AUD',
  INR = 'INR',
  IDR = 'IDR',
  BRL = 'BRL',
  DKK = 'DKK',
  TRY = 'TRY',
  ILS = 'ILS',
  PHP = 'PHP',
  SEK = 'SEK',
  KRW = 'KRW',
  THB = 'THB',
  HUF = 'HUF',
  MYR = 'MYR',
  CLP = 'CLP',
  UYU = 'UYU',
  AFN = 'AFN',
  XCD = 'XCD',
  ALL = 'ALL',
  DZD = 'DZD',
  AOA = 'AOA',
  ARS = 'ARS',
  AMD = 'AMD',
  AZN = 'AZN',
  BSD = 'BSD',
  BHD = 'BHD',
  ZWL = 'ZWL',
  ZMW = 'ZMW',
  YER = 'YER',
  VND = 'VND',
  XAF = 'XAF',
  VUV = 'VUV',
  MAD = 'MAD',
  UZS = 'UZS',
  AED = 'AED',
  UAH = 'UAH',
  UGX = 'UGX',
  TMT = 'TMT',
  BDT = 'BDT',
  BGN = 'BGN',
  HKD = 'HKD',
  BIF = 'BIF',
  BND = 'BND',
  BOB = 'BOB',
  NOK = 'NOK',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CDF = 'CDF',
  GNF = 'GNF',
  COP = 'COP',
  CRC = 'CRC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DOP = 'DOP',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  FJD = 'FJD',
  GEL = 'GEL',
  TND = 'TND',
  TOP = 'TOP',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  WST = 'WST',
  XOF = 'XOF',
  ZAR = 'ZAR',
  BBD = 'BBD',
  BAM = 'BAM',
  KHR = 'KHR',
  KMF = 'KMF',
  HRK = 'HRK',
  GMD = 'GMD',
  GHS = 'GHS',
  GTQ = 'GTQ',
  SZL = 'SZL',
  GYD = 'GYD',
  HTG = 'HTG',
  HNL = 'HNL',
  ISK = 'ISK',
  IRR = 'IRR',
  IQD = 'IQD',
  JMD = 'JMD',
  JOD = 'JOD',
  KZT = 'KZT',
  KES = 'KES',
  KWD = 'KWD',
  KGS = 'KGS',
  LAK = 'LAK',
  LBP = 'LBP',
  LSL = 'LSL',
  LRD = 'LRD',
  LYD = 'LYD',
  MGA = 'MGA',
  MWK = 'MWK',
  MVR = 'MVR',
  MUR = 'MUR',
  MXN = 'MXN',
  MDL = 'MDL',
  MNT = 'MNT',
  MZN = 'MZN',
  MMK = 'MMK',
  NAD = 'NAD',
  NPR = 'NPR',
  NIO = 'NIO',
  NGN = 'NGN',
  KPW = 'KPW',
  OMR = 'OMR',
  PKR = 'PKR',
  PAB = 'PAB',
  PGK = 'PGK',
  PYG = 'PYG',
  PEN = 'PEN',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  RSD = 'RSD',
  SCR = 'SCR',
  SLL = 'SLL',
  SBD = 'SBD',
  SOS = 'SOS',
  SSP = 'SSP',
  LKR = 'LKR',
  SDG = 'SDG',
  SRD = 'SRD',
  SYP = 'SYP',
  TJS = 'TJS',
}

export const mapCurrencyToLongString = {
  [CurrencyCode.USD]: 'United States dollar (USD - $)',
  [CurrencyCode.AUD]: 'Australian dollar (AUD - $)',
  [CurrencyCode.ILS]: 'Israeli new shekel (ILS - ₪)',
  [CurrencyCode.CAD]: 'Canadian dollar (CAD - $)',
  [CurrencyCode.EUR]: 'Euro (EUR - €)',
  [CurrencyCode.BRL]: 'Brazilian real (BRL - R$)',
  [CurrencyCode.CLP]: 'Chilean peso (CLP - $)',
  [CurrencyCode.CNY]: 'Chinese renminbi (CNY - CN¥)',
  [CurrencyCode.JPY]: 'Japanese yen (JPY - ¥)',
  [CurrencyCode.MYR]: 'Malaysian ringgit (MYR - RM)',
  [CurrencyCode.NZD]: 'New Zealand dollar (NZD - $)',
  [CurrencyCode.PHP]: 'Philippine peso (PHP - ₱)',
  [CurrencyCode.GBP]: 'Pound sterling (GBP - £)',
  [CurrencyCode.SGD]: 'Singapore dollar (SGD – $)',
  [CurrencyCode.SEK]: 'Swedish krona (SEK – kr)',
  [CurrencyCode.THB]: 'Thai baht (THB - ฿)',
  [CurrencyCode.TRY]: 'Turkish lira (TRY – ₺)',
  [CurrencyCode.UYU]: 'Uruguayan peso (UYU – $U)',
  [CurrencyCode.HKD]: 'Hong Kong dollar (HKD – $)',
  [CurrencyCode.MXN]: 'Mexican peso (MXN – $)',
  [CurrencyCode.TWD]: 'Taiwan new dollar (TWD – NT$)',
  [CurrencyCode.CHF]: 'Indian rupeeSwiss Franc (CHF – CHF)',
  [CurrencyCode.VND]: 'Vietnamese dong (VND – ₫)',
  [CurrencyCode.NOK]: 'Norwegian krone (NOK – kr)',
  [CurrencyCode.IDR]: 'Indonesian rupiah (IDR – Rp)',
  [CurrencyCode.INR]: 'Indian rupee (INR – ₹)',
  [CurrencyCode.DKK]: 'Danish krone lev (DKK – Kr)',
  [CurrencyCode.CZK]: 'Czech koruna (CZK – Kč)',
  [CurrencyCode.PLN]: 'Polish złoty (PLN – zł)',
  [CurrencyCode.HUF]: 'Hungarian forint (HUF – Ft)',
};

export const mapCurrencyToShortString = {
  [CurrencyCode.USD]: 'USD - $',
  [CurrencyCode.AUD]: 'AUD - $',
  [CurrencyCode.ILS]: 'ILS - ₪',
  [CurrencyCode.CAD]: 'CAD - $',
  [CurrencyCode.EUR]: 'EUR - €',
  [CurrencyCode.BRL]: 'BRL - R$',
  [CurrencyCode.CLP]: 'CLP - $',
  [CurrencyCode.CNY]: 'CNY - CN¥',
  [CurrencyCode.JPY]: 'JPY - ¥',
  [CurrencyCode.MYR]: 'MYR - RM',
  [CurrencyCode.NZD]: 'NZD - $',
  [CurrencyCode.PHP]: 'PHP - ₱',
  [CurrencyCode.GBP]: 'GBP - £',
  [CurrencyCode.SGD]: 'SGD – $',
  [CurrencyCode.SEK]: 'SEK – kr',
  [CurrencyCode.THB]: 'THB - ฿',
  [CurrencyCode.TRY]: 'TRY – ₺',
  [CurrencyCode.UYU]: 'UYU – $U',
  [CurrencyCode.HKD]: 'HKD – $',
  [CurrencyCode.MXN]: 'MXN – $',
  [CurrencyCode.TWD]: 'TWD – NT$',
  [CurrencyCode.CHF]: 'CHF – CHF',
  [CurrencyCode.VND]: 'VND – ₫',
  [CurrencyCode.NOK]: 'NOK – kr',
  [CurrencyCode.IDR]: 'IDR – Rp',
  [CurrencyCode.INR]: 'INR – ₹',
  [CurrencyCode.DKK]: 'DKK – Kr',
  [CurrencyCode.CZK]: 'CZK – Kč',
  [CurrencyCode.PLN]: 'PLN – zł',
  [CurrencyCode.HUF]: 'HUF – Ft',
};

export const mapCurrencyToSymbol = {
  [CurrencyCode.USD]: '$',
  [CurrencyCode.AUD]: 'AU$',
  [CurrencyCode.ILS]: '₪',
  [CurrencyCode.CAD]: 'CA$',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.BRL]: 'R$',
  [CurrencyCode.CLP]: '$',
  [CurrencyCode.CNY]: 'CN¥',
  [CurrencyCode.JPY]: '¥',
  [CurrencyCode.MYR]: 'RM',
  [CurrencyCode.NZD]: 'NZ$',
  [CurrencyCode.PHP]: '₱',
  [CurrencyCode.GBP]: '£',
  [CurrencyCode.SGD]: 'S$',
  [CurrencyCode.SEK]: 'SEK',
  [CurrencyCode.THB]: '฿',
  [CurrencyCode.TRY]: '₺',
  [CurrencyCode.UYU]: '$U',
  [CurrencyCode.HKD]: 'HK$',
  [CurrencyCode.MXN]: 'MX$',
  [CurrencyCode.TWD]: 'NT$',
  [CurrencyCode.CHF]: 'CHF',
  [CurrencyCode.VND]: '₫',
  [CurrencyCode.NOK]: 'NOK',
  [CurrencyCode.IDR]: 'Rp',
  [CurrencyCode.INR]: '₹',
  [CurrencyCode.DKK]: 'DKK',
  [CurrencyCode.CZK]: 'CZ',
  [CurrencyCode.PLN]: 'zł',
  [CurrencyCode.HUF]: 'Ft',
};

export const mapCountryToCurrency = {
  [Countries.Afghanistan]: [CurrencyCode.AFN],
  [Countries.Anguilla]: [CurrencyCode.XCD],
  [Countries.Albania]: [CurrencyCode.ALL],
  [Countries.Algeria]: [CurrencyCode.DZD],
  [Countries.Andorra]: [CurrencyCode.EUR],
  [Countries.Angola]: [CurrencyCode.AOA],
  [Countries.AntiguaAndBarbuda]: [CurrencyCode.XCD],
  [Countries.Antarctica]: [CurrencyCode.USD],
  [Countries.Argentina]: [CurrencyCode.ARS],
  [Countries.Armenia]: [CurrencyCode.AMD],
  [Countries.AmericanSamoa]: [CurrencyCode.USD],
  [Countries.Australia]: [CurrencyCode.AUD],
  [Countries.AlandIslands]: [CurrencyCode.EUR],
  [Countries.Austria]: [CurrencyCode.EUR],
  [Countries.Azerbaijan]: [CurrencyCode.AZN],
  [Countries.Bahamas]: [CurrencyCode.BSD],
  [Countries.Bahrain]: [CurrencyCode.BHD],
  [Countries.Bangladesh]: [CurrencyCode.BDT],
  [Countries.Barbados]: [CurrencyCode.BBD],
  [Countries.Belarus]: [CurrencyCode.BYN],
  [Countries.Belgium]: [CurrencyCode.EUR],
  [Countries.Belize]: [CurrencyCode.BZD],
  [Countries.CocosIslands]: [CurrencyCode.AUD],
  [Countries.Benin]: [CurrencyCode.XOF],
  [Countries.SaintBarthelemy]: [CurrencyCode.EUR],
  [Countries.Bhutan]: [CurrencyCode.BTN],
  [Countries.BouvetIslands]: [CurrencyCode.NOK],
  [Countries.Bolivia]: [CurrencyCode.BOB],
  [Countries.BosniaAndHerzegovina]: [CurrencyCode.BAM],
  [Countries.Botswana]: [CurrencyCode.BWP],
  [Countries.Brazil]: [CurrencyCode.BRL],
  [Countries.Brunei]: [CurrencyCode.BND],
  [Countries.Bulgaria]: [CurrencyCode.BGN],
  [Countries.BurkinaFaso]: [CurrencyCode.XOF],
  [Countries.Burundi]: [CurrencyCode.BIF],
  [Countries.CapeVerde]: [CurrencyCode.CVE],
  [Countries.ChristmasIsland]: [CurrencyCode.AUD],
  [Countries.Cambodia]: [CurrencyCode.KHR],
  [Countries.Cameroon]: [CurrencyCode.XAF],
  [Countries.Canada]: [CurrencyCode.CAD],
  [Countries.CentralAfricanRepublic]: [CurrencyCode.XAF],
  [Countries.Chad]: [CurrencyCode.XAF],
  [Countries.FrenchSouthernTerritories]: [CurrencyCode.EUR],
  [Countries.Chile]: [CurrencyCode.CLP],
  [Countries.China]: [CurrencyCode.CNY],
  [Countries.Colombia]: [CurrencyCode.COP],
  [Countries.Comoros]: [CurrencyCode.KMF],
  [Countries.Congo]: [CurrencyCode.CDF],
  [Countries.CostaRica]: [CurrencyCode.CRC],
  [Countries.Croatia]: [CurrencyCode.HRK],
  [Countries.Cyprus]: [CurrencyCode.EUR],
  [Countries.CzechRepublic]: [CurrencyCode.CZK],
  [Countries.Denmark]: [CurrencyCode.DKK],
  [Countries.Djibouti]: [CurrencyCode.DJF],
  [Countries.Dominica]: [CurrencyCode.XCD],
  [Countries.DominicanRepublic]: [CurrencyCode.DOP],
  [Countries.Ecuador]: [CurrencyCode.USD],
  [Countries.Egypt]: [CurrencyCode.EGP],
  [Countries.ElSalvador]: [CurrencyCode.USD],
  [Countries.EquatorialGuinea]: [CurrencyCode.XAF],
  [Countries.Eritrea]: [CurrencyCode.ERN],
  [Countries.Estonia]: [CurrencyCode.EUR],
  [Countries.Eswatini]: [CurrencyCode.SZL],
  [Countries.Ethiopia]: [CurrencyCode.ETB],
  [Countries.FaroeIslands]: [CurrencyCode.DKK],
  [Countries.Fiji]: [CurrencyCode.FJD],
  [Countries.Finland]: [CurrencyCode.EUR],
  [Countries.France]: [CurrencyCode.EUR],
  [Countries.FrenchGuiana]: [CurrencyCode.EUR],
  [Countries.Gabon]: [CurrencyCode.XAF],
  [Countries.Gambia]: [CurrencyCode.GMD],
  [Countries.Georgia]: [CurrencyCode.GEL],
  [Countries.Germany]: [CurrencyCode.EUR],
  [Countries.Ghana]: [CurrencyCode.GHS],
  [Countries.Greece]: [CurrencyCode.EUR],
  [Countries.Greenland]: [CurrencyCode.DKK],
  [Countries.Grenada]: [CurrencyCode.XCD],
  [Countries.Guadeloupe]: [CurrencyCode.EUR],
  [Countries.Guam]: [CurrencyCode.USD],
  [Countries.Guatemala]: [CurrencyCode.GTQ],
  [Countries.Guernsey]: [CurrencyCode.GBP],
  [Countries.Guinea]: [CurrencyCode.GNF],
  [Countries.GuineaBissau]: [CurrencyCode.XOF],
  [Countries.Guyana]: [CurrencyCode.GYD],
  [Countries.Haiti]: [CurrencyCode.HTG],
  [Countries.HeardIslandAndMcdonaldIslands]: [CurrencyCode.AUD],
  [Countries.Honduras]: [CurrencyCode.HNL],
  [Countries.HongKong]: [CurrencyCode.HKD],
  [Countries.Hungary]: [CurrencyCode.HUF],
  [Countries.Iceland]: [CurrencyCode.ISK],
  [Countries.India]: [CurrencyCode.INR],
  [Countries.Indonesia]: [CurrencyCode.IDR],
  [Countries.Iran]: [CurrencyCode.IRR],
  [Countries.Iraq]: [CurrencyCode.IQD],
  [Countries.Ireland]: [CurrencyCode.EUR],
  [Countries.IsleOfMan]: [CurrencyCode.GBP],
  [Countries.Israel]: [CurrencyCode.ILS],
  [Countries.Italy]: [CurrencyCode.EUR],
  [Countries.Jamaica]: [CurrencyCode.JMD],
  [Countries.Japan]: [CurrencyCode.JPY],
  [Countries.Jersey]: [CurrencyCode.GBP],
  [Countries.Jordan]: [CurrencyCode.JOD],
  [Countries.Kazakhstan]: [CurrencyCode.KZT],
  [Countries.Kenya]: [CurrencyCode.KES],
  [Countries.Kiribati]: [CurrencyCode.AUD],
  [Countries.Kuwait]: [CurrencyCode.KWD],
  [Countries.Kyrgyzstan]: [CurrencyCode.KGS],
  [Countries.Laos]: [CurrencyCode.LAK],
  [Countries.Latvia]: [CurrencyCode.EUR],
  [Countries.Lebanon]: [CurrencyCode.LBP],
  [Countries.Lesotho]: [CurrencyCode.LSL],
  [Countries.Liberia]: [CurrencyCode.LRD],
  [Countries.Libya]: [CurrencyCode.LYD],
  [Countries.Liechtenstein]: [CurrencyCode.CHF],
  [Countries.Lithuania]: [CurrencyCode.EUR],
  [Countries.Luxembourg]: [CurrencyCode.EUR],
  [Countries.Madagascar]: [CurrencyCode.MGA],
  [Countries.Malawi]: [CurrencyCode.MWK],
  [Countries.Malaysia]: [CurrencyCode.MYR],
  [Countries.Maldives]: [CurrencyCode.MVR],
  [Countries.Mali]: [CurrencyCode.XOF],
  [Countries.Malta]: [CurrencyCode.EUR],
  [Countries.MarshallIslands]: [CurrencyCode.USD],
  [Countries.Martinique]: [CurrencyCode.EUR],
  [Countries.Mauritius]: [CurrencyCode.MUR],
  [Countries.Mayotte]: [CurrencyCode.EUR],
  [Countries.Mexico]: [CurrencyCode.MXN],
  [Countries.Micronesia]: [CurrencyCode.USD],
  [Countries.Moldova]: [CurrencyCode.MDL],
  [Countries.Monaco]: [CurrencyCode.EUR],
  [Countries.Mongolia]: [CurrencyCode.MNT],
  [Countries.Montenegro]: [CurrencyCode.EUR],
  [Countries.Montserrat]: [CurrencyCode.XCD],
  [Countries.Morocco]: [CurrencyCode.MAD],
  [Countries.Mozambique]: [CurrencyCode.MZN],
  [Countries.Myanmar]: [CurrencyCode.MMK],
  [Countries.Namibia]: [CurrencyCode.NAD],
  [Countries.Nauru]: [CurrencyCode.AUD],
  [Countries.Nepal]: [CurrencyCode.NPR],
  [Countries.Netherlands]: [CurrencyCode.EUR],
  [Countries.NewZealand]: [CurrencyCode.NZD],
  [Countries.Nicaragua]: [CurrencyCode.NIO],
  [Countries.Niger]: [CurrencyCode.XOF],
  [Countries.Nigeria]: [CurrencyCode.NGN],
  [Countries.Niue]: [CurrencyCode.NZD],
  [Countries.NorfolkIsland]: [CurrencyCode.AUD],
  [Countries.NorthKorea]: [CurrencyCode.KPW],
  [Countries.NorthMarianaIslands]: [CurrencyCode.USD],
  [Countries.Norway]: [CurrencyCode.NOK],
  [Countries.Oman]: [CurrencyCode.OMR],
  [Countries.Pakistan]: [CurrencyCode.PKR],
  [Countries.Palau]: [CurrencyCode.USD],
  [Countries.Panama]: [CurrencyCode.PAB],
  [Countries.PapuaNewGuinea]: [CurrencyCode.PGK],
  [Countries.Paraguay]: [CurrencyCode.PYG],
  [Countries.Peru]: [CurrencyCode.PEN],
  [Countries.Philippines]: [CurrencyCode.PHP],
  [Countries.Pitcairn]: [CurrencyCode.NZD],
  [Countries.Poland]: [CurrencyCode.PLN],
  [Countries.Portugal]: [CurrencyCode.EUR],
  [Countries.PuertoRico]: [CurrencyCode.USD],
  [Countries.Qatar]: [CurrencyCode.QAR],
  [Countries.Romania]: [CurrencyCode.RON],
  [Countries.Russia]: [CurrencyCode.RUB],
  [Countries.Rwanda]: [CurrencyCode.RWF],
  [Countries.SaintBarthelemy]: [CurrencyCode.EUR],
  [Countries.SaintKittsAndNevis]: [CurrencyCode.XCD],
  [Countries.SaintLucia]: [CurrencyCode.XCD],
  [Countries.SaintMartin]: [CurrencyCode.EUR],
  [Countries.SaintPierreAndMiquelon]: [CurrencyCode.EUR],
  [Countries.SaintVincentAndTheGrenadines]: [CurrencyCode.XCD],
  [Countries.Samoa]: [CurrencyCode.WST],
  [Countries.SanMarino]: [CurrencyCode.EUR],
  [Countries.SaudiArabia]: [CurrencyCode.SAR],
  [Countries.Senegal]: [CurrencyCode.XOF],
  [Countries.Serbia]: [CurrencyCode.RSD],
  [Countries.Seychelles]: [CurrencyCode.SCR],
  [Countries.SierraLeone]: [CurrencyCode.SLL],
  [Countries.Singapore]: [CurrencyCode.SGD],
  [Countries.Slovakia]: [CurrencyCode.EUR],
  [Countries.Slovenia]: [CurrencyCode.EUR],
  [Countries.SolomonIslands]: [CurrencyCode.SBD],
  [Countries.Somalia]: [CurrencyCode.SOS],
  [Countries.SouthAfrica]: [CurrencyCode.ZAR],
  [Countries.SouthKorea]: [CurrencyCode.KRW],
  [Countries.SouthSudan]: [CurrencyCode.SSP],
  [Countries.Spain]: [CurrencyCode.EUR],
  [Countries.SriLanka]: [CurrencyCode.LKR],
  [Countries.Sudan]: [CurrencyCode.SDG],
  [Countries.Suriname]: [CurrencyCode.SRD],
  [Countries.Sweden]: [CurrencyCode.SEK],
  [Countries.Switzerland]: [CurrencyCode.CHF],
  [Countries.Syria]: [CurrencyCode.SYP],
  [Countries.Taiwan]: [CurrencyCode.TWD],
  [Countries.Tajikistan]: [CurrencyCode.TJS],
  [Countries.Tanzania]: [CurrencyCode.TZS],
  [Countries.Thailand]: [CurrencyCode.THB],
  [Countries.TimorLeste]: [CurrencyCode.USD],
  [Countries.Togo]: [CurrencyCode.XOF],
  [Countries.Tokelau]: [CurrencyCode.NZD],
  [Countries.Tonga]: [CurrencyCode.TOP],
  [Countries.TrinidadAndTobago]: [CurrencyCode.TTD],
  [Countries.Tunisia]: [CurrencyCode.TND],
  [Countries.Turkey]: [CurrencyCode.TRY],
  [Countries.Turkmenistan]: [CurrencyCode.TMT],
  [Countries.TurksAndCaicosIslands]: [CurrencyCode.USD],
  [Countries.Tuvalu]: [CurrencyCode.AUD],
  [Countries.Uganda]: [CurrencyCode.UGX],
  [Countries.Ukraine]: [CurrencyCode.UAH],
  [Countries.UnitedArabEmirates]: [CurrencyCode.AED],
  [Countries.UnitedKingdom]: [CurrencyCode.GBP],
  [Countries.UnitedStates]: [CurrencyCode.USD],
  [Countries.Uruguay]: [CurrencyCode.UYU],
  [Countries.Uzbekistan]: [CurrencyCode.UZS],
  [Countries.Vanuatu]: [CurrencyCode.VUV],
  [Countries.VaticanCity]: [CurrencyCode.EUR],
  [Countries.Vietnam]: [CurrencyCode.VND],
  [Countries.VirginIslandsUK]: [CurrencyCode.USD],
  [Countries.VirginIslandsUS]: [CurrencyCode.USD],
  [Countries.WesternSahara]: [CurrencyCode.MAD],
  [Countries.Yemen]: [CurrencyCode.YER],
  [Countries.Zambia]: [CurrencyCode.ZMW],
  [Countries.Zimbabwe]: [CurrencyCode.ZWL],
};
