import { gql } from '@apollo/client';

export const GET_SHIPPING_RATES_FOR_CHECKOUT = gql`
  query GetAvailableRatesForCheckout(
    $checkoutId: String!
    $shippingAddress: ShippingAddressFullInput
    $fullName: String!
    $email: String!
  ) {
    getAvailableRatesForCheckout(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
      fullName: $fullName
      email: $email
    ) {
      payments {
        stripe {
          id
          title
          description
          buttonText
          imageUrls
        }
        paypal
      }
      rates {
        rateId
        arrivalDate
        name
        price {
          price
          currency
          currencyCode
        }
        tags
        image
        image
      }
    }
  }
`;
