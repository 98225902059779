import { IDiscoverProduct, IProduct, IProductsInSearchResults } from 'types';

export const updateProductLikes = (
  product?: IProduct | IProductsInSearchResults | IDiscoverProduct,
) => {
  if (product) {
    if (product.totalLikes) {
      product.totalLikes += product.isLiked ? -1 : 1;
    }
    product.isLiked = !product.isLiked;
  }
};
