import { createSlice } from '@reduxjs/toolkit';

import { IPopup } from 'types';

const initialState: IPopup = {
  ids: [],
  rememberMe: false,
};

export const PopupSlicer = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    show: (state, action) => {
      const {
        id,
        shouldHidePopupOverlay,
        imageIndex,
        isUserBlocked,
        productId,
        shouldOpenImagePicker,
        shouldOpenCatalog,
        selectedReviewIndex,
        reviewId,
        isSticky,
        postId,
        createPostInitialValues,
        isSpecificSection,
        commentId,
        replyId,
        selectedGroup,
        messagesId,
        mediaItem,
        postLink,
        postDetails,
        notificationType,
        isReposted,
        actionId,
        payoutType,
        link,
        checkoutItemDetails,
        paypalErrorType,
        orderId,
        requestId,
        offerId,
        taggedUsers,
        addressSuggestion,
        shippingProfileId,
        userId,
        shouldBlockUser,
        isDuplicate,
        source,
        itemAddedToBasketDetails,
        shopId,
        mojoId,
        visionId,
        checkoutId,
        index,
        image,
        images,
        isBuyNow,
        totalCredits,
        selectedUploadOption,
        messageId,
      } = action.payload;
      state.ids.push(id);
      state.shouldHidePopupOverlay = shouldHidePopupOverlay;
      state.checkoutItemDetails = checkoutItemDetails;
      state.isSpecificSection = isSpecificSection;
      state.productId = productId;
      state.selectedGroup = selectedGroup;
      state.isUserBlocked = isUserBlocked;
      state.imageIndex = imageIndex;
      state.selectedReviewIndex = selectedReviewIndex;
      state.reviewId = reviewId;
      state.isSticky = isSticky;
      state.shouldOpenImagePicker = shouldOpenImagePicker;
      state.shouldOpenCatalog = shouldOpenCatalog;
      state.postId = postId;
      state.actionId = actionId;
      state.createPostInitialValues = createPostInitialValues;
      state.commentId = commentId;
      state.replyId = replyId;
      state.messagesId = messagesId;
      state.mediaItem = mediaItem;
      state.postLink = postLink;
      state.postDetails = postDetails;
      state.notificationType = notificationType;
      state.payoutType = payoutType;
      state.isReposted = isReposted;
      state.link = link;
      state.paypalErrorType = paypalErrorType;
      state.orderId = orderId;
      state.offerId = offerId;
      state.taggedUsers = taggedUsers;
      state.addressSuggestion = addressSuggestion;
      state.shippingProfileId = shippingProfileId;
      state.userId = userId;
      state.shouldBlockUser = shouldBlockUser;
      state.isDuplicate = isDuplicate;
      state.source = source;
      state.itemAddedToBasketDetails = itemAddedToBasketDetails;
      state.shopId = shopId;
      state.mojoId = mojoId;
      state.checkoutId = checkoutId;
      state.visionId = visionId;
      state.requestId = requestId;
      state.index = index;
      state.image = image;
      state.images = images;
      state.isBuyNow = isBuyNow;
      state.totalCredits = totalCredits;
      state.selectedUploadOption = selectedUploadOption;
      state.messageId = messageId;
    },
    hide: (state) => {
      state.selectedGroup = undefined;
      state.ids.pop();
    },

    hideAll: (state) => {
      state.selectedGroup = undefined;
      state.ids = [];
    },
  },
});

export default PopupSlicer.reducer;
