import { FC } from 'react';
import { Button } from 'tedooo-web-design-system/ui';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Avatar, Image, Span } from '../helpers';
import './profileCard.scss';
import { IProfileCard } from './types';
import { translations } from 'translations/en';
import DefaultCoverPhoto from 'photos/coverPhoto.png';
import { ROUTES } from 'constant';
import { popupIdsOptions } from 'types';
import FollowButton from '../followButton/FollowButton';

const ProfileCard: FC<IProfileCard> = ({
  isFollowing,
  avatar,
  username,
  shopName,
  coverPhoto,
  totalFollowers,
  totalFollowing,
  onFollowToggle,
  shouldShowFollowButton,
  onViewProfileClick,
  isReadOnly,
  isUserProfileCard,
  showPopup,
}) => {
  const navigate = useNavigate();

  const onFollowingClick = () => {
    if (isUserProfileCard) {
      navigate(`/${ROUTES.FOLLOWING}`);
    }
  };

  const onFollowersClick = () => {
    if (isUserProfileCard) {
      showPopup({ id: popupIdsOptions.followersPopup });
    }
  };

  const shouldShowFollowers = !isReadOnly && shouldShowFollowButton;

  return (
    <div className="profile-card-container">
      <div className="header">
        <div className="cover-photo-container">
          <Image className="cover-photo" src={coverPhoto || DefaultCoverPhoto} />
        </div>
        <Avatar onClick={onViewProfileClick} className="avatar" src={avatar} size={54} />
      </div>
      <div className="text-container">
        <Span
          text={shopName}
          className={classNames('shop-name', { clickable: !isReadOnly })}
          onClick={onViewProfileClick}
        />
        <Span text={username} className="username" />
        {shouldShowFollowers && (
          <div className="followers-container">
            <span
              className={classNames('follow-text-container', { clickable: isUserProfileCard })}
              onClick={onFollowingClick}
            >
              {totalFollowing}
              <Span text="Following" className="follow" />
            </span>
            <span
              className={classNames('follow-text-container', { clickable: isUserProfileCard })}
              onClick={onFollowersClick}
            >
              {totalFollowers}
              <Span text="Followers" className="follow" />
            </span>
          </div>
        )}
      </div>
      {!isUserProfileCard && (
        <div className="button-container">
          {shouldShowFollowButton && (
            <FollowButton
              isFollowing={isFollowing}
              onFollowToggle={onFollowToggle}
              type="secondary"
              isFullWidth
            />
          )}
          {!shouldShowFollowButton && (
            <Button
              type="secondary"
              size="medium"
              borderRadius="small"
              text={translations.COMMON.VIEW_PROFILE}
              onClick={onViewProfileClick}
              isFullWidth
            />
          )}
        </div>
      )}
      {isUserProfileCard && (
        <div className="profile-card-footer">
          <Button
            type="tertiary"
            size="medium"
            borderRadius="small"
            text={translations.FEED.PROFILE.GO_TO_PROFILE}
            onClick={onViewProfileClick}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
