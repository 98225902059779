import { FC, useState } from 'react';
import { FilledHeartIcon, HeartIcon } from 'tedooo-web-design-system/icons';
import './circleLikeButton.scss';
import Lottie from 'react-lottie';

import Animation from 'lotties/light-like-lottie.json';

const LOTTIE_SIZE = 65;

interface ICircleLikeButton {
  isLiked: boolean;
  onClick(): void;
}

const CircleLikeButton: FC<ICircleLikeButton> = ({ isLiked, onClick }) => {
  const [isAnimationShown, setIsAnimationShown] = useState(false);

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onAnimationFinish = () => {
    setIsAnimationShown(false);
  };

  const icon = isLiked ? (
    <FilledHeartIcon className="filled-heart" />
  ) : (
    <HeartIcon className="icon" />
  );

  const onLikeClick = (e: any) => {
    e.stopPropagation();
    if (!isLiked) {
      setIsAnimationShown(true);
    } else {
      setIsAnimationShown(false);
    }
    onClick();
  };

  return (
    <div className="like-button-container" onClick={onLikeClick}>
      {!isAnimationShown && icon}
      {isAnimationShown && (
        <Lottie
          options={lottieOptions}
          height={LOTTIE_SIZE}
          width={LOTTIE_SIZE}
          isStopped={false}
          isPaused={false}
          eventListeners={[{ eventName: 'complete', callback: onAnimationFinish }]}
        />
      )}
    </div>
  );
};

export default CircleLikeButton;
