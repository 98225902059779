import { Skeleton } from 'tedooo-web-design-system/ui';
import './postSkeleton.scss';

const PostSkeleton = () => {
  return (
    <div className="post-skeleton">
      <Skeleton title avatar className="title-skeleton" rows={1} />
      <Skeleton title={false} avatar={false} rows={3} className="text-skeleton" />
      <Skeleton title={false} avatar={{ shape: 'square' }} className="image-skeleton" />
    </div>
  );
};

export default PostSkeleton;
