import { createSlice } from '@reduxjs/toolkit';

import {
  ISendVisionOfferState,
  IVisionOffer,
  ReturnPolicyType,
  VisionOfferShippingError,
  VisionOffersTab,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import {
  getCurrencyCodeAndShippingProfiles,
  getRequestForOffer,
  getVisionOfferShippingPrice,
} from './SendVisionOfferActions';
import { onEditOffer } from './helper';
import { parseVisionOffer, parseVisionRequest } from '../apiParser';
import { getVisionOffer } from '../specificVisionRequest/SpecificVisionRequestActions';

const initialState: ISendVisionOfferState = {
  id: null,
  tariffNumber: '',
  comments: '',
  shippingProfileId: '',
  shippingPrice: null,
  price: {
    value: 0,
    currencyCode: CurrencyCode.USD,
    currencySymbol: '$',
  },
  returnPolicy: ReturnPolicyType.ReturnAndExchange30,
  dimensions: {
    width: 0,
    length: 0,
    height: 0,
    weightSmall: 0,
    weightBig: 0,
    isMetric: true,
  },
  request: null,
  shippingPriceError: null,
};

export const SendVisionOfferSlicer = createSlice({
  name: 'sendVisionOffer',
  initialState,
  reducers: {
    reset: () => initialState,
    onTariffNumberChange: (state, action) => {
      const { text } = action.payload;
      state.tariffNumber = text;
    },
    onPriceChange: (state, action) => {
      const { price } = action.payload;
      state.price.value = price;
    },
    onNoteChange: (state, action) => {
      const { text } = action.payload;
      state.comments = text;
    },
    updateShippingPriceErrorAndRemovePrice: (state, action) => {
      const { error } = action.payload;
      state.shippingPriceError = error;
      state.shippingPrice = null;
    },
    onShippingProfileSelect: (state, action) => {
      const { id } = action.payload;
      state.shippingPrice = null;
      state.shippingProfileId = id;
    },
    onMetricChange: (state, action) => {
      const { isMetric } = action.payload;
      state.dimensions.isMetric = isMetric;
    },
    onLengthChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.length = value;
    },
    onHeightChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.height = value;
    },
    onWidthChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.width = value;
    },
    onWeightBigChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.weightBig = value;
    },
    onWeightSmallChange: (state, action) => {
      const { value } = action.payload;
      state.dimensions.weightSmall = value;
    },
    onReturnPolicyChange: (state, action) => {
      const { type } = action.payload;
      state.returnPolicy = type;
    },
    onEditOffer: (_, action: { payload: { offer: IVisionOffer } }) =>
      onEditOffer(action.payload.offer),
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionOfferShippingPrice.pending, (state) => {
      state.shippingPriceError = null;
    });
    builder.addCase(getVisionOfferShippingPrice.rejected, (state) => {
      state.shippingPriceError = VisionOfferShippingError.NoRates;
    });
    builder.addCase(getVisionOfferShippingPrice.fulfilled, (state, action) => {
      const { price } = action.payload;
      state.shippingPrice = price.price / 100;
    });
    builder.addCase(getRequestForOffer.fulfilled, (state, action) => {
      state.request = parseVisionRequest(action.payload);
    });
    builder.addCase(getCurrencyCodeAndShippingProfiles.fulfilled, (state, action) => {
      const { currency, shippingProfiles } = action.payload;
      state.price.currencyCode = currency.currencyCode as CurrencyCode;
      state.price.currencySymbol = currency.currency;
      if (shippingProfiles.profiles.length > 0) {
        const selectedProfile = shippingProfiles.profiles[0];
        state.shippingProfileId = selectedProfile?.id;
      }
    });
    builder.addCase(getVisionOffer.fulfilled, (state, action) => {
      const offer = parseVisionOffer(action.payload, VisionOffersTab.Pending);
      state.dimensions = offer.dimensions;
      state.price = offer.price;
      state.shippingPrice = offer.shippingPrice.value;
      state.returnPolicy = offer.returnPolicy.type;
      state.comments = offer.sellerNote;
      state.shippingProfileId = offer.shippingProfileId;
      state.tariffNumber = offer.tariffNumber || '';
      state.id = offer.id;
    });
  },
});
