import { createAsyncThunk } from '@reduxjs/toolkit';

import { SpecificVisionApi } from './SpecificVisionApi';
import { IStore2 } from 'types';

const api = new SpecificVisionApi();

export const getSpecificVision = createAsyncThunk(
  'async/getSpecificVision',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return api.getSpecificVision(token, id);
  },
);

export const requestToBuyVision = createAsyncThunk(
  'async/requestToBuyVision',
  async (input: { id: string; isOwner: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { category, images, notes, shippingAddress } =
      state.specificVision.requestToBuyVisionForm;

    const { id } = input;

    return api.requestToBuyVision(token, id, category, notes, images, shippingAddress.id || '');
  },
);

export const getVisionsCategories = createAsyncThunk(
  'async/getVisionsCategories',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getVisionCategories(token);
  },
);
