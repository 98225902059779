import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IGenerateVisionState } from 'types';
import { generateVision, onVisionPublicToggle } from './GenerateVisionActions';
import { StorageSrv } from 'services/StorageSrv';

const initialState: IGenerateVisionState = {
  id: '',
  isVisibleToPublic: true,
  hasGenerateError: false,
  results: null,
  promptDetails: { text: '', image: null },
  isLoading: false,
  isFirstGenerate: StorageSrv.visions.isFirstGenerate(),
};

export const GenerateVisionSlicer = createSlice({
  name: 'generateVision',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(generateVision.pending, (state) => {
      state.isLoading = true;
      state.hasGenerateError = false;
    });
    builder.addCase(generateVision.fulfilled, (state, action) => {
      const { visions, generationId } = action.payload;
      state.promptDetails = action.meta.arg;
      state.id = generationId;
      state.results = visions;
      state.isLoading = false;
      StorageSrv.visions.set();
    });
    builder.addCase(generateVision.rejected, (state) => {
      state.hasGenerateError = true;
    });
    builder.addMatcher(
      isAnyOf(onVisionPublicToggle.pending, onVisionPublicToggle.rejected),
      (state) => {
        state.isVisibleToPublic = !state.isVisibleToPublic;
      },
    );
  },
});
