import { FC, useState } from 'react';
import classNames from 'classnames';
import {
  BasketIcon,
  CommentIcon,
  FilledHeartIcon,
  HeartIcon,
  RepostIcon,
  Share,
} from 'tedooo-web-design-system/icons';
import Lottie from 'react-lottie';
import { Span } from 'tedooo-web-design-system/ui';

import Animation from 'lotties/light-like-lottie.json';
import { DecorativeImage } from 'components/decorativeImage';
import './actionsMenu.scss';
import { IPostButtons } from 'types';
import { translations } from 'translations/en';

const ActionsMenu: FC<IPostButtons> = ({
  onLike,
  isLiked,
  isMojo,
  productDetails,
  isReposted,
  onShare,
  onRepostActionClick,
  onCommentActionClick,
}) => {
  const [isAnimationShown, setIsAnimationShown] = useState(false);

  const likeText = isLiked ? translations.COMMON.LIKED : translations.COMMON.LIKE;

  const repostText = isReposted ? translations.FEED.POST.REPOSTED : translations.FEED.POST.REPOST;

  const onLikeClick = (e: any) => {
    e.stopPropagation();

    if (!isLiked) {
      setIsAnimationShown(true);
    } else {
      setIsAnimationShown(false);
    }
    onLike();
  };

  const onShareClick = (e: any) => {
    e.stopPropagation();
    onShare();
  };

  const onCommentClick = (e: any) => {
    e.stopPropagation();
    onCommentActionClick();
  };

  const onRepostClick = (e: any) => {
    e.stopPropagation();
    onRepostActionClick();
  };

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const icon = isLiked ? <FilledHeartIcon className="icon" /> : <HeartIcon className="icon" />;

  const onAnimationFinish = () => {
    setIsAnimationShown(false);
  };

  return (
    <div className="post-actions-menu-container">
      <div className="post-buttons-container">
        <div className={classNames('decorative-image', { filled: isLiked })} onClick={onLikeClick}>
          {isAnimationShown && (
            <div className="lottie-container">
              <Lottie
                options={lottieOptions}
                speed={1.5}
                height={28}
                width={45}
                isStopped={!isAnimationShown}
                eventListeners={[{ eventName: 'complete', callback: onAnimationFinish }]}
              />
            </div>
          )}
          {!isAnimationShown && icon}
          <Span text={likeText} className="text" />
        </div>
        <DecorativeImage
          onClick={onCommentClick}
          text={translations.FEED.POST.COMMENT}
          icon={<CommentIcon className="icon" />}
        />
        {!isMojo && (
          <DecorativeImage
            onClick={onRepostClick}
            text={repostText}
            icon={<RepostIcon className="icon" />}
            className={classNames('repost-button', { filled: isReposted })}
          />
        )}
        {isMojo && productDetails && (
          <DecorativeImage onClick={() => {}} text="Buy" icon={<BasketIcon className="icon" />} />
        )}
        <DecorativeImage
          text={translations.COMMON.SHARE}
          icon={<Share className="icon" />}
          onClick={onShareClick}
        />
      </div>
    </div>
  );
};

export default ActionsMenu;
