import { createSelector } from '@reduxjs/toolkit';

import { FeatureFlags, IPremiumDetails, IStore2, PremiumPlanOptions, PremiumStatus } from 'types';
import { getDefaultAvatar, mapPopupKeyToId } from './helper';

const DEFAULT_PREMIUM_DETAILS: IPremiumDetails = {
  nextBillingDate: 0,
  userPremiumPlan: PremiumPlanOptions.none,
  premiumStatus: PremiumStatus.TryFreeMonth,
  payoutProvider: null,
  managePlanUrl: '',
  monthlySubscriptionUrl: '',
  yearlySubscriptionUrl: '',
  isSubscriptionAutoRenew: null,
};

const tokenSelector = (state: IStore2) => state.user.token || null;

const avatarSelector = (state: IStore2) => state.user.avatar;

const isAvatarLoading = (state: IStore2) => state.user.isAvatarLoading;

const loggedUserIdSelector = (state: IStore2) => state.user.id;

const isSubscribeSelector = (state: IStore2) => state.user.isPremium;

const loggedUserNameSelector = (state: IStore2) => state.user.username;

const isShippoConnectedSelector = (state: IStore2) => state.user.isShippoConnected;

const loggedUserFullNameSelector = (state: IStore2) => state.user.fullName;

const loggedInUserShopIdSelector = (state: IStore2) => state.user.loggedInUserShopId;

const shouldShowOnboarding = (state: IStore2) => state.user.shouldShowOnboarding;

const shopNameSelector = (state: IStore2) => state.user.shopDetails?.name || '';

const introPopupKeySelector = (state: IStore2) => state.config.introPopupKey;

const orderIdToShowSelector = (state: IStore2) => state.user.orderIdToShow;

const totalOrdersSelector = (state: IStore2) => state.user.totalPendingOrders;

const premiumDetailsSelector = (state: IStore2) => state.user.premiumDetails;

const featureFlagsSelector = (state: IStore2) => state.config.featureFlags;

const shouldDisplayLegacyPremiumSelector = createSelector(
  [featureFlagsSelector],
  (featureFlags) => {
    return !featureFlags.find((flag) => flag === FeatureFlags.PremiumUi);
  },
);

const getPremiumDetailsSelector = createSelector(
  [premiumDetailsSelector, tokenSelector],
  (premiumDetails) => {
    if (!premiumDetails) {
      return DEFAULT_PREMIUM_DETAILS;
    }

    return premiumDetails;
  },
);

const defaultAvatarSelector = createSelector(
  [avatarSelector, loggedUserIdSelector],
  (avatar, id) => {
    if (!avatar) {
      return getDefaultAvatar(id || '');
    }

    return avatar;
  },
);

const getIntroPopupSelector = createSelector([introPopupKeySelector], (key) => {
  return mapPopupKeyToId[key || ''] || null;
});

export const UserSelector = {
  tokenSelector,
  totalOrdersSelector,
  avatarSelector,
  isAvatarLoading,
  loggedUserIdSelector,
  loggedUserNameSelector,
  loggedUserFullNameSelector,
  shopNameSelector,
  isSubscribeSelector,
  loggedInUserShopIdSelector,
  defaultAvatarSelector,
  shouldShowOnboarding,
  orderIdToShowSelector,
  getIntroPopupSelector,
  isShippoConnectedSelector,
  getPremiumDetailsSelector,
  featureFlagsSelector,
  shouldDisplayLegacyPremiumSelector,
};
