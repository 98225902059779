import { FC } from 'react';
import classNames from 'classnames';

import './commentReactions.scss';
import { ICommentReaction } from 'types';
import { Span } from 'components/helpers';
import { translations } from 'translations/en';

const { REPLY, LIKED, LIKE } = translations.COMMON;

const CommentReactions: FC<ICommentReaction> = ({
  onReplyClick,
  timePosted,
  isLiked,
  onLikeClick,
}) => {
  const likeText = isLiked ? LIKED : LIKE;

  const onReplyCommentClick = (e: any) => {
    e.stopPropagation();
    onReplyClick();
  };

  const onLike = (e: any) => {
    e.stopPropagation();
    onLikeClick();
  };

  return (
    <div className="comment-reaction-container">
      <div className="reply-text-container">
        <Span text={timePosted} className="date" />
        <div className="line" />
        <Span text={likeText} onClick={onLike} className={classNames('text', { liked: isLiked })} />
        <Span text={REPLY} onClick={onReplyCommentClick} className="text" />
      </div>
    </div>
  );
};

export default CommentReactions;
