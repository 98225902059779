import { FC, useMemo } from 'react';
import ReactLinkify from 'linkify-react';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';

import { IMention } from 'types';

interface ILinkify {
  text: string;
  mentions?: IMention[];
  keywords?: string[];
  className?: string;
}

const Linkify: FC<ILinkify> = ({ text, mentions, keywords = [], className }) => {
  const onClick = (e: any) => {
    e.stopPropagation();
  };

  const renderUrl = ({ attributes, content }: any) => {
    const { href } = attributes;

    return (
      <a target="blank" href={href} onClick={onClick} className="link">
        {content}
      </a>
    );
  };

  const renderKeyword = ({ content }: any) => {
    const slicedText = content.slice(1);

    if (keywords.indexOf(slicedText) !== -1) {
      return <span className="highlighted-text">{slicedText}</span>;
    }

    return <>{content}</>;
  };

  const markKeywords = () => {
    let spliceText = [text];

    keywords.forEach((keyword) => {
      spliceText = spliceText.flatMap((item) => {
        if (keywords.indexOf(item.toLowerCase()) !== -1) {
          return item;
        }

        return item.split(new RegExp(`(${keyword})`, 'gi'));
      });
    });

    return spliceText
      .filter((item) => !!item)
      .map((item) => {
        if (keywords.findIndex((keyword) => keyword.toLowerCase() === item.toLowerCase()) !== -1) {
          return `#${item}`;
        }

        return item;
      })
      .join('');
  };

  const formattedText = useMemo(() => markKeywords(), [keywords, text]);

  const renderMention = ({ content }: any) => {
    let item = <span>{content}</span>;

    const selectedMention = mentions?.find((mention) => `@${mention?.username}` === content);

    if (selectedMention) {
      item = (
        <a href={selectedMention.link} className="link">
          {content}
        </a>
      );
    }

    return item;
  };

  const options = {
    render: { url: renderUrl, mention: renderMention, hashtag: renderKeyword },
  };

  return (
    <span className={className}>
      <ReactLinkify options={options}>{formattedText}</ReactLinkify>
    </span>
  );
};

export default Linkify;
