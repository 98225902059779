import { Popover } from 'tedooo-web-design-system/ui';
import { FC, useState } from 'react';
import { MenuIcon } from 'tedooo-web-design-system/icons';
import classNames from 'classnames';

import { Avatar } from 'components/helpers';
import './dropMenu.scss';
import Dropdown from './dropdown/Dropdown';
import { IProfileDropMenu, ProfileMenuOptions } from 'types';
import { mapProfileMenuOptionToRoute, mapProfileOptionToPopupId } from '../../helper';
import { AnalyticsEvents } from 'services/AnalyticsEvents';

const DropMenu: FC<IProfileDropMenu> = ({
  onNavigate,
  onSignOut,
  avatar,
  userName,
  totalCompletedDraftSteps,
  isAvatarLoading,
  onMyPostsClick,
  onFavoritesClick,
  onAvatarChange,
  onViewProfile,
  showPopup,
}) => {
  const [visible, setVisible] = useState(false);

  const onVisibleChange = (updatedVisible: boolean) => {
    setVisible(updatedVisible);
  };

  const onSignOutClick = () => {
    setVisible(false);
    onSignOut();
  };

  const onOptionClick = (option: ProfileMenuOptions) => {
    if (option === ProfileMenuOptions.Posts) {
      onMyPostsClick();
    } else if (option === ProfileMenuOptions.Favorites) {
      onFavoritesClick();
    } else {
      const route = mapProfileMenuOptionToRoute[option];

      if (!route) {
        showPopup({ id: mapProfileOptionToPopupId[option] });
      } else {
        if (option === ProfileMenuOptions.Visions) {
          AnalyticsEvents.onEnterVisionsPage('more');
        }
        onNavigate(route);
      }
    }
    setVisible(false);
  };

  const onProfileClick = () => {
    onViewProfile();
    setVisible(false);
  };

  const Content = (
    <Dropdown
      onViewProfile={onProfileClick}
      isAvatarLoading={isAvatarLoading}
      onAvatarChange={onAvatarChange}
      onOptionClick={onOptionClick}
      onSignOut={onSignOutClick}
      avatar={avatar}
      userName={userName}
      totalCompletedDraftSteps={totalCompletedDraftSteps}
    />
  );

  return (
    <Popover
      placement="bottomRight"
      content={Content}
      overlayClassName={classNames('profile-menu-content', {
        'full-width': !!totalCompletedDraftSteps,
      })}
      trigger="click"
      visible={visible}
      showArrow={false}
      onVisibleChange={onVisibleChange}
    >
      <div className="menu-drop-button button">
        <MenuIcon />
        <Avatar src={avatar} size={32} />
      </div>
    </Popover>
  );
};

export default DropMenu;
