import './tedoooProBox.scss';
import { Button } from 'tedooo-web-design-system/ui';
import { FC } from 'react';

import tedoooProLogo from 'photos/premium/tedooo-pro-title-logo.png';
import { Image } from '../../helpers';
import { translations } from 'translations/en';
import { popupIdsOptions } from 'store/popup/types';
import { IBoostYourShopVisibilityBox } from 'types';

const { TITLE, DESCRIPTION_1, DESCRIPTION_2, BUTTON_TEXT } =
  translations.PREMIUM.BOOST_YOUR_SHOP_BOX_LEGACY;

export const TedoooProBoxLegacy: FC<IBoostYourShopVisibilityBox> = ({ showPopup }) => {
  const onClick = () => {
    showPopup({ id: popupIdsOptions.upgradeToProPopup });
  };

  return (
    <div className="tedooo-pro-box-container">
      <Image src={tedoooProLogo} className="tedooo-pro-logo-image" />
      <span className="title">{TITLE}</span>
      <span className="description">
        {DESCRIPTION_1} <span className="inner-description">{DESCRIPTION_2}</span>
      </span>
      <Button
        type="primary"
        size="medium"
        borderRadius="large"
        text={BUTTON_TEXT}
        onClick={onClick}
        isFullWidth
      />
    </div>
  );
};

export default TedoooProBoxLegacy;
