import { FC, useEffect } from 'react';
import './followShopsStep.scss';
import { Button } from 'tedooo-web-design-system/ui';

import FollowShopsSkeleton from 'components/skeletons/followShopsSkeleton/FollowShopsSkeleton';
import { translations } from 'translations/en';
import FollowShopsCard from './FollowShopsCard/FollowShopsCard';
import { IFollowShopsStep, IOnboardingShop } from 'types';

const FollowShopsStep: FC<IFollowShopsStep> = ({
  shops,
  onShopClick,
  onBackClick,
  onContinueClick,
  getShops,
}) => {
  useEffect(() => {
    getShops();
  }, []);

  const list = shops?.length ? shops : [null, null, null, null];

  const isButtonDisabled = !shops;

  const itemContent = (shop: IOnboardingShop | null, index: number) => {
    if (!shop) {
      return <FollowShopsSkeleton key={index} />;
    }

    const onClick = () => onShopClick({ shopId: shop.id });

    return <FollowShopsCard key={shop.id} shopDetails={shop} onClick={onClick} />;
  };

  return (
    <div className="follow-shops-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.FOLLOW_SHOPS_STEPS.TITLE}</span>
        <span className="description">
          {translations.ONBOARDING.FOLLOW_SHOPS_STEPS.DESCRIPTION}
        </span>
      </div>
      <div className="content-container">{list.map((shop, index) => itemContent(shop, index))}</div>
      <div className="buttons-container">
        <Button
          isFullWidth
          type="primary"
          size="large"
          borderRadius="large"
          disabled={isButtonDisabled}
          text={translations.COMMON.CONTINUE}
          className="continue-button-interests"
          onClick={onContinueClick}
        />
        <Button
          isFullWidth
          type="secondary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.BACK}
          className="back-button-interests"
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default FollowShopsStep;
