import { CurrencyCode } from 'constant/currencies';
import { Countries as CountryTypes } from 'constant/countries';
import { ServerStatus, ContactOptions, IShippingProfileResponse } from 'types';
import { LegacyPaymentMethods } from 'constant/paymentMethods';
import { DecodeProductCsvResponse, Price } from '../../graphql/generated/graphql';

export interface ICreateShopState {
  csvUploadInformation: CsvUploadInformation;
  step: CreateShopSteps;
  specificStep: EditShopSpecificSteps | null;
  shopName: string;
  shopDescription: string;
  phoneNumber: string;
  email: string;
  country: CountryTypes;
  coverPhoto: string;
  legacyPayoutMethods: ILegacyPayoutMethod[];
  payoutMethods: IPayoutMethod[];
  serverStatus: ServerStatus | null;
  shopId: string | null;
  currencyCode: CurrencyCode;
  isFromOnboarding: boolean;
  isLegacyPayoutsAvailable: boolean;
  isPaypalEnabled: boolean;
  isStripeEnabled: boolean;
  billingCountry: CountryTypes;
  payoutLinkToNavigate: string | null;
  isPaypalLinkLoading: boolean;
  isStripeLinkLoading: boolean;
  productCategories: string[][];
  draftDetails: {
    currentStep: number;
    totalStepsCompleted: number;
    shouldDisplayInFeed: boolean;
  } | null;
}

export enum ListingOption {
  Create = 'create',
  Edit = 'edit',
  Duplicate = 'duplicate',
}

export interface IDigitalFileInfo {
  key: string;
  name: string;
  size: number;
}

export enum DiscountTypes {
  Fixed = 'fixed',
  Percentage = 'percent',
}

export interface IProductShippingInformation {
  returnPolicy: { type: ReturnPolicyType; title: string; description: string } | null;
  dimensions: IProductDimensions;
  shippingProfileId: string | null;
  processingTime: { min: number; max: number };
  countries: string[];
  originCountry: string;
}

export interface IProductDimensions {
  length: number;
  width: number;
  height: number;
  weightBig: number;
  weightSmall: number;
  isMetric: boolean;
}

export interface IProductDimensionsResponse {
  length: number;
  width: number;
  height: number;
  weightBig: number;
  weightSmall: number;
  isMetric: boolean;
}

export interface IPayoutMethod {
  type: PayoutMethods;
  email: string | null;
  isActive: boolean;
}

export interface ILegacyPayoutMethod {
  type: LegacyPaymentMethods;
  link: string;
}

export enum EditShopSpecificSteps {
  Shippo,
  Payouts,
  LegacyPayments,
  AddListing,
  EditListing,
}

export enum CreateShopSteps {
  Initial = 1,
  ShopInfo,
  ContactDetails,
  Payouts,
  LegacyPayments,
}

export enum PayoutMethods {
  Stripe = 'stripe',
  Paypal = 'paypal',
}

export enum ReturnPolicyType {
  NoReturn = 'NO_RETURN',
  ReturnAndExchange30 = 'RETURN_EXCHANGE_30',
  ReturnAndExchange90 = 'RETURN_EXCHANGE_90',
  Return30 = 'RETURN_30',
}

export interface ICreateShopKeyword {
  value: string;
  isActive: boolean;
}

export interface IGetShopInformationResponse {
  products: IProductShopResponse[];
  legacyPayoutMethods: ILegacyPayoutMethodResponse[];
  availablePayouts: AvailablePayoutsResponse[];
  payments: { [provider: string]: { email: string; active?: boolean } };
  email?: string;
  phoneNumber?: string;
  currencyCode: CurrencyCode;
  shippingProfiles: IShippingProfileResponse[];
  isShippingConnected: boolean;
  country: string;
}

export enum AvailablePayoutsResponse {
  Stripe = 'stripe',
  Paypal = 'paypal',
  Legacy = 'legacy',
}

export interface ILegacyPayoutMethodResponse {
  provider: LegacyPaymentMethods;
  link: string;
}

export interface IProductShopResponse {
  currency: string;
  currencyCode: string;
  description: string;
  id: string;
  imageUrl: string;
  originalPrice: number;
  price: number;
  title: string;
  shippingInformation?: IProductShippingInformationResponse;
  tariff?: string;
  selectedCategory?: string[];
  keywords?: string[];
  isDigital?: boolean;
  digitalFileInformation?: IDigitalFileInfo[];
  quantity?: number;
  variants?: string[];
  instructions?: string;
}

export interface ICreateShopProductFromCsv {
  images: string[];
  title: string;
  description: string;
  price: Price;
  keywords: string[];
  quantity?: number;
}

export interface IdGenericResponse {
  id: string;
}

export interface ICreateShopApi {
  createShop(token: string, requestFields: ICreateShopRequest): void;

  editSpecificListing(token: string, productId: string, body: ICreateShopProduct): Promise<void>;
  addItemToCatalog(
    token: string,
    shopId: string,
    body: ICreateShopProduct,
  ): Promise<IdGenericResponse>;

  getProductCategories(token: string): Promise<{ categories: string[][] }>;

  uploadProductsFromCsv(
    shopId: string,
    products: ICreateShopProductFromCsv[],
    token: string,
  ): Promise<string[]>;

  getProductsFromCsv(
    token: string,
    csv: string,
    currencyCode: string,
  ): Promise<DecodeProductCsvResponse>;
}

export enum IImageContainerTypes {
  Main,
  Side,
  Top,
  Upside,
  Extra,
}

export interface CsvUploadInformation {
  total: number;
  totalSuccess: number;
  uploadPercentage: number | null;
  productFromCsv: ICreateShopProductFromCsv[];
  requestId: string;
}

export interface ICreateShopCatalogItem {
  id: string;
  images: IImageContainer[];
  category: string[];
  keywords: string[];
  quantity: number | null;
  isDigital: boolean;
  digitalFiles: IDigitalFileInfo[];
  title: string;
  description: string;
  price: number;
  priceAfterDiscount: number;
  saleAmount: number;
  discountType: DiscountTypes;
  currency: CurrencyCode;
  shippingProfileId: string | null;
  dimensions: IProductDimensions;
  returnPolicy: ReturnPolicyType | null;
  tariffNumber: string;
}

export interface IProductShippingInformationResponse {
  profileId: string;
  isDigital: boolean;
  countries: string[];
  origin: { country: string };
  workingDays: { min: number; max: number };
  dimensions: IProductDimensionsResponse;
  returnPolicy: { title: string; description: string; type: ReturnPolicyType };
}

export interface ICreateShopProduct {
  images: string[];
  title: string;
  description: string;
  isDigital: boolean;
  selectedCategory: string[];
  quantity: number;
  price: {
    price: number;
    currency: string;
    currencyCode: string;
  };
  originalPrice: number;
  keywords: string[];
  tariff?: string;
  shippingInformation?: {
    profileId: string;
    returnPolicy: ReturnPolicyType;
    dimensions: IProductDimensionsResponse;
  };
  digitalFiles?: string[];
}

export interface ICreateShopRequest {
  shop: {
    name: string;
    description: string;
    foundedCountry: string;
    coverPhoto: string;
    phoneNumber: string;
    email: string;
    paymentProviders: { provider: string; link: string }[];
    id: string | null;
    companyType: string;
    currencyCode: string;
  };
}

export interface ICreateShopResponse {
  _id: string;
}

export interface IUpdateShopBody {
  location?: {
    country: string;
  };
  deliverTo?: string[];
  contactInfo?: { type: ContactOptions; number: string }[];
  paymentMethods?: { provider: string; link: string }[];
  currencyCode?: string;
}

export enum PaypalErrorResponse {
  PaymentNotReceivable = 5,
  EmailNotVerified = 4,
}

export interface IImageContainer {
  type: IImageContainerTypes;
  listingImage: string | null;
}
