import { Middleware } from 'redux';

import { reviewOrder, withdrawOrder } from './OrdersActions';
import { declineOrder, markOrderAsArrived } from '../specificOrder/SpecificOrderActions';
import { PopupSlicer } from '../popup/PopupActions';
import { OrdersSlicer } from './OrdersSlicer';
import { MessageIds, popupIdsOptions } from 'types';
import { PayoutsSlicer } from '../payouts/PayoutsSlicer';
import { SpecificOrderSlicer } from '../specificOrder/SpecificOrderSlicer';
import { MessageSlicer } from '../message/MessageActions';

export const ordersMiddleware: Middleware =
  ({ dispatch }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case withdrawOrder.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case reviewOrder.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        dispatch(MessageSlicer.actions.show({ id: MessageIds.orderReviewed }));
        break;

      case markOrderAsArrived.fulfilled.type:
      case declineOrder.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case OrdersSlicer.actions.updatePaidPaymentMethod.type:
        const { orderId, username, avatar, hasDigitalFiles } = action.payload;
        dispatch(PayoutsSlicer.actions.reset());
        dispatch(PopupSlicer.actions.hide());
        dispatch(
          SpecificOrderSlicer.actions.updatePaidPaymentMethod({
            username,
            avatar,
            hasDigitalFiles,
          }),
        );
        dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.paymentSuccessPopup, orderId }));
        break;

      default:
        break;
    }

    return next(action);
  };
