import { createAsyncThunk } from '@reduxjs/toolkit';

import { MojosApi } from './MojosApi';
import { IStore2 } from '../types';

const api = new MojosApi();

export const getMojos = createAsyncThunk(
  'async/getMojos',
  async (
    input: { details: { mojoId: string | null; userId: string | null } | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { paginationToken } = state.mojos;

    const { details } = input;
    let listId = 'MAIN';
    let paginationId = paginationToken;
    if (details) {
      const { mojoId, userId } = details;

      if (mojoId) {
        listId = 'FROM_ID';
      }
      if (userId) {
        listId = 'USER_ID';
      }

      const id = userId || mojoId;

      if (id) {
        paginationId = `1:${id}`;
      }
    }

    return api.getMojos(paginationId, listId, token || '');
  },
);
