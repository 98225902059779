import { IInterestItem, UserProfile, IInterests } from 'types';
import { translations } from 'translations/en';
import sellerCardImage from 'photos/shopMainGoalStep.png';
import buyerCardImage from 'photos/lensMainGoalStep.png';

const { BUYER, SELLER } = translations.ONBOARDING.MAIN_GOAL_STEP.MAIN_GOAL_CARD;

export const mapUserProfileToTitle: { [key in UserProfile]: string } = {
  [UserProfile.Buyer]: BUYER.TITLE,
  [UserProfile.Seller]: SELLER.TITLE,
};

export const mapUserProfileToDescription: { [key in UserProfile]: string } = {
  [UserProfile.Buyer]: BUYER.DESCRIPTION,
  [UserProfile.Seller]: SELLER.DESCRIPTION,
};

export const mapUserProfileToImage: { [key in UserProfile]: string } = {
  [UserProfile.Buyer]: buyerCardImage,
  [UserProfile.Seller]: sellerCardImage,
};

export const getFilteredInterests = (interests: IInterests, searchText: string) => {
  return Object.keys(interests).reduce((filtered: IInterests, interest: string) => {
    const interestsItems = interests[interest].filter(
      (interestItem: IInterestItem) =>
        interestItem.text.toLowerCase().includes(searchText.toLowerCase()) &&
        !interestItem.isSelected,
    );
    if (interestsItems.length > 0) {
      filtered[interest] = interestsItems;
    }

    return filtered;
  }, {});
};
