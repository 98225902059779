import { Middleware } from 'redux';

import {
  getBasket,
  getShopBasketShippingRate,
  onAddItemToBasket,
  onRemoveItemFromBasket,
} from './BasketActions';
import { MessageSlicer } from '../message/MessageActions';
import { MessageIds } from 'types';

export const basketMiddleware: Middleware =
  ({ dispatch }: any) =>
  (next) =>
  (action = {}) => {
    switch (action.type) {
      case onAddItemToBasket.fulfilled.type:
        dispatch(getBasket());
        break;
      case onRemoveItemFromBasket.fulfilled.type:
        const { checkoutId, shouldFavorite } = action.meta.arg;
        dispatch(getShopBasketShippingRate({ checkoutId }));
        if (!shouldFavorite) {
          dispatch(MessageSlicer.actions.show({ id: MessageIds.removeItemFromBasket }));
        }
        break;

      default:
        break;
    }

    return next(action);
  };
