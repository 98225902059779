import { Middleware } from 'redux';

import { IStore2, MessageIds, popupIdsOptions } from 'types';
import { PopupSlicer } from '../popup/PopupActions';
import { SpecificOrderSlicer } from './SpecificOrderSlicer';
import { UserSlicer } from '../user/UserActions';
import {
  getSpecificOrder,
  sendEmailDigitalFiles,
  validateBuyerShippingAddress,
} from './SpecificOrderActions';
import { PopupsSrv } from 'services/PopupsSrv';
import { ShippingSlicer } from '../shipping/ShippingSlicer';
import { MessageSlicer } from '../message/MessageActions';

export const specificOrderMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case PopupSlicer.actions.hide.type:
        const state: IStore2 = getState();

        if (PopupsSrv.checkIfDisplayed(popupIdsOptions.addTrackingNumberPopup, state.popup.ids)) {
          dispatch(SpecificOrderSlicer.actions.resetCarrierOptions());
        }
        break;
      case UserSlicer.actions.updateTotalPendingOrders.type:
        const state2: IStore2 = getState();

        const { orderId } = action.payload;

        const { avatar } = state2.user;

        const { id } = state2.specificOrder;

        if (id && id === orderId) {
          dispatch(getSpecificOrder({ orderId: id, userAvatar: avatar || '' }));
        }
        break;

      case validateBuyerShippingAddress.rejected.type:
        const { code } = action.payload;

        const { orderId: specificOrderId } = action.meta.arg;

        if (code === 422) {
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.validateBuyerShippingAddressPopup,
              orderId: specificOrderId,
            }),
          );
        } else {
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.validateBuyerShippingAddressPopup,
              orderId: specificOrderId,
            }),
          );
        }
        break;

      case validateBuyerShippingAddress.fulfilled.type:
        const storeState: IStore2 = getState();

        const { ids } = storeState.popup;

        const { newAddress, newCity, newCountry, newPostalCode, newState } = action.payload;

        const addressSuggestion = {
          address: newAddress,
          state: newState,
          city: newCity,
          country: newCountry,
          postalCode: newPostalCode,
        };

        const { streetAddress, country, city, postalCode } =
          storeState.shipping.shippingAddressForm;

        const specificId = storeState.specificOrder.orderDetails
          ? storeState.specificOrder.id
          : null;

        if (
          orderId &&
          (streetAddress !== newAddress ||
            city !== newCity ||
            country !== newCountry ||
            postalCode !== newPostalCode)
        ) {
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.confirmBuyerShippingAddressPopup,
              addressSuggestion,
              orderId: specificId,
            }),
          );
        }
        if (
          PopupsSrv.checkIfDisplayed(popupIdsOptions.editBuyerShippingAddressPopup, ids) &&
          ids.length === 1
        ) {
          dispatch(PopupSlicer.actions.hide());
        }
        break;

      case ShippingSlicer.actions.updateRecipientAddress.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case sendEmailDigitalFiles.fulfilled.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.emailSent }));
        break;

      default:
        break;
    }

    return next(action);
  };
