import { createSelector } from '@reduxjs/toolkit';

import { IShippingProfile, IStore2, PayoutMethods } from 'types';
import { mapPayoutTypeToSupportedCountries } from 'pages/createShop/helper';

const PayoutMethodsSelector = (state: IStore2) => state.createShop.payoutMethods;

const IsPaypalEnabledSelector = (state: IStore2) => state.createShop.isPaypalEnabled;

const IsStripeEnabledSelector = (state: IStore2) => state.createShop.isStripeEnabled;

const BillingCountrySelector = (state: IStore2) => state.createShop.billingCountry;

const CurrentListingItemSelector = (state: IStore2) => state.listing;

const ShippingProfilesSelector = (state: IStore2) => state.shipping.shippingProfiles;

const DraftDetailsSelector = (state: IStore2) => state.createShop.draftDetails;

const getRemainingPayoutOptionsSelector = createSelector(
  [PayoutMethodsSelector, BillingCountrySelector, IsPaypalEnabledSelector, IsStripeEnabledSelector],
  (paymentMethods, billingCountry, isPaypalAvailable, isStripeAvailable): PayoutMethods[] => {
    const availableMethods: PayoutMethods[] = [];

    if (
      isStripeAvailable &&
      mapPayoutTypeToSupportedCountries[PayoutMethods.Stripe].includes(billingCountry)
    ) {
      availableMethods.push(PayoutMethods.Stripe);
    }

    if (
      isPaypalAvailable &&
      mapPayoutTypeToSupportedCountries[PayoutMethods.Paypal].includes(billingCountry)
    ) {
      availableMethods.push(PayoutMethods.Paypal);
    }

    return availableMethods.filter(
      (unconnectedMethod) =>
        !paymentMethods.find((connectedMethod) => connectedMethod.type === unconnectedMethod),
    );
  },
);

const getTotalAvailablePayoutOptionsSelector = createSelector(
  [IsPaypalEnabledSelector, IsStripeEnabledSelector],
  (isStripeAvailable, isPaypalAvailable): number => {
    let counter = 0;

    if (isPaypalAvailable) {
      counter++;
    }
    if (isStripeAvailable) {
      counter++;
    }

    return counter;
  },
);

const getSelectedShippingProfileSelector = createSelector(
  [CurrentListingItemSelector, ShippingProfilesSelector],
  (item, shippingProfiles): IShippingProfile | null => {
    const selectedProfileId = item.shippingProfileId;

    return shippingProfiles.find((profile) => profile.id === selectedProfileId) || null;
  },
);

const totalDraftStepsCompletedSelector = createSelector(
  [DraftDetailsSelector],
  (draftDetails): number => {
    return draftDetails?.totalStepsCompleted || 0;
  },
);
//
// const isContinueDisabledSelector = createSelector([currentItemsSelector], (curItems): boolean => {
//   return curItems.some((item) => !item.shippingProfileId && !item.isDigital);
// });

export const CreateShopSelector = {
  getRemainingPayoutOptionsSelector,
  getTotalAvailablePayoutOptionsSelector,
  getSelectedShippingProfileSelector,
  totalDraftStepsCompletedSelector,
};
