import {
  DiscoverListsId,
  IBasketApi,
  ICustomizationRequest,
  IGetBasketSupportedItemsResponse,
  IGetCartResponse,
  Method,
} from 'types';
import { graphQlRequest } from 'api/graphQl';
import {
  ADD_TO_BASKET_MUTATION,
  GENERATE_CHECKOUT_ID_MUTATION,
  GET_BASKET_QUERY,
  GET_BASKET_SHIPPING_RATES_QUERY,
  GET_SHIPPING_RATE_FOR_BASKET_QUERY,
  GET_TOTAL_BASKET_ITEMS_QUERY,
  REMOVE_FROM_BASKET_MUTATION,
  REMOVE_UNAVAILABLE_ITEMS_MUTATION,
  UPDATE_ITEM_CUSTOMIZATION_NOTE_MUTATION,
} from './BasketQueries';
import {
  CartCountResponse,
  CheckoutResponse,
  IdableResponse,
  IdResponse,
  ShippingEstimationResponse,
  SuccessResponse,
} from 'graphql/generated/graphql';
import { fetcher } from 'api';

export class BasketApi implements IBasketApi {
  async getBasket(token: string): Promise<IGetCartResponse> {
    const variables = { listId: DiscoverListsId.Favorites };

    const res = await graphQlRequest(token).query({
      query: GET_BASKET_QUERY,
      variables,
    });

    return res.data;
  }

  async addItemToBasket(
    token: string,
    productId: string,
    quantity: number,
    customizationRequest: ICustomizationRequest | null,
  ): Promise<IdableResponse> {
    const variables = {
      input: {
        productId,
        quantity,
        customization: customizationRequest,
      },
    };

    const res = await graphQlRequest(token).mutate({
      mutation: ADD_TO_BASKET_MUTATION,
      variables,
    });

    return res.data.addToCart;
  }

  async removeItemFromBasket(token: string, cartItemId: string): Promise<SuccessResponse> {
    const variables = {
      cartItemId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: REMOVE_FROM_BASKET_MUTATION,
      variables,
    });

    return res.data.removeFromCart;
  }

  async updateCustomizationRequest(
    token: string,
    cartItemId: string,
    customization: ICustomizationRequest,
  ): Promise<SuccessResponse> {
    const variables = {
      cartItemId,
      customization,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: UPDATE_ITEM_CUSTOMIZATION_NOTE_MUTATION,
      variables,
    });

    return res.data.editCustomizationByCartItemId;
  }

  getBasketSupportedItems(
    token: string,
    productIds: string[],
  ): Promise<IGetBasketSupportedItemsResponse> {
    const body = { productIds };

    return fetcher(`shipping/supported/items`, Method.POST, token, body);
  }

  async getShopBasketShippingRate(
    token: string,
    checkoutId: string,
  ): Promise<ShippingEstimationResponse> {
    const variables = { checkoutId };

    const res = await graphQlRequest(token).query({
      query: GET_SHIPPING_RATE_FOR_BASKET_QUERY,
      variables,
    });

    return res.data.getShippingRateForCheckout;
  }

  async getBasketShippingRates(token: string, checkoutId: string): Promise<CheckoutResponse> {
    const variables = { checkoutId };

    const res = await graphQlRequest(token).query({
      query: GET_BASKET_SHIPPING_RATES_QUERY,
      variables,
    });

    return res.data.getInstantCheckoutForCheckoutId;
  }

  async getTotalBasketItems(token: string): Promise<CartCountResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_TOTAL_BASKET_ITEMS_QUERY,
    });

    return res.data.getCartCount;
  }

  async removeUnavailableItems(
    token: string,
    shopId: string,
    shouldFavorite: boolean,
  ): Promise<SuccessResponse> {
    const variables = {
      shopId,
      addToWishlist: shouldFavorite,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: REMOVE_UNAVAILABLE_ITEMS_MUTATION,
      variables,
    });

    return res.data.removeUnavailableFromCart;
  }

  async getCheckoutIdForProduct(
    token: string,
    productId: string,
    quantity: number,
    customization: ICustomizationRequest | null,
  ): Promise<IdResponse> {
    const variables = {
      productId,
      quantity,
      customization,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GENERATE_CHECKOUT_ID_MUTATION,
      variables,
    });

    return res.data.generateCheckoutIdForProduct;
  }
}
