import { FC } from 'react';

import './followButton.scss';
import { Span } from 'components/helpers';
import { translations } from 'translations/en';
import { IFollowButton } from 'types';

const { FOLLOW, FOLLOWING } = translations.COMMON;

const FollowButton: FC<IFollowButton> = ({ onFollowToggle, isFollowing }) => {
  const followText = isFollowing ? FOLLOWING : FOLLOW;

  return (
    <div className="follow-button-container">
      <div className="follow-button" onClick={onFollowToggle}>
        <Span text={followText} />
      </div>
    </div>
  );
};

export default FollowButton;
