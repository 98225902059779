import { createAsyncThunk } from '@reduxjs/toolkit';

import { GenerateVisionApi } from './GenerateVisionApi';
import { IPromptDetails, IStore2 } from 'types';

const api = new GenerateVisionApi();

export const generateVision = createAsyncThunk(
  'async/generateVision',
  async (_input: IPromptDetails, { getState }: any) => {
    const state: IStore2 = getState();

    const { text, image } = state.visions.promptDetails;

    const { token = '' } = state.user;

    return api.generateVision(token, text, image);
  },
);

export const getSmartTags = createAsyncThunk('async/getSmartTags', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { text } = state.visions.promptDetails;

  const { token = '' } = state.user;

  return api.getSmartTags(token, text);
});

export const onVisionPublicToggle = createAsyncThunk(
  'async/onVisionPublicToggle',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { id, isVisibleToPublic } = state.generateVision;

    const { token = '' } = state.user;

    return api.onVisionPublicToggle(token, id, isVisibleToPublic);
  },
);
