export interface IPopupProps {
  isClickOutsideDisabled?: boolean;
  withOverlay?: boolean;
  className?: string;
  modalClassName?: string;
  isFullHeight?: boolean;
  isDrawerInMobile?: boolean;
  isSticky?: boolean;
  children?: any;
  hide(): void;
}

export enum PopupDeepLinkOptions {
  VacationMode = 'vacation',
  BuyVisionsCredits = 'buy-visions-credits',
  Premium = 'premium',
}
