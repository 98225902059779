import {
  IGetPaymentSessionDetailsResponse,
  ServerStatus,
  ILinkedPaymentOption,
  IPrice,
  PayoutMethods,
} from 'types';
import { CreatePaymentIntentResult, PaypalOption } from 'graphql/generated/graphql';

export interface IPayoutsState {
  stripePaymentSessionDetails: IPaymentSessionDetails | null;
  paypalCheckoutUrl: string;
  instantCheckoutDetails: {
    linkedPaymentOptions: ILinkedPaymentOption[];
    originalPrice: IPrice;
  } | null;
  isPaymentMethodsLoading: boolean;
  isPaymentLinksLoading: boolean;
  paypalPaymentServerStatus: ServerStatus | null;
  isShippingRatesError: boolean;
}

export interface IPaymentSessionDetails {
  id: string;
  clientId: string;
  paymentIntentSecret: string;
  price: IPrice;
}

export interface IPayoutApi {
  getStripePayoutDetails(
    provider: string,
    orderId: string,
    token: string,
    couponCode: string | null,
  ): Promise<IGetPaymentSessionDetailsResponse>;
  getVisionStripePayoutDetails(
    shippingRateId: string,
    offerId: string,
    token: string,
  ): Promise<CreatePaymentIntentResult>;

  getStripeDetailsByCheckoutId(
    checkoutId: string,
    shippingRateId: string | null,
    couponCode: string | null,
    token: string,
  ): Promise<CreatePaymentIntentResult>;
  getPaypalPayoutLinkByCheckoutId(
    checkoutId: string,
    shippingRateId: string | null,
    couponCode: string | null,
    token: string,
  ): Promise<PaypalOption>;
  onStripeLink(token: string): Promise<ILinkPayoutResponse>;
  deactivateStripe(token: string): Promise<void>;
  onPaypalLink(token: string): Promise<ILinkPayoutResponse>;
  deactivatePaypal(token: string): Promise<void>;
  refreshPaypal(merchantId: string, merchantIdInPayPal: string, id?: string): Promise<void>;
}

export interface IGetPaypalCheckoutUrl {
  checkoutUrl: string;
}

export interface ILinkPayoutResponse {
  linkUrl: string;
}

export enum ThirdPartyConnectionStatus {
  Success = 'success',
  Error = 'error',
}

export enum PaymentSource {
  Order = 'order',
  Vision = 'vision',
  Basket = 'basket',
  OneTimePayment = 'one_time_payment',
  BuyNow = 'buy_now',
}

export interface IGetCheckoutDetailsFromTransaction {
  price: number;
  receiverId: string;
  description: string;
  paymentMethod: PayoutMethods | null;
  email: string;
}
