import { ChangeEvent, forwardRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { Button, CircleButton, LoaderIndicator, Textarea } from 'tedooo-web-design-system/ui';
import { Camera, XIcon } from 'tedooo-web-design-system/icons';

import { Avatar } from '../avatar/Avatar';
import './commentInput.scss';
import { translations } from 'translations/en';
import FileUploader from '../fileUploader/FileUploader';
import { Image } from '../helpers';
import MentionsDropdown from '../mentionsDropdown/MentionsDropdown';
import { getCursorOffset } from '../mentionsDropdown/helper';
import { Fonts } from 'constant/fonts';
import { ITaggedUser } from 'types';
import { useRequireToken } from 'hooks/useRequireToken';

interface ICommentInput {
  isUserSingIn: boolean;
  avatar: string | null;
  avatarSize: number;
  placeholder: string;
  maxImages: number;
  taggedUser?: ITaggedUser;
  onSubmit(text: string, taggedUsers: ITaggedUser[], images: Array<string>): void;
}

const ENTER_KEY_CODE = 13;

export const CommentInput = forwardRef<any, ICommentInput>(
  (
    { isUserSingIn, avatarSize, placeholder, avatar, onSubmit, maxImages, taggedUser },
    ref: any,
  ) => {
    const initialText = taggedUser ? `@${taggedUser.username} ` : '';

    const initialTaggedUsers = taggedUser ? [taggedUser] : [];

    const [text, setText] = useState(initialText);

    const [cursorPosition, setCursorPosition] = useState(0);

    const [taggedUsers, setTaggedUsers] = useState<ITaggedUser[]>(initialTaggedUsers);

    const [isImageLoading, setIsImageLoading] = useState(false);

    const [image, setImage] = useState<string | null>(null);

    const imageUploaderId = uuidv4();

    const resetInput = () => {
      setImage(null);
      setTaggedUsers([]);
      setText('');
    };

    const onKeyPress = (e: any) => {
      if (e.charCode === ENTER_KEY_CODE) {
        e.preventDefault();
        onSubmit(text, taggedUsers, [image || '']);
        resetInput();
      }
    };

    const onSendClick = useRequireToken((e: any) => {
      e.stopPropagation();
      onSubmit(text, taggedUsers, [image || '']);
      resetInput();
    });

    const onTagSelect = (selectedUser: ITaggedUser) => {
      setTaggedUsers([...taggedUsers, selectedUser]);
    };

    const onImageAdd = (src: string[]) => {
      setImage(src[0]);
      setIsImageLoading(false);
    };

    const onImageRemove = (e: any) => {
      e.stopPropagation();
      setImage(null);
    };

    const onImageLoadingStart = () => setIsImageLoading(true);

    const shouldShowSendButton = text !== '' || !!image;

    const suffix = !image ? (
      <FileUploader
        maxItems={maxImages}
        onImageUpload={onImageAdd}
        onImagesLoadingStart={onImageLoadingStart}
        id={imageUploaderId}
      >
        <div className="camera-icon">
          <Camera />
        </div>
      </FileUploader>
    ) : undefined;

    const onContainerClick = (e: any) => {
      e.stopPropagation();
    };

    const showImages = isImageLoading || image;

    const buttonText = taggedUser ? translations.FEED.POST.REPLY : translations.FEED.POST.POST;

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCursorPosition(e.target.selectionStart);
    };

    const calculateOffset = () => {
      const inputWidth = ref?.current?.resizableTextArea?.textArea.offsetWidth || 0;

      const parsedText = text.substring(0, cursorPosition);

      return getCursorOffset(parsedText, inputWidth, Fonts.BodySmall);
    };

    return (
      <div
        className={classNames('decorative-input-container', { 'is-reply': taggedUser })}
        onClick={onContainerClick}
      >
        <div className="input-image-container">
          <div className="input-container">
            <Avatar className="avatar" src={avatar} size={avatarSize} />
            <MentionsDropdown
              text={text}
              cursorPosition={cursorPosition}
              onTextChange={setText}
              calculateTopOffset={calculateOffset}
              onSelect={onTagSelect}
              onCursorPositionChange={setCursorPosition}
            >
              <Textarea
                id="comment"
                placeholder={placeholder}
                bordered={false}
                autoSize={{ minRows: 1 }}
                ref={ref}
                onChange={onChange}
                onKeyPress={onKeyPress}
                padding={0}
              />
            </MentionsDropdown>
            {isUserSingIn && suffix}
            {shouldShowSendButton && (
              <Button
                type="primary"
                size="small"
                borderRadius="large"
                text={buttonText}
                onClick={onSendClick}
              />
            )}
          </div>
          {showImages && (
            <div className={classNames('image-container')}>
              <div className="comment-image">
                {image && (
                  <>
                    <CircleButton
                      type="secondary-gray"
                      size="small"
                      icon={<XIcon />}
                      onClick={onImageRemove}
                      className="remove-button"
                    />
                    <Image src={image} withLoader />
                  </>
                )}
                {isImageLoading && <LoaderIndicator size={30} />}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);
