import { IShopReviewsState } from 'types';

export interface IReviewRow {
  name: string;
  value: number;
  avatar: string | null;
  title: string | null;
  id: string;
  text: string | null;
  date: number;
  images: string[];
}

export interface IImagesRow {
  maxItems: number;
  images: string[];
  onImageClick(index: number): void;
}

export interface IReviewsSection {
  list: IReviewRow[];
  onReviewsClick(): void;
}

export enum TotalRateType {
  One,
  Two,
  Three,
  Four,
  Five,
}

export interface ITotalRateItem {
  value: number;
  type: TotalRateType;
}

export interface IReviewOverview {
  avg: number;
  totalReviews: number;
  totalRates: ITotalRateItem[];
}

export interface IOverviewRateRow {
  maxTotalCount: number;
  value: number;
  type: TotalRateType;
}

export interface IShopReviews {
  reviews: IShopReviewsState | null;
  avg: number;
  totalReviews: number;
  getInitialReviews(): void;
}
