import { Mutation } from 'graphql/generated/graphql';
import { fetcher } from 'api';
import {
  IGetProductEstimatedDeliveryResponse,
  IGetSpecificProductResponse,
  ISpecificProductApi,
  Method,
} from 'types';
import { graphQlRequest } from 'api/graphQl';
import { GET_SPECIFIC_PRODUCT_QUERY, LIKE_PRODUCT_QUERY } from './ProductQueries';

export class SpecificProductApi implements ISpecificProductApi {
  async getSpecificProduct(
    productId: string,
    token?: string,
  ): Promise<IGetSpecificProductResponse> {
    const variables = { productId, paginationToken: `0:${productId}` };

    const res = await graphQlRequest(token).query({
      query: GET_SPECIFIC_PRODUCT_QUERY,
      variables,
    });

    return res.data;
  }

  getProductEstimatedDelivery(
    productId: string,
    token: string,
  ): Promise<IGetProductEstimatedDeliveryResponse> {
    return fetcher(`v3/products/delivery/${productId}`, Method.GET, token);
  }

  async toggleLikeProduct(productId: string, isLiked: boolean, token: string): Promise<void> {
    const variables = {
      productId,
      like: !isLiked,
    };

    await graphQlRequest(token).mutate<Mutation>({
      mutation: LIKE_PRODUCT_QUERY,
      variables,
    });

    return Promise.resolve();
  }
}
