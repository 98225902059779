import {
  IGroupOption,
  IGroupPrivacyOptions,
  IMessageFileDetails,
  IMessageGroupDetails,
  IMessageOrderDetails,
  IMessageReplyDetails,
  IMessageShopDetails,
  ISelectedReply,
  IMemberOption,
  IUserOption,
  MessageReceivedBaseResponse,
  MessagesStatuses,
  ReportOptions,
  ServerStatus,
  IPostMessageDetails,
  IFeedResponseApi,
  IVisionMessageDetails,
  MediaOption,
  FileReportOptions,
} from 'types';
import { MILLISECONDS } from 'constant';
import { SuccessResponse } from 'graphql/generated/graphql';

export * from './socket/types';

export enum MessageSelectionOptions {
  Forward = 'Forward',
  Delete = 'Delete',
}

export interface IChatGroupState {
  details: IMessageGroupDetails | null;
  status: ServerStatus;
}

export interface IChatShopState {
  details: IMessageShopDetails | null;
  status: ServerStatus;
}

export interface IChatGroupsState {
  [key: string]: IChatGroupState;
}

export interface IChatShopsState {
  [key: string]: IChatShopState;
}

export interface IChatState {
  shops: IChatShopsState;
  groups: IChatGroupsState;
  selectedChat: ISelectedChat | null;
  isLoading: boolean;
  isFilterActive: boolean;
  searchText: string;
  chats: IChatOption[] | null;
  suggestedGroups: IGroupOption[];
  archivedChats: IChatOption[];
  searchResults: IChatStoreSearchResults;
  createChatDetails: IStoreCreateGroup;
  selectedUploadOption: MediaOption | null;
}

export interface IMessageRestResponse extends MessageReceivedBaseResponse {
  forwarded: boolean;
  fromFullName: string;
  fromName: string;
}

export interface IMessagesResponse {
  data: IMessageRestResponse[];
  blocked: boolean;
}

export interface IShopLinkResponse {
  image: string;
  name: string;
  isPremium: boolean;
  images?: string[];
  description: string;
  _id: string;
  user: {
    avatar: string;
    name: string;
    username: string;
  };
}

export interface IGetChatFilesResponse {
  files: IChatMediaFiles[];
  hasMore: boolean;
}

export interface IGetChatLinksResponse {
  links: IChatMediaLinks[];
  hasMore: boolean;
}

export interface ICreateGroupResponse {
  _id: string;
}

export interface IChatMediaResponse {
  data: IChatMediaItemResponse[];
}

export interface IChatApi {
  getMessages(search: string, token: string): Promise<IChatSearchResponse>;
  joinGroupViaInvite(groupId: string, token: string): Promise<{ groupId: string }>;
  onAdminToggle(
    groupId: string,
    userId: string,
    shouldMakeAdmin: boolean,
    token: string,
  ): Promise<void>;
  getGroupChatPosts(groupId: string, token: string, maxId?: string): Promise<IFeedResponseApi>;
  removeMember(groupId: string, userId: string, token: string): Promise<void>;
  editGroup(
    groupId: string,
    name: string,
    avatar: string | null,
    description: string,
    privacy: IGroupPrivacyOptions | null,
    token: string,
  ): Promise<void>;
  getParticipants(
    search: string,
    groupId: string,
    skip: number,
    token: string,
  ): Promise<IChatParticipantsResponse>;
  getShopInformation(id: string, token: string): Promise<IShopLinkResponse>;
  getGroupInformation(
    id: string,
    token: string,
    isLink: boolean,
  ): Promise<IGroupInformationResponse>;
  archiveChat(chatId: string, isGroup: boolean, isArchived: boolean, token: string): Promise<any>;
  createGroup(details: IStoreCreateGroup, token: string): Promise<ICreateGroupResponse>;
  inviteUsers(groupId: string, users: string[], token: string): Promise<any>;
  getOlderMessages(
    id: string,
    isGroup: boolean,
    maxTime: number,
    token: string,
  ): Promise<IMessagesResponse>;
  getNewerMessages(
    id: string,
    isGroup: boolean,
    time: number,
    token: string,
  ): Promise<IMessagesResponse>;
  getBothDirectionMessages(
    id: string,
    isGroup: boolean,
    time: number,
    token: string,
  ): Promise<IMessagesResponse>;
  getUsers(text: string, token: string): Promise<ISearchUsersResponse[]>;
  reportUser(userId: string, option: ReportOptions, text: string, token: string): Promise<void>;
  blockUser(userId: string, token: string): Promise<void>;
  unblockUser(userId: string, token: string): Promise<void>;
  leaveGroup(groupId: string, token: string): Promise<void>;
  muteGroup(groupId: string, timeToMute: MuteChatOptions, token: string): Promise<void>;
  muteUser(userId: string, timeToMute: MuteChatOptions, token: string): Promise<void>;
  getSearchMessages(
    chatId: string,
    search: string,
    skip: number,
    token: string,
  ): Promise<IChatSearchMessageResponse>;
  getChatFiles(
    chatId: string,
    isGroup: boolean,
    token: string,
    maxId?: string,
  ): Promise<IGetChatFilesResponse>;
  getChatLinks(
    chatId: string,
    isGroup: boolean,
    token: string,
    maxId?: string,
  ): Promise<IGetChatLinksResponse>;
  getChatImages(
    chatId: string,
    isGroup: boolean,
    time: number,
    direction: LoadImagesDirection,
    limit: number,
    token: string,
  ): Promise<IChatMediaResponse>;
  getGroupLink(link: string, token: string): Promise<any>;
  unexpireMessage(messageId: string, token: string): Promise<SuccessResponse>;
  reportMessage(
    token: string,
    messageId: string,
    option: FileReportOptions,
    extra?: string,
  ): Promise<SuccessResponse>;
}

export interface IMockChatApi {
  getMessages(search: string, token: string): Promise<IChatSearchResponse>;
  getSearchMessages(search: string, token: string): Promise<IChatSearchMessageResponse>;
  archiveChat(chatId: string, isGroup: boolean, isArchived: boolean, token: string): Promise<void>;
  createGroup(details: IStoreCreateGroup, token: string): Promise<void>;
  inviteUsers(users: string[], token: string): Promise<void>;
  getUsers(text: string, token: string): Promise<ISearchUsersResponse[]>;
}

export interface IChatStoreSearchResults {
  totalSearchedMessages: number;
  searchedMessages: IChatSearchedMessage[];
  isLoading: boolean;
}

export interface IStoreCreateGroup {
  suggestedUsers: {
    search: string;
    users: IUserOption[];
    isLoading: boolean;
  };
  suggestedMembers: {
    search: string;
    users: IUserOption[];
    isLoading: boolean;
    selectedUsers: IUserOption[];
  };
  avatar: string | null;
  name: string;
  serverStatus: ServerStatus | null;
  description: string;
  privacy: IGroupPrivacyOptions | null;
}

export interface IMessageCount {
  date: number;
  count: number;
}

export interface ISelectedChat {
  id: string;
  searchMessages: ISelectedChatSearch;
  selectedMessageDate?: number;
  isMessagesLoading: boolean;
  isGroup: boolean;
  isSuperAdmin: boolean;
  isArchived: boolean;
  isMuted: boolean;
  avatar: string | null;
  shopId: string | null;
  name: string;
  messagesPrevIndex: number;
  privacy: IGroupPrivacyOptions | null;
  username: string | null;
  description: string;
  typingName: string | null;
  hasMoreOlder: boolean;
  hasMoreNewer: boolean;
  lastSeen: string;
  isBlocked: boolean;
  isAdmin: boolean;
  selectedReply: ISelectedReply | null;
  messages: IMessagesStore[];
  messagesGroupCount: IMessageCount[];
  selectedMessages: string[];
  selectionMode: MessageSelectionOptions | null;
  isReportLoading: boolean;
  totalParticipants: number;
  members: {
    totalCount: number;
    skip: number;
    isLoading: boolean;
    users: IMemberOption[];
    search: string;
    hasMore: boolean;
  };
  mediaGallery: {
    media: IChatMedia[];
    currentIndex: number;
    isLoading: boolean;
  };
  media: {
    list: IChatMedia[] | null;
    hasMore: boolean;
    isLoading: boolean;
  };
  links: {
    list: IChatMediaLinks[] | null;
    hasMore: boolean;
    isLoading: boolean;
  };
  files: {
    list: IChatMediaFiles[] | null;
    hasMore: boolean;
    isLoading: boolean;
  };
}

export interface IChatMedia {
  id: string;
  date: number;
  source: string;
  username: string;
  userId: string;
  isOwner: boolean;
  text: string;
  avatar: string;
}

export interface IChatMediaLinks {
  id: string;
  description: string;
  link: string;
  title: string;
}

export interface IChatMediaFiles {
  id: string;
  size: number;
  url: string;
  name: string;
  extension: string;
}

export interface IMessageLinkDetails {
  title: string;
  text: string;
  image: string | null;
}

export interface ITextStoryUrl {
  description: string;
  title: string;
  imageUrl?: string;
}

export interface IMessageBaseDetails {
  id: string;
  name: string;
  username: string;
  userId: string;
  avatar: string | null;
  avatarDisplayOption: AvatarDisplayOptions;
  isOwner: boolean;
  isTranslated: boolean;
  isDeleted: boolean;
  isForwarded: boolean;
  shouldDisplayName: boolean;
  userColor: string;
  type: MessagesTypes;
  status: MessagesStatuses;
  isExpired: boolean;
  expiryText: string | null;
  date: number;
  text: string;
  searchWords: string[];
  images: string[];
  postDetails: IPostMessageDetails | null;
  fileDetails: IMessageFileDetails | null;
  linkDetails: IMessageLinkDetails | null;
  orderDetails: IMessageOrderDetails | null;
  visionDetails: IVisionMessageDetails | null;
  reply: IMessageReplyDetails | null;
}

export enum AvatarDisplayOptions {
  Visible = 'visible',
  None = 'none',
  Invisible = 'invisible',
}

export interface IMessagesStore extends IMessageBaseDetails {
  groupLink: string | null;
  shopId: string | null;
}

export interface IChatSearchedMessage {
  chatName: string;
  avatar: string;
  id: string;
  text: string;
  chatId: string;
  username: string | null;
  senderName: string;
  date: number;
  isGroup: boolean;
  isSuperAdmin: boolean;
}

export interface IChatSearchResponse {
  total: number;
  data: IMessageResponse[];
}

export interface IParticipantResponse {
  avatar: string | null;
  id: string;
  admin?: boolean;
  isFollowing: boolean;
  username: string;
  fullName: string;
}

export interface IChatParticipantsResponse {
  data: IParticipantResponse[];
  total: number;
}

export interface IChatSearchMessageItemResponse {
  hasPrevious: boolean;
  hasLater: boolean;
  indexes: number[];
  messages: IMessageRestResponse[];
}

export interface IChatSearchMessageResponse {
  data: IChatSearchMessageItemResponse;
  total: number;
}

export interface IMessageBatch {
  hasPrevMore: boolean;
  hasNextMore: boolean;
  messages: IMessagesStore[];
  searchMessageIndexes: number[];
  currentIndex: number;
}

export interface ISelectedChatSearch {
  isSearchVisible: boolean;
  search: string;
  skip: number;
  hasMore: boolean;
  isLoading: boolean;
  totalCount: number;
  messagesBatches: IMessageBatch[];
  currentMessageBatchIndex: number;
}

export interface IChatMediaItemResponse {
  uuid: string;
  date: number;
  message: string;
  from: string;
  fromName?: string;
  imageText?: string;
  avatar?: string;
}

export interface IMessageResponse {
  id: string;
  message_t: string[];
  from: string;
  fromName?: string;
  targets: string[];
  date: number;
}

export interface ISearchUsersResponse {
  _id: string;
  username: string;
  avatar: string;
  fullName: string;
}

export interface IGroupInformationResponse {
  _id: string;
  participants: number;
  public: boolean;
  description: string;
  name: string;
  image?: string;
}

export interface IGroupLinkResponse {
  url: string;
}

export interface IChatOption {
  avatar: string | null;
  isAdmin: boolean;
  isGroup: boolean;
  isSuperAdmin: boolean;
  privacy: IGroupPrivacyOptions | null;
  totalParticipants?: number;
  username: string | null;
  typingName: string | null;
  id: string;
  name: string;
  description: string;
  lastMessageDate: number;
  totalUnread: number;
  type: MessagesTypes;
  text: string;
  senderName: string;
  isOwner: boolean;
  isArchived: boolean;
  isMuted: boolean;
  status: MessagesStatuses;
  isBlocked: boolean;
}

export enum MessagesRestTypes {
  Text = 0,
  Image = 1,
  ShopLink = 2,
  Video = 5,
  File = 12,
  System = 100,
  GroupLink = 101,
  OfferUpdate = 1001,
  Vision = 1002,
  Transaction = 1003,
}

export enum MessagesTypes {
  Text = 'Text',
  Image = 'Image',
  ShopLink = 'ShopLink',
  Video = 'Video',
  System = 'System',
  OfferUpdate = 'OfferUpdate',
  File = 'File',
  Gallery = 'Gallery',
  PostLink = 'PostLink',
  Transaction = 'Transaction',
  Group = 'Group',
  Vision = 'Vision',
}

export const mapMessageRestTypeToMessageType = {
  [MessagesRestTypes.Image]: MessagesTypes.Image,
  [MessagesRestTypes.File]: MessagesTypes.File,
  [MessagesRestTypes.ShopLink]: MessagesTypes.ShopLink,
  [MessagesRestTypes.Video]: MessagesTypes.Video,
  [MessagesRestTypes.System]: MessagesTypes.System,
  [MessagesRestTypes.GroupLink]: MessagesTypes.Group,
  [MessagesRestTypes.OfferUpdate]: MessagesTypes.OfferUpdate,
  [MessagesRestTypes.Transaction]: MessagesTypes.Transaction,
  [MessagesRestTypes.Vision]: MessagesTypes.Vision,
};

export const mapMessageTypeToMessageRestType = {
  [MessagesTypes.Image]: MessagesRestTypes.Image,
  [MessagesTypes.Text]: MessagesRestTypes.Text,
  [MessagesTypes.File]: MessagesRestTypes.File,
  [MessagesTypes.ShopLink]: MessagesRestTypes.ShopLink,
  [MessagesTypes.Video]: MessagesRestTypes.Video,
  [MessagesTypes.System]: MessagesRestTypes.System,
  [MessagesTypes.Transaction]: MessagesRestTypes.Transaction,
  [MessagesTypes.Group]: MessagesRestTypes.GroupLink,
  [MessagesTypes.OfferUpdate]: MessagesRestTypes.OfferUpdate,
};

export enum LoadImagesDirection {
  Both = 'both',
  Previous = 'early',
  Next = 'later',
}

export enum MuteChatOptions {
  Hours = MILLISECONDS.HOUR * 8,
  Week = MILLISECONDS.DAY * 7,
  Always = MILLISECONDS.YEAR * 10,
}

export interface IChatItem {
  id: string;
  name: string;
  username: string;
  image: string;
  lastMessageDate: number;
  totalUnread: number;
  type: MessagesTypes;
  description: string;
  text: string;
  senderName: string;
  isOwner: boolean;
  isAdmin: boolean;
  isArchived: boolean;
  isMuted: boolean;
  isSuperAdmin: boolean;
  status: MessagesStatuses;
  isBlocked: boolean;
  totalParticipants?: number;
  privacy: IGroupPrivacyOptions | null;
}

export interface IStoryUrlParser {
  images: string[];
  text: string;
  file: IMessageFileDetails | null;
  linkDetails: IMessageLinkDetails | null;
  postDetails: IPostMessageDetails | null;
  orderDetails: IMessageOrderDetails | null;
  visionDetails: IVisionMessageDetails | null;
}
