import { find } from 'linkifyjs';

import { MediaOption } from 'types';

export const uploadOptionsOrder = [MediaOption.Photo, MediaOption.File];

export const mapOptionToText: { [key in MediaOption]: string } = {
  [MediaOption.Photo]: 'Photo/Video',
  [MediaOption.File]: 'File/Document',
};

export const getFirstLink = (text: string) => {
  return find(text)[0]?.value || null;
};
