import { Span, AutoComplete } from 'tedooo-web-design-system/ui';
import { FC, useCallback, useEffect, useState } from 'react';
import './mentionsDropdown.scss';
import { connect } from 'react-redux';

import { IStore2, ITaggedUser, ITagUser } from 'types';
import { Avatar } from 'components/avatar/Avatar';
import { searchTaggedUsers } from 'store/mentions/MentionsActions';
import { getLastWord } from './helper';
import { debounce } from 'utils';

const DEBOUNCE = 300;

interface IMentionsDropdown {
  children: any;
  text: string;
  users: ITagUser[] | null;
  cursorPosition: number;
  calculateTopOffset: () => number;
  className?: string;
  onCursorPositionChange(value: number): void;
  onTextChange(value: string): void;
  onSelect(selectedUser: ITaggedUser): void;
  searchUsers(input: { text: string }): void;
}

const MentionsDropdown: FC<IMentionsDropdown> = ({
  children,
  text,
  users,
  cursorPosition,
  calculateTopOffset,
  onTextChange,
  onCursorPositionChange,
  onSelect,
  searchUsers,
  className,
}) => {
  const [dropdownOffset, setDropdownOffset] = useState(0);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderUser = (user: ITagUser) => {
    return (
      <div className="user-container">
        <Avatar src={user.avatar} size={40} />
        <div className="text-container">
          <Span text={user.fullName} className="full-name" />
          <Span text={`@${user.username}`} className="username" />
        </div>
      </div>
    );
  };

  const dropdownOptions =
    users?.map((user) => ({
      value: user.username,
      label: renderUser(user),
    })) || [];

  const onUsersSearch = useCallback(
    debounce((searchText) => searchUsers({ text: searchText.substring(1) }), DEBOUNCE),
    [],
  );

  const onUserClick = (value: string) => {
    const lastWord = getLastWord(text.substring(0, cursorPosition));

    const textBeforeTag = text.substring(0, cursorPosition - lastWord.length);

    const textAfterTag = text.substring(cursorPosition);

    const newText = `${textBeforeTag}@${value} ${textAfterTag}`;

    const selectedUser = users?.find((user) => user.username === value);

    if (selectedUser) {
      onSelect({ userId: selectedUser.id, username: selectedUser.username });
    }
    onCursorPositionChange(cursorPosition - lastWord.length + value.length + 2);
    onTextChange(newText);
  };

  const onBlur = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      setDropdownOffset(calculateTopOffset());
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const lastWord = getLastWord(text.substring(0, cursorPosition));

    if (lastWord[0] === '@' && !isDropdownOpen) {
      setIsDropdownOpen(true);
    }
    if (lastWord[0] !== '@' && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
    if (isDropdownOpen) {
      onUsersSearch(lastWord);
    }
  }, [cursorPosition]);

  const getPopupContainer = (parentNode: HTMLElement) => parentNode.parentElement || document.body;

  return (
    <div className="mention-dropdown-container">
      <AutoComplete
        options={dropdownOptions}
        value={text}
        onBlur={onBlur}
        onChange={onTextChange}
        shouldAutofill={false}
        open={isDropdownOpen}
        onSelect={onUserClick}
        dropdownClassName={`mention-auto-complete-dropdown ${className}`}
        getPopupContainer={getPopupContainer}
        topOffset={dropdownOffset}
        maxWidth={448}
      >
        {children}
      </AutoComplete>
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { displayedUsers: users } = state.mentions;

  return { users };
};

const mapDispatchToProps = {
  searchUsers: searchTaggedUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentionsDropdown);
