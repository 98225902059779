import { FC } from 'react';
import './createShopErrorMessage.scss';
import { Notification } from 'tedooo-web-design-system/ui';

const CreateShopErrorMessage: FC = () => {
  return (
    <Notification
      type="error"
      placement="topRight"
      text="Failed to create shop"
      className="create-shop-notification"
    />
  );
};

export default CreateShopErrorMessage;
