import { FC } from 'react';
import { AddAvatar, Span } from 'tedooo-web-design-system/ui';
import { User } from 'tedooo-web-design-system/icons';
import './dropdown.scss';

import { translations } from 'translations/en';
import { IMenuDropdown } from 'types';
import ProfileContent from '../../profileContent/ProfileContent';
import CompleteShopBox from 'components/completeShopBox/CompleteShopBox';

const Dropdown: FC<IMenuDropdown> = ({
  onSignOut,
  onAvatarChange,
  avatar,
  userName,
  totalCompletedDraftSteps,
  isAvatarLoading,
  onOptionClick,
  onViewProfile,
}) => {
  return (
    <div className="profile-drop-menu">
      <div className="header" onClick={onViewProfile}>
        <AddAvatar
          id="menu"
          placeholder={<User />}
          isLoading={isAvatarLoading}
          size={48}
          avatar={avatar}
          onAvatarChange={onAvatarChange}
        />
        <div className="text-container">
          <Span text={userName} className="title" />
          <Span text={translations.COMMON.VIEW_PROFILE} className="text" />
        </div>
      </div>
      {totalCompletedDraftSteps > 0 && (
        <div className="draft-wrapper">
          <CompleteShopBox
            step={totalCompletedDraftSteps}
            shouldShowLater={false}
            onLaterClick={() => {}}
            size="small"
          />
        </div>
      )}
      <ProfileContent
        onSignOut={onSignOut}
        onOptionClick={onOptionClick}
        isDraftSaved={!!totalCompletedDraftSteps}
      />
    </div>
  );
};

export default Dropdown;
