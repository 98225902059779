import imageCompression from 'browser-image-compression';
import axios from 'axios';

import { ACCEPT_OPTIONS } from 'components/fileUploader/FileUploader';
import { StorageSrv } from '../services/StorageSrv';
import { fetcher } from './fetcher';
import { Method } from '../store/types';
import { getFileType } from './imageUploader';
import { GET_SIGNED_URL_QUERY, graphQlRequest } from './graphQl';
import { UploadFileTypes } from 'components/fileUploader/types';

export const a = '70c4';

const compressFile = async (file: any) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    fileType: 'image/jpeg',
  };

  return await imageCompression(file, options)
    .then((compressedFile: any) => compressedFile)
    .catch((err: any) => {
      throw new Error(err);
    });
};

export const uploadFile = async (options: any) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    file,
    onProgress = () => {},
    feature,
  } = options;

  // image type - e.g. png, jpeg
  const type = getFileType(file.name);

  let signedUrl;

  let headers;

  let url;

  // signedRequest - image link to put in aws. url - image link if success
  if (feature) {
    const {
      signedUrl: signUrl,
      headers: requestHeaders,
      url: link,
    } = await getGQLFileSignUrl(file.name, type, file.size, feature);
    signedUrl = signUrl;
    headers = requestHeaders;
    url = link;
  } else {
    const {
      signedUrl: signUrl,
      headers: requestHeaders,
      url: link,
    } = await getFileSignUrl(type, file.size);
    signedUrl = signUrl;
    headers = requestHeaders;
    url = link;
  }
  await uploadFileToAWS(signedUrl, file, headers, onProgress, onError, onSuccess);

  return url;
};

export const uploadImage = async (options: any) => {
  const {
    onSuccess = () => {},
    onError = () => {},
    file,
    onProgress = () => {},
    feature,
  } = options;

  let compressedFile = file;

  if (ACCEPT_OPTIONS.photo.includes(file.type)) {
    // compress image
    compressedFile = await compressFile(file);
  }

  // image type - e.g. png, jpeg
  const type = getFileType(compressedFile.name);

  // signedRequest - image link to put in aws. url - image link if success
  let headers: any;

  let signedUrl: string;

  let url: string;
  if (feature) {
    const {
      signedUrl: signUrl,
      headers: requestHeaders,
      url: link,
    } = await getGQLFileSignUrl(compressedFile.name, type, compressedFile.size, feature);
    headers = requestHeaders;
    url = link;
    signedUrl = signUrl;
  } else {
    const { signedRequest, url: link } = await getImageSignUrl(type);
    headers = {
      'Content-Type': `mediaType/${type}`,
      'x-amz-acl': 'public-read',
    };
    url = link;
    signedUrl = signedRequest;
  }

  await uploadFileToAWS(signedUrl, compressedFile, headers, onProgress, onError, onSuccess);

  return url;
};

// Upload file to aws
const uploadFileToAWS = async (
  signedUrl: string,
  file: any,
  headers: any,
  onProgress: any,
  onError: any,
  onSuccess: any,
) => {
  const options = {
    headers,
    onUploadProgress: (event: any) => {
      onProgress({ percent: (event.loaded / event.total) * 100 }, file);
    },
  };
  await axios
    .put(signedUrl, file, options)
    .then((result) => {
      onSuccess(file);

      return result;
    })
    .catch((err) => {
      const error = new Error('Some error');
      onError({ event: error });
      throw new Error(err);
    });
};

const getImageSignUrl = async (type: any) => {
  const token = StorageSrv.token.get();

  const body = {
    extension: type,
    chat: false,
  };

  return fetcher('aws', Method.PUT, token, body);
};

const getFileSignUrl = async (type: any, size: number) => {
  const token = StorageSrv.token.get();

  const body = {
    extensions: [
      {
        extension: type,
        size,
      },
    ],
  };

  const res = await fetcher('v2/aws', Method.POST, token, body);

  const { signedUrl } = res.urls[0];

  const { contentType } = res.urls[0];

  const awsRequestHeaders = {
    'Content-Type': contentType,
    'x-amz-acl': 'public-read',
  };

  return { url: res.urls[0].url, headers: awsRequestHeaders, signedUrl };
};

const getGQLFileSignUrl = async (
  name: string,
  type: any,
  size: number,
  feature: UploadFileTypes,
) => {
  const token = StorageSrv.token.get();

  const variables = {
    feature,
    fileSize: size,
    extension: type,
    fileName: name,
  };

  const res = await graphQlRequest(token).query({
    query: GET_SIGNED_URL_QUERY,
    variables,
  });

  const { signedUrl, headers, url } = res.data.getUploadUrl;

  const awsRequestHeaders = {};
  headers.forEach((header) => {
    awsRequestHeaders[header.key] = header.value;
  });

  return { url, signedUrl, headers: awsRequestHeaders };
};
