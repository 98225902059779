import { ReactNode } from 'react';

export enum EmptyStateOptions {
  ShopCatalog,
  ShopActivity,
  ShopReviews,
  Feed,
  ChatGroups,
  Comments,
  NoResults,
  Search,
  ChatInviteMembers,
  ChatMedia,
  ChatFiles,
  ChatLinks,
  Payouts,
  OrdersOverviewTodo,
  OrdersOverviewAwaiting,
  OrdersOverviewShipped,
  OrdersOverviewCompleted,
  OrdersOverviewDeclined,
  OrdersOverviewNoActiveOrders,
  ProductCategories,
  Favorites,
  Mojos,
  CreateShop,
  PotentialOrders,
  PendingOffers,
  Basket,
}

export interface IEmptyState {
  type: EmptyStateOptions;
  button?: ReactNode;
}
