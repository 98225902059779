import { ILinkDetails, ILinkDetailsResponse, Method } from 'types';
import { fetcher } from './fetcher';

export const getLinkDetails = async (link: string, token: string): Promise<ILinkDetails | null> => {
  try {
    const details: ILinkDetailsResponse = await fetcher('og/info', Method.POST, token, {
      link,
    });

    return {
      description: details.description,
      image: details.imageUrl,
      title: details.title,
      link,
      isVisible: true,
    };
  } catch (e) {
    return null;
  }
};
