import './feedBubblesCarousel.scss';
import { FC } from 'react';
import classNames from 'classnames';

import { MultiItemsCarousel } from 'components/carousels';
import { FeedBubblesCarouselSettings } from './FeedBubblesCarouselSettings';
import { IFeedBubble } from 'store/feed/types';
import FeedBubblesCarouselSkeleton from './feedBubblesCarouselSkeleton/FeedBubblesCarouselSkeleton';
import { ALL } from './helper';

export interface IFeedBubblesCarousel {
  selectedFeedBubble: string | null;
  feedBubbles: IFeedBubble[] | null;
  onFeedBubbleClick(input: { id: string }): void;
}

const FeedBubblesCarousel: FC<IFeedBubblesCarousel> = ({
  feedBubbles,
  onFeedBubbleClick,
  selectedFeedBubble,
}) => {
  const renderContent = () => {
    if (!feedBubbles) {
      return <FeedBubblesCarouselSkeleton />;
    }

    const defaultFeedBubble = selectedFeedBubble === null ? ALL : selectedFeedBubble;

    return (
      <MultiItemsCarousel settings={FeedBubblesCarouselSettings} responsiveArrows>
        {feedBubbles.map((bubble) => (
          <div
            className={classNames('bubble-container', {
              'is-selected': bubble.id === defaultFeedBubble,
            })}
            onClick={() => onFeedBubbleClick({ id: bubble.id })}
            key={bubble.id}
          >
            {bubble.text}
          </div>
        ))}
      </MultiItemsCarousel>
    );
  };

  return <div className="feed-bubbles-carousel-section">{renderContent()}</div>;
};

export default FeedBubblesCarousel;
