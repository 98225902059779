import { FC } from 'react';
import classNames from 'classnames';

import ImageWithLoader from './imageWithLoader/ImageWithLoader';
import './image.scss';
import { checksIsVideo } from 'utils';

interface IImage {
  src: string;
  className?: string;
  id?: string;
  onLoad?: () => void;
  onClick?: any;
  withOverlay?: boolean;
  withLoader?: boolean;
}

export const Image: FC<IImage> = ({
  src,
  withOverlay,
  onLoad,
  id,
  className,
  withLoader,
  onClick,
}) => {
  const renderContent = () => {
    if (withLoader) {
      return <ImageWithLoader onLoad={onLoad} id={id} onClick={onClick} src={src} />;
    }
    if (checksIsVideo(src)) {
      return (
        <video preload="metadata" onClick={onClick} id={id}>
          <source src={`${src}#t=0.1`} type="video/mp4" />
        </video>
      );
    }

    return <img alt="" id={id} onLoad={onLoad} src={src} onClick={onClick} />;
  };

  return (
    <div className={classNames('image-wrapper', [className])}>
      {renderContent()}
      {withOverlay && <div className="overlay" />}
    </div>
  );
};
