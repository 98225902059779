import { Middleware } from 'redux';

import { IStore2, UserProfile } from 'types';
import { CreateShopSlicer } from '../createShop/CreateShopSlicer';
import { onOnboardingFinish } from './OnboardingActions';

export const onboardingMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case onOnboardingFinish.fulfilled.type:
        const state: IStore2 = getState();

        const { mainGoal } = state.onboarding;

        const isFromOnboarding = mainGoal === UserProfile.Seller;
        dispatch(
          CreateShopSlicer.actions.updateIsFromOnboarding({
            isFromOnboarding,
          }),
        );
        break;

      default:
        break;
    }

    return next(action);
  };
