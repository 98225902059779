import { FC, useCallback } from 'react';

import { ISuggestedShop } from 'types';
import { Span } from 'components/helpers';
import { translations } from 'translations/en';
import './shopsSuggestionsSection.scss';
import { ISuggestedShopsSection } from './types';
import ShopBox from 'components/shopBox/ShopBox';

const ShopSuggestionsSection: FC<ISuggestedShopsSection> = ({
  shops,
  onShopClick,
  onFollowToggle,
}) => {
  const itemRenderer = useCallback((shop: ISuggestedShop) => {
    return (
      <ShopBox
        avatar={shop.avatar}
        username={shop.username}
        category={shop.category}
        name={shop.name}
        premium={shop.isPremium}
        isFollowing={shop.isFollowing}
        rating={shop.rating}
        images={shop.images}
        totalReviews={shop.totalReviews}
        userId={shop.userId}
        id={shop.id}
        onFollowToggle={onFollowToggle}
        onShopClick={onShopClick}
        key={shop.id}
      />
    );
  }, []);

  const title = translations.PROFILE.SHOP_SUGGESTIONS;

  return (
    <div className="section shops-section">
      <Span text={title} className="title" />
      <div className="section-content">{shops?.map(itemRenderer)}</div>
    </div>
  );
};

export default ShopSuggestionsSection;
