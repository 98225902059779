import {
  IDiscoverSectionTitle,
  IPromptDetails,
  IRequestsPreviewDetails,
  IVision,
  IVisionsCreditOption,
  IVisionsPageItem,
  UserTypes,
} from 'types';

export interface IVisionPage {
  promptDetails: IPromptDetails;
  tags: string[];
  totalCredits: number | null;
  items: IVisionsPageItem[] | null;
  isLoading: boolean;
  isFirstGenerate: boolean;
  isGenerateButtonDisabled: boolean;
  requestsPreview: {
    buyerDetails: IRequestsPreviewDetails;
    sellerDetails: IRequestsPreviewDetails | null;
  } | null;
  getSmartTags(): void;
  getVisionsPage(): void;
  onImageRemove(): void;
  onImageUpload(input: { image: string }): void;
  onTextChange(input: { text: string }): void;
  onVisionGenerate(input: IPromptDetails): void;
  onTagSelect(input: { tag: string }): void;
  getTotalCredits(): void;
  showPopup(input: { id: string }): void;
}

export interface ISmartTagsContainer {
  tags: string[];
  image: string | null;
  onTagClick(tag: string): void;
  onImageUpload(image: string): void;
  onImageRemove(): void;
}

export interface IGenerateInputContainer {
  promptDetails: IPromptDetails;
  tags: string[];
  isToolTipVisible: boolean;
  isButtonDisabled: boolean;
  onTagClick(tag: string): void;
  onTextChange(text: string): void;
  onGenerate(): void;
  onImageAdd(image: string): void;
  onImageRemove(): void;
}

export interface IBuyVisionCreditsPopup {
  visionCreditOptions: IVisionsCreditOption[] | null;
  startCheckoutProcess(input: { id: string }): void;
  getCreditOptions(): void;
  hidePopup(): void;
}

export interface IBuyVisionCreditsSuccessPopup {
  totalCredits: number;
  totalPurchasedCredits: number;
  hidePopup(): void;
}

export interface IVisionsGuidePopup {
  hidePopup(): void;
}

export enum GuideSteps {
  One,
  Two,
  Three,
  Four,
}

export interface IDiscoverVision {
  vision: IVision;
  onVisionClick(visionId: string): void;
  onTryClick(prompt: string, image: string): void;
}

export interface IVisionPageItemProps {
  titles: IDiscoverSectionTitle[];
  visions: IVision[];
  marginTop: number;
  onVisionClick(visionId: string): void;
  onTryClick(prompt: string, image: string): void;
  onListClick(): void;
}

export interface IGenerateVisionAd {
  marginTop: number;
  onImageUpload(image: string): void;
}

export interface IVisionRequestsSection {
  buyerDetails: IRequestsPreviewDetails | null;
  sellerDetails: IRequestsPreviewDetails | null;
  onMyRequestsClick(): void;
  onBuyerRequestsClick(): void;
}

export interface IVisionRequestContainer {
  details: IRequestsPreviewDetails;
  type: UserTypes;
  onClick(): void;
}
