export enum EventSources {
  MojosTab = 'mojos_tab',
  Feed = 'feed',
  Link = 'link',
  Shop = 'shop',
  Discover = 'discover',
  Profile = 'profile',
  Me = 'me',
  Chat = 'chat',
}
