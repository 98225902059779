import { FC } from 'react';
import classNames from 'classnames';

import { Image, Span } from 'components/helpers';
import Rate from 'components/rate/Rate';
import './shopBox.scss';
import { IShopBox } from 'types';
import EmptyPhoto from 'photos/emptyState/shopImageEmptyState.png';
import PremiumTag from '../premiumTag/PremiumTag';
import FollowButton from '../followButton/FollowButton';
import { useRequireToken } from 'hooks/useRequireToken';

const ShopBox: FC<IShopBox> = ({
  id,
  onShopClick,
  avatar,
  images,
  username,
  category,
  totalReviews,
  premium,
  name,
  rating,
  isFollowing,
  onFollowToggle,
  userId,
}) => {
  const onClick = () => {
    onShopClick(id);
  };

  const onFollowToggleAction = useRequireToken(() =>
    onFollowToggle({
      isFollowing,
      userId,
    }),
  );

  const onFollowClick = (e: any) => {
    e.stopPropagation();
    onFollowToggleAction();
  };

  const imagesToDisplay = [...images, EmptyPhoto, EmptyPhoto, EmptyPhoto, EmptyPhoto].slice(0, 3);

  return (
    <div key={id} className="shop-item" onClick={onClick}>
      <div className="shop-inner-container">
        {premium && <PremiumTag size="medium" />}
        <div className="images-container">
          {imagesToDisplay.map((image, i) => (
            <Image
              withOverlay
              withLoader
              src={image}
              className={classNames('shop-image', { empty: !images[i] })}
              key={i}
            />
          ))}
        </div>
        <Image src={avatar} className="avatar" />
        <div className="carousel-text-container">
          <div className="header">
            <Span className="shop-name" text={name} />
            <Rate value={rating} totalReviews={totalReviews} shouldDisplayReviews={false} />
          </div>
          <div className="details">
            <Span className="shop-username" text={username} />
            <div className="dot" />
            <Span className="shop-category" text={category} />
          </div>
          <FollowButton
            isFollowing={isFollowing || false}
            onFollowToggle={onFollowClick}
            type="secondary"
            isFullWidth
          />
        </div>
      </div>
    </div>
  );
};

export default ShopBox;
