import { gql } from '@apollo/client';

export const GET_SHIPPING_PROFILES_QUERY = gql`
  query GetShippingProfiles {
    getShippingProfiles {
      profiles {
        id
        isFixed
        name
        workingDays {
          max
          min
        }
        destinationDetails {
          countries {
            countriesType
            countries
          }
          services
          price {
            firstItem
            additionalItem
          }
          freeDomestic
          freeInternational
          deliveryTime {
            max
            min
          }
        }
        origin {
          city
          country
          postalCode
          state
          address
        }
      }
    }
  }
`;
