import { createSlice } from '@reduxjs/toolkit';

import { IMentionsState } from './types';
import { parseSearchTaggedUsers } from '../apiParser';
import { searchTaggedUsers } from './MentionsActions';
import { createPost } from '../feed/PostActions';
import { addSearchedUsersToCache, sortUsersByPriority } from './helper';

const initialState: IMentionsState = {
  displayedUsers: [],
  cachedUsers: [],
};

export const MentionsSlicer = createSlice({
  name: 'mentions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchTaggedUsers.fulfilled, (state, action) => {
      const { text } = action.meta.arg;

      const { data } = action.payload;

      const searchedUsers = parseSearchTaggedUsers(data);

      state.cachedUsers = addSearchedUsersToCache(state.cachedUsers, searchedUsers);

      const displayedUsers = state.cachedUsers
        .filter(
          (user) =>
            user.username.toLowerCase().startsWith(text.toLowerCase()) ||
            user.fullName.toLowerCase().startsWith(text.toLowerCase()),
        )
        .slice(0, 8);

      state.displayedUsers = displayedUsers;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      const { taggedUsers } = action.meta.arg;

      taggedUsers.forEach((user) => {
        const cachedUser = state.cachedUsers.find(
          (currentUser) => currentUser.username === user.username,
        );

        if (cachedUser) {
          cachedUser.priority = 2;
        }
      });

      state.cachedUsers = sortUsersByPriority(state.cachedUsers);
    });
  },
});
