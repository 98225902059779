import { fetcher } from 'api';
import { Method } from 'types';
import { IMentionsApi, ISearchTaggedUsersResponse } from './types';

export class MentionsReducer implements IMentionsApi {
  searchTaggedUsers(text: string, token: string): Promise<ISearchTaggedUsersResponse> {
    const query = { q: text };

    return fetcher('v2/users/tag', Method.GET, token, undefined, query);
  }
}
