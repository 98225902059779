import { Middleware } from 'redux';

import { NotificationsSlicer } from './NotificationsSlicer';
import { onFollowUserToggle } from '../profile/ProfileActions';

export const notificationsMiddleware: Middleware =
  ({ dispatch }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case NotificationsSlicer.actions.onFollowToggle.type: {
        const { userId, isFollowing } = action.payload;
        dispatch(onFollowUserToggle({ userId, isFollowing }));
        break;
      }
      default:
        break;
    }

    return next(action);
  };
