import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2 } from '../types';
import { OnboardingApi } from './OnboardingApi';
import { getSelectedInterestsIds } from './helper';

const reducer = new OnboardingApi();

export const getOnboardingInterests = createAsyncThunk(
  'async/getOnboardingInterests',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.getOnboardingInterests(token);
  },
);

export const getHasSuggestions = createAsyncThunk(
  'async/getHasSuggestions',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.getHasSuggestions(token);
  },
);

export const getOnboardingShops = createAsyncThunk(
  'async/getOnboardingShops',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { interests, birthday } = state.onboarding;

    const interestsList = interests ? getSelectedInterestsIds(interests) : [];

    return reducer.getShopSuggestions(interestsList, birthday, token);
  },
);

export const getOnboardingGroups = createAsyncThunk(
  'async/getOnboardingGroups',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { interests, birthday } = state.onboarding;

    const interestsList = interests ? getSelectedInterestsIds(interests) : [];

    return reducer.getGroupSuggestions(interestsList, birthday, token);
  },
);

export const onOnboardingFinish = createAsyncThunk(
  'async/onOnboardingFinish',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { interests, groups, shops } = state.onboarding;

    const interestsList = interests ? getSelectedInterestsIds(interests) : [];

    const shopsIds = shops.filter((shop) => shop.isSelected).map((shop) => shop.id);

    const groupIds = groups.filter((group) => group.isSelected).map((group) => group.id);

    return reducer.updateOnboardingData(interestsList, shopsIds, groupIds, token);
  },
);
