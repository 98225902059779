import './goalCard.scss';
import { FC } from 'react';
import { FilledCircleCheckboxIcon } from 'tedooo-web-design-system/icons';
import classNames from 'classnames';

import { Image } from 'components/helpers';
import { IGoalCard } from 'types';

const GoalCard: FC<IGoalCard> = ({
  isSelected,
  onClick,
  image,
  title,
  description,
  profileType,
}) => {
  return (
    <div
      className={classNames('goal-card-container', { selected: isSelected })}
      onClick={() => onClick(profileType)}
    >
      {isSelected && <FilledCircleCheckboxIcon className="circle-checkbox-icon" />}
      <Image src={image} className="shop-image" />
      <span className="title">{title}</span>
      <span className="description">{description}</span>
    </div>
  );
};

export default GoalCard;
