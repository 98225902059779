import { FC } from 'react';
import { Skeleton } from 'tedooo-web-design-system/ui';
import './shopPageSkeleton.scss';
import { connect } from 'react-redux';

const ShopPageSkeleton: FC = () => {
  return (
    <div className="shop-skeleton-container">
      <div className="shop-skeleton-top-container skeleton-section">
        <div className="shop-skeleton-header">
          <Skeleton title={false} avatar={{ shape: 'square' }} className="shop-cover-skeleton" />
          <Skeleton title={false} avatar={{ shape: 'circle' }} className="shop-avatar-skeleton" />
        </div>
        <div className="shop-skeleton-content">
          <Skeleton title className="skeleton-shop-title" />
          <Skeleton className="skeleton-shop-followers" />
          <Skeleton className="skeleton-shop-subtitle" />
          <Skeleton className="skeleton-shop-rate" />
          <Skeleton className="skeleton-shop-countries" />
          <div className="shop-buttons-skeleton">
            <Skeleton title={false} avatar={{ shape: 'square' }} className="shop-button-skeleton" />
            <Skeleton title={false} avatar={{ shape: 'square' }} className="shop-button-skeleton" />
          </div>
          <div className="separator" />
        </div>
      </div>
      <div className="shop-skeleton-about skeleton-section">
        <Skeleton title className="about-skeleton-title" />
        <Skeleton title className="about-skeleton-subtitle" />
        <Skeleton title className="about-skeleton-text" />
      </div>
    </div>
  );
};

export default connect(null)(ShopPageSkeleton);
