import { ReactNode } from 'react';

export type MediaTypes = 'photo' | 'video' | 'file' | 'csv';

export interface IFileUploader {
  children: ReactNode;
  maxItems: number;
  acceptTypes?: MediaTypes[];
  shouldOpenImagePicker?: boolean;
  id: string;
  feature?: UploadFileTypes;
  onImageUpload(images: string[], files: File[]): void;
  onImagesLoadingStart(loadingFilesCount: number): void;
}

export enum UploadFileTypes {
  DigitalItems = 'DIGITAL_ITEMS',
  VisionRequest = 'VISION_REQUEST',
  Visions = 'VISIONS',
}
