import { Notification } from 'tedooo-web-design-system/ui';

import { ServerStatus } from 'types';
import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';

const RequestOrderErrorMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.ERROR) {
    return <Notification type="error" text="Error requesting order" placement="top" />;
  }

  return null;
};

export default RequestOrderErrorMessage;
