import { translations } from 'translations/en';
import {
  IChatGroupsState,
  IChatShopsState,
  IMessageCount,
  IMessagesStore,
  IMessageItemDetails,
  OrderStatuses,
  ServerStatus,
  MessagesTypes,
  SystemMessagesOptions,
  ISelectedChatSearch,
} from 'types';
import { getDiffInUnit, isSameDay, isSameHour } from 'utils';

const { YOU_SENT_AN_OFFER, OFFER_DECLINED, YOU_DECLINED, NEW_OFFER } =
  translations.CHAT.VISION_MESSAGE_TITLES;

export const mapSystemTypeToText: { [key in SystemMessagesOptions]: string } = {
  userJoined: translations.CHAT.JOIN_THE_GROUP,
  userKicked: translations.CHAT.HAS_BEEN_REMOVED,
  userLeft: translations.CHAT.LEFT_THE_GROUP,
  nameChanged: translations.COMMON.NAME,
  descChanged: translations.COMMON.DESCRIPTION,
  avatarChanged: translations.COMMON.IMAGE,
};

export const mapOrderStatusToText: { [key in OrderStatuses]: string } = {
  [OrderStatuses.Complete]: translations.OFFERS.ORDER_COMPLETED,
  [OrderStatuses.Decline]: translations.OFFERS.ORDER_DECLINED,
  [OrderStatuses.Offered]: translations.OFFERS.ADDED_SHIPPING_COST,
  [OrderStatuses.PaymentSent]: translations.OFFERS.SENT_THE_PAYMENT,
  [OrderStatuses.Requested]: translations.OFFERS.REQUEST_TO_BUY,
  [OrderStatuses.Shipped]: translations.OFFERS.ORDER_SHIPPED,
  [OrderStatuses.ShippingApiUpdate]: '',
  [OrderStatuses.PaymentReceived]: translations.OFFERS.MARKED_PAYMENT_RECEIVED,
};

export const parseMessage = (
  message: IMessagesStore,
  shops: IChatShopsState,
  groups: IChatGroupsState,
  selectedMessages: string[],
  shouldDisplayName: boolean,
  isSequenceMessage: boolean,
): IMessageItemDetails => {
  return {
    isForwarded: message.isForwarded,
    userId: message.userId,
    name: message.name,
    linkDetails: message.linkDetails,
    avatar: message.avatar,
    searchWords: message.searchWords,
    avatarDisplayOption: message.avatarDisplayOption,
    username: message.username,
    id: message.id,
    isOwner: message.isOwner,
    orderDetails: message.orderDetails,
    visionDetails: message.visionDetails,
    date: message.date,
    isSelected: !!selectedMessages.find((id) => id === message.id),
    shopDetails: shops[message.shopId || ''] || { details: null, status: ServerStatus.PENDING },
    groupDetails: groups[message.groupLink || ''] || {
      details: null,
      status: ServerStatus.PENDING,
    },
    isSequenceMessage,
    expiryText: message.expiryText,
    isExpired: message.isExpired,
    postDetails: message.postDetails,
    reply: message.reply,
    fileDetails: message.fileDetails,
    images: message.images,
    type: message.type,
    isDeleted: message.isDeleted,
    status: message.status,
    userColor: message.userColor,
    isTranslated: message.isTranslated,
    shouldDisplayName,
    text: message.text,
  };
};

export const getMessageImagesAndNextIndex = (
  messages: IMessagesStore[],
  startIndex: number,
): { images: string[]; nextIndex: number; id: string } => {
  const currentMessage = messages[startIndex];

  if (currentMessage.type !== MessagesTypes.Image || !!currentMessage.text) {
    return { nextIndex: startIndex + 1, images: [], id: currentMessage.id };
  }

  let i = startIndex;

  const updatedImages: string[] = [];

  const ids: string[] = [];

  while (
    i < messages.length &&
    messages[i].type === MessagesTypes.Image &&
    messages[i].name === currentMessage.name &&
    !messages[i].text &&
    messages[i].isForwarded === currentMessage.isForwarded &&
    isSameHour(messages[i].date, currentMessage.date)
  ) {
    ids.push(messages[i].id);
    updatedImages.push(...messages[i].images);
    i++;
  }

  return { images: updatedImages, nextIndex: i, id: ids.join('|') };
};

export const getUpdatedGroupCount = (
  groupCount: IMessageCount[],
  messageDate: number,
  removedItems: number,
) => {
  return groupCount.map((item) => {
    if (isSameDay(item.date, messageDate)) {
      return { count: item.count - removedItems, date: messageDate };
    }

    return item;
  });
};

export const removeSearchWordsFromMessages = (messages: IMessagesStore[]) =>
  messages.map((message) => ({ ...message, searchWords: [] }));

export const updateSearchBatches = (
  search: string,
  isSearchVisible: boolean,
): ISelectedChatSearch => ({
  search,
  isSearchVisible,
  messagesBatches: [],
  currentMessageBatchIndex: 0,
  skip: 0,
  totalCount: 0,
  isLoading: false,
  hasMore: true,
});

export const getTimeToPatternExpire = (date: number) => {
  const now = Date.now();

  const hours = getDiffInUnit(now, date, 'hour');

  const minutes = getDiffInUnit(now, date, 'minute') % 60;

  return `${hours}h ${minutes}m`;
};

export const getVisionMessageTitle = (isOwner: boolean, isDeclined: boolean) => {
  let title = NEW_OFFER;

  if (isOwner) {
    if (isDeclined) {
      title = YOU_DECLINED;
    } else {
      title = YOU_SENT_AN_OFFER;
    }
  } else if (isDeclined) {
    title = OFFER_DECLINED;
  }

  return title;
};
