import {
  IChatApi,
  IChatMediaResponse,
  IChatParticipantsResponse,
  IChatSearchMessageResponse,
  IChatSearchResponse,
  ICreateGroupResponse,
  IFeedResponseApi,
  IGetChatFilesResponse,
  IGetChatLinksResponse,
  IGroupInformationResponse,
  IGroupLinkResponse,
  IGroupPrivacyOptions,
  IMessagesResponse,
  ISearchUsersResponse,
  IShopLinkResponse,
  IStoreCreateGroup,
  LoadImagesDirection,
  Method,
  MuteChatOptions,
  ReportOptions,
} from 'types';
import { fetcher } from 'api';
import { graphQlRequest } from 'api/graphQl';
import { REPORT_MESSAGE_MUTATION, UNEXPIRE_MESSAGE_MUTATION } from './ChatQueries';
import { SuccessResponse } from 'graphql/generated/graphql';

export class ChatApi implements IChatApi {
  getSearchMessages(
    chatId: string,
    search: string,
    skip: number,
    token: string,
  ): Promise<IChatSearchMessageResponse> {
    const query = { q: search, skip };

    return fetcher(`chatsearch/chat/${chatId}`, Method.GET, token, undefined, query);
  }

  getParticipants(
    search: string,
    groupId: string,
    skip: number,
    token: string,
  ): Promise<IChatParticipantsResponse> {
    const query: { skip: number; q?: string } = { skip };

    if (search) {
      query.q = search;
    }

    return fetcher(`v2/chatgroups/participants/${groupId}`, Method.GET, token, undefined, query);
  }

  removeMember(groupId: string, userId: string, token: string): Promise<void> {
    const query = { kick: userId };

    return fetcher(`chatgroups/${groupId}`, Method.DELETE, token, undefined, query);
  }

  onAdminToggle(
    groupId: string,
    userId: string,
    shouldMakeAdmin: boolean,
    token: string,
  ): Promise<void> {
    const body = { groupId, userId, admin: shouldMakeAdmin };

    return fetcher(`chatgroups/admin`, Method.PUT, token, body);
  }

  getMessages(search: string, token: string): Promise<IChatSearchResponse> {
    const query = { q: search };

    return fetcher('chatsearch', Method.GET, token, undefined, query);
  }

  archiveChat(chatId: string, isGroup: boolean, isArchived: boolean, token: string): Promise<any> {
    const body = { target: chatId, room: isGroup, archive: isArchived };

    return fetcher('chatops/archive', Method.PATCH, token, body);
  }

  createGroup(details: IStoreCreateGroup, token: string): Promise<ICreateGroupResponse> {
    const isPublic = details.privacy === IGroupPrivacyOptions.Public;

    const body = {
      name: details.name,
      avatar: details.avatar,
      description: details.description,
      public: isPublic,
    };

    return fetcher('chatgroups', Method.POST, token, body);
  }

  inviteUsers(groupId: string, users: string[], token: string): Promise<any> {
    const body = {
      users,
    };

    return fetcher(`v2/chatgroups/invite/${groupId}`, Method.POST, token, body);
  }

  editGroup(
    groupId: string,
    name: string | null,
    avatar: string | null,
    description: string | null,
    privacy: IGroupPrivacyOptions | null,
    token: string,
  ): Promise<any> {
    const promises: any[] = [];

    if (name) {
      promises.push(() => fetcher('chatgroups/edit', Method.PUT, token, { name, groupId }));
    }
    if (avatar) {
      promises.push(() => fetcher('chatgroups/edit', Method.PUT, token, { avatar, groupId }));
    }
    if (description) {
      promises.push(() => fetcher('chatgroups/edit', Method.PUT, token, { description, groupId }));
    }
    if (privacy) {
      promises.push(() =>
        fetcher(`v2/chatgroups/visibility/${groupId}`, Method.PATCH, token, {
          visibility: privacy.toLocaleLowerCase(),
        }),
      );
    }

    return Promise.all(promises.map(async (promise) => await promise()));
  }

  getUsers(text: string, token: string): Promise<ISearchUsersResponse[]> {
    const query = {
      text,
    };

    return fetcher('users', Method.POST, token, undefined, query);
  }

  reportUser(userId: string, report: ReportOptions, text: string, token: string): Promise<void> {
    const parsedText = `${report} ${text}`;

    const body = { reportedUserId: userId, text: parsedText };

    return fetcher('messages', Method.POST, token, body);
  }

  blockUser(userId: string, token: string): Promise<void> {
    return fetcher(`blocks/${userId}`, Method.PATCH, token);
  }

  unblockUser(userId: string, token: string): Promise<void> {
    return fetcher(`blocks/${userId}`, Method.DELETE, token);
  }

  leaveGroup(groupId: string, token: string): Promise<void> {
    return fetcher(`chatgroups/${groupId}`, Method.DELETE, token);
  }

  muteGroup(groupId: string, timeToMute: MuteChatOptions, token: string): Promise<void> {
    const body = {
      groupId,
      mute: timeToMute,
    };

    return fetcher('chatgroups/mute', Method.PUT, token, body);
  }

  muteUser(userId: string, timeToMute: MuteChatOptions, token: string): Promise<void> {
    const body = {
      mute: timeToMute,
    };

    return fetcher(`v2/chat/mute/user/${userId}`, Method.POST, token, body);
  }

  getChatFiles(
    chatId: string,
    isGroup: boolean,
    token: string,
    maxId?: string,
  ): Promise<IGetChatFilesResponse> {
    const room = isGroup ? 1 : undefined;

    const query = {
      chatId,
      maxId,
      room,
      limit: 40,
    };

    return fetcher('chat/files', Method.GET, token, undefined, query);
  }

  getChatLinks(
    chatId: string,
    isGroup: boolean,
    token: string,
    maxId?: string,
  ): Promise<IGetChatLinksResponse> {
    const room = isGroup ? 1 : undefined;

    const query = {
      chatId,
      maxId,
      room,
      limit: 40,
    };

    return fetcher('chat/links', Method.GET, token, undefined, query);
  }

  getChatImages(
    chatId: string,
    isGroup: boolean,
    time: number,
    direction: LoadImagesDirection,
    limit: number,
    token: string,
  ): Promise<IChatMediaResponse> {
    const room = isGroup ? 1 : undefined;

    const query = {
      chatId,
      time,
      limit,
      dir: direction,
      room,
      v2: 1,
    };

    return fetcher('chat/media', Method.GET, token, undefined, query);
  }

  getShopInformation(id: string, token: string): Promise<IShopLinkResponse> {
    const query = {
      shop: id,
    };

    return fetcher(`chatlinks`, Method.GET, token, undefined, query);
  }

  getGroupInformation(
    id: string,
    token: string,
    isLink: boolean,
  ): Promise<IGroupInformationResponse> {
    const query: { [key: string]: any } = {};

    if (isLink) {
      query.link = id;
    } else {
      query.group = id;
    }

    return fetcher(`chatlinks`, Method.GET, token, undefined, query);
  }

  getOlderMessages(
    id: string,
    isGroup: boolean,
    maxTime: number | null,
    token: string,
  ): Promise<IMessagesResponse> {
    const query = {
      time: 0,
      maxTime: maxTime || undefined,
      v3: 1,
      room: isGroup,
      target: id,
      limit: 20,
    };

    return fetcher(`chat`, Method.GET, token, undefined, query);
  }

  getBothDirectionMessages(
    id: string,
    isGroup: boolean,
    time: number,
    token: string,
  ): Promise<IMessagesResponse> {
    const query = {
      time,
      v3: 1,
      room: isGroup,
      target: id,
      limit: 20,
      dir: 'both',
    };

    return fetcher(`chat`, Method.GET, token, undefined, query);
  }

  getNewerMessages(
    id: string,
    isGroup: boolean,
    time: number,
    token: string,
  ): Promise<IMessagesResponse> {
    const query = {
      time,
      v3: 1,
      room: isGroup,
      target: id,
      limit: 20,
    };

    return fetcher(`chat`, Method.GET, token, undefined, query);
  }

  getGroupLink(link: string, token: string): Promise<IGroupLinkResponse> {
    return fetcher(`chatlinks/${link}`, Method.GET, token);
  }

  joinGroupViaInvite(groupId: string, token: string): Promise<{ groupId: string }> {
    const body = {
      response: 'ACCEPT',
    };

    return fetcher(`chatgroups/${groupId}`, Method.PUT, token, body);
  }

  joinGroupFromLink(id: string, token: string): Promise<{ groupId: string }> {
    return fetcher(`chatlinks/${id}`, Method.PUT, token);
  }

  getGroupChatPosts(groupId: string, token: string, maxId?: string): Promise<IFeedResponseApi> {
    let query: any;

    if (maxId) {
      query = { id: maxId };
    }

    return fetcher(`v3/feed/group/${groupId}`, Method.GET, token, undefined, query);
  }

  async unexpireMessage(messageId: string, token: string): Promise<SuccessResponse> {
    const variables = { id: messageId };

    const res = await graphQlRequest(token).mutate({
      mutation: UNEXPIRE_MESSAGE_MUTATION,
      variables,
    });

    return res.data.unexpireMessage;
  }

  async reportMessage(
    token: string,
    messageId: string,
    option: string,
    extra?: string,
  ): Promise<SuccessResponse> {
    const variables = {
      option,
      messageId,
      extra,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: REPORT_MESSAGE_MUTATION,
      variables,
    });

    return res.data.reportMessage;
  }
}
