import { PaginatedMojoResponse } from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import { GET_MOJOS_QUERY } from './MojosQueries';
import { IMojosApi } from 'types';

export class MojosApi implements IMojosApi {
  async getMojos(
    paginationToken: string | null,
    listId: string,
    token: string,
  ): Promise<PaginatedMojoResponse> {
    const variables = { paginationToken: paginationToken || undefined, listId };

    const res = await graphQlRequest(token).query({
      query: GET_MOJOS_QUERY,
      variables,
    });

    return res.data.getMojos;
  }
}
