import './mojoComments.scss';
import { FC, useEffect } from 'react';
import { XIcon } from 'tedooo-web-design-system/icons';

import { IComments, ITaggedUser } from 'types';
import Comments from 'components/post/comments/Comments';
import { CommentInput } from '../../commentInput';
import { Span } from '../../helpers';

export interface MojoCommentsProps extends IComments {
  avatar: string;
  isUserSingIn: boolean;
  onCommentSubmit: (
    comment: string,
    taggedUsers: ITaggedUser[],
    attachedImages: Array<string>,
  ) => void;
  onClose(): void;
}

const MojoComments: FC<MojoCommentsProps> = ({
  avatar,
  comments,
  totalComments,
  hasMore,
  shouldDisplayEmptyState,
  postOwnerId,
  isExpanded,
  isUserSingIn,
  onCommentDelete,
  onCommentLike,
  onCommentLikesClick,
  onLoadMore,
  onRepliesLoad,
  onReply,
  onToggleRepliesClick,
  onUserClick,
  onCommentSubmit,
  onClose,
}) => {
  useEffect(() => {
    onLoadMore();
  }, []);

  return (
    <div className="mojo-comments-container">
      <Span text="Comments" className="title" /> <XIcon className="close-icon" onClick={onClose} />
      <Comments
        comments={comments}
        shouldDisplayEmptyState={shouldDisplayEmptyState}
        hasMore={hasMore}
        isExpanded={isExpanded}
        totalComments={totalComments}
        postOwnerId={postOwnerId}
        onToggleRepliesClick={onToggleRepliesClick}
        onLoadMore={onLoadMore}
        onUserClick={onUserClick}
        onRepliesLoad={onRepliesLoad}
        onReply={onReply}
        onCommentLike={onCommentLike}
        onCommentDelete={onCommentDelete}
        onCommentLikesClick={onCommentLikesClick}
        onViewMoreCommentsClick={() => {}}
      />
      <CommentInput
        isUserSingIn={isUserSingIn}
        avatar={avatar}
        avatarSize={40}
        placeholder="Write a comment..."
        maxImages={1}
        onSubmit={onCommentSubmit}
      />
    </div>
  );
};

export default MojoComments;
