import './interestsEmptyState.scss';
import { Image, Span } from 'components/helpers';
import EmptyState from 'photos/emptyState/interestsFirstEmptyState.png';
import { translations } from 'translations/en';

const { TITLE, DESCRIPTION } = translations.ONBOARDING.INTERESTS_STEP.EMPTY_STATE;

export const InterestsEmptyState = () => {
  return (
    <div className="interests-empty-state-container">
      <Image className="interests-first-empty-state-logo" src={EmptyState} />
      <div className="text-container">
        <Span className="title" text={TITLE} />
        <Span className="description" text={DESCRIPTION} />
      </div>
    </div>
  );
};
