import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useTedoooSelector } from 'store/Store';
import { mapIdToPopup } from './hepler';
import { PopupSlicer } from 'store/popup/PopupActions';
import { useSkipFirstEffect } from 'hooks/useSkipFirstEffect';
import { isEmptyArray } from 'utils';

const Popups = () => {
  const { ids, shouldHidePopupOverlay = false } = useTedoooSelector((state) => state.popup);

  const location = useLocation();

  const dispatch = useDispatch();

  useSkipFirstEffect(() => {
    if (!isEmptyArray(ids)) {
      dispatch(PopupSlicer.actions.hide());
    }
  }, [location]);

  if (isEmptyArray(ids)) {
    return null;
  }

  const renderPopup = (popupId: string, index: number) => {
    const Popup = mapIdToPopup[popupId];

    const shouldShowOverlay = !shouldHidePopupOverlay && index === 0;

    return (
      <Suspense
        fallback={<div className={classNames({ 'popup-overlay': shouldShowOverlay })} />}
        key={popupId}
      >
        <Popup />
      </Suspense>
    );
  };

  return <>{ids.map((id, index) => renderPopup(id, index))}</>;
};

export default Popups;
