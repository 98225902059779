import { ISpecificVisionApi } from 'types';
import {
  GetVisionCategories,
  RequestToBuyResponse,
  VisionByIdResponse,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  GET_SPECIFIC_VISION_QUERY,
  GET_VISION_CATEGORIES_QUERY,
  REQUEST_TO_BUY_VISION_MUTATION,
} from './SpecificVisionQueries';

export class SpecificVisionApi implements ISpecificVisionApi {
  async getSpecificVision(token: string, id: string): Promise<VisionByIdResponse> {
    const variables = { id };

    const res = await graphQlRequest(token).query({
      query: GET_SPECIFIC_VISION_QUERY,
      variables,
    });

    return res.data.getVisionById;
  }

  async requestToBuyVision(
    token: string,
    visionId: string,
    category: string,
    notes: string,
    images: string[],
    shippingAddressId: string,
  ): Promise<RequestToBuyResponse> {
    const variables = { visionId, notes, category, images, shippingAddressId };

    const res = await graphQlRequest(token).mutate({
      mutation: REQUEST_TO_BUY_VISION_MUTATION,
      variables,
    });

    return res.data.requestToBuy;
  }

  async getVisionCategories(token: string): Promise<GetVisionCategories> {
    const res = await graphQlRequest(token).query({
      query: GET_VISION_CATEGORIES_QUERY,
    });

    return res.data.getVisionCategories;
  }
}
