import { FC, ReactNode, useEffect, useState } from 'react';
import './stickyLayout.scss';

import { transformToArray } from 'utils';

const Content: FC<any> = ({ children }) => <div className="preview">{children}</div>;

const SideMenu: FC<any> = ({ children }) => <>{children}</>;

interface IStickyLayout {
  topOffset?: number;
  bottomOffset?: number;
  parentScrollerId: string;
  children: any;
}

const StickyLayout = ({
  children,
  bottomOffset = 0,
  topOffset = 0,
  parentScrollerId,
}: IStickyLayout) => {
  const [topValue, setTopValue] = useState(0);

  const totalOffset = bottomOffset + topOffset;

  const processChildren = (): {
    content: null | ReactNode;
    sideMenu: null | ReactNode;
  } => {
    const emptyArray = {
      content: null,
      sideMenu: null,
    };

    if (!children) return emptyArray;

    // transform to array
    const childrenArray = transformToArray(children);

    return childrenArray.reduce((acc, child) => {
      const { name } = child.type;

      if (name === Content.name) {
        return {
          ...acc,
          content: child,
        };
      }

      if (name === SideMenu.name) {
        return {
          ...acc,
          sideMenu: child,
        };
      }

      return acc;
    }, emptyArray);
  };

  useEffect(() => {
    const handleScroll = (e: any) => {
      const listHeight = document.getElementById('list')?.clientHeight || 0;

      const contentHeight = e.target?.clientHeight;
      let top = contentHeight - listHeight - totalOffset;
      if (listHeight < contentHeight) {
        top = topOffset;
      }
      setTopValue(top);
    };

    const scrollContainer = document.getElementById(parentScrollerId);
    scrollContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const stickyLayoutData = processChildren();

  return (
    <div className="sticky-layout-container">
      {stickyLayoutData.content}
      <div className="list" id="list" style={{ top: `${topValue}px` }}>
        {stickyLayoutData.sideMenu}
      </div>
    </div>
  );
};

StickyLayout.SideMenu = SideMenu;
StickyLayout.Content = Content;

export default StickyLayout;
