import { validateEmail, validatePhoneNumber } from './validationUtils';
import { isEmptyArray } from './ArrayUtils';
import { DiscountTypes } from 'types';

export const getNameError = (value: string): string | null => {
  if (!value) {
    return null;
  }

  if (!/[a-zA-Z]/.test(value)) {
    return 'Name must contain letters';
  }

  if (value.length < 4 || value.length > 40) {
    return `Name must contain between ${4}-${40} characters`;
  }

  return null;
};

export const getDescriptionError = (value: string): string | null => {
  if (!value) {
    return null;
  }

  if (!/[a-zA-Z]/.test(value)) {
    return 'Description must contain letters';
  }

  if (value.length < 4) {
    return 'Description must contain at least 4 characters';
  }

  return null;
};

export const validatePasswordsMatches = ({ getFieldValue }: any) => ({
  validator(_: any, value: string) {
    if (!value || getFieldValue('password') === value) {
      return Promise.resolve();
    }

    return Promise.reject(new Error("Passwords don't match"));
  },
});

export const validateNameField = () => ({
  validator(_: any, value: string) {
    const error = getNameError(value);

    if (!error) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(error));
  },
});

export const validateDescriptionField = () => ({
  validator(_: any, value: string) {
    const error = getDescriptionError(value);

    if (!error) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(error));
  },
});

export const validateEmailInput = () => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (!validateEmail(value)) {
      return Promise.reject(new Error('Email is invalid'));
    }

    return Promise.resolve();
  },
});

export const validatePhoneInput = (countryCode: any) => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (!validatePhoneNumber(value, countryCode)) {
      return Promise.reject(new Error('Phone number is invalid'));
    }

    return Promise.resolve();
  },
});

export const validateDiscountInput = (discount: DiscountTypes, itemPrice: number) => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (Number(value) < 1) {
      if (discount === DiscountTypes.Fixed) {
        return Promise.reject(new Error("Fixed amount can't be 0"));
      }

      return Promise.reject(new Error("Sale amount can't be 0%"));
    }

    if (discount === DiscountTypes.Fixed) {
      if (Number(value) >= Number(itemPrice)) {
        return Promise.reject(new Error('Fixed amount cannot be higher than the price'));
      }
    } else if (Number(value) >= 100) {
      return Promise.reject(new Error('Sale cannot be above 100%'));
    }

    return Promise.resolve();
  },
});

export const validateQuantity = () => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (Number(value) < 0 || Number(value) > 999) {
      return Promise.reject(new Error('Insert a number between 0 to 999'));
    }

    return Promise.resolve();
  },
});

export const validateFullName = () => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (!/^[a-zA-Z\s]+$/.test(value)) {
      return Promise.reject(new Error('Please enter only alphabetic characters'));
    }
    if (value.length < 3) {
      return Promise.reject(new Error('Full name must contain at least 3 characters '));
    }

    return Promise.resolve();
  },
});

export const validateLength = (min: number, minText: string, max?: number, maxText?: string) => ({
  validator(_: any, value: string) {
    if (value === '') {
      return Promise.resolve();
    }
    if (Number(value) <= min) {
      return Promise.reject(new Error(minText));
    }
    if (max && Number(value) > max) {
      return Promise.reject(new Error(maxText));
    }

    return Promise.resolve();
  },
});

export const validateAccordion = (items: string[], message: string) => ({
  validator() {
    if (isEmptyArray(items)) {
      return Promise.reject(new Error(message));
    }

    return Promise.resolve();
  },
});

export const validateTariffNumber = () => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    const firstOption = /^([0-9]{4}.[0-9]{2})$/;

    const secondOption = /^([0-9]{4}.[0-9]{2}.[0-9]{2})$/;

    const thirdOption = /^([0-9]{4}.[0-9]{2}.[0-9]{4})$/;

    if (!firstOption.test(value) && !secondOption.test(value) && !thirdOption.test(value)) {
      return Promise.reject(new Error('Format must match NNNN.NN, NNNN.NN.NN, or NNNN.NN.NNNN'));
    }

    return Promise.resolve();
  },
});

export const validateWholeNumber = () => ({
  validator(_: any, value: string) {
    if (!value) {
      return Promise.resolve();
    }

    if (Number(value) % 1 !== 0) {
      return Promise.reject(new Error('Field should be a whole number'));
    }

    return Promise.resolve();
  },
});

export const validateWeightSmall = (weightBig: number) => ({
  validator(_: any, value: string) {
    if (Number(value) <= 0 && weightBig === 0) {
      return Promise.reject(new Error('Field cant be below 0'));
    }

    return Promise.resolve();
  },
});
