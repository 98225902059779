import {
  IComment,
  ICommentItemResponseApi,
  ISpecificPostState,
  ISubPost,
  popupIdsOptions,
} from 'types';
import { translations } from 'translations/en';
import { getCommentItem } from '../apiParser';

export const checkIsSpecificPostPopup = (ids: popupIdsOptions[]) => {
  const FEED_POPUPS = [
    popupIdsOptions.postReactionsPopup,
    popupIdsOptions.postImagesPopup,
    popupIdsOptions.deleteCommentPopup,
  ];

  return FEED_POPUPS.find((popupId) => ids.find((id) => id === popupId));
};

export const getSubPost = (state: ISpecificPostState) => {
  const { selectedPostId, subPosts, id } = state;

  return subPosts.find((item) => item.id === selectedPostId || item.id === id);
};

export const getSelectedPost = (state: ISpecificPostState) => {
  const { selectedPostId, subPosts } = state;

  const subPost = subPosts.find((item) => item.id === selectedPostId);

  return subPost || state;
};

export const getSelectedComment = (
  post: ISpecificPostState | ISubPost,
  commentId: string,
  replyId?: string,
) => {
  let selectedComment = post.comments.list?.find((item) => item.id === commentId);
  if (selectedComment && replyId) {
    selectedComment =
      selectedComment.replies.find((item) => item.id === replyId) || selectedComment;
  }

  return selectedComment;
};

export const deleteCommentToPost = (
  post: ISubPost | ISpecificPostState,
  commentId: string | null,
  replyId: string | null,
) => {
  if (post) {
    const { comments } = post;

    const comment = comments.list?.find((item) => item.id === commentId);

    const isCommentDelete = !replyId;

    if (comment) {
      post.totalComments -= 1;

      // delete comment
      if (isCommentDelete) {
        // remove comment from list
        if (comment.totalReplies === 0) {
          comments.list = comments.list!.filter((item) => item.id !== commentId);
        } else {
          comment.isDeleted = true;
          comment.text = translations.FEED.POST.COMMENTS.DELETED;
        }
      } else {
        comment.replies = comment.replies.filter((reply) => reply.id !== replyId);
        comment.totalReplies -= 1;
      }
    }
  }
};

export const addReplyToComment = (
  post: ISubPost | ISpecificPostState,
  data: ICommentItemResponseApi,
  repliedToCommentId: string,
) => {
  if (post) {
    const { list: prevList } = post.comments;

    let comment = prevList?.find((item: IComment) => item.id === repliedToCommentId);

    // if comment not found, search in replies and return the comment that contains it.
    if (!comment) {
      comment = prevList?.find((item) => {
        return item.replies.find((reply) => reply.id === repliedToCommentId);
      });
    }

    const reply = getCommentItem(data);

    if (comment) {
      if (!comment.isRepliesExpanded) {
        comment.replies = [reply];
      } else {
        comment.replies.unshift(reply);
      }
      post.totalComments += 1;
      comment.totalReplies += 1;
      comment.isRepliesExpanded = true;
    }
  }
};

export const togglePostLike = (post?: ISpecificPostState | ISubPost) => {
  if (post) {
    const { isLiked: prevIsLiked } = post;

    const addToLikes = prevIsLiked ? -1 : 1;
    post.isLiked = !prevIsLiked;
    post.totalLikes += addToLikes;
  }
};

export const onRepostToggle = (post?: ISpecificPostState) => {
  if (post) {
    const { isReposted: prevIsReposted } = post;

    const addToReposts = prevIsReposted ? -1 : 1;
    post.isReposted = !prevIsReposted;
    post.totalReposts += addToReposts;
  }
};
