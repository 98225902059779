import { FC } from 'react';

import { IInterestGroup, IInterestItem } from 'types';
import InterestItemButton from '../interestItemButton/InterestItemButton';

const InterestGroup: FC<IInterestGroup> = ({ interestTitle, interests, onItemClick }) => {
  return (
    <div className="interests-group-container">
      <span className="interest-title">{interestTitle}</span>
      <div key={interestTitle} className="interests-group-wrapper">
        {interests.map((interestItem: IInterestItem) => (
          <InterestItemButton
            interestId={interestItem.id}
            title={interestTitle}
            text={interestItem.text}
            isSelected={interestItem.isSelected}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default InterestGroup;
