import mixpanel from 'mixpanel-browser';

import { IAnalyticsService } from './types';
import { StorageSrv } from './StorageSrv';

export const mixpanelHelpers: IAnalyticsService = {
  init: (distinctId: string | null) => {
    if (distinctId) {
      const id = distinctId.padEnd(32, '0');

      const uuidString = `${id.substring(0, 8)}-${id.substring(8, 12)}-${id.substring(
        12,
        16,
      )}-${id.substring(16, 20)}-${id.substring(20, 32)}`;

      mixpanel.identify(uuidString);
    } else {
      mixpanel.reset();
    }

    // Conditional event tracking based on storage service (we don't need to add this we have it from amplitude)
    if (StorageSrv.amplitude.shouldSendUserEvent()) {
      mixpanelHelpers.track('three_days_user');
    }
  },
  track: (event, properties) => {
    mixpanel.track(event, properties);
  },
};
