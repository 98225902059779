import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';

import './styles/fonts.scss';
import './index.scss';
import * as serviceWorker from 'serviceWorker';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

serviceWorker.unregister();
