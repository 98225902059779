import { nanoid } from '@reduxjs/toolkit';

import {
  IAppConfigResponse,
  IGetPremiumPlanDetailsResponse,
  ILoginResponse,
  IMeResponse,
  IUserActions,
  Method,
} from 'types';
import { a, fetcher, signKey } from 'api';
import { StorageSrv } from 'services/StorageSrv';
import {
  GetCustomerPortalResult,
  Mutation,
  Query,
  SubscriptionFlowResult,
  SubscriptionType,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import { GET_MANAGE_PLAN_URL_QUERY, START_SUBSCRIPTION_FLOW_QUERY } from './userQueries';

const CURRENT_APP_CONFIG_VERSION = 13;

export class UserReducer implements IUserActions {
  getAppConfigurations(token: string): Promise<IAppConfigResponse> {
    return fetcher(`v3/appconfig/${CURRENT_APP_CONFIG_VERSION}`, Method.GET, token);
  }

  refreshToken(token: string): Promise<ILoginResponse> {
    return fetcher('authentication', Method.POST, token, {
      accessToken: token,
      strategy: 'jwt',
    });
  }

  login(userName: string, password: string): Promise<ILoginResponse> {
    const username = userName.toLowerCase();

    const nonce = nanoid(8);

    const timestamp = signKey(`${username} ${a} ${password}`, nonce);

    return fetcher('authentication', Method.POST, undefined, {
      strategy: 'local',
      username,
      nonce,
      timestamp,
      password,
    });
  }

  getPremiumPlanDetails(token: string): Promise<IGetPremiumPlanDetailsResponse> {
    return fetcher('subs/me', Method.GET, token);
  }

  async getSubscriptionUrl(
    subscriptionType: SubscriptionType,
    token: string,
  ): Promise<SubscriptionFlowResult | undefined> {
    const variables = {
      subscriptionType,
    };

    const res = await graphQlRequest(token).mutate<Mutation>({
      mutation: START_SUBSCRIPTION_FLOW_QUERY,
      variables,
    });

    return res.data?.startSubscriptionFlow;
  }

  async getManagePlanUrl(token: string): Promise<GetCustomerPortalResult> {
    const res = await graphQlRequest(token).query<Query>({
      query: GET_MANAGE_PLAN_URL_QUERY,
    });

    return res.data.getCustomerPortal;
  }

  submitVerifyCode(codeNumber: string): Promise<any> {
    const userId = StorageSrv.userId.get();

    return fetcher(`register/${userId}`, Method.PATCH, undefined, { number: codeNumber });
  }

  googleLogin(token: string): Promise<any> {
    return fetcher('authentication', Method.POST, undefined, {
      strategy: 'google',
      token,
    });
  }

  facebookLogin(token: string): Promise<any> {
    return fetcher('authentication', Method.POST, undefined, {
      strategy: 'facebook',
      token,
    });
  }

  signUp(
    firstName: string,
    username: string,
    password: string,
    email: string,
    phoneNumber: string,
    botToken: string | null,
  ): Promise<any> {
    const body = {
      firstName,
      username,
      password,
      email,
      phoneNumber,
    };

    return fetcher('register', Method.POST, undefined, body, undefined, { captcha2: botToken });
  }

  checkUsernameAvailability(username: string): Promise<void> {
    const query = { username };

    return fetcher(`v2/users/availability`, Method.GET, undefined, undefined, query);
  }

  sendCodeViaWhatsApp(): Promise<any> {
    const userId = StorageSrv.userId.get();

    return fetcher(`register/${userId}`, Method.PUT, undefined, { whatsApp: true });
  }

  updateBio(token: string, bio: string): Promise<void> {
    return fetcher(`v2/users/me`, Method.PATCH, token, { bio });
  }

  updateUserDetails(
    token: string,
    userId: string,
    firstName?: string,
    avatar?: string | null,
  ): Promise<void> {
    const body: any = {};

    if (firstName) {
      body.firstName = firstName;
    }

    if (avatar) {
      body.avatar = avatar;
    }

    return fetcher(`users/${userId}`, Method.PATCH, token, body);
  }

  resendCode(): Promise<any> {
    const userId = StorageSrv.userId.get();

    return fetcher(`register/${userId}`, Method.PUT);
  }

  sendCodeViaCall(): Promise<any> {
    const userId = StorageSrv.userId.get();

    return fetcher(`register/${userId}`, Method.PUT, undefined, { phone: true });
  }

  finishSignUp(
    token: string,
    endpoint: string,
    fullName: string,
    email: string,
    picture: string,
    username: string,
  ): Promise<any> {
    return fetcher(endpoint, Method.PATCH, undefined, {
      token,
      fullName,
      email,
      picture,
      username,
      timezone: 'website',
      website: true,
    });
  }

  updateAvatar(token: string, avatar: string): Promise<void> {
    return fetcher('v2/users/me', Method.PATCH, token, { avatar });
  }

  getLoggedInUserInfo(token: string): Promise<IMeResponse> {
    return fetcher('v2/users/me', Method.GET, token);
  }

  updateUserToBuyer(token: string): Promise<void> {
    return fetcher(`v2/users/me`, Method.PATCH, token, { buyer: true });
  }
}
