import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ISpecificProductState } from './types';
import {
  getProductEstimatedDelivery,
  getShopDetails,
  getSpecificProduct,
  onProductLikeToggle,
} from './SpecificProductActions';
import {
  parseDiscoverPageComponent,
  parseProductBanner,
  parseProductEstimatedDelivery,
  parseProductGraphql,
  parseShopResponse,
} from '../apiParser';
import { CurrencyCode } from 'constant/currencies';
import { onFollowUserToggle } from '../profile/ProfileActions';
import { DiscoverLayoutComponents } from 'types';

const initialState: ISpecificProductState = {
  source: null,
  isLoading: false,
  userId: '',
  shopId: '',
  id: '',
  images: [],
  price: 0,
  currencyCode: CurrencyCode.USD,
  currencySymbol: '$',
  convertedFromPrice: null,
  title: '',
  description: '',
  originalPrice: 0,
  labels: null,
  category: null,
  updateTime: null,
  isDigital: false,
  hasInstantCheckout: false,
  isLiked: false,
  totalLikes: 0,
  quantity: null,
  shippingInformation: null,
  shopDetails: null,
  tariffNumber: null,
  estimatedDeliveryTime: null,
  bannerDetails: null,
  productCarousels: null,
  vacationDetails: null,
};

export const SpecificProductSlicer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSource: (state, action) => {
      const { source } = action.payload;
      state.source = source;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpecificProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSpecificProduct.fulfilled, (state, action) => {
      const { getProductById, getProductBanner, getDiscoverPageData } = action.payload;

      if (getProductById.item) {
        const parsedData = parseProductGraphql(getProductById.item);

        state.isLoading = false;
        state.vacationDetails = getProductById.item?.vacationInfo || null;

        state.productCarousels =
          parseDiscoverPageComponent(
            getDiscoverPageData.horizontalListsProducts,
            DiscoverLayoutComponents.ProductsCarousel,
          ) || null;

        const {
          id,
          images,
          price,
          title,
          description,
          currencyCode,
          currencySymbol,
          labels,
          originalPrice,
          category,
          isDigital,
          hasInstantCheckout,
          quantity,
          shippingInformation,
          isLiked,
          convertedFromPrice,
          totalLikes,
        } = parsedData;
        state.userId = getProductById.item?.userId || '';
        state.shopId = getProductById.shopId || '';
        state.id = id;
        state.images = images;
        state.price = price;
        state.title = title;
        state.description = description;
        state.labels = labels;
        state.currencyCode = currencyCode;
        state.currencySymbol = currencySymbol;
        state.originalPrice = originalPrice;
        state.convertedFromPrice = convertedFromPrice;
        state.category = category;
        state.isDigital = isDigital;
        state.hasInstantCheckout = hasInstantCheckout;
        state.quantity = quantity;
        state.shippingInformation = shippingInformation;
        state.isLiked = isLiked;
        state.totalLikes = totalLikes;
        if (getProductBanner.banner) {
          state.bannerDetails = parseProductBanner(getProductBanner.banner);
        }
      }
    });
    builder.addCase(getShopDetails.fulfilled, (state, action) => {
      state.shopDetails = parseShopResponse(action.payload);
    });
    builder.addCase(getProductEstimatedDelivery.fulfilled, (state, action) => {
      state.estimatedDeliveryTime = parseProductEstimatedDelivery(action.payload);
    });
    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state) => {
        if (state.shopDetails) {
          state.shopDetails.isFollowing = !state.shopDetails.isFollowing;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(onProductLikeToggle.pending, onProductLikeToggle.rejected),
      (state, action) => {
        const { productId } = action.meta.arg;

        if (productId === state.id) {
          state.totalLikes += state.isLiked ? -1 : 1;
          state.isLiked = !state.isLiked;
        } else {
          state.productCarousels?.forEach((carousel) =>
            carousel.products.forEach((product) => {
              if (product.id === productId) {
                product.isLiked = !product.isLiked;
              }
            }),
          );
        }
      },
    );
  },
});
