import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { IMojosState } from 'types';
import { getMojos } from './MojosActions';
import { getMojoCommentItems, getMojoCommentLikesUserDetails, parseMojoList } from '../apiParser';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import {
  getMojoCommentLikes,
  getMojoComments,
  onMojoComment,
  onMojoDeleteComment,
  onMojoFollowUserToggle,
  onMojoLikeCommentToggle,
  onMojoLikeToggle,
} from './mojo/MojoActions';
import {
  addCommentToMojo,
  addReplyToMojoComment,
  deleteCommentFromMojo,
  deleteReplyFromComment,
  getComment,
  getMojo,
} from './helper';
import { toCommentItem } from '../feed/helper';

const initialState: IMojosState = {
  list: null,
  paginationToken: null,
  currentIndex: 0,
  isLoading: false,
  isMuted: true,
  sessionId: '',
  sessionStartDate: null,
};

export const MojosSlicer = createSlice({
  name: 'mojos',
  initialState,
  reducers: {
    reset: () => initialState,
    onSessionStart: (state, action) => {
      const { source } = action.payload;
      state.sessionId = uuidv4();
      state.sessionStartDate = Date.now();
      AnalyticsEvents.onMojoSessionStart(state.sessionId, source);
    },
    onSessionEnd: (state, action) => {
      const { source } = action.payload;

      if (state.sessionStartDate) {
        const sessionDuration = Date.now() - state.sessionStartDate;
        AnalyticsEvents.onMojoSessionEnded(state.sessionId, source, sessionDuration);
      }
    },
    updateCurrentIndex: (state, action) => {
      const { index } = action.payload;
      state.currentIndex = index;
    },
    toggleMute: (state) => {
      state.isMuted = !state.isMuted;
    },
    toggleCommentReplies: (state, action) => {
      const { commentId, postId } = action.payload;

      const selectedMojo = state.list?.find((mojo) => mojo.id === postId);

      const comment = selectedMojo?.comments.list.find((comm) => comm.id === commentId);

      if (comment) {
        comment.isRepliesExpanded = !comment.isRepliesExpanded;
      }
    },
    onLikesClose: (state, action) => {
      const { mojoId, commentId, replyId } = action.payload;

      const mojo = getMojo(state.list || [], mojoId);

      if (mojo) {
        const comment = getComment(mojo, commentId);

        if (comment) {
          if (replyId) {
            const selectedReply = comment.replies?.find((reply) => reply.id === replyId);

            if (selectedReply) {
              selectedReply.likes = { list: [], paginationToken: null, hasMore: true };
            }
          } else {
            comment.likes = { list: [], paginationToken: null, hasMore: true };
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMojos.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMojos.fulfilled, (state, action) => {
      const { data, paginationToken } = action.payload;

      state.isLoading = false;

      state.paginationToken = paginationToken || null;

      const prevList = state.list || [];
      state.list = [...prevList, ...parseMojoList(data)];
    });
    builder.addCase(getMojoComments.fulfilled, (state, action) => {
      const { id, commentId } = action.meta.arg;

      const { comments, paginationToken } = action.payload;

      const selectedMojo = getMojo(state.list || [], id);

      if (selectedMojo) {
        if (commentId) {
          const comment = getComment(selectedMojo, commentId);

          if (comment) {
            const prevList = comment.replies || [];

            comment.replies = [...prevList, ...getMojoCommentItems(comments)];

            comment.isRepliesExpanded = true;
          }
        } else {
          selectedMojo.comments.paginationToken = paginationToken || null;

          const prevList = selectedMojo.comments.list || [];

          const token = paginationToken || selectedMojo.comments.paginationToken;

          selectedMojo.comments = {
            list: [...prevList, ...getMojoCommentItems(comments)],
            paginationToken: token,
          };
        }
      }
    });
    builder.addCase(getMojoCommentLikes.fulfilled, (state, action) => {
      const { commentId, mojoId } = action.meta.arg;

      const { data, paginationToken } = action.payload;

      const selectedMojo = getMojo(state.list || [], mojoId);

      if (selectedMojo) {
        const comment = getComment(selectedMojo, commentId);

        if (comment) {
          comment.likes.paginationToken = paginationToken || null;
          if (!paginationToken) {
            comment.likes.hasMore = false;
          }

          const prevList = comment.likes.list || [];

          comment.likes.list = [...prevList, ...getMojoCommentLikesUserDetails(data)];
        }
      }
    });
    builder.addCase(onMojoDeleteComment.fulfilled, (state, action) => {
      const { mojoId, commentId, replyId } = action.meta.arg;

      const selectedMojo = getMojo(state.list || [], mojoId);

      if (selectedMojo) {
        if (replyId) {
          const comment = getComment(selectedMojo, commentId);

          if (comment) {
            selectedMojo.totalComments -= 1;
            deleteReplyFromComment(comment, replyId);
          }
        } else {
          deleteCommentFromMojo(selectedMojo, commentId);
        }
      }
    });
    builder.addCase(onMojoComment.fulfilled, (state, action) => {
      const { parentCommentId, token, text, taggedUsers, image, postId } = action.meta.arg;

      const { id } = action.payload;

      const selectedMojo = state.list?.find((mojo) => mojo.id === postId);

      if (selectedMojo) {
        const comment = toCommentItem(id, text, taggedUsers, image, token || '');

        if (parentCommentId) {
          addReplyToMojoComment(selectedMojo, parentCommentId, comment);
        } else {
          addCommentToMojo(selectedMojo, comment);
        }
      }
    });
    builder.addMatcher(
      isAnyOf(onMojoLikeCommentToggle.pending, onMojoLikeCommentToggle.rejected),
      (state, action) => {
        const { commentId, postId, replyId } = action.meta.arg;

        const selectedMojo = getMojo(state.list || [], postId);

        if (selectedMojo) {
          let selectedComment = getComment(selectedMojo, commentId);
          if (replyId) {
            selectedComment = selectedComment?.replies?.find((reply) => reply.id === replyId);
          }

          if (selectedComment) {
            selectedComment.isLiked = !selectedComment.isLiked;
            selectedComment.totalLikes = selectedComment.isLiked
              ? selectedComment.totalLikes + 1
              : selectedComment.totalLikes - 1;
          }
        }
      },
    );
    builder.addMatcher(
      isAnyOf(onMojoLikeToggle.rejected, onMojoLikeToggle.pending),
      (state, action) => {
        const { id } = action.meta.arg;

        const selectedMojo = state.list?.find((mojo) => mojo.id === id);

        if (selectedMojo) {
          selectedMojo.isLiked = !selectedMojo.isLiked;
          selectedMojo.totalLikes = selectedMojo.isLiked
            ? selectedMojo.totalLikes + 1
            : selectedMojo.totalLikes - 1;
        }
      },
    );
    builder.addMatcher(
      isAnyOf(onMojoFollowUserToggle.rejected, onMojoFollowUserToggle.pending),
      (state, action) => {
        const { userId } = action.meta.arg;

        state.list?.forEach((mojo) => {
          if (mojo.userDetails?.userId === userId) {
            mojo.userDetails.isFollowing = !mojo.userDetails.isFollowing;
          }
        });
      },
    );
  },
});
