import { FC } from 'react';
import './notificationRow.scss';
import { Button } from 'tedooo-web-design-system/ui';
import classNames from 'classnames';

import { INotificationRow } from 'types';
import { getShortRelativeTime } from 'utils';
import NotificationImages from './NotificationImages';
import { Image } from 'components/helpers';

const NotificationRow: FC<INotificationRow> = ({
  id,
  isNew,
  link,
  titles,
  text,
  buttonDetails,
  date,
  prefixImage,
  suffixImage,
  imageGrid,
  onRowClick,
  onButtonClick,
}) => {
  const onButton = (e: any) => {
    e.stopPropagation();
    if (buttonDetails && buttonDetails.link) {
      onButtonClick(id, buttonDetails.link);
    }
  };

  const onNotificationClick = () => {
    if (link) {
      onRowClick(id, link);
    }
  };

  return (
    <div
      className={classNames('notification-row-container', { new: isNew })}
      onClick={onNotificationClick}
    >
      <div className="left-side">
        {isNew && <div className="dot" />}
        {prefixImage && <NotificationImages type={prefixImage.type} images={prefixImage.images} />}
        <div className="text-container">
          <div className="title">
            {titles.map((title, index) => (
              <div
                key={index}
                style={{ color: title.modifiers.color }}
                className={title.modifiers.font}
              >
                {title.text}
              </div>
            ))}
          </div>
          <div className="description">
            {text.map((title, index) => (
              <div
                key={index}
                style={{ color: title.modifiers.color }}
                className={classNames([title.modifiers.font, 'text'])}
              >
                {title.text}
              </div>
            ))}
            <span className="date">· {getShortRelativeTime(date)}</span>
          </div>
          {buttonDetails && (
            <Button
              size="small"
              borderRadius="large"
              text={buttonDetails.text}
              type={buttonDetails.type}
              onClick={onButton}
            />
          )}
        </div>
      </div>
      {imageGrid && (
        <div className="grid-wrapper">
          <div className="grid-container">
            {imageGrid.map((image) => (
              <Image key={image.src} src={image.src} withLoader withOverlay />
            ))}
          </div>
        </div>
      )}
      {suffixImage && <NotificationImages type={suffixImage.type} images={suffixImage.images} />}
    </div>
  );
};

export default NotificationRow;
