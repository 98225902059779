import { createAsyncThunk } from '@reduxjs/toolkit';

import { EventSources, IStore2, ITaggedUser } from 'types';
import { MojoApi } from './MojoApi';
import { ProfileApi } from '../../profile/ProfileApi';

const api = new MojoApi();

const userApi = new ProfileApi();

export const getMojoLink = createAsyncThunk(
  'async/getMojoLink',
  async (input: { postId: string }, { getState }: any) => {
    const { postId } = input;

    const { token } = getState().user;

    return api.getMojoLink(postId, token);
  },
);

export const onMojoLikeToggle = createAsyncThunk(
  'async/onMojoLikeToggle',
  async (
    input: {
      id: string;
      isLiked: boolean;
      source: EventSources;
      originSource?: EventSources;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, isLiked } = input;

    return api.onMojoLikeToggle(id, isLiked, token);
  },
);

export const onMojoDelete = createAsyncThunk(
  'async/onMojoDelete',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return api.onMojoDelete(id, token);
  },
);

export const onMojoComment = createAsyncThunk(
  'async/onMojoComment',
  async (
    input: {
      postId: string;
      text: string;
      image: string | null;
      taggedUsers: ITaggedUser[];
      parentCommentId?: string;
      token: string | null;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { postId, image, taggedUsers, text, parentCommentId } = input;

    const tags = taggedUsers.map((taggedUser) => taggedUser.userId);

    return api.addComment(token, postId, text, tags, image || undefined, parentCommentId);
  },
);

export const onMojoDeleteComment = createAsyncThunk(
  'async/onMojoDeleteComment',
  async (input: { mojoId: string; commentId: string; replyId?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, replyId } = input;

    const commentToDelete = replyId || commentId;

    return api.deleteMojoComment(token, commentToDelete);
  },
);

export const onMojoLikeCommentToggle = createAsyncThunk(
  'async/onMojoLikeCommentToggle',
  async (
    input: { commentId: string; isLiked: boolean; postId: string; replyId?: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, isLiked, replyId } = input;

    return api.onMojoCommentLikeToggle(token, replyId || commentId, isLiked);
  },
);

export const getMojoComments = createAsyncThunk(
  'async/getMojoComments',
  async (
    input: { id: string; paginationToken: string | null; commentId?: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, paginationToken, commentId } = input;

    return api.getComments(token, id, paginationToken, commentId);
  },
);

export const getMojoCommentLikes = createAsyncThunk(
  'async/getMojoCommentLikes',
  async (
    input: { commentId: string; mojoId: string; paginationToken: string | null },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { paginationToken, commentId } = input;

    return api.getCommentLikes(token, commentId, paginationToken);
  },
);

export const onMojoReport = createAsyncThunk(
  'async/onMojoReport',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return api.reportMojo(token, id);
  },
);

export const onMojoFollowUserToggle = createAsyncThunk(
  'async/onMojoFollowUserToggle',
  async (
    input: {
      userId: string;
      isFollowing: boolean;
      source: EventSources;
      originSource?: EventSources;
      sessionId?: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { userId, isFollowing } = input;

    const { token = '' } = state.user;

    return userApi.onFollowToggle(userId, isFollowing, token);
  },
);
