import {
  BasketRateAttributes,
  FixedPriceOptions,
  IAddressPreview,
  IShippingDestination,
  IShippingDestinationResponse,
  IShippingProfileRequest,
  ProcessingTimeOptions,
  ShippingCountriesType,
  ShippingPriceOptions,
} from 'types';
import { Countries } from 'constant/countries';
import { getRemainingCountries } from 'pages/createShop/popups/createShippingProfilePopup/helper';
import { ShippingFeature } from 'graphql/generated/graphql';

export const DEFAULT_DESTINATION = {
  countries: {
    type: ShippingCountriesType.EverywhereElse,
    values: [],
  },
  shippingServicesIds: [],
  isDomesticFreeShipping: false,
  isInternationalFreeShipping: false,
  fixedPrice: { dropdownValue: FixedPriceOptions.Fixed, firstItem: 0, additionalItem: 0 },
  deliveryTime: { dropdownValue: ProcessingTimeOptions.ThreeDays, min: 0, max: 0 },
};

export const parseToCreateShippingProfileBody = (
  profileName: string,
  shippingAddress: IAddressPreview,
  shippingDestinations: IShippingDestination[],
  processingTime: { dropdownValue: ProcessingTimeOptions; min: number; max: number },
  shippingPriceOption: ShippingPriceOptions,
): IShippingProfileRequest => {
  const parsedProcessingTime = mapProcessingTimeTypeToTime[processingTime.dropdownValue] || {
    min: processingTime.min,
    max: processingTime.max,
  };

  return {
    name: profileName,
    shippingPriceOption,
    shippingDestinations: parseToShippingDestinationsRequests(
      shippingDestinations,
      shippingPriceOption,
    ),
    workingDays: parsedProcessingTime,
    origin: shippingAddress,
  };
};

export const parseToShippingDestinationsRequests = (
  data: IShippingDestination[],
  shippingPriceOption: ShippingPriceOptions,
): IShippingDestinationResponse[] => {
  return data.map((item) => {
    let updatedDeliveryTime;
    if (shippingPriceOption === ShippingPriceOptions.FixedPrice) {
      updatedDeliveryTime = { min: item.deliveryTime.min, max: item.deliveryTime.max };
    }

    let fixedPrice;
    if (item.fixedPrice) {
      fixedPrice = {
        firstItem: Number(item.fixedPrice.firstItem.toFixed(2)) * 100,
        additionalItem: Number(item.fixedPrice.additionalItem.toFixed(2)) * 100,
      };
    }

    return {
      countries: item.countries,
      servicesIds: item.shippingServicesIds,
      isFreeInternational: item.isInternationalFreeShipping,
      isFreeDomestic: item.isDomesticFreeShipping,
      deliveryTime: updatedDeliveryTime,
      price: fixedPrice,
    };
  });
};

export const getDeliveryTimeOption = (min: number, max: number): ProcessingTimeOptions => {
  if (min === 1 && max === 3) {
    return ProcessingTimeOptions.ThreeDays;
  }
  if (min === 3 && max === 5) {
    return ProcessingTimeOptions.FiveDays;
  }
  if (min === 5 && max === 7) {
    return ProcessingTimeOptions.SevenDays;
  }

  return ProcessingTimeOptions.Custom;
};

export const mapProcessingTimeTypeToTime = {
  [ProcessingTimeOptions.ThreeDays]: { min: 1, max: 3 },
  [ProcessingTimeOptions.FiveDays]: { min: 3, max: 5 },
  [ProcessingTimeOptions.SevenDays]: { min: 5, max: 7 },
};

export const getDisabledCountries = (destinations: IShippingDestination[]): Countries[] => {
  const disabledCountries: Countries[] = [];
  destinations.forEach((item) => {
    disabledCountries.push(...item.countries.values);
  });

  return disabledCountries;
};

const ALL_COUNTRIES = getRemainingCountries([]);

export const isAllCountriesSelected = (destinations: IShippingDestination[]): boolean => {
  const allSelectedCountries = destinations.reduce(
    (acc, item) => acc + item.countries.values.length,
    0,
  );

  return allSelectedCountries === ALL_COUNTRIES.length;
};

export const getAllSelectedCountries = (destinations: IShippingDestination[]): Countries[] => {
  const allSelectedCountries: Countries[] = [];
  destinations.forEach((item) => {
    allSelectedCountries.push(...item.countries.values);
  });

  return allSelectedCountries;
};

export const getNewCountry = (selectedCountries: Countries[]): Countries[] => {
  const newCountry = Object.entries(Countries).find(
    (country) => !selectedCountries.includes(country[1]) && country[1] !== Countries.WorldWide,
  );

  return newCountry ? [newCountry[1]] : [];
};

export const mapShippingFeatureToAttribute: { [key in ShippingFeature]: BasketRateAttributes } = {
  [ShippingFeature.BestValue]: BasketRateAttributes.BestValue,
  [ShippingFeature.Fastest]: BasketRateAttributes.Fastest,
  [ShippingFeature.Cheapest]: BasketRateAttributes.Cheapest,
};
