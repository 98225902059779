import { createSlice } from '@reduxjs/toolkit';

import { IDrawerState } from './types';

const initialState: IDrawerState = {
  id: null,
};

export const DrawerSlicer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    show: (state, action) => {
      const { id } = action.payload;
      state.id = id;
    },
    hide: (state) => {
      state.id = null;
    },
  },
});
