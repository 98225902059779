import {
  IGetFollowersResponse,
  IGetProductResponse,
  IGetShopInformationResponse,
  IInitialShopReviewResponse,
  IShopApi,
  IShopProductResponseApi,
  IShopReviewsResponse,
  IShopsResponseApi,
  IStoreDetailsApi,
  Method,
} from 'types';
import { fetcher } from 'api';

export class ShopReducer implements IShopApi {
  getCatalog(shopId: string, token: string): Promise<IShopProductResponseApi[]> {
    return fetcher(`v3/products/shop/${shopId}`, Method.GET, token);
  }

  getShopInformation(token: string, shopId: string | null): Promise<IGetShopInformationResponse> {
    return fetcher(`v4/shops/editinfo`, Method.POST, token, { shopId, v2: true });
  }

  onShopReviewsLoadMore(
    shopId: string,
    maxId: string,
    token?: string,
  ): Promise<IShopReviewsResponse> {
    const query = {
      maxId,
    };

    return fetcher(`v2/review/initial/${shopId}`, Method.GET, token, undefined, query);
  }

  getShop(id: string, token?: string): Promise<IStoreDetailsApi> {
    return fetcher(`v3/shops/shop/${id}`, Method.GET, token);
  }

  getInitialReviews(shopId: string, token: string): Promise<IInitialShopReviewResponse> {
    return fetcher(`v2/review/initial/${shopId}`, Method.GET, token);
  }

  toggleFavorites(id: string, isFollowing: boolean, token?: string): Promise<void> {
    const method = isFollowing ? Method.DELETE : Method.POST;

    return fetcher(`follow/${id}`, method, token);
  }

  getSimilarShops(category: string, token?: string): Promise<IShopsResponseApi> {
    const queryParams = {
      text: category,
      limit: 5,
    };

    return fetcher(`shops2`, Method.GET, token, undefined, queryParams);
  }

  editCoverPhoto(coverPhoto: string, shopId: string, token?: string) {
    return fetcher(`v2/shops/cover/${shopId}`, Method.PATCH, token, { coverPhoto });
  }

  getFollowers(date?: number, token?: string): Promise<IGetFollowersResponse> {
    let queryParams: any = { full: 1 };

    if (date) {
      queryParams = { maxTime: date };
    }

    return fetcher('services/v2/followers', Method.GET, token, undefined, queryParams);
  }

  getProduct(id: string, token?: string): Promise<IGetProductResponse> {
    return fetcher(`v3/products/link/info/${id}`, Method.GET, token);
  }

  deleteProduct(token: string, productId: string): Promise<void> {
    return fetcher(`v3/products/${productId}`, Method.DELETE, token);
  }

  getProductLink(token: string, productId: string): Promise<{ link: string }> {
    return fetcher(`v3/products/link/get/${productId}`, Method.GET, token);
  }

  reorderCatalog(token: string, productId: string, toIndex: number, shopId: string): Promise<void> {
    const body = {
      id: productId,
      toIndex,
    };

    return fetcher(`v3/products/reorder/${shopId}`, Method.POST, token, body);
  }
}
