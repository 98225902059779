import {
  ICourier,
  IGetTrackingInformationResponse,
  ISpecificOrderApi,
  ISpecificOrderResponse,
  IUpdateOrderStatusBody,
  IUpdateOrderStatusResponse,
  IValidateShippingAddressResponse,
  Method,
} from 'types';
import { fetcher } from 'api';
import { GetDigitalFileUrlsResponse, SuccessResponse } from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  GET_ORDER_DIGITAL_FILES_QUERY,
  SEND_EMAILS_DIGITAL_FILES_MUTATION,
} from './SpecificOrderQueries';

export class SpecificOrderApi implements ISpecificOrderApi {
  getSpecificOrder(token: string, orderId: string): Promise<ISpecificOrderResponse> {
    return fetcher(`orders/v2/specific/${orderId}`, Method.GET, token);
  }

  requestOrder(
    token: string,
    items: any[],
    shopId: string,
    city: string,
    address: string,
    country: string,
    postalCode: string,
    name: string,
    phoneNumber: string,
    selectedDeliveryId: string | null,
    couponCode: string | null,
  ): Promise<{ id: string }> {
    const body = {
      items,
      shopId,
      city,
      address,
      country,
      name,
      selectedDeliveryId,
      postalCode,
      phoneNumber,
      couponCode,
    };

    return fetcher(`orders/v2/request`, Method.POST, token, body);
  }

  updateOrderStatus(
    token: string,
    orderId: string,
    body: IUpdateOrderStatusBody,
  ): Promise<IUpdateOrderStatusResponse> {
    return fetcher(`orders/tedooo/status/${orderId}`, Method.PATCH, token, body);
  }

  checkTrackingNumber(token: string, trackingNumber: string): Promise<ICourier[]> {
    const query = { trackingNumber };

    return fetcher(`orders/tedooo/carrier`, Method.GET, token, undefined, query);
  }

  getTrackingInformation(token: string, orderId: string): Promise<IGetTrackingInformationResponse> {
    return fetcher(`orders/v2/tracking/${orderId}`, Method.GET, token);
  }

  validateBuyerShippingAddress(
    token: string,
    country: string,
    city: string,
    address: string,
    postalCode: string,
  ): Promise<IValidateShippingAddressResponse> {
    const body = {
      country,
      city,
      address,
      postalCode,
    };

    return fetcher(`shipping/validate`, Method.POST, token, body);
  }

  async getOrderDigitalFiles(token: string, orderId: string): Promise<GetDigitalFileUrlsResponse> {
    const variables = { orderId };

    const res = await graphQlRequest(token).query({
      query: GET_ORDER_DIGITAL_FILES_QUERY,
      variables,
    });

    return res.data.getDigitalFileUrlsForOrder;
  }

  async sendEmailDigitalFiles(
    token: string,
    orderId: string,
    email: string,
  ): Promise<SuccessResponse> {
    const variables = { orderId, email };

    const res = await graphQlRequest(token).mutate({
      mutation: SEND_EMAILS_DIGITAL_FILES_MUTATION,
      variables,
    });

    return res.data.sendDigitalFilesEmail;
  }
}
