import { FC } from 'react';

import Reactions from './reactions/Reactions';
import ActionsMenu from './actionsMenu/ActionsMenu';
import './footer.scss';
import { IFooter } from 'types';

const Footer: FC<IFooter> = ({
  onLike,
  totalLikes,
  totalComments,
  totalReposts,
  onLikesClick,
  onCommentsClick,
  onRepostClick,
  avatar,
  onCommentSubmit,
  onShare,
  isLiked,
  isMojo,
  productDetails,
  isReposted,
  onCommentActionClick,
  onRepostActionClick,
}) => {
  return (
    <div className="post-footer-container">
      <Reactions
        totalLikes={totalLikes}
        totalComments={totalComments}
        totalReposts={totalReposts}
        onCommentsClick={onCommentsClick}
        onLikesClick={onLikesClick}
        onRepostClick={onRepostClick}
      />
      <ActionsMenu
        shouldDisplayInputByDefault
        isLiked={isLiked}
        isMojo={isMojo}
        productDetails={productDetails}
        isReposted={isReposted}
        avatar={avatar}
        onCommentSubmit={onCommentSubmit}
        onLike={onLike}
        onShare={onShare}
        onCommentActionClick={onCommentActionClick}
        onRepostActionClick={onRepostActionClick}
      />
    </div>
  );
};

export default Footer;
