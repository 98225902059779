import { isValidPhoneNumber } from 'libphonenumber-js';

export const validatePhoneNumber = (text: string, countryCode?: any) => {
  if (countryCode) {
    return isValidPhoneNumber(text, countryCode);
  }

  const REG = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  return REG.test(text);
};

export const validateEmail = (text: string) => {
  const REG = /^\S+@\S+\.\S+$/;

  return REG.test(text);
};
