import { FC } from 'react';
import classNames from 'classnames';

import { Image, Span } from '../helpers';

interface IDecorativeImage {
  text: string;
  icon: any;
  lottie?: any;
  className?: string;
  onClick?: (e: any) => void;
}

export const DecorativeImage: FC<IDecorativeImage> = ({ className, onClick, text, icon }) => {
  const renderIcon = () => {
    if (typeof icon === 'string') {
      return <Image className="image" src={icon} />;
    }

    return icon;
  };

  return (
    <div className={classNames('decorative-image', [className])} onClick={onClick}>
      {renderIcon()}
      <Span className="text" text={text} />
    </div>
  );
};
