import jwt_decode from 'jwt-decode';

import {
  ButtonTypes,
  Directions,
  DiscountTypes,
  DiscoverLayoutComponents,
  DiscoverListsId,
  DiscoverListTypes,
  DiscoverVisionsComponentType,
  EmailNotificationsTypes,
  FeedStaticItemsTypes,
  FixedPriceOptions,
  FontResponses,
  IAccountPreferenceResponse,
  IAccountPreferences,
  IAddressPreview,
  IBasketShippingRate,
  IBasketShippingRateResponse,
  IBlockedUsersResponse,
  ICartItem,
  IComment,
  ICommentItemResponseApi,
  ICreateShopCatalogItem,
  IDiscountDetails,
  IDiscoverCategory,
  IDiscoverLayoutGridDetails,
  IDiscoverListDetails,
  IDiscoverPageComponent,
  IDiscoverProduct,
  IDiscoverSectionTitle,
  IDiscoverStaticItem,
  IFeedBubble,
  IFeedCommentResponseApi,
  IFeedPost,
  IFeedResponseItemApi,
  IFilterState,
  IFollower,
  IFollowsSuggestionsResponse,
  IGetFollowersResponse,
  IGetInterestSectionResponse,
  IFollowsSuggestions,
  IGetPackagesTypeResponse,
  IGetPaymentSessionDetailsResponse,
  IGetPremiumPlanDetails,
  IGetPremiumPlanDetailsResponse,
  IGetProductEstimatedDeliveryResponse,
  IGetShippingLabelDetailsResponse,
  IGetShippingLabelUrlsResponse,
  IGetShippingRatesResponse,
  IGetShopsResponse,
  IGetShopViewersResponse,
  IGroupInSearchResults,
  IGroupResponse,
  IGroupRow,
  IGroupsResponse,
  IGroupsSearchResultResponse,
  IGroupSuggestion,
  IGroupSuggestionResponse,
  IImageContainerTypes,
  IInitialShopReviewResponse,
  IInterestItem,
  IInterestSectionResponse,
  ILabelsResponse,
  ILegacyPayoutMethod,
  ILegacyPayoutMethodResponse,
  ILikeResponseApi,
  ILinkedPaymentOption,
  ILinkedPaymentOptionsResponse,
  ImageComposition,
  IMojo,
  IMyPost,
  INotificationItem,
  INotificationItemResponse,
  INotificationsButton,
  INotificationsButtonResponse,
  INotificationsImage,
  INotificationsImageGroup,
  INotificationsImageGroupResponse,
  INotificationsImageResponse,
  INotificationsModifierResponse,
  INotificationsModifiers,
  IOnboardingShop,
  IOrderActivity,
  IOrderActivityResponse,
  IOrderDetails,
  IOrderItem,
  IOrderItemResponse,
  IOrderPartnerDetails,
  IOrderPartnerInfoResponse,
  IOrderResponse,
  IOrdersListState,
  IPackageType,
  IPackageTypesResponse,
  IPaymentSessionDetails,
  IPost,
  IPostAction,
  IPostNotificationsResponse,
  IPostProductDetails,
  IPrice,
  IPriceResponse,
  IProduct,
  IProductBannerDetails,
  IProductLabel,
  IProductShippingInformation,
  IProductShippingInformationResponse,
  IProductShopResponse,
  IProductsInSearchResults,
  IProductsSearchResultResponse,
  IProfileDetails,
  IProfileGroup,
  IProfileGroupResponse,
  IProfileInformationResponse,
  IProfileView,
  IProfileViewerResponse,
  IProfileViewItemResponse,
  IProfileViewsResponse,
  IRecentPostResponse,
  IRepostDetailsResponse,
  IRepostsResponse,
  IReview,
  IReviewRow,
  IReviewsResponseApi,
  ISearchTaggedUserResponse,
  ISelectedShippingService,
  ISelectedShippingServiceResponse,
  IShippingAddress,
  IShippingAddressResponse,
  IShippingDestination,
  IShippingDestinationResponse,
  IShippingDisabledRate,
  IShippingDisabledRateResponse,
  IShippingLabelDetails,
  IShippingLabelDownloadUrls,
  IShippingProfile,
  IShippingProfileResponse,
  IShippingRate,
  IShippingRateResponse,
  IShopActivity,
  IShopActivityResponseApi,
  IShopBasket,
  IShopInSearchResults,
  IShopOfTheDay,
  IShopProductResponseApi,
  IShopReviewResponse,
  IShopReviewsState,
  IShopSearchResultResponse,
  IShopViewers,
  ISimilarShop,
  ISimilarShopResponse,
  ISpecificOrderResponse,
  ISpecificPostResponseApi,
  IStaticItem,
  IStoreDetails,
  IStoreDetailsApi,
  ISubPost,
  ISubPostResponseApi,
  ISuggestedShop,
  ITaggedUser,
  ITaggedUserResponseApi,
  ITagUser,
  ITip,
  ITipDisplayItem,
  ITipDisplayItemResponse,
  ITipResponse,
  IToken,
  IUserDetails,
  IUserInSearchResults,
  IUserOrdersResponse,
  IUserOrderStatistics,
  IUserReview,
  IUserReviewApi,
  IUserSearchResultResponse,
  IUserShopInfo,
  IVision,
  IVisionOffer,
  IVisionRequest,
  IVisionsCreditOption,
  IVisionsPageItem,
  NotificationsTypes,
  OrderStatuses,
  PageOptions,
  PayoutMethods,
  PostActionType,
  ProcessingTimeOptions,
  ProductAvailabilityItem,
  ReturnPolicyType,
  ShippingPriceOptions,
  TagVariants,
  TextBackground,
  VisionOffersTab,
  VisionOfferType,
  IShippingProfilePriceResponse,
  ShippingCountriesType,
  IShippingProfileDeliveryTimeParserInput,
} from 'types';
import {
  formatTimeStampToMilliseconds,
  isAllZero,
  isEmptyArray,
  isEmptyArrayOrUndefined,
  parseObject,
  toFormat,
  toShortFormat,
} from 'utils';
import { CurrencyCode } from 'constant/currencies';
import { getDefaultAvatar, mapTipFontResponseToFont } from './user/helper';
import { getDeliveryTimeOption, mapShippingFeatureToAttribute } from './shipping/helper';
import { Countries } from 'constant/countries';
import {
  CartItem,
  CartItemStatus,
  CartShop,
  CountriesTypeType,
  CreatePaymentIntentResult,
  DiscoverPageCategoriesItem,
  DiscoverPageLayout1,
  DiscoverPageLayout2,
  DiscoverPageLayout3,
  DiscoverPageProduct,
  DiscoverPageStaticItem,
  DiscoverPageVision,
  DiscoverPageVisions,
  EqualGridProduct,
  FeedItem,
  FeedRepostPostDetails,
  FeedTag,
  GetMojoWhoLikedCommentUser,
  GetPaymentCheckoutResponse,
  GetVisionPaymentOptionResponse,
  HorizontalListProduct,
  Label,
  LastComment,
  ListData,
  Maybe,
  Mojo,
  MojoComment,
  MojoItem,
  ProductBanner,
  ProductItem,
  ProductItemV2,
  ProductShippingInformation,
  ShippingAddress,
  ShippingProfile,
  ShippingProfileDestinationDetails,
  ShippingRate,
  ShopOfTheDay,
  StyledText,
  TaggedUser,
  Vision,
  VisionCreditOfferWeb,
  VisionOffer,
  VisionRequest,
} from 'graphql/generated/graphql';
import PayPalLogo from 'photos/paypal-logo.png';
import { mapReturnsPolicyResponseToType } from './visions/helper';
import { Fonts } from 'constant/fonts';
import { parseStripeTitle } from './payouts/helper';
import { translations } from '../translations/en';
import { ALL } from '../pages/feed/feedBubblesCarousel/helper';
import { getSubTotalPrice, getTotalDiscount } from './helper';

const DEFAULT_PRODUCT_QUANTITY = 300;

export const buildFilter = (filters: IFilterState, page?: PageOptions) => {
  const query: any = {};

  const { search, minRate, countries, companyTypes, sortBy } = filters;

  if (search && search !== '') {
    query.text = search;
  }
  if (companyTypes.length > 0) {
    query.types = companyTypes.join(',');
  }
  if (countries.length > 0) {
    query.countries = countries.join(',');
  }
  if (minRate) {
    query.stars = minRate;
  }
  if (sortBy && sortBy.type !== '') {
    query[sortBy.type] = sortBy.direction === Directions.DESC;
  }
  if (page === PageOptions.history) {
    query.history = true;
  } else if (page === PageOptions.following) {
    query.fav = true;
  } else if (page === PageOptions.myBusinesses) {
    query.userId = true;
  } else if (page === PageOptions.notes) {
    query.noteOnly = true;
  }

  return query;
};

const getUserReview = (userReview?: IUserReviewApi): IUserReview | undefined => {
  if (!userReview) {
    return undefined;
  }

  return {
    date: userReview.creationDate,
    images: userReview.images,
    shopId: userReview.shop,
    rate: userReview.star,
    title: userReview.title,
    userId: userReview.userId,
    text: userReview.text,
    id: userReview._id,
  };
};

const parseGroups = (data: IGroupsResponse[]): IGroupRow[] => {
  return data.map((group) => ({
    image: group.image || null,
    name: group.name,
    totalParticipants: group.totalParticipants,
  }));
};

const parseActivities = (activities: IShopActivityResponseApi[]): IShopActivity[] => {
  return activities.map((activity) => ({
    id: activity.id,
    text: activity.text,
    date: activity.date,
    image: activity.image || null,
    repostId: activity.repostId || null,
  }));
};

const getProductLabels = (labels: ILabelsResponse[] | Label[]): IProductLabel[] => {
  return labels.map((label) => ({
    text: label.texts[0].text || '',
    backgroundColor: label.backgroundColor.substring(2) || '',
    textColor: label.textColor || '',
  }));
};

export const parseProduct = (product: IShopProductResponseApi): IProduct => {
  const { price, labels = [], originalPrice } = product;

  const formattedPrice = price / 100;

  const formattedOriginalPrice = originalPrice ? originalPrice / 100 : formattedPrice;

  const images = [...(product.variants || [])];
  images.unshift(product.imageUrl);

  const parsedLabels = getProductLabels(labels);

  const shippingInformation = product.shippingInformation
    ? getProductShippingInformation(product.shippingInformation)
    : null;

  const convertedFromPrice = product.convertedFrom ? getPrice(product.convertedFrom) : null;

  const quantity = product.quantity !== undefined ? product.quantity : DEFAULT_PRODUCT_QUANTITY;

  return {
    id: product.id,
    title: product.title || '',
    description: product.description || '',
    images,
    currencyCode: product.currencyCode || CurrencyCode.USD,
    price: formattedPrice,
    originalPrice: formattedOriginalPrice,
    convertedFromPrice,
    currencySymbol: product.currency,
    labels: parsedLabels,
    shippingInformation,
    isDigital: !!product.isDigital,
    hasInstantCheckout: !!product.hasInstantCheckoutV2,
    quantity,
    tariffNumber: product.tariff || null,
    category: product.selectedCategory || null,
    isLiked: product.didLike,
    totalLikes: product.totalLikes,
  };
};

export const parseProducts = (products: IShopProductResponseApi[]): IProduct[] => {
  return products.map((product) => parseProduct(product));
};

export const parseReview = (review: IShopReviewResponse): IReviewRow => {
  return {
    images: review.images,
    name: review.username,
    avatar: review.avatar || null,
    text: review.description || null,
    title: review.title || null,
    date: review.date,
    value: review.stars,
    id: review._id,
  };
};

const parseSimilarShop = (shop: ISimilarShopResponse): ISimilarShop => {
  const {
    id,
    userId,
    name,
    city,
    images,
    following,
    rating,
    avatar,
    totalReviews,
    country,
    username,
    premium,
    category,
  } = shop;
  let location = country;
  if (city) {
    location = `${city}, ${country}`;
  }

  return {
    category,
    images: images || [],
    isPremium: premium,
    username,
    id,
    userId,
    name,
    isFollowing: following,
    avatar: avatar || getDefaultAvatar(shop.userId),
    totalReviews,
    location,
    rate: rating,
  };
};

const parseSimilarShopsResponse = (similarShops: ISimilarShopResponse[]): ISimilarShop[] => {
  return similarShops.map(parseSimilarShop);
};

export const parseShopResponse = (data: IStoreDetailsApi): IStoreDetails => {
  const {
    posts,
    shop,
    products,
    groups,
    reviews,
    similarShops,
    authorizationStatus,
    hasNewItems,
    profileViews,
    totalLikes,
    vacationInfo,
    payments,
    reviewEnabled,
  } = data;

  const {
    _id,
    fav,
    name,
    shop_number,
    map: mapImage,
    avg,
    countries,
    foundedCountry,
    shippingType,
    keywords,
    city,
    description = '',
    address,
    userDetails,
    totalReviews,
    premium,
    category: categories,
    userId,
    market_name: marketName,
    favs,
    totalFollowing,
    coverPhoto,
    userReview,
    userId2,
    currencyCode,
    marked,
  } = shop;

  const isFollowing = fav === 1;

  const category = categories![0];

  const parsedGroups = parseGroups(groups);

  const parsedUserReview = getUserReview(userReview);

  const catalog = parseProducts(products);

  const parsedReviews = reviews.map(parseReview);

  const activities = parseActivities(posts);

  const parsedSimilarShops = parseSimilarShopsResponse(similarShops);

  const parsedAuthorizationStatus = Number(authorizationStatus);

  let coverImage = coverPhoto || null;

  const connectedPayouts = payments.map((payment) => payment.provider);

  if (
    !coverPhoto &&
    !isEmptyArrayOrUndefined(catalog) &&
    !isEmptyArrayOrUndefined(catalog[0]?.images)
  ) {
    coverImage = catalog[0].images[0];
  }

  const parsedUserDetails = {
    id: userDetails[0]._id,
    avatar: userDetails[0].avatar || getDefaultAvatar(userDetails[0]._id),
    username: userDetails[0].username,
    fullName: userDetails[0].fullName,
  };

  return {
    country: foundedCountry,
    countries: countries || [],
    address,
    id: _id,
    similarShops: parsedSimilarShops,
    activities,
    isFollowing,
    connectedPayouts,
    city,
    isPremium: premium,
    reviews: parsedReviews,
    description,
    catalog,
    categories,
    userId,
    category,
    totalFollowers: favs,
    totalFollowing,
    ownersIds: userId2,
    userDetails: parsedUserDetails,
    name,
    shop_number,
    shippingType: shippingType || [],
    averageRating: avg,
    userReview: parsedUserReview,
    coverImage,
    mapImage,
    keywords,
    totalReviews,
    marketName,
    authorizationStatus: parsedAuthorizationStatus,
    hasNewItems,
    groups: parsedGroups,
    totalProfileViews: profileViews,
    currencyCode,
    totalSavedProducts: totalLikes || 0,
    isMarkedAsCrystalShop: !!marked,
    vacationDetails: vacationInfo || null,
    isReviewEnabled: reviewEnabled,
  };
};

export const parseReviews = (storeDetailsApi: IReviewsResponseApi): Array<IReview> => {
  const { data } = storeDetailsApi;

  return data.map((item) => ({
    id: item._id,
    userId: item.userId,
    date: item.creationDate,
    images: item.images,
    name: item.name,
    text: item.text,
    title: item.title,
    rate: item.star,
    avatar: item.avatar || getDefaultAvatar(item.userId),
  }));
};

const getPostAction = (reason: PostActionType, reasonExtra: string): IPostAction | null => {
  if (!reason) {
    return null;
  }

  return {
    type: reason,
    totalUsers: reasonExtra?.split(' ')[2],
    username: reasonExtra?.split(' ')[0] || '',
  };
};

const getComment = (token?: string, comments?: Array<IFeedCommentResponseApi>): Array<IComment> => {
  let parsedToken = {
    avatar: null,
    username: '',
    userId: '',
    firstName: '',
  };
  if (token) {
    parsedToken = jwt_decode<any>(token);
  }

  if (!comments) {
    return [];
  }

  return comments.map((comment) => ({
    avatar: parsedToken?.avatar || getDefaultAvatar(comment.userId),
    username: parsedToken?.username,
    fullName: parsedToken?.firstName,
    text: comment.text,
    totalLikes: 0,
    totalReplies: 0,
    isLiked: false,
    id: comment._id,
    userId: parsedToken.userId,
    date: new Date(comment.date).getTime(),
    isPremium: false,
    isReply: false,
    isRepliesExpanded: false,
    image: comment.image || null,
    replies: [],
    likes: {
      list: null,
      paginationToken: null,
      hasMore: true,
    },
    isDeleted: comment.deleted,
    taggedUsers: [],
  }));
};

const getUserDetails = (
  feed: IFeedResponseItemApi | IRepostDetailsResponse | FeedItem,
): IUserDetails => {
  return {
    avatar: feed.avatar || getDefaultAvatar(feed.userId),
    username: feed.username,
    shopName: feed.shopName || '',
    shopImage: feed.shopImage || '',
    fullName: feed.fullName,
    date: feed.date,
    isPremium: feed.premium,
    userId: feed.userId,
    shopId: feed.shopId || null,
    isFollowing: feed.following,
  };
};

export const getMojoCommentLikesUserDetails = (
  data: GetMojoWhoLikedCommentUser[],
): IUserDetails[] => {
  return data.map((item) => ({
    avatar: item.avatar || getDefaultAvatar(item.userId),
    username: item.username,
    shopName: item.shopName || '',
    shopImage: item.shopImage || '',
    fullName: item.name,
    date: 0,
    isPremium: item.isPremium,
    userId: item.userId,
    shopId: item.shopId || '',
    isFollowing: item.following,
  }));
};

const getPostProductDetails = (
  image?: string,
  id?: string,
  title?: string,
  price?: IPriceResponse,
): IPostProductDetails | null => {
  if (!id) {
    return null;
  }

  const parsedPrice = price
    ? getPrice(price)
    : { value: 0, currencyCode: CurrencyCode.USD, currencySymbol: '$' };

  return {
    id,
    title: title || '',
    price: parsedPrice,
    image: image || '',
  };
};

export const getPrice = (price: IPriceResponse): IPrice => {
  const { price: value, currency, currencyCode } = price;

  return {
    currencyCode: currencyCode as CurrencyCode,
    value: value / 100,
    currencySymbol: currency,
  };
};

const getLoggedInUserId = (token?: string | null) => {
  if (!token) {
    return null;
  }

  return jwt_decode<IToken>(token).userId || null;
};

// only in reposts list
const parseRepostWithoutText = (post: IPost, details: IRepostsResponse): IPost => {
  return {
    ...post,
    actionId: post.actionId,
    id: details.id,
    lastComment: null,
    action: {
      type: PostActionType.Repost,
      username: details.userDetails.username,
    },
  };
};

// only in reposts list
const parseRepostWithText = (post: IPost, details: IRepostsResponse): IPost => {
  return {
    actionId: details.id,
    isCommentsExpanded: false,
    likes: {
      list: null,
      hasMore: true,
    },
    id: details.id,
    shopId: details.repostDetails?.shopDetails?.id || null,
    isLiked: details.didLike,
    isFollowing: post.userDetails.isFollowing,
    text: details.repostDetails?.text || '',
    action: null,
    totalComments: details.repostDetails?.comments || 0,
    lastComment: null,
    selfComments: [],
    comments: {
      list: null,
      hasMore: true,
    },
    repostDetails: post,
    lastEdited: details.repostDetails?.lastEdited || 0,
    isEditable: false,
    images: [],
    mojoDetails: null,
    imageComposition: 0,
    totalLikes: details.repostDetails?.comments || 0,
    totalReposts: post.totalReposts,
    productDetails: post.productDetails,
    userDetails: {
      userId: details.userDetails.id,
      avatar: details.userDetails.avatar || getDefaultAvatar(details.userDetails.id),
      username: details.userDetails.username,
      fullName: details.userDetails.name,
      date: details.date,
      shopId: details.repostDetails?.shopDetails?.id || null,
      shopName: details.repostDetails?.shopDetails?.name || '',
      isFollowing: !!details.repostDetails?.isFollowing,
      isPremium: !!details.repostDetails?.isPremium,
      shopImage: details.repostDetails?.shopDetails?.avatar || '',
    },
    totalFollowers: 0,
    totalFollowing: 0,
    isReposted: post.isReposted,
    taggedUsers: post.taggedUsers,
    shouldDisplayHideFromActivity: false,
    isShoppingPost: !!details.repostDetails?.isShoppingPost,
    shouldHideDate: post.shouldHideDate,
  };
};

export const parsePostToRepostsList = (data: IRepostsResponse[], post: IPost): IPost[] => {
  return data.map((item) => {
    if (!item.repostDetails) {
      return parseRepostWithoutText(post, item);
    }

    return parseRepostWithText(post, item);
  });
};

export const getPostTaggedUsers = (users: { [key: string]: string } | undefined): ITaggedUser[] => {
  if (!users) {
    return [];
  }

  return Object.keys(users).map((username) => ({
    username,
    userId: users[username],
  }));
};

const parseFeedItemRepostDetails = (details: IRepostDetailsResponse): IPost => {
  const repostImages = details.images ?? [];

  const productDetails = getPostProductDetails(
    repostImages[0],
    details.productId,
    details.title,
    details.price,
  );

  const images = productDetails ? [] : repostImages;

  return {
    actionId: details._id,
    isCommentsExpanded: false,
    likes: {
      list: null,
      hasMore: true,
    },
    id: details._id,
    shopId: details.shopId,
    isLiked: false,
    isFollowing: details.following,
    text: details.backgroundText || details.text || '',
    action: null,
    totalComments: details.comments,
    lastComment: null,
    selfComments: null,
    comments: {
      list: null,
      hasMore: true,
    },
    repostDetails: null,
    lastEdited: details.lastEdited || null,
    isEditable: false,
    images,
    mojoDetails: null,
    backgroundIndex: details.backgroundIndex,
    imageComposition: details.composition,
    totalLikes: details.likes,
    totalReposts: details.reposts,
    productDetails,
    userDetails: getUserDetails(details),
    totalFollowers: 0,
    totalFollowing: 0,
    isReposted: false,
    taggedUsers: getPostTaggedUsers(details.tags),
    shouldDisplayHideFromActivity: false,
    isShoppingPost: !!details.isShoppingPost,
    shouldHideDate: !!details.shouldHideDate,
  };
};

const getFeedItem = (feed: IFeedResponseItemApi, token?: string): IFeedPost => {
  const loggedInUserId = getLoggedInUserId(token);

  const isPostOwner = loggedInUserId === feed.userId;

  const lastComment = feed.lastComment ? getCommentItem(feed.lastComment) : null;

  const repostDetails = feed.repostDetails ? parseFeedItemRepostDetails(feed.repostDetails) : null;

  const totalReposts = repostDetails ? 0 : feed.reposts;

  const actionId = feed.repostDetails ? feed._id : feed.repostId || feed._id;

  const taggedUsers = getPostTaggedUsers(feed.tags);

  const productImage = feed.productId && feed.images ? feed.images[0] : undefined;

  const productDetails = getPostProductDetails(
    productImage,
    feed.productId,
    feed.title,
    feed.price,
  );

  const images = productDetails ? [] : feed?.images || [];

  const shouldDisplayHideFromActivity = !!taggedUsers.find(
    (user) => user.userId === loggedInUserId && !isPostOwner,
  );

  return {
    order: 0,
    actionId,
    isCommentsExpanded: false,
    likes: {
      list: null,
      hasMore: true,
    },
    id: feed._id,
    shopId: feed.shopId || null,
    isLiked: feed.didLike,
    isFollowing: feed.following || isPostOwner,
    text: feed.backgroundText || feed.text || '',
    action: getPostAction(feed.reason, feed.reasonExtra || ''),
    totalComments: feed.comments,
    lastComment,
    selfComments: getComment(token, feed?.selfComments),
    comments: {
      list: null,
      hasMore: true,
    },
    repostDetails,
    lastEdited: feed.lastEdited || null,
    isEditable: feed.editable,
    images,
    mojoDetails: null,
    backgroundIndex: feed.backgroundIndex,
    imageComposition: feed.composition,
    totalLikes: feed.likes,
    totalReposts,
    productDetails,
    userDetails: getUserDetails(feed),
    totalFollowers: feed.totalFollowers,
    totalFollowing: feed.totalFollowing,
    orderId: feed.orderId,
    isReposted: feed.didRepost,
    taggedUsers,
    shouldDisplayHideFromActivity,
    isShoppingPost: !!feed.isShoppingPost,
    shouldHideDate: !!feed.shouldHideDate,
  };
};

export const getCommentTaggedUsers = (users: ITaggedUserResponseApi[] | TaggedUser[]) => {
  if (!users) return [];

  return users.map((user) => ({
    username: user.tag.substring(1),
    userId: user.userId,
  }));
};

export const getCommentItem = (comment: ICommentItemResponseApi): IComment => {
  return {
    userId: comment.userId,
    id: comment._id || comment.id,
    date: new Date(comment.date).getTime(),
    totalReplies: comment.replies || 0,
    isRepliesExpanded: false,
    avatar: comment.avatar || getDefaultAvatar(comment.userId),
    text: comment.text,
    username: comment.username,
    fullName: comment.fullName,
    replies: [],
    image: comment.image || null,
    isPremium: comment.premium,
    totalLikes: comment.likes || 0,
    isDeleted: !!comment.deleted,
    isLiked: comment.didLike || false,
    likes: {
      list: null,
      paginationToken: null,
      hasMore: true,
    },
    taggedUsers: getCommentTaggedUsers(comment.taggedUser),
  };
};

export const getMojoCommentItems = (comments: MojoComment[]): IComment[] => {
  return comments.map((comment) => {
    return {
      userId: comment.userId,
      id: comment.id,
      date: comment.date,
      totalReplies: comment.replies,
      isRepliesExpanded: false,
      avatar: comment.avatar || getDefaultAvatar(comment.userId),
      text: comment.text || '',
      username: comment.username,
      fullName: comment.fullName,
      replies: [],
      image: comment.image || null,
      isPremium: comment.isPremium,
      totalLikes: comment.likes || 0,
      isDeleted: comment.isDeleted,
      isLiked: comment.didLike || false,
      likes: {
        list: null,
        paginationToken: null,
        hasMore: true,
      },
      taggedUsers: getCommentTaggedUsers(comment.taggedUsers),
    };
  });
};

export const getLikeItem = (like: ILikeResponseApi): IUserDetails => {
  return {
    id: like._id,
    userId: like.userId,
    date: new Date(like.date).getTime(),
    shopImage: '',
    fullName: like.fullName,
    avatar: like.avatar || getDefaultAvatar(like.userId),
    username: like.username,
    shopName: like.shopName,
    shopId: like.shopId || null,
    isPremium: like.premium,
    isFollowing: like.following,
  };
};

export const getSubPostItem = (subPost: ISubPostResponseApi): ISubPost => {
  return {
    actionId: subPost._id,
    id: subPost._id,
    totalLikes: subPost.likes,
    isLiked: subPost.didLike,
    totalComments: subPost.comments,
    isCommentsExpanded: true,
    comments: {
      list: null,
      hasMore: true,
    },
    likes: {
      list: null,
      hasMore: true,
    },
  };
};

export const getFeedItems = (
  data: Array<IFeedResponseItemApi>,
  token: string,
): Array<IFeedPost> => {
  return [...data.map((item) => getFeedItem(item, token))];
};

export const getModularTaggedUsers = (data: FeedTag[]): ITaggedUser[] => {
  return data.map((item) => ({
    userId: item.value,
    username: item.id,
  }));
};

export const getModularRepostDetails = (data: FeedRepostPostDetails): IPost => {
  const backgroundIndex = data.backgroundIndex
    ? (data.backgroundIndex as TextBackground)
    : undefined;

  const productDetails = data.productDetails
    ? getPostProductDetails(
        data.images[0],
        data.productDetails.productId,
        data.productDetails.title,
        data.productDetails.price,
      )
    : null;

  const images = productDetails ? [] : data.images;

  return {
    actionId: data.id,
    isCommentsExpanded: false,
    likes: {
      list: null,
      hasMore: true,
    },
    id: data.id,
    isShoppingPost: data.isShoppingPost,
    shopId: data.shopId || null,
    isLiked: false,
    isFollowing: false,
    text: data.text,
    action: null,
    totalComments: data.comments,
    lastComment: null,
    selfComments: null,
    mojoDetails: null,
    comments: {
      list: null,
      hasMore: true,
    },
    repostDetails: null,
    lastEdited: null,
    isEditable: false,
    images,
    backgroundIndex,
    imageComposition: data.composition,
    totalLikes: data.likes,
    totalReposts: data.reposts,
    productDetails,
    userDetails: {
      id: data.userId,
      username: data.username,
      fullName: data.fullName,
      avatar: data.avatar || getDefaultAvatar(data.userId),
      date: data.date,
      shopId: data.shopId || null,
      shopName: data.shopName || '',
      shopImage: data.shopImage || '',
      userId: data.userId,
      isFollowing: false,
      isPremium: data.premium,
    },
    totalFollowers: 0,
    totalFollowing: 0,
    isReposted: false,
    taggedUsers: getModularTaggedUsers(data.tags),
    shouldDisplayHideFromActivity: false,
    shouldHideDate: false,
  };
};

export const getModularCommentItem = (comment: LastComment): IComment => {
  return {
    userId: comment.userId,
    id: comment.id || comment.id,
    date: comment.date,
    totalReplies: comment.replies || 0,
    isRepliesExpanded: false,
    avatar: comment.avatar || getDefaultAvatar(comment.userId),
    text: comment.text || '',
    username: comment.username,
    fullName: comment.fullName,
    replies: [],
    image: comment.image || null,
    isPremium: comment.premium,
    totalLikes: comment.likes || 0,
    isDeleted: false,
    isLiked: comment.didLike || false,
    likes: {
      list: null,
      paginationToken: null,
      hasMore: true,
    },
    taggedUsers: [],
  };
};

export const parseFeedFiltersExtras = (extrasJson: string | null): IFeedBubble[] | null => {
  if (!extrasJson) return null;

  const extras = parseObject(extrasJson);

  if (extras) {
    const allBubble = { text: translations.FEED.FEED_FILTERS.ALL, filter_key: ALL };

    return [allBubble, ...extras].map((extra) => ({
      text: extra.text,
      id: extra.filter_key,
    }));
  }

  return null;
};

export const parseStaticItems = (data: DiscoverPageStaticItem[]): IStaticItem[] => {
  return data.map((item) => {
    let extras;

    const staticItemType = item.key as FeedStaticItemsTypes;

    if (staticItemType === FeedStaticItemsTypes.FeedFilters) {
      extras = parseFeedFiltersExtras(item.extras || null);
    } else {
      // As more cases are added, they would be additional else if statements
      extras = null;
    }

    return {
      id: item.id,
      key: item.key,
      extras,
    };
  });
};

const getModularFeedItem = (feed: FeedItem, token: string): IFeedPost => {
  const loggedInUserId = getLoggedInUserId(token);

  const isPostOwner = loggedInUserId === feed.userId;

  const lastComment = feed.lastComment ? getModularCommentItem(feed.lastComment) : null;

  const repostDetails = feed.repostDetails ? getModularRepostDetails(feed.repostDetails) : null;

  const totalReposts = repostDetails ? 0 : feed.reposts;

  const actionId = feed.repostDetails ? feed.id : feed.repostId || feed.id;

  const taggedUsers = getModularTaggedUsers(feed.tags);

  const productDetails = getPostProductDetails(
    feed.images[0],
    feed.productDetails?.productId,
    feed.productDetails?.title,
    feed.productDetails?.price,
  );

  const images = productDetails ? [] : feed.images;

  const backgroundIndex = feed.backgroundIndex
    ? (feed.backgroundIndex as TextBackground)
    : undefined;

  const shouldDisplayHideFromActivity = !!taggedUsers.find(
    (user) => user.userId === loggedInUserId && !isPostOwner,
  );

  return {
    order: feed.sectionDetails.order,
    actionId,
    isCommentsExpanded: false,
    likes: {
      list: null,
      hasMore: true,
    },
    id: feed.id,
    shopId: feed.shopId || null,
    isLiked: feed.didLike,
    isFollowing: feed.following || isPostOwner,
    text: feed.text || '',
    action: getPostAction(feed.reason, feed.reasonExtra),
    totalComments: feed.comments,
    lastComment,
    selfComments: [],
    comments: {
      list: null,
      hasMore: true,
    },
    repostDetails,
    lastEdited: feed.editedDate || null,
    isEditable: feed.editable,
    images,
    mojoDetails: null,
    backgroundIndex,
    imageComposition: feed.composition,
    isShoppingPost: feed.isShoppingPost,
    totalLikes: feed.likes,
    totalReposts,
    productDetails,
    userDetails: getUserDetails(feed),
    totalFollowers: feed.totalFollowers,
    totalFollowing: feed.totalFollowing,
    isReposted: feed.didRepost,
    taggedUsers,
    shouldDisplayHideFromActivity,
    shouldHideDate: feed.hideDate,
  };
};

export const getModularFeedItems = (data: FeedItem[], token: string): IFeedPost[] => {
  return [...data.map((item) => getModularFeedItem(item, token))];
};

export const getMojoFeedItems = (data: MojoItem[]): IFeedPost[] => {
  return data.map((item) => {
    const productDetails = item.productDetails
      ? getPostProductDetails(
          item.productDetails.image,
          item.productDetails.productId,
          item.productDetails.title,
          item.productDetails.price,
        )
      : null;

    return {
      order: item.sectionDetails.order,
      actionId: item.id,
      isCommentsExpanded: false,
      likes: {
        list: null,
        hasMore: true,
      },
      id: item.id,
      shopId: null,
      isLiked: item.didLike,
      isFollowing: item.following,
      text: item.text || '',
      action: null,
      totalComments: item.comments,
      lastComment: null,
      selfComments: [],
      isShoppingPost: false,
      comments: {
        list: null,
        hasMore: true,
      },
      repostDetails: null,
      lastEdited: null,
      isEditable: false,
      images: [],
      mojoDetails: { videoUrl: item.videoUrl, thumbnailUrl: item.thumbnailUrl },
      backgroundIndex: undefined,
      imageComposition: ImageComposition.one,
      totalLikes: item.likes,
      totalReposts: 0,
      productDetails,
      userDetails: {
        id: item.userId,
        username: item.username,
        fullName: item.fullName,
        avatar: item.avatar || getDefaultAvatar(item.userId),
        date: item.date,
        shopId: null,
        shopName: '',
        shopImage: '',
        userId: item.userId,
        isFollowing: item.following,
        isPremium: item.premium,
      },
      totalFollowers: 0,
      totalFollowing: 0,
      isReposted: false,
      taggedUsers: [],
      shouldDisplayHideFromActivity: false,
      shouldHideDate: false,
    };
  });
};

export const getCommentsItems = (data: Array<ICommentItemResponseApi>): Array<IComment> => {
  return [...data.map((item) => getCommentItem(item))];
};

export const getLikesItems = (data: Array<ILikeResponseApi>): Array<IUserDetails> => {
  return [...data.map((item) => getLikeItem(item))];
};

export const getSubPostsItems = (data: Array<ISubPostResponseApi> | null): Array<ISubPost> => {
  if (!data) {
    return [];
  }

  return [...data.map((item) => getSubPostItem(item))];
};

export const getSpecificPostSubPosts = (
  postInfo: Array<ISubPostResponseApi> | null,
  post: IPost,
): Array<ISubPost> => {
  const isSubPostIsMainPost = isEmptyArray(postInfo) && !isEmptyArray(post.images);

  if (isSubPostIsMainPost) {
    return [
      {
        actionId: post.id,
        totalComments: post.totalComments,
        totalLikes: post.totalLikes,
        id: post.id,
        isLiked: post.isLiked,
        comments: post.comments,
        likes: post.likes,
        isCommentsExpanded: true,
      },
    ];
  }

  return getSubPostsItems(postInfo);
};

export const getSpecificPost = (data: ISpecificPostResponseApi, token?: string) => {
  const { notificationDetails, postInfo } = data;

  const postDetails = getFeedItem(notificationDetails, token);

  const subPosts = getSpecificPostSubPosts(postInfo, postDetails);

  return {
    subPosts,
    postDetails,
  };
};

export const parseLoggedInUserInformation = (
  posts: Array<IRecentPostResponse>,
  shop?: IUserShopInfo,
): { shopDetails: IUserShopInfo | null; posts: Array<IMyPost> } => {
  const updatedPosts = posts.map((item) => ({
    date: item.date,
    id: item.id,
    image: item.image || null,
    text: item.text,
  }));
  let shopDetails: null | IUserShopInfo = null;

  if (shop) {
    shopDetails = {
      name: shop.name,
      id: shop.id,
      coverPhoto: shop.coverPhoto || null,
      productImages: shop.productImages,
    };
  }

  return {
    posts: updatedPosts,
    shopDetails,
  };
};

const getFollowsSuggestions = (data: IFollowsSuggestionsResponse[]): IFollowsSuggestions[] => {
  return data.map((item) => ({
    name: item.name,
    id: item.id,
    image: item.image || null,
    userId: item.userId,
    isFollowing: false,
    description: item.description,
  }));
};

const getProfileGroups = (data: IProfileGroupResponse[]): IProfileGroup[] => {
  return data.slice(0, 3).map((item) => ({
    id: item.id,
    image: item.image || null,
    name: item.name,
    totalParticipants: item.participants,
  }));
};

export const parseProfileInformationResponse = (
  data: IProfileInformationResponse,
): IProfileDetails => {
  return {
    avatar: data.avatar || null,
    bio: data.bio || null,
    profileViews: null,
    name: data.fullName,
    username: data.username,
    groups: getProfileGroups(data.mutualGroups),
    followSuggestions: getFollowsSuggestions(data.followSuggestions || []),
    isFollowing: data.following,
    totalFollowers: data.totalFollowers,
    isPremium: data.premium,
    totalFollowing: data.totalFollowing,
  };
};

const getNotificationModifiers = (
  modifiers: INotificationsModifierResponse[],
): INotificationsModifiers => {
  const parsedModifiers: any = {};

  modifiers.forEach((modifier) => {
    switch (modifier.type) {
      case 1:
        parsedModifiers.font = mapTipFontResponseToFont[modifier.value.font] || Fonts.BodySmall;
        break;
      case 2:
        parsedModifiers.color = modifier.value.color;
        break;
      case 3:
        parsedModifiers.url = modifier.value.url;
        break;
      case 4:
        parsedModifiers.borderRadius = 32;
        break;
      case 5:
        parsedModifiers.borderRadius = Number(modifier.value.radius);
        break;
      default:
        break;
    }
  });

  return parsedModifiers;
};

const getNotificationButtonDetails = (
  button: INotificationsButtonResponse,
): INotificationsButton => {
  let buttonType;
  switch (button.variant) {
    case 0:
      buttonType = ButtonTypes.Primary;
      break;
    case 1:
      buttonType = ButtonTypes.Secondary;
      break;
    default:
      buttonType = ButtonTypes.Tertiary;
      break;
  }

  return {
    type: buttonType,
    text: button.text,
    link: button.link || null,
    prefixIcon: button.leftIcon || null,
    suffixIcon: button.rightIcon || null,
  };
};

const parseNotificationImages = (images: INotificationsImageResponse[]): INotificationsImage[] => {
  return images.map((image) => ({
    src: image.url,
    userId: image.userId,
    modifiers: getNotificationModifiers(image.modifiers || []),
  }));
};

const parseNotificationImageGroup = (
  group: INotificationsImageGroupResponse,
): INotificationsImageGroup => {
  return { type: group.type, images: parseNotificationImages(group.images) };
};

const parseNotificationItem = (
  item: INotificationItemResponse,
  isNew: boolean,
): INotificationItem => {
  const titles = item.title.map((title) => ({
    text: title.text,
    modifiers: getNotificationModifiers(title.modifiers || []),
  }));

  const texts = item.text.map((text) => ({
    text: text.text,
    modifiers: getNotificationModifiers(text.modifiers || []),
  }));

  const buttonDetails = item.button ? getNotificationButtonDetails(item.button) : null;

  const buttonAfterClickDetails = item.buttonAfterClick
    ? getNotificationButtonDetails(item.buttonAfterClick)
    : null;

  const prefixImage = item.leftImage ? parseNotificationImageGroup(item.leftImage) : null;

  const suffixImage = item.rightImage ? parseNotificationImageGroup(item.rightImage) : null;

  const imageGrid = item.imageGrid ? parseNotificationImages(item.imageGrid) : null;

  return {
    id: item.id,
    isNew,
    titles,
    text: texts,
    date: item.date || Date.now(),
    link: item.link || null,
    buttonDetails,
    buttonAfterClickDetails,
    prefixImage,
    suffixImage,
    imageGrid,
  };
};

export const parseNotifications = (
  data: INotificationItemResponse[],
  isNew: boolean,
): INotificationItem[] => {
  return data.map((item) => parseNotificationItem(item, isNew));
};

export const parseShopReviews = (data: IInitialShopReviewResponse): IShopReviewsState => {
  const { totals, reviews, selfReview, hasMore } = data;

  const list = reviews.map(parseReview);

  if (selfReview) {
    const parsedSelfReview = parseReview(selfReview);
    list.unshift(parsedSelfReview);
  }

  const totalRates = totals
    .slice(0, 5)
    .map((value, type) => ({ value, type }))
    .reverse();

  return {
    totalRates,
    hasMore,
    list,
  };
};

const parseViewResponse = (item: IProfileViewItemResponse): IProfileView => {
  const date = formatTimeStampToMilliseconds(item.time);

  return {
    avatar: item.avatar || getDefaultAvatar(item.id),
    name: item.name,
    date,
    userId: item.id,
    username: item.username,
  };
};

export const parseProfileViewsResponse = (response: IProfileViewsResponse) => {
  const { data } = response;

  return data.map(parseViewResponse);
};

export const parseShippingAddress = (address: IShippingAddressResponse): IShippingAddress => {
  return {
    id: address.id,
    country: address.country,
    fullName: address.fullName,
    streetAddress: address.streetAddress,
    email: '',
    apartmentNumber: address.other,
    city: address.city,
    postalCode: address.postalCode || '',
    phoneNumber: address.phoneNumber || '',
    isDefault: !!address.default,
    state: address.state || null,
  };
};

export const parseAccountPreferencesResponse = (
  response: IAccountPreferenceResponse,
): IAccountPreferences => {
  const shippingAddresses = response.defaultShippingAddress
    ? parseShippingAddress(response.defaultShippingAddress)
    : null;

  return {
    isLastSeenEnabled: response.lastSeen,
    isLocalTimeEnabled: response.localTime,
    isLiveTranslationEnabled: response.liveTranslations,
    language: response.language,
    email: response.email || null,
    birthday: response.birthdate || null,
    defaultAddress: shippingAddresses,
    currencyCode: response.currencyCode,
    premiumPlan: response.premiumPlan || null,
  };
};

export const parsePushNotificationsResponse = (
  response: IPostNotificationsResponse,
): { [key in NotificationsTypes]: boolean } => {
  const notifications: { [key in NotificationsTypes]: boolean } = {} as {
    [key in NotificationsTypes]: boolean;
  };
  Object.keys(response.push).forEach((key) => (notifications[key] = response.push[key]));

  return notifications;
};

export const parseEmailNotificationsResponse = (
  response: IPostNotificationsResponse,
  email: string | null,
): { [key in EmailNotificationsTypes]: boolean } => {
  const notifications: { [key in EmailNotificationsTypes]: boolean } = {} as {
    [key in EmailNotificationsTypes]: boolean;
  };
  Object.keys(response.email).forEach((key) => {
    if (email) {
      notifications[key] = response.email[key];
    } else {
      notifications[key] = false;
    }
  });

  return notifications;
};

export const parseBlockingResponse = (response: IBlockedUsersResponse) => {
  return {
    id: response._id,
    avatar: response.avatar || getDefaultAvatar(response._id),
    userName: response.username,
    name: response.fullName,
  };
};

const getProductShippingInformation = (
  shippingInformation: IProductShippingInformationResponse,
): IProductShippingInformation => {
  const { weightBig, weightSmall, length, isMetric, width, height } =
    shippingInformation.dimensions;

  const dimensions = {
    weightBig: weightBig / 100,
    weightSmall: weightSmall / 100,
    height: height / 100,
    width: width / 100,
    length: length / 100,
    isMetric,
  };

  return {
    returnPolicy: shippingInformation.returnPolicy,
    dimensions,
    shippingProfileId: shippingInformation.profileId,
    processingTime: shippingInformation.workingDays,
    countries: shippingInformation.countries,
    originCountry: shippingInformation.origin.country,
  };
};

export const parseShopProductResponse = (product: IProductShopResponse): ICreateShopCatalogItem => {
  const {
    variants = [],
    originalPrice,
    price,
    selectedCategory = [],
    keywords = [],
    quantity = null,
    shippingInformation,
  } = product;

  const parsedPrice = price / 100;

  const parsedOriginalPrice = originalPrice ? originalPrice / 100 : parsedPrice;

  const images = variants.map((image) => ({
    type: IImageContainerTypes.Extra,
    listingImage: image,
  }));
  images.unshift({ type: IImageContainerTypes.Extra, listingImage: product.imageUrl });

  const dimensions = shippingInformation
    ? {
        weightBig: shippingInformation.dimensions.weightBig / 100,
        weightSmall: shippingInformation.dimensions.weightSmall / 100,
        height: shippingInformation.dimensions.height / 100,
        width: shippingInformation.dimensions.width / 100,
        length: shippingInformation.dimensions.length / 100,
        isMetric: shippingInformation.dimensions.isMetric,
      }
    : {
        weightBig: 0,
        weightSmall: 0,
        height: 0,
        width: 0,
        length: 0,
        isMetric: false,
      };

  return {
    id: product.id,
    title: product.title,
    description: product.description,
    isDigital: !!product.isDigital,
    price: parsedOriginalPrice,
    priceAfterDiscount: parsedPrice,
    digitalFiles: product.digitalFileInformation || [],
    category: selectedCategory,
    keywords,
    quantity,
    saleAmount: Number((parsedOriginalPrice - parsedPrice).toFixed(3)),
    discountType: DiscountTypes.Fixed,
    currency: CurrencyCode[product.currencyCode],
    shippingProfileId: shippingInformation?.profileId || null,
    returnPolicy: shippingInformation?.returnPolicy?.type || null,
    dimensions,
    images,
    tariffNumber: product.tariff || '',
  };
};

export const parseCreateShopProductsResponse = (
  data: IProductShopResponse[],
): ICreateShopCatalogItem[] => {
  return data.map((product) => parseShopProductResponse(product));
};

export const parseCreateShopPayoutsResponse = (data: {
  [provider: string]: { email: string; active?: boolean };
}) => {
  return Object.entries(data).map(([key, value]: any) => ({
    type: key,
    email: value.email,
    isActive: value.active,
  }));
};

export const parseCreateShopLegacyPaymentsResponse = (
  data: ILegacyPayoutMethodResponse[],
): ILegacyPayoutMethod[] => {
  return data.map((item) => ({
    type: item.provider,
    link: item.link,
  }));
};

export const parseGetFollowers = (response: IGetFollowersResponse): IFollower[] => {
  const { data } = response;

  return data.map((user) => ({
    avatar: user.avatar || getDefaultAvatar(user.id),
    id: user.id,
    name: user.name,
    username: user.username,
    time: Date.parse(user.time),
  }));
};

export const parseShopsSearchResults = (
  data: IShopSearchResultResponse[],
): IShopInSearchResults[] => {
  return data.map((shop) => {
    const category = Array.isArray(shop.category) ? shop.category[0] : shop.category;

    const rating = shop.avg === undefined ? shop.rating : shop.avg;

    return {
      rating,
      category,
      description: shop.description,
      foundedCountry: shop.foundedCountry,
      images: shop.img_links || shop.images || [],
      name: shop.name || '',
      isPremium: shop.premium,
      shopNumber: shop.shop_number,
      totalReviews: shop.totalReviews,
      type: shop.type,
      id: shop._id || shop.id,
      avatar: shop.avatar || getDefaultAvatar(shop.userId),
      username: shop.username,
      userId: shop.userId,
      isFollowing: shop.following,
      isMarkedAsCrystalShop: !!shop.marked,
    };
  });
};

export const parseDiscoverProducts = (data: DiscoverPageProduct[]): IDiscoverProduct[] => {
  return data.map((item) => {
    const price = item?.price ? getPrice(item.price as IPriceResponse) : null;

    return {
      id: item.id,
      image: item.imageUrl,
      isLiked: !!item.didLike,
      totalLikes: item.totalLikes || null,
      price,
    };
  });
};

export const parseGroupsSearchResults = (
  data: IGroupsSearchResultResponse[],
): IGroupInSearchResults[] => {
  return data.map((group) => ({
    id: group._id,
    name: group.groupName,
    avatar: group.avatar,
    description: group.description,
    participants: group.participants,
    isPending: !!group.pending,
    isMember: !!group.isMember,
  }));
};

export const parseUsersSearchResults = (
  data: IUserSearchResultResponse[],
): IUserInSearchResults[] => {
  return data.map((user) => ({
    id: user.id,
    fullName: user.fullName,
    avatar: user.avatar || getDefaultAvatar(user.id),
    username: user.username,
    isFollowing: user.isFollowing,
    isPremium: user.premium,
    shopName: user.shopName || null,
  }));
};

export const parseProductsSearchResults = (
  data: IProductsSearchResultResponse[],
): IProductsInSearchResults[] => {
  return data.map((product) => {
    const formattedPrice = product.price / 100;

    const formattedOriginalPrice = product.originalPrice
      ? product.originalPrice / 100
      : formattedPrice;

    const { labels = [] } = product;

    const parsedLabels = labels.map((label) => ({
      text: label.texts[0]?.text || '',
      backgroundColor: label?.backgroundColor || '',
      textColor: label?.textColor || '',
    }));

    return {
      id: product.id,
      title: product.title || 'untitled item',
      currencyCode: product.currencyCode || CurrencyCode.USD,
      currency: product.currency || '$',
      price: formattedPrice,
      totalLikes: product.totalLikes,
      isLiked: product.didLike,
      image: product.images[0],
      isDigital: product.isDigital,
      originalPrice: formattedOriginalPrice,
      description: product.description,
      labels: parsedLabels,
      isCustomizable: product.isCustomizable,
      userId: product.userId,
    };
  });
};

export const parseSuggestedShops = (data: any): ISuggestedShop[] => {
  return data.map((shop: any) => ({
    username: shop.username,
    isFollowing: false,
    category: shop.category,
    images: shop.images,
    name: shop.name,
    city: shop.city,
    country: shop.country,
    totalReviews: shop.totalReviews,
    rating: shop.rating,
    id: shop.id,
    isPremium: shop.premium,
    avatar: shop.avatar || getDefaultAvatar(shop.userId),
    userId: shop.userId,
  }));
};

const parseInterestItems = (data: IInterestItem[]): IInterestItem[] => {
  return data.map((item: IInterestItem) => ({
    id: item.id,
    text: item.text,
    isSelected: false,
  }));
};

export const parsedOnboardingInterests = (
  data: IGetInterestSectionResponse,
): { [key in string]: IInterestItem[] } => {
  const interests = {};

  data.sections.forEach(
    (category: IInterestSectionResponse) =>
      (interests[category.title] = parseInterestItems(category.items)),
  );

  return interests;
};

export const parseShopsSuggestion = (data: IGetShopsResponse): IOnboardingShop[] => {
  return data.suggestions.map((shop: any) => ({
    id: shop.id,
    images: shop.images,
    avatar: shop.avatar || getDefaultAvatar(shop.userId),
    shopName: shop.name,
    username: shop.username,
    userId: shop.userId,
    shopCategory: shop.categories[0] || '',
    isSelected: true,
  }));
};

export const parseGroupsSuggestion = (data: IGroupSuggestionResponse): IGroupSuggestion[] => {
  return data.suggestions.map((group: IGroupResponse) => ({
    id: group.id,
    name: group.name,
    description: `${toShortFormat(group.participants)} members`,
    image: group.image,
    isSelected: true,
  }));
};

export const parseSearchTaggedUsers = (data: ISearchTaggedUserResponse[]): ITagUser[] => {
  return data.map((user) => ({
    id: user.id,
    avatar: user.avatar || getDefaultAvatar(user.id),
    fullName: user.fullName || '',
    username: user.username,
    priority: 1,
  }));
};

export const parseTipItems = (items: ITipDisplayItemResponse[]): ITipDisplayItem[] => {
  return items.map((item) => {
    if (item.imageItem) {
      return {
        imageItem: {
          name: item.imageItem.name,
          width: item.imageItem.width,
          height: item.imageItem.height,
          link: item.imageItem.link || null,
        },
      };
    }

    return {
      textItem: {
        text: item.textItem?.text || '',
        font: mapTipFontResponseToFont[item.textItem?.font || FontResponses.Body14],
        underline: !!item.textItem?.underline,
        strikethrough: !!item.textItem?.strikethrough,
        link: item.textItem?.link || null,
      },
    };
  });
};

export const parseTip = (data: ITipResponse[]): ITip[] => {
  if (!data) return [];

  return data.map((tip) => ({
    type: tip.type,
    displayedRoutes: tip.screens,
    items: parseTipItems(tip.items),
    link: tip.link || null,
  }));
};

export const parseOrderActivities = (
  data: IOrderActivityResponse[],
  partnerDetails: IOrderPartnerDetails,
  userAvatar: string,
): IOrderActivity[] => {
  const collapsibleStatuses = [
    OrderStatuses.Requested,
    OrderStatuses.Offered,
    OrderStatuses.ShippingApiUpdate,
  ];

  return data.map((activity) => {
    const username = activity.selfMade ? 'You' : partnerDetails.username;

    let avatar = activity.selfMade ? userAvatar : partnerDetails.avatar;

    if (activity.image) {
      avatar = activity.image;
    }

    const isCollapsible = collapsibleStatuses.includes(activity.status);

    return {
      id: activity.id,
      date: Date.parse(activity.date),
      status: activity.status,
      isUserActivity: activity.selfMade,
      username,
      avatar,
      fullName: partnerDetails.fullName,
      isCollapsible,
      discountDetails: null,
    };
  });
};

export const parsePartnerDetails = (data: IOrderPartnerInfoResponse): IOrderPartnerDetails => {
  const { username, avatar, fullname: fullName, _id: id, email } = data;

  const isAnonymous = isAllZero(id);

  return {
    username,
    avatar: avatar || getDefaultAvatar(id),
    coverPhoto: data.coverPhoto || null,
    id,
    fullName,
    email,
    isAnonymous,
  };
};

export const parseLinkedPayoutOptions = (
  data: ILinkedPaymentOptionsResponse[],
): ILinkedPaymentOption[] => {
  const paymentOptions: ILinkedPaymentOption[] = [];

  const stripeIds: string[] = [];

  const stripeTitles: string[] = [];

  const stripeImages: string[] = [];

  data.forEach((method) => {
    if (method.id === PayoutMethods.Paypal) {
      paymentOptions.push({
        type: PayoutMethods.Paypal,
        ids: [],
        title: 'PayPal',
        images: [PayPalLogo],
      });
    } else {
      stripeIds.push(method.id);
      stripeTitles.push(method.title);
      stripeImages.push(...method.imageUrls);
    }
  });
  if (!isEmptyArray(stripeIds)) {
    paymentOptions.push({
      type: PayoutMethods.Stripe,
      ids: stripeIds,
      title: parseStripeTitle(stripeTitles, 3),
      images: stripeImages.slice(0, 4),
    });
  }

  return paymentOptions;
};

export const parseOrderItems = (data: IOrderItemResponse[]): IOrderItem[] => {
  return data.map((item) => {
    const customizationRequests = item.customization
      ? { text: item.customization.note || '', images: item.customization.images || [] }
      : null;

    const price = getPrice(item.price);

    const originalPrice = item.originalPrice ? getPrice(item.originalPrice) : price;

    const totalItemPrice = { ...price, value: price.value * item.quantity };

    const totalItemOriginalPrice = { ...originalPrice, value: price.value * item.quantity };

    return {
      id: item.id,
      title: item.title,
      image: item.image,
      isDigital: item.isDigital,
      price: totalItemPrice,
      originalPrice: totalItemOriginalPrice,
      quantity: item.quantity,
      customizationRequests,
    };
  });
};

export const parseSelectedShippingService = (
  data: ISelectedShippingServiceResponse,
): ISelectedShippingService => {
  return {
    name: data.name,
    image: data.image || '',
    price: getPrice(data.price),
    arrivalDate: data.arrivalDate,
    canCreateShippingLabel: data.canCreateShippingLabel,
  };
};

export const parseOrderDetails = (order: IOrderResponse): IOrderDetails => {
  const partnerDetails = parsePartnerDetails(order.partnerInfo);

  const subTotalPrice =
    order.items.reduce((acc, item) => acc + item.price.price * item.quantity, 0) / 100;

  const totalOriginalPrice =
    order.items.reduce(
      (acc, item) => acc + (item.originalPrice?.price || item.price.price) * item.quantity,
      0,
    ) / 100;

  const totalDiscount = totalOriginalPrice - subTotalPrice;

  let subTotalPriceAfterCoupon: number;

  if (order.discountDetails) {
    subTotalPriceAfterCoupon = order.discountDetails.finalPrice / 100;
  } else {
    subTotalPriceAfterCoupon = subTotalPrice;
  }

  const totalPrice = subTotalPriceAfterCoupon - totalDiscount + (order.shippingPrice || 0) / 100;

  const shippingPrice = order.shippingPrice !== undefined ? order.shippingPrice / 100 : null;

  const title = order.items.length > 1 ? 'Mixed Bundle' : order.items[0].title;

  const selectedShippingService = order.selectedShippingService
    ? parseSelectedShippingService(order.selectedShippingService)
    : null;

  const recipientAddress = order.address
    ? {
        address: order.address?.address || '',
        city: order.address?.city || '',
        country: order.address?.country || Countries.UnitedStates,
        state: order.address?.state || null,
        phoneNumber: order.address?.phoneNumber || '',
        postalCode: order.address?.postalCode || '',
      }
    : null;

  const dimensions = order.dimensions
    ? {
        length: order.dimensions.length / 100,
        width: order.dimensions.width / 100,
        height: order.dimensions.height / 100,
        weight: order.dimensions.weight / 100,
        isMetric: order.dimensions.isMetric,
        weightSmall: order.dimensions.weightSmall / 100,
        weightBig: order.dimensions.weightBig / 100,
      }
    : null;

  const isAnonymous = isAllZero(order.partnerInfo._id);

  const discountDetails: IDiscountDetails | null = order.discountDetails
    ? {
        finalPrice: order.discountDetails.finalPrice / 100,
        couponCodeName: order.discountDetails.couponCodeName,
      }
    : null;

  return {
    id: order.id,
    orderNumber: order.orderNumber,
    status: order.status,
    recipientAddress,
    shippingPrice,
    shippingAddressName: order.fullName || '',
    creationDate: Date.parse(order.creationDate),
    shippingProviderImage: null,
    trackingNumber: order.trackingNumber || null,
    lastActivityDate: order.lastUpdated,
    paidPaymentMethod: order.paidPaymentMethod || null,
    shippingLabelUrl: order.labelLink || null,
    packingSlipUrl: order.packingSlipLink || null,
    hasDigitalFiles: !!order.hasDigitalFiles,
    isInstantCheckout: !!order.isInstantCheckout,
    partnerDetails,
    subTotalPrice,
    totalPrice,
    totalDiscount,
    currencyCode: getPrice(order.items[0].price).currencyCode,
    items: parseOrderItems(order.items),
    isSeller: order.sent,
    title,
    shopName: order.shopName,
    shopId: order.shopId,
    isShopReviewed: !!order.isShopReviewed,
    linkedPaymentOptions: parseLinkedPayoutOptions(order.linkedPaymentOptions),
    originalPrice: getPrice(order.originalPrice),
    payoutOptions: order.paymentOptions,
    originAddress: order.origin || null,
    shippingLabelDetails: null,
    selectedShippingService,
    dimensions,
    expiryDate: order.expiryDate || null,
    isAnonymous,
    discountDetails,
  };
};

export const parseSpecificOrder = (
  data: ISpecificOrderResponse,
  userAvatar: string,
): { orderDetails: IOrderDetails; activities: IOrderActivity[] } => {
  const { order, activities } = data;

  const partnerDetails = parsePartnerDetails(order.partnerInfo);

  const orderDetails = parseOrderDetails(order);

  const parsedActivities = parseOrderActivities(activities, partnerDetails, userAvatar);

  return {
    activities: parsedActivities,
    orderDetails,
  };
};

export const parseOrders = (data: IUserOrdersResponse): IOrdersListState => {
  const { otherNext, youNext, totalPastOrders } = data;

  const pendingOrders = [...otherNext, ...youNext];

  const parsedAwaitingOrders = otherNext
    .filter((order) => order.status !== OrderStatuses.Shipped)
    .map((order) => parseOrderDetails(order));

  const parsedTodoOrders = youNext
    .filter((order) => order.status !== OrderStatuses.Shipped)
    .map((order) => parseOrderDetails(order));

  const parsedShippedOrders = pendingOrders
    .filter((order) => order.status === OrderStatuses.Shipped)
    .map((order) => parseOrderDetails(order));

  const todoOrders = {
    list: parsedTodoOrders,
    totalCount: parsedTodoOrders.length,
  };

  const awaitingOrders = {
    list: parsedAwaitingOrders,
    totalCount: parsedAwaitingOrders.length,
  };

  const shippedOrders = {
    list: parsedShippedOrders,
    totalCount: parsedShippedOrders.length,
  };

  const pastOrders = {
    list: data.pastOrders.map((order) => parseOrderDetails(order)),
    totalCount: totalPastOrders,
  };

  return {
    todoOrders,
    pastOrders,
    awaitingOrders,
    shippedOrders,
  };
};

export const parseUserOrdersStatistics = (
  response: IUserOrdersResponse,
  totalActiveOrders: number,
  totalShippedOrders: number,
): IUserOrderStatistics => {
  const { totalPastOrders, totalCompletedOrders, totalDeclinedOrders, sellerInformation } =
    response;

  const parsedSellerInformation = sellerInformation
    ? {
        strength: sellerInformation.strength,
        shopId: sellerInformation.shopId,
        totalShopViews: sellerInformation.totalShopViews,
        totalExternalLinks: sellerInformation.totalExternalLinks,
      }
    : null;

  return {
    totalPastOrders,
    totalCompletedOrders,
    totalDeclinedOrders,
    totalShippedOrders,
    totalActiveOrders,
    sellerInformation: parsedSellerInformation,
  };
};

export const parseStripePaymentSessionDetails = (
  response: IGetPaymentSessionDetailsResponse,
): IPaymentSessionDetails => {
  const { id, paymentIntentSecret, clientId, price } = response;

  return {
    id,
    paymentIntentSecret,
    clientId,
    price: getPrice(price),
  };
};

export const parseGraphqlStripePaymentSessionDetails = (
  response: CreatePaymentIntentResult,
): IPaymentSessionDetails => {
  const { paymentId, paymentIntentSecret, clientId, price } = response;

  return {
    id: paymentId,
    paymentIntentSecret,
    clientId,
    price: getPrice(price),
  };
};

export const parseShippingLabelDetails = (
  response: IGetShippingLabelDetailsResponse,
): IShippingLabelDetails => {
  const { insurancePrice } = response;

  return {
    insurancePrice: insurancePrice / 100,
  };
};

export const parseShopViewers = (response: IGetShopViewersResponse): IShopViewers[] => {
  return response.data.map((viewer: IProfileViewerResponse) => ({
    id: viewer._id,
    username: viewer.username,
    name: viewer.name,
    avatar: viewer.avatar ? viewer.avatar : '',
    shopName: viewer.shopName ? viewer.shopName : '',
    time: Date.parse(viewer.time),
  }));
};

export const parseShippingLabelShippingRates = (
  response: IGetShippingRatesResponse,
): IShippingRate[] => {
  return response.rates.map((rate: IShippingRateResponse) => ({
    id: rate.id,
    attributes: rate.attributes,
    providerName: rate.provider.name,
    providerImage: rate.provider.image,
    price: getPrice(rate.price),
    minimumDeliveryDays: rate.estimation.min,
    estimationDeliveryDays: rate.estimation.avg,
    maximumDeliveryDays: rate.estimation.max,
    signaturePrice: getPrice(rate.price) || null,
  }));
};

export const parseDisabledShippingRates = (
  response: IGetShippingRatesResponse,
): IShippingDisabledRate[] => {
  return response.disabledRates.map((disabledRate: IShippingDisabledRateResponse) => ({
    carrier: disabledRate.carrier,
    disabledReason: disabledRate.disabledReason,
  }));
};

export const parsePackagesTypeList = (response: IGetPackagesTypeResponse): IPackageType[] => {
  return response.packageTypes.map((packageType: IPackageTypesResponse) => ({
    name: packageType.name,
    carrier: packageType.carrier,
    weightLb: Number(toFormat(packageType.weightLb, 2)),
    length: Number(toFormat(packageType.length, 2)),
    width: Number(toFormat(packageType.width, 2)),
    height: Number(toFormat(packageType.height, 2)),
  }));
};

export const parseShippingLabelUrls = (
  response: IGetShippingLabelUrlsResponse,
): IShippingLabelDownloadUrls => {
  const email = response.email ? response.email : '';

  const shippingLabelUrl = response.shippingLabelUrl ? response.shippingLabelUrl : '';

  const packageSlipUrl = response.packageSlipUrl ? response.packageSlipUrl : '';

  return {
    email,
    shippingLabelUrl,
    packageSlipUrl,
  };
};

export const parseProductEstimatedDelivery = (
  response: IGetProductEstimatedDeliveryResponse,
): { min: number; max: number } => {
  const { startDate, endDate } = response;

  return {
    min: startDate,
    max: endDate,
  };
};

export const parseShippingProfiles = (data: IShippingProfileResponse[]): IShippingProfile[] => {
  return data.map((profile) => ({
    id: profile.id,
    name: profile.name,
    originAddress: profile.origin,
    processingTime: profile.workingDays,
    shippingDestinations: parseShippingDestinations(profile.shippingDestinations),
    shippingPriceOption: profile.shippingPriceOption,
  }));
};

export const parseShippingProfilesToAffectedListings = (
  items: IProductShopResponse[],
  shippingProfiles: IShippingProfileResponse[],
) => {
  const profiles = {};
  shippingProfiles.forEach((profile) => {
    profiles[profile.id] = items.filter(
      (item) => item.shippingInformation?.profileId === profile.id,
    ).length;
  });

  return profiles;
};

export const parseShippingPriceAndDeliveryTime = (
  price?: IShippingProfilePriceResponse,
  deliveryTime?: IShippingProfileDeliveryTimeParserInput,
) => {
  const fixedPriceOption = price ? FixedPriceOptions.Fixed : FixedPriceOptions.FreeShipping;

  let fixedPrice = { dropdownValue: fixedPriceOption, firstItem: 0, additionalItem: 0 };
  if (price) {
    fixedPrice = {
      dropdownValue: fixedPriceOption,
      firstItem: price.firstItem / 100,
      additionalItem: price.additionalItem / 100,
    };
  }

  const deliveryTimeOption = deliveryTime
    ? getDeliveryTimeOption(deliveryTime?.min, deliveryTime?.max)
    : ProcessingTimeOptions.ThreeDays;

  const delivery = {
    dropdownValue: deliveryTimeOption,
    min: deliveryTime?.min || 0,
    max: deliveryTime?.max || 0,
  };

  return {
    deliveryTime: delivery,
    fixedPrice,
    deliveryTimeOption,
    fixedPriceOption,
  };
};

export const parseShippingDestinations = (
  data: IShippingDestinationResponse[],
): IShippingDestination[] => {
  return data.map((destination) => {
    const { deliveryTime, deliveryTimeOption, fixedPriceOption, fixedPrice } =
      parseShippingPriceAndDeliveryTime(destination.price, destination.deliveryTime);

    return {
      countries: destination.countries,
      isInternationalFreeShipping: destination.isFreeInternational,
      isDomesticFreeShipping: destination.isFreeDomestic,
      deliveryTime,
      fixedPriceOption,
      shippingServicesIds: destination.servicesIds,
      fixedPrice,
      deliveryTimeOptionValue: deliveryTimeOption,
    };
  });
};

export const parseBasketShippingRates = (
  data: IBasketShippingRateResponse[],
): IBasketShippingRate[] => {
  return data.map((rate, index) => {
    const isSelected = index === 0;

    return {
      id: rate.id,
      attributes: rate.attributes,
      image: rate.provider.image || null,
      name: rate.provider.name,
      price: getPrice(rate.price),
      deliveryDate: rate.estimatedDeliveryDate,
      isSelected,
    };
  });
};

export const parsePremiumPlanDetails = (
  data: IGetPremiumPlanDetailsResponse,
): IGetPremiumPlanDetails => {
  const isSubscriptionAutoRenew = data.autoRenew === undefined ? null : data.autoRenew;

  return {
    subscriptionEndsDate: data.subUntil,
    premiumPlan: data.plan,
    payoutProvider: data.type || null,
    isSubscriptionAutoRenew,
  };
};

export const parseSavedProducts = (data: ProductItem[]): IProductsInSearchResults[] => {
  return data.map((product) => ({
    id: product.id,
    title: product.title,
    image: product.imageUrl,
    price: product.price / 100,
    originalPrice: product.originalPrice / 100,
    currencyCode: product.currencyCode as CurrencyCode,
    isLiked: product.didLike,
    totalLikes: product.totalLikes,
    labels: product.labels,
    userId: product.userId,
    isDigital: product.isDigital,
  }));
};

export const parseDiscoverCategories = (data: DiscoverPageCategoriesItem): IDiscoverCategory[] => {
  return data.categories.map((category) => {
    return {
      name: category.name,
      image: category.smallImage,
      keywords: category.subCategories,
    };
  });
};

export const parseDiscoverTitles = (data: StyledText[]): IDiscoverSectionTitle[] => {
  return data.map((title) => ({
    text: title.text,
    color: title.color,
    font: mapTipFontResponseToFont[title.font],
    hasArrow: title.hasArrow,
  }));
};

export const parseListDetails = (data?: Maybe<ListData>): IDiscoverListDetails | null => {
  if (!data) return null;

  return {
    listId: data.listId as any as DiscoverListsId,
    type: data.screen as any as DiscoverListTypes,
    title: data.title,
    paginationToken: data.initialPaginationToken || null,
  };
};

export const parseDiscoverStaticItems = (data: DiscoverPageStaticItem[]): IDiscoverStaticItem[] => {
  return data.map((item) => {
    return {
      type: item.id,
      order: item.sectionDetails.order,
      marginTop: item.sectionDetails.topSpacing,
    };
  });
};

export const parseDiscoverBaseLayout = (
  item: any,
  type: DiscoverLayoutComponents,
  isMixedLayout: boolean,
) => {
  const products = item?.bigProduct ? [item.bigProduct, ...item.smallProducts] : item.products;

  const titles = item.title ? parseDiscoverTitles(item.title) : [];

  const totalRows = type === DiscoverLayoutComponents.ProductsUnEqualGrid ? 2 : item?.rows || null;

  const gridDetails = isMixedLayout
    ? parseGridItems(item.topLeft, item.bottomLeft, item.right, type)
    : null;

  return {
    type,
    products: parseDiscoverProducts(products || []),
    titles,
    totalRows,
    gridDetails,
    backgroundImage: item.backgroundImage || null,
    listDetails: parseListDetails(item.listInfo),
  };
};

export const parseGridItems = (
  topLeft: any,
  bottomLeft: any,
  right: any,
  type: DiscoverLayoutComponents,
): IDiscoverLayoutGridDetails => {
  const rightType =
    type === DiscoverLayoutComponents.MixedLayout2
      ? DiscoverLayoutComponents.ProductsUnEqualGrid
      : DiscoverLayoutComponents.ProductsEqualGrid;

  const bottomLeftType =
    type === DiscoverLayoutComponents.MixedLayout1
      ? DiscoverLayoutComponents.CustomProductsLayout
      : DiscoverLayoutComponents.ProductsCarousel;

  return {
    topLeft: parseDiscoverBaseLayout(topLeft, DiscoverLayoutComponents.ProductsCarousel, false),
    bottomLeft: parseDiscoverBaseLayout(bottomLeft, bottomLeftType, false),
    right: parseDiscoverBaseLayout(right, rightType, false),
  };
};

export const parseDiscoverPageComponent = (
  data:
    | EqualGridProduct[]
    | HorizontalListProduct[]
    | DiscoverPageLayout1[]
    | DiscoverPageLayout2[]
    | DiscoverPageLayout3[],
  type: DiscoverLayoutComponents,
): IDiscoverPageComponent[] => {
  return data.map((item) => {
    const isMixedLayout =
      type === DiscoverLayoutComponents.MixedLayout1 ||
      type === DiscoverLayoutComponents.MixedLayout2 ||
      type === DiscoverLayoutComponents.MixedLayout3;

    const baseComponent = parseDiscoverBaseLayout(item, type, isMixedLayout);

    return {
      ...baseComponent,
      order: item.sectionDetails.order,
      marginTop: item.sectionDetails.topSpacing || 0,
    };
  });
};

export const parseShopOfTheDay = (data: ShopOfTheDay): IShopOfTheDay => {
  const titles: IDiscoverSectionTitle[] = [];

  if (data.title) {
    titles.push(parseDiscoverTitles([data.title])[0]);
  }
  if (data.text) {
    titles.push(parseDiscoverTitles([data.text])[0]);
  }

  return {
    userId: data.userId,
    shopId: data.shopId,
    titles,
    footerBackgroundColor: data.footerBackgroundColor,
    image: data.imageUrl,
    isFollowing: data.isFollowing,
  };
};

export const getMojoUserDetails = (mojo: Mojo): IUserDetails => {
  const { id, fullName, avatar, isFollowing, username, isPremium } = mojo.user;

  return {
    avatar: avatar || getDefaultAvatar(id),
    username,
    shopName: '',
    shopImage: '',
    fullName,
    date: mojo.date,
    isPremium,
    userId: id,
    shopId: null,
    isFollowing,
  };
};

export const parseMojoList = (data: Mojo[]): IMojo[] => {
  return data.map((item) => {
    const productDetails = item.product
      ? getPostProductDetails(item.product.image, item.product.id, '', item.product.price)
      : null;

    const userDetails = getMojoUserDetails(item);

    return {
      id: item.id,
      isLiked: item.didLike,
      totalLikes: item.likes,
      totalComments: item.comments,
      comments: { list: [], paginationToken: '' },
      text: item.text || null,
      video: item.url,
      thumbnail: item.thumbnailUrl,
      productDetails,
      userDetails,
    };
  });
};

export const parseVisionCreditOptions = (data: VisionCreditOfferWeb[]): IVisionsCreditOption[] => {
  return data.map((item) => {
    const tag = item.tag ? { text: item.tag.text, variant: TagVariants.Purple } : null;

    return {
      id: item.id,
      totalCredits: item.credits,
      price: {
        value: item.price / 100,
        currencyCode: item.currencyCode as CurrencyCode,
        currencySymbol: item.currencySymbol,
      },
      tag,
    };
  });
};

export const parseSpecificVision = (data: Vision): IVision => {
  return {
    id: data.id,
    imageUrl: data.imageUrl,
    title: null,
    promptDetails: { text: data.prompt || '', image: null },
    userDetails: {
      id: data.user.id,
      avatar: data.user.avatar || getDefaultAvatar(data.user.id),
      username: data.user.username,
      fullName: data.user.fullName,
    },
  };
};

export const parseVisions = (data: DiscoverPageVision[]): IVision[] => {
  return data.map((item) => ({
    id: item.id,
    imageUrl: item.imageUrl,
    title: item.title || null,
    promptDetails: { text: item.prompt || '', image: null },
    userDetails: {
      id: item.user.id,
      avatar: item.user.avatar || getDefaultAvatar(item.user.id),
      username: item.user.username,
      fullName: item.user.fullName,
    },
  }));
};

export const parseVisionStaticItems = (data: DiscoverPageStaticItem[]): IVisionsPageItem[] => {
  return data.map((item) => {
    return {
      type: item.id as any as DiscoverVisionsComponentType,
      sectionDetails: {
        marginTop: item.sectionDetails.topSpacing,
        order: item.sectionDetails.order,
      },
      titles: [],
      listDetails: null,
      visions: [],
    };
  });
};

export const parseVisionsPage = (data: DiscoverPageVisions[]): IVisionsPageItem[] => {
  return data.map((item) => {
    return {
      type: item.sectionType as any as DiscoverVisionsComponentType,
      title: item.title,
      titles: parseDiscoverTitles(item.title),
      listDetails: parseListDetails(item.listInfo),
      visions: parseVisions(item.visions),
      sectionDetails: {
        marginTop: item.sectionDetails.topSpacing,
        order: item.sectionDetails.order,
      },
    };
  });
};

export const parseVisionRequest = (data: VisionRequest): IVisionRequest => {
  const { country, city, address, postalCode, state } = data.shippingAddress.shippingAddress;

  const shippingAddress = {
    country: country as Countries,
    address: address || '',
    city: city || '',
    postalCode: postalCode || '',
    state: state || null,
  };

  return {
    id: data.id,
    prompt: data.prompt,
    totalOffers: data.totalOffers,
    category: data.category,
    imageUrl: data.visionImageUrl,
    date: data.date,
    address: shippingAddress,
    username: data.user.username,
    fullName: data.user.fullName,
    notes: data.notes,
  };
};

export const parseVisionRequests = (data: VisionRequest[]): IVisionRequest[] => {
  return data.map((item) => parseVisionRequest(item));
};

export const parseVisionOffers = (data: VisionOffer[], tab: VisionOffersTab): IVisionOffer[] => {
  return data.map((item) => parseVisionOffer(item, tab));
};

export const parseVisionOffer = (item: VisionOffer, tab: VisionOffersTab): IVisionOffer => {
  let type = item.orderId ? VisionOfferType.Approved : VisionOfferType.Pending;
  if (tab === VisionOffersTab.Expired && !item.orderId) {
    type = VisionOfferType.Expired;
  }

  const { country, city, address, postalCode, state } = item.shippingAddress.shippingAddress;

  const shippingAddress = {
    country: country as Countries,
    address: address || '',
    city: city || '',
    postalCode: postalCode || '',
    state: state || null,
  };

  const dimensions = {
    length: item.dimensions.length / 100,
    width: item.dimensions.width / 100,
    height: item.dimensions.height / 100,
    weightBig: item.dimensions.weightBig / 100,
    weightSmall: (item.dimensions?.weightSmall || 0) / 100,
    isMetric: item.dimensions.metric,
  };

  return {
    type,
    id: item.offerId,
    category: item.category,
    imageUrl: item.imageUrl,
    tariffNumber: item.tariff || null,
    shippingProfileId: item.shippingProfileId,
    dimensions,
    buyerNote: item.buyerNotes || '',
    sellerNote: item.sellerNotes || '',
    deliveryTime: item.deliveryTime,
    date: item.date,
    address: shippingAddress,
    price: getPrice(item.price),
    shippingPrice: getPrice(item.shippingPrice),
    orderId: item.orderId || null,
    returnPolicy: {
      type: mapReturnsPolicyResponseToType[item.returnPolicy.policy],
      text: item.returnPolicy.title,
    },
    partnerDetails: {
      id: item?.partnerInfo?.id,
      username: item?.partnerInfo?.username,
      avatar: item?.partnerInfo?.avatar || getDefaultAvatar(item?.partnerInfo?.id),
    },
    isBestMatch: item.bestMatch,
  };
};

const parseShippingProfileDestinationDetails = (
  destination: ShippingProfileDestinationDetails,
): IShippingDestination => {
  const formattedPrice: IShippingProfilePriceResponse | undefined = destination.price
    ? { firstItem: destination.price.firstItem, additionalItem: destination.price.additionalItem }
    : undefined;

  const formattedDeliveryTime: IShippingProfileDeliveryTimeParserInput | undefined =
    destination.deliveryTime
      ? {
          min: destination.deliveryTime.min,
          max: destination.deliveryTime.max,
        }
      : undefined;

  const { deliveryTime, fixedPrice } = parseShippingPriceAndDeliveryTime(
    formattedPrice,
    formattedDeliveryTime,
  );

  const countriesType =
    destination.countries.countriesType === CountriesTypeType.Specific
      ? ShippingCountriesType.Specific
      : ShippingCountriesType.EverywhereElse;

  return {
    fixedPrice,
    shippingServicesIds: destination.services,
    isDomesticFreeShipping: destination.freeDomestic,
    isInternationalFreeShipping: destination.freeInternational,
    countries: {
      type: countriesType,
      values: destination.countries.countries as Countries[],
    },
    deliveryTime,
  };
};

export const parseGQLShippingProfiles = (data: ShippingProfile[]): IShippingProfile[] => {
  return data.map((profile) => {
    const shippingPriceOption = profile.isFixed
      ? ShippingPriceOptions.FixedPrice
      : ShippingPriceOptions.CalculatedShipping;

    const { country, city, address, postalCode, state } = profile.origin;

    const originAddress = {
      address: address || '',
      city: city || '',
      postalCode: postalCode || '',
      state: state || null,
      country: country as Countries,
    };

    const shippingDestinations = profile.destinationDetails.map(
      parseShippingProfileDestinationDetails,
    );

    return {
      id: profile.id,
      name: profile.name,
      originAddress,
      processingTime: profile.workingDays,
      shippingDestinations,
      shippingPriceOption,
    };
  });
};

export const parseAddressPreview = (data: ShippingAddress): IAddressPreview => {
  return {
    address: data.address || '',
    city: data.city,
    country: data.country as Countries,
    postalCode: data.postalCode || '',
    state: data.state || null,
  };
};

export const parseShippingRates = (rates: ShippingRate[]): IBasketShippingRate[] => {
  return rates.map((rate, index) => {
    const attributes = rate.tags ? [mapShippingFeatureToAttribute[rate.tags]] : [];

    const isSelected = index === 0;

    return {
      id: rate.rateId,
      attributes,
      name: rate.name,
      image: rate.image || '',
      price: getPrice(rate.price),
      deliveryDate: rate.arrivalDate,
      signaturePrice: null,
      isSelected,
    };
  });
};

export const parseCheckoutLinkedPayoutOptions = (
  data: GetVisionPaymentOptionResponse[] | GetPaymentCheckoutResponse[],
): ILinkedPaymentOption[] => {
  const paymentOptions: ILinkedPaymentOption[] = [];

  const stripeIds: string[] = [];

  const stripeTitles: string[] = [];

  const stripeImages: string[] = [];

  data.forEach((method) => {
    if (method.paypal) {
      paymentOptions.push({
        type: PayoutMethods.Paypal,
        ids: [],
        title: 'PayPal',
        images: [PayPalLogo],
      });
    }
    if (method.stripe) {
      stripeIds.push(method.stripe.id);
      stripeTitles.push(method.stripe.title);
      stripeImages.push(...method.stripe.imageUrls);
    }
  });
  if (!isEmptyArray(stripeIds)) {
    paymentOptions.push({
      type: PayoutMethods.Stripe,
      ids: stripeIds,
      title: parseStripeTitle(stripeTitles, 3),
      images: stripeImages.slice(0, 4),
    });
  }

  return paymentOptions;
};

export const getCartItemStatus = (status: CartItemStatus): ProductAvailabilityItem => {
  switch (status) {
    case CartItemStatus.Unavailable:
      return ProductAvailabilityItem.NotShipping;
    case CartItemStatus.SoldOut:
      return ProductAvailabilityItem.OutOfStock;
    default:
      return ProductAvailabilityItem.Available;
  }
};

export const parseBasketItems = (data: CartItem[], checkoutId: string): ICartItem[] => {
  return data.map((item) => {
    const customizationRequest = item.customization ? item.customization : { text: '', images: [] };

    const originalPrice = item.originalPrice
      ? {
          value: item.originalPrice / 100,
          currencyCode: item.price.currencyCode as CurrencyCode,
          currencySymbol: item.price.currency,
        }
      : getPrice(item.price);

    originalPrice.value *= item.quantity;

    const price = getPrice(item.price);

    price.value *= item.quantity;

    const convertedFromPrice = item.convertedFrom ? getPrice(item.convertedFrom) : null;

    if (convertedFromPrice) {
      convertedFromPrice.value *= item.quantity;
    }

    return {
      cartItemId: item.cartItemId,
      productId: item.productId,
      shopCheckoutId: checkoutId,
      title: item.title,
      price,
      isDigital: false,
      originalPrice,
      convertedFromPrice,
      quantity: item.quantity,
      image: item.image,
      customizationRequest,
      status: getCartItemStatus(item.status),
    };
  });
};

export const parseBasket = (data: CartShop[]): IShopBasket[] => {
  return data.map((shop) => {
    const subTotalPrice = getSubTotalPrice(shop.products);

    const totalDiscount = getTotalDiscount(shop.products);

    const sellerDetails = {
      id: shop.user.id,
      avatar: shop.user.avatar || getDefaultAvatar(shop.user.id),
      fullName: shop.user.fullName,
      username: shop.user.username,
    };

    return {
      shopId: shop.shopId,
      shopName: shop.shopName,
      checkoutId: shop.checkoutId,
      hasInstantCheckout: shop.hasInstantCheckout,
      userDetails: sellerDetails,
      shippingPrice: undefined,
      subTotalPrice,
      currencyCode: getPrice(shop.products[0].price).currencyCode,
      items: parseBasketItems(shop.products, shop.checkoutId),
      discountDetails: null,
      totalDiscount,
    };
  });
};

const parseShippingInformation = (
  data: ProductShippingInformation,
): IProductShippingInformation => {
  const { weightBig, weightSmall, length, metric, width, height } = data.dimensions;

  const dimensions = {
    weightBig: weightBig / 100,
    weightSmall: weightSmall / 100,
    height: height / 100,
    width: width / 100,
    length: length / 100,
    isMetric: metric,
  };

  const returnPolicy = data.returnPolicy
    ? {
        type: data.returnPolicy.policy as unknown as ReturnPolicyType,
        title: data.returnPolicy.title,
        description: data.returnPolicy.description,
      }
    : null;

  return {
    returnPolicy,
    dimensions,
    shippingProfileId: data.profileId,
    processingTime: data.workingDays,
    countries: data.countries,
    originCountry: data.originCountry,
  };
};

export const parseProductGraphql = (data: ProductItemV2): IProduct => {
  const convertedFromPrice = data.convertedFrom ? getPrice(data.convertedFrom) : null;

  const images = [...(data.variants || [])];
  images.unshift(data.imageUrl);

  const formattedPrice = data.price.price / 100;

  const formattedOriginalPrice = data.originalPrice ? data.originalPrice / 100 : formattedPrice;

  const quantity = typeof data.quantity === 'number' ? data.quantity : DEFAULT_PRODUCT_QUANTITY;

  const shippingInformation = data.shippingInformation
    ? parseShippingInformation(data.shippingInformation)
    : null;

  return {
    id: data.id,
    images,
    title: data.title,
    description: data.description,
    isDigital: data.isDigital,
    price: formattedPrice,
    originalPrice: formattedOriginalPrice,
    convertedFromPrice,
    currencySymbol: data.price.currency,
    currencyCode: data.price.currencyCode as CurrencyCode,
    hasInstantCheckout: data.hasInstantCheckout,
    shippingInformation,
    tariffNumber: data.tariff || null,
    quantity,
    category: data.selectedCategory,
    isLiked: data.didLike,
    totalLikes: data.totalLikes,
    labels: getProductLabels(data.labels),
  };
};

export const parseProductBanner = (data: ProductBanner): IProductBannerDetails => {
  const text = parseDiscoverTitles([...data.title, ...data.description]);

  const gradientString = data.gradient
    ? `linear-gradient(${data.gradient.angle}deg,${data.gradient?.colors
        .map((item) => `${item.color} ${item.startPercent}%`)
        .join(', ')})`
    : null;

  return {
    id: data.id,
    image: data.rightImage,
    text,
    backgroundColor: gradientString || data.backgroundColor,
    borderWidth: data.borderWidth,
    borderColor: data.borderColor,
  };
};

// export const addShouldDisplayWarningToItemsFromCsv = (
//   itemsAfterUploadImages: ICreateShopProductFromCsv[],
//   itemsBeforeUploadImages: ICreateShopProductFromCsv[],
// ): ICreateShopProductFromCsv[] => {
//   const mapProductIdToImageCount = itemsBeforeUploadImages.reduce(
//     (acc, product) => ({
//       ...acc,
//       [product.id]: product.images.length,
//     }),
//     {},
//   );
//
//   return itemsAfterUploadImages
//     .filter((item) => item.images.length > 0)
//     .map((item) => ({
//       ...item,
//       shouldDisplayWarning: mapProductIdToImageCount[item.id] !== item.images.length,
//     }));
// };
