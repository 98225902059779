import { gql } from '@apollo/client';

export const GET_FEED_QUERY = gql`
  query GetDiscoverPageData($paginationToken: String, $listId: ListItem!) {
    getDiscoverPageData(
      screenHeight: 0
      screenWidth: 0
      paginationToken: $paginationToken
      listId: $listId
      version: 6
    ) {
      paginationToken
      feedItems {
        id
        userId
        username
        avatar
        fullName
        shopName
        shopId
        shopImage
        images
        comments
        date
        text
        likes
        reposts
        repostId
        groupId
        groupName
        backgroundIndex
        composition
        ratio
        didLike
        didRepost
        reason
        reasonExtra
        following
        premium
        totalFollowers
        totalFollowing
        editable
        editedDate
        isShoppingPost
        hideDate
        lastComment {
          id
          text
          image
          date
          userId
          username
          fullName
          avatar
          replies
          premium
          didLike
          likes
        }
        productDetails {
          title
          productId
          price {
            price
            currencyCode
            currency
          }
        }
        repostDetails {
          id
          userId
          username
          avatar
          fullName
          shopName
          shopId
          shopImage
          images
          comments
          date
          text
          likes
          reposts
          repostId
          groupId
          groupName
          backgroundIndex
          composition
          ratio
          premium
          isShoppingPost
          productDetails {
            title
            productId
            price {
              price
              currencyCode
              currency
            }
          }
          tags {
            id
            value
          }
        }
        tags {
          id
          value
        }
        sectionDetails {
          order
          topSpacing
        }
      }
      staticItems {
        id
        key
        extras
      }
      mojos {
        id
        userId
        username
        avatar
        fullName
        videoUrl
        thumbnailUrl
        date
        text
        likes
        ratio
        didLike
        following
        premium
        comments
        sectionDetails {
          order
          topSpacing
        }
        productDetails {
          title
          productId
          image
          price {
            price
            currencyCode
            currency
          }
        }
      }
    }
  }
`;
