import { Middleware } from 'redux';

import { ICheckoutItem, IStore2 } from 'types';
import { getShippingRatesForNonSignupUser } from './CheckoutActions';
import { CheckoutSlicer } from './CheckoutSlicer';
import { getBasketSupportedItems, getCheckoutIdForProduct } from '../basket/BasketActions';
import { isEmptyArray } from 'utils';
import { addShippingAddress, setDefaultAddress } from '../settings/SettingsActions';
import { getBasketCheckoutDetails, getBasketPaymentsDetails } from '../payouts/PayoutsActions';

export const checkoutMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (
    action: any = {
      payload: {},
      type: {},
    },
  ) => {
    switch (action.type) {
      case CheckoutSlicer.actions.updateInitialDetails.type: {
        const { products, checkoutId = null } = action.payload;

        const firstProduct: ICheckoutItem = products[0];

        if (checkoutId) {
          dispatch(getBasketCheckoutDetails({ checkoutId }));
        } else {
          dispatch(getCheckoutIdForProduct({ item: firstProduct }));
        }

        break;
      }

      // Non signup
      case getCheckoutIdForProduct.fulfilled.type: {
        const { id: checkoutId } = action.payload;

        const state = getState();

        const { products } = state.checkoutForNonSignupUsers;

        const isDigital = products?.some((product) => product.isDigital) || false;

        const { token } = state.user;

        // SignedUp user
        if (token) {
          if (isDigital) {
            dispatch(getBasketPaymentsDetails({ checkoutId }));
          } else {
            dispatch(getBasketCheckoutDetails({ checkoutId }));
          }
        }
        break;
      }

      case CheckoutSlicer.actions.updateShippingAddress.type: {
        const { shippingAddress } = action.payload;

        const state: IStore2 = getState();

        const { checkoutId, products, supportedCountries } = state.checkoutForNonSignupUsers;

        const { token } = state.user;

        // if there are no supported countries, Its came from the basket page
        if (isEmptyArray(supportedCountries) && token) {
          const ids = products?.map((product) => product.id) || [];
          dispatch(getBasketSupportedItems({ ids }));
        }
        if (checkoutId) {
          if (token) {
            dispatch(getBasketCheckoutDetails({ checkoutId }));
          } else if (!products?.find((product) => product.isDigital)) {
            dispatch(
              getShippingRatesForNonSignupUser({
                shippingAddress,
                checkoutId,
              }),
            );
          }
        }

        break;
      }

      case addShippingAddress.fulfilled.type: {
        const state2: IStore2 = getState();

        const { shippingAddress } = action.meta.arg;

        const { checkoutId } = state2.checkoutForNonSignupUsers;

        if (checkoutId) {
          dispatch(CheckoutSlicer.actions.updateShippingAddress({ shippingAddress }));
        }

        break;
      }

      case setDefaultAddress.fulfilled.type: {
        const { shippingAddress } = action.meta.arg;

        const state3: IStore2 = getState();

        const { checkoutId } = state3.checkoutForNonSignupUsers;

        if (checkoutId) {
          dispatch(CheckoutSlicer.actions.updateShippingAddress({ shippingAddress }));
        }

        break;
      }

      default:
        break;
    }

    return next(action);
  };
