import { gql } from '@apollo/client';

export const GET_MANAGE_PLAN_URL_QUERY = gql`
  query {
    getCustomerPortal {
      url
    }
  }
`;

export const START_SUBSCRIPTION_FLOW_QUERY = gql`
  mutation ($subscriptionType: SubscriptionType!) {
    startSubscriptionFlow(subscriptionType: $subscriptionType) {
      url
    }
  }
`;
