import { AvatarDisplayOptions, IMessageReplyDetails, MessageSelectionOptions } from 'types';

export * from './messageTypes/types';

export enum MessageActionOptions {
  Forward = 'Forward',
  Copy = 'Copy',
  Reply = 'Reply',
  MessagePrivately = 'MessagePrivately',
  Delete = 'Delete Message',
  Report = 'Report',
}

export interface IMessageItemWrapper {
  className?: string;
  children?: any;
  avatar: string | null;
  isOwner: boolean;
  isSequenceMessage: boolean;
  selectionMode: MessageSelectionOptions | null;
  isSelected: boolean;
  avatarDisplayOption: AvatarDisplayOptions;
  isDeletable: boolean;
  isReplyEnable: boolean;
  isReportEnable: boolean;
  isMessagePrivatelyEnable: boolean;
  isCopyEnable: boolean;
  isForwardEnable: boolean;
  id: string;
  reply: IMessageReplyDetails | null;
  onReplyClick(id: string, date: number): void;
  onActionClick(messageId: string, action: MessageActionOptions): void;
  onSelectToggle(options: { id: string; isSelected: boolean }): void;
}
