import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './basketPopover.scss';
import { Button } from 'tedooo-web-design-system/ui';

import { EmptyState } from 'components/emptyState';
import { BasketPageSources, EmptyStateOptions, IBasketPopover, ICartItem, IStore2 } from 'types';
import { translations } from 'translations/en';
import { ROUTES } from 'constant';
import { BasketSelector } from 'store/basket/BasketSelector';
import { getBasket, onRemoveItemFromBasket } from 'store/basket/BasketActions';
import { Image } from 'components/helpers';
import { toCurrency } from 'utils';
import { BasketSlicer } from 'store/basket/BasketSlicer';

const BasketPopover: FC<IBasketPopover> = ({
  totalItems,
  items,
  subTotalPrice,
  onRemoveItem,
  getCart,
  updateSource,
  onHide,
}) => {
  const navigate = useNavigate();

  const onStartShoppingClick = () => {
    onHide();
    navigate(`/${ROUTES.DISCOVER}`);
  };

  const onCheckoutClick = () => {
    updateSource({ source: BasketPageSources.BasketIcon });
    navigate(`/${ROUTES.BASKET}`);
    onHide();
  };

  const onRemoveClick = (cartItem: ICartItem, checkoutId: string) => {
    onRemoveItem({ item: cartItem, checkoutId, shouldFavorite: false });
  };

  useEffect(() => {
    if (!items) {
      getCart();
    }
  }, []);

  return (
    <div className="basket-popover-inner-container">
      <span className="title">{translations.BASKET.TITLE}</span>
      <div className="items-container">
        {items?.map((item) => (
          <div key={item.cartItemId} className="item-container">
            <div className="left-side">
              <Image src={item.image} />
              <div className="text-container">
                <div className="item-title-container">
                  <span className="item-title">{item.title}</span>
                  <span className="quantity">
                    {translations.BASKET.BASKET_ITEM.QTY} {item.quantity}
                  </span>
                </div>
                <span className="button" onClick={() => onRemoveClick(item, item.shopCheckoutId)}>
                  {translations.BASKET.BASKET_ITEM.REMOVE}
                </span>
              </div>
            </div>
            <span className="price">
              {toCurrency(item.price.value, item.price.currencyCode, 2)}
            </span>
          </div>
        ))}
      </div>
      {totalItems === 0 && (
        <EmptyState
          type={EmptyStateOptions.Basket}
          button={
            <Button
              type="primary"
              size="medium"
              borderRadius="large"
              onClick={onStartShoppingClick}
              text={translations.BASKET.EMPTY_STATE.BUTTON_TEXT}
            />
          }
        />
      )}
      {totalItems > 0 && (
        <div className="checkout-container">
          <div className="text-container">
            <span>
              {translations.ORDERS.POPUPS.CREATE_SHIPPING_LABEL_POPUP.SIDE_BAR.TOTAL_PRICE.SUBTOTAL}
            </span>
            <span className="price">
              {toCurrency(subTotalPrice.value, subTotalPrice.currencyCode, 2)}
            </span>
          </div>
          <Button
            type="primary"
            size="medium"
            borderRadius="large"
            isFullWidth
            text={translations.BASKET.BASKET_POPOVER_BUTTON}
            onClick={onCheckoutClick}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { totalItems } = state.basket;

  const items = BasketSelector.basketItemsSelector(state);

  const subTotalPrice = BasketSelector.basketSubTotalPriceSelector(state);

  return { totalItems, items, subTotalPrice };
};

const mapDispatchToProps = {
  updateSource: BasketSlicer.actions.updateSource,
  onRemoveItem: onRemoveItemFromBasket,
  getCart: getBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketPopover);
