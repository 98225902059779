import { gql } from '@apollo/client';

export const LIKE_MOJO_MUTATION = gql`
  mutation SetLikeMojo($id: String!, $like: Boolean!) {
    setLikeMojo(id: $id, like: $like) {
      success
    }
  }
`;

export const GET_MOJO_LINK_QUERY = gql`
  query GetMojoLink($id: String!) {
    getMojoLink(id: $id) {
      url
    }
  }
`;

export const DELETE_MOJO_MUTATION = gql`
  mutation DeleteMojo($id: String!) {
    deleteMojo(id: $id) {
      success
    }
  }
`;

export const GET_MOJOS_QUERY = gql`
  query GetMojos($paginationToken: String, $listId: String!) {
    getMojos(listId: $listId, paginationToken: $paginationToken) {
      paginationToken
      data {
        id
        text
        url
        thumbnailUrl
        likes
        date
        didLike
        ratio
        comments
        user {
          id
          username
          fullName
          avatar
          isFollowing
          isPremium
        }
        product {
          id
          image
          price {
            price
            currencyCode
            currency
          }
        }
      }
    }
  }
`;

export const COMMENT_MOJO_MUTATION = gql`
  mutation PostMojoComment(
    $mojoId: String!
    $text: String
    $image: String
    $tags: [String!]
    $replyToId: String
  ) {
    postMojoComment(
      mojoId: $mojoId
      text: $text
      image: $image
      tags: $tags
      replyToId: $replyToId
    ) {
      id
    }
  }
`;

export const LIKE_COMMENT_TOGGLE_MUTATION = gql`
  mutation LikeMojoComment($commentId: String!, $like: Boolean!) {
    likeMojoComment(commentId: $commentId, like: $like) {
      success
    }
  }
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteMojoComment($commentId: String!) {
    deleteMojoComment(commentId: $commentId) {
      success
    }
  }
`;

export const REPORT_MOJO_MUTATION = gql`
  mutation ReportMojo($mojoId: String!) {
    reportMojo(mojoId: $mojoId) {
      success
    }
  }
`;

export const GET_MOJO_COMMENTS_QUERY = gql`
  query GetMojoComments($id: String!, $repliesFor: String, $paginationToken: String) {
    getMojoComments(id: $id, repliesFor: $repliesFor, paginationToken: $paginationToken) {
      paginationToken
      comments {
        id
        userId
        username
        fullName
        avatar
        isPremium
        text
        image
        date
        likes
        replies
        didLike
        isDeleted
        taggedUsers {
          id
          username
        }
      }
    }
  }
`;

export const GET_MOJO_COMMENT_LIKES_QUERY = gql`
  query GetWhoLikedComment($commentId: String!, $paginationToken: String) {
    getWhoLikedComment(commentId: $commentId, paginationToken: $paginationToken) {
      paginationToken
      data {
        id
        userId
        name
        username
        shopName
        avatar
        isPremium
        shopId
        shopImage
        name
        following
      }
    }
  }
`;
