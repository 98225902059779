import { ITagUser } from 'types';

export const sortUsersByPriority = (users: ITagUser[]): ITagUser[] => {
  return users.sort((a, b) => b.priority - a.priority);
};

export const addSearchedUsersToCache = (
  cachedUsers: ITagUser[],
  searchedUsers: ITagUser[],
): ITagUser[] => {
  const newUsers = searchedUsers.filter(
    (searchedUser) => !cachedUsers.find((user) => user.id === searchedUser.id),
  );

  const newList = [...newUsers, ...cachedUsers].slice(0, 200);

  return sortUsersByPriority(newList);
};
