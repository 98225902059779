import { ClickableImage } from 'tedooo-web-design-system/ui';
import './sectionTitle.scss';
import { ArrowRight } from 'tedooo-web-design-system/icons';
import { FC } from 'react';
import classNames from 'classnames';

import { IDiscoverSectionTitle } from 'types';

interface ISectionTitle {
  titles: IDiscoverSectionTitle[];
  onListClick(): void;
}

const SectionTitle: FC<ISectionTitle> = ({ titles, onListClick }) => {
  const onTitleClick = (clickable: boolean) => {
    if (clickable) {
      onListClick();
    }
  };

  return (
    <div className="section-titles-container">
      {titles.map((title, index) => (
        <span
          className={classNames([title.font], 'title', { clickable: title.hasArrow })}
          onClick={() => onTitleClick(title.hasArrow)}
          style={{ color: title.color }}
          key={index}
        >
          {title.text}
          {title.hasArrow && <ClickableImage icon={<ArrowRight />} />}
        </span>
      ))}
    </div>
  );
};

export default SectionTitle;
