import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAddCommentOptions, IAddReplyOptions, ICreatePostRequest, IPost, IStore2 } from 'types';
import { getCommentById, getPost } from '../post/helper';
import { PostApi } from '../post/PostApi';
import { isEmptyArrayOrUndefined } from 'utils';
import { checkTaggedUsers, parseCreatePostRequest } from './helper';

const reducer = new PostApi();

export const onLikePostToggle = createAsyncThunk(
  'async/onLikePost',
  async (input: { actionId: string; postId: string; isLiked: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { selectedSubPostId } = state.feed;

    const { token = '' } = state.user;

    const { actionId, isLiked } = input;

    return reducer.onPostLikeToggle(selectedSubPostId || actionId, isLiked, token);
  },
);

export const onLikeComment = createAsyncThunk(
  'async/onLikeComment',
  async (
    input: { commentId: string; isLiked: boolean; postId: string; replyId?: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, isLiked, replyId, postId } = input;

    const post = getPost(state.feed, postId) || null;

    const selectedComment = getCommentById(post, commentId, replyId);

    return reducer.onLikeComment(selectedComment?.id || '', isLiked, token);
  },
);

export const onPostHide = createAsyncThunk(
  'async/onPostHide',
  async (input: { id: string }, { getState }: any) => {
    const { id } = input;

    const { token = '' } = getState().user;

    return reducer.onPostHide(id, token);
  },
);

export const onPostReport = createAsyncThunk(
  'async/onPostReport',
  async (input: { id: string }, { getState }: any) => {
    const { id } = input;

    const { token } = getState().user;

    return reducer.onPostReport(id, token);
  },
);

export const onPostDelete = createAsyncThunk(
  'async/onPostDelete',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token } = state.user;

    return reducer.onPostDelete(id, token);
  },
);

export const getPostLikes = createAsyncThunk(
  'async/getPostLikes',
  async (input: { postId: string }, { getState }: any) => {
    const { postId } = input;

    const state: IStore2 = getState();

    const { token = '' } = state.user;
    let maxId;

    const post = getPost(state.feed, postId);

    if (post?.likes.list) {
      const { list } = post.likes;
      maxId = list[list.length - 1].id;
    }

    return reducer.getLikes(post?.actionId || '', token, 20, maxId);
  },
);

export const getCommentLikes = createAsyncThunk(
  'async/getCommentLikes',
  async (input: { commentId: string; postId: string; replyId?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, replyId, postId } = input;

    let maxId;

    const post = getPost(state.feed, postId) || null;

    const selectedComment = getCommentById(post, commentId, replyId);

    if (selectedComment?.likes.list) {
      const { list } = selectedComment.likes;
      maxId = list[list.length - 1].id;
    }

    return reducer.getCommentLikes(selectedComment?.id || '', token, 20, maxId);
  },
);

export const getPostChildren = createAsyncThunk(
  'async/getPostChildren',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { selectedPostId } = state.feed;

    return reducer.getPostChildren(selectedPostId!, token || '');
  },
);

export const addComment = createAsyncThunk(
  'async/addComment',
  async (input: IAddCommentOptions, { getState }: any) => {
    const { text, image, actionId, taggedUsers } = input;

    const tags = taggedUsers.map((taggedUser) => taggedUser.userId);

    const updatedTaggedUsers = checkTaggedUsers(text, tags);

    const { token } = getState().user;

    return reducer.onAddComment(actionId, text, updatedTaggedUsers, token, image);
  },
);

export const addReply = createAsyncThunk(
  'async/addReply',
  async (input: IAddReplyOptions, { getState }: any) => {
    const { text, image, commentId, actionId, taggedUsers } = input;

    const tags = taggedUsers.map((taggedUser) => taggedUser.userId);

    const { token } = getState().user;

    return reducer.onAddReply(actionId, commentId, text, tags, token, image);
  },
);

export const getComments = createAsyncThunk(
  'async/getComments',
  async (input: { postId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    let maxTime: number | undefined;

    const post = getPost(state.feed, input.postId);

    const commentsList = post?.comments?.list;

    if (!isEmptyArrayOrUndefined(commentsList)) {
      maxTime = commentsList![commentsList!.length - 1]?.date;
    }

    return reducer.getComments(post?.actionId || '', 20, maxTime, token);
  },
);

export const getReplies = createAsyncThunk(
  'async/getReplies',
  async (input: { actionId: string; postId: string; commentId: string }, { getState }: any) => {
    const { actionId, commentId } = input;

    const { token } = getState().user;

    return reducer.getReplies(actionId, commentId, token);
  },
);

export const deleteComment = createAsyncThunk(
  'async/deleteComment',
  async (input: { commentId: string }, { getState }: any) => {
    const { commentId } = input;

    const { token } = getState().user;

    return reducer.deleteComment(commentId, token);
  },
);

export const toggleRepost = createAsyncThunk(
  'async/toggleRepost',
  async (
    input: { postId: string; actionId: string; isReposted: boolean; loggedInUsername: string },
    { getState }: any,
  ) => {
    const { token } = getState().user;

    const { actionId, isReposted } = input;

    return reducer.toggleRepost(actionId, isReposted, token);
  },
);

export const getReposts = createAsyncThunk(
  'async/getReposts',
  async (input: { postId: string; selectedPost: IPost }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { list } = state.feed.repostsFeed;

    const { postId } = input;

    let minId;

    if (list) {
      minId = list[list.length - 1].id;
    }

    return reducer.getReposts(postId, minId, token);
  },
);

export const onHidePostFromActivity = createAsyncThunk(
  'async/onHidePostFromActivity',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { id } = input;

    return reducer.hidePostFromActivity(id, token);
  },
);

export const getPostLink = createAsyncThunk(
  'async/getPostLink',
  async (input: { postId: string }, { getState }: any) => {
    const { postId } = input;

    const { token } = getState().user;

    return reducer.getPostLink(postId, token);
  },
);

export const createPost = createAsyncThunk(
  'async/createPost',
  async (input: ICreatePostRequest, { getState }: any) => {
    const { shopId, id } = input;

    const state: IStore2 = getState();

    const groupId = state.chat.selectedChat?.id;

    const requestBody = parseCreatePostRequest({ ...input, groupId });

    const { token } = state.user;

    if (id) {
      return reducer.editUserPost(id, shopId, requestBody, token);
    }

    return reducer.createUserPost(shopId, requestBody, token);
  },
);
