import { Middleware } from 'redux';

import {
  getProductEstimatedDelivery,
  getShopDetails,
  getSpecificProduct,
  onProductLikeToggle,
} from './SpecificProductActions';
import { MessageSlicer } from '../message/MessageActions';
import { IStore2, MessageIds, popupIdsOptions } from 'types';
import { BasketSlicer } from '../basket/BasketSlicer';
import { PopupSlicer } from '../popup/PopupActions';
import { getCheckoutIdForProduct } from '../basket/BasketActions';

export const specificProductMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case getSpecificProduct.fulfilled.type: {
        const { item, shopId } = action.payload.getProductById;

        const state: IStore2 = getState();

        const { token } = state.user;

        dispatch(getShopDetails({ id: shopId }));
        if (token) {
          dispatch(getProductEstimatedDelivery({ productId: item.id }));
        }

        break;
      }
      case onProductLikeToggle.fulfilled.type: {
        dispatch(MessageSlicer.actions.show({ id: MessageIds.toggleFavoriteProduct }));
        break;
      }

      case BasketSlicer.actions.updateBuyNowItem.type: {
        const { hasInstantCheckout, item } = action.payload;

        if (hasInstantCheckout) {
          dispatch(getCheckoutIdForProduct({ item }));
        }

        const popupId = hasInstantCheckout
          ? popupIdsOptions.payBasketPopup
          : popupIdsOptions.requestToBuyPopup;
        dispatch(PopupSlicer.actions.show({ id: popupId, isBuyNow: true }));
        break;
      }

      default:
        break;
    }

    return next(action);
  };
