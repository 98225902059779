import { Socket } from 'socket.io-client';

import {
  CONNECT,
  DELETE_MESSAGE,
  GROUP_MEMBERS,
  JOIN_CHAT,
  MESSAGE_RECEIVED,
  ORDER_UPDATE,
  SEND_MESSAGE_BATCH,
  SEND_SINGLE_MESSAGE,
  SIDEBAR_MESSAGES,
  SUBSCRIBE_CHAT,
  TYPING_GROUP,
  TYPING_USER,
  UNSUBSCRIBE_CHAT,
  USER_INFO,
  VISION_OFFER_APPROVED,
  VISION_OFFER_CHANGED,
  VISION_OFFER_DELETED,
  VISION_REQUEST_DELETED,
  VISIONS_CREDIT_CHANGED,
} from './api';
import {
  DeleteMessageRequest,
  GroupParticipantsCountResponse,
  IVisionCreditsChangedResponse,
  IVisionOfferApproveResponse,
  IVisionOfferDeleteResponse,
  MessageReceivedResponse,
  MessageRequest,
  MultipleMessagesRequest,
  MultipleMessagesResponse,
  OrderUpdateResponse,
  SendMessageResponse,
  SendTypingEvent,
  SideBarMessagesResponse,
  TypingResponse,
  UserInfoResponse,
} from 'types';
import { VisionOffer } from 'graphql/generated/graphql';

export class SocketApi {
  private socket: Socket;

  constructor(socket: Socket) {
    this.socket = socket;
  }

  connect(onConnect: () => void) {
    this.socket.on(CONNECT, onConnect);
  }

  disconnect() {
    this.socket.disconnect();
  }

  subscribeSpecificChat(id: string, isRoom: boolean) {
    this.socket.emit(SUBSCRIBE_CHAT, { id, room: isRoom });
  }

  unsubscribeSpecificChat(id: string) {
    this.socket.emit(UNSUBSCRIBE_CHAT, { id });
  }

  // Listeners
  listenToChatInfo(onUpdate: (user: UserInfoResponse) => void) {
    this.socket.on(USER_INFO, onUpdate);
  }

  listenToGroupParticipants(onUpdate: (details: GroupParticipantsCountResponse) => void) {
    this.socket.on(GROUP_MEMBERS, onUpdate);
  }

  listenToMessages(onUpdate: (details: MessageReceivedResponse) => void) {
    this.socket.on(MESSAGE_RECEIVED, onUpdate);
  }

  listenToSideBarMessages(onUpdate: (response: SideBarMessagesResponse) => void) {
    this.socket.on(SIDEBAR_MESSAGES, onUpdate);
  }

  listenToGroupTyping(onUpdate: (response: TypingResponse) => void) {
    this.socket.on(TYPING_GROUP, onUpdate);
  }

  listenToUserTyping(onUpdate: (response: TypingResponse) => void) {
    this.socket.on(TYPING_USER, onUpdate);
  }

  listenToOrderUpdate(onUpdate: (orderDetails: OrderUpdateResponse) => void) {
    this.socket.on(ORDER_UPDATE, onUpdate);
  }

  listenToVisionOfferDelete(onUpdate: (response: IVisionOfferDeleteResponse) => void) {
    this.socket.on(VISION_OFFER_DELETED, onUpdate);
  }

  listenToVisionOfferChange(onUpdate: (response: VisionOffer) => void) {
    this.socket.on(VISION_OFFER_CHANGED, onUpdate);
  }

  listenToVisionRequestDelete(onUpdate: (response: IVisionOfferDeleteResponse) => void) {
    this.socket.on(VISION_REQUEST_DELETED, onUpdate);
  }

  listenToVisionOfferApproved(onUpdate: (response: IVisionOfferApproveResponse) => void) {
    this.socket.on(VISION_OFFER_APPROVED, onUpdate);
  }

  listenToVisionsCreditChange(onUpdate: (response: IVisionCreditsChangedResponse) => void) {
    this.socket.on(VISIONS_CREDIT_CHANGED, onUpdate);
  }

  connectChat() {
    this.socket.emit(JOIN_CHAT, { time: 0 });
  }

  deleteMessage(options: DeleteMessageRequest, onComplete: (isSuccess: boolean) => void) {
    this.socket.emit(DELETE_MESSAGE, options, onComplete);
  }

  sendMessage(request: MessageRequest, onComplete: (response: SendMessageResponse) => void) {
    this.socket.emit(SEND_SINGLE_MESSAGE, request, onComplete);
  }

  sendTyping(request: SendTypingEvent) {
    this.socket.emit(TYPING_USER, request);
  }

  sendMultipleMessages(
    request: MultipleMessagesRequest,
    onComplete: (response: MultipleMessagesResponse) => void,
  ) {
    this.socket.emit(SEND_MESSAGE_BATCH, request, onComplete);
  }
}
