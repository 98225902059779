import { IProductDimensions, IVisionsState, ReturnPolicyType, VisionOffersTab } from 'types';
import { ReturnsPolicyOptions } from '../../graphql/generated/graphql';

export const getCurrentOfferList = (state: IVisionsState, listId: VisionOffersTab) => {
  if (listId === VisionOffersTab.Pending) {
    return state.myOffers.pendingOffers;
  }

  return state.myOffers.expiredOffers;
};

export const getGQLDimensions = (dimensions: IProductDimensions) => {
  const { height, length, weightBig, width, isMetric, weightSmall } = dimensions;

  return {
    width: Math.floor(Number(width.toFixed(2)) * 100),
    height: Math.floor(Number(height.toFixed(2)) * 100),
    length: Math.floor(Number(length.toFixed(2)) * 100),
    weightBig: Math.floor(Number(weightBig.toFixed(2)) * 100),
    weightSmall: Math.floor(Number(weightSmall.toFixed(2)) * 100) || 0,
    metric: isMetric,
  };
};

export const mapReturnsPolicyTypeToRequest = {
  [ReturnPolicyType.NoReturn]: ReturnsPolicyOptions.NoReturns,
  [ReturnPolicyType.Return30]: ReturnsPolicyOptions.ReturnOnly30d,
  [ReturnPolicyType.ReturnAndExchange30]: ReturnsPolicyOptions.ReturnExchange30d,
  [ReturnPolicyType.ReturnAndExchange90]: ReturnsPolicyOptions.ReturnExchange90d,
};

export const mapReturnsPolicyResponseToType = {
  [ReturnsPolicyOptions.NoReturns]: ReturnPolicyType.NoReturn,
  [ReturnsPolicyOptions.ReturnOnly30d]: ReturnPolicyType.Return30,
  [ReturnsPolicyOptions.ReturnExchange30d]: ReturnPolicyType.ReturnAndExchange30,
  [ReturnsPolicyOptions.ReturnExchange90d]: ReturnPolicyType.ReturnAndExchange90,
};
