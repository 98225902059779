import { Middleware } from 'redux';

import { MenuSlicer } from './MenuActions';
import { SearchResultSlicer } from '../searchResult/SearchResultActions';

export const menuMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case MenuSlicer.actions.onSubmit.type:
        dispatch(SearchResultSlicer.actions.reset());
        break;

      default:
        break;
    }

    return next(action);
  };
