import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ForgetPasswordReducer } from './ForgetPasswordReducer';
import { IForgetPasswordState, ForgetPasswordPhasesOptions } from 'types';

const initialState: IForgetPasswordState = {
  isLoading: false,
  phone: '',
  prevPassword: '',
  username: '',
  phase: ForgetPasswordPhasesOptions.phoneForm,
};

const reducer = new ForgetPasswordReducer();

export const onForgetPassword = createAsyncThunk('async/onForgetPassword', async () =>
  reducer.onForgetPassword(),
);

export const onPhoneSubmit = createAsyncThunk(
  'async/onPhoneSubmit',
  async (input: { phone: string }) => {
    const { phone } = input;

    return reducer.sendVerifyCode(phone);
  },
);

export const resendCodeInCall = createAsyncThunk(
  'async/resendCodeInCall',
  async (input: { phone: string }) => {
    const { phone } = input;

    return reducer.resendCodeInCall(phone);
  },
);

export const submitVerificationCode = createAsyncThunk(
  'async/submitVerificationCode',
  async (input: { code: string; username: string }) => {
    const { username, code } = input;

    return reducer.submitVerificationCode(code, username);
  },
);

export const submitNewPassword = createAsyncThunk(
  'async/submitNewPassword',
  async (input: { prevPassword: string; password: string; username: string }) => {
    const { username, password, prevPassword } = input;

    return reducer.submitNewPassword(prevPassword, password, username);
  },
);

export const ForgetPasswordSlicer = createSlice({
  name: 'forgetPassword',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.phone = initialState.phone;
      state.phase = initialState.phase;
      state.username = initialState.username;
      state.prevPassword = initialState.prevPassword;
      state.serverErrorMessage = undefined;
    },
    changeSignPhase: (state, action) => {
      state.phase = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onPhoneSubmit.fulfilled, (state, action) => {
      state.phone = action.meta.arg.phone;
      state.username = action.payload.username;
      state.phase = ForgetPasswordPhasesOptions.codeVerification;
    });

    builder.addCase(submitVerificationCode.fulfilled, (state, action) => {
      state.prevPassword = action.payload.password;
      state.phase = ForgetPasswordPhasesOptions.createPassword;
    });

    builder.addCase(submitNewPassword.fulfilled, (state) => {
      state.phase = ForgetPasswordPhasesOptions.endMessage;
    });

    builder.addMatcher(
      isAnyOf(
        onForgetPassword.pending,
        onPhoneSubmit.pending,
        resendCodeInCall.pending,
        submitNewPassword.pending,
        submitVerificationCode.pending,
      ),
      (state) => {
        state.isLoading = true;
      },
    );

    builder.addMatcher(
      isAnyOf(
        onForgetPassword.rejected,
        onPhoneSubmit.rejected,
        resendCodeInCall.rejected,
        submitNewPassword.rejected,
        submitVerificationCode.rejected,
      ),
      (state, action) => {
        state.serverErrorMessage = action.error.message;
        state.isLoading = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        onForgetPassword.fulfilled,
        onPhoneSubmit.fulfilled,
        resendCodeInCall.fulfilled,
        submitNewPassword.fulfilled,
        submitVerificationCode.fulfilled,
      ),
      (state) => {
        state.serverErrorMessage = undefined;
        state.isLoading = false;
      },
    );
  },
});
