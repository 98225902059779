import './changePasswordMessage.scss';
import { Notification } from 'tedooo-web-design-system/ui';

import { ServerStatus } from 'types';
import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';

const ChangePasswordMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text="Password changed successfully"
        placement="topRight"
        className="password-update-message"
      />
    );
  }

  return null;
};

export default ChangePasswordMessage;
