import {
  ICurrencyAndShippingProfilesResponse,
  IGetVisionInfoResponse,
  IProductDimensions,
  IVisionsApi,
  ReturnPolicyType,
  VisionOffersTab,
} from 'types';
import {
  DiscoverPageResultWeb,
  VisionCreditOfferWeb,
  PaginatedVisionResult,
  StartCheckoutSessionResponse,
  MyRequestsResponse,
  SellerRequestsResponse,
  SellerRequestsTabCountResponse,
  SellerOfferResponse,
  SendOfferResponse,
  ShippingRate,
  VisionRequest,
  SuccessResponse,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  GET_CREDIT_OPTIONS_QUERY,
  GET_POTENTIAL_VISION_OFFERS_QUERY,
  GET_MY_VISION_REQUESTS_QUERY,
  GET_STRIPE_CHECKOUT_URL_MUTATION,
  GET_VISIONS_BY_LIST_ID_QUERY,
  GET_VISIONS_INFORMATION_QUERY,
  GET_VISIONS_OFFERS_TOTAL_COUNTS_QUERY,
  GET_VISIONS_QUERY,
  GET_MY_VISION_OFFERS_QUERY,
  SEND_VISION_OFFER_MUTATION,
  GET_SHIPPING_PRICE_BY_DIMENSIONS_QUERY,
  GET_REQUEST_FOR_OFFER_QUERY,
  EDIT_VISION_OFFER_MUTATION,
  GET_CURRENCY_AND_SHIPPING_PROFILES,
} from './VisionsQueries';
import { getGQLDimensions, mapReturnsPolicyTypeToRequest } from './helper';

export class VisionsApi implements IVisionsApi {
  async getVisionsPage(token: string): Promise<DiscoverPageResultWeb> {
    const variables = { listId: 'MAIN_VISIONS' };

    const res = await graphQlRequest(token).query({
      query: GET_VISIONS_QUERY,
      variables,
    });

    return res.data.getDiscoverPageDataWeb;
  }

  async getCurrencyCodeAndShippingProfiles(
    token: string,
  ): Promise<ICurrencyAndShippingProfilesResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_CURRENCY_AND_SHIPPING_PROFILES,
    });

    const { data } = res;

    return {
      shippingProfiles: data.getShippingProfiles,
      currency: data.getVisionCurrency,
    };
  }

  async getVisionsByListId(
    token: string,
    listId: string,
    paginationToken: string | null,
  ): Promise<PaginatedVisionResult> {
    const variables = {
      listId,
      paginationToken,
    };

    const res = await graphQlRequest(token).query({
      query: GET_VISIONS_BY_LIST_ID_QUERY,
      variables,
    });

    return res.data.getVisionsByListId;
  }

  async getVisionsInformation(token: string): Promise<IGetVisionInfoResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_VISIONS_INFORMATION_QUERY,
    });

    return res.data;
  }

  async getCreditOptions(token: string): Promise<VisionCreditOfferWeb[]> {
    const res = await graphQlRequest(token).query({
      query: GET_CREDIT_OPTIONS_QUERY,
    });

    return res.data.getVisionCreditOffers.web;
  }

  async startVisionsCreditsCheckout(
    token: string,
    offerId: string,
  ): Promise<StartCheckoutSessionResponse> {
    const variables = { offerId };

    const res = await graphQlRequest(token).mutate({
      mutation: GET_STRIPE_CHECKOUT_URL_MUTATION,
      variables,
    });

    return res.data.startVisionCheckoutSession;
  }

  async getMyVisionRequests(
    token: string,
    paginationToken: string | null,
  ): Promise<MyRequestsResponse> {
    const variables = { paginationToken };

    const res = await graphQlRequest(token).query({
      query: GET_MY_VISION_REQUESTS_QUERY,
      variables,
    });

    return res.data.getMyRequests;
  }

  async getMyVisionOffers(
    token: string,
    paginationToken: string | null,
    listId: VisionOffersTab,
  ): Promise<SellerOfferResponse> {
    const variables = { paginationToken, listId };

    const res = await graphQlRequest(token).query({
      query: GET_MY_VISION_OFFERS_QUERY,
      variables,
    });

    return res.data.getVisionOffers;
  }

  async getPotentialVisionOffers(
    token: string,
    paginationToken: string | null,
  ): Promise<SellerRequestsResponse> {
    const variables = { paginationToken };

    const res = await graphQlRequest(token).query({
      query: GET_POTENTIAL_VISION_OFFERS_QUERY,
      variables,
    });

    return res.data.getSellerRequests;
  }

  async getMyOffersTotalCounts(token: string): Promise<SellerRequestsTabCountResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_VISIONS_OFFERS_TOTAL_COUNTS_QUERY,
    });

    return res.data.getSellerRequestsCounts;
  }

  async sendVisionOffer(
    token: string,
    requestId: string,
    price: number,
    tariff: string | null,
    returnsPolicy: ReturnPolicyType,
    shippingProfileId: string,
    note: string | null,
    dimensions: IProductDimensions,
  ): Promise<SendOfferResponse> {
    const variables = {
      requestId,
      price: Math.floor(price * 100),
      tariff: tariff || null,
      returnsPolicy: mapReturnsPolicyTypeToRequest[returnsPolicy],
      shippingProfileId,
      note: note || null,
      dimensions: getGQLDimensions(dimensions),
    };

    const res = await graphQlRequest(token).mutate({
      mutation: SEND_VISION_OFFER_MUTATION,
      variables,
    });

    return res.data.sendVisionOffer;
  }

  async editVisionOffer(
    token: string,
    offerId: string,
    requestId: string,
    price: number,
    tariff: string | null,
    returnsPolicy: ReturnPolicyType,
    shippingProfileId: string,
    note: string | null,
    dimensions: IProductDimensions,
  ): Promise<SuccessResponse> {
    const variables = {
      offerId,
      requestId,
      price: Math.floor(price * 100),
      tariff: tariff || null,
      returnsPolicy: mapReturnsPolicyTypeToRequest[returnsPolicy],
      shippingProfileId,
      note: note || null,
      dimensions: getGQLDimensions(dimensions),
    };

    const res = await graphQlRequest(token).mutate({
      mutation: EDIT_VISION_OFFER_MUTATION,
      variables,
    });

    return res.data.editVisionOffer;
  }

  async getShippingRate(
    token: string,
    dimensions: IProductDimensions,
    requestId: string,
    shippingProfileId: string,
  ): Promise<ShippingRate> {
    const variables = {
      dimensions: getGQLDimensions(dimensions),
      requestId,
      shippingProfileId,
    };

    const res = await graphQlRequest(token).query({
      query: GET_SHIPPING_PRICE_BY_DIMENSIONS_QUERY,
      variables,
    });

    return res.data.getRecommendedShippingPriceForDimensions;
  }

  async getRequestForOffer(token: string, offerId: string): Promise<VisionRequest> {
    const variables = {
      id: offerId,
    };

    const res = await graphQlRequest(token).query({
      query: GET_REQUEST_FOR_OFFER_QUERY,
      variables,
    });

    return res.data.getVisionRequestForOffer;
  }
}
