import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IVisionsState, VisionOffersTab, VisionOfferType } from 'types';
import { getSmartTags } from '../generateVision/GenerateVisionActions';
import {
  getMyVisionOffers,
  getMyVisionOffersTotalCounts,
  getMyVisionRequests,
  getPotentialOffers,
  getVisionCreditsOptions,
  getVisionsInformation,
  getVisionsPage,
} from './VisionsActions';
import {
  parseVisionCreditOptions,
  parseVisionOffer,
  parseVisionOffers,
  parseVisionRequests,
  parseVisionsPage,
  parseVisionStaticItems,
} from '../apiParser';
import { getCurrentOfferList } from './helper';
import {
  deleteVisionRequest,
  hideVisionRequest,
  withdrawVisionOffer,
} from '../specificVisionRequest/SpecificVisionRequestActions';
import { sendVisionOffer } from '../sendVisionOffer/SendVisionOfferActions';

const initialState: IVisionsState = {
  myRequests: { list: null, paginationToken: null },
  myOffers: {
    activeTab: VisionOffersTab.Potential,
    potentialOffers: { list: null, paginationToken: null, totalCount: 0 },
    pendingOffers: { list: null, paginationToken: null, totalCount: 0 },
    expiredOffers: { list: null, paginationToken: null, totalCount: 0 },
  },
  promptDetails: { text: '', image: null },
  hasUsedSmartTags: false,
  totalCredits: null,
  visionCreditOptions: null,
  requestsPreview: null,
  items: null,
  tags: [],
  isLoading: false,
};

export const VisionsSlicer = createSlice({
  name: 'visions',
  initialState,
  reducers: {
    updateTotalCredits: (state, action) => {
      const { totalCredits } = action.payload;
      state.totalCredits = totalCredits;
    },
    onTextChange: (state, action) => {
      const { text } = action.payload;
      state.promptDetails.text = text;
    },
    onSmartTagClick: (state, action) => {
      const { tag } = action.payload;
      state.promptDetails.text += `${tag}, `;
      state.hasUsedSmartTags = true;
      state.tags = state.tags.filter((t) => t !== tag);
    },
    onImageAdd: (state, action) => {
      const { image } = action.payload;
      state.promptDetails.image = image;
    },
    removeImage: (state) => {
      state.promptDetails.image = null;
    },
    onEditVision: (state, action) => {
      const { image, text } = action.payload;
      state.promptDetails = {
        image,
        text,
      };
    },
    onTabChange: (state, action) => {
      const { tab } = action.payload;
      state.myOffers.activeTab = tab;
    },
    onOfferApprove: (state, action) => {
      const { offerId } = action.payload;

      if (state.myOffers.pendingOffers.list) {
        const selectedOffer = state.myOffers.pendingOffers.list.find(
          (offer) => offer.id === offerId,
        );

        if (selectedOffer) {
          selectedOffer.type = VisionOfferType.Approved;
        }
      }
    },
    onRequestDelete: (state, action) => {
      const { requestId } = action.payload;

      if (state.myOffers.potentialOffers.list) {
        state.myOffers.potentialOffers.list = state.myOffers.potentialOffers.list.filter(
          (request) => request.id !== requestId,
        );
      }
    },
    onEditOffer: (state, action) => {
      const { offer } = action.payload;

      const { list: pendingList } = state.myOffers.pendingOffers;

      const { list: expiredList } = state.myOffers.expiredOffers;

      const list = state.myOffers.activeTab === VisionOffersTab.Pending ? pendingList : expiredList;

      if (list) {
        const parsedOffer = parseVisionOffer(offer, VisionOffersTab.Pending);

        const selectedOfferIndex = list.findIndex((o) => o.id === offer.offerId);

        if (selectedOfferIndex !== -1) {
          if (state.myOffers.activeTab === VisionOffersTab.Pending) {
            list[selectedOfferIndex] = parsedOffer;
          }
          if (state.myOffers.activeTab === VisionOffersTab.Expired) {
            list.splice(selectedOfferIndex, 1);
            if (state.myOffers.pendingOffers.list) {
              state.myOffers.pendingOffers.list.push(parsedOffer);
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmartTags.fulfilled, (state, action) => {
      state.tags = action.payload.tags;
    });
    builder.addCase(getVisionsInformation.fulfilled, (state, action) => {
      const { getVisionInformationForUser, getSellerRequestsCounts } = action.payload;

      const { visionsLeft, myRequestCount, first3Requests, first3Seller } =
        getVisionInformationForUser;

      const { pendingCount, potentialCount } = getSellerRequestsCounts;
      state.totalCredits = visionsLeft;
      state.requestsPreview = {
        buyerDetails: {
          totalRequests: myRequestCount,
          images: first3Requests,
        },
        sellerDetails: {
          totalRequests: pendingCount + potentialCount,
          images: first3Seller,
        },
      };
    });
    builder.addCase(getVisionCreditsOptions.fulfilled, (state, action) => {
      state.visionCreditOptions = parseVisionCreditOptions(action.payload);
    });
    builder.addCase(getVisionsPage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVisionsPage.fulfilled, (state, action) => {
      const { visions, staticItems } = action.payload;
      state.isLoading = false;

      const ads = parseVisionStaticItems(staticItems);

      const items = parseVisionsPage(visions);
      state.items = [...ads, ...items].sort(
        (a, b) => a.sectionDetails.order - b.sectionDetails.order,
      );
    });
    builder.addCase(getMyVisionRequests.fulfilled, (state, action) => {
      const { requests, paginationToken } = action.payload;
      state.isLoading = false;
      if (paginationToken) {
        state.myRequests.paginationToken = paginationToken;
      }

      const prevList = state.myRequests.list || [];
      state.myRequests.list = [...prevList, ...parseVisionRequests(requests)];
    });
    builder.addCase(getMyVisionOffers.fulfilled, (state, action) => {
      const { offers, paginationToken } = action.payload;

      const { activeTab } = state.myOffers;
      state.isLoading = false;

      const currentOffers = getCurrentOfferList(state, activeTab);

      currentOffers.paginationToken = paginationToken || '';

      const prevList = currentOffers.list || [];
      currentOffers.list = [...prevList, ...parseVisionOffers(offers, activeTab)];
    });
    builder.addCase(getPotentialOffers.fulfilled, (state, action) => {
      const { requests, paginationToken } = action.payload;

      state.isLoading = false;

      const { potentialOffers } = state.myOffers;

      potentialOffers.paginationToken = paginationToken || '';

      const prevList = potentialOffers.list || [];
      potentialOffers.list = [...prevList, ...parseVisionRequests(requests)];
    });
    builder.addCase(getMyVisionOffersTotalCounts.fulfilled, (state, action) => {
      const { expiredCount, potentialCount, pendingCount } = action.payload;
      state.myOffers.expiredOffers.totalCount = expiredCount;
      state.myOffers.potentialOffers.totalCount = potentialCount;
      state.myOffers.pendingOffers.totalCount = pendingCount;
    });
    builder.addCase(deleteVisionRequest.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      if (state.myRequests.list) {
        state.myRequests.list = state.myRequests.list.filter((request) => request.id !== id);
      }

      if (state.requestsPreview) {
        state.requestsPreview.buyerDetails.totalRequests -= 1;
      }
    });
    builder.addCase(hideVisionRequest.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg;

      if (state.myOffers.potentialOffers.list) {
        state.myOffers.potentialOffers.list = state.myOffers.potentialOffers.list.filter(
          (request) => request.id !== requestId,
        );
      }
    });
    builder.addCase(withdrawVisionOffer.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      if (state.myOffers.pendingOffers.list) {
        state.myOffers.pendingOffers.list = state.myOffers.pendingOffers.list.filter(
          (offer) => offer.id !== id,
        );
      }
    });
    builder.addCase(sendVisionOffer.fulfilled, (state, action) => {
      const { requestId } = action.meta.arg;
      state.myOffers.pendingOffers.totalCount += 1;

      if (state.myOffers.potentialOffers.list) {
        state.myOffers.potentialOffers.list = state.myOffers.potentialOffers.list.filter(
          (request) => request.id !== requestId,
        );
      }
    });
    builder.addMatcher(
      isAnyOf(getPotentialOffers.pending, getMyVisionOffers.pending, getMyVisionRequests.pending),
      (state) => {
        state.isLoading = true;
      },
    );
  },
});
