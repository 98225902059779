import { Skeleton } from 'tedooo-web-design-system/ui';

import './feedFallback.scss';
import PostSkeleton from 'components/skeletons/postSkeleton/PostSkeleton';

const FeedFallback = () => {
  return (
    <div className="feed-fallback-container page">
      <div className="left-section">
        <div className="create-post-and-bubbles-container">
          <Skeleton avatar={{ shape: 'square' }} title={false} className="create-post-skeleton" />
          <Skeleton avatar={{ shape: 'square' }} title={false} className="bubbles-skeleton" />
        </div>
        <PostSkeleton />
        <PostSkeleton />
      </div>
      <div className="right-section">
        <Skeleton avatar={{ shape: 'square' }} title={false} className="right-skeleton" />
      </div>
    </div>
  );
};

export default FeedFallback;
