import { gql } from '@apollo/client';

export const GET_ORDER_DIGITAL_FILES_QUERY = gql`
  query GetDigitalFileUrlsForOrder($orderId: String!) {
    getDigitalFileUrlsForOrder(orderId: $orderId) {
      email
      files {
        name
        url
      }
    }
  }
`;

export const SEND_EMAILS_DIGITAL_FILES_MUTATION = gql`
  mutation SendDigitalFilesEmail($orderId: String!, $email: String!) {
    sendDigitalFilesEmail(orderId: $orderId, email: $email) {
      success
    }
  }
`;
