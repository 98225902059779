import { Middleware } from 'redux';

import { addReview, deleteReview } from './ReviewActions';
import { ShopSlicer } from '../shop/ShopActions';

export const reviewMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case addReview.fulfilled.type:
        const lastSlashIndex = window.location.pathname.lastIndexOf('/');

        const shopId = window.location.pathname.slice(lastSlashIndex + 1);
        window.history.pushState('', '', `/shop/${shopId}`);
        window.history.go();
        break;
      case deleteReview.fulfilled.type:
        dispatch(ShopSlicer.actions.deleteReview());
        break;
      default:
        break;
    }

    return next(action);
  };
