import { Notification } from 'tedooo-web-design-system/ui';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { ServerStatus } from 'store/shop/types';

const FailedToBuyShippingLabelMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.ERROR) {
    return <Notification type="error" text="Failed to buy shipping label" placement="top" />;
  }

  return null;
};

export default FailedToBuyShippingLabelMessage;
