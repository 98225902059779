import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ITransactionsState } from './types';
import {
  getCheckoutIdFromTransaction,
  getOneTimePaymentInformationFromLink,
} from './TransactionsActions';
import { parseOneTimePaymentInformationFromLink } from './apiParser';
import {
  getPaypalPayoutLinkByCheckoutId,
  getStripeDetailsByCheckoutId,
} from '../payouts/PayoutsActions';

const initialState: ITransactionsState = {
  details: null,
  checkoutId: null,
  errorMessage: null,
  description: null,
};

export const TransactionsSlicer = createSlice({
  name: 'TransactionsSlicer',
  initialState,
  reducers: {
    resetErrorMessage: (state) => {
      state.errorMessage = null;
    },
    resetCheckoutId: (state) => {
      state.checkoutId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOneTimePaymentInformationFromLink.pending, (state) => {
      state.details = null;
      state.description = null;
      state.errorMessage = null;
    });
    builder.addCase(getOneTimePaymentInformationFromLink.fulfilled, (state, action) => {
      state.details = parseOneTimePaymentInformationFromLink(action.payload);
    });
    builder.addCase(getCheckoutIdFromTransaction.pending, (state) => {
      state.checkoutId = null;
      state.description = null;
      state.errorMessage = null;
    });
    builder.addCase(getCheckoutIdFromTransaction.fulfilled, (state, action) => {
      state.checkoutId = action.payload;
      state.description = action.meta.arg.description;
    });
    builder.addMatcher(
      isAnyOf(getPaypalPayoutLinkByCheckoutId.pending, getStripeDetailsByCheckoutId.pending),
      (state) => {
        state.errorMessage = null;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getPaypalPayoutLinkByCheckoutId.rejected,
        getStripeDetailsByCheckoutId.rejected,
        getCheckoutIdFromTransaction.rejected,
      ),
      (state, action) => {
        state.errorMessage = action.error.message || 'Something went wrong';
      },
    );
  },
});
