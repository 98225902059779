import { ServerStatus } from 'types';

export enum MessageIds {
  linkCopied,
  createShopError,
  joinGroupInvitationError,
  leaveGroupError,
  joinGroupRequest,
  changePassword,
  deletePost,
  repost,
  undoRepost,
  hideFromActivity,
  changeCurrency,
  orderReviewed,
  emailSent,
  requestOrderError,
  failedToBuyShippingLabel,
  toggleFavoriteProduct,
  vacationModeSuccess,
  generateVisionError,
  removeItemFromBasket,
}

export interface IMessageState {
  id: MessageIds | null;
  link?: string;
  postId?: string;
  actionId?: string;
  isLiked?: boolean;
  serverStatus?: ServerStatus | null;
}
