import { IReviewApi, IReviewsApi, IReviewsResponseApi, Method } from 'types';
import { fetcher, uploadImage } from 'api';

export class ReviewReducer implements IReviewsApi {
  getReviews(id: string, token?: string): Promise<IReviewsResponseApi> {
    const queryParams = {
      shop: id,
    };

    return fetcher('review', Method.GET, token, undefined, queryParams);
  }

  async addReview(
    id: string,
    files: Array<string>,
    description: string,
    title: string,
    rate: number,
    token?: string,
  ): Promise<IReviewApi> {
    const images = await Promise.all(files.map((file) => uploadImage({ file })));

    const body = {
      description,
      title,
      stars: rate,
      images,
    };

    return fetcher(`v2/review/${id}`, Method.POST, token, body);
  }

  deleteReview(reviewId: string, token?: string): Promise<IReviewApi> {
    return fetcher(`review/${reviewId}`, Method.DELETE, token);
  }
}
