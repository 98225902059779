import { ICreatePostRequest, IShopShortInfo, ImageComposition, IPost, ITaggedUser } from 'types';
import { CurrencyCode } from 'constant/currencies';

export interface ICreatePost {
  isSeller: boolean;
  onUserClick(): void;
}

export interface ICreatePostPopup {
  username: string;
  initialValues: {
    id: string | null;
    product: IPostProduct | null;
    text: string;
    images: string[] | null;
    backgroundIndex: TextBackground | null;
    imageComposition: ImageComposition;
    repostDetails: IPost | null;
    shopId: string | null;
    taggedUsers: ITaggedUser[];
    visionId: string | null;
  };
  shouldOpenImagePicker?: boolean;
  shouldOpenCatalog?: boolean;
  avatar: string;
  fullName: string | null;
  shops: IShopShortInfo[];
  isCatalogLoading: boolean;
  initialTaggedUsers: ITaggedUser[];
  isPremium: boolean;
  userId: string | null;
  onHide(): void;
  getProducts(input: { shopId: string }): void;
  onPostCreate(input: ICreatePostRequest): void;
}

export interface ICreatePostInitialValues {
  id?: string;
  product?: IPostProduct;
  text?: string;
  images?: string[] | null;
  backgroundIndex?: TextBackground | null;
  imageComposition?: ImageComposition;
  repostDetails?: IPost | null;
  shopId?: string | null;
  taggedUsers?: ITaggedUser[];
  visionId?: string;
}

export interface IPostProduct {
  id: string;
  images: string[];
  title: string;
  price: number;
  isDigital: boolean;
  originalPrice: number;
  currencySymbol: string;
  currencyCode: CurrencyCode;
}

export enum CreatePostPhases {
  Details,
  EditPost,
  EditImages,
  ChooseShop,
  ChooseItem,
}

export interface IPostDetails {
  imageComposition: ImageComposition;
  textBackground: TextBackground | null;
  repostDetails: IPost | null;
  shopImage: string;
  avatar: string | null;
  title: string;
  subtitle: string;
  isImagesLoading: boolean;
  images: string[];
  text: string;
  hasMoreOptions: boolean;
  isProfileSelected: boolean;
  product: IPostProduct | null;
  isShoppingPost: boolean;
  onTagSelect(selectedUser: ITaggedUser): void;
  onBackgroundChange(image: TextBackground | null): void;
  onProductRemove(product: IPostProduct | null): void;
  onShopClick(): void;
  onTextChange(text: string): void;
  onImagesExpend(): void;
  onImagesClear(): void;
}

export interface IShopSelectionProps {
  shops: IShopShortInfo[];
  avatar: string | null;
  username: string;
  fullName: string;
  selectedOption: string | null;
  defaultSelectedShopId: string | null;
  onShopClick(id: string | null): void;
}

export interface IItemSelection {
  shop: IShopShortInfo;
  isLoading: boolean;
  onClick(product: IPostProduct): void;
}

export enum TextBackground {
  Planes,
  Megaphone,
  DIY,
  Wreath,
  Knit,
  Paint,
  Plants,
  Birds,
  Clear,
}

export interface IProductContainer {
  image: string;
  currencyCode: CurrencyCode;
  price: number | null;
  title: string;
  originalPrice: number;
  showBuyButton: boolean;
  onClick(): void;
}

export interface ITagUser {
  id: string;
  fullName: string;
  username: string;
  avatar: string;
  priority: number;
}
