import { toCanvas } from 'html-to-image';

import { uploadImage } from './aws';

// Creates an existing DOM element as a canvas element. Which could later be converted to a file
export const createCanvas = async (containerId: string, elementClassNameToRemove?: string) => {
  const elementToScreenShot = document.getElementById(containerId) || document.body;

  if (elementClassNameToRemove) {
    const autoCompleteDropdown = document.getElementsByClassName(elementClassNameToRemove);

    if (autoCompleteDropdown.length > 0) {
      autoCompleteDropdown[0].remove();
    }
  }
  let canvas: HTMLCanvasElement | null = null;
  if (elementToScreenShot) {
    canvas = await toCanvas(elementToScreenShot);
  }

  return canvas;
};

// Creates a file from a canvas element, and uploads to aws
export const convertCanvasToFile = async (canvas: HTMLCanvasElement) => {
  return new Promise((res) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], 'image.jpg', {
          type: blob.type,
        });
        res(uploadImage({ file }));
      }
    });
  });
};

export const getFileType = (image: string) => {
  const splitImage = image.split('.');

  return splitImage[splitImage.length - 1];
};
