import { FC } from 'react';
import { connect } from 'react-redux';
import { Button } from 'tedooo-web-design-system/ui';
import { Smartphone } from 'tedooo-web-design-system/icons';

import './stickyGetTheAppButton.scss';

import { PopupSlicer } from 'store/popup/PopupActions';
import { IStore2, popupIdsOptions } from 'types';
import { translations } from 'translations/en';

export interface IGetTheAppButton {
  showPopup(options: { id: string }): void;
  token: string | undefined;
}

const StickyGetTheAppButton: FC<IGetTheAppButton> = ({ showPopup, token }) => {
  const onClick = () => {
    showPopup({ id: popupIdsOptions.getTheAppPopup });
  };
  if (!token) {
    return null;
  }

  return (
    <Button
      type="secondary"
      size="medium"
      borderRadius="large"
      text={translations.GET_THE_APP.GET_THE_APP}
      className="sticky-get-the-app-button"
      prefixIcon={<Smartphone />}
      onClick={onClick}
    />
  );
};

const mapStateToProps = (state: IStore2) => {
  const { token } = state.user;

  return { token };
};

const mapDispatchToProps = {
  showPopup: PopupSlicer.actions.show,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickyGetTheAppButton);
