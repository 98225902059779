import { lazy, Suspense, useEffect } from 'react';
import { Routes as ReactRoutes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { shouldShowGetTheAppPage } from 'api';
import { WithContent } from 'components/layout/WithContent';
import { checkIfPathBlockedByFeatureFlag, checkIfPathBlockedByNonLogin, routes } from './helper';
import { store, useTedoooSelector } from 'store/Store';
import { UserSelector } from 'store/user/UserSelector';
import { getAppConfig, getLoggedInUserInfo, refreshToken } from 'store/user/UserActions';
import { StorageSrv } from 'services/StorageSrv';
import { shouldRefreshToken } from 'store/user/helper';
import { PopupSlicer } from 'store/popup/PopupActions';
import { popupIdsOptions } from 'types';
import Onboarding from 'pages/onboarding/Onboarding';
import { CreateShopSlicer } from 'store/createShop/CreateShopSlicer';
import { SEARCH_PARAMS } from 'constant';
import { mapPopupDeepLinkToId } from 'components/popup/hepler';
import { getTotalBasketItems } from 'store/basket/BasketActions';
import Feed from '../pages/feed/Feed';
import { AnalyticsSrv } from '../services/AnalyticsSrv';

const SignInPage = lazy(() => import('pages/signPage/SignPage'));

const Routes = () => {
  const isGetTheAppPage = shouldShowGetTheAppPage();

  const token = useTedoooSelector(UserSelector.tokenSelector);

  const shouldShowOnboarding = useTedoooSelector(UserSelector.shouldShowOnboarding);

  const introPopupId = useTedoooSelector(UserSelector.getIntroPopupSelector);

  const orderIdToShow = useTedoooSelector(UserSelector.orderIdToShowSelector);

  const userId = useTedoooSelector((state) => state.user.id);

  const featureFlags = useTedoooSelector(UserSelector.featureFlagsSelector);

  const [params] = useSearchParams();

  const popupKey = params.get(SEARCH_PARAMS.POPUP_KEY);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRefreshToken(token)) {
      store.dispatch(refreshToken());
    }
  }, []);

  useEffect(() => {
    const popupId = mapPopupDeepLinkToId[popupKey || ''];

    if (popupId) {
      dispatch(PopupSlicer.actions.show({ id: popupId }));
    }
  }, [popupKey]);

  useEffect(() => {
    const redirectUrl = StorageSrv.redirectLink.get();

    if (token) {
      dispatch(getAppConfig());
      dispatch(getTotalBasketItems());
    }

    if (token && !isGetTheAppPage) {
      dispatch(getLoggedInUserInfo({ token }));

      if (redirectUrl) {
        StorageSrv.redirectLink.remove();
        navigate(redirectUrl);
      }
    }
    if (userId) {
      const createShopDraft = StorageSrv.draft.get(userId);

      if (createShopDraft) {
        dispatch(CreateShopSlicer.actions.updateDraft({ draft: createShopDraft }));
      }
    }
  }, [token, isGetTheAppPage]);

  useEffect(() => {
    if (introPopupId && !shouldShowOnboarding) {
      dispatch(PopupSlicer.actions.show({ id: introPopupId }));
    }
  }, [introPopupId]);

  useEffect(() => {
    AnalyticsSrv.initialize(userId);
  }, [userId]);

  useEffect(() => {
    if (orderIdToShow) {
      dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.newOrderPopup }));
    }
  }, [orderIdToShow]);
  if (isGetTheAppPage) {
    dispatch(
      PopupSlicer.actions.show({ id: popupIdsOptions.getTheAppPopup, shouldNotClose: true }),
    );
    StorageSrv.getTheAppPage.update();
  }

  if (token && shouldShowOnboarding) {
    return (
      <ReactRoutes>
        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <Onboarding />
            </Suspense>
          }
        />
      </ReactRoutes>
    );
  }

  return (
    <ReactRoutes>
      {routes.map((route, i) => {
        const shouldBlockRouteFeatureFlag = checkIfPathBlockedByFeatureFlag(
          route.path,
          featureFlags,
        );

        const shouldBlockRouteLogin = checkIfPathBlockedByNonLogin(token, route.path);

        const shouldBlockRoute = shouldBlockRouteFeatureFlag || shouldBlockRouteLogin;

        if (shouldBlockRoute) {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<></>}>
                  {shouldBlockRouteLogin ? <SignInPage /> : WithContent(Feed)()}
                </Suspense>
              }
            />
          );
        }

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Suspense fallback={WithContent(route.fallback)()}>
                {route.isWithLayout ? WithContent(route.element)() : <route.element />}
              </Suspense>
            }
          />
        );
      })}
    </ReactRoutes>
  );
};

export default Routes;
