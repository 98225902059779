import { Notification, Span } from 'tedooo-web-design-system/ui';
import { useDispatch } from 'react-redux';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { ServerStatus } from 'types';
import { toggleRepost } from 'store/feed/PostActions';
import { translations } from 'translations/en';

const UndoRepostMessage = () => {
  const dispatch = useDispatch();

  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  const postId = useTedoooSelector(MessageSelector.postIdSelector) || '';

  const actionId = useTedoooSelector(MessageSelector.actionIdSelector) || '';

  const onUndoPostClick = () => {
    dispatch(
      toggleRepost({
        postId,
        isReposted: true,
        loggedInUsername: '',
        actionId,
      }),
    );
  };

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text={translations.FEED.POST.UNDO_REPOST_MESSAGE}
        placement="top"
        description={<Span text="Undo" onClick={onUndoPostClick} />}
        className="undo-repost-message"
      />
    );
  }

  return null;
};

export default UndoRepostMessage;
