import { FC, memo, useState } from 'react';
import classNames from 'classnames';

import { Span } from '../helpers';
import './expandedText.scss';
import Linkify from '../linkify/Linkify';
import { IExpandableText } from 'types';

const MORE_TEXT = 'See more';

const LESS_TEXT = 'See less';

const ExpandableText: FC<IExpandableText> = ({
  text = '',
  isCollapsable,
  isTextClickable,
  maxChars,
  mentions,
}) => {
  const isTextOverflow = text.length > maxChars;

  const [isExpanded, setIsExpanded] = useState(false);

  const onToggleText = (e: any) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const onTextClick = (e: any) => {
    if (isTextOverflow) {
      onToggleText(e);
    }
  };

  const textLength = isExpanded ? text.length : maxChars;

  const visibleText = `${text.slice(0, textLength)}`;

  const toggleText = ` ...${isExpanded ? LESS_TEXT : MORE_TEXT}`;

  const isButtonHidden = !isCollapsable && isExpanded;

  const noText = textLength === 0;

  return (
    <div
      className={classNames('expanded-text-container', {
        'hide-button': isButtonHidden,
        'no-text': noText,
        'expanded-text': isExpanded,
      })}
      onClick={onTextClick}
    >
      <Linkify
        text={visibleText}
        mentions={mentions}
        className={classNames('text', { clickable: isTextClickable })}
      />
      {isTextOverflow && (
        <Span className="clickable-text" text={toggleText} onClick={onToggleText} />
      )}
    </div>
  );
};

export default memo(ExpandableText);
