import { INotificationApi, INotificationsResponse, Method } from 'types';
import { fetcher } from '../../api';

export class NotificationApi implements INotificationApi {
  getNotifications(maxId: string | null, token: string): Promise<INotificationsResponse> {
    const query = { maxId: maxId || undefined };

    return fetcher('v2/notifications', Method.GET, token, undefined, query);
  }

  getOrderNotifications(maxId: string, token: string): Promise<INotificationsResponse> {
    const query = { maxId: maxId || undefined };

    return fetcher('v2/notifications/orders', Method.GET, token, undefined, query);
  }
}
