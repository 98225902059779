import {
  IOrderDetails,
  IOrdersMode,
  ISpecificOrderState,
  LoadMoreTypes,
  OrderStatuses,
} from 'types';
import { CurrencyCode } from 'constant/currencies';

const DEFAULT_ORDER_DETAILS: IOrderDetails = {
  id: '',
  orderNumber: '',
  title: '',
  status: OrderStatuses.Requested,
  shippingPrice: null,
  shippingAddressName: '',
  creationDate: 0,
  shippingProviderImage: null,
  isInstantCheckout: false,
  lastActivityDate: 0,
  paidPaymentMethod: null,
  packingSlipUrl: null,
  shippingLabelUrl: null,
  isSeller: false,
  hasDigitalFiles: false,
  partnerDetails: {
    id: '',
    avatar: '',
    username: '',
    email: '',
    fullName: '',
    coverPhoto: '',
    isAnonymous: false,
  },
  shopName: '',
  subTotalPrice: 0,
  totalPrice: 0,
  totalDiscount: 0,
  currencyCode: CurrencyCode.USD,
  items: [],
  trackingNumber: null,
  shopId: '',
  isShopReviewed: false,
  linkedPaymentOptions: [],
  originalPrice: { value: 0, currencyCode: CurrencyCode.USD, currencySymbol: '$' },
  payoutOptions: [],
  originAddress: null,
  shippingLabelDetails: null,
  selectedShippingService: null,
  recipientAddress: null,
  dimensions: null,
  expiryDate: null,
  isAnonymous: false,
  discountDetails: null,
};

export const getMaxTime = (orders: IOrderDetails[], mode: LoadMoreTypes): number => {
  const declinedOrders = orders.filter((order) => order.status === OrderStatuses.Decline);

  const completedOrders = orders.filter((order) => order.status === OrderStatuses.Decline);

  const lastCompletedOrderDate = completedOrders[completedOrders.length - 1].creationDate;

  const lastDeclinedOrderDate = declinedOrders[declinedOrders.length - 1].creationDate;

  if (mode === LoadMoreTypes.Completed) {
    return lastCompletedOrderDate;
  }
  if (mode === LoadMoreTypes.Declined) {
    return lastDeclinedOrderDate;
  }

  return Math.min(lastDeclinedOrderDate, lastCompletedOrderDate);
};

export const getSpecificOrder = (
  orders: IOrderDetails[],
  orderId: string,
): IOrderDetails | null => {
  return orders.find((order) => order.id === orderId) || null;
};

export const addOrderToList = (
  orders: { list: IOrderDetails[]; totalCount: number },
  order: IOrderDetails,
) => {
  order.lastActivityDate = Date.now();
  orders.list = [order, ...orders.list];
  orders.totalCount += 1;
};

export const removeOrderFromList = (
  orders: { list: IOrderDetails[]; totalCount: number },
  orderId: string,
) => {
  const specificOrder = getSpecificOrder(orders.list, orderId);

  if (specificOrder) {
    orders.list = orders.list.filter((order) => order.id !== orderId);
    orders.totalCount -= 1;
  }
};

export const getOrder = (
  orderId: string | null,
  specificOrder: ISpecificOrderState | null,
  buyerOrders: IOrdersMode | null,
  sellerOrders: IOrdersMode | null,
  isSeller: boolean,
): IOrderDetails => {
  const orders = isSeller ? sellerOrders : buyerOrders;

  if (
    specificOrder &&
    specificOrder.id &&
    specificOrder.orderDetails &&
    specificOrder.id === orderId
  ) {
    return { ...specificOrder.orderDetails, id: specificOrder.id };
  }

  if (!orders) {
    return DEFAULT_ORDER_DETAILS;
  }

  const { todoOrders, pastOrders, awaitingOrders, shippedOrders } = orders;

  const allOrders = [
    ...todoOrders.list,
    ...awaitingOrders.list,
    ...shippedOrders.list,
    ...pastOrders.list,
  ];

  return allOrders.find((order) => order.id === orderId) || DEFAULT_ORDER_DETAILS;
};
