import {
  ICreatePostInitialValues,
  IPost,
  ISpecificPostState,
  RepostOptions,
  IPostProduct,
} from 'types';
import { translations } from 'translations/en';

export const parsePostToEditPost = (
  selectedPost: IPost | ISpecificPostState,
): ICreatePostInitialValues => {
  let postImages = selectedPost.images;
  if (selectedPost.backgroundIndex !== undefined || selectedPost.productDetails) {
    postImages = [];
  }

  const backgroundIndex =
    selectedPost.backgroundIndex !== undefined ? selectedPost.backgroundIndex : null;
  let product: IPostProduct | undefined;

  if (selectedPost.productDetails) {
    const productImage = selectedPost.images ? [selectedPost.images[0]] : [];
    product = {
      id: selectedPost.productDetails.id,
      images: productImage,
      title: selectedPost.productDetails.title,
      isDigital: false,
      price: selectedPost.productDetails.price.value,
      originalPrice: selectedPost.productDetails.price.value,
      currencySymbol: selectedPost.productDetails.price.currencySymbol,
      currencyCode: selectedPost.productDetails?.price.currencyCode,
    };
  }

  return {
    id: selectedPost.id,
    imageComposition: selectedPost.imageComposition,
    shopId: selectedPost.shopId || null,
    images: postImages,
    text: selectedPost.text,
    product,
    backgroundIndex,
    repostDetails: selectedPost.repostDetails,
    taggedUsers: selectedPost.taggedUsers,
  };
};

export const mapRepostOptionToTitle = {
  [RepostOptions.Repost]: translations.FEED.POST.REPOST,
  [RepostOptions.RepostWithThoughts]:
    translations.FEED.POST.REPOST_POPUP.REPOST_WITH_THOUGHTS_TITLE,
  [RepostOptions.UndoRepost]: translations.FEED.POST.REPOST_POPUP.UNDO_REPOST_TITLE,
};

export const mapRepostOptionToDescription = {
  [RepostOptions.Repost]: translations.FEED.POST.REPOST_POPUP.REPOST_DESCRIPTION,
  [RepostOptions.RepostWithThoughts]:
    translations.FEED.POST.REPOST_POPUP.REPOST_WITH_THOUGHTS_DESCRIPTION,
  [RepostOptions.UndoRepost]: translations.FEED.POST.REPOST_POPUP.UNDO_REPOST_DESCRIPTION,
};
