import { createAsyncThunk } from '@reduxjs/toolkit';

import { VisionsApi } from './VisionsApi';
import { DiscoverListsId, IStore2, VisionOffersTab } from 'types';

const api = new VisionsApi();

export const getVisionsPage = createAsyncThunk(
  'async/getVisionsPage',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getVisionsPage(token);
  },
);

export const getVisionsByListId = createAsyncThunk(
  'async/getVisionsByListId',
  async (input: { listId: DiscoverListsId; paginationToken: string | null }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { paginationToken: initialPaginationToken, listId } = input;

    const { paginationToken } = state.visionsList;

    return api.getVisionsByListId(token, listId, initialPaginationToken || paginationToken);
  },
);

export const getVisionsInformation = createAsyncThunk(
  'async/getVisionsInformation',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getVisionsInformation(token);
  },
);

export const getVisionCreditsOptions = createAsyncThunk(
  'async/getVisionCreditsOptions',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getCreditOptions(token);
  },
);

export const startVisionsCreditsCheckout = createAsyncThunk(
  'async/startVisionsCreditsCheckout',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return api.startVisionsCreditsCheckout(token, id);
  },
);

export const getMyVisionRequests = createAsyncThunk(
  'async/getMyVisionRequests',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { paginationToken } = state.visions.myRequests;

    return api.getMyVisionRequests(token, paginationToken);
  },
);

export const getMyVisionOffers = createAsyncThunk(
  'async/getMyVisionOffers',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { expiredOffers, pendingOffers, activeTab } = state.visions.myOffers;

    let activePaginationToken = pendingOffers.paginationToken;

    if (activeTab === VisionOffersTab.Expired) {
      activePaginationToken = expiredOffers.paginationToken;
    }

    return api.getMyVisionOffers(token, activePaginationToken, activeTab);
  },
);

export const getPotentialOffers = createAsyncThunk(
  'async/getPotentialOffers',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { potentialOffers } = state.visions.myOffers;

    return api.getPotentialVisionOffers(token, potentialOffers.paginationToken);
  },
);

export const getMyVisionOffersTotalCounts = createAsyncThunk(
  'async/getMyVisionOffersTotalCounts',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getMyOffersTotalCounts(token);
  },
);
