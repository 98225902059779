import { createSlice } from '@reduxjs/toolkit';

import { DiscoverListsId, IVisionsListState } from 'types';
import { getVisionsByListId } from '../visions/VisionsActions';
import { parseVisions } from '../apiParser';

const initialState: IVisionsListState = {
  listId: DiscoverListsId.MyVisions,
  list: null,
  paginationToken: null,
  offset: 0,
  visionIndexToScroll: 0,
  isLoading: false,
};

export const VisionsListSlicer = createSlice({
  name: 'visionsList',
  initialState,
  reducers: {
    reset: () => initialState,
    updateVisionIndexToScroll: (state, action) => {
      state.visionIndexToScroll = action.payload.index;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionsByListId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVisionsByListId.fulfilled, (state, action) => {
      const { visions, paginationToken } = action.payload;
      state.isLoading = false;

      if (paginationToken) {
        state.paginationToken = paginationToken;
      }

      const parsedData = parseVisions(visions);

      const currentVisions = state.list || [];
      state.list = [...currentVisions, ...parsedData];
    });
  },
});
