import { Fonts } from 'constant/fonts';
import './mentionsDropdown.scss';

const tempSpan = document.createElement('span');
tempSpan.style.visibility = 'hidden';
tempSpan.style.position = 'absolute';
tempSpan.style.wordBreak = 'break-word';
document.body.appendChild(tempSpan);

const DROPDOWN_OFFSET = 12;

export const getCursorOffset = (text: string, inputWidth: number, font: Fonts): number => {
  tempSpan.className = font;
  tempSpan.innerText = text;
  tempSpan.style.width = `${inputWidth}px`;

  return tempSpan.offsetHeight + DROPDOWN_OFFSET;
};

export const getLastWord = (text: string): string => {
  return text.replaceAll('\n', ' ').split(' ').pop() || '';
};
