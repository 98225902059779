export const getImagesSizes = async (images: string[]) => {
  return await Promise.all(images.map(async (img: string) => await getImageDimensions(img)));
};

export const getImageDimensions = (image: string): Promise<{ height: number; width: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const { height, width } = img;
      resolve({ height, width });
    };
    img.onerror = reject;
  });
};
