import {
  IUserOrderStatistics,
  OrderStatuses,
  ILinkedPaymentOptionsResponse,
  IOrderPartnerInfoResponse,
  IPriceResponse,
  IOrderItemResponse,
  PayoutMethods,
  IOrderDetails,
  IShopViewers,
  ISelectedShippingServiceResponse,
} from 'types';
import { Countries } from 'constant/countries';

export interface IOrdersState {
  sellerOrders: IOrdersMode | null;
  buyerOrders: IOrdersMode | null;
  isSellerMode: boolean;
  sellerOrderStatistics: IUserOrderStatistics | null;
  buyerOrderStatistics: IUserOrderStatistics | null;
  shopViewers: IShopViewers[];
}

export interface IOrdersMode {
  todoOrders: { list: IOrderDetails[]; totalCount: number };
  awaitingOrders: { list: IOrderDetails[]; totalCount: number };
  shippedOrders: { list: IOrderDetails[]; totalCount: number };
  pastOrders: { list: IOrderDetails[]; totalCount: number };
  totalCompletedOrders: number;
  totalDeclinedOrders: number;
}

export interface IOrdersListState {
  todoOrders: { list: IOrderDetails[]; totalCount: number };
  awaitingOrders: { list: IOrderDetails[]; totalCount: number };
  shippedOrders: { list: IOrderDetails[]; totalCount: number };
  pastOrders: { list: IOrderDetails[]; totalCount: number };
}

export interface IOrdersApi {
  getSellerOrders(token: string): Promise<IUserOrdersResponse>;
  getBuyerOrders(token: string): Promise<IUserOrdersResponse>;
  loadMoreSellerOrders(
    mode: LoadMoreTypes,
    maxTime: number,
    token: string,
  ): Promise<ILoadMoreOrdersResponse>;
  loadMoreBuyerOrders(
    mode: LoadMoreTypes,
    maxTime: number,
    token: string,
  ): Promise<ILoadMoreOrdersResponse>;
}

export interface ISellerInformationResponse {
  strength: number;
  shopId: string;
  totalActiveOrders: number;
  totalShopViews: number;
  totalExternalLinks: number;
}

export interface IPaymentOptionResponse {
  provider: string;
  info: string;
}

export interface IUserOrdersResponse {
  youNext: IOrderResponse[];
  otherNext: IOrderResponse[];
  pastOrders: IOrderResponse[];
  totalPastOrders: number;
  totalCompletedOrders: number;
  totalDeclinedOrders: number;
  hasBuyingPendingOrders?: boolean;
  sellerInformation?: ISellerInformationResponse;
}

export interface ILoadMoreOrdersResponse {
  hasMore: boolean;
  offers: IOrderResponse[];
}

export interface IOrderResponse {
  id: string;
  orderNumber: string;
  items: IOrderItemResponse[];
  shippingPrice?: number;
  creationDate: string;
  lastUpdated: number;
  status: OrderStatuses;
  statusStep: number;
  sender: string;
  recipient: string;
  sent: boolean;
  partnerInfo: IOrderPartnerInfoResponse;
  paymentOptions: IPaymentOptionResponse[];
  shippingAddress?: string;
  address?: {
    address: string;
    city: string;
    state?: string;
    country?: Countries;
    postalCode?: string;
    phoneNumber?: string;
  };
  expiryDate?: number;
  trackingNumber?: string;
  packingSlipLink?: string;
  labelLink?: string;
  shopId: string;
  shopName: string;
  additionalDetails: string;
  fullName?: string;
  pending?: boolean;
  isShopReviewed?: boolean;
  isSellerDeclined?: boolean;
  isSellerConnectedPayouts: boolean;
  paidPaymentMethod?: PayoutMethods;
  linkedPaymentOptions: ILinkedPaymentOptionsResponse[];
  originalPrice: IPriceResponse;
  hasDigitalFiles?: boolean;
  origin: IOriginAddressResponse;
  selectedShippingService: ISelectedShippingServiceResponse | null;
  dimensions: IDimensionsResponse | null;
  isInstantCheckout?: boolean;
  discountDetails?: IDiscountDetails;
}

export interface IDiscountDetails {
  finalPrice: number;
  couponCodeName: string;
}

export interface IDiscountDetailsState {
  discountDetails: IDiscountDetails | null;
  isLoading: boolean;
  errorMessage: string | null;
}

export interface IDimensionsResponse {
  height: number;
  isMetric: boolean;
  length: number;
  weight: number;
  weightBig: number;
  weightSmall: number;
  width: number;
}

export interface IOriginAddressResponse {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  phoneNumber: string;
}

export enum LoadMoreTypes {
  Completed = 'completed',
  Declined = 'declined',
  Both = 'both',
}

export interface IGetPaymentSessionDetailsResponse {
  id: string;
  clientId: string;
  merchantDisplayName: string;
  customerId: string;
  customerToken: string;
  paymentIntentSecret: string;
  paymentMethods: string[];
  checkoutUrl?: string;
  price: IPriceResponse;
}

export interface IProfileViewerResponse {
  _id: string;
  username: string;
  name: string;
  avatar: string | null;
  shopName: string | null;
  time: string;
}

export interface IGetShopViewersResponse {
  data: IProfileViewerResponse[];
  premium: boolean;
}

export interface IReviewOrderResponse {
  newTotals: number[];
  newAvg: number;
  updatedReview: {
    _id: string;
    text?: string;
    title?: string;
    stars: number;
    images: string[];
  };
}
