import { gql } from '@apollo/client';

export const VACATION_MODE_MUTATION = gql`
  mutation updateVacationDetails($shopMessage: String, $autoReplyMessage: String) {
    updateVacationDetails(
      request: { shopMessage: $shopMessage, autoReplyMessage: $autoReplyMessage }
    ) {
      success
    }
  }
`;

export const GET_VACATION_MODE_DETAILS_QUERY = gql`
  query GetVacationDetails {
    getVacationDetails {
      isActive
      autoReplyMessage
      shopMessage
    }
  }
`;
