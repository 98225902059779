import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MenuReducer } from './MenuReducer';
import { IMenuState } from './types';
import { getUrlTextParamValue } from 'utils';

const initialState: IMenuState = {
  search: getUrlTextParamValue() || '',
  searchOptions: [],
  recentSearches: [],
};

const reducer = new MenuReducer();

export const onSearch = createAsyncThunk('async/changeSearch', async (input: { text: string }) =>
  reducer.onSearchChange(input.text),
);

export const MenuSlicer = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    onSubmit: (state, action) => {
      const { selectedOption } = action.payload;

      const updatedOptions = state.recentSearches.filter((item) => item !== selectedOption);
      updatedOptions.unshift(selectedOption);
      if (updatedOptions.length > 5) {
        updatedOptions.pop();
      }
      state.recentSearches = updatedOptions;
      state.search = selectedOption;
    },
    reset: (state) => {
      state.recentSearches = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onSearch.fulfilled, (state, action) => {
      const { text } = action.meta.arg;

      const { payload: options } = action;
      state.search = text;
      state.searchOptions = options;
    });
  },
});
