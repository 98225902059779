import './feedBubblesCarouselSkeleton.scss';
import { Skeleton } from 'tedooo-web-design-system/ui';

import { createArray } from 'utils';

const FeedBubblesCarouselSkeleton = () => {
  const items = createArray(6);

  return (
    <div className="feed-bubbles-carousel-skeleton">
      <div className="items-container">
        {items.map((_, index) => (
          <div className="bubble-skeleton-container" key={index}>
            <Skeleton title className="title" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedBubblesCarouselSkeleton;
