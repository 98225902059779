import { FC } from 'react';
import { Button } from 'tedooo-web-design-system/ui';
import { ArrowRight } from 'tedooo-web-design-system/icons';

const NextArrow: FC<any> = ({ currentSlide, slideCount, ...props }) => (
  <div {...{ ...props, currentslide: currentSlide, slidecount: slideCount }}>
    <Button
      type="secondary"
      size="medium"
      borderRadius="large"
      prefixIcon={<ArrowRight />}
      className="slider-button"
    />
  </div>
);

export const favoritesSliderSettings = {
  slidesToShow: 1.7,
  slidesToScroll: 0.95,
  initialSlide: 0,
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <NextArrow />,
};
