import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ReviewReducer } from './ReviewReducer';
import { IReviewState, IStore2, ServerStatus } from 'types';
import { parseReviews } from '../apiParser';

const initialState: IReviewState = {
  avg: 0,
  defaultRate: 0,
  data: [],
};

const reducer = new ReviewReducer();

export const getReviews = createAsyncThunk(
  'async/getReviews',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token } = state.user;

    return reducer.getReviews(id, token);
  },
);

export const addReview = createAsyncThunk(
  'async/addReview',
  async (
    input: { title: string; description: string; rate: number; images: Array<any> },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { details } = state.shop;

    const id = details?.id || '';

    const { title, images, description, rate } = input;

    const { token } = state.user;

    return reducer.addReview(id, images, description, title, rate, token);
  },
);

export const deleteReview = createAsyncThunk(
  'async/deleteReview',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token } = state.user;

    return reducer.deleteReview(id, token);
  },
);

export const ReviewSlicer = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setDefaultRate: (state, action) => {
      const { rate } = action.payload;
      state.defaultRate = rate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReviews.fulfilled, (state, action) => {
      const { payload } = action;

      state.data = parseReviews(payload);
      state.avg = payload.avg;
      state.status = undefined;
      state.serverMessage = undefined;
    });
    builder.addCase(deleteReview.fulfilled, (state, action) => {
      const { _id } = action.payload;

      state.data = state.data.filter((item) => item.id !== _id);
    });

    builder.addCase(addReview.rejected, (state, action) => {
      state.status = ServerStatus.ERROR;
      state.serverMessage = action.error.message;
    });
  },
});
