import { FC } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import './googleSignInButton.scss';

import { googleLogin } from 'store/user/UserActions';
import { translations } from 'translations/en';

const GoogleSignInButton: FC = () => {
  const dispatch = useDispatch();

  const onSuccess = (response: any) => {
    const { tokenId: token } = response;

    const { email, name, imageUrl } = response.profileObj;
    dispatch(
      googleLogin({
        token,
        imageUrl,
        name,
        email,
      }),
    );
  };

  const googleLoginError = (error: any) => {
    console.log('google login error', error);
  };

  return (
    <div className="google-sign-in-container">
      <GoogleLogin
        clientId="247595500749-a3hgqcsatesihliholqlcil8dnvicckm.apps.googleusercontent.com"
        buttonText={translations.SIGN.CONTINUE_WITH_GOOGLE}
        className="google-sign-in"
        onFailure={googleLoginError}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default GoogleSignInButton;
