import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { DiscoverLayoutComponents, IDiscoverState } from 'types';
import { getDiscoverPageData, getShopOfTheDay } from './DiscoverActions';
import {
  parseDiscoverCategories,
  parseDiscoverStaticItems,
  parseDiscoverPageComponent,
  parseShopOfTheDay,
} from '../apiParser';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { updateProductLikes } from '../specificProduct/helper';
import { onFollowUserToggle } from '../profile/ProfileActions';

const initialState: IDiscoverState = {
  shopOfTheDay: null,
  categories: {
    type: DiscoverLayoutComponents.Categories,
    list: [],
    marginTop: 0,
    order: 0,
  },
  items: null,
  isLoading: false,
};

export const DiscoverSlicer = createSlice({
  name: 'discover',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDiscoverPageData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDiscoverPageData.fulfilled, (state, action) => {
      state.isLoading = false;

      const {
        categories,
        staticItems,
        equalGridsProducts,
        horizontalListsProducts,
        layout1,
        layout2,
        layout3,
      } = action.payload;

      const { sectionDetails } = categories[0];

      const categoriesList = parseDiscoverCategories(categories[0]);

      state.categories = {
        type: DiscoverLayoutComponents.Categories,
        list: categoriesList,
        marginTop: sectionDetails.topSpacing,
        order: sectionDetails.order,
      };

      const staticItemsList = parseDiscoverStaticItems(staticItems);

      const productsGrids = parseDiscoverPageComponent(
        equalGridsProducts,
        DiscoverLayoutComponents.ProductsEqualGrid,
      );

      const productsCarousels = parseDiscoverPageComponent(
        horizontalListsProducts,
        DiscoverLayoutComponents.ProductsCarousel,
      );

      const mixedLayouts = [
        ...parseDiscoverPageComponent(layout1, DiscoverLayoutComponents.MixedLayout1),
        ...parseDiscoverPageComponent(layout2, DiscoverLayoutComponents.MixedLayout2),
        ...parseDiscoverPageComponent(layout3, DiscoverLayoutComponents.MixedLayout3),
      ];

      state.items = {
        staticItems: staticItemsList,
        productsCarousels,
        grids: productsGrids,
        mixedLayouts,
        shopsCarousels: [],
      };
    });
    builder.addCase(getShopOfTheDay.fulfilled, (state, action) => {
      const data = action.payload[0];

      if (data) {
        state.shopOfTheDay = parseShopOfTheDay(data);
      }
    });
    builder.addCase(onProductLikeToggle.fulfilled, (state, action) => {
      const { productId } = action.meta.arg;

      if (state.items) {
        const modularItems = [
          ...state.items.grids,
          ...state.items.productsCarousels,
          ...state.items.mixedLayouts,
        ];

        modularItems.forEach((item) => {
          item.products.forEach((product) => {
            if (product.id === productId) {
              updateProductLikes(product);
            }
          });
          if (item.gridDetails) {
            const gridProducts = [
              ...item.gridDetails.topLeft.products,
              ...item.gridDetails.bottomLeft.products,
              ...item.gridDetails.right.products,
            ];
            gridProducts.forEach((product) => {
              if (product.id === productId) {
                updateProductLikes(product);
              }
            });
          }
        });
      }
    });
    builder.addMatcher(
      isAnyOf(onFollowUserToggle.pending, onFollowUserToggle.rejected),
      (state, action) => {
        const { userId } = action.meta.arg;

        if (state.shopOfTheDay && state.shopOfTheDay.userId === userId) {
          state.shopOfTheDay.isFollowing = !state.shopOfTheDay.isFollowing;
        }
      },
    );
  },
});
