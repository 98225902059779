import {
  IMemberOption,
  popupIdsOptions,
  ServerStatus,
  IChatMedia,
  IChatMediaFiles,
  IChatMediaLinks,
} from 'types';

export interface IChatInformation {
  name: string;
  description: string;
  groupDescription: string;
  isGroup: boolean;
  avatar: string | null;
  isOnline: boolean;
  isMuted: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isBlocked: boolean;
  isSeller: boolean;
  id: string;
  shopId: string | null;
  onAddGroupMembers(): void;
  onMediaClick(): void;
  onPopupShow(options: { id: popupIdsOptions; userId?: string }): void;
  onClose(): void;
  onUnblockUser(): void;
  onSearchClick(): void;
  onUnmute(): void;
  onViewMembersClick(): void;
  onEdit(): void;
}

export enum ChatInformationAction {
  ViewProfile,
  ViewShop,
  Mute,
  UnMute,
  Block,
  UnBlock,
  Report,
  ViewMediaAndLinks,
  ViewMedia,
  Search,
  InviteMember,
  SeeAllMembers,
  InviteLink,
  Leave,
}

export interface IChatInformationRow {
  isDanger: boolean;
  text: string;
  leftIcon: any;
  type: ChatInformationAction;
}

export interface IChatInformationSection {
  title: string;
  options: IChatInformationRow[];
  onClick(option: ChatInformationAction): void;
}

export interface IChatInformationRowProps {
  isDanger: boolean;
  text: string;
  leftIcon: any;
  onClick(): void;
}

export interface IInformationSections {
  sections: { title: string; options: ChatInformationAction[] }[];
  onClick(type: ChatInformationAction): void;
}

export interface IChatInformationSidebar {
  visibleSidebar: ChatInformationSidebarOptions | null;
  chatId: string;
  serverStatus: ServerStatus | null;
  onClose(): void;
  onVisibleSidebarChange(option: ChatInformationSidebarOptions): void;
  setGroupDetails(): void;
  onMembersReset(): void;
  onAddMembersSubmit(options: { groupId: string }): void;
  onGroupEdit(): void;
  onEditGroupReset(): void;
}

export enum ChatInformationSidebarOptions {
  Members = 'members',
  AddMembers = 'add-members',
  Information = 'information',
  GroupDetails = 'group-details',
  Media = 'media',
}

export enum MemberAction {
  Remove = 'remove',
  MakeAdmin = 'MakeAdmin',
  RemoveAdmin = 'RemoveAdmin',
  Follow = 'follow',
  Message = 'message',
}

export interface IMemberRow {
  avatar: string;
  userId: string;
  name: string;
  isAdmin: boolean;
  isLoggedInUser: boolean;
  isFollowing: boolean;
  username: string;
  isAdminActionsEnable: boolean;
  onActionClick(action: MemberAction): void;
}

export interface IMembers {
  totalCount: number;
  users: IMemberOption[];
  search: string;
  isAdmin: boolean;
  loggedInUserId: string;
  isLoading: boolean;
  isVisible: boolean;

  onSearch(options: { search: string }): void;

  onAddMemberClick(): void;
  onAdminToggle(options: { userId: string; shouldMakeAdmin: boolean }): void;

  onBack(): void;

  onLoad(): void;

  onUserRemove(options: { userId: string }): void;

  onUserMessage(options: {
    id: string;
    isGroup: boolean;
    avatar: string;
    name: string;
    username: string;
  }): void;

  onUserFollow(options: { userId: string; isFollowing: boolean }): void;
}

export enum MediaTabs {
  Media = 'Media',
  Files = 'Files',
  Links = 'Links',
}

export interface IMediaWrapper {
  media: IChatMedia[] | null;
  files: IChatMediaFiles[] | null;
  links: IChatMediaLinks[] | null;
  hasMoreMedia: boolean;
  hasMoreFiles: boolean;
  isGroup: boolean;
  hasMoreLinks: boolean;
  isMediaLoading: boolean;
  isFilesLoading: boolean;
  isLinksLoading: boolean;
  isVisible: boolean;
  loggedInUserId: string;
  loggedInUsername: string;
  loadChatImages(options: { loggedInUserId: string; loggedInUsername: string }): void;
  loadChatFiles(): void;
  loadChatLinks(): void;
  onBack(): void;
  showPopup(input: { id: string; mediaItem?: IChatMedia; messageId?: string }): void;
}

export interface ILinkList {
  links: IChatMediaLinks[];
  hasMore: boolean;
  isLoading: boolean;
  loadMoreLinks(): void;
}

export interface IFileList {
  files: IChatMediaFiles[];
  hasMore: boolean;
  isLoading: boolean;
  loadMoreFiles(): void;
  showPopup(input: { id: string; mediaItem?: IChatMedia; messageId?: string }): void;
}

export interface IMediaList {
  media: IChatMedia[];
  hasMore: boolean;
  isLoading: boolean;
  loadMoreImages(): void;
  onImageClick(mediaItem: IChatMedia): void;
}
