import { ImageComposition, ImageOrientation } from 'types';

export const mapCompositionToClassName = {
  [ImageComposition.zero]: 'no-images',
  [ImageComposition.one]: 'one-image',
  [ImageComposition.twoSquare]: 'two-square',
  [ImageComposition.twoVertical]: 'two-vertical',
  [ImageComposition.twoHorizontal]: 'two-horizontal',
  [ImageComposition.threeVertical]: 'three-vertical',
  [ImageComposition.threeHorizontal]: 'three-horizontal',
  [ImageComposition.fourSquares]: 'four-squares',
  [ImageComposition.fourVertical]: 'four-vertical',
  [ImageComposition.fourHorizontal]: 'four-horizontal',
  [ImageComposition.fiveSquares]: 'five-squares',
  [ImageComposition.fiveHorizontal]: 'five-horizontal',
};

export const getImageOrientation = (size: { height: number; width: number }) => {
  const { height, width } = size;

  const ratio = width / height;

  if (ratio > 1.3) {
    return ImageOrientation.Horizontal;
  }
  if (ratio < 0.6) {
    return ImageOrientation.Vertical;
  }

  return ImageOrientation.Square;
};

export const calculateCompositions = (sizes: { height: number; width: number }[]) => {
  switch (sizes.length) {
    case 0:
      return ImageComposition.zero;

    case 1:
      return ImageComposition.one;

    case 2:
      const types = sizes.map((size) => getImageOrientation(size));

      if (types[0] !== types[1] || types[0] === ImageOrientation.Square) {
        return ImageComposition.twoSquare;
      }
      if (types[0] === ImageOrientation.Vertical) {
        return ImageComposition.twoVertical;
      }

      return ImageComposition.twoHorizontal;

    case 3:
      switch (getImageOrientation(sizes[0])) {
        case ImageOrientation.Vertical:
        case ImageOrientation.Square:
          return ImageComposition.threeVertical;
        default:
          return ImageComposition.threeHorizontal;
      }

    case 4:
      switch (getImageOrientation(sizes[0])) {
        case ImageOrientation.Vertical:
          return ImageComposition.fourVertical;
        case ImageOrientation.Horizontal:
          return ImageComposition.fourHorizontal;
        default:
          return ImageComposition.fourSquares;
      }
    default:
      // Checks if all images in range are squares, if not, breaks the for loop.
      for (let i = 2; i < 4; i++) {
        const currentImageOrientation = getImageOrientation(sizes[i]);

        switch (currentImageOrientation) {
          case ImageOrientation.Vertical:
            return ImageComposition.fiveSquares;
          case ImageOrientation.Horizontal:
            return ImageComposition.fiveHorizontal;
          default:
            break;
        }
      }

      return ImageComposition.fiveSquares;
  }
};

export const calculateRatio = (
  sizes: { height: number; width: number }[],
  composition: ImageComposition,
  containerWidth: number,
) => {
  switch (composition) {
    case ImageComposition.zero:
    case ImageComposition.twoHorizontal:
    case ImageComposition.threeVertical:
    case ImageComposition.fourSquares:
    case ImageComposition.fourVertical:
    case ImageComposition.fiveHorizontal:
      return 1;
    case ImageComposition.one:
      return sizes[0].width / sizes[0].height;
    case ImageComposition.threeHorizontal:
    case ImageComposition.fourHorizontal:
    case ImageComposition.fiveSquares:
      return containerWidth / (sizes[0].height + sizes[1].height);
    default:
      return containerWidth / sizes[0].height;
  }
};
