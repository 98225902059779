import { Middleware } from 'redux';

import { requestJoinGroup } from './SearchResultActions';
import { MessageSlicer } from '../message/MessageActions';
import { MessageIds } from 'types';

export const searchResultMiddleware: Middleware =
  ({ dispatch }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case requestJoinGroup.rejected.type:
      case requestJoinGroup.fulfilled.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.joinGroupRequest }));
        break;

      default:
        break;
    }

    return next(action);
  };
