import { FC, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { LoaderIndicator, Tag } from 'tedooo-web-design-system/ui';
import { useTextAreaRef } from 'tedooo-web-design-system/hooks';
import { ShoppingBagIcon } from 'tedooo-web-design-system/icons';

import Linkify from 'components/linkify/Linkify';
import ActionHeader from './header/actionHeader/ActionHeader';
import {
  EventSources,
  IPostProps,
  IStore2,
  MenuOptions,
  NonSignInPopupSources,
  popupIdsOptions,
  ProductPageSources,
} from 'types';
import UserDetails from './header/userDetails/UserDetails';
import ExpandableText from 'components/expandableText/ExpandableText';
import { isEmptyArray } from 'utils';
import Footer from './footer/Footer';
import Comments from './comments/Comments';
import './post.scss';
import { Image } from 'components/helpers';
import { UserSelector } from 'store/user/UserSelector';
import { ROUTES } from 'constant';
import { PopupSlicer } from 'store/popup/PopupActions';
import { useRequireToken } from 'hooks/useRequireToken';
import ImagesGrid from 'components/ImageGrid/ImagesGrid';
import ProductContainer from 'pages/feed/createPost/createPostPopup/postDetails/productContainer/ProductContainer';
import { CommentInput } from '../commentInput';
import { translations } from 'translations/en';
import { FeedSlicer } from 'store/feed/FeedSlicer';
import useOnScreen from 'hooks/useOnScreen';
import { useSkipFirstEffect } from 'hooks/useSkipFirstEffect';
import { parsePostToEditPost } from './helper';
import { mapTextBackgroundToImage } from 'pages/feed/createPost/createPostPopup/helper';
import Repost from './repost/Repost';
import { PopupsSrv } from 'services/PopupsSrv';
import Mojo from '../mojo/Mojo';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { SpecificProductSlicer } from 'store/specificProduct/SpecificProductSlicer';

const LAST_POST_FOR_NON_SIGN_IN_USER = 4;

const Post: FC<IPostProps> = ({
  source,
  post,
  type,
  textMaxChars,
  loggedInAvatar,
  loggedInUserId,
  onFollowToggle,
  onCommentsLoad,
  onReply,
  onDeletePost,
  onLikeClick,
  onRepliesLoad,
  onCommentSubmit,
  isClickable,
  onImageClick,
  onLikesClick,
  onCommentDelete,
  shouldDisplayImages,
  shouldDisplayComments,
  shouldHidePremium,
  shouldHideFollowButton,
  showPopup,
  updateProductPageSource,
  isSpecificPost,
  index,
  isPostInPopup,
  onPostReport,
  onPostHide,
  onPostHideFromActivity,
  onRepostsClick,
  onShowRepostsClick,
  onLikeComment,
  onCommentLikesClick,
  onToggleRepliesClick,
  onPostImpression,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const inputRef = useTextAreaRef();

  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useOnScreen(ref);

  useSkipFirstEffect(() => {
    if (location.pathname === '/' && isVisible) {
      if (post.mojoDetails) {
        AnalyticsEvents.onMojoImpression(post.id);
      } else {
        onPostImpression({ id: post.id });
      }
    }
  }, [isVisible]);

  const {
    actionId,
    action,
    isFollowing,
    text,
    images,
    imageComposition,
    isLiked,
    isReposted,
    totalLikes,
    totalComments,
    totalReposts,
    shouldHideDate,
    comments,
    userDetails,
    shopId,
    productDetails,
    lastComment,
    backgroundIndex,
    isEditable,
    lastEdited,
    repostDetails,
    taggedUsers,
    mojoDetails,
    shouldDisplayHideFromActivity,
    isShoppingPost,
  } = post;

  const onOptionMenuClick = useRequireToken((option: MenuOptions) => {
    if (option === MenuOptions.Hide) {
      onPostHide({ id: actionId });
    } else if (option === MenuOptions.HideFromActivity) {
      onPostHideFromActivity({ id: actionId });
    } else if (option === MenuOptions.Report) {
      onPostReport();
    } else if (option === MenuOptions.Delete) {
      onDeletePost();
    } else if (option === MenuOptions.Share) {
      onShareClick();
    } else if (option === MenuOptions.Edit) {
      const createPostInitialValues = parsePostToEditPost(post);
      showPopup({ createPostInitialValues, id: popupIdsOptions.createPostPopup });
    }
  });

  const mentions = taggedUsers.map((user) => ({
    username: user.username,
    link: `/${ROUTES.USER}/${user.userId}`,
  }));

  const isPostOwner = userDetails.userId === loggedInUserId;

  const isImagesVisible = shouldDisplayImages && images && !isEmptyArray(images);

  let commentsToDisplayInPost = comments.list || [];
  if (!isSpecificPost && lastComment) {
    commentsToDisplayInPost = [lastComment];
  }

  const onUserClick = (id: string) => {
    navigate(`/${ROUTES.USER}/${id}`);
  };

  const onRepliesClick = useRequireToken((commentId: string) => {
    onRepliesLoad(commentId);
  });

  const onPostClick = () => {
    if (isClickable) {
      if (mojoDetails && source) {
        AnalyticsEvents.onEnterMojo(post.id, source);
        showPopup({ id: popupIdsOptions.mojosPopup, mojoId: actionId, source });
      } else {
        navigate(`/${ROUTES.POST}/${post.actionId}`);
        AnalyticsEvents.onOpenSignupPopup(NonSignInPopupSources.Comment);
      }
    }
  };

  const onCommentActionClick = useRequireToken(() => {
    inputRef.current?.focus();
  });

  const onMoreClick = () => onPostImagesClick(0);

  const onPostImagesClick = (i: number) => {
    onImageClick(i);
  };

  const onFollowClick = useRequireToken(() => onFollowToggle());

  const shouldShowImageGrid =
    !productDetails && isImagesVisible && !isEmptyArray(images) && backgroundIndex === undefined;

  const onViewItemClick = () => {
    if (mojoDetails && source) {
      AnalyticsEvents.onMojoProductClick(post.id, '', source);
    }
    if (productDetails) {
      updateProductPageSource({ source: ProductPageSources.Feed });
      navigate(`/${ROUTES.PRODUCT}/${productDetails.id}`);
    }
  };

  const onImageGridClick = (e: any) => {
    e.stopPropagation();
  };

  const onRepostActionClick = useRequireToken(() => {
    onRepostsClick();
  }, NonSignInPopupSources.Repost);

  const onVideoEnd = (id: string, duration: number, percentage: number) => {
    if (source) {
      const originType = source !== EventSources.MojosTab ? 'specific' : undefined;
      AnalyticsEvents.onMojoEnd(id, source, duration, percentage, '', originType);
    }
  };

  const onShareClick = useRequireToken(() => {
    const { username, fullName, isPremium, avatar } = post.userDetails;

    const postImage = post.images ? post.images[0] : null;

    const postDetails = {
      name: fullName,
      isPremium,
      username,
      avatar: avatar || '',
      image: postImage,
      text: post.text,
      link: '',
      price: productDetails?.price || null,
    };
    if (mojoDetails) {
      showPopup({ id: popupIdsOptions.shareMojoPopup, actionId: post.id, postDetails });
    } else {
      showPopup({ id: popupIdsOptions.sharePostPopup, actionId: post.id, postDetails });
    }
  }, NonSignInPopupSources.SharePost);

  const shouldShowProductContainer = productDetails && !mojoDetails;

  const shouldDisplayNonSignInPopup = !loggedInUserId && index === LAST_POST_FOR_NON_SIGN_IN_USER;

  return (
    <div
      className={classNames('post-container', {
        clickable: isClickable && !shouldDisplayNonSignInPopup,
        'specific-post': isSpecificPost,
        hasShadow: shouldDisplayNonSignInPopup,
      })}
      onClick={onPostClick}
      ref={ref}
    >
      {action && (
        <ActionHeader
          isPostOwner={isPostOwner}
          username={action.username}
          totalUsers={action.totalUsers}
          type={action.type}
        />
      )}
      {isShoppingPost && (
        <div className="tag-container">
          <Tag color="blue" size="medium" text="Shopping post" icon={<ShoppingBagIcon />} />
        </div>
      )}
      <UserDetails
        shouldHideFollowButton={shouldHideFollowButton}
        shopImage={userDetails.shopImage}
        onUserClick={onUserClick}
        shopId={shopId}
        shouldHideDate={shouldHideDate}
        fullName={userDetails.fullName}
        isPostOwner={isPostOwner}
        userId={userDetails.userId}
        onFollowToggle={onFollowClick}
        shouldDisplayOptionMenu
        isFollowing={isFollowing}
        type={type}
        onOptionMenuClick={onOptionMenuClick}
        avatar={userDetails.avatar}
        username={userDetails.username}
        shopName={userDetails.shopName}
        date={userDetails.date}
        isPremium={userDetails.isPremium && !shouldHidePremium}
        isEditablePost={isEditable}
        shouldDisplayHideFromActivity={shouldDisplayHideFromActivity}
        isPostEdited={!!lastEdited}
      />
      <div
        className={classNames('post-details-inner-container', {
          'no-images': !images && !repostDetails,
        })}
      >
        {backgroundIndex === undefined && text && (
          <ExpandableText
            text={text}
            maxChars={textMaxChars}
            isCollapsable={false}
            isTextClickable={false}
            mentions={mentions}
          />
        )}
        {repostDetails && (
          <div className="repost-wrapper">
            <Repost repostDetails={repostDetails} />
          </div>
        )}
        {backgroundIndex !== undefined && (
          <div className="background-index-container" onClick={onImageGridClick}>
            <Image src={mapTextBackgroundToImage[backgroundIndex]} />
            <Linkify text={text} className="text" mentions={mentions} />
          </div>
        )}
        <div className="post-image-container" onClick={onImageGridClick}>
          {shouldShowImageGrid && (
            <ImagesGrid
              withOverlay={false}
              imageComposition={imageComposition}
              images={images}
              onMoreClick={onMoreClick}
              onClick={onPostImagesClick}
            />
          )}
          {shouldShowProductContainer && (
            <ProductContainer
              image={productDetails.image}
              currencyCode={productDetails.price.currencyCode}
              price={productDetails.price.value}
              title={productDetails.title}
              originalPrice={productDetails.price.value}
              showBuyButton
              onClick={onViewItemClick}
            />
          )}
          {mojoDetails && source && (
            <Mojo
              isDisplayed={isVisible}
              canPause={false}
              video={mojoDetails.videoUrl}
              thumbnail={mojoDetails.thumbnailUrl}
              productDetails={post.productDetails}
              onLikeToggle={onLikeClick}
              isLiked={isLiked}
              totalLikes={totalLikes}
              totalComments={totalComments}
              comments={{ list: [], paginationToken: '' }}
              id={post.id}
              userDetails={null}
              text={null}
              onShareClick={onShareClick}
              onProductClick={onViewItemClick}
              onFollow={onFollowClick}
              onUserClick={onUserClick}
              onClick={onPostClick}
              onVideoEnd={onVideoEnd}
              onLoadComments={() => {}}
              onLoadReplies={() => {}}
              onCommentDelete={() => {}}
              onCommentSubmit={() => {}}
              onReplySubmit={() => {}}
              onCommentLikeToggle={() => {}}
              onToggleReplies={() => {}}
              onLikesClick={() => {}}
            />
          )}
        </div>
        <Footer
          shouldDisplayInputByDefault
          isLiked={isLiked}
          isMojo={!!mojoDetails}
          productDetails={productDetails}
          isReposted={isReposted}
          onCommentsClick={onPostClick}
          onRepostClick={onShowRepostsClick}
          totalComments={totalComments}
          onLikesClick={onLikesClick}
          onLike={onLikeClick}
          avatar={loggedInAvatar}
          totalLikes={totalLikes}
          totalReposts={totalReposts}
          onCommentSubmit={onCommentSubmit}
          onShare={onShareClick}
          onCommentActionClick={onCommentActionClick}
          onRepostActionClick={onRepostActionClick}
        />
        {!isPostInPopup && (
          <Comments
            postOwnerId={userDetails.userId}
            onViewMoreCommentsClick={onPostClick}
            onUserClick={onUserClick}
            onCommentDelete={onCommentDelete}
            isExpanded={shouldDisplayComments}
            onReply={onReply}
            onRepliesLoad={onRepliesClick}
            hasMore={comments.hasMore}
            onLoadMore={onCommentsLoad}
            comments={commentsToDisplayInPost}
            totalComments={totalComments}
            shouldDisplayEmptyState={false}
            onCommentLike={onLikeComment}
            onCommentLikesClick={onCommentLikesClick}
            onToggleRepliesClick={onToggleRepliesClick}
          />
        )}
        <div className="input-wrapper">
          <CommentInput
            ref={inputRef}
            isUserSingIn={!!loggedInUserId}
            maxImages={1}
            avatar={loggedInAvatar}
            avatarSize={40}
            placeholder={translations.FEED.POST.WRITE_COMMENT}
            onSubmit={onCommentSubmit}
          />
        </div>
        {isPostInPopup && (
          <Comments
            postOwnerId={userDetails.userId}
            onViewMoreCommentsClick={onPostClick}
            onUserClick={onUserClick}
            onCommentDelete={onCommentDelete}
            isExpanded={shouldDisplayComments}
            onReply={onReply}
            onRepliesLoad={onRepliesClick}
            hasMore={comments.hasMore}
            onLoadMore={onCommentsLoad}
            comments={comments.list || []}
            totalComments={totalComments}
            onCommentLike={onLikeComment}
            onCommentLikesClick={onCommentLikesClick}
            shouldDisplayEmptyState
            onToggleRepliesClick={onToggleRepliesClick}
          />
        )}

        {!commentsToDisplayInPost && <LoaderIndicator size={30} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const loggedInAvatar = UserSelector.defaultAvatarSelector(state);

  const { ids } = state.popup;

  const isPostInPopup =
    PopupsSrv.checkIfDisplayed(popupIdsOptions.feedImagesPopup, ids) ||
    PopupsSrv.checkIfDisplayed(popupIdsOptions.postImagesPopup, ids);

  const loggedInUserId = UserSelector.loggedUserIdSelector(state);

  return { loggedInAvatar, loggedInUserId, isPostInPopup };
};

const mapDispatchToProps = {
  showPopup: PopupSlicer.actions.show,
  onPopupClose: PopupSlicer.actions.hide,
  onPostImpression: FeedSlicer.actions.onPostImpression,
  updateProductPageSource: SpecificProductSlicer.actions.updateSource,
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
