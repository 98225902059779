import { FC, useState } from 'react';
import { MoreHorizontal } from 'tedooo-web-design-system/icons';

import TedoooLogo from 'photos/tedooologosquare.png';
import {
  IUserDetailsProps,
  mapUserDetailsLocationToAvatarSize,
  mapUserDetailsLocationToPremiumTagSize,
  MenuOptions,
  OptionsMenuList,
} from 'types';
import { Avatar } from 'components/avatar/Avatar';
import { Image, Span } from 'components/helpers';
import './userDetails.scss';
import { getShortRelativeTime } from 'utils';
import FollowButton from 'components/post/followButton/FollowButton';
import PremiumTag from 'components/premiumTag/PremiumTag';
import DropdownOptionsMenu from 'components/dropdownOptionsMenu/DropdownOptionsMenu';
import { translations } from 'translations/en';

const UserDetails: FC<IUserDetailsProps> = ({
  avatar,
  username,
  isPremium,
  fullName,
  userId,
  shopName,
  date,
  shouldDisplayOptionMenu,
  isFollowing,
  onOptionMenuClick,
  type,
  onFollowToggle,
  isPostOwner,
  shopId,
  shopImage,
  shouldHideFollowButton,
  shouldDisplayHideFromActivity,
  onUserClick,
  isEditablePost,
  isPostEdited,
  shouldHideDate,
}) => {
  const formattedDate = date && !shouldHideDate ? ` · ${getShortRelativeTime(date)}` : '';

  const [shouldDisplayFollowButton] = useState(
    !isFollowing && !isPostOwner && !shouldHideFollowButton,
  );

  let avatarSize = mapUserDetailsLocationToAvatarSize[type];
  if (shopId) {
    avatarSize = 26;
  }

  const premiumTagSize = mapUserDetailsLocationToPremiumTagSize[type];

  const onTitleClick = (e: any) => {
    e.stopPropagation();
    onUserClick(userId);
  };

  const onFollowClick = (e: any) => {
    e.stopPropagation();
    onFollowToggle();
  };

  const onProfileImagesClick = (e: any) => {
    e.stopPropagation();
  };

  const onDropdownClick = (e: any) => {
    e.stopPropagation();
  };

  const title = shopId ? shopName : fullName;

  const subText = shopId ? fullName : `@${username}`;

  const dropdownOptions = OptionsMenuList.filter(
    (option) =>
      (option.key !== MenuOptions.Edit || (isEditablePost && isPostOwner)) &&
      (option.key !== MenuOptions.Hide || !isPostOwner) &&
      (option.key !== MenuOptions.Delete || isPostOwner) &&
      (option.key !== MenuOptions.HideFromActivity || shouldDisplayHideFromActivity) &&
      (option.key !== MenuOptions.Report || !isPostOwner),
  );

  return (
    <div className="user-details-container">
      {!!shopId && (
        <div className="images-container" onClick={onProfileImagesClick}>
          <Image src={shopImage || TedoooLogo} className="shop-image" />
          <Avatar src={avatar} size={avatarSize} />
        </div>
      )}
      {!shopId && <Avatar src={avatar} size={44} />}
      <div className="text-container">
        <div className="text-header">
          <div className="title-container">
            <Span className="title-text" text={title} onClick={onTitleClick} />
            {shouldDisplayFollowButton && (
              <>
                <div className="dot" />
                <FollowButton isFollowing={isFollowing} onFollowToggle={onFollowClick} />
              </>
            )}
          </div>
          {isPremium && <PremiumTag size={premiumTagSize} />}
        </div>
        <div className="sub-text">
          <span className="text" />
          <Span className="text" text={`${subText}${formattedDate}`} />
          {isPostEdited && <span className="text">{translations.FEED.POST.EDITED}</span>}
        </div>
      </div>
      <div className="user-details-button" onClick={onDropdownClick}>
        {shouldDisplayOptionMenu && (
          <DropdownOptionsMenu onOptionClick={onOptionMenuClick} options={dropdownOptions}>
            <MoreHorizontal className="dropdown-button" />
          </DropdownOptionsMenu>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
