import { FC, ReactNode } from 'react';
import MediaQuery from 'react-responsive';

export const SmallScreen: FC<{ children: ReactNode; maxWidth?: number }> = ({
  children,
  maxWidth,
}) => {
  return <MediaQuery maxWidth={maxWidth || 900}>{children}</MediaQuery>;
};

export const LargeScreen: FC<{ children: ReactNode }> = ({ children }) => {
  return <MediaQuery minWidth={901}>{children}</MediaQuery>;
};
