import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2 } from 'types';
import { SpecificVisionRequestApi } from './SpecificVisionRequestApi';

const api = new SpecificVisionRequestApi();

export const getOffersForVisionRequest = createAsyncThunk(
  'async/getOffersForVisionRequest',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    const { paginationToken, sortType } = state.specificVisionRequest;

    return api.getOffersForRequest(token, id, paginationToken, sortType);
  },
);

export const getVisionRequest = createAsyncThunk(
  'async/getVisionRequest',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.getVisionRequest(token, id);
  },
);

export const declineVisionOffer = createAsyncThunk(
  'async/declineVisionOffer',
  async (input: { offerId: string; reason: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { offerId, reason } = input;

    const { token = '' } = state.user;

    return api.declineOffer(token, offerId, reason);
  },
);

export const withdrawVisionOffer = createAsyncThunk(
  'async/withdrawVisionOffer',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.withdrawOffer(token, id);
  },
);

export const deleteVisionRequest = createAsyncThunk(
  'async/deleteVisionRequest',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.deleteRequest(token, id);
  },
);

export const hideVisionRequest = createAsyncThunk(
  'async/hideVisionRequest',
  async (input: { requestId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { requestId } = input;

    return api.hideVisionRequest(token, requestId);
  },
);

export const getSellerPaymentDetails = createAsyncThunk(
  'async/getSellerPaymentDetails',
  async (input: { offerId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { offerId } = input;

    const { token = '' } = state.user;

    return api.getSellerPaymentDetails(token, offerId);
  },
);

export const getVisionOffer = createAsyncThunk(
  'async/getVisionOffer',
  async (input: { offerId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { offerId } = input;

    const { token = '' } = state.user;

    return api.getVisionOffer(token, offerId);
  },
);
