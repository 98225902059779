import {
  IOrderDetails,
  IOrderItem,
  IUserOrderStatistics,
  LoadMoreTypes,
  IOrderPartnerDetails,
  ICourier,
  MessageIds,
  IPrice,
  IPaymentSessionDetails,
  IPayoutOptions,
  PayoutMethods,
  ISelectedShippingService,
  IShippingLabelDetails,
  IShippingRate,
  IRecipientAddress,
  IShippingDisabledRate,
  IOriginAddress,
  IProductDimensions,
  IShippingLabelDownloadUrls,
  IShippingAddress,
  IAddressPreview,
  ICreateShippingLabelForm,
  IOrderDigitalFile,
  ILinkedPaymentOption,
  IBasketShippingRate,
  ICheckoutItem,
  PaymentSource,
  BasketRateAttributes,
  IShopUserDetails,
  IDiscountDetails,
} from 'types';
import excellentSmile from 'lotties/smiles/smile5.json';
import horribleSmile from 'lotties/smiles/smile1.json';
import badSmile from 'lotties/smiles/smile2.json';
import goodSmile from 'lotties/smiles/smile3.json';
import greatSmile from 'lotties/smiles/smile4.json';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';

export * from './ordersOverview/types';

export * from './orderBox/types';

export interface IOrdersPage {
  orders: IOrdersLists | null;
  sellerOrderStatistics: IUserOrderStatistics | null;
  buyerOrderStatistics: IUserOrderStatistics | null;
  shopId: string | null;
  fullName: string;
  isSellerMode: boolean;
  showPopup(input: { id: string; orderId: string }): void;
  toggleSellerMode(): void;
  getSellerOrdersList(): void;
  getBuyerOrdersList(): void;
  showPopup(input: { id: string }): void;
  loadMoreOrders(input: { mode: LoadMoreTypes }): void;
  initUserMode(input: { shopId: string | null }): void;
}

export interface IOrdersLists {
  allOrders: { list: IOrderDetails[]; totalCount: number };
  todoOrders: { list: IOrderDetails[]; totalCount: number };
  awaitingOrders: { list: IOrderDetails[]; totalCount: number };
  shippedOrders: { list: IOrderDetails[]; totalCount: number };
  completedOrders: { list: IOrderDetails[]; totalCount: number };
  declinedOrders: { list: IOrderDetails[]; totalCount: number };
}

export interface IOrderTag {
  isSeller: boolean;
  status: OrderStatuses;
}

export enum OrderStatuses {
  Requested = 'requested',
  Offered = 'offered',
  PaymentSent = 'accepted',
  PaymentReceived = 'payment_received',
  Decline = 'cancelled',
  Shipped = 'shipped',
  ShippingApiUpdate = 'shippedUpdate',
  Complete = 'delivered',
}

export enum TagColors {
  Purple = 'purple',
  Blue = 'blue',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
}

export enum OrderActions {
  Withdraw,
  AddShippingCost,
  DeclineOrder,
  PayNow,
  HavingIssueWithPayment,
  PaymentReceived,
  AcceptOrder,
  OrderShipped,
  EditTrackingNumber,
  TrackOrder,
  OrderIsDelivered,
  WriteReview,
  ViewDigitalFiles,
  SeeTrackingHistory,
  SeeWhyOrderIsDeclined,
  CreateShippingLabel,
  PurchasedLabelPopup,
}

export enum ButtonTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Danger = 'danger',
  DangerSecondary = 'danger-secondary',
}

export interface IWaitForSellerPopup {
  hidePopup(): void;
}

export interface ITrackingActivity {
  id: string;
  date: number;
  text: string;
}

export interface ITrackOrderPopup {
  trackingNumber: string;
  shippingProviderImage: string | null;
  activities: ITrackingActivity[];
  orderId: string;
  lastUpdatedDate: number | null;
  isSeller: boolean;
  getTrackingInformation(input: { id: string }): void;
  onCopy(input: { id: MessageIds }): void;
  hidePopup(): void;
}

export interface ISendFinalPricePopup {
  selectedItems: IOrderItem[];
  selectedShippingService: ISelectedShippingService | null;
  partnerDetails: IOrderPartnerDetails;
  recipientAddress: IRecipientAddress | null;
  id: string;
  avatar: string;
  username: string;
  isSpecificOrderPage: boolean;
  discountDetails: IDiscountDetails | null;
  onSendFinalPrice(input: {
    id: string;
    shippingPrice: number;
    items: IOrderItem[];
    avatar: string;
    username: string;
  }): void;
  hidePopup(): void;
}

export interface IOrderArrivedPopup {
  checkTrackingNumber(input: { trackingNumber: string }): void;
  hidePopup(): void;
}

export interface INewOrderPopup {
  orderId: string;
  hidePopup(): void;
}

export interface IAddTrackingNumberPopup {
  orderId: string;
  trackingNumberMessage: string | null;
  trackingNumber: string | null;
  carrierOptions: ICourier[];
  username: string;
  avatar: string;
  saveTrackingNumber(input: {
    id: string;
    trackingNumber: string;
    courierCode: string;
    avatar: string;
    username: string;
  }): void;
  checkTrackingNumber(input: { trackingNumber: string }): void;
  hidePopup(): void;
}

export interface IPaymentIssuePopup {
  partnerDetailsId: string;
  hidePopup(): void;
}

export interface IContactTheSellerPopup {
  partnerDetails: IOrderPartnerDetails;
  hidePopup(): void;
}

export interface IOnTheWayPopup {
  shippedOrders: { list: IOrderDetails[]; totalCount: number };
  showPopup(input: { id: string; orderId: string }): void;
  hidePopup: () => void;
}

export interface IDigitalFilesPopup {
  orderId: string;
  defaultEmail: string;
  digitalFiles: IOrderDigitalFile[] | null;
  sendEmail(input: { orderId: string; email: string }): void;
  getDigitalFiles(input: { orderId: string }): void;
  hide(): void;
}

export interface IOrderReviewPopup {
  id: string;
  shopName: string;
  shopId: string;
  images: string[];
  reviewPopupStep: ReviewPopupSteps;
  hidePopup(): void;
  onFinishReview(input: {
    orderId: string;
    shopId: string;
    title: string;
    description: string;
    stars: number;
    images: Array<any>;
  }): void;
}

export interface IRateShopStep {
  images: string[];
  shopName: string;
  totalStars: StarsRateState;
  setTotalStars(state: StarsRateState): void;
  setStep(step: ReviewPopupSteps): void;
}

export interface IGiveFeedbackToShopStep {
  totalStars: StarsRateState;
  shopId: string;
  orderId: string;
  onFinishReview(input: {
    orderId: string;
    shopId: string;
    title: string;
    description: string;
    stars: number;
    images: Array<any>;
  }): void;
  setStep(step: ReviewPopupSteps): void;
}

export const enum ReviewPopupSteps {
  RatingStep,
  FeedbackStep,
}

export const enum StarsRateState {
  Zero,
  One,
  Two,
  Three,
  Four,
  Five,
}

export const mapStarsToClassName = {
  [StarsRateState.Zero]: 'initial-state-bg',
  [StarsRateState.One]: 'horrible-bg',
  [StarsRateState.Two]: 'bad-bg',
  [StarsRateState.Three]: 'good-bg',
  [StarsRateState.Four]: 'great-bg',
  [StarsRateState.Five]: 'excellent-bg',
};

export const mapStarsToDescription = {
  [StarsRateState.Zero]: 'Rate experience',
  [StarsRateState.One]: 'Horrible',
  [StarsRateState.Two]: 'Bad',
  [StarsRateState.Three]: 'Good',
  [StarsRateState.Four]: 'Great',
  [StarsRateState.Five]: 'Excellent!',
};

export const mapStarsToFeedbackTitle = {
  [StarsRateState.One]: 'Sorry to hear that...',
  [StarsRateState.Two]: 'Sorry to hear that...',
  [StarsRateState.Three]: 'Good is not the best..',
  [StarsRateState.Four]: 'Thats Great!',
  [StarsRateState.Five]: 'Thats Awesome!',
};

export const mapStarsToFeedbackDescription = {
  [StarsRateState.One]: 'What would improve your experience?',
  [StarsRateState.Two]: 'What would improve your experience?',
  [StarsRateState.Three]: 'Let the seller know what he could improve',
  [StarsRateState.Four]: 'Great is not awesome, what can be improved?',
  [StarsRateState.Five]: 'Let us know how was your experience!',
};

export const mapStarsToAnimation = {
  [StarsRateState.Zero]: {
    loop: true,
    autoplay: true,
    animationData: excellentSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  [StarsRateState.One]: {
    loop: true,
    autoplay: true,
    animationData: horribleSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  [StarsRateState.Two]: {
    loop: true,
    autoplay: true,
    animationData: badSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  [StarsRateState.Three]: {
    loop: true,
    autoplay: true,
    animationData: goodSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  [StarsRateState.Four]: {
    loop: true,
    autoplay: true,
    animationData: greatSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
  [StarsRateState.Five]: {
    loop: true,
    autoplay: true,
    animationData: excellentSmile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  },
};

export interface IPastOrdersPopup {
  declinedOrders: { list: IOrderDetails[]; totalCount: number };
  completedOrders: { list: IOrderDetails[]; totalCount: number };
  loadMoreOrders(input: { mode: LoadMoreTypes }): void;
  showPopup(input: { id: string; orderId: string }): void;
  hidePopup(): void;
}

export enum PastOrdersPopupTabs {
  Completed = 'Completed',
  Declined = 'Declined',
}

export interface IPaymentAccordion {
  couponCode: string | null;
  id: string;
  source: PaymentSource;
  transactionDescription: string | null;
  hasDigitalFiles: boolean;
  shippingPrice: number | null;
  shouldExpandPaypal: boolean;
  shippingRates: IBasketShippingRate[] | null;
  linkedPaymentOptions: ILinkedPaymentOption[];
  stripePaymentSessionDetails: IPaymentSessionDetails | null;
  paypalCheckoutUrl: string;
  isDisabled: boolean;
  convertedFromPrice: IPrice;
  avatar: string;
  username: string;
  legacyPayoutOptions: IPayoutOptions[];
  isPaymentMethodsLoading: boolean;
  isPaymentLinksLoading: boolean;
  totalPrice: IPrice;
  isShippingRatesError: boolean;
  sellerDetails: IShopUserDetails;
  resetPayments(): void;
  onPaymentMethodClick(paymentMethod: PayoutMethods): void;
  updateOrderStatus(input: {
    id: string;
    orderId: string;
    avatar: string;
    username: string;
    hasDigitalFiles: boolean;
    source: PaymentSource;
    price: IPrice;
    selectedRateAttribute: BasketRateAttributes | null;
  }): void;
  onStripePaymentFinish(input: {
    username: string;
    avatar: string;
    hasDigitalFiles: boolean;
    source: PaymentSource;
    id: string;
    price: IPrice;
    selectedRateAttribute: BasketRateAttributes | null;
  }): void;
  showPopup(input: { id: string; orderId?: string; checkoutId?: string; link?: string }): void;
  getStripeSessionDetails(id: string, provider: string): void;
  getPaypalCheckoutUrl(): void;
  onCopy(input: { id: MessageIds; link: string }): void;
  hidePopup(): void;
}

export interface IStripePayoutWrapper {
  id: string;
  paymentSessionDetails: IPaymentSessionDetails | null;
  paymentMethods: string[];
  isLoading: boolean;
  showPopup(input: { id: string }): void;
  onPayment(): void;
  hidePopup(): void;
}

export interface IStripePayoutForm {
  id: string;
  totalPrice: number;
  currencyCode: CurrencyCode;
  paymentMethods: string[];
  isLoading: boolean;
  hidePopup(): void;
  getPaymentConfirmation(): void;
  showPopup(input: { id: string; orderId: string; checkoutId: string }): void;
}

export interface IPayNowPopup {
  shopId: string;
  id: string;
  source: PaymentSource;
  hasDigitalFiles: boolean;
  items: ICheckoutItem[];
  shippingPrice: number | null;
  shouldExpandPaypal: boolean;
  totalDiscount: number;
  subTotalPrice: number;
  isBuyNow: boolean;
  isShippingRatesError: boolean;
  shippingRates: IBasketShippingRate[] | null;
  currencyCode: CurrencyCode;
  linkedPaymentOptions: ILinkedPaymentOption[];
  paypalCheckoutUrl: string;
  convertedFromPrice: IPrice;
  avatar: string;
  username: string;
  legacyPayoutOptions: IPayoutOptions[];
  shippingAddress: IShippingAddress | null;
  totalPrice: IPrice;
  discountDetails: IDiscountDetails | null;
  onEditAddressClick(): void;
  updateOrderStatus(input: {
    id: string;
    orderId: string;
    avatar: string;
    username: string;
    hasDigitalFiles: boolean;
    source: PaymentSource;
    price: IPrice;
    selectedRateAttribute: BasketRateAttributes | null;
  }): void;
  hidePopup(): void;
  showPopup(input: { id: string; orderId?: string; checkoutId?: string; link?: string }): void;
  getStripeSessionDetails(id: string, provider: string): void;
  getPaypalCheckoutUrl(): void;
  onRateChange(id: string): void;
}

export interface IPaymentSection {
  id: string;
  isPaymentMethodsLoading: boolean;
  isPaymentLinksLoading: boolean;
  isDisabled: boolean;
  isShippingRatesError: boolean;
  linkedPayoutOptions: ILinkedPaymentOption[];
  isUserLinkedToPayoutProvider: boolean;
  originalPrice: IPrice | null;
  legacyPayoutOptions: IPayoutOptions[];
  selectedPaymentMethod: PayoutMethods | null;
  stripePaymentSessionDetails: IPaymentSessionDetails | null;
  onContactSellerClick(): void;
  onStripePaymentFinish(): void;
  onPayWithPaypalClick(): void;
  onCopyClick(path: string): void;
  handleAccordionChange(activeKey: string): void;
  showPopup(input: { id: string; orderId?: string; link?: string }): void;
}

export interface ICheckoutSummarySection {
  shopId: string;
  items: ICheckoutItem[];
  shippingRates: IBasketShippingRate[] | null;
  shippingPrice: number | null;
  totalDiscount: number;
  hasError: boolean;
  subTotalPrice: number;
  currencyCode: CurrencyCode;
  shippingAddress: IShippingAddress | null;
  isBuyNow: boolean;
  discountDetails: IDiscountDetails | null;
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  isCouponEditable: boolean;
  onRemoveCouponCode(): void;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  onShippingRateChange(id: string): void;
  onEditAddressClick(): void;
  onRemoveCouponCodeError(): void;
}

export interface IShopViewers {
  id: string;
  username: string;
  name: string;
  avatar: string;
  shopName: string;
  time: number;
}

export interface IViewer {
  viewer: IShopViewers;
  onClick(): void;
}

export interface IShopViewsPopup {
  shopViewers: IShopViewers[];
  isPremium: boolean;
  totalShopViewers: number;
  onLoadMore(): void;
  onHide(): void;
  showPopup(input: { id: string }): void;
}

export enum TotalActiveOrdersPopupTabs {
  Todo = 'To-Do',
  Awaiting = 'Awaiting',
}

export interface ITotalActiveOrdersPopup {
  todoOrders: { list: IOrderDetails[]; totalCount: number };
  awaitingOrders: { list: IOrderDetails[]; totalCount: number };
  hidePopup(): void;
  showPopup(input: { id: string; orderId: string }): void;
}

export interface IPayNowPopupOrderWrapper {
  shopId: string;
  id: string;
  items: ICheckoutItem[];
  shippingPrice: number | null;
  totalDiscount: number;
  subTotalPrice: number;
  currencyCode: CurrencyCode;
  linkedPaymentOptions: ILinkedPaymentOption[];
  originalPrice: IPrice;
  payoutOptions: IPayoutOptions[];
  discountDetails: IDiscountDetails | null;
  getPaypalCheckoutUrl(input: {
    orderId: string;
    provider: string;
    couponCode: string | null;
  }): void;
  getStripeSessionDetails(input: {
    orderId: string;
    provider: string;
    couponCode: string | null;
  }): void;
}

export interface IWithdrawOrderPopup {
  orderId: string;
  hidePopup(): void;
  onWithdrawOrder(input: { orderId: string }): void;
}

export interface IDeclineOrderPopup {
  id: string;
  isSeller: boolean;
  hidePopup(): void;
  onDeclineOrder(declineReason: string): void;
}

export interface IDidOrderArrivePopup {
  id: string;
  onMarkOrderAsArrived(input: { orderId: string }): void;
  hidePopup(): void;
}

export interface IConfirmPaymentPopup {
  id: string;
  avatar: string;
  username: string;
  hidePopup: () => void;
  updateOrderStatus(input: { id: string; username: string; avatar: string }): void;
}

export enum DeclineOptions {
  ItemIsSoldOut = 'This item is sold out',
  IDoNotShipToThisLocation = 'I do not ship to this location',
  CantMeetBuyerRequests = 'I can’t meet the buyer requests',
  ShippingCostTooHigh = 'The shipping cost is too high',
  ItemIsntAsIThought = 'The item isn’t as I thought',
  IHaveOtherReasons = 'I have other reasons',
}

export enum OrderBoxTypes {
  ActiveOrders = 'Total Active Orders',
  PastOrders = 'Past Orders',
  ShippedOrders = 'On the way',
  ShopViews = 'Shop Views',
  ExternalClicks = 'External links clicks',
}

export interface IActiveOrders {
  awaitingOrders: { list: IOrderDetails[]; totalCount: number };
  todoOrders: { list: IOrderDetails[]; totalCount: number };
}

export interface IPackageType {
  name: string;
  carrier: string;
  weightLb: number;
  length: number;
  width: number;
  height: number;
}

export interface ICreateShippingLabelPopup {
  id: string;
  orderNumber: string;
  shippingLabelDetails: IShippingLabelDetails | null;
  hasInsurance: boolean;
  isShippoConnected: boolean;
  isLoadingBuyShippingLabel: boolean;
  shippingAddress: IShippingAddress;
  orderShippingService: ISelectedShippingService | null;
  orderDimensions: IProductDimensions | null;
  createShippingLabelForm: ICreateShippingLabelForm;
  onShippoConnect(): void;
  hidePopup(): void;
  getPackagesTypeOptions(): void;
  getLabelInfo(input: { orderId: string }): void;
  updateDimensions(input: { dimensions: IProductDimensions }): void;
  updateRecipientAddress(input: { shippingAddress: IShippingAddress }): void;
}

export interface IPackageInfoForm {
  id: string;
  avatar: string;
  username: string;
  orderNumber: string;
  isLoadingShippingService: boolean;
  packagesTypeList: IPackageType[] | null;
  shippingLabelDetails: IShippingLabelDetails | null;
  shippingRates: IShippingRate[] | null;
  disabledShippingRates: IShippingDisabledRate[] | null;
  fromAddress: IOriginAddress | null;
  createShippingLabelForm: ICreateShippingLabelForm;
  isAddressEdited: boolean;
  updateIsLoadingBuyShippingLabel(): void;
  getShippingRate(input: {
    orderId: string;
    shipDate: number;
    fromAddress: IOriginAddress | null;
    toAddress: IShippingAddress;
    dimensions: IProductDimensions;
    withInsurance: boolean;
    withSignature: boolean;
  }): void;
  updateSelectedShippingService(input: { shippingServiceId: string }): void;
  buyAndConfirmShippingLabel(input: {
    orderId: string;
    orderNumber: string;
    rateId: string;
    avatar: string;
    username: string;
    isAddressEdited: boolean;
    packageId: string;
    hasInsurance: boolean;
    hasSignature: boolean;
  }): void;
}

export interface IInfoSideBar {
  id: string;
  shippingPrice: number | null;
  subTotalPrice: number;
  totalDiscount: number;
  lastActivityDate: number;
  createShippingLabelForm: ICreateShippingLabelForm;
  orderShippingService: ISelectedShippingService | null;
  currencyCode: CurrencyCode;
  shippingLabelDetails: IShippingLabelDetails | null;
  items: IOrderItem[];
  shippingServicePrice: IPrice | null;
  shippingAddress: string;
  isBuyShippingLabelLoading: boolean;
  onClick(): void;
  showPopup(input: { id: string; orderId: string }): void;
}

export interface IShippingServiceOption {
  isDisabled: boolean;
  shippingRate: IShippingRate | null;
  shippingDisabledRate: IShippingDisabledRate | null;
  isSelected: boolean;
  onClick(id: string): void;
}

export interface IPurchasedLabelPopup {
  orderId: string;
  orderNumber: string;
  shippingLabelDownloadUrls: IShippingLabelDownloadUrls | null;
  hidePopup(): void;
  getSippingLabelDownloadUrls(input: { orderId: string }): void;
  sendShippingLabelFilesToEmail(input: { orderId: string }): void;
}

export interface IEditBuyerShippingAddressPopup {
  orderId: string;
  recipientAddress: IShippingAddress;
  orderNumber: string;
  saveAddressForm(input: { shippingAddress: IShippingAddress }): void;
  validateAddress(input: { orderId: string }): void;
  hidePopup(): void;
}

export interface IDownloadShippingLabelBox {
  url: string;
  type: string;
  onClick(): void;
}

export enum ShippingLabelDocuments {
  ShippingLabel = 'Shipping Label',
  PackingSlip = 'Packing Slip',
}

export interface IConfirmBuyerAddressPopupWrapper {
  shippingAddress: IShippingAddress;
  addressSuggestion: IAddressPreview;
  updateRecipientAddress(input: {
    postalCode: string;
    city: string;
    country: Countries;
    address: string;
    state: string | null;
  }): void;
}

export interface IUpdateShippingInformationPopup {
  shopId: string;
  hidePopup(): void;
}

export interface IPaymentSuccessfulPopup {
  isSpecificOrderPage: boolean;
  orderId: string | null;
  token: string | null;
  hidePopup(): void;
}
