import { FC, useEffect, useState } from 'react';
import { useTextAreaRef } from 'tedooo-web-design-system/hooks';
import classNames from 'classnames';

import Comment from './comment/Comment';
import './commentWrapper.scss';
import { ICommentWrapper, ITaggedUser } from 'types';
import { CommentInput } from 'components/commentInput';
import Replies from './replies/Replies';
import { useRequireToken } from 'hooks/useRequireToken';

const CommentWrapper: FC<ICommentWrapper> = ({
  text,
  replies,
  username,
  fullName,
  taggedUsers,
  date,
  totalReplies,
  isPremium,
  totalLikes,
  isRepliesExpanded,
  likes,
  isLiked,
  avatar,
  id,
  image,
  userId,
  onReply,
  onCommentDelete,
  isDeleted,
  onRepliesClick,
  loggedInUserId,
  loggedInUserAvatar,
  onUserClick,
  isPostOwner,
  postOwnerId,
  onCommentsLike,
  onCommentLikesClick,
}) => {
  const [isInputVisible, setIsInputVisible] = useState(false);

  const [commentId, setCommentId] = useState(id);

  const [taggedUser, setTaggedUser] = useState<ITaggedUser>({ username: '', userId: '' });

  const ref = useTextAreaRef();

  const onReplyClick = useRequireToken((replyParentId: string, tag: ITaggedUser) => {
    setIsInputVisible(true);
    setCommentId(replyParentId);
    setTaggedUser(tag);
  });

  const onSubmit = (replyText: string, replyTaggedUsers: ITaggedUser[], images: Array<string>) => {
    onReply(commentId, replyText, replyTaggedUsers, images);
    setIsInputVisible(false);
  };

  const isCommentOwner = userId === loggedInUserId;

  useEffect(() => {
    const cursorStartingPosition = (taggedUser?.username?.length || 0) + 1;
    ref.current?.focus();
    ref.current?.resizableTextArea?.textArea.setSelectionRange(
      cursorStartingPosition,
      cursorStartingPosition,
    );
  }, [isInputVisible]);

  return (
    <div className={classNames('comment-wrapper-container')}>
      <Comment
        onUserClick={onUserClick}
        isCommentOwner={isCommentOwner}
        isReply={false}
        isDeleted={isDeleted}
        onCommentDelete={onCommentDelete}
        userId={userId}
        onReplyClick={onReplyClick}
        replies={replies}
        isRepliesExpanded={isRepliesExpanded}
        image={image}
        id={id}
        totalReplies={totalReplies}
        avatar={avatar}
        username={username}
        fullName={fullName}
        isPremium={isPremium}
        totalLikes={totalLikes}
        isLiked={isLiked}
        date={date}
        text={text}
        isPostOwner={isPostOwner}
        onCommentLike={onCommentsLike}
        onCommentLikesClick={onCommentLikesClick}
        likes={likes}
        taggedUsers={taggedUsers}
      />
      <Replies
        totalReplies={totalReplies}
        postOwnerId={postOwnerId}
        onUserClick={onUserClick}
        onReplyClick={onReplyClick}
        replies={replies}
        commentId={id}
        onRepliesClick={onRepliesClick}
        onCommentDelete={onCommentDelete}
        onCommentLike={onCommentsLike}
        onCommentLikesClick={onCommentLikesClick}
        isRepliesExpanded={isRepliesExpanded}
      />
      {isInputVisible && (
        <CommentInput
          ref={ref}
          isUserSingIn={!!loggedInUserId}
          maxImages={1}
          avatar={loggedInUserAvatar}
          avatarSize={40}
          placeholder="Add a comment"
          onSubmit={onSubmit}
          taggedUser={taggedUser}
        />
      )}
    </div>
  );
};

export default CommentWrapper;
