import { IDiscoverApi } from 'types';
import { graphQlRequest } from 'api/graphQl';
import { DiscoverPageResultWeb, Query, ShopOfTheDay } from 'graphql/generated/graphql';
import { GET_DISCOVER_PAGE_QUERY, GET_SHOP_OF_THE_DAY_QUERY } from './DiscoverQueries';

export class DiscoverApi implements IDiscoverApi {
  async getDiscoverPageData(token?: string): Promise<DiscoverPageResultWeb> {
    const res = await graphQlRequest(token).query<Query>({
      query: GET_DISCOVER_PAGE_QUERY,
    });

    return res.data.getDiscoverPageDataWeb;
  }

  async getShopOfTheDay(token: string): Promise<ShopOfTheDay[]> {
    const res = await graphQlRequest(token).query<Query>({
      query: GET_SHOP_OF_THE_DAY_QUERY,
    });

    return res.data.getDiscoverPageData.shopsOfTheDay;
  }
}
