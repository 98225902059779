import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IMessageState } from './types';
import { ServerStatus } from '../shop/types';
import { requestJoinGroup } from '../searchResult/SearchResultActions';
import {
  updatePassword,
  updateUserCurrency,
  updateVacationMode,
} from '../settings/SettingsActions';
import { submitNewPassword } from '../forgetPassword/ForgetPasswordActions';
import { getGroupLink } from '../chat/ChatActions';
import { createPost, getPostLink, onHidePostFromActivity, toggleRepost } from '../feed/PostActions';
import { reviewOrder } from '../orders/OrdersActions';
import { buyShippingLabel, sendShippingLabelToEmail } from '../shipping/ShippingActions';
import { requestOrder, sendEmailDigitalFiles } from '../specificOrder/SpecificOrderActions';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { getMojoLink } from '../mojos/mojo/MojoActions';

const initialState: IMessageState = {
  id: null,
  serverStatus: null,
};

export const MessageSlicer = createSlice({
  name: 'message',
  initialState,
  reducers: {
    show: (state, action) => {
      const { id, link } = action.payload;
      state.id = id;
      state.link = link;
    },
    hide: (state) => {
      state.id = null;
      state.serverStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestJoinGroup.rejected, (state) => {
      state.serverStatus = ServerStatus.ERROR;
    });
    builder.addCase(getGroupLink.fulfilled, (state, action) => {
      state.link = action.payload.url;
    });
    builder.addCase(getPostLink.fulfilled, (state, action) => {
      state.link = action.payload.link;
    });
    builder.addCase(getMojoLink.fulfilled, (state, action) => {
      state.link = action.payload.url;
    });
    builder.addCase(toggleRepost.fulfilled, (state, action) => {
      const { actionId, postId } = action.meta.arg;

      state.postId = postId;
      state.actionId = actionId;
      state.serverStatus = ServerStatus.SUCCESS;
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      const { repostDetails } = action.meta.arg;

      const repostId = repostDetails?.id;

      const { id } = action.payload;

      if (repostId) {
        state.actionId = id;
        state.serverStatus = ServerStatus.SUCCESS;
      }
    });
    builder.addCase(onProductLikeToggle.fulfilled, (state, action) => {
      const { isLiked: prevIsLiked } = action.meta.arg;
      state.isLiked = !prevIsLiked;
    });
    builder.addCase(updateVacationMode.fulfilled, (state, action) => {
      const { details } = action.meta.arg;

      if (details) {
        state.serverStatus = ServerStatus.SUCCESS;
      }
    });
    builder.addMatcher(
      isAnyOf(
        toggleRepost.rejected,
        createPost.rejected,
        onHidePostFromActivity.rejected,
        requestOrder.rejected,
        buyShippingLabel.rejected,
      ),
      (state) => {
        state.serverStatus = ServerStatus.ERROR;
      },
    );
    builder.addMatcher(
      isAnyOf(
        submitNewPassword.fulfilled,
        updatePassword.fulfilled,
        requestJoinGroup.fulfilled,
        onHidePostFromActivity.fulfilled,
        updateUserCurrency.fulfilled,
        onProductLikeToggle.fulfilled,
        sendEmailDigitalFiles.fulfilled,
        sendShippingLabelToEmail.fulfilled,
        reviewOrder.fulfilled,
      ),
      (state) => {
        state.serverStatus = ServerStatus.SUCCESS;
      },
    );
  },
});

export default MessageSlicer.reducer;
