import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscoverListsId } from 'types';
import { IStore2 } from '../types';
import { ProductsApi } from './ProductsApi';
import { SearchResultReducer } from '../searchResult/SearchResultReducer';

const api = new ProductsApi();

const searchResultsApi = new SearchResultReducer();

export const getAllProducts = createAsyncThunk(
  'async/getAllProducts',
  async (input: { type: DiscoverListsId; paginationToken: string | null }, { getState }: any) => {
    const state: IStore2 = getState();

    const { type, paginationToken: initialPaginationToken } = input;

    const { token } = state.user;

    const { paginationToken } = state.products;

    return api.getAllProducts(type, initialPaginationToken || paginationToken, token);
  },
);

export const getCategoryProducts = createAsyncThunk(
  'async/getCategoryProducts',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { offset } = state.products;

    const { filters } = state.searchResult;

    const { search } = filters;

    return searchResultsApi.getProducts(offset, search, token);
  },
);
