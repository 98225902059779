import jwt_decode from 'jwt-decode';
import crypto from 'crypto-js';
import CryptoJS from 'crypto-js/core';

import {
  FontResponses,
  IntroPopupKeys,
  IToken,
  popupIdsOptions,
  PremiumPlanOptions,
  PremiumStatus,
} from 'types';
import { Fonts } from 'constant/fonts';
import { getDiffInUnit } from 'utils';

export const USERNAME_EXISTS_ERROR_MESSAGE = 'Username already exists';

export const USERNAME_TYPING_ERROR_MESSAGE =
  'Username can only contain letters, digits, underscores and dashes';

export const USERNAME_INCLUDES_TEDOOO_ERROR_MESSAGE = 'Username cannot contains the word tedooo';

export const PHONE_NUMBER_ERROR_MESSAGE = 'Phone number already exists';

const SUPER_ADMINS_IDS = ['6370058b63bdc130c6d1b1fb', '63fb2f083c5c64c9d4c28598'];

export const getAvatar = (token?: string) => {
  if (!token) {
    return null;
  }

  const parsedToken = jwt_decode<IToken>(token);

  return parsedToken.avatar;
};

export const getThirdParty = (token?: string) => {
  if (!token) {
    return null;
  }

  const { thirdParty } = jwt_decode<any>(token);

  if (thirdParty === 2) {
    return 'Google';
  }
  if (thirdParty === 4) {
    return 'Facebook';
  }

  return null;
};

export const getUserId = (token?: string) => {
  if (!token) {
    return null;
  }

  return jwt_decode<IToken>(token).userId;
};

export const getUsername = (token?: string) => {
  if (!token) {
    return null;
  }

  return jwt_decode<IToken>(token).username;
};

export const getFullName = (token?: string) => {
  if (!token) {
    return null;
  }

  return jwt_decode<IToken>(token).firstName;
};

export const getIsPremium = (token?: string) => {
  if (!token) {
    return false;
  }

  return jwt_decode<IToken>(token).subUntil > Date.now();
};

export const getUserPremiumStatus = (token?: string): PremiumStatus => {
  if (!token) {
    return PremiumStatus.TryFreeMonth;
  }
  if (jwt_decode<IToken>(token).subUntil === 0) {
    return PremiumStatus.TryFreeMonth;
  }
  if (jwt_decode<IToken>(token).subUntil > Date.now()) {
    return PremiumStatus.Active;
  }
  if (jwt_decode<IToken>(token).subUntil < Date.now()) {
    return PremiumStatus.Expired;
  }

  return PremiumStatus.TryFreeMonth;
};

export const getSubscriptionNextBilling = (token?: string) => {
  if (!token) {
    return 0;
  }

  return jwt_decode<IToken>(token).subUntil;
};

export const getPremiumDetails = (token?: string) => {
  if (!token) {
    return null;
  }

  const userPremiumStatus = getUserPremiumStatus(token);

  return {
    userPremiumPlan: PremiumPlanOptions.none,
    nextBillingDate: getSubscriptionNextBilling(token),
    premiumStatus: userPremiumStatus,
    managePlanUrl: '',
    payoutProvider: null,
    isSubscriptionAutoRenew: null,
    monthlySubscriptionUrl: '',
    yearlySubscriptionUrl: '',
  };
};

export const getIsSuperAdmin = (token?: string) => {
  const userId = getUserId(token);

  if (userId) {
    return SUPER_ADMINS_IDS.includes(userId);
  }

  return false;
};

const getAllAvatars = () => {
  const paths = require.context('photos/avatars', false, /\.png$/);

  return paths
    .keys()
    .filter((path) => path.startsWith('photos/avatars'))
    .map(paths);
};

const DEFAULT_AVATARS_LIST: any[] = getAllAvatars();

export const getDefaultAvatar = (id: string): string => {
  const avatarIndex =
    parseInt(crypto.SHA256(id).toString(CryptoJS.enc.Hex).substring(0, 10), 16) %
    DEFAULT_AVATARS_LIST.length;

  return DEFAULT_AVATARS_LIST[avatarIndex];
};

export const shouldRefreshToken = (token: string | null): boolean => {
  if (!token) {
    return false;
  }

  const date = Date.now();

  const issuedTime = jwt_decode<IToken>(token!).iat * 1000;

  const minutes = getDiffInUnit(issuedTime, date, 'minute');

  return minutes > 10;
};

export const mapTipFontResponseToFont: { [key in FontResponses]: Fonts } = {
  [FontResponses.Heading1]: Fonts.Heading1,
  [FontResponses.Heading2]: Fonts.Heading2,
  [FontResponses.Heading3]: Fonts.Heading3,
  [FontResponses.Heading4]: Fonts.Heading4,
  [FontResponses.Heading5]: Fonts.Heading5,
  [FontResponses.ButtonSmall]: Fonts.ButtonSmall,
  [FontResponses.ButtonMedium]: Fonts.ButtonMedium,
  [FontResponses.Body14]: Fonts.BodySmall,
  [FontResponses.Body16]: Fonts.BodyRegular,
  [FontResponses.Subheading]: Fonts.SubHeading,
  [FontResponses.SubHeadingLarge]: Fonts.SubHeadingLarge,
};

export const mapPopupKeyToId = {
  [IntroPopupKeys.Payment]: popupIdsOptions.payoutIntroPopup,
  [IntroPopupKeys.Shipping]: popupIdsOptions.shippingIntroPopup,
  [IntroPopupKeys.Visions]: popupIdsOptions.visionsGuidePopup,
  [IntroPopupKeys.VisionOffers]: popupIdsOptions.newVisionOffersPopup,
  [IntroPopupKeys.Favorites]: popupIdsOptions.newSavedItemPopup,
};
