import { gql } from '@apollo/client';

export const GENERATE_VISION_MUTATION = gql`
  mutation GenerateVision($prompt: String, $imageUrl: String, $quantity: Int!) {
    generateVision(prompt: $prompt, imageUrl: $imageUrl, quantity: $quantity) {
      generationId
      visions {
        id
        imageUrl
      }
    }
  }
`;

export const GET_SMART_TAGS_QUERY = gql`
  query GetSmartTags($query: String) {
    getSmartTags(query: $query) {
      tags
    }
  }
`;

export const CHANGE_VISION_VISIBILITY_MUTATION = gql`
  mutation ChangeVisionVisibility($generationId: String!, $isPublic: Boolean!) {
    changeVisionVisibility(generationId: $generationId, isPublic: $isPublic) {
      success
    }
  }
`;
