import axios from 'axios';

import { Method } from 'types';
import { BASE_URL } from 'constant';

export interface IObject {
  [key: string]: any;
}

export const fetcher = async (
  endpoint: string,
  method: Method,
  token?: string,
  body?: IObject,
  queryParams?: IObject,
  additionalHeaders?: object,
) => {
  let queryString = '';
  let data;

  const defaultHeaders = additionalHeaders || {};

  const headers: IObject = {
    ...defaultHeaders,
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = token;
  }
  if (queryParams) {
    Object.entries(queryParams)
      .filter(([, value]): any => value !== undefined && value !== null)
      .map(([key, value]: any) => (queryString += `&${key}=${encodeURIComponent(value)}`));
    queryString = `?${queryString.substring(1)}`;
  }
  if (body) {
    data = JSON.stringify(body);
  }

  const request = {
    url: `${BASE_URL}/${endpoint}${queryString}`,
    method,
    data,
    headers,
  };
  try {
    const result = await axios.request(request);

    if (result.status > 199 && result.status < 400) {
      return result.data;
    }

    return Promise.reject({
      message: result.data.message,
      code: result.data.code,
    });
  } catch (e: any) {
    if (e.response) {
      throw await Promise.reject({ message: e.response.data, code: e.response.status });
    }
    throw e;
  }
};
