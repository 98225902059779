import { graphQlRequest } from 'api/graphQl';
import { GET_ONE_TIME_PAYMENT_INFORMATION_FROM_QUERY } from './TransactionsQueries';
import { GetOneTimePaymentLinkInformationResponse } from 'graphql/generated/graphql';
import { ITransactionsApi } from './types';
import { GET_CHECKOUT_ID_QUERY } from '../payouts/PayoutQueries';

export class TransactionsApi implements ITransactionsApi {
  async getOneTimePaymentInformationFromLink(
    id: string,
    token?: string,
  ): Promise<GetOneTimePaymentLinkInformationResponse> {
    const variables = {
      id,
    };

    const res = await graphQlRequest(token).query({
      query: GET_ONE_TIME_PAYMENT_INFORMATION_FROM_QUERY,
      variables,
    });

    return res.data.getOneTimePaymentLinkInformation;
  }

  async getCheckoutIdFromTransaction(
    price: number,
    receiverId: string,
    description: string,
    email: string,
    token?: string,
  ): Promise<string> {
    const variablesCheckoutId = {
      email,
      description,
      priceAmount: price,
      receiverId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GET_CHECKOUT_ID_QUERY,
      variables: variablesCheckoutId,
    });

    return res.data.generateCheckoutIdForOneTimePayment.id;
  }
}
