import { Middleware } from 'redux';

import {
  addShippingProfile,
  updateShippingProfile,
  validateShippingAddress,
  buyShippingLabel,
  sendShippingLabelToEmail,
  validateShippingProfileAddress,
} from './ShippingActions';
import { PopupSlicer } from '../popup/PopupActions';
import { popupIdsOptions, IStore2, MessageIds } from 'types';
import { ShippingSlicer } from './ShippingSlicer';
import { ListingSlicer } from '../listing/ListingSlicer';
import { MessageSlicer } from '../message/MessageActions';
import { PopupsSrv } from 'services/PopupsSrv';
import { addShippingAddress, updateShippingAddress } from '../settings/SettingsActions';

export const shippingMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action: any = {}) => {
    switch (action.type) {
      case validateShippingAddress.rejected.type: {
        const { code } = action.payload;

        if (code === 422) {
          dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.validateShippingAddressPopup }));
        } else {
          dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.invalidShippingAddressPopup }));
        }
        break;
      }

      case validateShippingProfileAddress.rejected.type: {
        const { code } = action.payload;

        if (code === 422) {
          dispatch(
            PopupSlicer.actions.show({ id: popupIdsOptions.shippingProfileValidateAddressPopup }),
          );
        } else {
          dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.invalidShippingAddressPopup }));
        }
        break;
      }

      case validateShippingProfileAddress.fulfilled.type:
        const {
          newAddress: newProfileAddress,
          newCity: newProfileCity,
          newCountry: newProfileCountry,
          newPostalCode: newProfilePostalCode,
          newState: newProfileState,
        } = action.payload;

        const newAddressSuggestion = {
          address: newProfileAddress,
          state: newProfileState,
          city: newProfileCity,
          country: newProfileCountry,
          postalCode: newProfilePostalCode,
        };

        const store: IStore2 = getState();

        const { shippingAddress, id: profileId } = store.shipping.shippingProfileForm;

        const {
          address: profileAddress,
          city: profileCity,
          country: profileCountry,
          postalCode: profilePostalCode,
        } = shippingAddress;

        if (
          profileAddress !== newProfileAddress ||
          profileCity !== newProfileCity ||
          profileCountry !== newProfileCountry ||
          profilePostalCode !== newProfilePostalCode
        ) {
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.shippingProfileConfirmAddressPopup,
              addressSuggestion: newAddressSuggestion,
            }),
          );
        } else if (profileId) {
          dispatch(updateShippingProfile());
        } else {
          dispatch(addShippingProfile());
        }
        break;

      case validateShippingAddress.fulfilled.type:
        const { newAddress, newCity, newCountry, newPostalCode, newState } = action.payload;

        const addressSuggestion = {
          address: newAddress,
          state: newState,
          city: newCity,
          country: newCountry,
          postalCode: newPostalCode,
        };

        const state2: IStore2 = getState();

        const { itemAddedToBasketDetails } = state2.popup;

        const { streetAddress, country, city, postalCode, id } =
          state2.shipping.shippingAddressForm;

        if (
          streetAddress !== newAddress ||
          city !== newCity ||
          country !== newCountry ||
          postalCode !== newPostalCode
        ) {
          dispatch(
            PopupSlicer.actions.show({
              id: popupIdsOptions.confirmShippingAddressPopup,
              addressSuggestion,
              itemAddedToBasketDetails,
            }),
          );
        } else if (state2.user.token) {
          if (id) {
            dispatch(
              updateShippingAddress({ shippingAddress: state2.shipping.shippingAddressForm }),
            );
          } else {
            dispatch(addShippingAddress({ shippingAddress: state2.shipping.shippingAddressForm }));
          }
        }
        break;

      case updateShippingProfile.fulfilled.type:
      case addShippingProfile.fulfilled.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case ListingSlicer.actions.updateShippingProfile.type:
        const store2: IStore2 = getState();

        const oldId = store2.listing.shippingProfileId;

        const { id: newId } = action.payload;
        dispatch(ShippingSlicer.actions.updateMapToAffectedListings({ oldId, newId }));
        break;

      case PopupSlicer.actions.show.type: {
        const { id: popupId, shippingProfileId } = action.payload;

        const state: IStore2 = getState();

        const { country: originCountry } = state.createShop;

        if (popupId === popupIdsOptions.createShippingProfilePopup && !shippingProfileId) {
          dispatch(ShippingSlicer.actions.resetShippingProfileForm({ country: originCountry }));
        }
        break;
      }

      case buyShippingLabel.fulfilled.type:
        const { orderId, orderNumber } = action.meta.arg;
        dispatch(PopupSlicer.actions.hide());
        dispatch(
          PopupSlicer.actions.show({
            id: popupIdsOptions.purchasedLabelPopup,
            orderId,
            orderNumber,
          }),
        );
        dispatch(ShippingSlicer.actions.reset());

        break;

      case buyShippingLabel.rejected.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.failedToBuyShippingLabel }));
        break;

      case sendShippingLabelToEmail.fulfilled.type:
        dispatch(MessageSlicer.actions.show({ id: MessageIds.emailSent }));
        dispatch(PopupSlicer.actions.hide());
        break;

      case PopupSlicer.actions.hide.type: {
        const state: IStore2 = getState();

        const { ids } = state.popup;

        if (PopupsSrv.checkIfDisplayed(popupIdsOptions.purchasedLabelPopup, ids)) {
          dispatch(ShippingSlicer.actions.reset());
        }

        break;
      }

      default:
        break;
    }

    return next(action);
  };
