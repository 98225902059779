export const debounce = <F extends (...args: any[]) => void>(func: F, ms: number) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<F>) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, ms);
  };
};

export const throttle = <F extends (...args: any[]) => void>(func: F, ms: number) => {
  let timer: ReturnType<typeof setTimeout> | null;

  return (...args: Parameters<F>) => {
    if (timer) return;
    timer = setTimeout(() => {
      func(...args);
      timer = null;
    }, ms);
  };
};
