import { createAsyncThunk } from '@reduxjs/toolkit';

import { SpecificOrderApi } from './SpecificOrderApi';
import {
  IOrderItem,
  IShippingAddress,
  IStore2,
  IUpdateOrderStatusBody,
  OrderStatuses,
} from 'types';
import { parseItemsToRequestOrderItems, parseItemsToUpdateStatusRequest } from './helper';

const api = new SpecificOrderApi();

export const getSpecificOrder = createAsyncThunk(
  'async/getSpecificOrder',
  async (input: { orderId: string; userAvatar: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { orderId } = input;

    const { token = '' } = state.user;

    return api.getSpecificOrder(token, orderId);
  },
);

export const requestOrder = createAsyncThunk(
  'async/requestOrder',
  async (
    input: {
      shippingAddress: IShippingAddress;
      selectedDeliveryId: string | null;
      items: IOrderItem[];
      shopId: string;
      couponCode: string | null;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { shippingAddress, items, shopId, selectedDeliveryId, couponCode } = input;

    const { country, city, streetAddress, fullName, postalCode, phoneNumber } = shippingAddress;

    const { token = '' } = state.user;

    const parsedItems = parseItemsToRequestOrderItems(items);

    return api.requestOrder(
      token,
      parsedItems,
      shopId,
      city,
      streetAddress,
      country,
      postalCode,
      fullName,
      phoneNumber,
      selectedDeliveryId,
      couponCode,
    );
  },
);

export const addShippingCost = createAsyncThunk(
  'async/addShippingCost',
  async (
    input: {
      id: string;
      items: IOrderItem[];
      shippingPrice: number;
      username: string;
      avatar: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { items, shippingPrice, id } = input;

    const { token = '' } = state.user;

    const parsedItems = parseItemsToUpdateStatusRequest(items);

    const body = {
      status: OrderStatuses.Offered,
      items: parsedItems,
      shippingCost: Math.floor(shippingPrice * 100),
    };

    return api.updateOrderStatus(token, id, body);
  },
);

export const markPaymentAsReceived = createAsyncThunk(
  'async/markPaymentAsReceived',
  async (input: { id: string; username: string; avatar: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    const body = { status: OrderStatuses.PaymentReceived };

    return api.updateOrderStatus(token, id, body);
  },
);

export const acceptOrder = createAsyncThunk(
  'async/acceptOrder',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    const body = { status: OrderStatuses.PaymentReceived };

    return api.updateOrderStatus(token, id, body);
  },
);

export const markPaymentAsSent = createAsyncThunk(
  'async/markPaymentAsSent',
  async (input: { id: string; username: string; avatar: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    const body = { status: OrderStatuses.PaymentSent };

    return api.updateOrderStatus(token, id, body);
  },
);

export const checkTrackingNumber = createAsyncThunk(
  'async/checkTrackingNumber',
  async (input: { trackingNumber: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { trackingNumber } = input;

    const { token = '' } = state.user;

    return api.checkTrackingNumber(token, trackingNumber);
  },
);

export const saveTrackingNumber = createAsyncThunk(
  'async/saveTrackingNumber',
  async (
    input: {
      id: string;
      trackingNumber: string;
      courierCode: string;
      avatar: string;
      username: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { trackingNumber, id, courierCode } = input;

    const { token = '' } = state.user;

    const body = { status: OrderStatuses.Shipped, trackingNumber, courierCode };

    return api.updateOrderStatus(token, id, body);
  },
);

export const getTrackingInformation = createAsyncThunk(
  'async/getTrackingInformation',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.getTrackingInformation(token, id);
  },
);

export const declineOrder = createAsyncThunk(
  'async/declineOrder',
  async (
    input: { orderId: string; declineReason: string; isSeller: boolean },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { orderId, declineReason } = input;

    const { token = '' } = state.user;

    const body: IUpdateOrderStatusBody = {
      status: OrderStatuses.Decline,
      declineReason,
    };

    return api.updateOrderStatus(token, orderId, body);
  },
);

export const markOrderAsArrived = createAsyncThunk(
  'async/markOrderAsArrived',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { orderId } = input;

    const { token = '' } = state.user;

    const body: IUpdateOrderStatusBody = {
      status: OrderStatuses.Complete,
    };

    return api.updateOrderStatus(token, orderId, body);
  },
);

export const getOrderDigitalFiles = createAsyncThunk(
  'async/getOrderDigitalFiles',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { orderId } = input;

    const { token = '' } = state.user;

    return api.getOrderDigitalFiles(token, orderId);
  },
);

export const sendEmailDigitalFiles = createAsyncThunk(
  'async/sendEmailDigitalFiles',
  async (input: { orderId: string; email: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { orderId, email } = input;

    const { token = '' } = state.user;

    return api.sendEmailDigitalFiles(token, orderId, email);
  },
);

export const validateBuyerShippingAddress = createAsyncThunk(
  'async/validateBuyerShippingAddress',
  async (_input: { orderId: string }, { getState, rejectWithValue }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { streetAddress, country, city, postalCode } = state.shipping.shippingAddressForm;

    try {
      return await api.validateBuyerShippingAddress(
        token,
        country,
        city,
        streetAddress,
        postalCode,
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
