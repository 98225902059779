import { createSelector } from '@reduxjs/toolkit';

import { IStore2, NotificationsTabs } from 'types';
import { isEmptyArray } from 'utils';
import { getTotalTodayNotifications, getTotalYesterdayNotifications } from './helper';
import { translations } from 'translations/en';

const { NEW, EARLIER } = translations.NOTIFICATIONS;

const { YESTERDAY, TODAY } = translations.COMMON;

const activeTabSelector = (state: IStore2) => state.notifications.activeTab;

const isLoadingSelector = (state: IStore2) => state.notifications.isLoading;

const allNotificationsSelector = (state: IStore2) => state.notifications.allNotifications;

const orderNotificationsSelector = (state: IStore2) => state.notifications.orderNotifications;

const notificationsListSelector = createSelector(
  [activeTabSelector, allNotificationsSelector, orderNotificationsSelector],
  (activeTab, allNotifications, orderNotifications) => {
    if (activeTab === NotificationsTabs.All && allNotifications) {
      return [...allNotifications.newNotifications, ...allNotifications.viewedNotifications];
    }

    if (activeTab === NotificationsTabs.Orders && orderNotifications) {
      return [...orderNotifications.newNotifications, ...orderNotifications.viewedNotifications];
    }

    return null;
  },
);

const groupCountsSelector = createSelector(
  [activeTabSelector, allNotificationsSelector, orderNotificationsSelector, isLoadingSelector],
  (activeTab, allNotifications, orderNotifications, isLoading) => {
    let notifications;
    if (activeTab === NotificationsTabs.All && allNotifications) {
      notifications = allNotifications;
    }

    if (activeTab === NotificationsTabs.Orders && orderNotifications) {
      notifications = orderNotifications;
    }

    if (notifications) {
      const totalToday = getTotalTodayNotifications(notifications.viewedNotifications);

      const totalYesterday = getTotalYesterdayNotifications(notifications.viewedNotifications);

      const TOTAL_SKELETONS = isLoading ? 3 : 0;

      const totalEarlier =
        notifications.viewedNotifications.length - totalToday - totalYesterday + TOTAL_SKELETONS;

      const counts: { total: number; text: string }[] = [];

      if (totalEarlier > 0) {
        counts.push({ total: totalEarlier, text: EARLIER });
      }
      if (totalYesterday > 0) {
        counts.unshift({ total: totalYesterday, text: YESTERDAY });
      }

      if (totalToday > 0) {
        counts.unshift({ total: totalToday, text: TODAY });
      }

      if (!isEmptyArray(notifications.newNotifications)) {
        counts.unshift({ total: notifications.newNotifications.length, text: NEW });
      }

      return counts;
    }

    const DEFAULT_TOTAL = isLoading ? 3 : 0;

    return [{ total: DEFAULT_TOTAL, text: NEW }];
  },
);

const hasMoreNotificationsSelector = createSelector(
  [activeTabSelector, allNotificationsSelector, orderNotificationsSelector],
  (activeTab, allNotifications, orderNotifications) => {
    if (activeTab === NotificationsTabs.All) {
      return !!allNotifications?.hasMore;
    }

    return !!orderNotifications?.hasMore;
  },
);

export const NotificationsSelector = {
  hasMoreNotificationsSelector,
  notificationsListSelector,
  groupCountsSelector,
};
