import { ChangeEvent, ReactElement, ReactNode } from 'react';

import {
  CreateShopSteps,
  ICreateShopCatalogItem,
  IImageContainer,
  ILegacyPayoutMethod,
  IPayoutMethod,
  MessageIds,
  PayoutMethods,
  PaypalErrorResponse,
  ServerStatus,
  IShippingProfile,
  ReturnPolicyType,
  ListingOption,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';

export * from './popups/types';

export * from './createShopPopup/types';

export interface ICreateShop {
  step: CreateShopSteps;
  serverStatus: ServerStatus | null;
  shouldDisplayAnimation: boolean;
  shopId: string | null;
  isSeller: boolean;
  isFromOnboarding: boolean;
  isDraft: boolean;
  shouldNavigateBack: boolean;
  onRefreshPaypal(input: { merchantId: string; merchantIdInPayPal: string; id: string }): void;
  getShopInformation(): void;
  showPopup(options: { id: string; isSpecificSection?: boolean }): void;
  showMessage(options: { id: MessageIds }): void;
  reset(): void;
  resetServerStatus(): void;
  onBack(): void;
  onContinue(): void;
  createShop(): void;
}

export interface ICreateShopPopup {
  hidePopup(): void;
}

export interface IBackgroundContainer {
  title: string;
  description?: string;
  header: ReactElement | null;
  footer: ReactElement | null;
}

export interface IContentContainer {
  children: ReactNode;
  stepNumber: number;
  footer: ReactNode;
  isSpecificStep: boolean;
  onExitClick(): void;
}

export interface IBottomBar {
  onRightButtonClick(): void;
  onLeftButtonClick?(): void;
  isContinueDisabled: boolean;
  rightButtonText?: string;
  leftButtonText?: string;
}

export interface IInitialStepForm {
  setIsContinueDisabled(isContinueDisabled: boolean): void;
  updateShopName(input: { value: string }): void;
  updateShopDescription(input: { value: string }): void;
  onContinue(): void;
  shopName: string;
  shopDescription: string;
}

export interface IKeyword {
  value: string;
  onRemoveClick(): void;
}

export interface IShopInfoForm {
  country: Countries;
  currencyCode: CurrencyCode;
  onContinue(): void;
  updateCountry(input: { value: Countries }): void;
  updateCurrencyCode(input: { value: CurrencyCode }): void;
}

export interface IContactDetailsForm {
  onContinue(): void;
  updatePhoneNumber(input: { value: string }): void;
  updateEmail(input: { value: string }): void;
  setIsContinueDisabled(isDisabled: boolean): void;
  phoneNumber: string;
  email: string;
}

export interface IListingForm {
  catalogItem: ICreateShopCatalogItem;
  listingOption: ListingOption;
  currencyCode: CurrencyCode;
  totalShippingProfiles: number;
  shopId: string | null;
  shippingProfile: IShippingProfile | null;
  updateIsMetric(input: { isMetric: boolean }): void;
  showPopup(input: { id: string }): void;
  onClick(input: { item: ICreateShopCatalogItem; shopId: string | null }): void;
  setIsContinueDisabled(isContinueDisabled: boolean): void;
  onContinue(): void;
}

export interface IAddImageContainer {
  image: string;
  label: string;
  listingImage: string | null;
  index: number;
  images: IImageContainer[];
  onAddImage(input: { listingImages: string[] }): void;
  onRemoveImage(input: { index: number }): void;
}

export interface IShippoForm {
  isShippoLinked: boolean;
  shippoUrl: string | null;
  isLinkLoading: boolean;
  onContinueClick(isConnected: boolean): void;
  updateShippoConnection(payload: { isShippoConnected: boolean }): void;
  onLink(): void;
  onUnlink(): void;
}

export interface IPayoutsForm {
  isEditShop: boolean;
  payoutMethods: IPayoutMethod[];
  totalAvailablePayoutMethods: number;
  legacyPayoutMethods: ILegacyPayoutMethod[];
  payoutLinkToNavigate: string | null;
  billingCountry: string;
  unconnectedPaymentMethods: PayoutMethods[];
  isPaypalLinkLoading: boolean;
  isStripeLinkLoading: boolean;
  isLegacyPayoutsAvailable: boolean;
  onManualPayoutsClick(): void;
  updateBillingCountry(input: { billingCountry: string }): void;
  setIsContinueDisabled(isContinueDisabled: boolean): void;
  resetPayoutLink(): void;
  showPopup(input: {
    id: string;
    payoutType?: PayoutMethods;
    link?: string;
    paypalErrorType?: PaypalErrorResponse;
  }): void;
  linkPayoutAccount(input: { payout: PayoutMethods }): void;
  getPayouts(): void;
  onClick(isMoreUnconnected: boolean): void;
}

export interface ILegacyPaymentMethodsForm {
  paymentMethods: ILegacyPayoutMethod[];
  setIsContinueDisabled(isContinueDisabled: boolean): void;
  onClick(): void;
  updatePaymentMethods(input: { paymentMethods: ILegacyPayoutMethod[] }): void;
  addPayment(): void;
  show(input: { id: string }): void;
}

export interface IDropDownSelectOption {
  label: ReactNode;
  value: string | string[];
}

export enum PayoutMenuOptions {
  Deactivate = 'Deactivate',
}

export enum ShippingMenuOptions {
  Deactivate = 'Deactivate',
}

export enum EditPaymentSources {
  EditShop = 'editShop',
  CreateShop = 'createShop',
  Popup = 'drawer',
}

export interface IConnectedPayoutContainer {
  type: PayoutMethods;
  email: string | null;
  isActive: boolean;
  onDeactivateClick(type: PayoutMethods): void;
}

export interface IPayoutContainer {
  type: PayoutMethods;
  isLoading: boolean;
  onConnectClick(type: PayoutMethods): void;
}

export interface ICustomsInformationSection {
  tariffNumber: string;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export interface IReturnsPolicySection {
  returnPolicy: ReturnPolicyType | null;
  shouldShowReturnPolicyError: boolean;
  onReturnsPolicyClick(): void;
}

export interface IShippingProfileSection {
  shouldShowShippingProfileError: boolean;
  shippingProfile: IShippingProfile | null;
  onAddClick(): void;
}
