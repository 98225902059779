import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { MILLISECONDS } from 'constant';
import { translations } from 'translations/en';

dayjs.extend(localizedFormat);

export const isSameDay = (date1: number, date2: number) => dayjs(date1).isSame(date2, 'days');

export const isSameHour = (date1: number, date2: number) => dayjs(date1).isSame(date2, 'hours');

export const getDayInWeek = (date: number) => dayjs(date).format('dddd');

export const isThisWeek = (date: number) => dayjs().isSame(date, 'week');

export const isToday = (date: number) => dayjs().isSame(date, 'day');

// 04/10/2023
export const toDateFormat = (date: number) => dayjs(date).format('L');

// July 31, 2023
export const toDateFormatWithText = (date: number) => dayjs(date).format('ll');

export const toTimeDateFormat = (date: number) => {
  if (isToday(date)) {
    return dayjs(date).format('hh:mm a');
  }

  return dayjs(date).format('L');
};

// Aug 16
export const toMonthAndDaysFormat = (date: number): string => {
  return dayjs(date).format('MMM DD');
};

// hh:mm on dd/mm/yy
export const toFullDateFormat = (date: number) => {
  const hour = dayjs(date).format('hh:mm a');

  const day = dayjs(date).format('DD/MM/YY');

  return `${hour} on ${day}`;
};

// Thursday, 04 Aug
export const toWeekDayAndDateFormat = (date: number) => {
  return dayjs(date).format('dddd, DD MMM');
};

export const toTimeFormat = (date: number) => {
  return dayjs(date).format('hh:mm a');
};

export const isYesterday = (date: number) => {
  const yesterday = dayjs().subtract(1, 'days').valueOf();

  return isSameDay(yesterday, date);
};

export const getDiffInUnit = (from: number, to: number, unitOfTime: QUnitType | OpUnitType) =>
  dayjs(to).diff(from, unitOfTime);

export const getYear = () => dayjs().get('year');

export const isLastTenSeconds = (date: number) => {
  return Date.now() - date < 10000;
};

export const getRelativeTime = (date: number) => {
  const { TODAY, YESTERDAY } = translations.CHAT;

  if (isToday(date)) {
    return TODAY;
  }
  if (isYesterday(date)) {
    return YESTERDAY;
  }
  if (isThisWeek(date)) {
    return getDayInWeek(date);
  }

  return toDateFormat(date);
};

export const getShortRelativeTime = (date: number) => {
  const now = Date.now();

  const diff = dayjs(now).diff(date);

  let formattedDate: string;

  if (diff < MILLISECONDS.MINUTE) {
    formattedDate = '1m';
  } else if (diff < MILLISECONDS.HOUR) {
    const minutes = getDiffInUnit(date, now, 'minute');
    formattedDate = `${minutes}m`;
  } else if (diff < MILLISECONDS.DAY) {
    const hours = getDiffInUnit(date, now, 'hour');
    formattedDate = `${hours}h`;
  } else if (diff < MILLISECONDS.WEEK) {
    const days = getDiffInUnit(date, now, 'day');
    formattedDate = `${days}d`;
  } else if (diff < MILLISECONDS.MONTH) {
    const weeks = getDiffInUnit(date, now, 'week');
    formattedDate = `${weeks}w`;
  } else if (diff < MILLISECONDS.YEAR) {
    const months = getDiffInUnit(date, now, 'month');
    formattedDate = `${months}mo`;
  } else {
    const years = getDiffInUnit(date, now, 'year');
    formattedDate = `${years}y`;
  }

  return formattedDate;
};

export const getDateDiffYears = (timestamp1: number, timestamp2: number): number => {
  const date1 = new Date(timestamp1);

  const date2 = new Date(timestamp2);

  const difference = date2.getTime() - date1.getTime();

  return Math.floor(difference / MILLISECONDS.YEAR);
};

export const getDateDiffDays = (timestamp1: number, timestamp2: number): number => {
  const date1 = new Date(timestamp1);

  const date2 = new Date(timestamp2);

  const difference = date2.getTime() - date1.getTime();

  return Math.floor(difference / MILLISECONDS.DAY);
};

export const addYearsToDate = (dateInMillis: number, yearsToAdd: number): number => {
  const date = dayjs(dateInMillis);

  const newDate = date.add(yearsToAdd, 'years');

  return newDate.valueOf();
};

export const getConsecutiveDates = (days: number): string[] => {
  const dates: string[] = [];

  const currentDate = dayjs().startOf('day');

  for (let i = 0; i < days; i++) {
    dates.push(currentDate.clone().add(i, 'days').format('ddd MMM D'));
  }

  return dates;
};

export const getConsecutiveDatesAsNumbers = (daysFromNow: number): string[] => {
  const dates: number[] = [];

  const currentDate = dayjs().startOf('day');

  for (let i = 0; i < daysFromNow; i++) {
    dates.push(currentDate.clone().add(i, 'days').valueOf());
  }

  return dates.map((date) => date.toString());
};

export const getDateInNDaysFromNow = (daysFromNow: number): string => {
  return dayjs().add(daysFromNow, 'days').format('MMM D, YYYY');
};

export const getDateBeforeDays = (daysBefore: number): number => {
  const currentDate = dayjs();

  const pastDate = currentDate.clone().subtract(daysBefore, 'days');

  return pastDate.valueOf();
};

export const formatTimeStampToMilliseconds = (time: string): number => {
  return dayjs(time).valueOf();
};

export const getCountdownTime = (date: number) => {
  const now = dayjs();

  const futureDate = dayjs(date);

  const days = futureDate.diff(now, 'day', true);

  if (days < 1) {
    const hoursDiff = futureDate.diff(now, 'hour', false);

    return `${hoursDiff} hours left`;
  }

  return `${Math.floor(days)} days left`;
};
