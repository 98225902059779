import { createSlice } from '@reduxjs/toolkit';

import { getAppConfig } from 'store/user/UserActions';
import { parseTip } from 'store/apiParser';
import { IConfigState } from 'types';

const initialState: IConfigState = {
  introPopupKey: null,
  introPopupDetails: {},
  featureFlags: [],
  shouldDisplayVisionsBuyerRequests: false,
  tips: [],
};

export const ConfigSlicer = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppConfig.fulfilled, (state, action) => {
      const { showDrawer, tips, popupDetails, shouldDisplayBuyerRequests, featureFlags } =
        action.payload;
      state.shouldDisplayVisionsBuyerRequests = !!shouldDisplayBuyerRequests;
      state.introPopupKey = showDrawer || popupDetails?.key || null;
      state.introPopupDetails = popupDetails?.details || {};
      state.tips = parseTip(tips);
      state.featureFlags = featureFlags || [];
    });
  },
});
