import {
  IUserOrdersResponse,
  IGetShopViewersResponse,
  IOrdersApi,
  IReviewOrderResponse,
  ILoadMoreOrdersResponse,
  LoadMoreTypes,
  Method,
} from 'types';
import { fetcher } from 'api';

export class OrdersApi implements IOrdersApi {
  getSellerOrders(token: string): Promise<IUserOrdersResponse> {
    return fetcher('orders/v2/initial/seller', Method.GET, token);
  }

  getBuyerOrders(token: string): Promise<IUserOrdersResponse> {
    return fetcher('orders/v2/initial/buyer', Method.GET, token);
  }

  getShopViewers(token: string, shop: string, maxTime?: number): Promise<IGetShopViewersResponse> {
    const query = {
      maxTime,
      shop,
    };

    return fetcher('services/profileviews', Method.GET, token, undefined, query);
  }

  withdrawOrder(token: string, orderId: string): Promise<any> {
    return fetcher(`orders/v2/${orderId}`, Method.DELETE, token);
  }

  reviewOrder(
    token: string,
    shopId: string,
    title: string,
    description: string,
    stars: number,
    images: string[],
  ): Promise<IReviewOrderResponse> {
    const body = {
      title,
      description,
      stars,
      images,
    };

    return fetcher(`v2/review/${shopId}`, Method.POST, token, body);
  }

  loadMoreSellerOrders(
    mode: LoadMoreTypes,
    maxTime: number,
    token: string,
  ): Promise<ILoadMoreOrdersResponse> {
    const query = {
      mode,
      maxTime,
    };

    return fetcher('orders/v2/seller', Method.GET, token, undefined, query);
  }

  loadMoreBuyerOrders(
    mode: LoadMoreTypes,
    maxTime: number,
    token: string,
  ): Promise<ILoadMoreOrdersResponse> {
    const query = {
      mode,
      maxTime,
    };

    return fetcher('orders/v2/buyer', Method.GET, token, undefined, query);
  }
}
