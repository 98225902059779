import { FC } from 'react';
import { Button } from 'tedooo-web-design-system/ui';
import { ArrowRight } from 'tedooo-web-design-system/icons';

const NextArrow: FC<any> = ({ currentSlide, slideCount, ...props }) => (
  <div {...{ ...props, currentslide: currentSlide, slidecount: slideCount }}>
    <Button
      type="secondary"
      size="medium"
      borderRadius="large"
      prefixIcon={<ArrowRight />}
      className="slider-button"
    />
  </div>
);

export const FeedBubblesCarouselSettings = {
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
  slidesToShow: 5,
  className: 'keyword-slider',
  dots: false,
  infinite: false,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};
