import {
  GetMojoCommentsResponse,
  GetMojoLinkResponse,
  IdableResponse,
  PaginatedWhoLikedCommentResponse,
  SuccessResponse,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  COMMENT_MOJO_MUTATION,
  DELETE_COMMENT_MUTATION,
  DELETE_MOJO_MUTATION,
  GET_MOJO_COMMENT_LIKES_QUERY,
  GET_MOJO_COMMENTS_QUERY,
  GET_MOJO_LINK_QUERY,
  LIKE_COMMENT_TOGGLE_MUTATION,
  LIKE_MOJO_MUTATION,
  REPORT_MOJO_MUTATION,
} from '../MojosQueries';
import { IMojoApi } from 'types';

export class MojoApi implements IMojoApi {
  async onMojoLikeToggle(
    postId: string,
    isLiked: boolean,
    token: string,
  ): Promise<SuccessResponse> {
    const variables = {
      id: postId,
      like: !isLiked,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: LIKE_MOJO_MUTATION,
      variables,
    });

    return res.data.setLikeMojo;
  }

  async onMojoDelete(postId: string, token: string): Promise<SuccessResponse> {
    const variables = {
      id: postId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: DELETE_MOJO_MUTATION,
      variables,
    });

    return res.data.deleteMojo;
  }

  async getMojoLink(postId: string, token?: string): Promise<GetMojoLinkResponse> {
    const variables = { id: postId };

    const res = await graphQlRequest(token).query({
      query: GET_MOJO_LINK_QUERY,
      variables,
    });

    return res.data.getMojoLink;
  }

  async addComment(
    token: string,
    mojoId: string,
    text: string,
    tags: string[],
    image?: string,
    parentCommentId?: string,
  ): Promise<IdableResponse> {
    const variables = { mojoId, text, image, tags, replyToId: parentCommentId };

    const res = await graphQlRequest(token).mutate({
      mutation: COMMENT_MOJO_MUTATION,
      variables,
    });

    return res.data.postMojoComment;
  }

  async onMojoCommentLikeToggle(
    token: string,
    commentId: string,
    isLiked: boolean,
  ): Promise<SuccessResponse> {
    const variables = { commentId, like: isLiked };

    const res = await graphQlRequest(token).mutate({
      mutation: LIKE_COMMENT_TOGGLE_MUTATION,
      variables,
    });

    return res.data.setLikeMojoComment;
  }

  async deleteMojoComment(token: string, commentId: string): Promise<SuccessResponse> {
    const variables = { commentId };

    const res = await graphQlRequest(token).mutate({
      mutation: DELETE_COMMENT_MUTATION,
      variables,
    });

    return res.data.deleteMojoComment;
  }

  async reportMojo(token: string, commentId: string): Promise<SuccessResponse> {
    const variables = { commentId };

    const res = await graphQlRequest(token).mutate({
      mutation: REPORT_MOJO_MUTATION,
      variables,
    });

    return res.data.reportMojo;
  }

  async getComments(
    token: string,
    id: string,
    paginationToken: string | null,
    parentCommentId?: string,
  ): Promise<GetMojoCommentsResponse> {
    const variables = { id, repliesFor: parentCommentId, paginationToken };

    const res = await graphQlRequest(token).query({
      query: GET_MOJO_COMMENTS_QUERY,
      variables,
    });

    return res.data.getMojoComments;
  }

  async getCommentLikes(
    token: string,
    commentId: string,
    paginationToken: string | null,
  ): Promise<PaginatedWhoLikedCommentResponse> {
    const variables = { commentId, paginationToken };

    const res = await graphQlRequest(token).query({
      query: GET_MOJO_COMMENT_LIKES_QUERY,
      variables,
    });

    return res.data.getWhoLikedComment;
  }
}
