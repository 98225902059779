import { FC } from 'react';
import classNames from 'classnames';

import { Image } from 'components/helpers';
import './ImagesGrid.scss';
import { ImageComposition } from 'types';
import { mapCompositionToClassName } from './helper';

interface IImageGrid {
  imageComposition: ImageComposition;
  images: Array<string>;
  withOverlay: boolean;
  onClick?: (index: number) => void;
  onMoreClick(): void;
}

const ImagesGrid: FC<IImageGrid> = ({
  images,
  withOverlay,
  onMoreClick,
  onClick,
  imageComposition,
}) => {
  const MAX_DISPLAYED_IMAGES = Math.floor(imageComposition / 100);

  const totalImages = images.length;

  const isImagesOverflow = totalImages > MAX_DISPLAYED_IMAGES;

  const moreText = `+${totalImages - MAX_DISPLAYED_IMAGES}`;

  const onImageClick = (index: number) => {
    if (onClick) {
      onClick(index);
    }
  };

  const renderImage = (index: number) => {
    if (!images[index] || index >= MAX_DISPLAYED_IMAGES) {
      return null;
    }

    const isLastShownImage = index === MAX_DISPLAYED_IMAGES - 1;

    return (
      <div className={classNames('image-container', { clickable: !!onClick })}>
        <Image withOverlay={withOverlay} src={images[index]} onClick={() => onImageClick(index)} />
        {isImagesOverflow && (
          <div
            className={classNames('more-button', { active: isLastShownImage })}
            onClick={onMoreClick}
          >
            {moreText}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames('image-grid-container', mapCompositionToClassName[imageComposition])}
    >
      {renderImage(0)}
      {renderImage(1)}
      {renderImage(2)}
      {renderImage(3)}
      {renderImage(4)}
    </div>
  );
};

export default ImagesGrid;
