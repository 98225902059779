import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import LinkMessage from 'components/message/linkMessage/LinkMessage';
import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { MessageIds, ChatErrorMessageTypes } from 'types';
import CreateShopErrorMessage from 'pages/createShop/createShopErrorMessage/CreateShopErrorMessage';
import JoinGroupRequest from 'pages/searchPage/joinGroupMessages/JoinGroupRequest';
import ChangePasswordMessage from 'pages/settings/changePasswordMessage/ChangePasswordMessage';
import DeletePostMessage from 'components/post/deletePostMessage/DeletePostMessage';
import { MessageSlicer } from 'store/message/MessageActions';
import ChatErrorMessage from 'pages/chatv2/notifications/ChatErrorMessage';
import RepostMessage from 'components/post/repostMessages/RepostMessage';
import UndoRepostMessage from 'components/post/repostMessages/UndoRepostMessage';
import HideFromActivityMessage from 'components/post/hideFromActivityMessage/hideFromActivityMessage';
import ChangeCurrencyMessage from 'pages/settings/changeCurrencyMessage/ChangeCurrencyMessage';
import OrderReviewMessage from 'pages/orders/popups/orderReviewPopup/orderReviewMessage/OrderReviewMessage';
import EmailSentMessage from 'pages/orders/popups/purchasedLabelPopup/emailSentMessage/EmailSentMessage';
import RequestOrderErrorMessage from 'pages/shopPage/popups/requestToBuyPopup/RequestOrderErrorMessage';
import FailedToBuyShippingLabelMessage from 'pages/orders/popups/editBuyerShippingAddressPopup/FailedToBuyShippingLabelMessage';
import ToggleFavoritesMessage from 'pages/specificProductPage/toggleFavoriteMessage/ToggleFavoritesMessage';
import VacationModeMessage from 'pages/settings/popups/vacationModePopup/VacationModeMessage';
import GenerateVisionErrorMessage from 'pages/visionsPage/GenerateVisionErrorMessage';
import RemoveFromBasketMessage from '../../pages/basketPage/RemoveFromBasketMessage';

const Message = () => {
  const id = useTedoooSelector(MessageSelector.selectMessageIdSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(MessageSlicer.actions.hide());
    };
  }, [id]);

  return (
    <>
      {id === MessageIds.createShopError && <CreateShopErrorMessage />}
      {id === MessageIds.linkCopied && <LinkMessage />}
      {id === MessageIds.joinGroupRequest && <JoinGroupRequest />}
      {id === MessageIds.joinGroupInvitationError && (
        <ChatErrorMessage type={ChatErrorMessageTypes.GroupInvite} />
      )}
      {id === MessageIds.leaveGroupError && (
        <ChatErrorMessage type={ChatErrorMessageTypes.LeaveGroup} />
      )}
      {id === MessageIds.changePassword && <ChangePasswordMessage />}
      {id === MessageIds.deletePost && <DeletePostMessage />}
      {id === MessageIds.repost && <RepostMessage />}
      {id === MessageIds.undoRepost && <UndoRepostMessage />}
      {id === MessageIds.hideFromActivity && <HideFromActivityMessage />}
      {id === MessageIds.changeCurrency && <ChangeCurrencyMessage />}
      {id === MessageIds.orderReviewed && <OrderReviewMessage />}
      {id === MessageIds.emailSent && <EmailSentMessage />}
      {id === MessageIds.requestOrderError && <RequestOrderErrorMessage />}
      {id === MessageIds.failedToBuyShippingLabel && <FailedToBuyShippingLabelMessage />}
      {id === MessageIds.toggleFavoriteProduct && <ToggleFavoritesMessage />}
      {id === MessageIds.vacationModeSuccess && <VacationModeMessage />}
      {id === MessageIds.generateVisionError && <GenerateVisionErrorMessage />}
      {id === MessageIds.removeItemFromBasket && <RemoveFromBasketMessage />}
    </>
  );
};

export default Message;
