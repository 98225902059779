import { Middleware } from 'redux';

import {
  createGroup,
  getBothChatImages,
  getBothMessages,
  getGroupInformation,
  getInitialMessages,
  getOlderMessages,
  getShopInformation,
  inviteUsers,
  joinGroup,
  onChatSearch,
  onLeaveGroup,
  onReportUser,
  reportMessage,
} from './ChatActions';
import { IChatOption, IStore2, MessageIds, popupIdsOptions } from 'types';
import { UserSlicer } from 'store/user/UserActions';
import { ChatSlicer } from './ChatSlicer';
import { PopupSlicer } from 'store/popup/PopupActions';
import { getMessageMissingShopOrGroupId, getShopsAndGroups } from './apiParser';
import { MessageSlicer } from '../message/MessageActions';
import { PopupsSrv } from 'services/PopupsSrv';

export const chatMiddleware: Middleware =
  ({ dispatch, getState }: any) =>
  (next) =>
  (action = {}) => {
    switch (action?.type) {
      case createGroup.fulfilled.type: {
        const groupId = action?.payload?._id || '';
        dispatch(inviteUsers({ groupId }));
        break;
      }
      case onChatSearch.fulfilled.type:
      case getOlderMessages.fulfilled.type:
      case getInitialMessages.fulfilled.type:
      case getBothMessages.fulfilled.type: {
        const details = action?.payload?.data?.messages || action?.payload?.data || [];

        const store: IStore2 = getState();

        const { shops: currentShops, groups: currentGroups } = store.chat;

        const { shops, groups } = getShopsAndGroups(details, currentShops, currentGroups);
        shops.map((id) => dispatch(getShopInformation({ id })));
        groups.map((group) =>
          dispatch(
            getGroupInformation({
              id: group.id,
              isLink: group.isLink,
            }),
          ),
        );
        break;
      }

      case ChatSlicer.actions.onMessageSend.type:
      case ChatSlicer.actions.onMessageReceived.type: {
        const store: IStore2 = getState();

        const { message } = action.payload;

        const { shops: existsShops, groups: existsGroups } = store.chat;

        const text = message?.message || message?.text;

        const missingShopOrGroup = getMessageMissingShopOrGroupId(
          text,
          message?.type,
          existsShops,
          existsGroups,
        );
        if (missingShopOrGroup) {
          if (missingShopOrGroup.type === 'group') {
            dispatch(
              getGroupInformation({
                id: missingShopOrGroup.id,
                isLink: missingShopOrGroup.isLink,
              }),
            );
          } else {
            dispatch(getShopInformation({ id: missingShopOrGroup.id }));
          }
        }
        break;
      }
      case UserSlicer.actions.signOut.type:
        dispatch(ChatSlicer.actions.reset());
        break;
      case joinGroup.rejected.type: {
        dispatch(MessageSlicer.actions.show({ id: MessageIds.joinGroupInvitationError }));
        break;
      }
      case onLeaveGroup.rejected.type: {
        dispatch(MessageSlicer.actions.show({ id: MessageIds.leaveGroupError }));
        break;
      }
      case ChatSlicer.actions.onSearchNextClick.type:
        const store4: IStore2 = getState();

        const { id: userId, username, fullName } = store4.user;

        const { selectedChat: currentChat } = store4.chat;

        if (currentChat) {
          const { messagesBatches, currentMessageBatchIndex, hasMore } = currentChat.searchMessages;

          const currentBatch = messagesBatches[currentMessageBatchIndex];

          const isLastItemInBatch =
            currentBatch.currentIndex === currentBatch.searchMessageIndexes.length - 1;

          const isLastBatch = currentMessageBatchIndex === messagesBatches.length - 1;

          if (isLastItemInBatch && isLastBatch && hasMore) {
            dispatch(
              onChatSearch({
                loggedInUserFullName: fullName || '',
                loggedInUserId: userId || '',
                loggedInUsername: username || '',
              }),
            );
          }
        }

        break;

      case PopupSlicer.actions.hide.type:
        const store2: IStore2 = getState();

        const { ids } = store2.popup;

        if (PopupsSrv.checkIfDisplayed(popupIdsOptions.deleteMessagePopup, ids)) {
          dispatch(ChatSlicer.actions.onMessagesSelectionClose());
        }

        if (
          PopupsSrv.checkIfDisplayed(popupIdsOptions.chatImagePreviewPopup, ids) &&
          ids.length === 1
        ) {
          dispatch(ChatSlicer.actions.resetChatMedia());
        }
        break;

      case PopupSlicer.actions.show.type:
        const store3: IStore2 = getState();

        const { id, mediaItem } = action.payload;

        const { selectedChat } = store3.chat;

        const messages = selectedChat?.messages || [];

        const media = selectedChat?.media.list || [];

        const selectedMessageDate =
          messages.find((textMessage) => textMessage.images.includes(mediaItem?.source))?.date ||
          media.find((image) => image.source.includes(mediaItem?.source))?.date;
        if (id === popupIdsOptions.chatImagePreviewPopup) {
          const { username: loggedInUsername, id: loggedInUserId } = store3.user;
          dispatch(
            getBothChatImages({
              date: selectedMessageDate || 0,
              loggedInUsername: loggedInUsername || '',
              loggedInUserId: loggedInUserId || '',
            }),
          );
        }
        break;
      case onReportUser.fulfilled.type: {
        const state: IStore2 = getState();

        const { shouldBlockUser } = state.popup;

        dispatch(PopupSlicer.actions.hide());

        const isUserBlocked = !!state.chat.selectedChat?.isBlocked;

        dispatch(
          PopupSlicer.actions.show({
            id: popupIdsOptions.reportUserConfirmationPopup,
            isUserBlocked,
            shouldBlockUser,
          }),
        );
        break;
      }
      case ChatSlicer.actions.onMessagesDelete.type:
        dispatch(PopupSlicer.actions.hide());
        break;

      case reportMessage.fulfilled.type: {
        const { success: isSuccess } = action.payload;

        if (isSuccess) {
          dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.reportFileMessageSuccessPopup }));
        }
        break;
      }

      case getGroupInformation.fulfilled.type: {
        const store5: IStore2 = getState();

        const { id: groupId, shouldNavigateToChat } = action.meta.arg;

        const { _id: originalGroupId } = action.payload;

        const { chats } = store5.chat;

        const selectedGroup = chats?.find((chat: IChatOption) => chat.id === originalGroupId);

        if (shouldNavigateToChat) {
          if (selectedGroup) {
            dispatch(
              ChatSlicer.actions.setSelectedChat({
                id: selectedGroup.id,
                isGroup: true,
              }),
            );
          } else {
            dispatch(
              joinGroup({
                id: groupId,
                isLink: true,
              }),
            );
          }
        }
        break;
      }
      default:
        break;
    }

    return next(action);
  };
