import {
  IShippingApi,
  ILinkPayoutResponse,
  Method,
  IGetShippingServicesResponse,
  IValidateShippingAddressResponse,
  IShippingProfileRequest,
  IShippingRateRequestAddress,
  IGetShippingRatesResponse,
  IGetShippingLabelUrlsResponse,
  IGetShippingLabelDetailsResponse,
  IRecipientAddress,
  IGetShippingRateProduct,
  IGetBasketShippingRateResponse,
  IProductDimensions,
} from 'types';
import { fetcher } from 'api';
import { Countries } from 'constant/countries';
import { graphQlRequest } from 'api/graphQl';
import { GetShippingProfilesResponse } from 'graphql/generated/graphql';
import { GET_SHIPPING_PROFILES_QUERY } from './ShippingQueries';

export class ShippingApi implements IShippingApi {
  linkShippo(token: string): Promise<ILinkPayoutResponse> {
    return fetcher(`shippo/link`, Method.POST, token);
  }

  unlinkShippo(token: string): Promise<void> {
    return fetcher(`shippo/link`, Method.DELETE, token);
  }

  getShippingServices(
    token: string,
    shippingCountry: Countries,
    isCalculated: boolean,
  ): Promise<IGetShippingServicesResponse> {
    const calculated = isCalculated ? 1 : undefined;

    const query = {
      fromCountry: shippingCountry,
      calculated,
    };

    return fetcher(`shipping/services`, Method.GET, token, undefined, query);
  }

  validateShippingAddress(
    token: string,
    country: string,
    city: string,
    address: string,
    postalCode: string,
  ): Promise<IValidateShippingAddressResponse> {
    const body = {
      country,
      city,
      address,
      postalCode,
    };

    return fetcher(`shipping/validate`, Method.POST, token, body);
  }

  addShippingProfile(token: string, body: IShippingProfileRequest): Promise<{ id: string }> {
    return fetcher(`shipping/profiles`, Method.POST, token, body);
  }

  updateShippingProfile(token: string, id: string, body: IShippingProfileRequest): Promise<void> {
    return fetcher(`shipping/profiles/${id}`, Method.PATCH, token, body);
  }

  deleteShippingProfile(token: string, id: string): Promise<void> {
    return fetcher(`shipping/profiles/${id}`, Method.DELETE, token);
  }

  getLabelInformation(orderId: string, token: string): Promise<IGetShippingLabelDetailsResponse> {
    return fetcher(`shipping/label/purchase/info/${orderId}`, Method.GET, token);
  }

  getPackagesType(token: string): Promise<any> {
    return fetcher(`shipping/packages`, Method.GET, token);
  }

  buyShippingLabel(
    orderId: string,
    rateId: string,
    token: string,
  ): Promise<IGetShippingLabelUrlsResponse> {
    const body = {
      orderId,
      rateId,
    };

    return fetcher(`shipping/label/buy`, Method.POST, token, body);
  }

  getShippingLabelAndEmail(orderId: string, token: string): Promise<any> {
    return fetcher(`shipping/links/${orderId}`, Method.GET, token);
  }

  sendShippingLabelToEmail(orderId: string, token: string): Promise<any> {
    const body = {
      orderId,
    };

    return fetcher(`orders/label/email`, Method.POST, token, body);
  }

  getShippingRates(
    orderId: string,
    shipDate: number,
    fromAddress: IShippingRateRequestAddress,
    toAddress: IRecipientAddress,
    dimensions: IProductDimensions,
    withInsurance: boolean,
    withSignature: boolean,
    token: string,
  ): Promise<IGetShippingRatesResponse> {
    const body = {
      orderId,
      shipDate,
      fromAddress,
      toAddress,
      dimensions,
      withInsurance,
      withSignature,
    };

    return fetcher(`shipping/rates`, Method.POST, token, body);
  }

  getBasketShippingRates(
    token: string,
    products: IGetShippingRateProduct[],
  ): Promise<IGetBasketShippingRateResponse> {
    const body = {
      products,
    };

    return fetcher(`shipping/rates/basket`, Method.POST, token, body);
  }

  async getShippingProfiles(token: string): Promise<GetShippingProfilesResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_SHIPPING_PROFILES_QUERY,
    });

    return res.data.getShippingProfiles;
  }
}
