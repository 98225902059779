import { createAsyncThunk } from '@reduxjs/toolkit';

import { IShippingAddress } from 'types';
import { CheckoutApi } from './CheckoutApi';

const api = new CheckoutApi();

export const getShippingRatesForNonSignupUser = createAsyncThunk(
  'async/getShippingRatesForNonSignupUser',
  async (input: { checkoutId: string; shippingAddress: IShippingAddress }) => {
    const { checkoutId, shippingAddress } = input;

    return api.getShippingServicesForNonSignupUser(checkoutId, shippingAddress);
  },
);

export const getPaymentsForNonSignupUser = createAsyncThunk(
  'async/getPaymentsForNonSignupUser',
  async (input: { checkoutId: string; email: string; fullName: string }) => {
    const { checkoutId, fullName, email } = input;

    return api.getPaymentsForNonSignupUser(checkoutId, fullName, email);
  },
);
