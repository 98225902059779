import { FC } from 'react';
import './shopOfTheDayBanner.scss';
import { Button } from 'tedooo-web-design-system/ui';

import { translations } from 'translations/en';
import { IDiscoverSectionTitle } from 'types';
import SectionTitle from 'pages/discoverPage/modularComponents/sectionTitle/SectionTitle';

export interface IShopOfTheDayBanner {
  userId: string;
  shopId: string;
  titles: IDiscoverSectionTitle[];
  footerBackgroundColor: string;
  image: string;
  isFollowing: boolean;
  onFollowUserToggle(input: { userId: string; isFollowing: boolean }): void;
  onViewShopClick(shopId: string): void;
}

const ShopOfTheDayBanner: FC<IShopOfTheDayBanner> = ({
  shopId,
  userId,
  image,
  footerBackgroundColor,
  titles,
  isFollowing,
  onFollowUserToggle,
  onViewShopClick,
}) => {
  const buttonText = isFollowing ? translations.COMMON.VIEW : translations.COMMON.FOLLOW;

  const buttonType = isFollowing ? 'secondary' : 'primary';

  const onButtonClick = () => {
    if (isFollowing) {
      onViewShopClick(shopId);
    } else {
      onFollowUserToggle({ userId, isFollowing });
    }
  };

  return (
    <div className="shop-of-the-day-container section">
      <div style={{ backgroundImage: `url(${image})` }} className="image-container" />
      <div className="details-container" style={{ backgroundColor: footerBackgroundColor }}>
        <SectionTitle titles={titles} onListClick={() => {}} />
        <Button
          type={buttonType}
          size="small"
          borderRadius="large"
          text={buttonText}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default ShopOfTheDayBanner;
