import { FC, useState } from 'react';
import classNames from 'classnames';
import { Skeleton } from 'tedooo-web-design-system/ui';

import './imageWithLoader.scss';

export interface IImageWithLoader {
  src: string;
  className?: string;
  id?: string;
  onLoad?: () => void;
  onClick(e: any): void;
}

const ImageWithLoader: FC<IImageWithLoader> = ({ src, onLoad, id, className, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  const onImageLoaded = () => {
    if (onLoad) {
      onLoad();
    }
    setIsLoading(false);
  };

  return (
    <div className="image-with-loader-container" onClick={onClick}>
      {isLoading && <Skeleton title={false} avatar={{ shape: 'square' }} />}
      <img id={id} alt="" className={classNames([className])} onLoad={onImageLoaded} src={src} />
    </div>
  );
};

export default ImageWithLoader;
