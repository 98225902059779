import { track, identify, Identify } from '@amplitude/analytics-browser';

import { IAnalyticsService } from './types';
import { StorageSrv } from './StorageSrv';

const USER_ID = 'userId';

export const amplitudeHelpers: IAnalyticsService = {
  init: (distinctId: string | null) => {
    if (distinctId) {
      const identifyObj = new Identify();
      identifyObj.set(USER_ID, distinctId);
      identify(identifyObj);
    }

    // Conditional event tracking based on storage service
    if (StorageSrv.amplitude.shouldSendUserEvent()) {
      amplitudeHelpers.track('three_days_user');
    }
  },
  track: (event: string, properties?: Record<string, any>) => {
    track(event, properties);
  },
};
