import { forwardRef, Fragment } from 'react';
import { Virtuoso, VirtuosoGrid, VirtuosoHandle, GroupedVirtuoso } from 'react-virtuoso';
import { LoaderIndicator } from 'tedooo-web-design-system/ui';

import { IList } from './types';
import { isEmptyArray } from 'utils';

export const List = forwardRef<VirtuosoHandle, IList>(
  (
    {
      emptyState,
      loadMore,
      data,
      totalCount,
      onStartReached,
      hasMore,
      overscan,
      itemContent,
      scrollerContainer,
      isGrid,
      initialIndex,
      className,
      atBottomStateChange,
      onRangeChange,
      footer,
      firstItemIndex,
      isGroup,
      groupCounts,
      groupContent,
      topHasMore,
      topThreshold,
      disableStickyHeader,
    },
    ref,
  ) => {
    if (!data) {
      return <LoaderIndicator size={30} />;
    }
    if (isEmptyArray(data)) {
      return emptyState;
    }

    const onLoadMore = () => {
      if (hasMore) {
        loadMore();
      }
    };

    const listComponents: any = { Footer: footer };

    if (disableStickyHeader) {
      listComponents.TopItemList = (...props: any) => <Fragment key={props?.key || 'TOP_ITEM'} />;
    }

    const atTopStateChanged = (isTopReached: boolean) => {
      if (onStartReached && isTopReached && topHasMore) {
        onStartReached();
      }
    };
    if (isGrid) {
      return (
        <VirtuosoGrid
          customScrollParent={scrollerContainer || undefined}
          height="100%"
          totalCount={totalCount || 0}
          endReached={onLoadMore}
          overscan={overscan}
          itemContent={itemContent}
          listClassName={className}
          ref={ref}
        />
      );
    }
    if (isGroup) {
      return (
        <GroupedVirtuoso
          groupContent={groupContent}
          atBottomStateChange={atBottomStateChange}
          atBottomThreshold={overscan}
          customScrollParent={scrollerContainer || undefined}
          height="100%"
          atTopThreshold={topThreshold}
          firstItemIndex={firstItemIndex || 0}
          groupCounts={groupCounts}
          endReached={onLoadMore}
          rangeChanged={onRangeChange}
          components={listComponents}
          initialTopMostItemIndex={initialIndex || 0}
          overscan={overscan}
          itemContent={itemContent}
          className={className}
          ref={ref}
          atTopStateChange={atTopStateChanged}
        />
      );
    }

    return (
      <Virtuoso
        atBottomStateChange={atBottomStateChange}
        atBottomThreshold={overscan}
        startReached={onStartReached}
        customScrollParent={scrollerContainer || undefined}
        height="100%"
        firstItemIndex={firstItemIndex || 0}
        components={listComponents}
        totalCount={totalCount}
        data={data}
        endReached={onLoadMore}
        rangeChanged={onRangeChange}
        initialTopMostItemIndex={initialIndex || 0}
        overscan={overscan}
        itemContent={itemContent}
        className={className}
        ref={ref}
      />
    );
  },
);
