import { CreatePostPhases, TextBackground } from 'types';
import Background1 from 'photos/postBackgrounds/post-background1.png';
import Background2 from 'photos/postBackgrounds/post-background2.png';
import Background3 from 'photos/postBackgrounds/post-background3.png';
import Background4 from 'photos/postBackgrounds/post-background4.png';
import Background5 from 'photos/postBackgrounds/post-background5.png';
import Background6 from 'photos/postBackgrounds/post-background6.png';
import Background7 from 'photos/postBackgrounds/post-background7.png';
import Background8 from 'photos/postBackgrounds/post-background8.png';
import XIcon from 'photos/postBackgrounds/x-background-icon.png';
import Icon1 from 'photos/postBackgrounds/background-icon1.png';
import Icon2 from 'photos/postBackgrounds/background-icon2.png';
import Icon3 from 'photos/postBackgrounds/background-icon3.png';
import Icon4 from 'photos/postBackgrounds/background-icon4.png';
import Icon5 from 'photos/postBackgrounds/background-icon5.png';
import Icon6 from 'photos/postBackgrounds/background-icon6.png';
import Icon7 from 'photos/postBackgrounds/background-icon7.png';
import Icon8 from 'photos/postBackgrounds/background-icon8.png';
import { translations } from 'translations/en';
import { getImagesSizes } from 'utils';
import { calculateCompositions } from 'components/ImageGrid/helper';

export const CLASSNAME_TO_REMOVE = 'background-auto-complete-dropdown';

export const mapPhasesToTitle = {
  [CreatePostPhases.ChooseShop]: translations.FEED.POST_FROM,
  [CreatePostPhases.Details]: translations.FEED.CREATE_POST,
  [CreatePostPhases.EditPost]: translations.FEED.EDIT_POST,
  [CreatePostPhases.EditImages]: translations.COMMON.PHOTOS,
  [CreatePostPhases.ChooseItem]: translations.FEED.CHOOSE_ITEM,
};

export const mapTextBackgroundToImage = {
  [TextBackground.Clear]: '',
  [TextBackground.Planes]: Background1,
  [TextBackground.Megaphone]: Background2,
  [TextBackground.DIY]: Background3,
  [TextBackground.Wreath]: Background4,
  [TextBackground.Knit]: Background5,
  [TextBackground.Paint]: Background6,
  [TextBackground.Plants]: Background7,
  [TextBackground.Birds]: Background8,
};

export const mapTextBackgroundToIcon = {
  [TextBackground.Clear]: XIcon,
  [TextBackground.Planes]: Icon1,
  [TextBackground.Megaphone]: Icon2,
  [TextBackground.DIY]: Icon3,
  [TextBackground.Wreath]: Icon4,
  [TextBackground.Knit]: Icon5,
  [TextBackground.Paint]: Icon6,
  [TextBackground.Plants]: Icon7,
  [TextBackground.Birds]: Icon8,
};

export const BACKGROUND_MAX_CHARACTERS = 90;

export const CONTAINER_WIDTH = 443;

export const getImageComposition = async (images: string[]) => {
  const sizes = await getImagesSizes(images);

  return calculateCompositions(sizes);
};
