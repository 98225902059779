import './createAnAccountBox.scss';
import { connect } from 'react-redux';
import { FC, useState } from 'react';
import { Button, Checkbox } from 'tedooo-web-design-system/ui';

import GoogleSignInButton from 'pages/signPage/googleSignIn/GoogleSignInButton';
import FacebookSignInButton from 'pages/signPage/facebookSignInButton/FacebookSignInButton';
import { Span } from '../helpers';
import { translations } from 'translations/en';
import SignInForm from 'pages/signPage/signIn/signInForm/SignInForm';
import { IStore2 } from 'store/types';
import { login } from 'store/user/UserActions';
import { ISignIn } from 'pages/signPage/types';

const { JOIN_THE_COMMUNITY, DISCOVER_TEDOOO } = translations.SIGN;

const CreateAnAccountBox: FC<ISignIn> = ({
  isLoading,
  serverStatus,
  errorMessage,
  onForgetPasswordClick,
  onLogin,
  username,
  onSignUpClick,
}) => {
  const [isRemember, setIsRemember] = useState(false);

  const onRememberToggle = () => setIsRemember(!isRemember);

  const onSubmit = (name: string, password: string) => {
    onLogin({ username: name, password, rememberMe: isRemember });
  };

  return (
    <div className="create-an-account-container">
      <div className="create-an-account-top-section">
        <span className="title">{DISCOVER_TEDOOO}</span>
        <span className="description">{JOIN_THE_COMMUNITY}</span>
        <GoogleSignInButton />
        <FacebookSignInButton />
        <div className="divider">
          <div className="line" />
          <Span className="text" text={translations.COMMON.OR} />
          <div className="line" />
        </div>
        <SignInForm
          username={username}
          isLoading={isLoading}
          serverStatus={serverStatus}
          errorMessage={errorMessage}
          onSubmit={onSubmit}
        />
        <div className="footer">
          <div className="row">
            <Checkbox
              onChange={onRememberToggle}
              text={translations.SIGN.STAR_LOGGED_IN}
              checked={isRemember}
            />
            <Span
              text={translations.SIGN.FORGOT_PASSWORD}
              className="forget-password"
              onClick={onForgetPasswordClick}
            />
          </div>
        </div>
      </div>
      <div className="create-an-account-sub-section">
        <Span text={translations.SIGN.DONT_HAVE_ACCOUNT} className="title" />
        <Button
          borderRadius="large"
          type="secondary"
          size="large"
          isFullWidth
          text={translations.SIGN.JOIN_TEDOOO}
          onClick={onSignUpClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const {
    serverStatus,
    isLoading,
    loginServerErrorMessage: errorMessage,
    loginDefaultUsername,
  } = state.user;

  return { errorMessage, isLoading, serverStatus, username: loginDefaultUsername };
};

const mapDispatchToProps = {
  onLogin: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAnAccountBox);
