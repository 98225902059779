export const BASE_URL = 'https://backend.tedooo.com';

export const appLinks = {
  iphone: 'https://apps.apple.com/app/id1487331226',
  google: 'https://play.google.com/store/apps/details?id=com.mor.tedooo',
  huawei: 'https://appgallery.cloud.huawei.com/marketshare/app/C102898733',
};

export const SOCKET_URL = 'https://chat.tedooo.com/chat';

export const WEB_LINK = 'https://tedooo.com';

export const FACEBOOK_SHARE_LINK =
  'https://www.facebook.com/dialog/share?app_id=342480360764926&display=popup&title=tedooo&description=[message]&quote=[message]&href=[link]';

export const TWITTER_SHARE_LINK = 'https://twitter.com/intent/tweet?url=';

export const WHATSAPP_SHARE_LINK = 'https://web.whatsapp.com/send?text=';

export const GOOGLE_MAPS_LINK = 'https://maps.google.com/?q=';

export const TEDOOO_SUPPORT_ROUTE =
  'chat/61b644d2244614b74daa0201?avatar=https%3A%2F%2Fimages.tedooo.com%2F61b644d2244614b74daa0201%2F3423fb10-af5f-40b4-b185-3720fc7c9b40.jpeg&fullName=TedoooSupport&user_id=61b644d2244614b74daa0201&username=tedooosupport';

// 86400000
export const MILLISECONDS = {
  SECOND: 1000,
  MINUTE: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
  WEEK: 1000 * 60 * 60 * 24 * 7,
  MONTH: 1000 * 60 * 60 * 24 * 30,
  YEAR: 1000 * 60 * 60 * 24 * 365,
};

export const STORAGE_KEYS = {
  TOKEN: 'token',
  SHOW_GET_APP: 'showApp',
  TEMPORARY_POPUP_ID: 'temporaryPopupId',
  AMPLITUDE: 'amplitude',
  USER_ID: 'userId',
  CHAT_SHOPS: 'chatShops',
  CHAT_GROUPS: 'chatGroups',
  REDIRECT_URL: 'redirect_url',
  SHOP_ID: 'shopId',
  USERS: 'users',
  PAYOUTS: 'payouts',
  SHIPPING: 'shipping',
  CREATE_SHOP_DRAFT: 'draft',
  PREMIUM: 'premium',
  VISIONS: 'visions',
};

export const API_KEYS = {
  RECAPTCHA: '6LelogwaAAAAAPJ62Xk81sBIaas4KE1gg1dEgG96',
  GOOGLE_CLIENT_ID: '247595500749-a3hgqcsatesihliholqlcil8dnvicckm.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '342480360764926',
  AMPLITUDE: '743d75b5f63bffd9cd308c335d3e9225',
  GOOGLE_ANALYTICS: 'G-F580PWXK9V',
  MIXPANEL: 'dc84a35488341ade20e2880c26557325',
};

export const MINIMUM_USER_AGE = 6;

export const PREMIUM_ORIGIN_YEAR_PRICE = 300;

export const PREMIUM_ORIGIN_MONTH_PRICE = 24.99;

export const PREMIUM_FINAL_YEAR_PRICE = 149.99;

export const PREMIUM_FINAL_MONTH_PRICE = 24.99;

export const KEYBOARD_KEYS = {
  BACKSPACE: 'Backspace',
  BACKSPACE_TYPE: 'deleteContentBackward',
};
