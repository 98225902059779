import { FC } from 'react';
import classNames from 'classnames';

import { Image, Span } from 'components/helpers';
import './emptyState.scss';
import { IEmptyState } from 'types';
import { mapEmptyStateToText, mapEmptyStateToTitle, mapEmptyStateTypeToIcon } from './helper';

export const EmptyState: FC<IEmptyState> = ({ type, button }) => {
  const icon = mapEmptyStateTypeToIcon[type];

  const text = mapEmptyStateToText[type];

  const title = mapEmptyStateToTitle[type];

  return (
    <div className={classNames('empty-state', { 'without-image': !icon })}>
      <div className="text-container">
        {icon && <Image src={icon} />}
        {title && <Span className="title" text={title} />}
        <Span className="text" text={text} />
      </div>
      {button}
    </div>
  );
};

export default EmptyState;
