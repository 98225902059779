import { IUserOption, ServerStatus } from 'types';

export interface ISidebarUser extends IUserOption {
  isSelected: boolean;
}

export interface IGroupMembers {
  search: string;
  totalUsersSelected: number;
  users: ISidebarUser[];
  isUsersLoading: boolean;
  serverStatus: ServerStatus | null;
  isSingleStep: boolean;
  onSearch(options: { search: string }): void;
  onGroupMemberClick(options: { userId: string }): void;
  getUsersSuggestions(): void;
  onBack(): void;
  onNext(): void;
}

export interface ICreateChatProps {
  search: string;
  isLoading: boolean;
  users: IUserOption[];
  onSearch(options: { search: string }): void;
  onClick(id: string, avatar: string, name: string, username: string): void;
  onBack(): void;
  onGroupCreate(): void;
  getUsersSuggestions(): void;
}

export interface ICreateChatWrapper {
  serverStatus: ServerStatus | null;
  onClose(): void;
  onGroupCreate(): void;
  onCreateChat(input: {
    id: string;
    isGroup: boolean;
    avatar: string;
    name: string;
    username: string;
  }): void;
}

export enum CreateChatOptions {
  UsersSuggestions = 'create-chat',
  AddGroupMember = 'members',
  GroupDetails = 'details',
}
