import './onboarding.scss';
import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { TedoooLogo, TedoooText } from 'tedooo-web-design-system/icons';
import { Avatar } from 'tedooo-web-design-system/ui';
import { useNavigate } from 'react-router-dom';

import ProfilePictureStep from './profilePictureStep/ProfilePictureStep';
import BirthdayStep from './birthdayStep/BirthdayStep';
import MainGoalStep from './mainGoalStep/MainGoalStep';
import FollowShopsStep from './followShops/FollowShopsStep';
import ProfileCreatedStep from './profileCreatedStep/ProfileCreatedStep';
import JoinGroupsStep from './joinGroupsStep/JoinGroupsStep';
import backgroundImage from 'photos/sign-background.png';
import { Image } from 'components/helpers';
import Footer from '../signPage/footer/Footer';
import { OnboardingSlicer } from 'store/onboarding/OnboardingSlicer';
import { uploadImage } from 'api';
import { Steps } from 'store/onboarding/types';
import { IStore2 } from 'store/types';
import {
  getHasSuggestions,
  getOnboardingGroups,
  getOnboardingInterests,
  getOnboardingShops,
  onOnboardingFinish,
} from 'store/onboarding/OnboardingActions';
import { IOnboarding, UserProfile } from './types';
import { UserSelector } from 'store/user/UserSelector';
import { ROUTES } from 'constant';
import { updateUserDetails, UserSlicer } from 'store/user/UserActions';
import InterestsStep from './interestsStep/InterestsStep';

const Onboarding: FC<IOnboarding> = ({
  step,
  updateAvatar,
  avatar,
  updateBirthday,
  birthday,
  updateMainGoal,
  shouldDisplayEmptyState,
  mainGoal,
  onContinue,
  onBack,
  getInterests,
  toggleInterest,
  shops,
  toggleShop,
  interests,
  groups,
  toggleGroup,
  username,
  userId,
  getShouldOfferSuggestions,
  getShops,
  getGroups,
  onFinish,
  updateUserAvatar,
  updateShowOnboarding,
  hideInterestsEmptyState,
}) => {
  useEffect(() => {
    getShouldOfferSuggestions();
    getInterests();
  }, []);

  const navigate = useNavigate();

  const onAvatarUpdate = async (updatedAvatar: Avatar) => {
    const image = await uploadImage({ file: updatedAvatar });
    updateAvatar({ avatar: image });
  };

  const onBirthdayChange = (updatedBirthday: number) => {
    updateBirthday({ birthday: updatedBirthday });
  };

  const onMainGoalChange = (updatedMainGoal: UserProfile) => {
    updateMainGoal({ mainGoal: updatedMainGoal });
  };

  const onContinueClick = () => onContinue({ userId });

  const onBackClick = () => onBack();

  const onClickFinish = () => {
    if (mainGoal === UserProfile.Seller) {
      navigate(`/${ROUTES.CREATE_SHOP}`);
    } else {
      navigate(`/${ROUTES.HOME}`);
    }
    onContinueClick();
    onFinish();
    updateShowOnboarding({ shouldShow: false });
  };

  const renderForm = () => {
    if (step === Steps.ProfilePictureStep) {
      return (
        <ProfilePictureStep
          profilePicture={avatar}
          onEditProfilePictureClick={onAvatarUpdate}
          onContinueClick={onContinueClick}
          onSkipClick={onContinueClick}
          updateUserAvatar={updateUserAvatar}
        />
      );
    }
    if (step === Steps.BirthdayStep) {
      return (
        <BirthdayStep
          onContinueClick={onContinueClick}
          onBackClick={onBackClick}
          birthdayValue={birthday}
          onBirthdayChange={onBirthdayChange}
        />
      );
    }
    if (step === Steps.MainGoalStep) {
      return (
        <MainGoalStep
          onContinueClick={onContinueClick}
          onBackClick={onBackClick}
          selectedCard={mainGoal}
          onCardClick={onMainGoalChange}
        />
      );
    }
    if (step === Steps.InterestsStep) {
      return (
        <InterestsStep
          interests={interests || {}}
          onContinueClick={onContinueClick}
          onBackClick={onBackClick}
          onInterestToggle={toggleInterest}
          isContinueDisabled
          shouldDisplayEmptyState={shouldDisplayEmptyState}
          hideInterestsEmptyState={hideInterestsEmptyState}
        />
      );
    }
    if (step === Steps.FollowShopsStep) {
      return (
        <FollowShopsStep
          shops={shops}
          onShopClick={toggleShop}
          onBackClick={onBackClick}
          onContinueClick={onContinueClick}
          getShops={getShops}
        />
      );
    }
    if (step === Steps.JoinGroupsStep) {
      return (
        <JoinGroupsStep
          groups={groups}
          onBackClick={onBackClick}
          onContinueClick={onContinueClick}
          onGroupsClick={toggleGroup}
          getGroups={getGroups}
        />
      );
    }

    return (
      <ProfileCreatedStep profilePicture={avatar} username={username} onClick={onClickFinish} />
    );
  };

  return (
    <div className="onboarding-container">
      <Image src={backgroundImage} className="background-image" />
      <div className="mobile-header">
        <TedoooLogo className="full-logo-icon" />
        <TedoooText className="tedooo-text" />
      </div>
      <div className="form-container">{renderForm()}</div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { step, avatar, birthday, mainGoal, interests, shops, groups, shouldDisplayEmptyState } =
    state.onboarding;

  const username = UserSelector.loggedUserNameSelector(state) || '';

  const userId = UserSelector.loggedUserIdSelector(state) || '';

  return {
    step,
    avatar,
    birthday,
    mainGoal,
    interests,
    shops,
    groups,
    username,
    userId,
    shouldDisplayEmptyState,
  };
};

const mapDispatchToProps = {
  onContinue: OnboardingSlicer.actions.onContinue,
  onBack: OnboardingSlicer.actions.onBack,
  updateAvatar: OnboardingSlicer.actions.updateAvatar,
  updateBirthday: OnboardingSlicer.actions.updateBirthday,
  updateMainGoal: OnboardingSlicer.actions.updateMainGoal,
  toggleInterest: OnboardingSlicer.actions.toggleInterest,
  hideInterestsEmptyState: OnboardingSlicer.actions.hideInterestsEmptyState,
  toggleShop: OnboardingSlicer.actions.toggleShop,
  toggleGroup: OnboardingSlicer.actions.toggleGroup,
  getInterests: getOnboardingInterests,
  getShouldOfferSuggestions: getHasSuggestions,
  getShops: getOnboardingShops,
  getGroups: getOnboardingGroups,
  onFinish: onOnboardingFinish,
  updateUserAvatar: updateUserDetails,
  updateShowOnboarding: UserSlicer.actions.updateShouldShowOnboarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
