import { SubscriptionType } from 'graphql/generated/graphql';

export interface IPremiumPlaceholder {
  text: string;
  onPremiumClick(): void;
}

export interface IUpgradeToProPopup {
  nextBillingDate: number;
  premiumStatus: PremiumStatus;
  userPremiumPlan: PremiumPlanOptions | null;
  yearlySubscriptionUrl: string;
  monthlySubscriptionUrl: string;
  managePlanUrl: string;
  payoutProvider: string | null;
  isSubscriptionAutoRenew: boolean | null;
  shouldDisplayLegacyPremium: boolean;
  getTedoooSubscriptionUrl(input: { subscriptionType: SubscriptionType }): void;
  getManagePlanStripeUrl(): void;
  hidePopup(): void;
  getUserPremiumPlan(): void;
  updateUserPremiumStatus(input: { premiumStatus: PremiumStatus }): void;
  showPopup(input: { id: string }): void;
}

export interface IBoostYourShopVisibilityBox {
  showPopup(input: { id: string }): void;
}

export interface IPremiumPlanBoxWrapper {
  isPremium: boolean;
  managePlanUrl: string;
  yearlySubscriptionUrl: string;
  monthlySubscriptionUrl: string;
  payoutProvider: string | null;
  isSubscriptionAutoRenew: boolean | null;
  plan: PremiumPlanOptions;
  premiumStatus: PremiumStatus;
  nextBillingDate: number;
}

export interface IPremiumPlanPrice {
  finalPrice: number;
  originPrice: number;
}

export enum PremiumPlanOptions {
  monthly = 'Monthly',
  yearly = 'Yearly',
  // tedooo plan is all the users that have a fake premium (test users)
  tedooo = 'tedooo',
  none = 'none',
}

export interface IGetPremiumPlanDetails {
  subscriptionEndsDate: number;
  premiumPlan: PremiumPlanOptions;
  isSubscriptionAutoRenew: boolean | null;
  payoutProvider: string | null;
}

export const mapPremiumPlanOptionsToText = {
  [PremiumPlanOptions.monthly]: 'Monthly plan',
  [PremiumPlanOptions.yearly]: 'Yearly plan',
  [PremiumPlanOptions.none]: 'None',
};

export enum PremiumStatus {
  Active = 'Active',
  Expired = 'Expired',
  TryFreeMonth = 'TryFreeMonth',
}

export interface IInactivePremiumPlanBox {
  isPopular: boolean;
  plan: PremiumPlanOptions;
  premiumStatus: PremiumStatus;
  yearlySubscriptionUrl: string;
  monthlySubscriptionUrl: string;
  finalPrice: number;
  originPrice: number;
  onClick(plan?: PremiumPlanOptions): void;
}

export interface IActivePremiumPlanBox {
  finalPrice: number;
  originPrice: number;
  shouldShowNextBilling: boolean;
  managePlanUrl: string;
  yearlySubscriptionUrl: string;
  monthlySubscriptionUrl: string;
  isSubscriptionAutoRenew: boolean | null;
  payoutProvider: string | null;
  plan: PremiumPlanOptions;
  nextBillingDate: number;
  onClick(plan?: PremiumPlanOptions): void;
}

export enum TedoooProBenefitsTypeLegacy {
  BoostedProfile,
  PriorityOnSearchResults,
  MoreDiscoveryAndVirality,
  SeeWhoViewedYourProfile,
  BoostYourWebsitesAndShops,
}

export enum TedoooProBenefitsType {
  VerifiedBadge,
  BoostedProfile,
  VisionsCredits,
  SupportCraftCommunity,
  ExclusiveExposureToVisionRequests,
  EarlyAccessToNewFeatures,
  PremiumSupport,
  ViewInsights,
  SearchPriority,
}
