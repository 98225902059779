import { IForgetPasswordApi, Method } from 'types';
import { fetcher } from 'api';

export class ForgetPasswordReducer implements IForgetPasswordApi {
  onForgetPassword(): Promise<any> {
    return Promise.resolve(undefined);
  }

  sendVerifyCode(phone: string): Promise<{ phoneNumber: string; username: string }> {
    return fetcher('recover', Method.POST, undefined, { phone }, { v2: 1 });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resendCodeInCall(_phone: string): Promise<any> {
    return fetcher('recover', Method.POST, undefined, undefined, { v2: 1, phone: 1 });
  }

  submitVerificationCode(code: string, username: string): Promise<{ password: string }> {
    const body = {
      username,
      code,
    };

    return fetcher('recover', Method.PUT, undefined, body, { v2: 1 });
  }

  submitNewPassword(prevPassword: string, password: string, username: string): Promise<any> {
    return fetcher('recover', Method.PATCH, undefined, { username, old: prevPassword, password });
  }
}
