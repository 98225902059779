import { CurrencyCode } from 'constant/currencies';

export enum PostActionType {
  Like = 1,
  Comment = 2,
  Repost = 3,
  Mention = 4,
}

export enum CategoryResponseOptions {
  Info,
  BuyAndSell,
}

export interface IPrice {
  currencyCode: CurrencyCode;
  currencySymbol: string;
  value: number;
}

export interface IPriceResponse {
  currency: string;
  price: number;
  currencyCode: string;
}

export interface IPostAction {
  username: string;
  totalUsers?: string;
  type: PostActionType;
}

export interface IActionHeader extends IPostAction {
  isPostOwner: boolean;
}

export enum MenuOptions {
  Hide = 'hide',
  HideFromActivity = 'hideFromActivity',
  Report = 'report',
  Delete = 'delete',
  Share = 'share',
  Edit = 'edit',
  Duplicate = 'duplicate',
  Message = 'message',
  GoToShop = 'goToShop',
}

export interface IUserDetails {
  avatar: string;
  fullName: string;
  username: string;
  shopName: string;
  date: number;
  userId: string;
  shopId: string | null;
  shopImage: string;
  isPremium: boolean;
  isFollowing: boolean;
  id?: string;
}

export enum UserDetailsLocations {
  Header,
  LikePopup,
}

export const mapUserDetailsLocationToAvatarSize = {
  [UserDetailsLocations.Header]: 26,
  [UserDetailsLocations.LikePopup]: 50,
};

export const mapUserDetailsLocationToPremiumTagSize: {
  [key in UserDetailsLocations]: 'small' | 'medium';
} = {
  [UserDetailsLocations.Header]: 'medium',
  [UserDetailsLocations.LikePopup]: 'small',
};

export interface IUserDetailsProps extends IUserDetails {
  shouldDisplayOptionMenu: boolean;
  shouldHideFollowButton: boolean;
  shouldHideDate: boolean;
  type: UserDetailsLocations;
  isPostOwner: boolean;
  shouldDisplayHideFromActivity: boolean;
  shopId: string | null;
  isEditablePost: boolean;
  isPostEdited: boolean;
  onUserClick(userId: string): void;
  onFollowToggle(): void;
  onOptionMenuClick(optionType: MenuOptions): void;
}

export const OptionsMenuList = [
  {
    key: MenuOptions.Edit,
    label: 'Edit',
    danger: false,
  },
  {
    key: MenuOptions.Hide,
    label: 'Hide',
    danger: false,
  },
  {
    key: MenuOptions.Share,
    label: 'Share',
    danger: false,
  },
  {
    key: MenuOptions.Delete,
    label: 'Delete',
    danger: true,
  },
  {
    key: MenuOptions.HideFromActivity,
    label: 'Hide from activity',
    danger: false,
  },
  {
    key: MenuOptions.Report,
    label: 'Report',
    danger: true,
  },
];

export const mapPostActionTypeToText = {
  [PostActionType.Comment]: 'comment on this',
  [PostActionType.Like]: 'liked this post',
  [PostActionType.Repost]: 'reposted this',
  [PostActionType.Mention]: 'was tagged in this post',
};
