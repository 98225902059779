import { FC } from 'react';
import classNames from 'classnames';
import './repost.scss';
import { useNavigate } from 'react-router-dom';

import { IPost, UserDetailsLocations } from 'types';
import UserDetails from '../header/userDetails/UserDetails';
import ExpandableText from 'components/expandableText/ExpandableText';
import { Image } from 'components/helpers';
import { mapTextBackgroundToImage } from 'pages/feed/createPost/createPostPopup/helper';
import ImagesGrid from 'components/ImageGrid/ImagesGrid';
import ProductContainer from 'pages/feed/createPost/createPostPopup/postDetails/productContainer/ProductContainer';
import { ROUTES } from 'constant';

interface IRepost {
  repostDetails: IPost;
}

const Repost: FC<IRepost> = ({ repostDetails }) => {
  const navigate = useNavigate();

  const {
    images,
    imageComposition,
    productDetails,
    text,
    backgroundIndex,
    userDetails,
    lastEdited,
    taggedUsers,
  } = repostDetails;

  const mentions = taggedUsers.map((user) => ({
    username: user.username,
    link: `/${ROUTES.USER}/${user.userId}`,
  }));

  const shouldShowImageGrid = !productDetails && !!images && backgroundIndex === undefined;

  const { userId } = userDetails;

  const onUserClick = () => {
    navigate(`/${ROUTES.USER}/${userId}`);
  };

  return (
    <div className="repost-container post-container" onClick={() => {}}>
      <UserDetails
        shouldHideFollowButton
        shopImage={userDetails.shopImage}
        onUserClick={onUserClick}
        shopId={userDetails.shopId}
        fullName={userDetails.fullName}
        isPostOwner={false}
        shouldHideDate={false}
        userId={userDetails.userId}
        onFollowToggle={() => {}}
        shouldDisplayOptionMenu={false}
        isFollowing={false}
        shouldDisplayHideFromActivity={false}
        type={UserDetailsLocations.Header}
        onOptionMenuClick={() => {}}
        avatar={userDetails.avatar}
        username={userDetails.username}
        shopName={userDetails.shopName}
        date={userDetails.date}
        isPremium={userDetails.isPremium}
        isEditablePost={false}
        isPostEdited={!!lastEdited}
      />
      <div className={classNames('post-details-inner-container', { 'no-images': !images })}>
        {backgroundIndex === undefined && (
          <ExpandableText
            text={text}
            maxChars={300}
            isCollapsable={false}
            mentions={mentions}
            isTextClickable={false}
          />
        )}
        {backgroundIndex !== undefined && (
          <div className="background-index-container" onClick={() => {}}>
            <Image src={mapTextBackgroundToImage[backgroundIndex]} />
            <span className="text">{text}</span>
          </div>
        )}
        <div className="post-image-container" onClick={() => {}}>
          {shouldShowImageGrid && (
            <ImagesGrid
              withOverlay={false}
              imageComposition={imageComposition}
              images={images}
              onMoreClick={() => {}}
              onClick={() => {}}
            />
          )}
          {productDetails && images && (
            <ProductContainer
              image={images[0]}
              currencyCode={productDetails.price.currencyCode}
              price={productDetails.price.value}
              title={productDetails.title}
              originalPrice={productDetails.price.value}
              showBuyButton
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Repost;
