import { createSlice } from '@reduxjs/toolkit';

import { IBasketState, ProductAvailabilityItem } from 'types';
import {
  getBasket,
  onRemoveItemFromBasket,
  getBasketSupportedItems,
  getShopBasketShippingRate,
  getTotalBasketItems,
  onAddItemToBasket,
  getCheckoutIdForProduct,
} from './BasketActions';
import {
  getPrice,
  parseBasket,
  parseSavedProducts,
  parseShippingRates,
  parseCheckoutLinkedPayoutOptions,
} from '../apiParser';
import { isEmptyArray } from 'utils';
import { checkCouponForCart, getBasketCheckoutDetails } from '../payouts/PayoutsActions';
import {
  CheckCouponCodeResponseError,
  CheckCouponCodeResponseSuccess,
} from '../../graphql/generated/graphql';
import {
  getCouponCodeErrorMessageFromResponse,
  getDiscountDetailsFromResponse,
} from '../checkoutForNonSignupUsers/helper';

const initialState: IBasketState = {
  shopBaskets: null,
  buyNowItem: null,
  buyNowShopId: null,
  discountDetailsStateMap: {},
  instantCheckoutDetails: null,
  savedItems: null,
  totalItems: 0,
  source: null,
};

export const BasketSlicer = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    resetCheckoutDetails: (state) => {
      state.instantCheckoutDetails = null;
      state.discountDetailsStateMap = {};
      state.buyNowShopId = null;
    },
    updateSource: (state, action) => {
      const { source } = action.payload;
      state.source = source;
    },

    removeCouponCode: (state, action) => {
      const { shopId } = action.payload;
      if (state.discountDetailsStateMap[shopId]) state.discountDetailsStateMap[shopId] = null;
    },
    resetDiscountData: (state) => {
      state.discountDetailsStateMap = {};
    },
    removeCouponCodeError: (state, action) => {
      const { shopId } = action.payload;

      const curDiscountState = state.discountDetailsStateMap[shopId];

      if (curDiscountState) {
        state.discountDetailsStateMap[shopId] = {
          ...curDiscountState,
          errorMessage: null,
          isLoading: false,
        };
      }
    },
    updateBuyNowItem: (state, action) => {
      const { item, shopId } = action.payload;
      state.buyNowShopId = shopId;
      state.buyNowItem = { item, checkoutId: null };
    },
    removeBuyNowItem: (state) => {
      state.buyNowItem = null;
      state.buyNowShopId = null;
    },
    onCustomizationNoteChange: (state, action) => {
      const { checkoutId, cartItemId, text } = action.payload;

      const selectedShop = state.shopBaskets?.find((shop) => shop.checkoutId === checkoutId);

      if (selectedShop) {
        const selectedItem = selectedShop.items.find((item) => item.cartItemId === cartItemId);

        if (selectedItem) {
          selectedItem.customizationRequest.text = text;
        }
      }
    },
    onCustomizationNoteImageChange: (state, action) => {
      const { checkoutId, cartItemId, image } = action.payload;

      const selectedShop = state.shopBaskets?.find((shop) => shop.checkoutId === checkoutId);

      if (selectedShop) {
        const selectedItem = selectedShop.items.find((item) => item.cartItemId === cartItemId);

        if (selectedItem) {
          selectedItem.customizationRequest.images.push(image);
        }
      }
    },
    onShippingRateSelect: (state, action) => {
      const { id } = action.payload;

      if (state.instantCheckoutDetails) {
        const previousSelectedRate = state.instantCheckoutDetails.shippingRates?.find(
          (rate) => rate.isSelected,
        );

        if (previousSelectedRate) {
          previousSelectedRate.isSelected = false;
        }

        const newSelectedRate = state.instantCheckoutDetails.shippingRates?.find(
          (rate) => rate.id === id,
        );

        if (newSelectedRate) {
          newSelectedRate.isSelected = true;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBasket.fulfilled, (state, action) => {
      const { getCart, getProductsByListId } = action.payload;
      state.shopBaskets = parseBasket(getCart.shops);
      state.savedItems = parseSavedProducts(getProductsByListId.products);
    });
    builder.addCase(getTotalBasketItems.fulfilled, (state, action) => {
      const { totalItems } = action.payload;
      state.totalItems = totalItems;
    });
    builder.addCase(getCheckoutIdForProduct.fulfilled, (state, action) => {
      const { item } = action.meta.arg;

      const { id } = action.payload;

      state.buyNowItem = { checkoutId: id, item };
    });
    builder.addCase(getBasketCheckoutDetails.fulfilled, (state, action) => {
      const { rates, payments } = action.payload;

      state.instantCheckoutDetails = {
        shippingRates: rates ? parseShippingRates(rates) : null,
        linkedPaymentOptions: parseCheckoutLinkedPayoutOptions(payments),
      };
    });
    builder.addCase(getBasketSupportedItems.fulfilled, (state, action) => {
      const { statuses } = action.payload;
      Object.entries(statuses).forEach(([id, status]) => {
        if (state.buyNowItem) {
          if (state.buyNowItem.item.id === id && status !== ProductAvailabilityItem.Available) {
            state.buyNowItem.item.status = status;
          }
        }
        if (state.shopBaskets) {
          state.shopBaskets.forEach((shop) => {
            shop.items.forEach((item) => {
              if (item.productId === id) {
                item.status = status;
              }
            });
          });
        }
      });
    });
    builder.addCase(onRemoveItemFromBasket.fulfilled, (state, action) => {
      const { item: cartItem, checkoutId } = action.meta.arg;

      const selectedShop = state.shopBaskets?.find((shop) => shop.checkoutId === checkoutId);

      const { cartItemId } = cartItem;

      if (selectedShop) {
        const selectedItemIndex = selectedShop.items.findIndex(
          (item) => item.cartItemId === cartItemId,
        );

        if (selectedItemIndex > -1) {
          const selectedItem = selectedShop.items[selectedItemIndex];
          state.totalItems = (state.totalItems || 0) - selectedItem.quantity;
          selectedShop.items.splice(selectedItemIndex, 1);

          selectedShop.subTotalPrice -= selectedItem.price.value;
        }
        if (isEmptyArray(selectedShop.items) && state.shopBaskets) {
          state.shopBaskets = state.shopBaskets?.filter((shop) => shop.checkoutId !== checkoutId);
        }
      }
    });
    builder.addCase(getShopBasketShippingRate.rejected, (state, action) => {
      const { checkoutId } = action.meta.arg;

      const selectedShop = state.shopBaskets?.find((shop) => shop.checkoutId === checkoutId);
      if (selectedShop) selectedShop.shippingPrice = null;
    });

    builder.addCase(getShopBasketShippingRate.fulfilled, (state, action) => {
      const { price } = action.payload;

      const { checkoutId } = action.meta.arg;

      const selectedShop = state.shopBaskets?.find((shop) => shop.checkoutId === checkoutId);

      if (selectedShop) {
        selectedShop.shippingPrice = getPrice(price);
      }
    });
    builder.addCase(onAddItemToBasket.fulfilled, (state, action) => {
      const { quantity } = action.meta.arg.details;
      state.totalItems = (state.totalItems || 0) + quantity;
    });

    builder.addCase(checkCouponForCart.pending, (state, action) => {
      const { shopId } = action.meta.arg;

      state.discountDetailsStateMap[shopId] = {
        discountDetails: null,
        errorMessage: null,
        isLoading: true,
      };
    });
    builder.addCase(checkCouponForCart.rejected, (state, action) => {
      const { shopId } = action.meta.arg;

      state.discountDetailsStateMap[shopId] = {
        discountDetails: null,
        errorMessage: 'Internal Error',
        isLoading: false,
      };
    });
    builder.addCase(checkCouponForCart.fulfilled, (state, action) => {
      const { couponCode, shopId } = action.meta.arg;

      const res: CheckCouponCodeResponseError | CheckCouponCodeResponseSuccess = action.payload;

      const discountDetails = getDiscountDetailsFromResponse(res, couponCode);

      const errorMessage = getCouponCodeErrorMessageFromResponse(res);

      state.discountDetailsStateMap[shopId] = {
        discountDetails,
        errorMessage,
        isLoading: false,
      };
    });
  },
});
