import { FC } from 'react';
import classNames from 'classnames';
import { User } from 'tedooo-web-design-system/icons';

import './avatar.scss';

interface IAvatar {
  src: string | null | undefined;
  size: number;
  className?: string;
  noLoader?: boolean;
  placeholderIcon?: any;
  onClick?: (e: any) => void;
  icon?: any;
}

export const Avatar: FC<IAvatar> = ({ src, placeholderIcon, size, onClick, icon, className }) => {
  const displayedIcon = placeholderIcon || (
    <User className={classNames('default-icon', className)} width={size / 2} height={size / 2} />
  );

  return (
    <div
      className="avatar-container"
      style={{
        height: size,
        width: size,
      }}
      onClick={onClick}
    >
      {src && (
        <img
          alt="avatar"
          className={classNames('avatar', [className])}
          src={src}
          style={{
            height: size,
            width: size,
          }}
        />
      )}
      {!src && displayedIcon}
      {icon && <div className="icon-container">{icon}</div>}
    </div>
  );
};
