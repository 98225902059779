import searchIcon from 'photos/emptyState/searchEmptyState.png';
import catalogIcon from 'photos/emptyState/catalogEmptyState.png';
import activityIcon from 'photos/emptyState/activityEmptyState.png';
import reviewIcon from 'photos/emptyState/reviewEmptyState.png';
import TagIcon from 'photos/emptyState/tag-empty-state.png';
import SpeakerIcon from 'photos/emptyState/speaker-empty-state.png';
import { EmptyStateOptions } from 'types';
import leafIcon from 'photos/leaf-quite.png';
import commentIcon from 'photos/emptyState/commentsEmptyState.png';
import mediaIcon from 'photos/emptyState/mediaEmptyState.png';
import FileIcon from 'photos/emptyState/filesEmptyState.png';
import LinkIcon from 'photos/emptyState/linksEmptyState.png';
import InviteMembersIcon from 'photos/emptyState/chatSearchEmptyState.png';
import OrdersOverviewIcon from 'photos/emptyState/ordersOverviewEmptyState.png';
import CategoriesIcon from 'photos/emptyState/categoriesEmptyState.png';
import HeartIcon from 'photos/emptyState/heartEmptyState.png';
import MagicIcon from 'photos/emptyState/mojos-empty-state.png';
import CreateShopIcon from 'photos/shopIcon.png';
import BasketIcon from 'photos/emptyState/basket-empty-state.png';
import { translations } from 'translations/en';

export const mapEmptyStateTypeToIcon: { [key in EmptyStateOptions]: string } = {
  [EmptyStateOptions.Basket]: BasketIcon,
  [EmptyStateOptions.ShopCatalog]: catalogIcon,
  [EmptyStateOptions.ShopActivity]: activityIcon,
  [EmptyStateOptions.ShopReviews]: reviewIcon,
  [EmptyStateOptions.Feed]: leafIcon,
  [EmptyStateOptions.Comments]: commentIcon,
  [EmptyStateOptions.NoResults]: searchIcon,
  [EmptyStateOptions.Search]: InviteMembersIcon,
  [EmptyStateOptions.ChatMedia]: mediaIcon,
  [EmptyStateOptions.ChatLinks]: LinkIcon,
  [EmptyStateOptions.ChatFiles]: FileIcon,
  [EmptyStateOptions.ChatInviteMembers]: InviteMembersIcon,
  [EmptyStateOptions.ChatGroups]: searchIcon,
  [EmptyStateOptions.Payouts]: searchIcon,
  [EmptyStateOptions.OrdersOverviewTodo]: OrdersOverviewIcon,
  [EmptyStateOptions.OrdersOverviewAwaiting]: OrdersOverviewIcon,
  [EmptyStateOptions.OrdersOverviewShipped]: OrdersOverviewIcon,
  [EmptyStateOptions.OrdersOverviewCompleted]: OrdersOverviewIcon,
  [EmptyStateOptions.OrdersOverviewDeclined]: OrdersOverviewIcon,
  [EmptyStateOptions.OrdersOverviewNoActiveOrders]: OrdersOverviewIcon,
  [EmptyStateOptions.ProductCategories]: CategoriesIcon,
  [EmptyStateOptions.Favorites]: HeartIcon,
  [EmptyStateOptions.Mojos]: MagicIcon,
  [EmptyStateOptions.CreateShop]: CreateShopIcon,
  [EmptyStateOptions.PendingOffers]: TagIcon,
  [EmptyStateOptions.PotentialOrders]: SpeakerIcon,
};

export const mapEmptyStateToText: { [key in EmptyStateOptions]: string } = {
  [EmptyStateOptions.ShopActivity]: translations.SHOP.NO_ACTIVITIES,
  [EmptyStateOptions.ShopCatalog]: translations.SHOP.NO_PRODUCTS,
  [EmptyStateOptions.ChatInviteMembers]: translations.CHAT.SEARCH_BY_NAME,
  [EmptyStateOptions.ShopReviews]: translations.SHOP.NO_REVIEWS,
  [EmptyStateOptions.Feed]: translations.FEED.POSTS_SIDE_BAR.NO_POSTS,
  [EmptyStateOptions.ChatGroups]: translations.PROFILE.NO_GROUPS,
  [EmptyStateOptions.Comments]: translations.FEED.POST.COMMENTS.NO_COMMENTS_TEXT,
  [EmptyStateOptions.NoResults]: translations.CHAT.EMPTY_STATE.NO_SEARCH_TEXT,
  [EmptyStateOptions.Search]: translations.CHAT.EMPTY_STATE.SEARCH_DESCRIPTION,
  [EmptyStateOptions.ChatMedia]: translations.CHAT.EMPTY_STATE.NO_MEDIA,
  [EmptyStateOptions.ChatLinks]: translations.CHAT.EMPTY_STATE.NO_LINKS,
  [EmptyStateOptions.ChatFiles]: translations.CHAT.EMPTY_STATE.NO_FILES,
  [EmptyStateOptions.Payouts]: translations.CHAT.EMPTY_STATE.NO_PAYOUTS_DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewTodo]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.TODO.DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewAwaiting]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.AWAITING.DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewShipped]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.SHIPPED.DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewCompleted]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.COMPLETED.DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewDeclined]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.DECLINED.DESCRIPTION,
  [EmptyStateOptions.OrdersOverviewNoActiveOrders]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.NO_ACTIVE_ORDERS.DESCRIPTION,
  [EmptyStateOptions.ProductCategories]: 'eg. crochet, resin, art objects',
  [EmptyStateOptions.Favorites]: translations.PRODUCTS_PAGE.EMPTY_STATE.FAVORITES.TITLE,
  [EmptyStateOptions.Mojos]: translations.SHOP.NO_MOJOS_DESCRIPTION,
  [EmptyStateOptions.CreateShop]: translations.SHOP.CREATE_SHOP_DESCRIPTION,
  [EmptyStateOptions.PendingOffers]:
    translations.SELLER_OFFERS_PAGE.PENDING_OFFERS_EMPTY_STATE.DESCRIPTION,
  [EmptyStateOptions.PotentialOrders]:
    translations.SELLER_OFFERS_PAGE.POTENTIAL_ORDERS_EMPTY_STATE.DESCRIPTION,
  [EmptyStateOptions.Basket]: translations.BASKET.EMPTY_STATE.DESCRIPTION,
};

export const mapEmptyStateToTitle: { [key in EmptyStateOptions]: string } = {
  [EmptyStateOptions.ShopActivity]: translations.SHOP.NO_ACTIVITIES_TITLE,
  [EmptyStateOptions.ShopCatalog]: translations.SHOP.NO_PRODUCTS_TITLE,
  [EmptyStateOptions.ShopReviews]: translations.SHOP.NO_REVIEWS_TITLE,
  [EmptyStateOptions.ChatGroups]: translations.SHOP.NO_GROUPS_TITLE,
  [EmptyStateOptions.NoResults]: translations.CHAT.EMPTY_STATE.NO_RESULT,
  [EmptyStateOptions.Search]: translations.CHAT.EMPTY_STATE.SEARCH_TITLE,
  [EmptyStateOptions.Comments]: translations.FEED.POST.COMMENTS.NO_COMMENTS_TITLE,
  [EmptyStateOptions.ChatMedia]: translations.CHAT.EMPTY_STATE.NO_MEDIA_TITLE,
  [EmptyStateOptions.ChatLinks]: translations.CHAT.EMPTY_STATE.NO_LINKS_TITLE,
  [EmptyStateOptions.ChatFiles]: translations.CHAT.EMPTY_STATE.NO_FILES_TITLE,
  [EmptyStateOptions.ChatInviteMembers]: translations.CHAT.INVITE_MEMBERS,
  [EmptyStateOptions.Payouts]: translations.CHAT.EMPTY_STATE.NO_PAYOUTS_TITLE,
  [EmptyStateOptions.Feed]: '',
  [EmptyStateOptions.OrdersOverviewTodo]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.TODO.TITLE,
  [EmptyStateOptions.OrdersOverviewAwaiting]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.AWAITING.TITLE,
  [EmptyStateOptions.OrdersOverviewShipped]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.SHIPPED.TITLE,
  [EmptyStateOptions.OrdersOverviewCompleted]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.COMPLETED.TITLE,
  [EmptyStateOptions.OrdersOverviewDeclined]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.DECLINED.TITLE,
  [EmptyStateOptions.OrdersOverviewNoActiveOrders]:
    translations.ORDERS.DASHBOARD.ORDERS_OVERVIEW.EMPTY_STATE.NO_ACTIVE_ORDERS.TITLE,
  [EmptyStateOptions.ProductCategories]: 'Search for a category',
  [EmptyStateOptions.Favorites]: translations.PRODUCTS_PAGE.EMPTY_STATE.FAVORITES.DESCRIPTION,
  [EmptyStateOptions.Mojos]: translations.SHOP.NO_MOJOS_TITLE,
  [EmptyStateOptions.CreateShop]: translations.SHOP.CREATE_SHOP_TITLE,
  [EmptyStateOptions.PendingOffers]:
    translations.SELLER_OFFERS_PAGE.PENDING_OFFERS_EMPTY_STATE.TITLE,
  [EmptyStateOptions.PotentialOrders]:
    translations.SELLER_OFFERS_PAGE.POTENTIAL_ORDERS_EMPTY_STATE.TITLE,
  [EmptyStateOptions.Basket]: translations.BASKET.EMPTY_STATE.TITLE,
};
