import { FC, useEffect } from 'react';
import { Button } from 'tedooo-web-design-system/ui';

import GroupsSkeleton from 'components/skeletons/groupSkeleton/GroupSkeletons';
import './joinGroupsStep.scss';
import { translations } from 'translations/en';
import GroupCard from './groupCard/GroupCard';
import { IGroupSuggestion, IJoinGroupsStep } from 'types';

const JoinGroupsStep: FC<IJoinGroupsStep> = ({
  onContinueClick,
  onBackClick,
  groups,
  onGroupsClick,
  getGroups,
}) => {
  useEffect(() => {
    getGroups();
  }, []);

  const list = groups?.length ? groups : [null, null, null, null];

  const isButtonDisabled = !groups;

  const itemContent = (group: IGroupSuggestion | null, index: number) => {
    if (!group) {
      return <GroupsSkeleton key={index} />;
    }

    const onClick = () => onGroupsClick({ groupId: group.id });

    return (
      <GroupCard
        key={group.id}
        description={group.description}
        groupName={group.name}
        avatar={group.image}
        isSelected={group.isSelected}
        onClick={onClick}
      />
    );
  };

  return (
    <div className="join-groups-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.JOIN_GROUPS_STEP.TITLE}</span>
        <span className="description">{translations.ONBOARDING.JOIN_GROUPS_STEP.DESCRIPTION}</span>
      </div>

      <div className="content-container">
        {list.map((group, index) => itemContent(group, index))}
      </div>
      <div className="buttons-container">
        <Button
          isFullWidth
          type="primary"
          size="large"
          borderRadius="large"
          disabled={isButtonDisabled}
          text={translations.COMMON.CONTINUE}
          className="continue-button-interests"
          onClick={onContinueClick}
        />
        <Button
          isFullWidth
          type="secondary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.BACK}
          className="back-button-interests"
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default JoinGroupsStep;
