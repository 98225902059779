export enum Countries {
  WorldWide = 'Worldwide',
  Afghanistan = 'Afghanistan',
  Anguilla = 'Anguilla',
  Albania = 'Albania',
  Algeria = 'Algeria',
  Andorra = 'Andorra',
  Angola = 'Angola',
  AntiguaAndBarbuda = 'Antigua and Barbuda',
  Antarctica = 'Antarctica',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  AmericanSamoa = 'American Samoa',
  Australia = 'Australia',
  Aruba = 'Aruba',
  AlandIslands = 'Åland Islands',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  CocosIslands = 'Cocos (Keeling) Islands',
  Benin = 'Benin',
  SaintBarthelemy = 'Saint Barthélemy',
  Bhutan = 'Bhutan',
  BouvetIslands = 'Bouvet Islands',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaAndHerzegovina = 'Bosnia and Herzegovina',
  Botswana = 'Botswana',
  Brazil = 'Brazil',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina Faso',
  Burundi = 'Burundi',
  CapeVerde = 'Cape Verde',
  Curacao = 'Curaçao',
  ChristmasIsland = 'Christmas Island',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CentralAfricanRepublic = 'Central African Republic',
  Chad = 'Chad',
  FrenchSouthernTerritories = 'French Southern Territories',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CostaRica = 'Costa Rica',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech Republic',
  DemocraticRepublicOfTheCongo = 'Democratic Republic of the Congo',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'Dominican Republic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  WesternSahara = 'Western Sahara',
  ElSalvador = 'El Salvador',
  EquatorialGuinea = 'Equatorial Guinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Eswatini = 'Eswatini',
  Ethiopia = 'Ethiopia',
  Fiji = 'Fiji',
  FalklandIslands = 'Falkland Islands',
  Finland = 'Finland',
  France = 'France',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Guernsey = 'Guernsey',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greenland = 'Greenland',
  Greece = 'Greece',
  SouthGeorgia = 'South Georgia',
  FrenchGuiana = 'French Guiana',
  Grenada = 'Grenada',
  Guatemala = 'Guatemala',
  Guam = 'Guam',
  Guinea = 'Guinea',
  Guadeloupe = 'Guadeloupe',
  GuineaBissau = 'Guinea-Bissau',
  Guyana = 'Guyana',
  HongKong = 'Hong Kong, China',
  HeardIslandAndMcdonaldIslands = 'Heard Island and McDonald Islands',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  IsleOfMan = 'Isle of Man',
  Italy = 'Italy',
  Jersey = 'Jersey',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North Korea',
  SouthKorea = 'South Korea',
  Kosovo = 'Kosovo',
  Kuwait = 'Kuwait',
  CaymanIslands = 'Cayman Islands',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall Islands',
  Mauritania = 'Mauritania',
  Montserrat = 'Montserrat',
  Mauritius = 'Mauritius',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  FaroeIslands = 'Faroe Islands',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Macao = 'Macao',
  NorthMarianaIslands = 'Northern Mariana Islands',
  Martinique = 'Martinique',
  Montenegro = 'Montenegro',
  SaintMartin = 'Saint Martin',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  NewCaledonia = 'New Caledonia',
  Nauru = 'Nauru',
  Niue = 'Niue',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NewZealand = 'New Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NorfolkIsland = 'Norfolk Island',
  NorthMacedonia = 'North Macedonia',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua New Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  FrenchPolynesia = 'French Polynesia',
  Philippines = 'Philippines',
  Poland = 'Poland',
  SaintPierreAndMiquelon = 'Saint Pierre and Miquelon',
  Pitcairn = 'Pitcairn Islands',
  PuertoRico = 'Puerto Rico',
  Portugal = 'Portugal',
  Qatar = 'Qatar',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  SaintKittsAndNevis = 'Saint Kitts and Nevis',
  SaintLucia = 'Saint Lucia',
  SaintVincentAndTheGrenadines = 'Saint Vincent and the Grenadines',
  Samoa = 'Samoa',
  SanMarino = 'San Marino',
  SaoTomeAndPrincipe = 'Sao Tome and Principe',
  SaudiArabia = 'Saudi Arabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra Leone',
  Singapore = 'Singapore',
  SaintHelena = 'Saint Helena',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SvalbardAndJanMayen = 'Svalbard and Jan Mayen',
  SolomonIslands = 'Solomon Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South Africa',
  SouthSudan = 'South Sudan',
  Spain = 'Spain',
  SriLanka = 'Sri Lanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  CoteDIvoire = 'Côte d’Ivoire',
  CookIslands = 'Cook Islands',
  Syria = 'Syria',
  Swaziland = 'Swaziland',
  TurksAndCaicosIslands = 'Turks and Caicos Islands',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tokelau = 'Tokelau',
  TimorLeste = 'Timor-Leste',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad and Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United Arab Emirates',
  UnitedKingdom = 'United Kingdom',
  UnitedStates = 'United States',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  WallisAndFutuna = 'Wallis and Futuna',
  VaticanCity = 'Vatican City',
  Venezuela = 'Venezuela',
  VirginIslandsUS = 'US Virgin Islands',
  VirginIslandsUK = 'British Virgin Islands',
  Vietnam = 'Vietnam',
  Yemen = 'Yemen',
  Mayotte = 'Mayotte',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

export const countries = [
  {
    isoCode: 'US',
    emojiFlag: '🇺🇸',
    country: Countries.UnitedStates,
  },
  {
    isoCode: 'CA',
    emojiFlag: '🇨🇦',
    country: Countries.Canada,
  },
  {
    isoCode: 'AD',
    emojiFlag: '🇦🇩',
    country: Countries.Andorra,
  },
  {
    isoCode: 'AE',
    emojiFlag: '🇦🇪',
    country: Countries.UnitedArabEmirates,
  },
  {
    isoCode: 'AF',
    emojiFlag: '🇦🇫',
    country: Countries.Afghanistan,
  },
  {
    isoCode: 'AG',
    emojiFlag: '🇦🇬',
    country: Countries.AntiguaAndBarbuda,
  },
  {
    isoCode: 'AI',
    emojiFlag: '🇦🇮',
    country: Countries.Anguilla,
  },
  {
    isoCode: 'AL',
    emojiFlag: '🇦🇱',
    country: Countries.Albania,
  },
  {
    isoCode: 'AM',
    emojiFlag: '🇦🇲',
    country: Countries.Armenia,
  },
  {
    isoCode: 'AO',
    emojiFlag: '🇦🇴',
    country: Countries.Angola,
  },
  {
    isoCode: 'AQ',
    emojiFlag: '🇦🇶',
    country: Countries.Antarctica,
  },
  {
    isoCode: 'AR',
    emojiFlag: '🇦🇷',
    country: Countries.Argentina,
  },
  {
    isoCode: 'AS',
    emojiFlag: '🇦🇸',
    country: Countries.AmericanSamoa,
  },
  {
    isoCode: 'AT',
    emojiFlag: '🇦🇹',
    country: Countries.Austria,
  },
  {
    isoCode: 'AU',
    emojiFlag: '🇦🇺',
    country: Countries.Australia,
  },
  {
    isoCode: 'AW',
    emojiFlag: '🇦🇼',
    country: Countries.Aruba,
  },
  {
    isoCode: 'AX',
    emojiFlag: '🇦🇽',
    country: Countries.AlandIslands,
  },
  {
    isoCode: 'AZ',
    emojiFlag: '🇦🇿',
    country: Countries.Azerbaijan,
  },
  {
    isoCode: 'BA',
    emojiFlag: '🇧🇦',
    country: Countries.BosniaAndHerzegovina,
  },
  {
    isoCode: 'BB',
    emojiFlag: '🇧🇧',
    country: Countries.Barbados,
  },
  {
    isoCode: 'BD',
    emojiFlag: '🇧🇩',
    country: Countries.Bangladesh,
  },
  {
    isoCode: 'BE',
    emojiFlag: '🇧🇪',
    country: Countries.Belgium,
  },
  {
    isoCode: 'BF',
    emojiFlag: '🇧🇫',
    country: Countries.BurkinaFaso,
  },
  {
    isoCode: 'BG',
    emojiFlag: '🇧🇬',
    country: Countries.Bulgaria,
  },
  {
    isoCode: 'BH',
    emojiFlag: '🇧🇭',
    country: Countries.Bahrain,
  },
  {
    isoCode: 'BI',
    emojiFlag: '🇧🇮',
    country: Countries.Burundi,
  },
  {
    isoCode: 'BJ',
    emojiFlag: '🇧🇯',
    country: Countries.Benin,
  },
  {
    isoCode: 'BL',
    emojiFlag: '🇧🇱',
    country: Countries.SaintBarthelemy,
  },
  {
    isoCode: 'BM',
    emojiFlag: '🇧🇲',
    country: Countries.Bermuda,
  },
  {
    isoCode: 'BN',
    emojiFlag: '🇧🇳',
    country: Countries.Brunei,
  },
  {
    isoCode: 'BO',
    emojiFlag: '🇧🇴',
    country: Countries.Bolivia,
  },
  {
    isoCode: 'BR',
    emojiFlag: '🇧🇷',
    country: Countries.Brazil,
  },
  {
    isoCode: 'BS',
    emojiFlag: '🇧🇸',
    country: Countries.Bahamas,
  },
  {
    isoCode: 'BT',
    emojiFlag: '🇧🇹',
    country: Countries.Bhutan,
  },
  {
    isoCode: 'BV',
    emojiFlag: '🇧🇻',
    country: Countries.BouvetIslands,
  },
  {
    isoCode: 'BW',
    emojiFlag: '🇧🇼',
    country: Countries.Botswana,
  },
  {
    isoCode: 'BY',
    emojiFlag: '🇧🇾',
    country: Countries.Belarus,
  },
  {
    isoCode: 'BZ',
    emojiFlag: '🇧🇿',
    country: Countries.Belize,
  },
  {
    isoCode: 'CC',
    emojiFlag: '🇨🇨',
    country: Countries.CocosIslands,
  },
  {
    isoCode: 'CD',
    emojiFlag: '🇨🇩',
    country: Countries.DemocraticRepublicOfTheCongo,
  },
  {
    isoCode: 'CF',
    emojiFlag: '🇨🇫',
    country: Countries.CentralAfricanRepublic,
  },
  {
    isoCode: 'CG',
    emojiFlag: '🇨🇬',
    country: Countries.Congo,
  },
  {
    isoCode: 'CH',
    emojiFlag: '🇨🇭',
    country: Countries.Switzerland,
  },
  {
    isoCode: 'CI',
    emojiFlag: '🇨🇮',
    country: Countries.CoteDIvoire,
  },
  {
    isoCode: 'CK',
    emojiFlag: '🇨🇰',
    country: Countries.CookIslands,
  },
  {
    isoCode: 'CL',
    emojiFlag: '🇨🇱',
    country: Countries.Chile,
  },
  {
    isoCode: 'CM',
    emojiFlag: '🇨🇲',
    country: Countries.Cameroon,
  },
  {
    isoCode: 'CN',
    emojiFlag: '🇨🇳',
    country: Countries.China,
  },
  {
    isoCode: 'CO',
    emojiFlag: '🇨🇴',
    country: Countries.Colombia,
  },
  {
    isoCode: 'CR',
    emojiFlag: '🇨🇷',
    country: Countries.CostaRica,
  },
  {
    isoCode: 'CU',
    emojiFlag: '🇨🇺',
    country: Countries.Cuba,
  },
  {
    isoCode: 'CV',
    emojiFlag: '🇨🇻',
    country: Countries.CapeVerde,
  },
  {
    isoCode: 'CW',
    emojiFlag: '🇨🇼',
    country: Countries.Curacao,
  },
  {
    isoCode: 'CX',
    emojiFlag: '🇨🇽',
    country: Countries.ChristmasIsland,
  },
  {
    isoCode: 'CY',
    emojiFlag: '🇨🇾',
    country: Countries.Cyprus,
  },
  {
    isoCode: 'CZ',
    emojiFlag: '🇨🇿',
    country: Countries.CzechRepublic,
  },
  {
    isoCode: 'DE',
    emojiFlag: '🇩🇪',
    country: Countries.Germany,
  },
  {
    isoCode: 'DJ',
    emojiFlag: '🇩🇯',
    country: Countries.Djibouti,
  },
  {
    isoCode: 'DK',
    emojiFlag: '🇩🇰',
    country: Countries.Denmark,
  },
  {
    isoCode: 'DM',
    emojiFlag: '🇩🇲',
    country: Countries.Dominica,
  },
  {
    isoCode: 'DO',
    emojiFlag: '🇩🇴',
    country: Countries.DominicanRepublic,
  },
  {
    isoCode: 'DZ',
    emojiFlag: '🇩🇿',
    country: Countries.Algeria,
  },
  {
    isoCode: 'EC',
    emojiFlag: '🇪🇨',
    country: Countries.Ecuador,
  },
  {
    isoCode: 'EE',
    emojiFlag: '🇪🇪',
    country: Countries.Estonia,
  },
  {
    isoCode: 'EG',
    emojiFlag: '🇪🇬',
    country: Countries.Egypt,
  },
  {
    isoCode: 'EH',
    emojiFlag: '🇪🇭',
    country: Countries.WesternSahara,
  },
  {
    isoCode: 'ER',
    emojiFlag: '🇪🇷',
    country: Countries.Eritrea,
  },
  {
    isoCode: 'ES',
    emojiFlag: '🇪🇸',
    country: Countries.Spain,
  },
  {
    isoCode: 'ET',
    emojiFlag: '🇪🇹',
    country: Countries.Ethiopia,
  },
  {
    isoCode: 'FI',
    emojiFlag: '🇫🇮',
    country: Countries.Finland,
  },
  {
    isoCode: 'FJ',
    emojiFlag: '🇫🇯',
    country: Countries.Fiji,
  },
  {
    isoCode: 'FK',
    emojiFlag: '🇫🇰',
    country: Countries.FalklandIslands,
  },
  {
    isoCode: 'FM',
    emojiFlag: '🇫🇲',
    country: Countries.Micronesia,
  },
  {
    isoCode: 'FO',
    emojiFlag: '🇫🇴',
    country: Countries.FaroeIslands,
  },
  {
    isoCode: 'FR',
    emojiFlag: '🇫🇷',
    country: Countries.France,
  },
  {
    isoCode: 'GA',
    emojiFlag: '🇬🇦',
    country: Countries.Gabon,
  },
  {
    isoCode: 'GB',
    emojiFlag: '🇬🇧',
    country: Countries.UnitedKingdom,
  },
  {
    isoCode: 'GD',
    emojiFlag: '🇬🇩',
    country: Countries.Grenada,
  },
  {
    isoCode: 'GE',
    emojiFlag: '🇬🇪',
    country: Countries.Georgia,
  },
  {
    isoCode: 'GF',
    emojiFlag: '🇬🇫',
    country: Countries.FrenchGuiana,
  },
  {
    isoCode: 'GG',
    emojiFlag: '🇬🇬',
    country: Countries.Guernsey,
  },
  {
    isoCode: 'GH',
    emojiFlag: '🇬🇭',
    country: Countries.Ghana,
  },
  {
    isoCode: 'GI',
    emojiFlag: '🇬🇮',
    country: Countries.Gibraltar,
  },
  {
    isoCode: 'GL',
    emojiFlag: '🇬🇱',
    country: Countries.Greenland,
  },
  {
    isoCode: 'GM',
    emojiFlag: '🇬🇲',
    country: Countries.Gambia,
  },
  {
    isoCode: 'GN',
    emojiFlag: '🇬🇳',
    country: Countries.Guinea,
  },
  {
    isoCode: 'GP',
    emojiFlag: '🇬🇵',
    country: Countries.Guadeloupe,
  },
  {
    isoCode: 'GQ',
    emojiFlag: '🇬🇶',
    country: Countries.EquatorialGuinea,
  },
  {
    isoCode: 'GR',
    emojiFlag: '🇬🇷',
    country: Countries.Greece,
  },
  {
    isoCode: 'GS',
    emojiFlag: '🇬🇸',
    country: Countries.SouthGeorgia,
  },
  {
    isoCode: 'GT',
    emojiFlag: '🇬🇹',
    country: Countries.Guatemala,
  },
  {
    isoCode: 'GU',
    emojiFlag: '🇬🇺',
    country: Countries.Guam,
  },
  {
    isoCode: 'GW',
    emojiFlag: '🇬🇼',
    country: Countries.GuineaBissau,
  },
  {
    isoCode: 'GY',
    emojiFlag: '🇬🇾',
    country: Countries.Guyana,
  },
  {
    isoCode: 'HK',
    emojiFlag: '🇭🇰',
    country: Countries.HongKong,
  },
  {
    isoCode: 'HM',
    emojiFlag: '🇭🇲',
    country: Countries.HeardIslandAndMcdonaldIslands,
  },
  {
    isoCode: 'HN',
    emojiFlag: '🇭🇳',
    country: Countries.Honduras,
  },
  {
    isoCode: 'HR',
    emojiFlag: '🇭🇷',
    country: Countries.Croatia,
  },
  {
    isoCode: 'HT',
    emojiFlag: '🇭🇹',
    country: Countries.Haiti,
  },
  {
    isoCode: 'HU',
    emojiFlag: '🇭🇺',
    country: Countries.Hungary,
  },
  {
    isoCode: 'ID',
    emojiFlag: '🇮🇩',
    country: Countries.Indonesia,
  },
  {
    isoCode: 'IE',
    emojiFlag: '🇮🇪',
    country: Countries.Ireland,
  },
  {
    isoCode: 'IL',
    emojiFlag: '🇮🇱',
    country: Countries.Israel,
  },
  {
    isoCode: 'IM',
    emojiFlag: '🇮🇲',
    country: Countries.IsleOfMan,
  },
  {
    isoCode: 'IN',
    emojiFlag: '🇮🇳',
    country: Countries.India,
  },
  {
    isoCode: 'IQ',
    emojiFlag: '🇮🇶',
    country: Countries.Iraq,
  },
  {
    isoCode: 'IR',
    emojiFlag: '🇮🇷',
    country: Countries.Iran,
  },
  {
    isoCode: 'IS',
    emojiFlag: '🇮🇸',
    country: Countries.Iceland,
  },
  {
    isoCode: 'IT',
    emojiFlag: '🇮🇹',
    country: Countries.Italy,
  },
  {
    isoCode: 'JE',
    emojiFlag: '🇯🇪',
    country: Countries.Jersey,
  },
  {
    isoCode: 'JM',
    emojiFlag: '🇯🇲',
    country: Countries.Jamaica,
  },
  {
    isoCode: 'JO',
    emojiFlag: '🇯🇴',
    country: Countries.Jordan,
  },
  {
    isoCode: 'JP',
    emojiFlag: '🇯🇵',
    country: Countries.Japan,
  },
  {
    isoCode: 'KE',
    emojiFlag: '🇰🇪',
    country: Countries.Kenya,
  },
  {
    isoCode: 'KG',
    emojiFlag: '🇰🇬',
    country: Countries.Kyrgyzstan,
  },
  {
    isoCode: 'KH',
    emojiFlag: '🇰🇭',
    country: Countries.Cambodia,
  },
  {
    isoCode: 'KI',
    emojiFlag: '🇰🇮',
    country: Countries.Kiribati,
  },
  {
    isoCode: 'KM',
    emojiFlag: '🇰🇲',
    country: Countries.Comoros,
  },
  {
    isoCode: 'KN',
    emojiFlag: '🇰🇳',
    country: Countries.SaintKittsAndNevis,
  },
  {
    isoCode: 'KP',
    emojiFlag: '🇰🇵',
    country: Countries.NorthKorea,
  },
  {
    isoCode: 'KR',
    emojiFlag: '🇰🇷',
    country: Countries.SouthKorea,
  },
  {
    isoCode: 'KW',
    emojiFlag: '🇰🇼',
    country: Countries.Kuwait,
  },
  {
    isoCode: 'KY',
    emojiFlag: '🇰🇾',
    country: Countries.CaymanIslands,
  },
  {
    isoCode: 'KZ',
    emojiFlag: '🇰🇿',
    country: Countries.Kazakhstan,
  },
  {
    isoCode: 'LA',
    emojiFlag: '🇱🇦',
    country: Countries.Laos,
  },
  {
    isoCode: 'LB',
    emojiFlag: '🇱🇧',
    country: Countries.Lebanon,
  },
  {
    isoCode: 'LC',
    emojiFlag: '🇱🇨',
    country: Countries.SaintLucia,
  },
  {
    isoCode: 'LI',
    emojiFlag: '🇱🇮',
    country: Countries.Liechtenstein,
  },
  {
    isoCode: 'LK',
    emojiFlag: '🇱🇰',
    country: Countries.SriLanka,
  },
  {
    isoCode: 'LR',
    emojiFlag: '🇱🇷',
    country: Countries.Liberia,
  },
  {
    isoCode: 'LS',
    emojiFlag: '🇱🇸',
    country: Countries.Lesotho,
  },
  {
    isoCode: 'LT',
    emojiFlag: '🇱🇹',
    country: Countries.Lithuania,
  },
  {
    isoCode: 'LU',
    emojiFlag: '🇱🇺',
    country: Countries.Luxembourg,
  },
  {
    isoCode: 'LV',
    emojiFlag: '🇱🇻',
    country: Countries.Latvia,
  },
  {
    isoCode: 'LY',
    emojiFlag: '🇱🇾',
    country: Countries.Libya,
  },
  {
    isoCode: 'MA',
    emojiFlag: '🇲🇦',
    country: Countries.Morocco,
  },
  {
    isoCode: 'MC',
    emojiFlag: '🇲🇨',
    country: Countries.Monaco,
  },
  {
    isoCode: 'MD',
    emojiFlag: '🇲🇩',
    country: Countries.Moldova,
  },
  {
    isoCode: 'ME',
    emojiFlag: '🇲🇪',
    country: Countries.Montenegro,
  },
  {
    isoCode: 'MF',
    emojiFlag: '🇲🇫',
    country: Countries.SaintMartin,
  },
  {
    isoCode: 'MG',
    emojiFlag: '🇲🇬',
    country: Countries.Madagascar,
  },
  {
    isoCode: 'MH',
    emojiFlag: '🇲🇭',
    country: Countries.MarshallIslands,
  },
  {
    isoCode: 'MK',
    emojiFlag: '🇲🇰',
    country: Countries.NorthMacedonia,
  },
  {
    isoCode: 'ML',
    emojiFlag: '🇲🇱',
    country: Countries.Mali,
  },
  {
    isoCode: 'MM',
    emojiFlag: '🇲🇲',
    country: Countries.Myanmar,
  },
  {
    isoCode: 'MN',
    emojiFlag: '🇲🇳',
    country: Countries.Mongolia,
  },
  {
    isoCode: 'MO',
    emojiFlag: '🇲🇴',
    country: Countries.Macao,
  },
  {
    isoCode: 'MP',
    emojiFlag: '🇲🇵',
    country: Countries.NorthMarianaIslands,
  },
  {
    isoCode: 'MQ',
    emojiFlag: '🇲🇶',
    country: Countries.Martinique,
  },
  {
    isoCode: 'MR',
    emojiFlag: '🇲🇷',
    country: Countries.Mauritania,
  },
  {
    isoCode: 'MS',
    emojiFlag: '🇲🇸',
    country: Countries.Montserrat,
  },
  {
    isoCode: 'MT',
    emojiFlag: '🇲🇹',
    country: Countries.Malta,
  },
  {
    isoCode: 'MU',
    emojiFlag: '🇲🇺',
    country: Countries.Mauritius,
  },
  {
    isoCode: 'MV',
    emojiFlag: '🇲🇻',
    country: Countries.Maldives,
  },
  {
    isoCode: 'MW',
    emojiFlag: '🇲🇼',
    country: Countries.Malawi,
  },
  {
    isoCode: 'MX',
    emojiFlag: '🇲🇽',
    country: Countries.Mexico,
  },
  {
    isoCode: 'MY',
    emojiFlag: '🇲🇾',
    country: Countries.Malaysia,
  },
  {
    isoCode: 'MZ',
    emojiFlag: '🇲🇿',
    country: Countries.Mozambique,
  },
  {
    isoCode: 'NA',
    emojiFlag: '🇳🇦',
    country: Countries.Namibia,
  },
  {
    isoCode: 'NC',
    emojiFlag: '🇳🇨',
    country: Countries.NewCaledonia,
  },
  {
    isoCode: 'NE',
    emojiFlag: '🇳🇪',
    country: Countries.Niger,
  },
  {
    isoCode: 'NF',
    emojiFlag: '🇳🇫',
    country: Countries.NorfolkIsland,
  },
  {
    isoCode: 'NG',
    emojiFlag: '🇳🇬',
    country: Countries.Nigeria,
  },
  {
    isoCode: 'NI',
    emojiFlag: '🇳🇮',
    country: Countries.Nicaragua,
  },
  {
    isoCode: 'NL',
    emojiFlag: '🇳🇱',
    country: Countries.Netherlands,
  },
  {
    isoCode: 'NO',
    emojiFlag: '🇳🇴',
    country: Countries.Norway,
  },
  {
    isoCode: 'NP',
    emojiFlag: '🇳🇵',
    country: Countries.Nepal,
  },
  {
    isoCode: 'NR',
    emojiFlag: '🇳🇷',
    country: Countries.Nauru,
  },
  {
    isoCode: 'NU',
    emojiFlag: '🇳🇺',
    country: Countries.Niue,
  },
  {
    isoCode: 'NZ',
    emojiFlag: '🇳🇿',
    country: Countries.NewZealand,
  },
  {
    isoCode: 'OM',
    emojiFlag: '🇴🇲',
    country: Countries.Oman,
  },
  {
    isoCode: 'PA',
    emojiFlag: '🇵🇦',
    country: Countries.Panama,
  },
  {
    isoCode: 'PE',
    emojiFlag: '🇵🇪',
    country: Countries.Peru,
  },
  {
    isoCode: 'PF',
    emojiFlag: '🇵🇫',
    country: Countries.FrenchPolynesia,
  },
  {
    isoCode: 'PG',
    emojiFlag: '🇵🇬',
    country: Countries.PapuaNewGuinea,
  },
  {
    isoCode: 'PH',
    emojiFlag: '🇵🇭',
    country: Countries.Philippines,
  },
  {
    isoCode: 'PK',
    emojiFlag: '🇵🇰',
    country: Countries.Pakistan,
  },
  {
    isoCode: 'PL',
    emojiFlag: '🇵🇱',
    country: Countries.Poland,
  },
  {
    isoCode: 'PM',
    emojiFlag: '🇵🇲',
    country: Countries.SaintPierreAndMiquelon,
  },
  {
    isoCode: 'PN',
    emojiFlag: '🇵🇳',
    country: Countries.Pitcairn,
  },
  {
    isoCode: 'PR',
    emojiFlag: '🇵🇷',
    country: Countries.PuertoRico,
  },
  {
    isoCode: 'PT',
    emojiFlag: '🇵🇹',
    country: Countries.Portugal,
  },
  {
    isoCode: 'PW',
    emojiFlag: '🇵🇼',
    country: Countries.Palau,
  },
  {
    isoCode: 'PY',
    emojiFlag: '🇵🇾',
    country: Countries.Paraguay,
  },
  {
    isoCode: 'QA',
    emojiFlag: '🇶🇦',
    country: Countries.Qatar,
  },
  {
    isoCode: 'RO',
    emojiFlag: '🇷🇴',
    country: Countries.Romania,
  },
  {
    isoCode: 'RS',
    emojiFlag: '🇷🇸',
    country: Countries.Serbia,
  },
  {
    isoCode: 'RU',
    emojiFlag: '🇷🇺',
    country: Countries.Russia,
  },
  {
    isoCode: 'RW',
    emojiFlag: '🇷🇼',
    country: Countries.Rwanda,
  },
  {
    isoCode: 'SA',
    emojiFlag: '🇸🇦',
    country: Countries.SaudiArabia,
  },
  {
    isoCode: 'SB',
    emojiFlag: '🇸🇧',
    country: Countries.SolomonIslands,
  },
  {
    isoCode: 'SC',
    emojiFlag: '🇸🇨',
    country: Countries.Seychelles,
  },
  {
    isoCode: 'SD',
    emojiFlag: '🇸🇩',
    country: Countries.Sudan,
  },
  {
    isoCode: 'SE',
    emojiFlag: '🇸🇪',
    country: Countries.Sweden,
  },
  {
    isoCode: 'SG',
    emojiFlag: '🇸🇬',
    country: Countries.Singapore,
  },
  {
    isoCode: 'SH',
    emojiFlag: '🇸🇭',
    country: Countries.SaintHelena,
  },
  {
    isoCode: 'SI',
    emojiFlag: '🇸🇮',
    country: Countries.Slovenia,
  },
  {
    isoCode: 'SJ',
    emojiFlag: '🇸🇯',
    country: Countries.SvalbardAndJanMayen,
  },
  {
    isoCode: 'SK',
    emojiFlag: '🇸🇰',
    country: Countries.Slovakia,
  },
  {
    isoCode: 'SL',
    emojiFlag: '🇸🇱',
    country: Countries.SierraLeone,
  },
  {
    isoCode: 'SM',
    emojiFlag: '🇸🇲',
    country: Countries.SanMarino,
  },
  {
    isoCode: 'SN',
    emojiFlag: '🇸🇳',
    country: Countries.Senegal,
  },
  {
    isoCode: 'SO',
    emojiFlag: '🇸🇴',
    country: Countries.Somalia,
  },
  {
    isoCode: 'SR',
    emojiFlag: '🇸🇷',
    country: Countries.Suriname,
  },
  {
    isoCode: 'SS',
    emojiFlag: '🇸🇸',
    country: Countries.SouthSudan,
  },
  {
    isoCode: 'SV',
    emojiFlag: '🇸🇻',
    country: Countries.ElSalvador,
  },
  {
    isoCode: 'SY',
    emojiFlag: '🇸🇾',
    country: Countries.Syria,
  },
  {
    isoCode: 'SZ',
    emojiFlag: '🇸🇿',
    country: Countries.Swaziland,
  },
  {
    isoCode: 'TC',
    emojiFlag: '🇹🇨',
    country: Countries.TurksAndCaicosIslands,
  },
  {
    isoCode: 'TD',
    emojiFlag: '🇹🇩',
    country: Countries.Chad,
  },
  {
    isoCode: 'TF',
    emojiFlag: '🇹🇫',
    country: Countries.FrenchSouthernTerritories,
  },
  {
    isoCode: 'TG',
    emojiFlag: '🇹🇬',
    country: Countries.Togo,
  },
  {
    isoCode: 'TH',
    emojiFlag: '🇹🇭',
    country: Countries.Thailand,
  },
  {
    isoCode: 'TJ',
    emojiFlag: '🇹🇯',
    country: Countries.Tajikistan,
  },
  {
    isoCode: 'TK',
    emojiFlag: '🇹🇰',
    country: Countries.Tokelau,
  },
  {
    isoCode: 'TL',
    emojiFlag: '🇹🇱',
    country: Countries.TimorLeste,
  },
  {
    isoCode: 'TM',
    emojiFlag: '🇹🇲',
    country: Countries.Turkmenistan,
  },
  {
    isoCode: 'TN',
    emojiFlag: '🇹🇳',
    country: Countries.Tunisia,
  },
  {
    isoCode: 'TO',
    emojiFlag: '🇹🇴',
    country: Countries.Tonga,
  },
  {
    isoCode: 'TR',
    emojiFlag: '🇹🇷',
    country: Countries.Turkey,
  },
  {
    isoCode: 'TT',
    emojiFlag: '🇹🇹',
    country: Countries.TrinidadAndTobago,
  },
  {
    isoCode: 'TV',
    emojiFlag: '🇹🇻',
    country: Countries.Tuvalu,
  },
  {
    isoCode: 'TW',
    emojiFlag: '🇹🇼',
    country: Countries.Taiwan,
  },
  {
    isoCode: 'TZ',
    emojiFlag: '🇹🇿',
    country: Countries.Tanzania,
  },
  {
    isoCode: 'UA',
    emojiFlag: '🇺🇦',
    country: Countries.Ukraine,
  },
  {
    isoCode: 'UG',
    emojiFlag: '🇺🇬',
    country: Countries.Uganda,
  },
  {
    isoCode: 'UY',
    emojiFlag: '🇺🇾',
    country: Countries.Uruguay,
  },
  {
    isoCode: 'UZ',
    emojiFlag: '🇺🇿',
    country: Countries.Uzbekistan,
  },
  {
    isoCode: 'VA',
    emojiFlag: '🇻🇦',
    country: Countries.VaticanCity,
  },
  {
    isoCode: 'VC',
    emojiFlag: '🇻🇨',
    country: Countries.SaintVincentAndTheGrenadines,
  },
  {
    isoCode: 'VE',
    emojiFlag: '🇻🇪',
    country: Countries.Venezuela,
  },
  {
    isoCode: 'VG',
    emojiFlag: '🇻🇬',
    country: Countries.VirginIslandsUK,
  },
  {
    isoCode: 'VI',
    emojiFlag: '🇻🇮',
    country: Countries.VirginIslandsUS,
  },
  {
    isoCode: 'VN',
    emojiFlag: '🇻🇳',
    country: Countries.Vietnam,
  },
  {
    isoCode: 'VU',
    emojiFlag: '🇻🇺',
    country: Countries.Vanuatu,
  },
  {
    isoCode: 'WF',
    emojiFlag: '🇼🇫',
    country: Countries.WallisAndFutuna,
  },
  {
    isoCode: 'WS',
    emojiFlag: '🇼🇸',
    country: Countries.Samoa,
  },
  {
    isoCode: 'YE',
    emojiFlag: '🇾🇪',
    country: Countries.Yemen,
  },
  {
    isoCode: 'YT',
    emojiFlag: '🇾🇹',
    country: Countries.Mayotte,
  },
  {
    isoCode: 'ZA',
    emojiFlag: '🇿🇦',
    country: Countries.SouthAfrica,
  },
  {
    isoCode: 'ZM',
    emojiFlag: '🇿🇲',
    country: Countries.Zambia,
  },
  {
    isoCode: 'ZW',
    emojiFlag: '🇿🇼',
    country: Countries.Zimbabwe,
  },
];

export const shippingCountriesOptions = [
  {
    title: 'Asia Pacific',
    items: [
      Countries.Bangladesh,
      Countries.Bhutan,
      Countries.Brunei,
      Countries.Cambodia,
      Countries.China,
      Countries.HongKong,
      Countries.India,
      Countries.Indonesia,
      Countries.Japan,
      Countries.Kazakhstan,
      Countries.Kyrgyzstan,
      Countries.Laos,
      Countries.Macao,
      Countries.Malaysia,
      Countries.Maldives,
      Countries.Mongolia,
      Countries.Myanmar,
      Countries.Nepal,
      Countries.Philippines,
      Countries.Singapore,
      Countries.SouthKorea,
      Countries.SriLanka,
      Countries.Taiwan,
      Countries.Tajikistan,
      Countries.Thailand,
      Countries.Turkmenistan,
      Countries.Uzbekistan,
      Countries.Vietnam,
    ],
  },
  {
    title: 'Australia, New Zealand and Oceania',
    items: [
      Countries.AmericanSamoa,
      Countries.Australia,
      Countries.ChristmasIsland,
      Countries.CocosIslands,
      Countries.CookIslands,
      Countries.Fiji,
      Countries.FrenchPolynesia,
      Countries.Guam,
      Countries.Kiribati,
      Countries.MarshallIslands,
      Countries.Micronesia,
      Countries.Nauru,
      Countries.NewCaledonia,
      Countries.NewZealand,
      Countries.Niue,
      Countries.NorfolkIsland,
      Countries.NorthMarianaIslands,
      Countries.Palau,
      Countries.PapuaNewGuinea,
      Countries.Samoa,
      Countries.SolomonIslands,
      Countries.TimorLeste,
      Countries.Tokelau,
      Countries.Tonga,
      Countries.Tuvalu,
      Countries.Vanuatu,
      Countries.WallisAndFutuna,
    ],
  },
  {
    title: 'Europe',
    items: [
      Countries.Albania,
      Countries.Andorra,
      Countries.Armenia,
      Countries.Austria,
      Countries.Belgium,
      Countries.BosniaAndHerzegovina,
      Countries.Bulgaria,
      Countries.Croatia,
      Countries.Cyprus,
      Countries.CzechRepublic,
      Countries.Denmark,
      Countries.Estonia,
      Countries.FaroeIslands,
      Countries.Finland,
      Countries.France,
      Countries.Georgia,
      Countries.Germany,
      Countries.Gibraltar,
      Countries.Greece,
      Countries.Greenland,
      Countries.VaticanCity,
      Countries.Hungary,
      Countries.Iceland,
      Countries.Ireland,
      Countries.IsleOfMan,
      Countries.Italy,
      Countries.Kosovo,
      Countries.Latvia,
      Countries.Liechtenstein,
      Countries.Lithuania,
      Countries.Luxembourg,
      Countries.NorthMacedonia,
      Countries.Malta,
      Countries.Moldova,
      Countries.Monaco,
      Countries.Montenegro,
      Countries.Norway,
      Countries.Poland,
      Countries.Portugal,
      Countries.Romania,
      Countries.SanMarino,
      Countries.Serbia,
      Countries.Slovakia,
      Countries.Slovenia,
      Countries.Spain,
      Countries.Sweden,
      Countries.Switzerland,
      Countries.Netherlands,
      Countries.Turkey,
      Countries.Ukraine,
      Countries.UnitedKingdom,
    ],
  },
  {
    title: 'Latin America and the Caribbean',
    items: [
      Countries.Anguilla,
      Countries.AntiguaAndBarbuda,
      Countries.Argentina,
      Countries.Aruba,
      Countries.Bahamas,
      Countries.Barbados,
      Countries.Belize,
      Countries.Bermuda,
      Countries.Bolivia,
      Countries.Brazil,
      Countries.VirginIslandsUK,
      Countries.CaymanIslands,
      Countries.Chile,
      Countries.Colombia,
      Countries.CostaRica,
      Countries.Curacao,
      Countries.Dominica,
      Countries.DominicanRepublic,
      Countries.Ecuador,
      Countries.ElSalvador,
      Countries.FalklandIslands,
      Countries.FrenchGuiana,
      Countries.Grenada,
      Countries.Guadeloupe,
      Countries.Guatemala,
      Countries.Guyana,
      Countries.Haiti,
      Countries.Honduras,
      Countries.Jamaica,
      Countries.Martinique,
      Countries.Mayotte,
      Countries.Mexico,
      Countries.Montserrat,
      Countries.Nicaragua,
      Countries.Panama,
      Countries.Paraguay,
      Countries.Peru,
      Countries.SaintKittsAndNevis,
      Countries.SaintLucia,
      Countries.SaintMartin,
      Countries.SaintVincentAndTheGrenadines,
      Countries.Suriname,
      Countries.TrinidadAndTobago,
      Countries.TurksAndCaicosIslands,
      Countries.VirginIslandsUS,
      Countries.Uruguay,
      Countries.Venezuela,
    ],
  },
  {
    title: 'North Africa and the Middle East',
    items: [
      Countries.Afghanistan,
      Countries.Algeria,
      Countries.Azerbaijan,
      Countries.Bahrain,
      Countries.Egypt,
      Countries.Iraq,
      Countries.Israel,
      Countries.Jordan,
      Countries.Kuwait,
      Countries.Lebanon,
      Countries.Libya,
      Countries.Morocco,
      Countries.Oman,
      Countries.Pakistan,
      Countries.Qatar,
      Countries.SaudiArabia,
      Countries.Somalia,
      Countries.Tunisia,
      Countries.UnitedArabEmirates,
      Countries.Yemen,
    ],
  },
  {
    title: 'North America',
    items: [
      Countries.Canada,
      Countries.PuertoRico,
      Countries.SaintPierreAndMiquelon,
      Countries.UnitedStates,
    ],
  },
  {
    title: 'Sub-Saharan Africa',
    items: [
      Countries.Angola,
      Countries.Benin,
      Countries.Botswana,
      Countries.BurkinaFaso,
      Countries.Burundi,
      Countries.Cameroon,
      Countries.CapeVerde,
      Countries.CentralAfricanRepublic,
      Countries.Chad,
      Countries.Comoros,
      Countries.Congo,
      Countries.Djibouti,
      Countries.EquatorialGuinea,
      Countries.Eritrea,
      Countries.Ethiopia,
      Countries.Gabon,
      Countries.Gambia,
      Countries.Ghana,
      Countries.Guinea,
      Countries.GuineaBissau,
      Countries.CoteDIvoire,
      Countries.Kenya,
      Countries.Lesotho,
      Countries.Liberia,
      Countries.Madagascar,
      Countries.Malawi,
      Countries.Mali,
      Countries.Mauritania,
      Countries.Mauritius,
      Countries.Mozambique,
      Countries.Namibia,
      Countries.Niger,
      Countries.Nigeria,
      Countries.Rwanda,
      Countries.SaintHelena,
      Countries.SaoTomeAndPrincipe,
      Countries.Senegal,
      Countries.Seychelles,
      Countries.SierraLeone,
      Countries.SouthAfrica,
      Countries.Sudan,
      Countries.Swaziland,
      Countries.Tanzania,
      Countries.Togo,
      Countries.Uganda,
      Countries.DemocraticRepublicOfTheCongo,
      Countries.Zambia,
      Countries.Zimbabwe,
    ],
  },
];
