import { Directions, IFilterState, Tabs } from 'types';
import { ROUTES } from 'constant';

export const parseQuery = (queryString: any) => {
  const query = {};

  const pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');
  pairs.forEach((item: any) => {
    const pair: any = item.split('=');
    // @ts-ignore
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  });

  return query;
};

export const getUrlTextParamValue = () =>
  (parseQuery(window.location.search) as { text: string })?.text;

export const getUrlFilterParams = () => {
  const url = parseQuery(window.location.search) as {
    countries: string;
    stars: number;
    types: string;
    text: string;
    sortBy?: string;
    dir: Directions;
    tab: Tabs;
  };

  const sortBy = {
    type: url.sortBy || '',
    direction: url.dir || Directions.DESC,
  };

  const search = url.text || '';

  const countries = url.countries?.split(',') || [];

  const companyTypes = url.types?.split(',') || [];

  const stars = url.stars ? Number(url.stars) : 0;

  const tab = url.tab || Tabs.All;

  return {
    countries,
    companyTypes,
    sortBy,
    search,
    minRate: stars,
    tab,
  };
};

export const buildFilterUrl = (filters: IFilterState) => {
  const { search, minRate, countries, companyTypes, sortBy, tab } = filters;

  const query: any = {};

  if (countries.length > 0) {
    query.countries = countries.join(',');
  }
  if (search && search !== '') {
    query.text = search;
  }
  if (companyTypes.length > 0) {
    query.types = companyTypes.join(',');
  }
  if (minRate) {
    query.stars = minRate;
  }
  if (sortBy && sortBy.type !== '') {
    query.sortBy = sortBy.type;
    query.dir = sortBy.direction;
  }

  if (tab) {
    query.tab = tab;
  }

  let url = '';
  if (query) {
    Object.entries(query).map(
      ([key, value]: any) => (url += `&${key}=${encodeURIComponent(value)}`),
    );
    url = `?${url.slice(1)}`;
  }

  return url;
};

export const buildChatUrl = (details: {
  userId: string;
  username: string;
  avatar: string;
  fullName: string;
}) => {
  const { userId, username, avatar, fullName } = details;

  const query: any = {
    username,
    avatar,
    user_id: userId,
    fullName,
  };

  let url = '';
  if (query) {
    Object.entries(query).map(
      ([key, value]: any) => (url += `&${key}=${encodeURIComponent(value)}`),
    );
    url = `/${ROUTES.CHAT}/${userId}?${url.slice(1)}`;
  }

  return url;
};

export const buildDiscoverPageQuery = (options: {
  id: string;
  title: string;
  paginationToken: string | null;
}) => {
  const { id, title, paginationToken } = options;

  const query: any = {
    id,
    title,
  };
  if (paginationToken) {
    query.token = paginationToken;
  }

  let url = '';
  if (query) {
    Object.entries(query).map(
      ([key, value]: any) => (url += `&${key}=${encodeURIComponent(value)}`),
    );
    url = `?${url.slice(1)}`;
  }

  return url;
};

export const checksIsVideo = (src?: string | null) => {
  if (!src) return false;

  return src.endsWith('.mp4') || src.endsWith('.mov') || src.endsWith('quicktime');
};

export const downloadFile = (fileUrl: string, fileName: string) => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => console.error('Error:', error));
};

export const downloadImage = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = url;
  link.click();
};
