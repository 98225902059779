import { IStore2 } from 'types';

const selectMessageIdSelector = (state: IStore2) => state.message.id;

const linkSelector = (state: IStore2) => state.message.link;

const serverStatusSelector = (state: IStore2) => state.message.serverStatus;

const postIdSelector = (state: IStore2) => state.message.postId;

const actionIdSelector = (state: IStore2) => state.message.actionId;

const isLikedSelector = (state: IStore2) => state.message.isLiked;

export const MessageSelector = {
  selectMessageIdSelector,
  linkSelector,
  serverStatusSelector,
  postIdSelector,
  actionIdSelector,
  isLikedSelector,
};
