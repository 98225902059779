import './interestsGroups.scss';
import { FC } from 'react';

import { IInterestsGroups } from 'types';
import { NoResult } from 'pages/noResult/NoResult';
import { translations } from 'translations/en';
import { isEmptyArray } from 'utils';
import InterestItemButton from '../interestItemButton/InterestItemButton';
import InterestGroup from '../interestsItemsButtons/InterestsItemsButtons';

const { SELECTED_INTERESTS } = translations.ONBOARDING.INTERESTS_STEP.CATEGORY_SECTION;

const InterestsGroups: FC<IInterestsGroups> = ({
  interests,
  searchText,
  filteredInterests,
  hasSelectedInterests,
  onItemClick,
}) => {
  const shouldDisplayEmptyState = isEmptyArray(Object.keys(filteredInterests));

  const renderSelectedInterests = () => {
    const interestsTitles = Object.keys(interests);

    return interestsTitles.map((title) =>
      interests[title].map((interestItem) => {
        if (interestItem.isSelected) {
          return (
            <InterestItemButton
              interestId={interestItem.id}
              title={title}
              text={interestItem.text}
              isSelected={interestItem.isSelected}
              onItemClick={onItemClick}
            />
          );
        }

        return null;
      }),
    );
  };

  const renderFilteredInterests = () => {
    return Object.keys(filteredInterests).map((interestTitle: string, index: number) => (
      <InterestGroup
        key={index}
        interestTitle={interestTitle}
        interests={filteredInterests[interestTitle]}
        onItemClick={onItemClick}
      />
    ));
  };

  return (
    <div className="interests-groups-content-container">
      {!shouldDisplayEmptyState && (
        <div className="selected-section">
          {hasSelectedInterests && <span className="selected-title">{SELECTED_INTERESTS}</span>}
          <div className="selected-interests-wrapper">{renderSelectedInterests()}</div>
        </div>
      )}
      <div className="results-section">{renderFilteredInterests()}</div>
      {shouldDisplayEmptyState && <NoResult searchText={searchText} />}
    </div>
  );
};

export default InterestsGroups;
