import {
  ITextLabel,
  IStoreDetails,
  IProductShippingInformation,
  IPrice,
  IDiscoverSectionTitle,
  IDiscoverPageComponent,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import {
  DiscoverPageResult,
  GetProductBannerResponse,
  GetProductByIdResponse,
} from 'graphql/generated/graphql';

export interface ISpecificProductApi {
  getSpecificProduct(productId: string, token?: string): Promise<IGetSpecificProductResponse>;
  toggleLikeProduct(productId: string, isLiked: boolean, token: string): Promise<void>;
  getProductEstimatedDelivery(
    productId: string,
    token: string,
  ): Promise<IGetProductEstimatedDeliveryResponse>;
}

export interface ISpecificProductState {
  source: ProductPageSources | null;
  userId: string;
  shopId: string;
  id: string;
  images: string[];
  price: number;
  currencySymbol: string;
  currencyCode: CurrencyCode;
  title: string;
  description: string;
  labels: IProductLabel[] | null;
  originalPrice: number;
  convertedFromPrice: IPrice | null;
  category: string[] | null;
  updateTime: number | null;
  isDigital: boolean;
  hasInstantCheckout: boolean;
  totalLikes: number;
  isLiked: boolean;
  quantity: number | null;
  bannerDetails: IProductBannerDetails | null;
  shopDetails: IStoreDetails | null;
  shippingInformation: IProductShippingInformation | null;
  tariffNumber: string | null;
  estimatedDeliveryTime: { min: number; max: number } | null;
  vacationDetails: { text: string; date: number } | null;
  productCarousels: IDiscoverPageComponent[] | null;
  isLoading: boolean;
}

export interface IGetSpecificProductResponse {
  getProductById: GetProductByIdResponse;
  getProductBanner: GetProductBannerResponse;
  getDiscoverPageData: DiscoverPageResult;
}

export interface IGetProductEstimatedDeliveryResponse {
  startDate: number;
  endDate: number;
}

export interface ILabelsResponse {
  texts: ITextLabel[];
  backgroundColor: string;
  textColor: string;
}

export interface IProductLabel {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export interface IProductBannerDetails {
  id: string;
  image: string;
  borderColor: string;
  borderWidth: number;
  backgroundColor: string;
  text: IDiscoverSectionTitle[];
}

export enum ProductPageSources {
  Feed = 'feed',
  Discover = 'discover',
  SearchResult = 'search_result',
  Shop = 'shop',
  Mojos = 'mojos',
  Favorites = 'favorites',
  Basket = 'basket',
}
