import './tedoooProBox.scss';
import { Button } from 'tedooo-web-design-system/ui';
import { FC } from 'react';
import { Checkmark } from 'tedooo-web-design-system/icons';

import tedoooProLogo from 'photos/premium/tedooo-pro-title-logo.png';
import { Image } from '../../helpers';
import { translations } from 'translations/en';
import { popupIdsOptions } from 'store/popup/types';
import { IBoostYourShopVisibilityBox } from 'types';

const { TITLE, BENEFIT_1, BENEFIT_2, BENEFIT_3, BUTTON_TEXT } =
  translations.PREMIUM.BOOST_YOUR_SHOP_BOX;

export const TedoooProBox: FC<IBoostYourShopVisibilityBox> = ({ showPopup }) => {
  const onClick = () => {
    showPopup({ id: popupIdsOptions.upgradeToProPopup });
  };

  const benefits = [BENEFIT_1, BENEFIT_2, BENEFIT_3];

  return (
    <div className="tedooo-pro-box-container">
      <Image src={tedoooProLogo} className="tedooo-pro-logo-image" />
      <span className="title">{TITLE}</span>
      {benefits.map((benefit, index) => (
        <div className="benefits-row" key={index}>
          <Checkmark />
          <span className="benefit" key={index}>
            {benefit}
          </span>
        </div>
      ))}
      <Button
        type="primary"
        size="medium"
        borderRadius="large"
        text={BUTTON_TEXT}
        onClick={onClick}
        isFullWidth
      />
    </div>
  );
};

export default TedoooProBox;
