import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAddCommentOptions, IAddReplyOptions, IStore2 } from 'types';
import { SpecificPostApi } from './SpecificPostApi';
import { getSelectedComment, getSelectedPost } from './helper';
import { isEmptyArrayOrUndefined } from 'utils';
import { checkTaggedUsers } from '../feed/helper';

const reducer = new SpecificPostApi();

export const getPost = createAsyncThunk(
  'async/getPost',
  async (input: { id: string; token?: string }) => {
    const { id, token } = input;

    return reducer.getSpecificPost(id, token);
  },
);

export const getShopImage = createAsyncThunk(
  'async/getShopImage',
  async (input: { id: string; token: string }) => {
    const { id, token } = input;

    return reducer.getShopImage(id, token);
  },
);

export const onLikeToggle = createAsyncThunk(
  'async/onSpecificPostLikeToggle',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedPostId, actionId, isLiked } = state.specificPost;

    return reducer.onLike(selectedPostId || actionId, isLiked, token);
  },
);

export const onLikeComment = createAsyncThunk(
  'async/onSpecificLikeComment',
  async (input: { commentId: string; isLiked: boolean; replyId?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, isLiked, replyId } = input;

    const { specificPost } = state;

    const post = getSelectedPost(specificPost);

    const selectedComment = getSelectedComment(post, commentId, replyId);

    return reducer.onLikeComment(selectedComment?.id || '', isLiked, token);
  },
);

export const getPostLikes = createAsyncThunk(
  'async/getSpecificPostLikes',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    let maxId;

    const { specificPost } = state;

    const post = getSelectedPost(specificPost);

    const { likes } = post;

    if (likes.list) {
      const { list } = likes;
      maxId = list[list.length - 1].id;
    }

    return reducer.getPostLikes(post.actionId, token, 20, maxId);
  },
);

export const getCommentLikes = createAsyncThunk(
  'async/getSpecificPostCommentLikes',
  async (input: { commentId: string; replyId?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { commentId, replyId } = input;

    let maxId;

    const { specificPost } = state;

    const post = getSelectedPost(specificPost);

    const selectedComment = getSelectedComment(post, commentId, replyId);

    if (selectedComment?.likes.list) {
      const { list } = selectedComment.likes;
      maxId = list[list.length - 1].id;
    }

    return reducer.getCommentLikes(selectedComment?.id || '', token, 20, maxId);
  },
);

export const addComment = createAsyncThunk(
  'async/addSpecificPostComment',
  async (input: IAddCommentOptions, { getState }: any) => {
    const { postId, text, image, taggedUsers } = input;

    const tags = taggedUsers.map((taggedUser) => taggedUser.userId);

    const updatedTaggedUsers = checkTaggedUsers(text, tags);

    const { token } = getState().user;

    return reducer.onAddComment(postId, text, updatedTaggedUsers, token, image);
  },
);

export const addReply = createAsyncThunk(
  'async/addSpecificPostReply',
  async (input: IAddReplyOptions, { getState }: any) => {
    const { postId, text, image, commentId, taggedUsers } = input;

    const tags = taggedUsers.map((taggedUser) => taggedUser.userId);

    const { token } = getState().user;

    return reducer.onAddReply(postId, commentId, text, tags, token, image);
  },
);

export const getComments = createAsyncThunk(
  'async/getSpecificPostComments',
  async (input: { postId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { postId } = input;

    const { token = '' } = state.user;

    const { specificPost } = state;

    const post = getSelectedPost(specificPost);

    let maxTime: number | undefined;

    const commentsList = post.comments?.list;

    if (!isEmptyArrayOrUndefined(commentsList)) {
      maxTime = commentsList![commentsList!.length - 1].date;
    }

    return reducer.getComments(postId, 20, maxTime, token);
  },
);

export const getReplies = createAsyncThunk(
  'async/getSpecificPostReplies',
  async (commentId: string, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { selectedPostId, id } = state.specificPost;

    return reducer.getReplies(selectedPostId || id, commentId, token);
  },
);

export const deleteComment = createAsyncThunk(
  'async/specificPostDeleteComment',
  async (input: { commentId: string }, { getState }: any) => {
    const { commentId } = input;

    const { token } = getState().user;

    return reducer.deleteComment(commentId, token);
  },
);
