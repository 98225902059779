import { IShippingAddress } from 'types';
import { graphQlRequest } from 'api/graphQl';
import { CheckoutResponse, ShippingAddressFullInput } from 'graphql/generated/graphql';
import { GET_SHIPPING_RATES_FOR_CHECKOUT } from './CheckoutQueries';
import { ICheckoutForNonSignupUsers } from './types';

export class CheckoutApi implements ICheckoutForNonSignupUsers {
  async getShippingServicesForNonSignupUser(
    checkoutId: string,
    shippingAddress: IShippingAddress,
  ): Promise<CheckoutResponse> {
    const parsedShippingAddress: ShippingAddressFullInput = {
      phoneNumber: shippingAddress.phoneNumber,
      address: {
        address: shippingAddress.streetAddress,
        city: shippingAddress.city,
        country: shippingAddress.country,
        postalCode: shippingAddress.postalCode,
        state: shippingAddress.state,
      },
    };

    const res = await graphQlRequest().query({
      query: GET_SHIPPING_RATES_FOR_CHECKOUT,
      variables: {
        checkoutId,
        shippingAddress: parsedShippingAddress,
        fullName: shippingAddress.fullName,
        email: shippingAddress.email,
      },
    });

    return res.data.getAvailableRatesForCheckout;
  }

  async getPaymentsForNonSignupUser(
    checkoutId: string,
    fullName: string,
    email: string,
  ): Promise<CheckoutResponse> {
    const res = await graphQlRequest().query({
      query: GET_SHIPPING_RATES_FOR_CHECKOUT,
      variables: { checkoutId, fullName, email },
    });

    return res.data.getAvailableRatesForCheckout;
  }
}
