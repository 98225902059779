import { FC, useState } from 'react';
import { Button, SearchInput } from 'tedooo-web-design-system/ui';

import { IInterestItem, IInterestsStep } from 'types';
import './interestsStep.scss';
import { translations } from 'translations/en';
import { InterestsEmptyState } from './interestsEmptyState/InterestsEmptyState';
import { getFilteredInterests } from '../helper';
import InterestsGroups from './interestsGroups/InterestsGroups';

const { SEARCH_PLACEHOLDER } = translations.ONBOARDING.INTERESTS_STEP;

const InterestsStep: FC<IInterestsStep> = ({
  interests,
  onContinueClick,
  onBackClick,
  onInterestToggle,
  isContinueDisabled,
  shouldDisplayEmptyState,
  hideInterestsEmptyState,
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const hasSelectedInterests = Object.keys(interests).some((interest) => {
    return interests[interest].some((interestItem: IInterestItem) => interestItem.isSelected);
  });

  const onChange = (e) => {
    hideInterestsEmptyState();
    setSearchText(e.target.value);
  };

  const filteredInterests = getFilteredInterests(interests, searchText);

  return (
    <div className="interests-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.INTERESTS_STEP.TITLE}</span>
        <span className="description">{translations.ONBOARDING.INTERESTS_STEP.DESCRIPTION}</span>
      </div>
      <div className="search-input-container">
        <SearchInput
          value={searchText}
          clearable
          onChange={onChange}
          placeholder={SEARCH_PLACEHOLDER}
        />
      </div>
      {shouldDisplayEmptyState && <InterestsEmptyState />}
      {!shouldDisplayEmptyState && (
        <InterestsGroups
          interests={interests}
          filteredInterests={filteredInterests}
          searchText={searchText}
          hasSelectedInterests={hasSelectedInterests}
          onItemClick={onInterestToggle}
        />
      )}
      <div className="buttons-container">
        <Button
          isFullWidth
          type="primary"
          size="large"
          borderRadius="large"
          disabled={isContinueDisabled && !hasSelectedInterests}
          text={translations.COMMON.CONTINUE}
          className="continue-button-interests"
          onClick={onContinueClick}
        />
        <Button
          isFullWidth
          type="secondary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.BACK}
          className="back-button-interests"
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default InterestsStep;
