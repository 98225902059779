import { ITaggedUser, IUserDetails } from 'types';

export interface ICommentReaction {
  timePosted: string;
  isLiked: boolean;

  onLikeClick(): void;
  onReplyClick(): void;
}

export interface IComment {
  avatar: string | null;
  username: string;
  fullName: string;
  isPremium: boolean;
  date: number;
  userId: string;
  text: string;
  image: string | null;
  id: string;
  totalReplies: number;
  isRepliesExpanded: boolean;
  isDeleted: boolean;
  replies: Array<IComment>;
  likes: { list: Array<IUserDetails> | null; paginationToken: string | null; hasMore: boolean };
  totalLikes: number;
  isLiked: boolean;
  taggedUsers: ITaggedUser[];
}

export interface ICommentProps extends IComment {
  isCommentOwner: boolean;
  isReply: boolean;
  userId: string;
  isPostOwner: boolean;
  onReplyClick(commentId: string, tag: ITaggedUser): void;
  onCommentLike(commentId: string, isLiked: boolean): void;
  onUserClick(id: string): void;
  onCommentDelete(commentId: string): void;
  onCommentLikesClick(commentId: string): void;
}

export interface ICommentWrapper extends IComment {
  loggedInUserAvatar: string | null;
  loggedInUserId: string | null;
  isPostOwner: boolean;
  postOwnerId: string;
  onUserClick(id: string): void;
  onReply(commentId: string, text: string, taggedUsers: ITaggedUser[], images: Array<string>): void;
  onRepliesClick(commentId: string): void;
  onCommentDelete(commentId: string, replyId?: string): void;
  onCommentsLike(commentId: string, isLiked: boolean): void;
  onCommentLikesClick(commentId: string): void;
}

export enum CommentMenuOptions {
  Delete = 'delete',
}

export interface IComments {
  comments: Array<IComment>;
  shouldDisplayEmptyState: boolean;
  hasMore: boolean;
  isExpanded: boolean;
  totalComments: number;
  postOwnerId: string;
  onToggleRepliesClick(commentId: string): void;
  onLoadMore(): void;
  onUserClick(id: string): void;
  onRepliesLoad(commentId: string): void;
  onReply(commentId: string, text: string, taggedUsers: ITaggedUser[], images: Array<string>): void;
  onCommentLike(commentId: string, isLiked: boolean): void;
  onCommentDelete(commentId: string, replyId?: string): void;
  onCommentLikesClick(commentId: string): void;
  onViewMoreCommentsClick(): void;
}

export interface IReplies {
  replies: Array<IComment>;
  commentId: string;
  postOwnerId: string;
  totalReplies: number;
  isRepliesExpanded: boolean;
  onReplyClick(commentId: string, tag: ITaggedUser): void;
  onUserClick(id: string): void;
  onRepliesClick(commentId: string): void;
  onCommentDelete(commentId: string, replyId?: string): void;
  onCommentLike(commentId: string, isLiked: boolean, replyId: string): void;
  onCommentLikesClick(commentId: string, replyId: string): void;
}
