import {
  IFilterState,
  IGetAllSearchResultsResponse,
  ISearchResultApi,
  IShopsResponseApi,
  Method,
  PageOptions,
} from 'types';
import { fetcher } from 'api';
import { buildFilter } from '../apiParser';

export class SearchResultReducer implements ISearchResultApi {
  getStores(token: string, offset: number, filters: IFilterState, page: PageOptions): Promise<any> {
    const query: any = buildFilter(filters, page);

    query.limit = 20;
    query.skip = offset;

    return fetcher('v3/shops', Method.GET, token, undefined, query);
  }

  getGroups(token: string, offset: number, search: string): Promise<any> {
    const query = { limit: 20, skip: offset, text: search, fetchMembership: 1 };

    return fetcher('search/groups', Method.GET, token, undefined, query);
  }

  getUsers(token: string, offset: number, search: string): Promise<any> {
    const query = { skip: offset, text: search, full: 1 };

    return fetcher('v2/users/search', Method.GET, token, undefined, query);
  }

  getPosts(token: string, offset: number, search: string): Promise<any> {
    const query: any = { limit: 20, skip: offset, text: search };

    return fetcher('search/posts', Method.GET, token, undefined, query);
  }

  getProducts(offset: number, search: string, token?: string): Promise<any> {
    const query: any = { limit: 20, skip: offset, text: search };

    return fetcher('v3/products/search', Method.GET, token, undefined, query);
  }

  getAllSearchResults(
    filters: IFilterState,
    token?: string,
  ): Promise<IGetAllSearchResultsResponse> {
    const query: any = buildFilter(filters);
    query.fetchMembership = 1;
    query.full = 1;

    return fetcher('v2/search/peek', Method.GET, token, undefined, query);
  }

  getSimilarShops(
    token: string,
    offset: number,
    filters: IFilterState,
    shopId: string,
  ): Promise<IShopsResponseApi> {
    const query = buildFilter(filters);

    query.limit = 20;
    query.skip = offset;

    return fetcher(`v3/shops/similar/${shopId}`, Method.GET, token, undefined, query);
  }

  requestJoinGroup(groupId: string, token: string): Promise<void> {
    return fetcher(`v2/chatgroups/requests/${groupId}`, Method.POST, token);
  }

  superAdminDeleteProduct(productId: string, token: string): Promise<void> {
    return fetcher(`v3/products/${productId}`, Method.DELETE, token);
  }

  superAdminMarkAsCrystalShop(token: string, shopId: string): Promise<void> {
    return fetcher(`v4/shops/mark/${shopId}`, Method.POST, token);
  }
}
