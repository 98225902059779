import { Notification, Span } from 'tedooo-web-design-system/ui';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { ServerStatus } from 'types';
import { translations } from 'translations/en';
import { ROUTES } from 'constant';
import { SpecificPostSlicer } from 'store/specificPost/SpecificPostSlicer';
import { UserSelector } from 'store/user/UserSelector';

const { SUCCESSFUL_REPOST_MESSAGE, FAILED_REPOST_MESSAGE } = translations.FEED.POST;

const RepostMessage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  const actionId = useTedoooSelector(MessageSelector.actionIdSelector);

  const username = useTedoooSelector(UserSelector.loggedUserNameSelector);

  const onViewPostClick = () => {
    dispatch(SpecificPostSlicer.actions.updateAction({ username }));
    navigate(`/${ROUTES.POST}/${actionId}`);
  };

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text={SUCCESSFUL_REPOST_MESSAGE}
        placement="top"
        description={<Span text="View post" onClick={onViewPostClick} />}
      />
    );
  }
  if (serverStatus === ServerStatus.ERROR) {
    return <Notification type="error" text={FAILED_REPOST_MESSAGE} placement="top" />;
  }

  return null;
};

export default RepostMessage;
