import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { INotificationState, NotificationsTabs } from 'types';
import { parseNotifications } from '../apiParser';
import { getNotifications, getOrderNotifications } from './NotificationsActions';
import { login, refreshToken } from '../user/UserActions';

const initialState: INotificationState = {
  activeTab: NotificationsTabs.All,
  allNotifications: null,
  orderNotifications: null,
  isLoading: false,
  totalNewNotifications: 0,
};

export const NotificationsSlicer = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    reset: () => initialState,
    onTabChange: (state, action) => {
      state.activeTab = action.payload.tab;
    },
    onFollowToggle: (state, action) => {
      const { notificationId } = action.payload;

      const combinedArray = [
        ...(state.allNotifications?.viewedNotifications || []),
        ...(state.allNotifications?.newNotifications || []),
      ];

      const selectedNotification = combinedArray.find(
        (notification) => notification.id === notificationId,
      );

      if (selectedNotification) {
        const { buttonDetails } = selectedNotification;
        selectedNotification.buttonDetails = selectedNotification.buttonAfterClickDetails;
        selectedNotification.buttonAfterClickDetails = buttonDetails;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const { hasMore, viewed = [], notViewed = [] } = action.payload;
      state.isLoading = false;

      const parsedViewedNotifications = parseNotifications(viewed, false);

      const parsedNewNotifications = parseNotifications(notViewed, true);

      const viewedNotifications = [
        ...(state.allNotifications?.viewedNotifications || []),
        ...parsedViewedNotifications,
      ];

      const newNotifications = [
        ...(state.allNotifications?.newNotifications || []),
        ...parsedNewNotifications,
      ];
      state.allNotifications = {
        newNotifications,
        viewedNotifications,
        hasMore,
      };
    });
    builder.addCase(getOrderNotifications.fulfilled, (state, action) => {
      const { hasMore, viewed = [], notViewed = [] } = action.payload;
      state.isLoading = false;

      const parsedViewedNotifications = parseNotifications(viewed, false);

      const parsedNewNotifications = parseNotifications(notViewed, true);

      const viewedNotifications = [
        ...(state.orderNotifications?.viewedNotifications || []),
        ...parsedViewedNotifications,
      ];

      const newNotifications = [
        ...(state.orderNotifications?.newNotifications || []),
        ...parsedNewNotifications,
      ];
      state.orderNotifications = {
        newNotifications,
        viewedNotifications,
        hasMore,
      };
    });
    builder.addMatcher(
      isAnyOf(getNotifications.pending, getOrderNotifications.pending),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(isAnyOf(login.fulfilled, refreshToken.fulfilled), (state, action) => {
      const { notifications } = action.payload;
      state.totalNewNotifications = notifications;
    });
  },
});
