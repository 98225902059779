export const isEmptyArray = (arr?: Array<any> | null) => !!arr && arr.length === 0;

export const isEmptyArrayOrUndefined = (arr?: Array<any> | null) => !arr || arr.length === 0;

export const transformToArray = (content: any) => (content instanceof Array ? content : [content]);

export const deepCloneArray = (array: any[]) => {
  try {
    return JSON.parse(JSON.stringify(array));
  } catch (e) {
    return [...array];
  }
};

export const createArray = (length: any) => [...new Array(length)];

export const splitArrayIntoChunks = <T>(arr: T[], chunkSize: number): T[][] => {
  const result: T[][] = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }

  return result;
};

export const isArraysEqual = (arr1: Array<any>, arr2: Array<any>) => {
  return (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((val, index) => val === arr2[index])
  );
};

export const uniqBy = <T>(arr: T[], predicate): T[] => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const callback = typeof predicate === 'function' ? predicate : (o) => o[predicate];

  const pickedObjects = arr
    .filter((item) => item)
    .reduce((map, item) => {
      const key = callback(item);

      if (key && !map[key]) {
        map[key] = item;
      }

      return map;
    }, {});

  return Object.values(pickedObjects);
};

export const uniq = (array: any[]) => {
  return Object.keys(
    array.reduce((acc, shop) => {
      acc[shop] = '';

      return acc;
    }, {}),
  );
};
