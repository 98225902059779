import { gql } from '@apollo/client';

export const GET_SPECIFIC_VISION_QUERY = gql`
  query getSpecificVision($id: String!) {
    getVisionById(id: $id) {
      vision {
        id
        imageUrl
        prompt
        user {
          id
          username
          fullName
          avatar
        }
      }
    }
  }
`;

export const REQUEST_TO_BUY_VISION_MUTATION = gql`
  mutation RequestToBuy(
    $visionId: String!
    $category: String!
    $notes: String!
    $images: [String!]!
    $shippingAddressId: String!
  ) {
    requestToBuyVision(
      request: {
        visionId: $visionId
        category: $category
        notes: $notes
        images: $images
        shippingAddressId: $shippingAddressId
      }
    ) {
      id
    }
  }
`;

export const GET_VISION_CATEGORIES_QUERY = gql`
  query GetVisionCategories {
    getVisionCategories {
      categories
    }
  }
`;
