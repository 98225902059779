import { createAsyncThunk } from '@reduxjs/toolkit';

import { SettingsApi } from './SettingsApi';
import { IShippingAddress, IStore2, IVacationModeDetails, NotificationsTypes } from 'types';
import { ShopReducer } from '../shop/ShopReducer';

const settingsApi = new SettingsApi();

const shopApi = new ShopReducer();

export const updateNotifications = createAsyncThunk(
  'async/updateNotifications',
  async (
    input: { type: NotificationsTypes; pushNotifications: boolean; emailNotifications: boolean },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { type, pushNotifications, emailNotifications } = input;

    return settingsApi.updatePostNotifications(type, pushNotifications, emailNotifications, token);
  },
);

export const updateVacationMode = createAsyncThunk(
  'async/updateVacationMode',
  async (input: { details: IVacationModeDetails | null }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { details } = input;

    return settingsApi.updateVacationMode(token, details);
  },
);

export const getVacationModeDetails = createAsyncThunk(
  'async/getVacationModeDetails',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.getVacationModeDetails(token);
  },
);

export const getPostNotifications = createAsyncThunk(
  'async/getPostNotifications',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.getPostNotifications(token);
  },
);

export const getAccountPreferences = createAsyncThunk(
  'async/getAccountPreferences',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.getAccountPreferences(token);
  },
);

export const updateLiveTranslations = createAsyncThunk(
  'async/updateLiveTranslations',
  async (isLiveTranslationEnabled: boolean, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.updateSettings(token, { liveTranslation: isLiveTranslationEnabled });
  },
);

export const updateLocalTime = createAsyncThunk(
  'async/updateLocalTime',
  async (isLocalTimeEnabled: boolean, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.updateSettings(token, { localTime: isLocalTimeEnabled });
  },
);

export const updateLastSeen = createAsyncThunk(
  'async/updateLastSeen',
  async (isLastSeenEnabled: boolean, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.updateSettings(token, { lastSeen: isLastSeenEnabled });
  },
);

export const updateLanguage = createAsyncThunk(
  'async/updateLanguage',
  async (input: { selectedLanguage: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.updateLanguage(token, { lang: input.selectedLanguage });
  },
);

export const getBlockedUsers = createAsyncThunk(
  'async/getBlockedUsers',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.getBlockedUsers(token);
  },
);

export const unBlockUser = createAsyncThunk(
  'async/unBlockUser',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.unBlockUser(token, input.id);
  },
);

export const updatePassword = createAsyncThunk(
  'async/updatePassword',
  async (input: { oldPassword: string; password: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.updatePassword(token, input);
  },
);

export const updateUserCurrency = createAsyncThunk(
  'async/updateCurrency',
  async (input: { currencyCode: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { currencyCode } = input;

    const { token = '' } = state.user;

    return settingsApi.updateUserCurrency(token, currencyCode);
  },
);

export const checkPassword = createAsyncThunk(
  'async/checkPassword',
  async (input: { username: string; password: string; reason: string; text: string }) => {
    const { username, password } = input;

    return settingsApi.checkPassword(username, password);
  },
);

export const deleteAccount = createAsyncThunk(
  'async/deleteAccount',
  async (input: { reason: string; text: string | null }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { reason, text } = input;

    return settingsApi.deleteAccount(token, reason, text);
  },
);

export const updateUserDetails = createAsyncThunk(
  'async/updateUserDetails',
  async (
    input: { fullName?: string; username?: string; email?: string; birthday?: number },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { fullName, username, email, birthday } = input;

    return settingsApi.updateUserDetails(token, fullName, username, email, birthday);
  },
);

export const getShippingAddresses = createAsyncThunk(
  'async/getShippingAddresses',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return settingsApi.getShippingAddresses(token);
  },
);

export const deleteShippingAddress = createAsyncThunk(
  'async/deleteShippingAddress',
  async (input: { shippingId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shippingId } = input;

    return settingsApi.deleteShippingAddress(shippingId, token);
  },
);

export const addShippingAddress = createAsyncThunk(
  'async/addShippingAddress',
  async (input: { shippingAddress: IShippingAddress }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shippingAddress } = input;

    return settingsApi.addShippingAddress(shippingAddress, token);
  },
);

export const updateShippingAddress = createAsyncThunk(
  'async/updateShippingAddress',
  async (input: { shippingAddress: IShippingAddress }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shippingAddress } = input;

    return settingsApi.editShippingAddress(shippingAddress, token);
  },
);

export const setDefaultAddress = createAsyncThunk(
  'async/setDefaultAddress',
  async (
    input: { shippingAddress: IShippingAddress; previousAddressId: string },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input.shippingAddress;

    return settingsApi.setDefaultAddress(id || '', token);
  },
);

export const getShopInformationSettings = createAsyncThunk(
  'async/getSettingsInformation',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '', loggedInUserShopId = '' } = state.user;

    return shopApi.getShopInformation(token, loggedInUserShopId);
  },
);
