import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IPayoutsState, ServerStatus } from 'types';
import {
  parseGraphqlStripePaymentSessionDetails,
  parseStripePaymentSessionDetails,
} from '../apiParser';
import {
  getStripePayoutDetails,
  getPaypalPayoutDetails,
  getPaypalPaymentConfirmation,
  getVisionStripePayoutDetails,
  getVisionPaypalPayoutLink,
  getStripeDetailsByCheckoutId,
  getBasketCheckoutDetails,
  getPaypalPayoutLinkByCheckoutId,
} from './PayoutsActions';
import { getSellerPaymentDetails } from '../specificVisionRequest/SpecificVisionRequestActions';
import { getPaymentsForNonSignupUser } from '../checkoutForNonSignupUsers/CheckoutActions';

const initialState: IPayoutsState = {
  stripePaymentSessionDetails: null,
  paypalCheckoutUrl: '',
  paypalPaymentServerStatus: null,
  instantCheckoutDetails: null,
  isPaymentMethodsLoading: false,
  isPaymentLinksLoading: false,
  isShippingRatesError: false,
};

export const PayoutsSlicer = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStripePayoutDetails.fulfilled, (state, action) => {
      state.stripePaymentSessionDetails = parseStripePaymentSessionDetails(action.payload);
      state.paypalCheckoutUrl = '';
      state.isPaymentLinksLoading = false;
    });
    builder.addCase(getPaypalPayoutDetails.fulfilled, (state, action) => {
      const { checkoutUrl } = action.payload;

      state.isPaymentLinksLoading = false;
      state.paypalCheckoutUrl = checkoutUrl;
      state.stripePaymentSessionDetails = null;
    });
    builder.addCase(getPaypalPaymentConfirmation.pending, (state) => {
      state.paypalPaymentServerStatus = ServerStatus.PENDING;
    });
    builder.addCase(getPaypalPaymentConfirmation.fulfilled, (state) => {
      state.paypalPaymentServerStatus = ServerStatus.SUCCESS;
    });
    builder.addCase(getPaypalPaymentConfirmation.rejected, (state) => {
      state.paypalPaymentServerStatus = ServerStatus.ERROR;
    });
    builder.addCase(getBasketCheckoutDetails.fulfilled, (state) => {
      state.isShippingRatesError = false;
    });
    builder.addMatcher(
      isAnyOf(getBasketCheckoutDetails.rejected, getPaymentsForNonSignupUser.rejected),
      (state) => {
        state.isPaymentMethodsLoading = false;
        state.isShippingRatesError = true;
      },
    );
    builder.addMatcher(
      isAnyOf(getStripeDetailsByCheckoutId.fulfilled, getVisionStripePayoutDetails.fulfilled),
      (state, action) => {
        state.stripePaymentSessionDetails = parseGraphqlStripePaymentSessionDetails(action.payload);
        state.paypalCheckoutUrl = '';
        state.isPaymentLinksLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(getVisionPaypalPayoutLink.fulfilled, getPaypalPayoutLinkByCheckoutId.fulfilled),
      (state, action) => {
        const { link } = action.payload;
        state.isPaymentLinksLoading = false;

        state.paypalCheckoutUrl = link;
        state.stripePaymentSessionDetails = null;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getPaypalPayoutDetails.pending,
        getVisionPaypalPayoutLink.pending,
        getPaypalPayoutLinkByCheckoutId.pending,
        getStripeDetailsByCheckoutId.pending,
        getVisionStripePayoutDetails.pending,
        getStripePayoutDetails.pending,
      ),
      (state) => {
        state.isPaymentLinksLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getSellerPaymentDetails.pending,
        getBasketCheckoutDetails.pending,
        getPaymentsForNonSignupUser.pending,
      ),
      (state) => {
        state.isPaymentMethodsLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        getBasketCheckoutDetails.fulfilled,
        getSellerPaymentDetails.fulfilled,
        getPaymentsForNonSignupUser.fulfilled,
      ),
      (state) => {
        state.isPaymentMethodsLoading = false;
      },
    );
  },
});
