import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrdersApi } from './OrdersApi';
import { IStore2, LoadMoreTypes } from '../types';
import { getMaxTime } from './helper';

const api = new OrdersApi();

export const getSellerOrders = createAsyncThunk(
  'async/getSellerOrders',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getSellerOrders(token);
  },
);

export const getBuyerOrders = createAsyncThunk(
  'async/getBuyerOrders',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getBuyerOrders(token);
  },
);

export const getShopViewers = createAsyncThunk(
  'async/getShopViewers',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { shopDetails } = state.user;

    const shop = shopDetails?.id || '';

    const { shopViewers } = state.orders;
    let maxDate: number | undefined;
    if (shopViewers) {
      maxDate = shopViewers[shopViewers.length - 1]?.time;
    }

    return api.getShopViewers(token, shop, maxDate);
  },
);

export const withdrawOrder = createAsyncThunk(
  'async/withdrawOrder',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId } = input;

    return api.withdrawOrder(token, orderId);
  },
);

export const reviewOrder = createAsyncThunk(
  'async/reviewOrder',
  async (
    input: {
      orderId: string;
      shopId: string;
      title: string;
      description: string;
      stars: number;
      images: Array<any>;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { shopId, title, description, stars, images } = input;

    const { token = '' } = state.user;

    return api.reviewOrder(token, shopId, title, description, stars, images);
  },
);

export const loadMoreOrders = createAsyncThunk(
  'async/loadMoreOrders',
  async (input: { mode: LoadMoreTypes }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { isSellerMode, sellerOrders, buyerOrders } = state.orders;

    const { mode } = input;

    const orders = isSellerMode ? sellerOrders : buyerOrders;
    let maxTime = 0;
    if (orders) {
      maxTime = getMaxTime(orders.pastOrders.list, mode);
    }

    if (isSellerMode) {
      return api.loadMoreSellerOrders(mode, maxTime, token);
    }

    return api.loadMoreBuyerOrders(mode, maxTime, token);
  },
);
