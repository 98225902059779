export const replaceUrl = (urlToReplaceTo: string) => window.location.replace(urlToReplaceTo);

export const isIphoneUserAgent = () => /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

export const detectMob = () =>
  /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const checkSmallScreen = () => window.innerWidth <= 900;

export const scrollToTopOfPage = () => {
  const scrollerElement = getElementById('content');
  scrollerElement?.scrollTo({ top: 0, behavior: 'smooth' });
};

export const changePage = (endPoint: string) => {
  // if (!save && sessionStorage.getItem('store')) sessionStorage.removeItem('store');
  window.history.pushState(endPoint, endPoint, endPoint);
  window.history.go();
};

export const getElementById = (id: string) => document.getElementById(id);

export const openNewWindow = (url: string) => {
  return window.open(url, '_blank');
};

export const closeWindow = () => {
  window.close();
};

export const refreshPage = () => {
  window.location.reload();
};
