import { createSelector } from '@reduxjs/toolkit';

import {
  IBasketShippingRate,
  ICartItem,
  ICheckoutItem,
  IDiscountDetails,
  IPrice,
  IStore2,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { isEmptyArray } from '../../utils';

const basketCheckoutIdSelector = (state: IStore2) => state.popup.checkoutId;

const buyNowCheckoutIdSelector = (state: IStore2) => state.basket.buyNowItem?.checkoutId || null;

const shopBasketsSelector = (state: IStore2) => state.basket.shopBaskets;

const buyNowItemSelector = (state: IStore2) => state.basket.buyNowItem?.item || null;

const productSellerDetailsSelector = (state: IStore2) =>
  state.specificProduct.shopDetails?.userDetails;

const shippingRatesSelector = (state: IStore2) =>
  state.basket.instantCheckoutDetails?.shippingRates;

const checkoutItemsSelector = createSelector(
  [basketCheckoutIdSelector, shopBasketsSelector, buyNowItemSelector],
  (checkoutId, baskets, buyNowItem): ICheckoutItem[] => {
    if (buyNowItem) {
      return [buyNowItem];
    }

    const items = baskets?.find((basket) => basket.checkoutId === checkoutId)?.items || [];

    return items.map((item) => ({
      id: item.productId,
      cartItemId: item.cartItemId,
      customizationRequest: item.customizationRequest,
      image: item.image,
      status: item.status,
      title: item.title,
      price: item.price,
      quantity: item.quantity,
      originalPrice: item.originalPrice,
      isDigital: item.isDigital,
      convertedFromPrice: item.convertedFromPrice,
    }));
  },
);

const convertedFromPriceSelector = createSelector(
  [basketCheckoutIdSelector, shopBasketsSelector, buyNowItemSelector],
  (checkoutId, baskets, buyNowItem): IPrice => {
    if (buyNowItem && buyNowItem.convertedFromPrice) {
      return {
        value: buyNowItem.convertedFromPrice.value,
        currencySymbol: buyNowItem.convertedFromPrice.currencySymbol,
        currencyCode: buyNowItem.convertedFromPrice.currencyCode,
      };
    }

    const items = baskets?.find((basket) => basket.checkoutId === checkoutId)?.items || [];

    const subtotal = items.reduce((acc, item) => {
      const price = item.convertedFromPrice ? item.convertedFromPrice.value : item.price.value;

      return acc + price;
    }, 0);

    return {
      value: subtotal,
      currencyCode: items[0]?.convertedFromPrice?.currencyCode || CurrencyCode.USD,
      currencySymbol: items[0]?.convertedFromPrice?.currencySymbol || '$',
    };
  },
);

const selectedShippingRateSelector = createSelector(
  [shippingRatesSelector],
  (rates): IBasketShippingRate | null => {
    return rates?.find((rate) => rate.isSelected) || null;
  },
);

const basketItemsSelector = createSelector(
  [shopBasketsSelector],
  (shopBaskets): ICartItem[] | null => {
    return shopBaskets?.flatMap((basket) => basket.items) || null;
  },
);

const checkoutIdSelector = createSelector(
  [basketCheckoutIdSelector, buyNowCheckoutIdSelector],
  (basketCheckoutId, buyNowCheckoutId): string => {
    return buyNowCheckoutId || basketCheckoutId || '';
  },
);

const basketSubTotalPriceSelector = createSelector([shopBasketsSelector], (baskets): IPrice => {
  const subtotal = baskets?.reduce((acc, basket) => {
    return acc + basket.subTotalPrice;
  }, 0);

  const currencyCode =
    baskets && !isEmptyArray(baskets) ? baskets[0]?.currencyCode : CurrencyCode.USD;

  const currencySymbol =
    baskets && !isEmptyArray(baskets) ? baskets[0].items[0]?.price.currencySymbol : '$';

  return {
    value: subtotal || 0,
    currencyCode,
    currencySymbol,
  };
});

const sellerDetailsSelector = createSelector(
  [buyNowItemSelector, productSellerDetailsSelector, checkoutIdSelector, shopBasketsSelector],
  (buyNowItem, userDetails, checkoutId, baskets) => {
    if (buyNowItem && userDetails) {
      return userDetails;
    }

    const selectedShop = baskets?.find((basket) => basket.checkoutId === checkoutId);

    if (selectedShop) {
      return selectedShop.userDetails;
    }

    return { avatar: '', username: '', fullName: '', id: '' };
  },
);

const buyNowShopIdSelector = (state: IStore2) => state.basket.buyNowShopId;

const discountDetailsStateMap = (state: IStore2) => state.basket.discountDetailsStateMap;

const buyNowDiscountDetailsSelector = createSelector(
  [buyNowShopIdSelector, discountDetailsStateMap],
  (shopId, discountsMap): IDiscountDetails | null => {
    return shopId ? discountsMap[shopId]?.discountDetails || null : null;
  },
);

const buyNowIsCouponCodeLoadingSelector = createSelector(
  [buyNowShopIdSelector, discountDetailsStateMap],
  (shopId, loadingMap): boolean => {
    return shopId ? loadingMap[shopId]?.isLoading || false : false;
  },
);

const buyNowCouponCodeErrorMessageSelector = createSelector(
  [buyNowShopIdSelector, discountDetailsStateMap],
  (shopId, errorsMap): string | null => {
    return shopId ? errorsMap[shopId]?.errorMessage || null : null;
  },
);

export const BasketSelector = {
  checkoutItemsSelector,
  convertedFromPriceSelector,
  selectedShippingRateSelector,
  checkoutIdSelector,
  basketItemsSelector,
  basketSubTotalPriceSelector,
  sellerDetailsSelector,
  buyNowDiscountDetailsSelector,
  buyNowIsCouponCodeLoadingSelector,
  buyNowCouponCodeErrorMessageSelector,
};
