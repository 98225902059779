export const d = '6';

export const colors = [
  '#0A66C2',
  '#02488F',
  '#E11900',
  '#0BB5B0',
  '#2F7DCB',
  '#FF8743',
  '#AF2BBF',
  '#BC284B',
  '#09918D',
];
