import { FC, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import './content.scss';

import { checkSmallScreen } from 'utils';
import FooterMenu from '../menu/footerMenu/FooterMenu';
import { useTedoooSelector } from 'store/Store';
import Menu from '../menu/Menu';
import NonSignInMenu from '../menu/nonSignInMenu/NonSignInMenu';

interface IContent {
  children: any;
}

const isSmallScreen = checkSmallScreen();

const Content: FC<IContent> = ({ children }) => {
  const { token } = useTedoooSelector((state) => state.user);

  const location = useLocation();

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  const renderMenu = useCallback(() => {
    if (token) {
      return <Menu />;
    }

    return <NonSignInMenu />;
  }, [token]);

  return (
    <div className={classNames('page-container')}>
      {renderMenu()}
      <div ref={ref} className="content" id="content">
        {children}
      </div>
      {isSmallScreen && <FooterMenu />}
    </div>
  );
};

export default Content;
