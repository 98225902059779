import { gql } from '@apollo/client';

export const GET_DISCOVER_PAGE_QUERY = gql`
  query GetDiscoverPageDataWeb {
    getDiscoverPageDataWeb {
      staticItems {
        id
        key
        sectionDetails {
          order
          topSpacing
        }
      }
      categories {
        id
        rows
        sectionDetails {
          order
          topSpacing
        }
        categories {
          name
          smallImage
          bigImage
          subCategories
          titleColor
        }
        title {
          text
          color
          font
          hasArrow
        }
        listInfo {
          listId
          title
          isFilterable
          initialPaginationToken
          screen
        }
      }
      layout1 {
        sectionDetails {
          order
          topSpacing
        }
        topLeft {
          id
          boxHeight
          boxWidth
          rows
          sectionDetails {
            order
            topSpacing
          }
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
          }
        }
        right {
          id
          rows
          sectionDetails {
            order
            topSpacing
          }
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
          }
        }
        bottomLeft {
          backgroundImage
          bigProduct {
            id
            imageUrl
            didLike
            totalLikes
          }
          smallProducts {
            id
            imageUrl
            didLike
            totalLikes
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
        }
      }
      layout2 {
        sectionDetails {
          order
          topSpacing
        }
        topLeft {
          id
          boxHeight
          boxWidth
          rows
          sectionDetails {
            order
            topSpacing
          }
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
          }
        }
        right {
          bigProduct {
            id
            imageUrl
            didLike
            totalLikes
          }
          smallProducts {
            id
            imageUrl
            didLike
            totalLikes
          }
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
        }
        bottomLeft {
          id
          boxHeight
          boxWidth
          rows
          sectionDetails {
            order
            topSpacing
          }
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
          }
        }
      }
      horizontalListsProducts {
        id
        boxHeight
        boxWidth
        rows
        sectionDetails {
          order
          topSpacing
        }
        title {
          text
          color
          font
          hasArrow
        }
        listInfo {
          listId
          title
          isFilterable
          initialPaginationToken
          screen
        }
        products {
          id
          imageUrl
          didLike
          totalLikes
        }
      }
      equalGridsProducts {
        id
        rows
        sectionDetails {
          order
          topSpacing
        }
        title {
          text
          color
          font
          hasArrow
        }
        listInfo {
          listId
          title
          isFilterable
          initialPaginationToken
          screen
        }
        products {
          id
          imageUrl
          didLike
          totalLikes
        }
      }
      layout3 {
        sectionDetails {
          order
          topSpacing
        }
        topLeft {
          id
          boxHeight
          boxWidth
          rows
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
            price {
              price
              currencyCode
              currency
            }
          }
        }
        right {
          id
          rows
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
            price {
              price
              currencyCode
              currency
            }
          }
        }
        bottomLeft {
          id
          boxHeight
          boxWidth
          rows
          title {
            text
            color
            font
            hasArrow
          }
          listInfo {
            listId
            title
            isFilterable
            initialPaginationToken
            screen
          }
          products {
            id
            imageUrl
            didLike
            totalLikes
            price {
              price
              currencyCode
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_SHOP_OF_THE_DAY_QUERY = gql`
  query GetDiscoverPageData {
    getDiscoverPageData(screenWidth: 0, screenHeight: 0) {
      shopsOfTheDay {
        id
        shopId
        userId
        imageUrl
        footerBackgroundColor
        isFollowing
        title {
          text
          color
          font
          hasArrow
        }
        text {
          text
          color
          font
          hasArrow
        }
      }
    }
  }
`;
