import { MediaOption, MuteChatOptions, popupIdsOptions } from 'types';

export enum ReportOptions {
  Inappropriate = 'Inappropriate',
  Dangerous = 'Dangerous',
  Pretending = 'Pretending',
  Spam = 'Spam',
  Bullying = 'Bullying',
  SexualComment = 'SexualComment',
  Irrelevant = 'Irrelevant',
  Other = 'Other',
}

export interface IShareGroupPopup {
  groupId: string;
  link?: string;
  getLink(input: { id: string }): void;
}

export interface IMuteChatPopup {
  onChatMute(input: { chatId: string; timeToMute: MuteChatOptions }): void;
  hide(): void;
  chatName: string;
  chatId: string;
}

export interface IFileSharingRulesPopup {
  selectedUploadOption: MediaOption | null;
  source: string | null;
  hide(): void;
  updateSelectedUploadOption(input: { type: MediaOption | null }): void;
}

export interface IReportFilePopup {
  messageId: string;
  hidePopup(): void;
  onReportMessage(input: { messageId: string; option: string; extra: string }): void;
}

export interface IReportFileMessageSuccessPopup {
  hidePopup(): void;
}

export interface ILeaveChatPopup {
  onChatLeave(): void;
  hide(): void;
}

export interface IReportUserPopup {
  userId: string;
  isLoading: boolean;
  onHide(): void;
  onReport(input: { userId: string; option: ReportOptions; text: string }): void;
}

export interface IBlockProfile {
  userFullName: string;
  onHide(): void;
  onBlock(): void;
}

export interface IReportConfirmPopup {
  isBlocked: boolean;
  shouldShowBlockedPopup: boolean;
  onHide(): void;
  onPopupShow(options: { id: popupIdsOptions }): void;
}

export enum FileReportOptions {
  None = '',
  CopyrightInfringement = 'Copyright Infringement',
  Spam = 'Spam',
  HarassmentOrBullying = 'Harassment/bullying',
  SexualCommentOrAdvances = 'Sexual comment or advances',
  Other = 'Other',
}

export enum ReportFilePopupSteps {
  SelectOption,
  WriteReason,
}
