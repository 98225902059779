import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProfileApi } from './ProfileApi';
import { IStore2 } from 'types';

const reducer = new ProfileApi();

export const getUserPage = createAsyncThunk(
  'async/getUserPage',
  async (input: { userId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    return reducer.getUserPage(input.userId, token || '');
  },
);

export const getSuggestedShops = createAsyncThunk(
  'async/getSuggestedShops',
  async (userId: string, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    return reducer.getSuggestedShops(userId, token || '');
  },
);

export const getProfileInformation = createAsyncThunk(
  'async/getProfileInformation',
  async (userId: string, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    return reducer.getProfileInformation(userId, token || '');
  },
);

export const getProfileViews = createAsyncThunk(
  'async/getProfileViews',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { profileViews } = state.profile;
    let maxDate: number | undefined;
    if (profileViews) {
      maxDate = profileViews[profileViews.length - 1]?.date;
    }

    return reducer.getProfileViews(token || '', maxDate);
  },
);

export const onFollowUserToggle = createAsyncThunk(
  'async/onFollowUserToggle',
  async (input: { userId: string; isFollowing: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { userId, isFollowing } = input;

    const { token = '' } = state.user;

    return reducer.onFollowToggle(userId, isFollowing, token);
  },
);
