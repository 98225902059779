import './profileCreatedStep.scss';
import { Button } from 'tedooo-web-design-system/ui';
import { FC, useState } from 'react';
import Lottie from 'react-lottie';

import { Image } from 'components/helpers';
import { translations } from 'translations/en';
import { IProfileCreatedStep } from 'types';
import Animation from 'lotties/confetti.json';

const ProfileCreatedStep: FC<IProfileCreatedStep> = ({ username, profilePicture, onClick }) => {
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div className="profile-created-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.PROFILE_CREATED_STEP.TITLE}</span>
        <span className="description">
          {translations.ONBOARDING.PROFILE_CREATED_STEP.DESCRIPTION}
        </span>
      </div>

      <div className="content-container">
        <div className="animation-container">
          {isImageLoaded && (
            <Lottie options={animationOptions} width={408} height={408} isClickToPauseDisabled />
          )}
        </div>
        <Image src={profilePicture || ''} onLoad={() => setIsImageLoaded(true)} />
        <span className="user-name">{username}</span>
      </div>
      <Button
        isFullWidth
        type="primary"
        size="large"
        borderRadius="large"
        text={translations.COMMON.AWESOME}
        className="submit-button"
        onClick={onClick}
      />
    </div>
  );
};

export default ProfileCreatedStep;
