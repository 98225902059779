import { FC } from 'react';
import classNames from 'classnames';

import { Image } from 'components/helpers/Image';
import { ImageGroupTypes, INotificationsImageGroup } from 'types';

const mapTypeToClassName: { [key in ImageGroupTypes]: string } = {
  [ImageGroupTypes.ImageWithBadge]: 'image-with-badge',
  [ImageGroupTypes.BigAndSmall]: 'big-and-small',
  [ImageGroupTypes.TwoDiagonal]: 'two-diagonal',
  [ImageGroupTypes.Single]: 'single',
};

const NotificationImages: FC<INotificationsImageGroup> = ({ type, images }) => {
  return (
    <div className={classNames([mapTypeToClassName[type], 'image-container'])}>
      {images.map((image, i) => (
        <Image key={i} src={image.src} withOverlay />
      ))}
    </div>
  );
};

export default NotificationImages;
