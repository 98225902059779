import { createSlice } from '@reduxjs/toolkit';

import { ISpecificVisionState, ServerStatus } from 'types';
import {
  getSpecificVision,
  getVisionsCategories,
  requestToBuyVision,
} from './SpecificVisionActions';
import { parseShippingAddress, parseSpecificVision } from '../apiParser';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { Countries } from 'constant/countries';
import { getShippingAddresses } from '../settings/SettingsActions';

const initialState: ISpecificVisionState = {
  id: '',
  imageUrl: '',
  versionIndex: null,
  userDetails: {
    id: '',
    avatar: '',
    fullName: '',
    username: '',
  },
  promptDetails: { text: '', image: null },
  isLoading: false,
  status: null,
  requestVisionCategories: null,
  requestToBuyVisionForm: {
    category: '',
    shippingAddress: {
      id: '',
      email: '',
      country: Countries.UnitedStates,
      state: '',
      fullName: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      postalCode: '',
      phoneNumber: '',
      isDefault: true,
    },
    notes: '',
    images: [],
  },
};

export const SpecificVisionSlicer = createSlice({
  name: 'specificVision',
  initialState,
  reducers: {
    reset: () => initialState,
    initializeSpecificVision: (state, action) => {
      const { vision, userDetails, promptDetails, versionIndex } = action.payload;
      AnalyticsEvents.onEnterVisionPreview('generated_visions', vision.id);

      state.id = vision.id;
      state.imageUrl = vision.imageUrl;
      state.status = null;
      state.versionIndex = versionIndex;
      state.userDetails = userDetails;
      state.promptDetails = promptDetails;
    },
    setSelectedShippingAddress: (state, action) => {
      const { shippingAddress } = action.payload;
      state.requestToBuyVisionForm.shippingAddress = shippingAddress;
    },
    setSelectedCategory: (state, action) => {
      const { category } = action.payload;
      state.requestToBuyVisionForm.category = category;
    },
    onRequestToBuyNoteChange: (state, action) => {
      const { text } = action.payload;
      state.requestToBuyVisionForm.notes = text;
    },
    addRequestToBuyImage: (state, action) => {
      const { image } = action.payload;
      state.requestToBuyVisionForm.images.push(image);
    },
    resetStatus: (state) => {
      state.status = null;
    },
    removeRequestToBuyImage: (state, action) => {
      const { src } = action.payload;
      state.requestToBuyVisionForm.images = state.requestToBuyVisionForm.images.filter(
        (image) => image !== src,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpecificVision.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSpecificVision.fulfilled, (state, action) => {
      const { vision } = action.payload;
      state.isLoading = false;

      const { id, imageUrl, promptDetails, userDetails } = parseSpecificVision(vision);
      state.id = id;
      state.imageUrl = imageUrl;
      state.promptDetails = promptDetails;
      state.userDetails = userDetails;
    });
    builder.addCase(getVisionsCategories.fulfilled, (state, action) => {
      const { categories } = action.payload;
      state.requestVisionCategories = categories;
    });
    builder.addCase(getShippingAddresses.fulfilled, (state, action) => {
      const { data } = action.payload;

      if (data[0]) {
        state.requestToBuyVisionForm.shippingAddress = parseShippingAddress(data[0]);
      }
    });
    builder.addCase(requestToBuyVision.fulfilled, (state) => {
      state.status = ServerStatus.SUCCESS;
    });
  },
});
