import { translations } from 'translations/en';
import { FooterOptions } from './types';
import { ROUTES, TEDOOO_SUPPORT_ROUTE } from 'constant';

export const FooterOrder = [FooterOptions.Help, FooterOptions.Terms, FooterOptions.Policy];

export const mapFooterOptionToLabel = {
  [FooterOptions.Help]: translations.PAGES.HELP,
  [FooterOptions.Terms]: translations.PAGES.TERMS,
  [FooterOptions.Policy]: translations.PAGES.PRIVACY_POLICY,
};

export const mapFooterOptionToRoute = {
  [FooterOptions.Help]: TEDOOO_SUPPORT_ROUTE,
  [FooterOptions.Terms]: ROUTES.TERMS,
  [FooterOptions.Policy]: ROUTES.PRIVACY,
};
