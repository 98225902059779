import { Notification } from 'tedooo-web-design-system/ui';

import { ServerStatus } from 'types';
import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';

const OrderReviewMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification type="primary-success" text="Review submitted successfully!" placement="top" />
    );
  }

  return null;
};

export default OrderReviewMessage;
