import { translations } from 'translations/en';
import { ChatErrorMessageTypes, IMessageItemDetails } from './types';
import { MessagesTypes } from '../../store/chat/types';

export const toFileSize = (size: number) => {
  if (!+size) return '0 Bytes';

  const k = 1024;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TiB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(size) / Math.log(k));

  if (i === 0) {
    return '1 KB';
  }

  if (i < 2) {
    return `${Math.round(size / k ** i)} ${sizes[i]}`;
  }

  return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
};

export const mapErrorTypeToText: { [key in ChatErrorMessageTypes]: string } = {
  [ChatErrorMessageTypes.LeaveGroup]: translations.CHAT.CANT_LEAVE_GROUP,
  [ChatErrorMessageTypes.GroupInvite]: translations.CHAT.INVALID_GROUP_INVITE,
};

export const hasFileMessageInSelectedMessages = (
  messages: IMessageItemDetails[],
  selectedMessagesIds: string[],
) => {
  return messages.some(
    (message) => selectedMessagesIds.includes(message.id) && message.type === MessagesTypes.File,
  );
};
