import { FC } from 'react';

import { Span } from 'components/helpers';
import './reactions.scss';
import { IReactions } from 'types';
import { toFormat } from 'utils';
import { translations } from 'translations/en';

const Reactions: FC<IReactions> = ({
  totalLikes,
  totalComments,
  onRepostClick,
  totalReposts,
  onLikesClick,
  onCommentsClick,
}) => {
  const shouldDisplayComments = totalComments > 0;

  const shouldDisplayLikes = totalLikes > 0;

  const shouldDisplayReposts = totalReposts > 0;

  const commentsText =
    totalComments === 1 ? translations.FEED.POST.COMMENT : translations.FEED.POST.COMMENTS_TEXT;

  const likesText = totalLikes === 1 ? translations.COMMON.LIKE : translations.COMMON.LIKES;

  const repostsText =
    totalReposts === 1 ? translations.FEED.POST.REPOST : translations.FEED.POST.REPOSTS;

  const formattedTotalLikes = toFormat(totalLikes);

  const formattedTotalComments = toFormat(totalComments);

  const formattedTotalReposts = toFormat(totalReposts);

  const onLikeClick = (e: any) => {
    e.stopPropagation();
    onLikesClick();
  };

  const onCommentClick = (e: any) => {
    e.stopPropagation();
    onCommentsClick();
  };

  const onRepostsClick = (e: any) => {
    e.stopPropagation();
    onRepostClick();
  };

  const shouldShowReactionsContainer =
    shouldDisplayLikes || shouldDisplayComments || shouldDisplayReposts;

  if (!shouldShowReactionsContainer) return null;

  return (
    <div className="reaction-container">
      {shouldDisplayLikes && (
        <div className="reaction" onClick={onLikeClick}>
          <span>
            <Span text={`${formattedTotalLikes} `} className="number" />
            <Span text={likesText} className="reaction-text" />
          </span>
        </div>
      )}
      {shouldDisplayComments && (
        <div className="reaction" onClick={onCommentClick}>
          <span>
            <Span text={`${formattedTotalComments} `} className="number" />
            <Span text={commentsText} className="reaction-text" />
          </span>
        </div>
      )}
      {shouldDisplayReposts && (
        <div className="reaction reposts-reaction" onClick={onRepostsClick}>
          <span>
            <Span text={`${formattedTotalReposts} `} className="number" />
            <Span text={repostsText} className="reaction-text" />
          </span>
        </div>
      )}
    </div>
  );
};

export default Reactions;
