import { IPrice, IProductDimensions, IVisionRequest, ReturnPolicyType } from 'types';

export enum VisionOfferShippingError {
  NotShippingToCountry = 'not shipping',
  NoRates = 'no rates',
}

export interface ISendVisionOfferState {
  id: string | null;
  price: IPrice;
  shippingPrice: number | null;
  tariffNumber: string;
  shippingProfileId: string;
  dimensions: IProductDimensions;
  returnPolicy: ReturnPolicyType;
  comments: string;
  request: IVisionRequest | null;
  shippingPriceError: VisionOfferShippingError | null;
}
