import { v4 as uuidv4 } from 'uuid';

import {
  uniq,
  checksIsVideo,
  deepCloneArray,
  firstNonAlphaNumericIndex,
  getRelativeTime,
  isEmptyArray,
  isGroupLink,
  isPostLink,
  isSameDay,
  isShopLink,
  isThisWeek,
  parseObject,
  toBytes,
  toTimeFormat,
  uniqBy,
} from 'utils';
import {
  AvatarDisplayOptions,
  IChatGroupsState,
  IChatItem,
  IChatMedia,
  IChatMediaItemResponse,
  IChatOption,
  IChatSearchedMessage,
  IChatSearchMessageItemResponse,
  IChatShopsState,
  IGroupInformationResponse,
  IGroupPrivacyOptions,
  ILinkDetails,
  ILoggedInUserDetails,
  IMemberOption,
  IMessageBatch,
  IMessageCount,
  IMessageFileDetails,
  IMessageGroupDetails,
  IMessageLinkDetails,
  IMessageOrderDetails,
  IMessageReceived,
  IMessageReplyDetails,
  IMessageResponse,
  IMessageRestResponse,
  IMessageShopDetails,
  IMessagesStore,
  IParticipantResponse,
  IPostMessageDetails,
  IReplyResponse,
  ISearchUsersResponse,
  ISelectedChat,
  ISendFile,
  IShopLinkResponse,
  IStoryUrlOrderMessage,
  IStoryUrlParser,
  IStoryUrlPostMessage,
  IStoryUrlSystemMessage,
  IStoryUrlVisionMessage,
  ITextStoryUrl,
  IUserOption,
  IVisionMessageDetails,
  mapMessageRestTypeToMessageType,
  mapMessageTypeToMessageRestType,
  MessageReceivedBaseResponse,
  MessageReceivedResponse,
  MessageRequest,
  MessagesRestTypes,
  MessagesStatuses,
  MessagesTypes,
  MultipleMessageItemRequest,
  MultipleMessagesRequest,
  SendMessageRequest,
  SendMultipleMessages,
  SidebarMessageResponse,
} from 'types';
import { sha256 } from 'constant/hash';
import { colors } from 'constant/colors';
import { MESSAGE_GROUP_PREFIX, MESSAGE_SHOP_PREFIX } from './constants';
import { translations } from 'translations/en';
import {
  getTimeToPatternExpire,
  getVisionMessageTitle,
  mapOrderStatusToText,
  mapSystemTypeToText,
} from './helper';
import { getDefaultAvatar } from 'store/user/helper';
import { toFileSize } from 'pages/chatv2/helper';
import { getPrice } from '../apiParser';

const parseChatMessage = (type: MessagesTypes, text: string, loggedInUsername: string) => {
  if (type === MessagesTypes.Image && !text) {
    return 'Photo';
  }
  if (type === MessagesTypes.Video && !text) {
    return 'Video';
  }
  if (type === MessagesTypes.File && !text) {
    return 'File';
  }
  if (type === MessagesTypes.Group) {
    return 'Group link';
  }
  if (type === MessagesTypes.System) {
    return parseSystemDetails(text, loggedInUsername);
  }

  return text;
};

export const parseChatMessages = (
  messages: SidebarMessageResponse[],
  loggedInUsername: string,
): IChatItem[] => {
  return messages.map((item) => {
    const type = getMessageType(item.type, item.text);

    const privacy = item.public ? IGroupPrivacyOptions.Public : IGroupPrivacyOptions.Private;

    const isOwner = item.sent;

    const username = isOwner ? loggedInUsername : item.fromName || item.name || '';

    let updatedText = 'Group link';

    if (type !== MessagesTypes.Group) {
      updatedText = parseStoryUrl(
        type,
        item.text,
        loggedInUsername,
        isOwner,
        username,
        item.storyUrl,
      ).text;
    }

    return {
      id: item.id,
      isAdmin: item.admin,
      isBlocked: !!item.blocked,
      image: item.image,
      isOwner,
      isSuperAdmin: item.isAdmin,
      lastMessageDate: item.date,
      isMuted: !!item.muted && item.muted > Date.now(),
      isArchived: !!item.archived,
      name: item.fullName || item.name,
      username: item.name,
      senderName: username,
      description: item.description || '',
      text: updatedText || parseChatMessage(type, updatedText, loggedInUsername),
      type,
      privacy,
      totalUnread: item.unreadCount || item.unread || 0,
      status: MessagesStatuses.Delivered,
      totalParticipants: item.totalParticipants,
    };
  });
};

export const toStoryUrl = (
  type: MessagesRestTypes,
  text: string,
  linkDetails: ILinkDetails | null,
  fileDetails: ISendFile | null,
  orderDetails: IMessageOrderDetails | null,
  postDetails: IPostMessageDetails | null,
) => {
  if (type === MessagesRestTypes.Video || type === MessagesRestTypes.Image) {
    if (text) {
      return `t: ${text}`;
    }

    return text;
  }
  let options;
  if (type === MessagesRestTypes.File && fileDetails) {
    const pieces = fileDetails.name.split('.');

    const extension = pieces[pieces.length - 1];
    options = {
      name: fileDetails.name,
      text: fileDetails.text,
      size: toBytes(fileDetails.size),
      extension,
    };
  } else if (type === MessagesRestTypes.OfferUpdate && orderDetails) {
    options = {
      offerId: orderDetails.orderId,
      title: orderDetails.title,
      description: orderDetails.description,
      shippingPrice: orderDetails.shippingPrice,
      itemPrice: {
        price: orderDetails.price,
        currency: orderDetails.currencySymbol,
        currencyCode: orderDetails.currencyCode,
      },
      productImage: orderDetails.images[0],
      productImages: orderDetails.images,
      shippingAddress: orderDetails.shippingAddress,
      status: orderDetails.status,
    };
  } else if (postDetails) {
    options = {
      description: postDetails.text,
      imageUrl: postDetails.image,
      name: postDetails.name,
      avatar: postDetails.avatar,
      username: postDetails.username,
      isPremium: postDetails.isPremium,
    };
  }
  if (linkDetails) {
    options = {
      description: linkDetails.description,
      title: linkDetails.title,
      imageUrl: linkDetails.image,
    };
  }

  const prefix = type === MessagesRestTypes.File ? 'file:' : 'og:';

  const storyUrlObject = JSON.stringify(options);

  return `${prefix}${storyUrlObject}`;
};

export const formatMessageToSend = (message: SendMessageRequest): MessageRequest => {
  const type = mapMessageTypeToMessageRestType[message.type];

  const storyUrl = toStoryUrl(
    type,
    message.text,
    message.linkDetails,
    message.file,
    message.orderDetails,
    message.postDetails,
  );

  const text = message.image || message.file?.text || message.text || '';

  const replyChatId = message.replyDetails ? message.chatId : '';

  const replyType =
    mapMessageTypeToMessageRestType[message.replyDetails?.type || MessagesTypes.Text] ||
    MessagesRestTypes.Text;

  return {
    message: text,
    to: message.chatId,
    type,
    replyFromName: message.replyDetails?.name || '',
    uuid: uuidv4(),
    uuid2: uuidv4(),
    readRec: message.shouldGetUpdateInSocket,
    room: message.isGroup,
    storyUrl,
    forwarded: message.isForwarded,
    replyId: message.replyDetails?.messageId || '',
    replyImage: message.replyDetails?.image || '',
    replyText: message.replyDetails?.text || '',
    replyType,
    replyChatId,
    replyFromUserId: message.replyDetails?.userId || '',
    replyDate: message.replyDetails?.date || 0,
    replyFrom: message.replyDetails?.username || '',
  };
};

export const formatMultiMessagesToSend = (
  message: SendMultipleMessages,
): MultipleMessagesRequest => {
  let parsedMessages: MultipleMessageItemRequest[];
  if (!isEmptyArray(message.media)) {
    parsedMessages = message.media.map((item) => {
      const isImage = !checksIsVideo(item.src);

      const type = isImage ? MessagesRestTypes.Image : MessagesRestTypes.Video;

      return {
        url: item.src,
        uuid2: uuidv4(),
        image: isImage,
        storyUrl: item.text,
        type,
      };
    });
  } else {
    parsedMessages = message.files.map((item) => ({
      url: item.src,
      uuid2: uuidv4(),
      storyUrl: toStoryUrl(MessagesRestTypes.File, item.src, null, item, null, null),
      type: MessagesRestTypes.File,
    }));
  }

  const replyChatId = message.replyDetails ? message.chatId : '';

  const type = message.replyDetails?.type || MessagesTypes.Text;

  const replyType = mapMessageTypeToMessageRestType[type] || MessagesRestTypes.Text;

  return {
    messages: parsedMessages,
    room: message.isGroup,
    replyChatId,
    readRec: false,
    v2: 1,
    replyDate: message.replyDetails?.date || 0,
    replyFrom: message.replyDetails?.username || '',
    replyImage: message.replyDetails?.image || '',
    replyText: message.replyDetails?.text || '',
    replyFromUserId: message.replyDetails?.userId || '',
    replyType,
    replyId: message.replyDetails?.messageId || '',
    to: message.chatId,
  };
};

export const parseUserInfoLastSeen = (lastSeen: 'Online' | number | null) => {
  if (!lastSeen) {
    return ' ';
  }

  if (lastSeen === 'Online') {
    return lastSeen;
  }

  const date = getRelativeTime(lastSeen);

  const parsedDate = date === 'today' ? '' : date;

  if (isThisWeek(lastSeen)) {
    const timeFormat = toTimeFormat(lastSeen);

    return `${translations.CHAT.LAST_SEEN} ${parsedDate} ${translations.COMMON.AT}  ${timeFormat}`;
  }

  return `${translations.CHAT.LAST_SEEN} ${translations.COMMON.AT} ${date}`;
};

const getMessageType = (type: MessagesRestTypes, text: string = ''): MessagesTypes => {
  if (type !== MessagesRestTypes.Text) {
    return mapMessageRestTypeToMessageType[type];
  }
  if (isPostLink(text)) {
    return MessagesTypes.PostLink;
  }
  if (isGroupLink(text)) {
    return MessagesTypes.Group;
  }
  if (isShopLink(text)) {
    return MessagesTypes.ShopLink;
  }

  return MessagesTypes.Text;
};

const parseReply = (
  loggedInUsername: string,
  reply?: IReplyResponse,
): IMessageReplyDetails | null => {
  if (!reply) {
    return null;
  }

  const isOwner = reply.from === loggedInUsername;

  const name = isOwner ? translations.COMMON.YOU : reply.fromFullName;

  return {
    name,
    type: getMessageType(reply.type, reply.text || reply.image),
    username: reply.from,
    text: reply.text,
    image: reply.image,
    id: reply.id,
    isOwner,
    userColor: getUserColor(reply.chatId, reply.fromUserId),
    date: reply.date,
  };
};

const parsePostDetails = (details: string, text: string): IPostMessageDetails | null => {
  try {
    const { imageUrl, description, post }: IStoryUrlPostMessage = JSON.parse(details.slice(3));

    const isPremium = post?.isPremium;

    const username = post?.username;

    const name = post?.name;

    const avatar = post?.avatar;

    const updatedPrice = post?.price
      ? { value: post.price.price, currencyCode: post.price.currencyCode }
      : null;

    return {
      name: name || '',
      link: text,
      image: imageUrl || null,
      text: description || null,
      avatar: avatar || '',
      username: username || '',
      isPremium: !!isPremium,
      price: updatedPrice,
    };
  } catch (e) {
    return null;
  }
};

const parseFileDetails = (
  details: string,
  text: string,
): { text: string; file: IMessageFileDetails | null } => {
  try {
    const prefix = 'file:';

    const { name, size, text: fileText = '', imageUrl } = JSON.parse(details.slice(prefix.length));

    return {
      file: {
        name,
        size: toFileSize(size),
        src: text,
        previewImage: imageUrl || null,
      },
      text: fileText,
    };
  } catch (e) {
    return { file: null, text: '' };
  }
};

const parseVisionDetails = (details: string, isOwner: boolean): IVisionMessageDetails | null => {
  try {
    const prefix = 'offerVision:';

    const {
      offerId,
      returnPolicy,
      notes,
      price,
      deliveryTime,
      productImage,
      status,
      reason,
    }: IStoryUrlVisionMessage = JSON.parse(details.slice(prefix.length));

    const isDeclined = status === 'DECLINED';

    return {
      offerId,
      returnPolicy,
      note: notes,
      price: getPrice(price),
      deliveryTime,
      image: productImage,
      isDeclined,
      title: getVisionMessageTitle(isOwner, isDeclined),
      declineReason: reason || '',
    };
  } catch (e) {
    return null;
  }
};

const parseOrderDetails = (
  details: string,
  isOwner: boolean,
  senderName: string,
  text: string,
): IMessageOrderDetails | null => {
  try {
    const prefix = 'offer:';

    const {
      status,
      title,
      itemPrice,
      productImage,
      productImages,
      shippingAddress,
      offerId,
      description,
      shippingPrice = 0,
    }: IStoryUrlOrderMessage = JSON.parse(details.slice(prefix.length));

    const totalItems = productImages?.length || 1;

    const orderName = totalItems > 1 ? translations.OFFERS.MIXED_BUNDLE : title;

    const declinedReasonPrefix = '[Order declined - ';

    const declinedReason = text.slice(declinedReasonPrefix.length, -1);

    const parsedPrice = itemPrice.price / 100;

    const parsedShippingPrice = shippingPrice / 100;

    return {
      status,
      orderId: offerId,
      shippingPrice: parsedShippingPrice,
      totalItemsCount: totalItems,
      orderName,
      description,
      currencySymbol: itemPrice.currency,
      senderName: isOwner ? translations.COMMON.YOU : senderName,
      shippingAddress,
      title: mapOrderStatusToText[status],
      currencyCode: itemPrice.currencyCode,
      price: parsedPrice + parsedShippingPrice,
      images: productImages || [productImage],
      declinedReason,
    };
  } catch (e) {
    return null;
  }
};

const parseSystemDetails = (details: string, loggedInUsername: string): string => {
  try {
    const { type, username = '', by = '' }: IStoryUrlSystemMessage = JSON.parse(details);

    const { YOU } = translations.COMMON;

    if (type === 'userKicked' || type === 'userLeft' || type === 'userJoined') {
      if (username === loggedInUsername && type === 'userKicked') {
        return `${YOU} ${translations.CHAT.REMOVE_FROM_GROUP}`;
      }

      const names = `@${username.replaceAll(', ', ', @')}`.replaceAll(`@${loggedInUsername}`, YOU);

      return `${names} ${mapSystemTypeToText[type]}`;
    }

    const name = by === loggedInUsername ? YOU : `@${by}`;

    return `${name} changed the group ${mapSystemTypeToText[type]}`;
  } catch (e) {
    return details;
  }
};

const parseTextStoryUrl = (text: string): IMessageLinkDetails | null => {
  try {
    const parsedLink: ITextStoryUrl = JSON.parse(text.slice(3));

    const { title, imageUrl, description } = parsedLink;

    if (!title && !description && !imageUrl) return null;

    return {
      title,
      text: description,
      image: imageUrl || null,
    };
  } catch (e) {
    return null;
  }
};

const parseStoryUrl = (
  type: MessagesTypes,
  text: string,
  loggedInUsername: string,
  isOwner: boolean,
  senderName: string,
  storyUrl?: string | null,
): IStoryUrlParser => {
  let file: IMessageFileDetails | null = null;
  let images: string[] = [];
  let displayedText: string = text;
  let postDetails: IPostMessageDetails | null = null;
  let visionDetails: IVisionMessageDetails | null = null;
  let linkDetails: IMessageLinkDetails | null = null;
  let orderDetails: IMessageOrderDetails | null = null;
  if (type === MessagesTypes.System) {
    displayedText = parseSystemDetails(text, loggedInUsername);
  }
  if (!storyUrl) {
    if (type === MessagesTypes.Image || type === MessagesTypes.Video) {
      images = [text];
      displayedText = '';
    } else if (type === MessagesTypes.Text) {
      displayedText = text;
    }

    return {
      text: displayedText,
      images,
      postDetails,
      orderDetails,
      file,
      linkDetails,
      visionDetails,
    };
  }

  if (type === MessagesTypes.Text) {
    images = [];
    linkDetails = parseTextStoryUrl(storyUrl);
    displayedText = text;
  } else if (type === MessagesTypes.Video || type === MessagesTypes.Image) {
    images = [text];
    displayedText = storyUrl.startsWith('t:') ? storyUrl.slice(2) : storyUrl;
  } else if (type === MessagesTypes.PostLink) {
    postDetails = parsePostDetails(storyUrl, text);
  } else if (type === MessagesTypes.OfferUpdate) {
    orderDetails = parseOrderDetails(storyUrl, isOwner, senderName, text);
  } else if (type === MessagesTypes.Transaction) {
    displayedText =
      '[Transaction message]\nNote: Quick pay feature is available only in the app. To view your transaction, please download the app!';
  } else if (type === MessagesTypes.File) {
    const { file: parsedFile, text: fileText } = parseFileDetails(storyUrl, text);
    file = parsedFile;
    displayedText = fileText;
  } else if (type === MessagesTypes.Vision) {
    visionDetails = parseVisionDetails(storyUrl, isOwner);
  }

  return {
    postDetails,
    text: displayedText,
    images,
    orderDetails,
    file,
    linkDetails,
    visionDetails,
  };
};

export const getShopOrGroupId = (
  text: string,
  type: MessagesTypes,
): { socialId: string; isLink: boolean } | null => {
  if (type !== MessagesTypes.Group && type !== MessagesTypes.ShopLink) {
    return null;
  }
  let prefix = MESSAGE_SHOP_PREFIX;
  if (type === MessagesTypes.Group) {
    const object = parseObject(text);

    if (object.groupId) {
      return { socialId: object.groupId, isLink: false };
    }
    prefix = MESSAGE_GROUP_PREFIX;
  }

  const startIndex = text.indexOf(prefix);

  if (startIndex > -1) {
    const slicedText = text.slice(startIndex + prefix.length);

    const lastIndex = firstNonAlphaNumericIndex(slicedText);

    return { socialId: slicedText.slice(0, lastIndex), isLink: true };
  }

  const socialId = text.split(';')[0];

  return { socialId, isLink: false };
};

export const getTextAndSearch = (text: string) => {
  const searchPrefix = '<b><font color="#000">';

  const searchSuffix = '</font></b>';

  const searchWords = text
    .split(searchPrefix)
    .flatMap((item) => item.split(searchSuffix))
    .filter((item, index) => index % 2 !== 0 && !!item);

  const updatedText = text.replaceAll(searchPrefix, '').replaceAll(searchSuffix, '');

  return { text: updatedText, searchWords };
};

export const parseSocketMessage = (
  message: MessageReceivedResponse,
  loggedInUserId: string,
  loggedInUsername: string,
  loggedInUserFullName: string,
): { message: IMessageReceived; isAdmin: boolean } => {
  const isGroup = !!message.t1;
  let isAdmin = false;

  if (message.type === MessagesRestTypes.System) {
    isAdmin = !!parseObject(message.message)?.admin;
  }

  const parsedMessage = parseMessage(
    message,
    loggedInUserId,
    loggedInUsername,
    loggedInUserFullName,
    isGroup,
    message.fromName || message.username,
    message.fromFullName,
  );

  const targetId = message.t1 || parsedMessage.isOwner ? message.to : message.from;

  return {
    isAdmin,
    message: {
      ...parsedMessage,
      targetAvatar: message.targetAvatar,
      targetId,
      isGroup,
      description: message.description,
      chatName: message.targetName || message.fromName || message.username,
    },
  };
};

//! isOwner && message.systemType === 'room'
export const parseMessage = (
  message: MessageReceivedBaseResponse,
  loggedInUserId: string,
  loggedInUsername: string,
  loggedInUserFullName: string,
  isGroup: boolean,
  username: string = '',
  userFullName: string,
  messageStatus: MessagesStatuses = MessagesStatuses.Delivered,
): IMessagesStore => {
  const isOwner = message.from === loggedInUserId;

  const type = getMessageType(message.type, message.message);

  const {
    postDetails,
    text = '',
    linkDetails,
    images,
    orderDetails,
    visionDetails,
    file,
  } = parseStoryUrl(
    type,
    message.message,
    loggedInUsername,
    isOwner,
    userFullName,
    message.storyUrl,
  );

  const parsedText = message.type === MessagesRestTypes.ShopLink ? '' : text;

  const isDeleted = type === MessagesTypes.Text && message.storyUrl === '1';

  const information = getShopOrGroupId(text, type);

  const groupLink = type === MessagesTypes.Group ? information?.socialId || null : null;

  const shopId = type === MessagesTypes.ShopLink ? information?.socialId || null : null;

  const { searchWords, text: updatedText } = getTextAndSearch(parsedText);

  const avatarType = isGroup && !isOwner ? AvatarDisplayOptions.Visible : AvatarDisplayOptions.None;

  const isExpired = !!message.expiry && message.expiry.date < Date.now();

  let expiryText: string | null = null;
  if (message.expiry) {
    expiryText = isExpired
      ? `${message.expiry.text} is Expired`
      : `${message.expiry.text} expires in ${getTimeToPatternExpire(message.expiry.date || 0)}`;
  }

  return {
    id: message.uuid,
    searchWords,
    type,
    userId: message.from,
    linkDetails,
    isExpired,
    username: isOwner ? loggedInUsername : username,
    status: messageStatus,
    avatarDisplayOption: avatarType,
    name: isOwner ? loggedInUserFullName : userFullName || '',
    date: message.date,
    expiryText,
    shopId,
    groupLink,
    isOwner,
    isForwarded: !!message.forwarded,
    avatar: message.fromAvatar || getDefaultAvatar(message.from),
    orderDetails,
    visionDetails,
    fileDetails: file,
    postDetails,
    reply: parseReply(loggedInUsername, message.reply),
    userColor: getUserColor(message.t1 || message.to, message.from),
    isTranslated: !!message.Translated,
    shouldDisplayName: !isOwner && isGroup,
    isDeleted,
    text: updatedText,
    images,
  };
};

export const getUserColor = (chatId: string, userId: string) => {
  const id = userId + chatId;

  const res = sha256(id);

  const colorIndex = Math.abs(res) % colors.length;

  return colors[colorIndex];
};

export const getShopsAndGroups = (
  data: IMessageRestResponse[],
  currentShops: IChatShopsState,
  currentGroups: IChatGroupsState,
): { groups: { id: string; isLink: boolean }[]; shops: string[] } => {
  const groups: { id: string; isLink: boolean }[] = [];

  const shops: string[] = [];
  data.forEach((item) => {
    const type = getMessageType(item.type, item.message);

    const information = getShopOrGroupId(item.message, type);

    if (information) {
      if (type === MessagesTypes.Group) {
        if (!currentGroups[information.socialId]) {
          groups.push({ id: information.socialId, isLink: information.isLink });
        }
      } else if (type === MessagesTypes.ShopLink) {
        if (!currentShops[information.socialId]) {
          shops.push(information.socialId);
        }
      }
    }
  });

  return { groups: uniqBy(groups, (group) => group.id), shops: uniq(shops) };
};

export const getReplyTextAndImage = (message: IMessagesStore) => {
  if (message.orderDetails) {
    return { text: translations.OFFERS.ORDER, image: message.orderDetails.images[0] };
  }
  if (message.type === MessagesTypes.Group) {
    const image = parseObject(message.text)?.avatar || null;

    return { text: translations.CHAT.GROUP_INVITE, image };
  }

  return { text: message.text, image: message.images[0] || null };
};

export const getDefaultUsersSuggestions = (chats: IChatOption[] | null) =>
  (chats || [])
    .filter((item) => !item.isGroup)
    .map((item) => ({
      name: item.name,
      username: item.username || '',
      id: item.id,
      avatar: item.avatar || '',
    }));

export const getUpdatedGroupCount = (
  prevMessages: IMessagesStore[],
  addedMessages: IMessagesStore[],
  currentGroupCount: IMessageCount[],
): IMessageCount[] => {
  let lastAddedDate = prevMessages[prevMessages.length - 1]?.date || addedMessages[0]?.date;

  if (!lastAddedDate) {
    return [];
  }

  const updatedCurrentCount: IMessageCount[] = isEmptyArray(currentGroupCount)
    ? [{ date: lastAddedDate, count: 0 }]
    : deepCloneArray(currentGroupCount);

  [...addedMessages].forEach((message) => {
    if (isSameDay(lastAddedDate, message.date)) {
      updatedCurrentCount[updatedCurrentCount.length - 1].count++;
    } else {
      updatedCurrentCount.push({ date: message.date, count: 1 });
      lastAddedDate = message.date;
    }
  });

  return updatedCurrentCount;
};

export const getUpdatedOlderGroupCount = (
  prevMessages: IMessagesStore[],
  addedMessages: IMessagesStore[],
  currentGroupCount: IMessageCount[],
): IMessageCount[] => {
  let lastAddedDate = prevMessages[0]?.date || addedMessages[addedMessages.length - 1]?.date;

  if (!lastAddedDate) {
    return [];
  }

  const updatedCurrentCount: IMessageCount[] = isEmptyArray(currentGroupCount)
    ? [{ date: lastAddedDate, count: 0 }]
    : deepCloneArray(currentGroupCount);

  [...addedMessages].reverse().forEach((message) => {
    if (isSameDay(lastAddedDate, message.date)) {
      updatedCurrentCount[0].count++;
    } else {
      updatedCurrentCount.unshift({ date: message.date, count: 1 });
      lastAddedDate = message.date;
    }
  });

  return updatedCurrentCount;
};

export const parseMessagesBatch = (
  batch: IChatSearchMessageItemResponse,
  loggedInUserId: string,
  loggedInUsername: string,
  loggedInUserFullName: string,
  isGroup: boolean,
  chatName: string | null,
  chatUsername: string | null,
): IMessageBatch => {
  const messages = parseMessages(
    batch.messages,
    loggedInUserId,
    loggedInUsername,
    loggedInUserFullName,
    isGroup,
    chatName,
    chatUsername,
  ).reverse();

  return {
    hasPrevMore: batch.hasPrevious,
    hasNextMore: batch.hasLater,
    searchMessageIndexes: batch.indexes.map((index) => batch.messages.length - index - 1),
    currentIndex: 0,
    messages,
  };
};

export const parseMessages = (
  messages: IMessageRestResponse[],
  loggedInUserId: string,
  loggedInUsername: string,
  loggedInUserFullName: string,
  isGroup: boolean,
  chatName: string | null,
  chatUsername: string | null,
): IMessagesStore[] => {
  return messages.map((message) => {
    const name = message.fromFullName || chatName || '';

    const username = message.fromName || chatUsername || '';

    return parseMessage(
      message,
      loggedInUserId,
      loggedInUsername,
      loggedInUserFullName,
      isGroup,
      username,
      name,
    );
  });
};

export const parseGroup = (
  details: IGroupInformationResponse,
  chats: IChatOption[],
  socialLinkId: string | null,
): IMessageGroupDetails => {
  const isMember = !!chats.find((chat) => chat.id === details._id);

  return {
    name: details.name,
    avatar: details.image || null,
    isMember,
    description: details.description,
    id: details._id,
    isPublic: details.public,
    totalParticipants: details.participants,
    socialLinkId,
  };
};

export const parseShopInformation = (shop: IShopLinkResponse): IMessageShopDetails => {
  const images = shop.images || (shop.image ? [shop.image] : []);

  return {
    username: shop.user.username,
    id: shop._id,
    isPremium: shop.isPremium,
    avatar: shop.user.avatar,
    description: shop.description,
    images,
    title: shop.name,
  };
};

const getSidebarRowAvatar = (avatar: string | null, isGroup: boolean, userId: string) => {
  if (isGroup) {
    return avatar;
  }

  return avatar || getDefaultAvatar(userId);
};

export const parseMessageToSidebar = (
  message: IMessageReceived,
  isSelectedChat: boolean,
  isAdmin: boolean,
  chat?: IChatOption,
): IChatOption => {
  let totalUnread = isSelectedChat ? 0 : (chat?.totalUnread || 0) + 1;
  if (message.isOwner) {
    totalUnread = chat?.totalUnread || 0;
  }

  const defaultAvatar = message.targetAvatar || chat?.avatar || null;

  const avatar = getSidebarRowAvatar(defaultAvatar, message.isGroup, message.targetId || '');

  const text = parseChatMessage(message.type, message.text, '');

  return {
    isMuted: !!chat?.isMuted,
    isArchived: !!chat?.isArchived,
    isSuperAdmin: !!chat?.isSuperAdmin,
    avatar,
    privacy: chat?.privacy || null,
    text,
    typingName: null,
    description: message.description || chat?.description || '',
    type: message.type,
    isAdmin: !!chat?.isAdmin || isAdmin,
    status: message.status,
    id: chat?.id || message.targetId || '',
    name: chat?.name || message.chatName || '',
    lastMessageDate: message.date,
    isBlocked: !!chat?.isBlocked,
    isGroup: message.isGroup,
    username: chat?.username || message.username,
    totalUnread,
    totalParticipants: chat?.totalParticipants || 1,
    senderName: message.username,
    isOwner: message.isOwner,
  };
};

export const parseSendMessageToSidebar = (
  chat: IChatOption,
  loggedInUser: ILoggedInUserDetails,
  type: MessagesTypes,
  text: string,
): IChatOption => {
  return {
    isMuted: chat.isMuted,
    isArchived: chat.isArchived,
    avatar: chat.avatar,
    text: parseChatMessage(type, text, loggedInUser.username),
    type,
    description: chat.description,
    privacy: chat.privacy,
    status: MessagesStatuses.Awaiting,
    isAdmin: chat.isAdmin,
    id: chat.id,
    name: chat.name,
    lastMessageDate: Date.now(),
    typingName: null,
    isBlocked: chat.isBlocked,
    isGroup: chat.isGroup,
    username: chat.username,
    totalUnread: 0,
    totalParticipants: chat.totalParticipants,
    senderName: loggedInUser.firstName,
    isOwner: true,
    isSuperAdmin: chat.isSuperAdmin,
  };
};

export const parseSearchedMessages = (
  messages: IMessageResponse[],
  chats: IChatOption[],
  loggedInUserId: string,
): IChatSearchedMessage[] => {
  return messages.map((message) => {
    const { id, from, fromName, message_t, date, targets } = message;

    const isGroup = targets.length === 1;

    const chatId = isGroup
      ? targets[0]
      : targets.find((targetId) => targetId !== loggedInUserId) || '';

    const text = message_t[0]
      .replaceAll('<b><font color="#000">', '')
      .replaceAll('</font></b>', '');

    const chatDetails = chats.find((chat) => chat.id === chatId);

    let chatName = '';
    let avatar = '';
    let chatUsername: string | null = '';
    let senderName = '';

    if (chatDetails) {
      chatName = chatDetails.name;
      chatUsername = chatDetails.username;
      senderName = from === chatDetails.id ? '' : 'You: ';
      avatar = chatDetails.avatar || '';
    }

    if (isGroup && fromName) {
      senderName = `${fromName}: `;
    }

    return {
      avatar,
      id,
      text,
      chatId,
      senderName,
      date,
      username: chatUsername,
      isGroup,
      chatName: chatName || '',
      isSuperAdmin: !!chatDetails?.isSuperAdmin,
    };
  });
};

export const parseSideBarChats = (
  prevChats: IChatOption[],
  data: IChatItem[],
  isGroup: boolean,
  hasMore: boolean,
): IChatOption[] => {
  const addedChats: IChatOption[] = data.map((chat) => ({
    id: chat.id,
    name: chat.name,
    lastMessageDate: chat.lastMessageDate,
    totalUnread: chat.totalUnread || 0,
    isBlocked: chat.isBlocked,
    privacy: chat.privacy,
    type: chat.type,
    typingName: null,
    isAdmin: chat.isAdmin,
    description: chat.description,
    status: chat.status,
    text: chat.text,
    isMuted: chat.isMuted,
    isArchived: chat.isArchived,
    senderName: chat.senderName,
    isOwner: chat.isOwner,
    isGroup,
    isSuperAdmin: chat.isSuperAdmin,
    avatar: chat.image || (isGroup ? null : getDefaultAvatar(chat.id)),
    username: isGroup ? null : chat.username,
    totalParticipants: chat.totalParticipants,
  }));

  let updatedChats = [...prevChats, ...addedChats];

  if (!hasMore) {
    updatedChats = updatedChats.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
  }

  return uniqBy(updatedChats, (item) => item.id);
};

export const parsedSuggestedUsers = (data: ISearchUsersResponse[]): IUserOption[] => {
  return data.map((user) => ({
    id: user._id,
    avatar: user.avatar,
    username: user.username,
    name: user.fullName,
  }));
};

export const parseChatMedia = (
  data: IChatMediaItemResponse[] | null,
  loggedInUsername: string,
  loggedInUserId: string,
  otherUsername: string,
): IChatMedia[] => {
  if (!data) return [];

  return data
    .map((message) => {
      const isOwner = message.from === loggedInUserId;

      const username = message.fromName || (isOwner ? loggedInUsername : otherUsername);

      return {
        id: message.uuid,
        date: message.date,
        source: message.message,
        isOwner,
        username,
        userId: message.from,
        text: message.imageText || '',
        avatar: message.avatar || getDefaultAvatar(message.from),
      };
    })
    .sort((a, b) => a.date - b.date);
};

export const parseSendMessage = (details: MessageRequest, loggedInUser: ILoggedInUserDetails) => {
  const isGroup = details.room;

  const reply: IReplyResponse = {
    chatId: details?.replyChatId || '',
    date: details.replyDate || 0,
    from: details.replyFrom || '',
    fromFullName: details.replyFromName,
    id: details.replyId || '',
    image: details.replyImage || '',
    fromUserId: details.replyFromUserId || '',
    text: details.replyText || '',
    type: details.type,
  };

  const message: MessageReceivedBaseResponse = {
    uuid: details.uuid,
    Translated: false,
    date: Date.now(),
    message: details.message,
    type: details.type,
    t1: isGroup ? details.to : undefined,
    storyUrl: details.storyUrl,
    from: loggedInUser.userId,
    to: details.to,
    reply: reply.id ? reply : undefined,
    fromAvatar: loggedInUser.avatar,
  };

  return parseMessage(
    message,
    loggedInUser.userId,
    loggedInUser.username,
    loggedInUser.firstName,
    isGroup,
    loggedInUser.username,
    loggedInUser.firstName,
    MessagesStatuses.Awaiting,
  );
};

export const selectedChatToChatItem = (
  selectedChat: ISelectedChat,
  loggedInUser: ILoggedInUserDetails,
): IChatOption => {
  return {
    isMuted: false,
    isArchived: false,
    avatar: selectedChat.avatar,
    text: '',
    privacy: selectedChat.privacy,
    description: selectedChat.description,
    type: MessagesTypes.Text,
    status: MessagesStatuses.Awaiting,
    typingName: null,
    id: selectedChat.id,
    isAdmin: selectedChat.isAdmin,
    name: selectedChat.name,
    isSuperAdmin: selectedChat.isSuperAdmin,
    lastMessageDate: Date.now(),
    isBlocked: false,
    isGroup: false,
    username: selectedChat.username,
    totalUnread: 0,
    totalParticipants: selectedChat.totalParticipants,
    senderName: loggedInUser.firstName,
    isOwner: true,
  };
};

export const parseGroupParticipants = (participants: IParticipantResponse[]): IMemberOption[] => {
  return participants.map((participant) => ({
    id: participant.id,
    username: participant.username,
    name: participant.fullName,
    avatar: participant.avatar || getDefaultAvatar(participant.id),
    isAdmin: !!participant.admin,
    isFollowing: participant.isFollowing,
  }));
};

export const parseSendMessages = (
  details: MultipleMessagesRequest,
  loggedInUser: ILoggedInUserDetails,
): IMessagesStore[] => {
  const replyResponse: IReplyResponse = {
    date: details.replyDate,
    text: details.replyText,
    image: details.replyImage,
    type: details.replyType,
    fromUserId: details.replyFromUserId,
    id: details.replyId,
    from: details.replyFrom,
    chatId: details.replyChatId,
    fromFullName: loggedInUser.firstName,
  };

  const reply = replyResponse.id ? parseReply(loggedInUser.username, replyResponse) : null;

  return details.messages.map((message) => {
    const type = mapMessageRestTypeToMessageType[message.type];

    const {
      file,
      text: parsedText,
      images,
      linkDetails,
    } = parseStoryUrl(
      type,
      message.url,
      loggedInUser.username,
      true,
      loggedInUser.firstName,
      message.storyUrl,
    );

    return {
      isOwner: true,
      type,
      text: parsedText,
      images,
      linkDetails,
      orderDetails: null,
      visionDetails: null,
      userId: loggedInUser.userId,
      postDetails: null,
      date: Date.now(),
      isForwarded: false,
      expiryText: null,
      id: message.uuid2,
      username: loggedInUser.username,
      searchWords: [],
      name: loggedInUser.firstName,
      avatar: loggedInUser.avatar,
      status: MessagesStatuses.Awaiting,
      isExpired: false,
      avatarDisplayOption: AvatarDisplayOptions.None,
      isDeleted: false,
      groupLink: null,
      shopId: null,
      fileDetails: file,
      isTranslated: false,
      shouldDisplayName: false,
      userColor: getUserColor(details.to, loggedInUser.userId),
      reply,
    };
  });
};

export const getMessageMissingShopOrGroupId = (
  text: string,
  messageType: MessagesRestTypes | MessagesTypes,
  currentShops: IChatShopsState,
  currentGroups: IChatGroupsState,
): { id: string; type: 'group' | 'shop'; isLink: boolean } | null => {
  const type: MessagesTypes =
    (messageType as MessagesTypes) || getMessageType(messageType as MessagesRestTypes, text || '');

  const information = getShopOrGroupId(text, type);

  if (information) {
    if (type === MessagesTypes.Group && !currentGroups[information.socialId]) {
      return { id: information.socialId, type: 'group', isLink: information.isLink };
    }
    if (type === MessagesTypes.ShopLink && !currentShops[information.socialId]) {
      return { id: information.socialId, type: 'shop', isLink: information.isLink };
    }
  }

  return null;
};

export const toSelectedChat = (
  selectedChat: IChatOption | null,
  isGroup: boolean,
  avatar: string | null,
  name: string | null,
  username: string | null,
  id: string,
  messageDate?: number,
): ISelectedChat => ({
  id,
  shopId: null,
  messagesPrevIndex: 0,
  isMessagesLoading: false,
  searchMessages: {
    messagesBatches: [],
    isSearchVisible: false,
    skip: 0,
    hasMore: true,
    totalCount: 0,
    search: '',
    isLoading: false,
    currentMessageBatchIndex: 0,
  },
  privacy: selectedChat?.privacy || null,
  lastSeen: '',
  username: username || selectedChat?.username || '',
  totalParticipants: selectedChat?.totalParticipants || 1,
  members: {
    totalCount: selectedChat?.totalParticipants || 1,
    isLoading: true,
    users: [],
    hasMore: true,
    skip: 0,
    search: '',
  },
  isSuperAdmin: !!selectedChat?.isSuperAdmin,
  isMuted: !!selectedChat?.isMuted,
  selectedMessageDate: messageDate,
  selectedMessages: [],
  selectionMode: null,
  isReportLoading: false,
  isAdmin: !!selectedChat?.isAdmin,
  isGroup,
  hasMoreOlder: true,
  hasMoreNewer: !!messageDate,
  selectedReply: null,
  isArchived: !!selectedChat?.isArchived,
  avatar: avatar || selectedChat?.avatar || (isGroup ? null : getDefaultAvatar(id)),
  name: name || selectedChat?.name || '',
  isBlocked: !!selectedChat?.isBlocked,
  description: selectedChat?.description || '',
  typingName: null,
  messages: [],
  messagesGroupCount: [],
  mediaGallery: { media: [], currentIndex: 0, isLoading: false },
  media: {
    list: null,
    hasMore: true,
    isLoading: false,
  },
  links: {
    list: null,
    hasMore: true,
    isLoading: false,
  },
  files: {
    list: null,
    hasMore: true,
    isLoading: false,
  },
});
