import { ReactNode, ReactElement } from 'react';

import { IChatSearchedMessage, MessagesTypes } from 'types';

export * from './createChatWrapper/types';

export interface ISearchChatOption {
  avatar: string | null;
  name: string;
  additionalInfo: string;
  id: string;
  isGroup: boolean;
  isSuperAdmin: boolean;
}

export interface IUserOption {
  avatar: string;
  name: string;
  username: string;
  id: string;
}

export interface IMemberOption {
  avatar: string;
  isAdmin: boolean;
  isFollowing: boolean;
  name: string;
  username: string;
  id: string;
}

export interface ISideBarHeader {
  title: string;
  rightIcon?: any;
  leftIcon?: any;
  onRightIconClick?: () => void;
  onLeftIconClick?: () => void;
  searchText: string;
  isFilterHidden?: boolean;
  searchPlaceholder?: string;
  isFilterActive?: boolean;
  onSearch(text: string): void;
  onFilterToggle?: () => void;
}

export enum IGroupPrivacyOptions {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export interface ICreateGroupDetails {
  name: string;
  avatar: string | null;
  isLoading: boolean;
  isSinglePage: boolean;
  onSubmit(): void;
  onBack(): void;
  onNameChange(options: { name: string }): void;
  onDescriptionChange(options: { description: string }): void;
  onAvatarChange(options: { avatar: string }): void;
}

export interface ISideBarRow {
  avatar: string | null;
  title: string;
  text?: string;
  topIcon?: any;
  avatarPlaceholderIcon: any;
  rightNode?: ReactNode;
  onClick(): void;
}

export enum ChatSearchOptions {
  All = '0',
  Users = '1',
  Messages = '2',
  Groups = '3',
}

export interface IUserSearchList {
  options: ISearchChatOption[];
  searchText: string;
  hasMore: boolean;
  emptyState: ReactElement;
  loadMore(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IGroupSearchList {
  options: ISearchChatOption[];
  searchText: string;
  hasMore: boolean;
  emptyState: ReactElement;
  loadMore(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IMessageSearchList {
  options: IChatSearchedMessage[];
  isLoading: boolean;
  searchText: string;
  hasMore: boolean;
  emptyState: ReactElement;
  loadMore(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IAllSearchProps {
  users: ISearchChatOption[];
  groups: ISearchChatOption[];
  messages: IChatSearchedMessage[];
  isLoading: boolean;
  searchText: string;
  emptyState: ReactElement;
  onSeeAllClick(tab: ChatSearchOptions): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IChatSearchResults {
  users: ISearchChatOption[];
  messages: IChatSearchedMessage[];
  isLoading: boolean;
  groups: ISearchChatOption[];
  hasMore: boolean;
  peek: {
    users: ISearchChatOption[];
    groups: ISearchChatOption[];
    messages: IChatSearchedMessage[];
  };
  searchText: string;
  loadMore(): void;
  onChatClick(options: { id: string; messageDate?: number; isGroup: boolean }): void;
}

export interface IUserSearchSection {
  title: string;
  options: ISearchChatOption[];
  searchText: string;
  onSeeAllClick(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IGroupSearchSection {
  title: string;
  searchText: string;
  options: ISearchChatOption[];
  onSeeAllClick(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface IMessageSearchSection {
  title: string;
  options: IChatSearchedMessage[];
  isLoading: boolean;
  searchText: string;
  onSeeAllClick(): void;
  onChatClick(id: string, isGroup: boolean, messageDate?: number): void;
}

export interface ISearchRow {
  searchText: string;
  avatar: string | null;
  title: string;
  description: string;
  isSuperAdmin: boolean;
  rightButton?: ReactElement;
  onClick(): void;
}

export enum MessagesStatuses {
  Delivered = 'delivered',
  Error = 'error',
  Awaiting = 'awaiting',
}

export interface IMessageItem {
  avatar: string | null;
  name: string;
  username: string;
  id: string;
  isOwner: boolean;
  isSuperAdmin: boolean;
  type: MessagesTypes;
  ownerName: string;
  text: string;
  totalUnreadCount: number;
  isMuted: boolean;
  isSelected: boolean;
  date: number;
  isGroup: boolean;
  status: MessagesStatuses;
  shouldDisplaySenderName: boolean;
}

export interface IMessageRow extends IMessageItem {
  onClick(id: string): void;
}

export interface IArchivedSidebar {
  chats: IMessageItem[];
  onClose(): void;
  onChatClick(options: { id: string; isGroup: boolean }): void;
}

export interface ISidebarList {
  chats: IMessageItem[] | null;
  totalArchived: number;
  search: string;
  isFilterActive: boolean;
  loggedInUserId: string;
  onSearch(options: { searchText: string; loggedInUserId: string }): void;
  onArchivedClick(): void;
  onChatCreate(): void;
  onFilterClick(): void;
  onChatClick(options: { id: string; isGroup: boolean }): void;
}

export interface IMessageList {
  chats: IMessageItem[] | null;
  totalArchivedChats: number;
  onChatClick(id: string, isGroup: boolean): void;
  onArchivedClick(): void;
}

export interface IGroupOption {
  avatar: string;
  name: string;
  id: string;
  totalMembers: number;
  isJoinRequested: boolean;
}

export interface IMessageSearchRow {
  searchText: string;
  avatar: string;
  title: string;
  isSuperAdmin: boolean;
  description: string;
  username: string | null;
  ownerName: string;
  onClick(): void;
}

export enum SidebarListOptions {
  CreateChat,
  ArchivedChats,
  Chats,
}

export interface IForwardUserRow {
  avatar: string | null;
  name: string;
  username: string | null;
  isSelected: boolean;
  onClick(): void;
}
