import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetTheAppApi } from './GetTheAppApi';
import { IGetTheAppState, IStore2, ServerStatus } from 'types';

const initialState: IGetTheAppState = {
  serverStatus: null,
  serverErrorMessage: undefined,
};

const api = new GetTheAppApi();

export const onSendAppLink = createAsyncThunk(
  'async/onSendAppLink',
  async (input: { phone: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.sendAppLink(input, token);
  },
);

export const GetTheAppSlicer = createSlice({
  name: 'getTheApp',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.serverStatus = null;
      state.serverErrorMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onSendAppLink.fulfilled, (state) => {
      state.serverStatus = ServerStatus.SUCCESS;
    });
    builder.addCase(onSendAppLink.rejected, (state, action) => {
      state.serverErrorMessage = action.error.message;
      state.serverStatus = ServerStatus.ERROR;
    });
  },
});
