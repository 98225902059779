import { FC } from 'react';
import { Drawer, DrawerSizes } from 'tedooo-web-design-system/ui';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DrawerSlicer } from 'store/drawers/DrawerActions';
import { Avatar, Span } from 'components/helpers';
import { IMenuDrawer, IStore2, DiscoverListsId, ProfileMenuOptions } from 'types';
import { UserSelector } from 'store/user/UserSelector';
import { ROUTES } from 'constant';
import './menuDrawer.scss';
import ProfileContent from '../profileContent/ProfileContent';
import { mapProfileMenuOptionToRoute, mapProfileOptionToPopupId } from 'components/menu/helper';
import { translations } from 'translations/en';
import { UserSlicer } from 'store/user/UserActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import { buildDiscoverPageQuery } from 'utils';
import { AnalyticsEvents } from 'services/AnalyticsEvents';

const MenuDrawer: FC<IMenuDrawer> = ({
  avatar,
  onSignOut,
  userId,
  userName,
  onHide,
  showPopup,
}) => {
  const navigate = useNavigate();

  const onProfileClick = () => {
    navigate(`/${ROUTES.USER}/${userId}`);
    onHide();
  };

  const onSignOutClick = () => {
    onHide();
    onSignOut();
    navigate(`/${ROUTES.HOME}`);
  };

  const onNavigate = (type: ProfileMenuOptions) => {
    if (type === ProfileMenuOptions.Posts) {
      navigate(`/${ROUTES.HOME}?user=${userId}`);
    } else if (type === ProfileMenuOptions.Favorites) {
      const query = buildDiscoverPageQuery({
        id: DiscoverListsId.Favorites,
        title: translations.PRODUCTS_PAGE.FAVORITES_TITLE,
        paginationToken: null,
      });
      navigate(`/${ROUTES.PRODUCTS}${query}`);
    } else {
      if (type === ProfileMenuOptions.Visions) {
        AnalyticsEvents.onEnterVisionsPage('more');
      }

      const route = mapProfileMenuOptionToRoute[type];

      if (!route) {
        showPopup({ id: mapProfileOptionToPopupId[type] });
      } else {
        navigate(`/${route}`);
      }
    }
    onHide();
  };

  return (
    <Drawer
      size={DrawerSizes.Medium}
      title="Account"
      onClose={onHide}
      placement="left"
      visible
      closeable
    >
      <div className="menu-drawer-header" onClick={onProfileClick}>
        <Avatar src={avatar} size={34} />
        <div className="text-container">
          <Span text={userName || ''} className="title" />
          <Span text={translations.COMMON.VIEW_PROFILE} onClick={onProfileClick} className="text" />
        </div>
      </div>
      <ProfileContent onSignOut={onSignOutClick} onOptionClick={onNavigate} isDraftSaved={false} />
    </Drawer>
  );
};

const mapStateToProps = (state: IStore2) => {
  const avatar = UserSelector.defaultAvatarSelector(state);

  const userName = UserSelector.loggedUserNameSelector(state);

  const userId = UserSelector.loggedUserIdSelector(state);

  return { avatar, userId, userName };
};

const mapDispatchToProps = {
  onHide: DrawerSlicer.actions.hide,
  onSignOut: UserSlicer.actions.signOut,
  showPopup: PopupSlicer.actions.show,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuDrawer);
