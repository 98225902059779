import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IProductDimensions,
  IShippingRateRequestAddress,
  IStore2,
  IShippingAddress,
  ICheckoutItem,
} from 'types';
import { ShippingApi } from './ShippingApi';
import { Countries } from 'constant/countries';
import { parseToCreateShippingProfileBody } from './helper';

const api = new ShippingApi();

export const linkShippo = createAsyncThunk('async/linkShippo', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  return api.linkShippo(token);
});

export const unlinkShippo = createAsyncThunk('async/unlinkShippo', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  return api.unlinkShippo(token);
});

export const getShippingServices = createAsyncThunk(
  'async/getShippingServices',
  async (input: { shippingCountry: Countries; isCalculated: boolean }, { getState }: any) => {
    const state: IStore2 = getState();

    const { shippingCountry, isCalculated } = input;

    const { token = '' } = state.user;

    return api.getShippingServices(token, shippingCountry, isCalculated);
  },
);

export const validateShippingProfileAddress = createAsyncThunk(
  'async/validateShippingProfileAddress',
  async (_, { getState, rejectWithValue }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { address, country, city, postalCode } =
      state.shipping.shippingProfileForm.shippingAddress;

    try {
      return await api.validateShippingAddress(token, country, city, address, postalCode);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const validateShippingAddress = createAsyncThunk(
  'async/validateShippingAddress',
  async (_, { getState, rejectWithValue }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { streetAddress, country, city, postalCode } = state.shipping.shippingAddressForm;

    try {
      return await api.validateShippingAddress(token, country, city, streetAddress, postalCode);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const addShippingProfile = createAsyncThunk(
  'async/addShippingProfile',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { name, shippingAddress, shippingDestinations, processingTime, shippingPriceOption } =
      state.shipping.shippingProfileForm;

    const body = parseToCreateShippingProfileBody(
      name,
      shippingAddress,
      shippingDestinations,
      processingTime,
      shippingPriceOption,
    );

    return api.addShippingProfile(token, body);
  },
);

export const updateShippingProfile = createAsyncThunk(
  'async/updateShippingProfile',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id, name, shippingAddress, shippingDestinations, processingTime, shippingPriceOption } =
      state.shipping.shippingProfileForm;

    const body = parseToCreateShippingProfileBody(
      name,
      shippingAddress,
      shippingDestinations,
      processingTime,
      shippingPriceOption,
    );

    return api.updateShippingProfile(token, id || '', body);
  },
);

export const deleteShippingProfile = createAsyncThunk(
  'async/deleteShippingProfile',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token = '' } = state.user;

    return api.deleteShippingProfile(token, id);
  },
);

export const getLabelInformation = createAsyncThunk(
  'async/getLabelInformation',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId } = input;

    return api.getLabelInformation(orderId, token);
  },
);

export const getPackagesType = createAsyncThunk(
  'async/getPackagesType',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getPackagesType(token);
  },
);

export const buyShippingLabel = createAsyncThunk(
  'async/buyShippingLabel',
  async (
    input: {
      orderId: string;
      username: string;
      avatar: string;
      orderNumber: string;
      isAddressEdited: boolean;
      rateId: string;
      packageId: string;
      hasInsurance: boolean;
      hasSignature: boolean;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId, rateId } = input;

    return api.buyShippingLabel(orderId, rateId, token);
  },
);

export const getShippingLabelAndEmail = createAsyncThunk(
  'async/getShippingLabelAndEmail',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId } = input;

    return api.getShippingLabelAndEmail(orderId, token);
  },
);

export const sendShippingLabelToEmail = createAsyncThunk(
  'async/sendShippingLabelToEmail',
  async (input: { orderId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId } = input;

    return api.sendShippingLabelToEmail(orderId, token);
  },
);

export const getShippingLabelShippingRates = createAsyncThunk(
  'async/getShippingLabelShippingRates',
  async (
    input: {
      orderId: string;
      shipDate: number;
      fromAddress: IShippingRateRequestAddress;
      toAddress: IShippingAddress;
      dimensions: IProductDimensions;
      withInsurance: boolean;
      withSignature: boolean;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { orderId, shipDate, fromAddress, toAddress, dimensions, withInsurance, withSignature } =
      input;

    const recipientAddress = {
      address: toAddress.streetAddress,
      city: toAddress.city,
      country: toAddress.country,
      postalCode: toAddress.postalCode,
      state: null,
      phoneNumber: toAddress.phoneNumber,
    };

    return api.getShippingRates(
      orderId,
      shipDate,
      fromAddress,
      recipientAddress,
      dimensions,
      withInsurance,
      withSignature,
      token,
    );
  },
);

export const getRequestToBuyBasketShippingRates = createAsyncThunk(
  'async/getRequestToBuyBasketShippingRates',
  async (input: { items: ICheckoutItem[] }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { items } = input;

    const products = items.map((item) => ({
      id: item.id,
      quantity: item.quantity,
    }));

    return api.getBasketShippingRates(token, products);
  },
);

export const getShippingProfiles = createAsyncThunk(
  'async/getShippingProfiles',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return api.getShippingProfiles(token);
  },
);
