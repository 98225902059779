import { gql } from '@apollo/client';

export const GET_PRODUCTS_QUERY = gql`
  query ($listId: ListItem!, $limit: Int, $paginationToken: String) {
    getProductsByListId(listId: $listId, limit: $limit, paginationToken: $paginationToken) {
      totalCount
      paginationToken
      products {
        id
        didLike
        totalLikes
        imageUrl
        title
        currencyCode
        price
        originalPrice
        userId
        isDigital
        labels {
          text
          textColor
          backgroundColor
        }
      }
    }
  }
`;
