import {
  FeedTypes,
  IComment,
  IFeedProfileState,
  IFeedState,
  IFeedStore,
  IPost,
  IShopBaseInfoResponse,
  IShopShortInfo,
  IShopShortInfoResponse,
  ISubPost,
  IUserInfoResponse,
  popupIdsOptions,
} from 'types';
import { translations } from 'translations/en';

export const getSelectedFeed = (state: IFeedState) => {
  const { selectedFeedType, shopFeed, chatFeed, mainFeed, searchFeed, userFeed } = state;

  if (selectedFeedType === FeedTypes.User) {
    return userFeed;
  }
  if (selectedFeedType === FeedTypes.Shop) {
    return shopFeed;
  }
  if (selectedFeedType === FeedTypes.Chat) {
    return chatFeed;
  }
  if (selectedFeedType === FeedTypes.Search) {
    return searchFeed;
  }

  return mainFeed;
};

export const getSelectedPost = (state: IFeedState) => {
  const { mapPostIdToSubPosts, selectedSubPostId, selectedPostId } = state;

  const feed = getSelectedFeed(state);

  if (selectedSubPostId && selectedPostId) {
    const posts = mapPostIdToSubPosts[selectedPostId];

    return posts?.find((item) => item.id === selectedSubPostId);
  }

  return feed?.list?.find((item) => item.id === selectedPostId);
};

export const getMainPostById = (list: Array<IPost> | null, postId: string | null) => {
  return list?.find((item) => item.id === postId);
};

export const getSubPostById = (list: Array<ISubPost> | null, postId: string | null) => {
  return list?.find((item) => item.id === postId);
};

export const getSubPostFromPost = (post: IPost): ISubPost => {
  return {
    actionId: post.id,
    likes: post.likes,
    totalLikes: post.totalLikes,
    totalComments: post.totalComments,
    comments: post.comments,
    id: post.id,
    isCommentsExpanded: true,
    isLiked: post.isLiked,
  };
};

export const getPost = (state: IFeedState, postId: string | null) => {
  const { selectedPostId, selectedSubPostId, mapPostIdToSubPosts } = state;

  const feed = getSelectedFeed(state);

  if (selectedPostId && selectedSubPostId) {
    const subPostsList = mapPostIdToSubPosts[selectedPostId];

    if (subPostsList) {
      return getSubPostById(subPostsList, postId);
    }

    return null;
  }

  return getMainPostById(feed.list, postId);
};

export const getCommentById = (
  post: ISubPost | IPost | null,
  commentId: string,
  replyId?: string,
) => {
  if (!post) return null;
  let selectedComment;
  // gets selected comment from the last comment or self comments props (feed)
  if (!post.isCommentsExpanded) {
    selectedComment =
      post.lastComment?.id === commentId
        ? post.lastComment
        : post.selfComments?.find((comment) => comment.id === commentId);
    if (selectedComment && replyId) {
      selectedComment =
        selectedComment.replies.find((item) => item.id === replyId) || selectedComment;
    }
  } else {
    // gets selected comment from the full list of comments (popup)
    selectedComment = post.comments?.list?.find((comment) => comment.id === commentId) || null;
    if (selectedComment && replyId) {
      selectedComment =
        selectedComment.replies.find((item) => item.id === replyId) || selectedComment;
    }
  }

  return selectedComment;
};

export const checkIsFeedPopup = (ids: popupIdsOptions[]) => {
  const FEED_POPUPS = [
    popupIdsOptions.feedReactionsPopup,
    popupIdsOptions.feedDeleteCommentPopup,
    popupIdsOptions.feedImagesPopup,
    popupIdsOptions.deletePostPopup,
    popupIdsOptions.feedRepostOptionsPopup,
    popupIdsOptions.feedRepostsPopup,
  ];

  return FEED_POPUPS.find((popupId) => ids.find((popupId2) => popupId2 === popupId));
};

export const toggleAllRepostsLikes = (list: IPost[] | null, actionId: string) => {
  list?.forEach((post) => {
    if (actionId === post.actionId) {
      onPostLikeToggle(post);
    }
  });
};

export const onPostLikeToggle = (post?: IPost | ISubPost | null) => {
  if (post) {
    const { isLiked: prevIsLiked } = post;

    const updatedIsLiked = !prevIsLiked;

    const addToLikes = prevIsLiked ? -1 : 1;

    post.isLiked = updatedIsLiked;
    post.totalLikes += addToLikes;
  }
};

export const isMainPost = (post: IPost | ISubPost) => {
  return 'isReposted' in post;
};

export const toggleAllReposts = (feed: IFeedStore, post: IPost) => {
  feed.list?.forEach((feedItem) => {
    const isRepost = feedItem.actionId === post.actionId;

    const isRepostWithText =
      feedItem.repostDetails?.id === post.actionId || post.repostDetails?.id === feedItem.actionId;

    if (isRepost || isRepostWithText) {
      onRepostToggle(feedItem);
    }
  });
};

export const onRepostToggle = (post?: IPost | null) => {
  if (post) {
    const { isReposted: prevIsReposted } = post;

    const updatedIsReposted = !prevIsReposted;

    const addToReposts = prevIsReposted ? -1 : 1;

    post.isReposted = updatedIsReposted;
    if (!post.repostDetails) {
      post.totalReposts += addToReposts;
    }
  }
};

export const addReplyToComment = (
  post: ISubPost | IPost,
  data: IComment,
  repliedToCommentId: string,
) => {
  if (post) {
    const { list: prevList } = post.comments;

    let comment = prevList?.find((item: IComment) => item.id === repliedToCommentId);

    const isRepliedToCommentIdInLastComment = !!post.lastComment?.replies.find(
      (reply) => reply.id === repliedToCommentId,
    );

    const isRepliedToCommentLastComment = !comment && repliedToCommentId === post.lastComment?.id;

    if ((isRepliedToCommentLastComment || isRepliedToCommentIdInLastComment) && post.lastComment) {
      comment = post.lastComment;
    }
    // if comment not found, search in replies and return the comment that contains it.
    if (!comment) {
      comment = prevList?.find((item) => {
        return item.replies.find((reply) => reply.id === repliedToCommentId);
      });
    }

    if (comment) {
      if (!comment.isRepliesExpanded) {
        comment.replies = [data];
      } else {
        comment.replies.unshift(data);
      }
      post.totalComments += 1;
      comment.totalReplies += 1;
      comment.isRepliesExpanded = true;
    }
  }
};

export const addCommentToAllReposts = (
  list: IPost[] | null,
  actionId: string,
  comment: IComment,
) => {
  list?.forEach((post) => {
    if (actionId === post.actionId) {
      addCommentToPost(post, comment);
    }
  });
};

export const addCommentToPost = (post: ISubPost | IPost, comment: IComment) => {
  if (post) {
    post.totalComments += 1;
    if (post.isCommentsExpanded) {
      const list = post.comments.list || [];
      post.comments.list = [comment, ...list];
    } else {
      const list = post.selfComments || [];
      post.selfComments = [comment, ...list];
      post.lastComment = comment;
    }
  }
};

export const deleteCommentFromAllReposts = (
  list: IPost[] | null,
  actionId: string,
  comment: IComment | null,
  selectedReplyId: string | null,
  selectedCommentId: string | null,
) => {
  list?.forEach((post) => {
    if (actionId === post.actionId) {
      deleteCommentFromPost(post, comment, selectedReplyId, selectedCommentId);
    }
  });
};

export const deleteCommentFromPost = (
  post: IPost | ISubPost | null,
  comment: IComment | null,
  selectedReplyId: string | null,
  selectedCommentId: string | null,
) => {
  if (comment && post) {
    const { selfComments, isCommentsExpanded, comments } = post;
    post.totalComments -= 1;

    const isCommentDelete = !selectedReplyId;

    const { totalReplies } = comment;

    if (comment.id === post.lastComment?.id) {
      if (selectedReplyId) {
        post.lastComment.replies = post.lastComment?.replies.filter(
          (reply) => reply.id !== selectedReplyId,
        );
      } else {
        post.lastComment = null;
      }
    }

    // delete only reply
    if (isCommentDelete) {
      const noReplies = totalReplies === 0;

      if (noReplies) {
        if (isCommentsExpanded) {
          comments.list = comments.list!.filter((item: any) => item.id !== selectedCommentId);
        } else {
          post.selfComments = selfComments?.filter((item: any) => item.id !== selectedCommentId);
        }
      }
      // with replies - change to deleted comment
      else {
        comment.isDeleted = true;
        comment.text = translations.FEED.POST.COMMENTS.DELETED;
      }
    }
    // Delete reply
    else {
      comment.replies = comment.replies.filter((reply) => reply.id !== selectedReplyId);
      comment.totalReplies -= 1;
    }
  }
};

const getUserShopItem = (data: IShopShortInfoResponse): IShopShortInfo => {
  const name = data.name || data.shop_number;

  return { id: data._id, name, shopImage: data.img_links || '', catalog: null };
};

export const getUserShopsItems = (data: IShopShortInfoResponse[]) => {
  return data.map((item) => getUserShopItem(item));
};

export const getFeedUserProfile = (data: IUserInfoResponse): IFeedProfileState => {
  const avatar = data.avatar || null;

  const coverPhoto = data.coverPhoto || null;

  const extraInformation = data.shopName || data.bio;

  return {
    avatar,
    name: data.username,
    extraInformation,
    coverPhoto,
    totalFollowers: data.totalFollowers,
  };
};

export const getFeedShopProfile = (data: IShopBaseInfoResponse): IFeedProfileState => {
  const avatar = data.image || null;

  return {
    avatar,
    name: data.name,
    coverPhoto: null,
    extraInformation: '',
    totalFollowers: data.totalFollowers,
  };
};
