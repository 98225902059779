import { FC } from 'react';
import './actionHeader.scss';
import { FilledHeartIcon, RepostIcon } from 'tedooo-web-design-system/icons';

import { IActionHeader, mapPostActionTypeToText, PostActionType } from 'types';
import { Span } from 'components/helpers';

const ActionHeader: FC<IActionHeader> = ({ type, username, totalUsers }) => {
  const text = mapPostActionTypeToText[type];
  let icon;
  if (type === PostActionType.Repost) {
    icon = <RepostIcon />;
  }
  if (type === PostActionType.Like) {
    icon = <FilledHeartIcon />;
  }

  return (
    <div className="action-header-container">
      {icon}
      <div className="text-container">
        <span>
          <Span text={`@${username} `} className="name" />
          {totalUsers && <Span text={`and ${totalUsers} more `} className="action-text" />}
          <Span text={text} className="action-text" />
        </span>
      </div>
    </div>
  );
};

export default ActionHeader;
