export enum ForgetPasswordPhasesOptions {
  phoneForm = 'phoneForm',
  codeVerification = 'codeVerification',
  createPassword = 'createPassword',
  endMessage = 'endMessage',
}

export interface IForgetPasswordState {
  phase: ForgetPasswordPhasesOptions;
  prevPassword: string;
  phone: string;
  username: string;
  isLoading: boolean;
  serverErrorMessage?: string;
}

export interface IForgetPasswordApi {
  onForgetPassword(): Promise<any>;

  sendVerifyCode(phone: string): Promise<any>;

  resendCodeInCall(phone: string): Promise<any>;

  submitVerificationCode(code: string, username: string): Promise<any>;

  submitNewPassword(prevPassword: string, password: string, username: string): Promise<any>;
}
