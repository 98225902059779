import { FC } from 'react';
import { connect } from 'react-redux';
import './mobileMoreButtonDrawer.scss';
import { Star } from 'tedooo-web-design-system/icons';
import { Drawer, DrawerSizes } from 'tedooo-web-design-system/ui';

import { popupIdsOptions } from 'store/popup/types';
import { DrawerSlicer } from 'store/drawers/DrawerActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import { translations } from 'translations/en';

export interface IMobileMoreButtonDrawer {
  hide(): void;
  show(id: { id: string }): void;
}

const MobileMoreButtonDrawer: FC<IMobileMoreButtonDrawer> = ({ hide, show }) => {
  const onReviewClick = () => {
    hide();
    show({ id: popupIdsOptions.getTheAppPopup });
  };

  return (
    <Drawer
      size={DrawerSizes.Small}
      onClose={hide}
      placement="bottom"
      visible
      closeable
      className="shop-more-drawer"
      height="fit-content"
    >
      <div className="field">
        <Star />
        <span onClick={onReviewClick}>{translations.SHOP.RATE_SHOP}</span>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = {
  hide: DrawerSlicer.actions.hide,
  show: PopupSlicer.actions.show,
};

export default connect(null, mapDispatchToProps)(MobileMoreButtonDrawer);
