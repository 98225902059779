import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscoverApi } from './DiscoverApi';
import { IStore2 } from 'types';

const discoverApi = new DiscoverApi();

export const getDiscoverPageData = createAsyncThunk(
  'async/getDiscoverPageData',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    return discoverApi.getDiscoverPageData(token);
  },
);

export const getShopOfTheDay = createAsyncThunk(
  'async/getShopOfTheDay',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return discoverApi.getShopOfTheDay(token);
  },
);
