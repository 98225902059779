import { GetOneTimePaymentLinkInformationResponse } from '../../graphql/generated/graphql';
import { IOneTimePaymentDetails } from './types';
import { CurrencyCode } from '../../constant/currencies';

export const parseOneTimePaymentInformationFromLink = (
  data: GetOneTimePaymentLinkInformationResponse,
): IOneTimePaymentDetails => {
  return {
    avatar: data.avatar || null,
    username: data.username,
    userId: data.userId,
    isPaypalConnected: data.isPaypalConnected,
    currencySymbol: data.currencySymbol,
    currencyCode: data.currencyCode as CurrencyCode,
    stripeDetails: data.stripePaymentOption.map((item) => ({
      id: item.id,
      images: [item.image],
    })),
  };
};
