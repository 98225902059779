import { FC } from 'react';
import { Checkbox } from 'tedooo-web-design-system/ui';
import './groupCard.scss';
import classNames from 'classnames';

import { Avatar } from 'components/avatar/Avatar';
import { IGroupCard } from 'types';

const GroupCard: FC<IGroupCard> = ({ avatar, groupName, description, isSelected, onClick }) => {
  return (
    <div className={classNames('group-card-container', { selected: isSelected })} onClick={onClick}>
      <div className="avatar-text-container">
        <Avatar src={avatar} size={48} />
        <div className="text-content">
          <span className="title">{groupName}</span>
          <span className="description">{description}</span>
        </div>
      </div>
      <div className="checkbox-container">
        <Checkbox checked={isSelected} />
      </div>
    </div>
  );
};

export default GroupCard;
