import { FC } from 'react';
import './followShopsCard.scss';
import { Checkbox } from 'tedooo-web-design-system/ui';
import classNames from 'classnames';

import { Avatar, Image } from 'components/helpers';
import PremiumTag from 'components/premiumTag/PremiumTag';
import { IFollowShopsCard } from 'types';
import EmptyPhoto from 'photos/emptyState/shopImageEmptyState.png';

const FollowShopsCard: FC<IFollowShopsCard> = ({ shopDetails, onClick }) => {
  const { images, avatar, shopName, username, shopCategory, isSelected } = shopDetails;

  const imagesToDisplay = [...images, EmptyPhoto, EmptyPhoto].slice(0, 3);

  return (
    <div
      className={classNames('follow-shops-card-container', { selected: isSelected })}
      onClick={onClick}
    >
      <div className="images-container">
        <PremiumTag size="medium" />
        {imagesToDisplay.map((image, index) => (
          <Image
            key={index}
            src={image}
            className={classNames('shop-image', { empty: !images[index] })}
          />
        ))}
        <Avatar src={avatar} size={40} />
      </div>
      <div className="content-container">
        <div className="text-container">
          <span className="shop-name">{shopName}</span>
          <div className="details">
            <span className="shop-username">{username}</span>
            <div className="dot" />
            <span className="shop-category">{shopCategory}</span>
          </div>
        </div>
        <Checkbox checked={isSelected} />
      </div>
    </div>
  );
};

export default FollowShopsCard;
