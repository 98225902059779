export const kgToLbs = (kg: number) => kg * 2.20462;

export const lbsToKg = (lbs: number) => lbs / 2.20462;

export const ozToLb = (oz: number): number => oz / 16;

export const lbsToOz = (lbs: number): number => lbs * 16;

export const gramsToKg = (grams: number): number => grams / 1000;

export const kgToGrams = (grams: number): number => grams * 1000;

export const cmToInch = (cm: number): number => cm / 2.54;

export const inchToCm = (cm: number): number => cm * 2.54;

export const secondsToMilliseconds = (seconds: number): number => seconds * 1000;
