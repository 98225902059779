import {
  CheckCouponCodeResponseError,
  CheckCouponCodeResponseSuccess,
} from '../../graphql/generated/graphql';
import { IDiscountDetails } from '../orders/types';

export const getDiscountDetailsFromResponse = (
  object: CheckCouponCodeResponseError | CheckCouponCodeResponseSuccess,
  couponCode: string,
): IDiscountDetails | null => {
  if ('newPrice' in object) {
    return {
      finalPrice: object.newPrice / 100,
      couponCodeName: couponCode,
    };
  }

  return null;
};

export const getCouponCodeErrorMessageFromResponse = (
  object: CheckCouponCodeResponseError | CheckCouponCodeResponseSuccess,
) => {
  if ('reason' in object && 'code' in object) {
    return object.reason;
  }

  return null;
};
