import { FC, useEffect, useRef } from 'react';

import './fileUploader.scss';
import { debounce } from 'utils';
import { getFileType } from 'api/imageUploader';
import { IFileUploader } from 'types';
import { uploadFile, uploadImage } from 'api';

export const ACCEPT_OPTIONS = {
  photo: 'image/png,image/jpeg,image/jpg,image/svg',
  video: 'video/mp4',
  file: '.pdf,.jpg,.svg',
  csv: 'text/csv',
};

const FileUploader: FC<IFileUploader> = ({
  children,
  shouldOpenImagePicker,
  id,
  feature,
  onImagesLoadingStart,
  maxItems,
  onImageUpload,
  acceptTypes = ['photo'],
}) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const upload = async (file: File) => {
    const type = getFileType(file.name);

    if (ACCEPT_OPTIONS.file.includes(type)) {
      return uploadFile({ file, feature });
    }

    return uploadImage({ file, feature });
  };

  const onImageUploaderClick = (e: any) => e.stopPropagation();

  const openImagePicker = () => ref.current?.click();

  const onClick = () => window.addEventListener('focus', onPickerCancel);

  const onUpload = async (e: any) => {
    const { files } = e.target;
    window.removeEventListener('focus', onPickerCancel);

    const formattedFiles: File[] = Object.values(files);
    onImagesLoadingStart(formattedFiles.length);

    const images = await Promise.all(formattedFiles.map(async (file: any) => await upload(file)));
    onImageUpload(images, formattedFiles);
  };

  const onPickerCancel = debounce(() => {
    if (!ref.current?.files?.length) {
      onImageUpload([], []);
    }
    window.removeEventListener('focus', onPickerCancel);
  }, 1000);

  useEffect(() => {
    if (shouldOpenImagePicker) {
      openImagePicker();
    }
  }, [shouldOpenImagePicker]);

  const mode = maxItems === 1 ? undefined : true;

  const accept = acceptTypes?.map((type) => ACCEPT_OPTIONS[type]).join(',');

  return (
    <div className="image-uploader-button" onClick={onImageUploaderClick}>
      <input
        ref={ref}
        onChange={onUpload}
        type="file"
        onClick={onClick}
        multiple={mode}
        id={id}
        data-multiple-caption="{count} files selected"
        className="file-input"
        accept={accept}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default FileUploader;
