import { Skeleton } from 'tedooo-web-design-system/ui';
import './mojoSkeleton.scss';

const MojoSkeleton = () => {
  return (
    <div className="mojo-skeleton">
      <div className="user-details">
        <Skeleton title avatar className="title-skeleton" rows={1} />
        <Skeleton title={false} avatar={false} rows={2} className="text-skeleton" />
      </div>
      <Skeleton title={false} avatar={{ shape: 'square' }} className="image-skeleton" />
    </div>
  );
};

export default MojoSkeleton;
