import { FC } from 'react';
import { connect } from 'react-redux';

import Post from 'components/post/Post';
import { UserDetailsLocations, popupIdsOptions, IMojoWrapper, ITaggedUser } from 'types';
import {
  onHidePostFromActivity as hidePostFromActivity,
  onPostHide as hidePost,
} from 'store/feed/PostActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import {
  onMojoComment,
  onMojoFollowUserToggle,
  onMojoLikeCommentToggle,
  onMojoLikeToggle,
} from 'store/mojos/mojo/MojoActions';
import { FeedSlicer } from 'store/feed/FeedSlicer';

const MAX_CHARS = 300;

const MojoWrapper: FC<IMojoWrapper> = ({
  index,
  token,
  mojoSource,
  post,
  shouldHideFollowButton,
  onPostHide,
  onPostHideFromActivity,
  show,
  onLikeToggle,
  onComment,
  onFollow,
  onLikeComment,
  onToggleCommentReplies,
}) => {
  const { id, isLiked, userDetails, actionId, repostDetails } = post;

  const repostId = repostDetails ? repostDetails.id : actionId;

  const onFollowToggle = () => {
    const options = {
      isFollowing: post.isFollowing,
      userId: userDetails.userId,
      source: mojoSource,
    };
    onFollow(options);
  };

  const onLikeClick = () => {
    const options = { id: post.id, isLiked, source: mojoSource, originSource: undefined };
    onLikeToggle(options);
  };

  const onImageClick = (imageIndex: number) => {
    const options = {
      id: popupIdsOptions.feedImagesPopup,
      imageIndex,
      postId: id,
    };
    show(options);
  };

  const onCommentLikesClick = (commentId: string, replyId?: string) => {
    const options = { id: popupIdsOptions.feedReactionsPopup, postId: id, commentId, replyId };
    show(options);
  };

  const onCommentSubmit = (
    comment: string,
    taggedUsers: ITaggedUser[],
    attachedImages: Array<string>,
  ) => {
    const options = {
      postId: id,
      image: attachedImages[0],
      taggedUsers,
      text: comment,
      token,
    };

    onComment(options);
  };

  const onReplySubmit = (
    commentId: string,
    replyText: string,
    taggedUsers: ITaggedUser[],
    attachedImages: Array<string>,
  ) => {
    const options = {
      image: attachedImages[0],
      parentCommentId: commentId,
      actionId,
      text: replyText,
      taggedUsers,
      postId: id,
      token,
    };
    onComment(options);
  };

  const onToggleRepliesClick = (commentId: string) => {
    onToggleCommentReplies({ commentId, postId: id });
  };

  const onCommentDelete = (commentId: string, replyId?: string) => {
    const options = {
      id: popupIdsOptions.mojoDeleteCommentPopup,
      postId: id,
      actionId,
      commentId,
      replyId,
    };
    show(options);
  };

  const onCommentLike = (commentId: string, isCommentLiked: boolean, replyId?: string) => {
    onLikeComment({ commentId, isLiked: isCommentLiked, postId: post.id, replyId });
  };

  const onShowRepostsClick = () => {
    show({ id: popupIdsOptions.feedRepostsPopup, postId: id, actionId: repostId });
  };

  const onDeletePost = () => {
    show({ id: popupIdsOptions.deleteMojoPopup, actionId });
  };

  const onMojoReport = () => {
    show({ id: popupIdsOptions.reportMojoPopup, actionId });
  };

  return (
    <Post
      source={mojoSource}
      onShowRepostsClick={onShowRepostsClick}
      index={index}
      shouldHidePremium={false}
      isClickable
      shouldHideFollowButton={!!shouldHideFollowButton}
      shouldDisplayImages
      onCommentDelete={onCommentDelete}
      onLikesClick={() => {}}
      onImageClick={onImageClick}
      onCommentSubmit={onCommentSubmit}
      onDeletePost={onDeletePost}
      onRepliesLoad={() => {}}
      onLikeClick={onLikeClick}
      onReply={onReplySubmit}
      onCommentsLoad={() => {}}
      onFollowToggle={onFollowToggle}
      onRepostsClick={() => {}}
      shouldDisplayInputByDefault
      textMaxChars={MAX_CHARS}
      post={post}
      type={UserDetailsLocations.Header}
      shouldDisplayComments={post.isCommentsExpanded}
      isSpecificPost={false}
      onPostHide={onPostHide}
      onPostHideFromActivity={onPostHideFromActivity}
      onLikeComment={onCommentLike}
      onPostReport={onMojoReport}
      onCommentLikesClick={onCommentLikesClick}
      onToggleRepliesClick={onToggleRepliesClick}
    />
  );
};

const mapDispatchToProps = {
  onToggleCommentReplies: FeedSlicer.actions.toggleCommentReplies,
  onFollow: onMojoFollowUserToggle,
  onLikeComment: onMojoLikeCommentToggle,
  onPostHide: hidePost,
  onPostHideFromActivity: hidePostFromActivity,
  onComment: onMojoComment,
  onLikeToggle: onMojoLikeToggle,
  show: PopupSlicer.actions.show,
};

export default connect(null, mapDispatchToProps)(MojoWrapper);
