import { HeartIcon } from 'tedooo-web-design-system/icons';
import './totalSavesTag.scss';
import { FC } from 'react';

import { translations } from 'translations/en';

interface ISavesTag {
  totalLikes: number;
}

const TotalSavesTag: FC<ISavesTag> = ({ totalLikes }) => {
  const saveText = totalLikes > 1 ? 'Saves' : translations.COMMON.SAVE;

  return (
    <div className="total-saves-container">
      <HeartIcon />
      <span>
        {totalLikes} {saveText}
      </span>
    </div>
  );
};

export default TotalSavesTag;
