import { FC } from 'react';
import { Button } from 'tedooo-web-design-system/ui';
import { UserCheck, UserPlus } from 'tedooo-web-design-system/icons';

import { translations } from 'translations/en';
import { IFollowButton } from 'types';

const { FOLLOW, FOLLOWING } = translations.COMMON;

const FollowButton: FC<IFollowButton> = ({
  isFullWidth,
  onFollowToggle,
  isFollowing,
  type = 'primary',
}) => {
  if (isFollowing) {
    return (
      <Button
        borderRadius="small"
        isFullWidth={isFullWidth}
        text={FOLLOWING}
        size="medium"
        prefixIcon={<UserCheck />}
        type="secondary"
        onClick={onFollowToggle}
      />
    );
  }

  return (
    <Button
      borderRadius="small"
      isFullWidth={isFullWidth}
      text={FOLLOW}
      size="medium"
      prefixIcon={<UserPlus />}
      type={type}
      onClick={onFollowToggle}
    />
  );
};

export default FollowButton;
