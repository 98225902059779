import { FC, useEffect } from 'react';
import { PlusIcon, Edit } from 'tedooo-web-design-system/icons';
import { AddAvatar, Button } from 'tedooo-web-design-system/ui';
import './profilePictureStep.scss';
import classNames from 'classnames';

import { translations } from 'translations/en';
import DefaultProfilePicture from 'photos/profileAvatarXL.png';
import { IProfilePictureStep } from 'types';

const ProfilePictureStep: FC<IProfilePictureStep> = ({
  profilePicture,
  onEditProfilePictureClick,
  onContinueClick,
  onSkipClick,
  updateUserAvatar,
}) => {
  const badgeIcon = profilePicture ? <Edit /> : <PlusIcon />;

  const isButtonDisabled = !profilePicture;

  useEffect(() => {
    if (profilePicture) {
      updateUserAvatar({ avatar: profilePicture });
    }
  }, [profilePicture]);

  return (
    <div className={classNames('profile-picture-container step-container')}>
      <div className="text-content">
        <span className="title">{translations.ONBOARDING.PROFILE_PICTURE_STEP.ALMOST_THERE}</span>
        <span className="title">
          {translations.ONBOARDING.PROFILE_PICTURE_STEP.ADD_A_PROFILE_PICTURE}
        </span>
        <span className="secondary-title">
          {translations.ONBOARDING.PROFILE_PICTURE_STEP.CHOOSE_A_PHOTO}
        </span>
      </div>

      <div className="profile-picture">
        <AddAvatar
          onAvatarChange={onEditProfilePictureClick}
          id="onboarding-avatar"
          placeholderBackground="#F4F5F5"
          size={208}
          shouldHideCameraOnHover
          avatar={profilePicture || DefaultProfilePicture}
          placeholder={DefaultProfilePicture}
          badge={<div className="badge">{badgeIcon}</div>}
        />
      </div>
      <Button
        isFullWidth
        type="primary"
        size="large"
        borderRadius="large"
        disabled={isButtonDisabled}
        text={translations.COMMON.CONTINUE}
        className="continue-button-profile"
        onClick={onContinueClick}
      />
      {!profilePicture && (
        <Button
          isFullWidth
          type="tertiary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.SKIP_FOR_NOW}
          className="skip-for-now-button-profile"
          onClick={onSkipClick}
        />
      )}
    </div>
  );
};

export default ProfilePictureStep;
