import { IGenerateVisionApi } from 'types';
import {
  GenerateVisionResponse,
  SmartTagsResponse,
  SuccessResponse,
} from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import {
  CHANGE_VISION_VISIBILITY_MUTATION,
  GENERATE_VISION_MUTATION,
  GET_SMART_TAGS_QUERY,
} from './GenerateVisionQueries';

export class GenerateVisionApi implements IGenerateVisionApi {
  async generateVision(
    token: string,
    prompt: string,
    image: string | null,
  ): Promise<GenerateVisionResponse> {
    const variables = {
      prompt,
      image,
      quantity: 4,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: GENERATE_VISION_MUTATION,
      variables,
    });

    return res.data.generateVision;
  }

  async getSmartTags(token: string, query: string): Promise<SmartTagsResponse> {
    const variables = {
      query,
    };

    const res = await graphQlRequest(token).query({
      query: GET_SMART_TAGS_QUERY,
      variables,
    });

    return res.data.getSmartTags;
  }

  async onVisionPublicToggle(
    token: string,
    generationId: string,
    isPublic: boolean,
  ): Promise<SuccessResponse> {
    const variables = {
      generationId,
      isPublic: !isPublic,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: CHANGE_VISION_VISIBILITY_MUTATION,
      variables,
    });

    return res.data.changeVisionVisibility;
  }
}
