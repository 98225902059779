import { MILLISECONDS, STORAGE_KEYS } from 'constant';
import { isEmptyObject, parseObject, stringify } from 'utils';
import {
  IChatGroupsState,
  IChatShopsState,
  ICreateShopState,
  PayoutMethods,
  ThirdPartyConnectionStatus,
} from 'types';

// Token
const removeToken = () => {
  localStorage.removeItem(STORAGE_KEYS.TOKEN);
  sessionStorage.removeItem(STORAGE_KEYS.TOKEN);
};

const getToken = () =>
  sessionStorage.getItem(STORAGE_KEYS.TOKEN) ||
  localStorage.getItem(STORAGE_KEYS.TOKEN) ||
  undefined;

const setTokenInLocal = (token: string) => localStorage.setItem(STORAGE_KEYS.TOKEN, token);

const setTokenInSession = (token: string) => sessionStorage.setItem(STORAGE_KEYS.TOKEN, token);

// user id
const setUserId = (userId: string) => sessionStorage.setItem(STORAGE_KEYS.USER_ID, userId);

const removeUserId = () => sessionStorage.removeItem(STORAGE_KEYS.USER_ID);

const getUserId = () => sessionStorage.getItem(STORAGE_KEYS.USER_ID);

const clearChatShops = () => localStorage.removeItem(STORAGE_KEYS.CHAT_SHOPS);

const clearChatGroups = () => localStorage.removeItem(STORAGE_KEYS.CHAT_GROUPS);

const getChatShops = (): IChatShopsState => {
  const shops = localStorage.getItem(STORAGE_KEYS.CHAT_SHOPS);

  const LIMIT = MILLISECONDS.DAY;

  const parsedShops: { shops: IChatShopsState; time: number } = parseObject(shops);

  if (isEmptyObject(parsedShops)) {
    return {};
  }
  if (Date.now() - LIMIT > parsedShops.time) {
    clearChatShops();

    return {};
  }

  return parsedShops.shops;
};

const getChatGroups = (): IChatGroupsState => {
  const groups = localStorage.getItem(STORAGE_KEYS.CHAT_GROUPS);

  const LIMIT = MILLISECONDS.DAY;

  const parsedGroups: { groups: IChatGroupsState; time: number } = parseObject(groups);

  if (isEmptyObject(parsedGroups)) {
    return {};
  }
  if (Date.now() - LIMIT > parsedGroups.time) {
    clearChatGroups();

    return {};
  }

  return parsedGroups.groups;
};

const saveChatShops = (shops: IChatShopsState) => {
  const currentShops = localStorage.getItem(STORAGE_KEYS.CHAT_SHOPS);

  const parsedShops: { shops: IChatShopsState; time: number } = parseObject(currentShops);

  const updatedShops = { shops, time: parsedShops.time || Date.now() };
  localStorage.setItem(STORAGE_KEYS.CHAT_SHOPS, stringify(updatedShops));
};

const saveChatGroup = (groups: IChatGroupsState) => {
  const currentGroups = localStorage.getItem(STORAGE_KEYS.CHAT_GROUPS);

  const parsedGroups: { groups: IChatGroupsState; time: number } = parseObject(currentGroups);

  const updatedGroups = { groups, time: parsedGroups.time || Date.now() };
  localStorage.setItem(STORAGE_KEYS.CHAT_GROUPS, stringify(updatedGroups));
};

// Redirect link
const setRedirectLink = (link: string) => sessionStorage.setItem(STORAGE_KEYS.REDIRECT_URL, link);

const getRedirectLink = () => sessionStorage.getItem(STORAGE_KEYS.REDIRECT_URL);

const removeRedirectLink = () => sessionStorage.removeItem(STORAGE_KEYS.REDIRECT_URL);

// payouts, triggers refresh payments after connect
const triggerPayoutsListener = (input: {
  status: ThirdPartyConnectionStatus;
  provider: PayoutMethods;
  orderId?: string;
  errorCode?: string;
}) => {
  localStorage.setItem(STORAGE_KEYS.PAYOUTS, stringify(input));
  localStorage.removeItem(STORAGE_KEYS.PAYOUTS);
};

// shipping, triggers refresh shipping after connect
const triggerShippingListener = (status: ThirdPartyConnectionStatus) => {
  localStorage.setItem(STORAGE_KEYS.SHIPPING, status);
  localStorage.removeItem(STORAGE_KEYS.SHIPPING);
};

const triggerPremiumSubscriptionListener = (status: ThirdPartyConnectionStatus) => {
  localStorage.setItem(STORAGE_KEYS.PREMIUM, status);
  localStorage.removeItem(STORAGE_KEYS.PREMIUM);
};

// Get the app page
const getShowTheAppDetails = () => {
  const details = localStorage.getItem(STORAGE_KEYS.SHOW_GET_APP);

  return parseObject(details);
};

const updateShowTheAppDate = () => {
  const date = new Date().getTime();

  const options = {
    date,
  };

  const value = JSON.stringify(options);
  localStorage.setItem(STORAGE_KEYS.SHOW_GET_APP, value);
};

const getPageStatus = () => {
  const details = parseObject(localStorage.getItem(STORAGE_KEYS.SHOW_GET_APP));

  const lastTime = details?.date || 0;

  const difference = Date.now() - lastTime;

  return !lastTime || difference >= MILLISECONDS.DAY;
};

const setLastLogin = (options: { date: number; count: number }) =>
  localStorage.setItem(STORAGE_KEYS.AMPLITUDE, stringify(options));

const resetLastLogin = () => {
  const options = {
    date: Date.now(),
    count: 0,
  };

  setLastLogin(options);
};

const shouldSendUserEvent = () => {
  const details = parseObject(localStorage.getItem(STORAGE_KEYS.AMPLITUDE)) || {};

  const now = Date.now();

  const { count = 0, date = now } = details;

  const difference = now - date;

  const isExpired = difference >= MILLISECONDS.DAY * 2;

  const isLoggedInYesterday = difference >= MILLISECONDS.DAY && !isExpired;

  const shouldSendEvent = isLoggedInYesterday && count === 2;

  if (isExpired || shouldSendEvent || !details.date) {
    resetLastLogin();
  } else if (isLoggedInYesterday) {
    const options = { date: now, count: count + 1 };
    setLastLogin(options);
  }

  return shouldSendEvent;
};

// draft
const getDraft = (userId: string): ICreateShopState | null => {
  const draft = localStorage.getItem(`${STORAGE_KEYS.CREATE_SHOP_DRAFT}_${userId}`);

  if (!draft) {
    return null;
  }

  return parseObject(draft);
};

const setDraft = (draft: ICreateShopState, userId: string) => {
  localStorage.setItem(`${STORAGE_KEYS.CREATE_SHOP_DRAFT}_${userId}`, stringify(draft));
};

const removeDraft = (userId: string) =>
  localStorage.removeItem(`${STORAGE_KEYS.CREATE_SHOP_DRAFT}_${userId}`);

const isFirstGenerate = () => !localStorage.getItem(STORAGE_KEYS.VISIONS);

const setFirstGenerate = () => localStorage.setItem(STORAGE_KEYS.VISIONS, 'true');

const token = {
  remove: removeToken,
  get: getToken,
  setInLocal: setTokenInLocal,
  setInSession: setTokenInSession,
};

const userId = {
  remove: removeUserId,
  get: getUserId,
  set: setUserId,
};

const payouts = {
  triggerPayoutsListener,
};

const shipping = {
  triggerShippingListener,
};

const premium = {
  triggerPremiumSubscriptionListener,
};

const redirectLink = {
  remove: removeRedirectLink,
  get: getRedirectLink,
  set: setRedirectLink,
};

const getTheAppPage = {
  get: getShowTheAppDetails,
  update: updateShowTheAppDate,
  getPageStatus,
};

const chat = {
  saveShops: saveChatShops,
  saveGroups: saveChatGroup,
  getShops: getChatShops,
  getGroups: getChatGroups,
};

const draft = {
  get: getDraft,
  set: setDraft,
  remove: removeDraft,
};

const visions = {
  isFirstGenerate,
  set: setFirstGenerate,
};

const amplitude = {
  shouldSendUserEvent,
};

export const StorageSrv = {
  getTheAppPage,
  token,
  redirectLink,
  chat,
  userId,
  amplitude,
  payouts,
  shipping,
  draft,
  premium,
  visions,
};
