import { ISpecificVisionRequestApi, OffersSortType } from 'types';
import { graphQlRequest } from 'api/graphQl';
import {
  DECLINE_OFFER_MUTATION,
  DELETE_REQUEST_MUTATION,
  GET_OFFERS_FOR_REQUEST_QUERY,
  GET_SELLER_PAYMENT_DETAILS_QUERY,
  GET_VISION_REQUEST_QUERY,
  WITHDRAW_OFFER_MUTATION,
  HIDE_VISION_REQUEST_MUTATION,
  GET_VISION_OFFER_QUERY,
} from './SpecificVisionRequestQueries';
import {
  OffersForRequestResponse,
  ShippingRatesForVisionOfferResponse,
  SuccessResponse,
  VisionOffer,
  VisionRequest,
} from 'graphql/generated/graphql';

export class SpecificVisionRequestApi implements ISpecificVisionRequestApi {
  async getOffersForRequest(
    token: string,
    requestId: string,
    paginationToken: string | null,
    sortType: OffersSortType,
  ): Promise<OffersForRequestResponse> {
    const variables = {
      requestId,
      paginationToken,
      sort: sortType,
    };

    const res = await graphQlRequest(token).query({
      query: GET_OFFERS_FOR_REQUEST_QUERY,
      variables,
    });

    return res.data.getOffersForRequest;
  }

  async getVisionRequest(token: string, requestId: string): Promise<VisionRequest> {
    const variables = {
      requestId,
    };

    const res = await graphQlRequest(token).query({
      query: GET_VISION_REQUEST_QUERY,
      variables,
    });

    return res.data.getVisionRequestById;
  }

  async declineOffer(token: string, offerId: string, reason: string): Promise<SuccessResponse> {
    const variables = {
      offerId,
      reason,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: DECLINE_OFFER_MUTATION,
      variables,
    });

    return res.data.declineVisionOffer;
  }

  async withdrawOffer(token: string, offerId: string): Promise<SuccessResponse> {
    const variables = {
      offerId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: WITHDRAW_OFFER_MUTATION,
      variables,
    });

    return res.data.withdrawVisionOffer;
  }

  async deleteRequest(token: string, requestId: string): Promise<SuccessResponse> {
    const variables = {
      requestId,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: DELETE_REQUEST_MUTATION,
      variables,
    });

    return res.data.deleteVisionRequest;
  }

  async hideVisionRequest(token: string, requestId: string): Promise<SuccessResponse> {
    const variables = {
      requestId,
    };

    const res = await graphQlRequest(token).query({
      query: HIDE_VISION_REQUEST_MUTATION,
      variables,
    });

    return res.data.hideVisionRequest;
  }

  async getSellerPaymentDetails(
    token: string,
    offerId: string,
  ): Promise<ShippingRatesForVisionOfferResponse> {
    const variables = { offerId };

    const res = await graphQlRequest(token).query({
      query: GET_SELLER_PAYMENT_DETAILS_QUERY,
      variables,
    });

    return res.data.getShippingRatesForVisionOffer;
  }

  async getVisionOffer(token: string, offerId: string): Promise<VisionOffer> {
    const variables = { offerId };

    const res = await graphQlRequest(token).query({
      query: GET_VISION_OFFER_QUERY,
      variables,
    });

    return res.data.getVisionOfferById;
  }
}
