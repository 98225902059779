import { Button, CircleButton, Tag } from 'tedooo-web-design-system/ui';
import './productContainer.scss';
import { FC } from 'react';
import { XIcon } from 'tedooo-web-design-system/icons';

import { Image } from 'components/helpers';
import TagImage from 'photos/tag.png';
import { toCurrency } from 'utils';
import { IProductContainer } from 'types';
import { translations } from 'translations/en';

const ProductContainer: FC<IProductContainer> = ({
  image,
  originalPrice,
  price,
  title,
  currencyCode,
  onClick,
  showBuyButton,
}) => {
  const onButtonClick = (e: any) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className="product-feed-container">
      {!showBuyButton && (
        <CircleButton type="secondary-gray" size="small" icon={<XIcon />} onClick={onButtonClick} />
      )}
      <div className="image-container">
        {price !== originalPrice && <Tag color="tedooo" size="medium" text="ON SALE!" />}
        <Image src={image} className="product-image" />
        <div className="price-container">
          <Image src={TagImage} className="tag-image" />
          <span className="price">{toCurrency(price || 0, currencyCode)}</span>
          {price !== originalPrice && (
            <>
              <div className="dot" />
              <span className="original-price">{toCurrency(originalPrice || 0, currencyCode)}</span>
            </>
          )}
        </div>
      </div>
      <div className="product-title">
        <span>{title}</span>
        {showBuyButton && (
          <Button
            type="secondary"
            size="medium"
            borderRadius="small"
            onClick={onButtonClick}
            text={translations.COMMON.VIEW_ITEM}
            className="buy-button"
          />
        )}
      </div>
    </div>
  );
};

export default ProductContainer;
