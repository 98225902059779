import { FooterOrder, mapFooterOptionToLabel, mapFooterOptionToRoute } from './helper';
import { Span } from 'components/helpers';
import './footer.scss';
import { getYear } from 'utils';

const Footer = () => {
  const year = getYear();

  const tedoooText = `@${year} Tedooo, inc.`;

  return (
    <div className="sign-footer">
      {FooterOrder.map((option, key) => (
        <a
          className="footer-option clickable"
          href={`/${mapFooterOptionToRoute[option]}`}
          key={key}
        >
          <Span text={mapFooterOptionToLabel[option]} key={key} className="" />
        </a>
      ))}
      <Span className="footer-option" text={tedoooText} />
    </div>
  );
};

export default Footer;
