export enum ImageOrientation {
  Horizontal,
  Vertical,
  Square,
}

// Values for api
export enum ImageComposition {
  zero = 0,
  one = 100,
  twoSquare = 200,
  twoVertical = 201,
  twoHorizontal = 202,
  threeVertical = 301,
  threeHorizontal = 302,
  fourSquares = 400,
  fourVertical = 401,
  fourHorizontal = 402,
  fiveSquares = 501,
  fiveHorizontal = 502,
}
