import { createSelector } from '@reduxjs/toolkit';

import { FeatureFlags, IStore2 } from 'types';

const featureFlagsSelector = (state: IStore2) => state.config.featureFlags;

const shouldShowVisionsSelector = createSelector(
  [featureFlagsSelector],
  (featureFlags): boolean => {
    return featureFlags.includes(FeatureFlags.Visions);
  },
);

export const ConfigSelector = {
  shouldShowVisionsSelector,
};
