import { FC, useEffect } from 'react';
import { Notification } from 'tedooo-web-design-system/ui';

import { useTedoooSelector } from 'store/Store';
import './linkMessage.scss';
import { MessageSelector } from 'store/message/MessageSelectors';

const LinkMessage: FC = () => {
  const link = useTedoooSelector(MessageSelector.linkSelector);

  useEffect(() => {
    if (link) {
      navigator.clipboard.writeText(link).then();
    }
  }, [link]);

  return (
    <Notification
      type="regular"
      text="Copied to clipboard!"
      placement="top"
      className="link-notification"
    />
  );
};

export default LinkMessage;
