import { FC } from 'react';
import { Skeleton } from 'tedooo-web-design-system/ui';
import './groupSkeleton.scss';

const GroupsSkeleton: FC = () => {
  return (
    <div className="skeleton_container">
      <Skeleton avatar={{ shape: 'circle', size: 48 }} />
      <Skeleton avatar={false} title={false} rows={2} />
      <Skeleton avatar={{ shape: 'circle', size: 34 }} title={{ width: 0 }} rows={0} />
    </div>
  );
};

export default GroupsSkeleton;
