import { gql } from '@apollo/client';

export const GET_SPECIFIC_PRODUCT_QUERY = gql`
  query GetSpecificProduct($productId: String!, $paginationToken: String) {
    getProductById(productId: $productId) {
      shopId
      item {
        id
        hasInstantCheckout
        didLike
        totalLikes
        imageUrl
        tariff
        quantity
        title
        description
        selectedCategory
        variants
        userId
        originalPrice
        isDigital
        price {
          price
          currencyCode
          currency
        }
        labels {
          texts {
            text
          }
          backgroundColor
          textColor
        }
        vacationInfo {
          text
          date
        }
        shippingInformation {
          profileId
          originCountry
          countries
          dimensions {
            metric
            length
            width
            height
            weightBig
            weightSmall
          }
          workingDays {
            min
            max
          }
          returnPolicy {
            policy
            title
            description
          }
        }
        convertedFrom {
          price
          currencyCode
          currency
        }
      }
    }
    getProductBanner(productId: $productId) {
      banner {
        id
        backgroundColor
        rightImage
        borderWidth
        borderColor
        gradient {
          angle
          colors {
            color
            startPercent
          }
        }
        title {
          text
          color
          font
          hasArrow
        }
        description {
          text
          color
          font
          hasArrow
        }
      }
    }
    getDiscoverPageData(
      listId: PRODUCT_ITEM
      screenHeight: 0
      screenWidth: 0
      paginationToken: $paginationToken
    ) {
      horizontalListsProducts {
        id
        boxHeight
        boxWidth
        rows
        sectionDetails {
          order
        }
        title {
          text
          color
          font
          hasArrow
        }
        listInfo {
          listId
          title
          isFilterable
          initialPaginationToken
          screen
        }
        products {
          id
          imageUrl
          didLike
          totalLikes
        }
      }
    }
  }
`;

export const LIKE_PRODUCT_QUERY = gql`
  mutation ($productId: TedoooID!, $like: Boolean!) {
    setLikeProduct(productId: $productId, like: $like) {
      success
    }
  }
`;
