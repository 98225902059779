import { ChatSearchOptions, IChatOption, IMessageItem, MessagesTypes } from 'types';
import { translations } from 'translations/en';

export const ChatSearchTabs = [
  {
    key: ChatSearchOptions.All,
    label: translations.COMMON.ALL,
  },
  {
    key: ChatSearchOptions.Users,
    label: translations.CHAT.USERS,
  },
  {
    key: ChatSearchOptions.Messages,
    label: translations.CHAT.MESSAGES,
  },
  {
    key: ChatSearchOptions.Groups,
    label: translations.CHAT.GROUPS,
  },
];

export const parseSidebarMessages = (
  chats: IChatOption[],
  selectedChatId: string | null,
  loggedInUsername: string | null,
  isFilterActive: boolean = false,
): IMessageItem[] => {
  const searchedList = isFilterActive ? chats.filter((chat) => chat.totalUnread > 0) : chats;

  return searchedList.map((item) => {
    const shouldDisplaySenderName =
      item.type !== MessagesTypes.System && !!item.senderName && !item.typingName;
    let text = item.text.replaceAll(loggedInUsername || '', 'You');

    const ownerName = item.isOwner ? translations.COMMON.YOU : item.senderName;

    if (item.typingName) {
      text = `${item.typingName} ${translations.CHAT.IS_TYPING}`;
    }

    return {
      shouldDisplaySenderName,
      type: item.type,
      id: item.id,
      isSuperAdmin: item.isSuperAdmin,
      text,
      name: item.name,
      isGroup: item.isGroup,
      username: item.isGroup ? '' : item.username || '',
      isMuted: item.isMuted,
      ownerName,
      status: item.status,
      totalUnreadCount: item.totalUnread,
      isOwner: item.isOwner,
      avatar: item.avatar,
      date: item.lastMessageDate,
      isSelected: item.id === selectedChatId,
    };
  });
};
