import { gql } from '@apollo/client';

export const GET_ONE_TIME_PAYMENT_INFORMATION_FROM_QUERY = gql`
  query GetOneTimePaymentLinkInformation($id: String!) {
    getOneTimePaymentLinkInformation(id: $id) {
      avatar
      username
      userId
      stripePaymentOption {
        id
        image
      }
      isPaypalConnected
      email
      currencyCode
      currencySymbol
    }
  }
`;
