import { useNavigate } from 'react-router-dom';
import { Button } from 'tedooo-web-design-system/ui';

import './visionsSection.scss';
import HeaderImage from 'photos/visions/visions-feed-sidebar.png';
import { translations } from 'translations/en';
import { ROUTES } from 'constant';
import { Image } from 'components/helpers';

const VisionsSection = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/${ROUTES.VISIONS}`);
  };

  return (
    <div className="feed-vision-section">
      <Image src={HeaderImage} />
      <div className="text-container">
        <span className="title">{translations.VISIONS.FEED_AD.TITLE}</span>
        <span className="description">{translations.VISIONS.FEED_AD.DESCRIPTION}</span>
        <Button
          type="primary"
          size="medium"
          borderRadius="large"
          text={translations.VISIONS.FEED_AD.BUTTON_TEXT}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default VisionsSection;
