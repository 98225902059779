// Routes
import { BellIcon, Clipboard, FilmIcon, Grid, Home, Message } from 'tedooo-web-design-system/icons';

import { MenuButtonOptions } from 'types';
import { ROUTES } from 'constant';
import { translations } from 'translations/en';

export const mapMenuOptionToRoute: { [key in MenuButtonOptions]: string } = {
  [MenuButtonOptions.Home]: ROUTES.HOME,
  [MenuButtonOptions.Messaging]: ROUTES.CHAT,
  [MenuButtonOptions.Discover]: ROUTES.DISCOVER,
  [MenuButtonOptions.Orders]: ROUTES.ORDERS,
  [MenuButtonOptions.Mojos]: ROUTES.MOJOS,
  [MenuButtonOptions.Notification]: '',
};

export const mapMenuOptionToIcon: { [key in MenuButtonOptions]: any } = {
  [MenuButtonOptions.Messaging]: Message,
  [MenuButtonOptions.Home]: Home,
  [MenuButtonOptions.Orders]: Clipboard,
  [MenuButtonOptions.Mojos]: FilmIcon,
  [MenuButtonOptions.Discover]: Grid,
  [MenuButtonOptions.Notification]: BellIcon,
};

// Orders
export const menuOptionsOrder = [
  MenuButtonOptions.Home,
  MenuButtonOptions.Discover,
  MenuButtonOptions.Messaging,
  MenuButtonOptions.Mojos,
  MenuButtonOptions.Orders,
];

export const mobileMenuOptionsOrder = [
  MenuButtonOptions.Home,
  MenuButtonOptions.Discover,
  MenuButtonOptions.Messaging,
  MenuButtonOptions.Mojos,
  MenuButtonOptions.Orders,
];

export const nonSignInUsersSupportedRoutes = [MenuButtonOptions.Home, MenuButtonOptions.Discover];

export const mapMenuOptionToText: { [key in MenuButtonOptions]: string } = {
  [MenuButtonOptions.Home]: translations.PAGES.HOME,
  [MenuButtonOptions.Messaging]: translations.PAGES.MESSAGING,
  [MenuButtonOptions.Discover]: translations.PAGES.DISCOVER,
  [MenuButtonOptions.Orders]: translations.PAGES.OFFERS,
  [MenuButtonOptions.Notification]: translations.PAGES.NOTIFICATIONS,
  [MenuButtonOptions.Mojos]: translations.PAGES.MOJOS,
};
