import { gql } from '@apollo/client';

export const DECODE_PRODUCTS_CSV = gql`
  query DecodeProductCsv($csv: String!, $currencyCode: String!) {
    decodeProductCsv(csvData: $csv, currencyCode: $currencyCode) {
      requestId
      products {
        title
        description
        quantity
        tags
        images
        price {
          price
          currencyCode
          currency
        }
      }
    }
  }
`;

export const GET_PRODUCT_CSV_PROGRESS = gql`
  query GetProductCsvProgress($requestId: String!) {
    getProductCsvProgress(requestId: $requestId) {
      successUrls
      progress
    }
  }
`;
