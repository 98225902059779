import './chatFallback.scss';
import { Skeleton } from 'tedooo-web-design-system/ui';

import { createArray } from 'utils';

const ChatFallback = () => {
  const items = createArray(7);

  return (
    <div className="chat-fallback-container page">
      <div className="left-section">
        <Skeleton className="left-header-skeleton" title rows={1} />
        {items.map((_, index) => (
          <Skeleton
            key={index}
            className="skeleton-sidebar-content"
            title
            avatar={{ size: 48 }}
            rows={1}
          />
        ))}
      </div>
      <div className="right-section">
        <Skeleton avatar={{ shape: 'circle' }} title={false} className="circle-skeleton" />
        <div className="text-skeleton-container">
          <Skeleton className="title-skeleton" title />
          <Skeleton className="description-skeleton" title />
        </div>
      </div>
    </div>
  );
};

export default ChatFallback;
