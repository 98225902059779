import {
  IShippingProfileForm,
  ReturnPolicyType,
  PayoutMethods,
  IShippingDestination,
  ShippingPriceOptions,
  IShippingProfile,
  IProductDimensions,
  IShippingServiceGroup,
  IAddressPreview,
} from 'types';
import { Countries } from 'constant/countries';

export enum WeightDimensions {
  Kg = 'kg',
  Lb = 'lb',
  Oz = 'oz',
  Gram = 'g',
}

export enum LengthDimensions {
  Cm = 'cm',
  In = 'in',
}

export enum ProcessingTimeOptions {
  ThreeDays = '1-3 business days',
  FiveDays = '3-5 business days',
  SevenDays = '5-7 business days',
  Custom = 'Custom range',
}

export enum FixedPriceOptions {
  FreeShipping = 'Free shipping',
  Fixed = 'Fixed price',
}

export interface IListingCategoryPopup {
  categories: string[][];
  defaultCategory: string[];
  hidePopup(): void;
  getCategories(): void;
  saveCategory(input: { category: string[] }): void;
}

export interface ICreateShippingProfilePopup {
  availableShippingServices: IShippingServiceGroup[];
  shippingProfileForm: IShippingProfileForm;
  isAllCountriesSelected: boolean;
  disabledCountries: Countries[][];
  isLoading: boolean;
  updateName(input: { name: string }): void;
  updateAddress(input: { address: string }): void;
  updatePostalCode(input: { postalCode: string }): void;
  updateCity(input: { city: string }): void;
  onOriginCountryChange(input: { country: Countries }): void;
  onShippingPriceOptionChange(input: { value: string }): void;
  validateAddress(input: IAddressPreview): void;
  onAddForm(): void;
  getServices(input: { shippingCountry: Countries; isCalculated: boolean }): void;
  onProcessingTimeOptionChange(input: { value: ProcessingTimeOptions }): void;
  onMinProcessingTimeChange(input: { value: number }): void;
  onMaxProcessingTimeChange(input: { value: number }): void;
  hidePopup(): void;
}

export interface IProcessingTimeInput {
  dropdownValue: ProcessingTimeOptions;
  minValue: number;
  maxValue: number;
  id: string;
  label: string;
  onDropdownChange(value: ProcessingTimeOptions): void;
  onMinimumChange(value: number): void;
  onMaximumChange(value: number): void;
}

export interface IShippingDestinationForm {
  shippingDestination: IShippingDestination;
  shippingType: ShippingPriceOptions;
  index: number;
  availableShippingServices: IShippingServiceGroup[];
  disabledCountries: Countries[];
  isWorldwide: boolean;
  onRemoveForm(input: { index: number }): void;
  onCountryToggle(input: { country: Countries; isChecked: boolean; index: number }): void;
  onRegionToggle(input: { countries: Countries[]; isChecked: boolean; index: number }): void;
  onAllCountriesToggle(input: { isChecked: boolean; index: number }): void;
  onShippingServiceSelect(input: {
    selectedService: string;
    index: number;
    isSelected: boolean;
  }): void;
  onShippingGroupToggle(input: { services: string[]; index: number; isChecked: boolean }): void;
  onAllServicesToggle(input: { index: number; isChecked: boolean }): void;
  onDeliveryTimeChange(input: { value: ProcessingTimeOptions; index: number }): void;
  onFreeDomesticToggle(input: { index: number }): void;
  onFreeInternationalToggle(input: { index: number }): void;
  onFixedPriceChange(input: { value: FixedPriceOptions; index: number }): void;
  onOneItemPriceChange(input: { price: number; index: number }): void;
  onAdditionalItemPriceChange(input: { price: number; index: number }): void;
  onMaximumDeliveryTimeChange(input: { max: number; index: number }): void;
  onMinimumDeliveryTimeChange(input: { min: number; index: number }): void;
}

export interface ISelectShippingProfilePopup {
  shippingProfiles: IShippingProfile[];
  shippingProfileId: string | null;
  mapShippingProfileIdToAffectedListings: { [key: string]: number };
  shouldShowDropdown: boolean;
  onSaveSelection(input: { id: string }): void;
  editShippingProfile(input: { id: string }): void;
  duplicateShippingProfile(input: { id: string }): void;
  showPopup(input: {
    id: string;
    shippingProfileId?: string;
    isDuplicate?: boolean;
    source?: string;
  }): void;
  hidePopup(): void;
}

export interface IDimensionsContainer {
  shouldDisableDimensionsFields: boolean;
  dimensions: IProductDimensions;
  isRequired: boolean;
  onWidthChange(width: number): void;
  onLengthChange(length: number): void;
  onHeightChange(height: number): void;
  onWeightBigChange(weight: number): void;
  onWeightSmallChange(decimalWeight: number): void;
}

export interface IReturnsPopup {
  returnPolicy: ReturnPolicyType | null;
  hidePopup(): void;
  updatePolicy(input: { returnPolicy: ReturnPolicyType }): void;
}

export interface IConfirmShippingAddressPopup {
  newAddress: IAddressPreview;
  address: IAddressPreview;
  onConfirm(): void;
  hidePopup(): void;
}

export interface IValidateAddressPopup {
  onConfirm(): void;
  hidePopup(): void;
}

export interface IEditWillAffectListingsPopup {
  totalListings: number;
  shippingProfileId: string;
  source: string;
  hidePopup(): void;
  showPopup(input: { id: string; shippingProfileId: string; source: string }): void;
}

export interface IConnectToShippoPopup {
  shippoUrl: string | null;
  hide(): void;
  onCancel(): void;
}

export interface IOnePayoutPopup {
  payoutMethod: PayoutMethods;
  linkPayoutAccount(input: { payout: PayoutMethods }): void;
  hide(): void;
  onCancel(): void;
}

export interface IDeactivatePayoutPopup {
  payoutType: PayoutMethods;
  hidePopup(): void;
  onDeactivate(input: { payout: PayoutMethods }): void;
}

export interface IPayoutIntroPopup {
  hide(): void;
  onConnect(): void;
}
