import VenmoIcon from 'photos/paymentMethods/Venmo.png';
import PayPalIcon from 'photos/paymentMethods/PayPal.png';
import SquareIcon from 'photos/paymentMethods/Square.png';
import CashAppIcon from 'photos/paymentMethods/CashApp.png';
import AmazonPayIcon from 'photos/paymentMethods/AmazonPay.png';
import BankTransferIcon from 'photos/paymentMethods/BankTransfer.png';
import WiseIcon from 'photos/paymentMethods/Wise.png';
import SofortIcon from 'photos/paymentMethods/Sofort.png';
import RevolutIcon from 'photos/paymentMethods/Revolut.png';
import BpayIcon from 'photos/paymentMethods/Bpay.png';
import BoliIcon from 'photos/paymentMethods/POLi.png';
import InteracIcon from 'photos/paymentMethods/Interac.png';
import SepaIcon from 'photos/paymentMethods/Sepa.png';
import FasterPaymentsIcon from 'photos/paymentMethods/FasterPayments.png';
import AdyenIcon from 'photos/paymentMethods/Adyen.png';
import SkrillIcon from 'photos/paymentMethods/Skrill.png';
import ZelleIcon from 'photos/paymentMethods/Zelle.png';
import PayDirektIcon from 'photos/paymentMethods/PayDirekt.png';
import BanContactIcon from 'photos/paymentMethods/BanContact.png';
import NettelerIcon from 'photos/paymentMethods/Netteler.png';
import { Countries } from './countries';

export const PAYPAL_SUPPORTED_COUNTRIES = [
  Countries.Algeria,
  Countries.Angola,
  Countries.Benin,
  Countries.Botswana,
  Countries.BurkinaFaso,
  Countries.Burundi,
  Countries.Cameroon,
  Countries.CapeVerde,
  Countries.Chad,
  Countries.Comoros,
  Countries.CoteDIvoire,
  Countries.DemocraticRepublicOfTheCongo,
  Countries.Djibouti,
  Countries.Egypt,
  Countries.Eritrea,
  Countries.Ethiopia,
  Countries.Gabon,
  Countries.Gambia,
  Countries.Guinea,
  Countries.GuineaBissau,
  Countries.Kenya,
  Countries.Lesotho,
  Countries.Madagascar,
  Countries.Malawi,
  Countries.Mali,
  Countries.Mauritania,
  Countries.Mauritius,
  Countries.Mayotte,
  Countries.Morocco,
  Countries.Mozambique,
  Countries.Namibia,
  Countries.Niger,
  Countries.Nigeria,
  Countries.Congo,
  Countries.Rwanda,
  Countries.SaintHelena,
  Countries.SaoTomeAndPrincipe,
  Countries.Senegal,
  Countries.Seychelles,
  Countries.SierraLeone,
  Countries.Somalia,
  Countries.SouthAfrica,
  Countries.Swaziland,
  Countries.Tanzania,
  Countries.Togo,
  Countries.Tunisia,
  Countries.Uganda,
  Countries.Zambia,
  Countries.Zimbabwe,
  Countries.Anguilla,
  Countries.AntiguaAndBarbuda,
  Countries.Argentina,
  Countries.Aruba,
  Countries.Bahamas,
  Countries.Barbados,
  Countries.Belize,
  Countries.Bermuda,
  Countries.Bolivia,
  Countries.Brazil,
  Countries.VirginIslandsUK,
  Countries.Canada,
  Countries.CaymanIslands,
  Countries.Chile,
  Countries.Colombia,
  Countries.CostaRica,
  Countries.Dominica,
  Countries.DominicanRepublic,
  Countries.Ecuador,
  Countries.ElSalvador,
  Countries.FalklandIslands,
  Countries.FrenchGuiana,
  Countries.Greenland,
  Countries.Grenada,
  Countries.Guadeloupe,
  Countries.Guatemala,
  Countries.Guyana,
  Countries.Honduras,
  Countries.Jamaica,
  Countries.Martinique,
  Countries.Mexico,
  Countries.Montserrat,
  Countries.Netherlands,
  Countries.Nicaragua,
  Countries.Panama,
  Countries.Paraguay,
  Countries.Peru,
  Countries.SaintKittsAndNevis,
  Countries.SaintLucia,
  Countries.SaintPierreAndMiquelon,
  Countries.SaintVincentAndTheGrenadines,
  Countries.Suriname,
  Countries.TrinidadAndTobago,
  Countries.TurksAndCaicosIslands,
  Countries.UnitedStates,
  Countries.Uruguay,
  Countries.Venezuela,
  Countries.Armenia,
  Countries.Australia,
  Countries.Bahrain,
  Countries.Bhutan,
  Countries.Brunei,
  Countries.Cambodia,
  Countries.China,
  Countries.CookIslands,
  Countries.Fiji,
  Countries.FrenchPolynesia,
  Countries.HongKong,
  Countries.China,
  Countries.India,
  Countries.Indonesia,
  Countries.Israel,
  Countries.Japan,
  Countries.Jordan,
  Countries.Kazakhstan,
  Countries.Kiribati,
  Countries.Kuwait,
  Countries.Kyrgyzstan,
  Countries.Laos,
  Countries.Malaysia,
  Countries.Maldives,
  Countries.MarshallIslands,
  Countries.Micronesia,
  Countries.Mongolia,
  Countries.Nauru,
  Countries.Nepal,
  Countries.NewCaledonia,
  Countries.NewZealand,
  Countries.Niue,
  Countries.NorfolkIsland,
  Countries.Oman,
  Countries.Palau,
  Countries.PapuaNewGuinea,
  Countries.Philippines,
  Countries.Pitcairn,
  Countries.Qatar,
  Countries.Samoa,
  Countries.SaudiArabia,
  Countries.Singapore,
  Countries.SolomonIslands,
  Countries.SouthKorea,
  Countries.SriLanka,
  Countries.Taiwan,
  Countries.Tajikistan,
  Countries.Thailand,
  Countries.Tonga,
  Countries.Turkmenistan,
  Countries.Tuvalu,
  Countries.UnitedArabEmirates,
  Countries.Vanuatu,
  Countries.Vietnam,
  Countries.WallisAndFutuna,
  Countries.Yemen,
  Countries.Albania,
  Countries.Andorra,
  Countries.Austria,
  Countries.Azerbaijan,
  Countries.Belarus,
  Countries.Belgium,
  Countries.BosniaAndHerzegovina,
  Countries.Bulgaria,
  Countries.Croatia,
  Countries.Cyprus,
  Countries.CzechRepublic,
  Countries.Denmark,
  Countries.Estonia,
  Countries.FaroeIslands,
  Countries.Finland,
  Countries.France,
  Countries.Georgia,
  Countries.Germany,
  Countries.Greece,
  Countries.Hungary,
  Countries.Iceland,
  Countries.Ireland,
  Countries.Italy,
  Countries.Latvia,
  Countries.Liechtenstein,
  Countries.Lithuania,
  Countries.Luxembourg,
  Countries.NorthMacedonia,
  Countries.Malta,
  Countries.Moldova,
  Countries.Monaco,
  Countries.Montenegro,
  Countries.Netherlands,
  Countries.Norway,
  Countries.Poland,
  Countries.Portugal,
  Countries.Romania,
  Countries.Russia,
  Countries.SanMarino,
  Countries.Serbia,
  Countries.Slovakia,
  Countries.Slovenia,
  Countries.Spain,
  Countries.SvalbardAndJanMayen,
  Countries.Sweden,
  Countries.Switzerland,
  Countries.Ukraine,
  Countries.UnitedKingdom,
  Countries.VaticanCity,
];

export const STRIPE_SUPPORTED_COUNTRIES = [
  Countries.Australia,
  Countries.Austria,
  Countries.Belgium,
  Countries.Brazil,
  Countries.Bulgaria,
  Countries.Canada,
  Countries.Croatia,
  Countries.Cyprus,
  Countries.CzechRepublic,
  Countries.Denmark,
  Countries.Estonia,
  Countries.Finland,
  Countries.France,
  Countries.Germany,
  Countries.Gibraltar,
  Countries.Greece,
  Countries.HongKong,
  Countries.Hungary,
  Countries.India,
  Countries.Indonesia,
  Countries.Ireland,
  Countries.Italy,
  Countries.Japan,
  Countries.Latvia,
  Countries.Liechtenstein,
  Countries.Lithuania,
  Countries.Luxembourg,
  Countries.Malaysia,
  Countries.Malta,
  Countries.Mexico,
  Countries.Netherlands,
  Countries.NewZealand,
  Countries.Norway,
  Countries.Poland,
  Countries.Portugal,
  Countries.Romania,
  Countries.Singapore,
  Countries.Slovakia,
  Countries.Slovenia,
  Countries.Spain,
  Countries.Sweden,
  Countries.Switzerland,
  Countries.Thailand,
  Countries.UnitedArabEmirates,
  Countries.UnitedKingdom,
  Countries.UnitedStates,
];

export enum LegacyPaymentMethods {
  Paypal = 'PayPal',
  Square = 'Square',
  CashApp = 'CashApp',
  AmazonPay = 'Amazon Pay',
  BankTransfer = 'bank transfer',
  Venmo = 'Venmo',
  Wise = 'Wise',
  Sofort = 'Sofort',
  Revolut = 'Revolut',
  Bpay = 'BPAY',
  Poli = 'POLi',
  Interac = 'Interac',
  Sepa = 'SEPA (Single Euro Payments Area)',
  FasterPayments = 'Faster Payments',
  Adyen = 'Adyen',
  Skrill = 'Skrill',
  Zelle = 'Zelle',
  PayDirekt = 'Paydirekt',
  BanContact = 'Bancontact',
  Netteler = 'Netteler',
}

export const mapPaymentMethodToImage = {
  [LegacyPaymentMethods.Paypal]: PayPalIcon,
  [LegacyPaymentMethods.Square]: SquareIcon,
  [LegacyPaymentMethods.CashApp]: CashAppIcon,
  [LegacyPaymentMethods.AmazonPay]: AmazonPayIcon,
  [LegacyPaymentMethods.BankTransfer]: BankTransferIcon,
  [LegacyPaymentMethods.Venmo]: VenmoIcon,
  [LegacyPaymentMethods.Wise]: WiseIcon,
  [LegacyPaymentMethods.Sofort]: SofortIcon,
  [LegacyPaymentMethods.Revolut]: RevolutIcon,
  [LegacyPaymentMethods.Bpay]: BpayIcon,
  [LegacyPaymentMethods.Poli]: BoliIcon,
  [LegacyPaymentMethods.Interac]: InteracIcon,
  [LegacyPaymentMethods.Sepa]: SepaIcon,
  [LegacyPaymentMethods.FasterPayments]: FasterPaymentsIcon,
  [LegacyPaymentMethods.Adyen]: AdyenIcon,
  [LegacyPaymentMethods.Skrill]: SkrillIcon,
  [LegacyPaymentMethods.Zelle]: ZelleIcon,
  [LegacyPaymentMethods.PayDirekt]: PayDirektIcon,
  [LegacyPaymentMethods.BanContact]: BanContactIcon,
  [LegacyPaymentMethods.Netteler]: NettelerIcon,
};
