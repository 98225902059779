import { FC } from 'react';
import './numberBubble.scss';
import { Span } from 'tedooo-web-design-system/ui';

import { toShortFormat } from 'utils';

interface INumberBubble {
  value: number;
}

const NumberBubble: FC<INumberBubble> = ({ value }) => {
  const displayedCount = toShortFormat(value);

  return (
    <div className="unread-container">
      <Span text={displayedCount} />
    </div>
  );
};

export default NumberBubble;
