import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2, LikeProductSource, DiscoverListsId } from 'types';
import { SpecificProductApi } from './SpecificProductApi';
import { ShopReducer } from '../shop/ShopReducer';

const api = new SpecificProductApi();

const shopApi = new ShopReducer();

export const getSpecificProduct = createAsyncThunk(
  'async/getSpecificProduct',
  async (input: { productId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { productId } = input;

    return api.getSpecificProduct(productId, token);
  },
);

export const getProductEstimatedDelivery = createAsyncThunk(
  'async/getProductEstimatedDelivery',
  async (input: { productId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { productId } = input;

    return api.getProductEstimatedDelivery(productId, token);
  },
);

export const onProductLikeToggle = createAsyncThunk(
  'async/onProductLikeToggle',
  async (
    input: {
      productId: string;
      isLiked: boolean;
      source: LikeProductSource;
      listId?: DiscoverListsId;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { productId, isLiked } = input;

    return api.toggleLikeProduct(productId, isLiked, token);
  },
);

export const getShopDetails = createAsyncThunk(
  'async/getShopDetails',
  async (
    input: {
      id: string;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { id } = input;

    return shopApi.getShop(id, token);
  },
);
