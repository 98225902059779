import { useLocation } from 'react-router-dom';

import { StorageSrv } from 'services/StorageSrv';

export const useSaveLocation = (callback: () => any) => {
  const location = useLocation();

  return () => {
    StorageSrv.redirectLink.set(location.pathname);
    callback();
  };
};
