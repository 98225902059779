import { createAsyncThunk } from '@reduxjs/toolkit';

import { IStore2 } from 'types';
import { NotificationApi } from './NotificationApi';
import { isEmptyArray } from 'utils';

const reducer = new NotificationApi();

export const getNotifications = createAsyncThunk(
  'async/getNotifications',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { allNotifications } = state.notifications;

    let maxId: string | null = null;
    if (allNotifications && !isEmptyArray(allNotifications.viewedNotifications)) {
      maxId =
        allNotifications.viewedNotifications[allNotifications.viewedNotifications.length - 1].id;
    }

    return reducer.getNotifications(maxId, token || '');
  },
);

export const getOrderNotifications = createAsyncThunk(
  'async/getOrderNotifications',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { orderNotifications } = state.notifications;

    let maxId = '';
    if (orderNotifications && !isEmptyArray(orderNotifications.viewedNotifications)) {
      maxId =
        orderNotifications.viewedNotifications[orderNotifications.viewedNotifications.length - 1]
          .id;
    }

    return reducer.getOrderNotifications(maxId, token || '');
  },
);
