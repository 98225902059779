import { fetcher } from 'api';
import {
  ICommentItemResponseApi,
  ICommentsResponseApi,
  ICreatePostBody,
  ICreatePostResponse,
  IGetRepostsResponse,
  ILikesResponseApi,
  ILinkResponseApi,
  IPostApi,
  ISubPostResponseApi,
  Method,
} from 'types';

export class PostApi implements IPostApi {
  onPostLikeToggle(postId: string, isLiked: boolean, token: string): Promise<void> {
    const method = isLiked ? Method.DELETE : Method.POST;

    return fetcher(`posts/like/${postId}`, method, token, undefined);
  }

  onLikeComment(commentId: string, isLiked: boolean, token: string): Promise<void> {
    const method = isLiked ? Method.POST : Method.DELETE;

    return fetcher(`v2/posts/like/comment/${commentId}`, method, token, undefined);
  }

  onPostHide(postId: string, token?: string): Promise<void> {
    return fetcher(`posts/hide/${postId}`, Method.POST, token);
  }

  onPostReport(postId: string, token?: string): Promise<void> {
    return fetcher(`posts/report/${postId}`, Method.POST, token);
  }

  getComments(
    postId: string,
    limit: number,
    maxTime?: number,
    token?: string,
  ): Promise<ICommentsResponseApi> {
    const query: any = { limit };

    if (maxTime) {
      query.maxTime = maxTime;
    }

    return fetcher(`posts/v2/comments/${postId}`, Method.GET, token, undefined, query);
  }

  getReplies(
    postId: string,
    commentId: string,
    token?: string,
  ): Promise<Array<ICommentItemResponseApi>> {
    return fetcher(`posts/replies/${postId}/${commentId}`, Method.GET, token);
  }

  onAddComment(
    postId: string,
    text: string,
    tags: string[],
    token: string,
    image?: string,
  ): Promise<ICommentItemResponseApi> {
    const body: any = {
      text,
      tags,
      image,
    };

    return fetcher(`posts/v2/comment/${postId}`, Method.POST, token, body);
  }

  onAddReply(
    postId: string,
    parentCommentId: string,
    text: string,
    tags: string[],
    token: string,
    image?: string,
  ): Promise<ICommentItemResponseApi> {
    const body = {
      text,
      tags,
      parentCommentId,
      image,
    };

    return fetcher(`posts/v2/comment/${postId}`, Method.POST, token, body);
  }

  getLikes(
    postId: string,
    token: string,
    limit: number,
    maxId?: string,
  ): Promise<ILikesResponseApi> {
    const query = {
      maxId,
      limit,
    };

    return fetcher(`posts/like/${postId}`, Method.GET, token, undefined, query);
  }

  getCommentLikes(
    commentId: string,
    token: string,
    limit: number,
    maxId?: string,
  ): Promise<ILikesResponseApi> {
    const query = {
      maxId,
      limit,
    };

    return fetcher(`posts/v2/comment/like/${commentId}`, Method.GET, token, undefined, query);
  }

  getPostChildren(postId: string, token?: string): Promise<Array<ISubPostResponseApi>> {
    return fetcher(`posts/post/${postId}`, Method.GET, token);
  }

  deleteComment(commentId: string, token: string): Promise<void> {
    return fetcher(`posts/comment/${commentId}`, Method.DELETE, token);
  }

  onPostDelete(postId: string, token?: string): Promise<void> {
    return fetcher(`posts/post/${postId}`, Method.DELETE, token);
  }

  getPostLink(postId: string, token?: string): Promise<ILinkResponseApi> {
    return fetcher(`posts/link/${postId}`, Method.GET, token);
  }

  toggleRepost(postId: string, isRepost: boolean, token?: string): Promise<{ id: string }> {
    const method = isRepost ? Method.POST : Method.DELETE;

    return fetcher(`posts/reposts/${postId}`, method, token);
  }

  getReposts(postId: string, minId?: string, token?: string): Promise<IGetRepostsResponse> {
    const query = { minId };

    return fetcher(`posts/reposts/${postId}`, Method.GET, token, undefined, query);
  }

  hidePostFromActivity(postId: string, token?: string): Promise<void> {
    return fetcher(`posts/mentions/activity/${postId}`, Method.DELETE, token);
  }

  createUserPost(
    shopId: string | null,
    body: ICreatePostBody,
    token?: string,
  ): Promise<ICreatePostResponse> {
    let query;
    if (shopId) {
      query = { shopId };
    }

    return fetcher(`posts/v2/create`, Method.POST, token, body, query);
  }

  editUserPost(
    postId: string,
    shopId: string | null,
    body: ICreatePostBody,
    token?: string,
  ): Promise<ICreatePostResponse> {
    let query;
    if (shopId) {
      query = { shopId };
    }

    return fetcher(`posts/v2/edit/${postId}`, Method.PATCH, token, body, query);
  }
}
