import { Notification, Span } from 'tedooo-web-design-system/ui';
import { useNavigate } from 'react-router-dom';
import { HeartCircleIcon } from 'tedooo-web-design-system/icons';
import './toggleFavoritesMessage.scss';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { DiscoverListsId, ServerStatus } from 'types';
import { buildDiscoverPageQuery } from 'utils';
import { translations } from 'translations/en';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { ROUTES } from 'constant';

const { ADDED_FAVORITE_MESSAGE, REMOVED_FAVORITES_MESSAGE } = translations.SPECIFIC_PRODUCT;

const RepostMessage = () => {
  const navigate = useNavigate();

  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  const isLiked = useTedoooSelector(MessageSelector.isLikedSelector);

  const onViewPostClick = () => {
    const query = buildDiscoverPageQuery({
      id: DiscoverListsId.Favorites,
      title: translations.PRODUCTS_PAGE.FAVORITES_TITLE,
      paginationToken: null,
    });
    AnalyticsEvents.onEnterFavoritesPage('toast');
    navigate(`/${ROUTES.PRODUCTS}/${query}`);
  };

  const text = isLiked ? ADDED_FAVORITE_MESSAGE : REMOVED_FAVORITES_MESSAGE;

  const button = isLiked ? <Span text="View" onClick={onViewPostClick} /> : null;

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text={text}
        placement="top"
        description={button}
        icon={<HeartCircleIcon />}
        className="toggle-favorite-message"
      />
    );
  }

  return null;
};

export default RepostMessage;
