import { gql } from '@apollo/client';

export const UNEXPIRE_MESSAGE_MUTATION = gql`
  mutation unexpireMessage($id: String!) {
    unexpireMessage(id: $id) {
      success
    }
  }
`;

export const REPORT_MESSAGE_MUTATION = gql`
  mutation ReportMessage($messageId: String!, $option: String!, $extra: String) {
    reportMessage(messageId: $messageId, option: $option, extra: $extra) {
      success
    }
  }
`;
