import { FC } from 'react';
import { connect } from 'react-redux';

import Post from 'components/post/Post';
import {
  IPostWrapper,
  ITaggedUser,
  NonSignInPopupSources,
  popupIdsOptions,
  UserDetailsLocations,
} from 'types';
import {
  addComment,
  addReply,
  getComments as onGetComments,
  getReplies as getCommentReplies,
  onHidePostFromActivity as hidePostFromActivity,
  onLikeComment as onLikeCommentAction,
  onLikePostToggle as likePost,
  onPostHide as hidePost,
} from 'store/feed/PostActions';
import { PopupSlicer } from 'store/popup/PopupActions';
import { onFollowUserToggle } from 'store/profile/ProfileActions';
import { FeedSlicer } from 'store/feed/FeedSlicer';
import { useRequireToken } from 'hooks/useRequireToken';

const MAX_CHARS = 300;

const PostWrapper: FC<IPostWrapper> = ({
  index,
  post,
  getComments,
  onPostHide,
  onPostHideFromActivity,
  show,
  onReply,
  isClickable,
  isSpecificPost,
  onLike,
  onCommentLike,
  onToggleCommentReplies,
  onComment,
  getReplies,
  shouldDisplayImages,
  onFollow,
  shouldHideFollowButton,
}) => {
  const { id, isLiked, userDetails, actionId, repostDetails } = post;

  const repostId = repostDetails ? repostDetails.id : actionId;

  const onCommentsLoad = () => getComments({ actionId, postId: id });

  const onFollowToggle = useRequireToken(() => {
    const options = {
      isFollowing: post.isFollowing,
      userId: userDetails.userId,
    };
    onFollow(options);
  });

  const onReplyClick = useRequireToken(
    (
      commentId: string,
      replyText: string,
      taggedUsers: ITaggedUser[],
      attachedImages: Array<string>,
    ) => {
      const options = {
        image: attachedImages[0],
        commentId,
        actionId,
        text: replyText,
        taggedUsers,
        postId: id,
      };
      onReply(options);
    },
  );

  const onLikeClick = useRequireToken(() => {
    const options = { actionId, postId: id, isLiked };
    onLike(options);
  }, NonSignInPopupSources.LikePost);

  const onCommentSubmit = useRequireToken(
    (comment: string, taggedUsers: ITaggedUser[], attachedImages: Array<string>) => {
      const options = {
        image: attachedImages[0],
        postId: id,
        actionId,
        taggedUsers,
        text: comment,
      };
      onComment(options);
    },
  );

  const onRepliesLoad = useRequireToken((commentId: string) => getReplies({ commentId, actionId }));

  const onToggleRepliesClick = useRequireToken((commentId: string) => {
    onToggleCommentReplies({ commentId, postId: id });
  });

  const onImageClick = (imageIndex: number) => {
    const options = {
      id: popupIdsOptions.feedImagesPopup,
      imageIndex,
      postId: id,
    };
    show(options);
  };

  const onLikesClick = useRequireToken(() => {
    const options = { id: popupIdsOptions.feedReactionsPopup, postId: id, actionId };
    show(options);
  });

  const onCommentLikesClick = useRequireToken((commentId: string, replyId?: string) => {
    const options = { id: popupIdsOptions.feedReactionsPopup, postId: id, commentId, replyId };
    show(options);
  });

  const onCommentDelete = useRequireToken((commentId: string, replyId?: string) => {
    const options = {
      id: popupIdsOptions.feedDeleteCommentPopup,
      postId: id,
      commentId,
      replyId,
    };
    show(options);
  });

  const onLikeComment = useRequireToken(
    (commentId: string, isCommentLiked: boolean, replyId?: string) => {
      onCommentLike({ commentId, isLiked: isCommentLiked, postId: post.id, replyId });
    },
  );

  const onRepostClick = useRequireToken(() => {
    show({
      id: popupIdsOptions.feedRepostOptionsPopup,
      postId: id,
      actionId: repostId,
    });
  });

  const onShowRepostsClick = useRequireToken(() => {
    show({ id: popupIdsOptions.feedRepostsPopup, postId: id, actionId: repostId });
  });

  const onDeletePost = useRequireToken(() => {
    show({ id: popupIdsOptions.deletePostPopup, actionId });
  });

  const onPostReport = useRequireToken(() => {
    show({ id: popupIdsOptions.reportPostPopup, actionId });
  });

  return (
    <Post
      source={null}
      onShowRepostsClick={onShowRepostsClick}
      index={index}
      shouldHidePremium={false}
      isClickable={isClickable}
      shouldHideFollowButton={!!shouldHideFollowButton}
      shouldDisplayImages={shouldDisplayImages}
      onDeletePost={onDeletePost}
      onCommentDelete={onCommentDelete}
      onLikesClick={onLikesClick}
      onImageClick={onImageClick}
      onCommentSubmit={onCommentSubmit}
      onRepliesLoad={onRepliesLoad}
      onLikeClick={onLikeClick}
      onReply={onReplyClick}
      onCommentsLoad={onCommentsLoad}
      onFollowToggle={onFollowToggle}
      onRepostsClick={onRepostClick}
      shouldDisplayInputByDefault
      textMaxChars={MAX_CHARS}
      post={post}
      type={UserDetailsLocations.Header}
      shouldDisplayComments={post.isCommentsExpanded}
      isSpecificPost={isSpecificPost}
      onPostHide={onPostHide}
      onPostHideFromActivity={onPostHideFromActivity}
      onLikeComment={onLikeComment}
      onCommentLikesClick={onCommentLikesClick}
      onPostReport={onPostReport}
      onToggleRepliesClick={onToggleRepliesClick}
    />
  );
};

const mapDispatchToProps = {
  onToggleCommentReplies: FeedSlicer.actions.toggleCommentReplies,
  onFollow: onFollowUserToggle,
  onPostHide: hidePost,
  onPostHideFromActivity: hidePostFromActivity,
  getComments: onGetComments,
  onLike: likePost,
  onComment: addComment,
  onReply: addReply,
  getReplies: getCommentReplies,
  onCommentLike: onLikeCommentAction,
  show: PopupSlicer.actions.show,
};

export default connect(null, mapDispatchToProps)(PostWrapper);
