import { DiscoverPageResultWeb, ShopOfTheDay } from 'graphql/generated/graphql';
import { DiscoverListsId, IDiscoverSectionTitle, ILayoutComponentBaseDetails, IPrice } from 'types';

export interface IDiscoverState {
  shopOfTheDay: IShopOfTheDay | null;
  categories: {
    type: DiscoverLayoutComponents.Categories;
    list: IDiscoverCategory[];
    marginTop: number;
    order: number;
  };
  items: IDiscoverPageItems | null;
  isLoading: boolean;
}

export interface IDiscoverApi {
  getDiscoverPageData(token?: string): Promise<DiscoverPageResultWeb>;
  getShopOfTheDay(token: string): Promise<ShopOfTheDay[]>;
}

export enum DiscoverLayoutComponents {
  ProductsEqualGrid = 'equalProductGrid',
  ProductsUnEqualGrid = 'unEqualProductGrid',
  ProductsCarousel = 'discoverProductsCarousel',
  ShopsCarousel = 'discoverShopsCarousel',
  CustomProductsLayout = 'customProductsLayout',
  MixedLayout1 = 'mixedLayout1',
  MixedLayout2 = 'mixedLayout2',
  MixedLayout3 = 'mixedLayout3',
  Categories = 'categories',
  Search = 'search',
  Premium = 'premium',
}

export interface IDiscoverProduct {
  id: string;
  image: string;
  isLiked: boolean;
  price: IPrice | null;
  totalLikes: number | null;
}

export interface IDiscoverCategory {
  name: string;
  image: string;
  keywords: string[];
}

export interface IDiscoverStaticItem {
  type: string;
  order: number;
  marginTop: number;
}

export interface IDiscoverPageComponent extends ILayoutComponentBaseDetails {
  order: number;
  marginTop: number;
}

export interface IDiscoverPageItems {
  staticItems: IDiscoverStaticItem[];
  productsCarousels: IDiscoverPageComponent[];
  grids: IDiscoverPageComponent[];
  mixedLayouts: IDiscoverPageComponent[];
  shopsCarousels: IDiscoverPageComponent[];
}

export interface IDiscoverListDetails {
  listId: DiscoverListsId;
  title: string;
  paginationToken: string | null;
  type: DiscoverListTypes;
}

export enum DiscoverListTypes {
  Products = 'products',
  Shops = 'shops',
  Discover = 'discover',
  Vision = 'vision',
}

export interface IShopOfTheDay {
  userId: string;
  shopId: string;
  titles: IDiscoverSectionTitle[];
  footerBackgroundColor: string;
  image: string;
  isFollowing: boolean;
}
