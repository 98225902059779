import { FC } from 'react';
import './Tip.scss';
import { Message } from 'tedooo-web-design-system/ui';
import { AlertCircle, AlertTriangle, HelpCircle, LightBulb } from 'tedooo-web-design-system/icons';
import classNames from 'classnames';

import { ITip, TipType } from 'types';
import { Image } from '../helpers';

interface IMessageTip {
  tip: ITip;
}

const mapTypeToIcon = {
  [TipType.Purple]: <LightBulb />,
  [TipType.Info]: <HelpCircle />,
  [TipType.Warning]: <AlertTriangle />,
  [TipType.Error]: <AlertCircle />,
};

const mapTypeToColor = {
  [TipType.Purple]: 'secondary',
  [TipType.Info]: 'primary',
  [TipType.Warning]: 'warning',
  [TipType.Error]: 'danger',
};

const Tip: FC<IMessageTip> = ({ tip }) => {
  const { type, items, link: tipLink } = tip;

  const onLinkClick = (link: string | null) => {
    if (link) {
      const a = document.createElement('a');
      a.href = link;
      a.click();
    }
  };

  const renderItem = () => {
    return items.map((item) => {
      if (item.textItem) {
        const { text, font, underline, link } = item.textItem;

        return renderTextItem(text, font, underline, link);
      }
      if (item.imageItem) {
        const { height, width, name, link } = item.imageItem;

        return renderImageItem(height, width, name, link);
      }

      return <></>;
    });
  };

  const renderTextItem = (text: string, font: string, underline: boolean, link: string | null) => {
    return (
      <span onClick={() => onLinkClick(link)} className={classNames('item', [font], { underline })}>
        {text}
      </span>
    );
  };

  const renderImageItem = (height: number, width: number, src: string, link: string | null) => {
    return (
      <div style={{ height, width }} className="image-container" onClick={() => onLinkClick(link)}>
        <Image src={src} />
      </div>
    );
  };

  return (
    <div onClick={() => onLinkClick(tipLink)}>
      <Message
        message={renderItem()}
        type={mapTypeToColor[type]}
        prefixIcon={mapTypeToIcon[type]}
      />
    </div>
  );
};

export default Tip;
