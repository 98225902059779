import { IProductsApi, DiscoverListsId } from 'types';
import { PaginatedItemsResult, Query } from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import { GET_PRODUCTS_QUERY } from './ProductsQueries';

export class ProductsApi implements IProductsApi {
  async getAllProducts(
    listId: DiscoverListsId,
    paginationToken?: string,
    token?: string,
  ): Promise<PaginatedItemsResult> {
    const variables = {
      paginationToken,
      listId,
    };

    const res = await graphQlRequest(token).query<Query>({
      query: GET_PRODUCTS_QUERY,
      variables,
    });

    return res.data.getProductsByListId;
  }
}
