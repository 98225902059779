import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { UserReducer } from './UserReducer';
import {
  FinishSignUpOptions,
  FinishSignUpRejectOptions,
  IFinishSignUpRequest,
  IGoogleLoginRequest,
  IMyPost,
  ISignUpRequest,
  IStore2,
  IUserState,
  ServerStatus,
  SignPageOptions,
  PremiumStatus,
  UserTypes,
  DiscoverListsId,
} from 'types';
import {
  getAvatar,
  getFullName,
  getIsPremium,
  getIsSuperAdmin,
  getPremiumDetails,
  getThirdParty,
  getUserId,
  getUsername,
  USERNAME_EXISTS_ERROR_MESSAGE,
} from './helper';
import { StorageSrv } from 'services/StorageSrv';
import { createPost, onPostDelete } from '../feed/PostActions';
import { createShop } from '../createShop/CreateShopActions';
import { SubscriptionType } from 'graphql/generated/graphql';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { updateProductLikes } from '../specificProduct/helper';
import { getAllProducts } from '../products/ProductsActions';
import {
  parseLoggedInUserInformation,
  parsePremiumPlanDetails,
  parseSavedProducts,
} from '../apiParser';
import { unlinkShippo } from '../shipping/ShippingActions';

const GENERAL_SERVER_ERROR = 'Something went wrong.\nPlease try again later';

const LOGIN_SERVER_ERROR = 'Wrong username or password';

const reducer = new UserReducer();

const storageToken = StorageSrv.token.get();

const initialState: IUserState = {
  token: storageToken,
  loginDefaultUsername: null,
  type: UserTypes.Buyer,
  avatar: getAvatar(storageToken),
  fullName: getFullName(storageToken),
  thirdParty: getThirdParty(storageToken),
  id: getUserId(storageToken),
  username: getUsername(storageToken),
  isLoading: false,
  isCodeSent: false,
  loggedInUserShopId: null,
  loginServerErrorMessage: undefined,
  posts: [],
  shopDetails: null,
  isPremium: getIsPremium(storageToken),
  isAvatarLoading: false,
  totalFollowers: 0,
  isShippoConnected: false,
  totalFollowing: 0,
  totalPendingOrders: 0,
  phase: SignPageOptions.SignIn,
  totalProfileViews: 0,
  finishSignUpInformation: null,
  bio: null,
  shouldShowOnboarding: false,
  savedProducts: null,
  orderIdToShow: null,
  isSuperAdmin: getIsSuperAdmin(storageToken),
  premiumDetails: getPremiumDetails(storageToken),
};

export const refreshToken = createAsyncThunk('async/refreshToken', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  return reducer.refreshToken(token);
});

export const login = createAsyncThunk(
  'async/login',
  async (input: { username: string; password: string; rememberMe: boolean }) =>
    reducer.login(input.username, input.password),
);

export const googleLogin = createAsyncThunk(
  'async/googleLogin',
  async (input: IGoogleLoginRequest, { rejectWithValue }) => {
    try {
      return await reducer.googleLogin(input.token);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const facebookLogin = createAsyncThunk(
  'async/facebookLogin',
  async (input: { token: string }, { rejectWithValue }) => {
    try {
      return await reducer.facebookLogin(input.token);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const signUp = createAsyncThunk(
  'async/signUp',
  async (input: ISignUpRequest, { rejectWithValue }) => {
    try {
      const { firstName, username, password, email, phoneNumber, botToken } = input;

      return await reducer.signUp(firstName, username, password, email, phoneNumber, botToken);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const checkUsernameAvailability = createAsyncThunk(
  'async/checkUsernameAvailability',
  async (input: { username: string }, { rejectWithValue }) => {
    try {
      const { username } = input;

      return await reducer.checkUsernameAvailability(username);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getPremiumPlanDetails = createAsyncThunk(
  'async/getPremiumPlanDetails',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.getPremiumPlanDetails(token);
  },
);

export const getSubscriptionUrl = createAsyncThunk(
  'async/getSubscriptionUrl',
  async (input: { subscriptionType: SubscriptionType }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const { subscriptionType } = input;

    return reducer.getSubscriptionUrl(subscriptionType, token);
  },
);

export const getManagePlanUrl = createAsyncThunk(
  'async/getManagePlanUrl',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.getManagePlanUrl(token);
  },
);

export const finishSignUp = createAsyncThunk(
  'async/finishSignUp',
  async (input: IFinishSignUpRequest, { rejectWithValue, getState }: any) => {
    const state: IStore2 = getState();

    const { finishSignUpInformation } = state.user;

    if (finishSignUpInformation) {
      try {
        const { name, email, image, type, token } = finishSignUpInformation;

        const { username } = input;

        return await reducer.finishSignUp(token, type, name, email, image, username);
      } catch (e) {
        return rejectWithValue(e);
      }
    }

    return rejectWithValue();
  },
);

export const submitVerifyCode = createAsyncThunk(
  'async/submitVerifyCode',
  async (codeNumber: string, { rejectWithValue }) => {
    try {
      return await reducer.submitVerifyCode(codeNumber);
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getLoggedInUserInfo = createAsyncThunk(
  'async/getLoggedInUserInfo',
  async (input: { token: string }) => {
    const { token } = input;

    return reducer.getLoggedInUserInfo(token);
  },
);

export const sendCodeViaWhatsUp = createAsyncThunk('async/sendCodeViaWhatsUp', async () => {
  return reducer.sendCodeViaWhatsApp();
});

export const sendCodeViaCall = createAsyncThunk('async/sendCodeViaCall', async () => {
  return reducer.sendCodeViaCall();
});

export const resendCode = createAsyncThunk('async/resendCode', async () => {
  return reducer.resendCode();
});

export const updateAvatar = createAsyncThunk(
  'async/updateAvatar',
  async (input: { avatar: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { avatar } = input;

    return reducer.updateAvatar(token || '', avatar);
  },
);

export const updateUserDetails = createAsyncThunk(
  'async/updateUserDetails',
  async (input: { bio?: string; name?: string; avatar?: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token, id } = state.user;

    const { bio, name, avatar } = input;

    if (bio !== undefined) {
      const res = await reducer.updateBio(token!, bio);

      if (!name && !avatar) {
        return res;
      }
    }

    return reducer.updateUserDetails(token!, id!, name, avatar);
  },
);

export const updateUserToBuyer = createAsyncThunk(
  'async/updateUserToBuyer',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    return reducer.updateUserToBuyer(token || '');
  },
);

export const getAppConfig = createAsyncThunk('async/getAppConfig', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token = '' } = state.user;

  return reducer.getAppConfigurations(token);
});

export const UserSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signOut: (state) => {
      state.token = undefined;
      state.type = UserTypes.Buyer;
      state.fullName = null;
      state.avatar = null;
      state.id = null;
      state.username = null;
      state.isPremium = false;
      state.loggedInUserShopId = null;
      state.loginDefaultUsername = null;
    },
    reset: (state) => {
      state.loginServerErrorMessage = undefined;
      state.serverStatus = undefined;
      state.loginDefaultUsername = null;
      state.isCodeSent = false;
      state.isLoading = false;
    },
    updateLoginDefaultUsername: (state, action: { payload: { username: string } }) => {
      state.loginDefaultUsername = action.payload.username;
      state.serverStatus = undefined;
      state.loginServerErrorMessage = undefined;
    },
    updateUserDetails: (state, action) => {
      const { username, fullName } = action.payload;

      if (username) {
        state.username = username;
      }
      if (fullName) {
        state.fullName = fullName;
      }
    },
    changeSignPhase: (state, action) => {
      state.phase = action.payload;
    },
    onAvatarLoading: (state) => {
      state.isAvatarLoading = true;
    },
    updateShouldShowOnboarding(state, action) {
      const { shouldShow } = action.payload;
      state.shouldShowOnboarding = shouldShow;
    },
    updateTotalPendingOrders: (state, action) => {
      const { totalPendingOrders } = action.payload;
      state.totalPendingOrders = totalPendingOrders;
    },
    updateShippoConnection: (state, action: { payload: { isShippoConnected: boolean } }) => {
      const { isShippoConnected } = action.payload;
      state.isShippoConnected = isShippoConnected;
    },
    updateUserPremiumStatus: (state, action) => {
      const { premiumStatus } = action.payload;

      if (state.premiumDetails) {
        state.premiumDetails = { ...state.premiumDetails, premiumStatus };
      }

      state.isPremium = premiumStatus === PremiumStatus.Active;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state) => {
      state.serverStatus = ServerStatus.ERROR;
      state.isLoading = false;
      state.loginServerErrorMessage = LOGIN_SERVER_ERROR;
    });

    builder.addCase(onPostDelete.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.posts = state.posts.filter((post) => post.id !== id);
    });

    builder.addCase(updateUserToBuyer.pending, (state) => {
      state.type = UserTypes.Buyer;
    });

    builder.addCase(updateUserToBuyer.rejected, (state) => {
      state.type = UserTypes.Seller;
    });

    builder.addCase(createPost.fulfilled, (state, action) => {
      const { id } = action.payload;

      const { images, text } = action.meta.arg;

      const newPost: IMyPost = {
        image: images[0] || null,
        id,
        text,
        date: Date.now(),
      };
      state.posts.unshift(newPost);
    });

    builder.addCase(unlinkShippo.fulfilled, (state) => {
      state.isShippoConnected = false;
    });

    builder.addCase(getLoggedInUserInfo.fulfilled, (state, action) => {
      const { followers, following, sellerInformation, bio, profileViews, posts } = action.payload;

      const { posts: parsedPosts, shopDetails } = parseLoggedInUserInformation(
        posts,
        sellerInformation?.shop,
      );

      const type = sellerInformation ? UserTypes.Seller : UserTypes.Buyer;
      state.totalFollowers = followers;
      state.totalProfileViews = profileViews;
      state.bio = bio || null;
      state.type = type;
      state.totalFollowing = following;
      state.shopDetails = shopDetails;
      state.loggedInUserShopId = sellerInformation?.shop?.id || null;
      state.posts = parsedPosts;
    });

    builder.addCase(signUp.fulfilled, (state) => {
      state.isCodeSent = true;
      state.isLoading = false;
    });

    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      const { bio, avatar } = action.meta.arg;

      if (bio !== undefined) {
        state.bio = bio;
      }
      if (avatar) {
        state.avatar = avatar;
      }
    });

    builder.addCase(updateAvatar.fulfilled, (state, action) => {
      const { avatar } = action.meta.arg;
      state.avatar = avatar;
      state.isAvatarLoading = false;
    });

    builder.addCase(facebookLogin.rejected, (state, action: any) => {
      state.isLoading = false;

      if (action.payload.code === FinishSignUpRejectOptions.NotMember) {
        const { id, email, name, picture } = action.payload.message.data;

        const { token } = action.meta.arg;

        state.finishSignUpInformation = {
          id,
          email,
          name,
          image: picture,
          token,
          type: FinishSignUpOptions.Facebook,
        };
      }
    });
    builder.addCase(googleLogin.rejected, (state, action: any) => {
      if (action.payload.code === FinishSignUpRejectOptions.NotMember) {
        const { id, email, name, imageUrl, token } = action.meta.arg;
        state.isLoading = false;

        state.finishSignUpInformation = {
          id,
          email,
          name,
          image: imageUrl,
          token,
          type: FinishSignUpOptions.Google,
        };
      }
    });
    builder.addCase(checkUsernameAvailability.rejected, (state, action: any) => {
      const { message } = action.payload.message;
      state.loginServerErrorMessage = message || USERNAME_EXISTS_ERROR_MESSAGE;
    });
    builder.addCase(createShop.fulfilled, (state) => {
      if (state.id) {
        StorageSrv.draft.remove(state.id);
      }
    });
    builder.addCase(getPremiumPlanDetails.fulfilled, (state, action) => {
      const parsedData = parsePremiumPlanDetails(action.payload);

      const { premiumPlan, payoutProvider, isSubscriptionAutoRenew } = parsedData;

      if (state.premiumDetails) {
        state.premiumDetails.userPremiumPlan = premiumPlan;
        state.premiumDetails.payoutProvider = payoutProvider;
        state.premiumDetails.isSubscriptionAutoRenew = isSubscriptionAutoRenew;
      }
    });
    builder.addCase(getManagePlanUrl.fulfilled, (state, action) => {
      const { url } = action.payload;

      if (state.premiumDetails) {
        state.premiumDetails.managePlanUrl = url;
      }
    });
    builder.addCase(getAppConfig.fulfilled, (state, action) => {
      state.isShippoConnected = !!action.payload.didLinkShippo;
    });
    builder.addCase(getSubscriptionUrl.fulfilled, (state, action) => {
      if (action.payload) {
        const { url } = action.payload;

        const { subscriptionType } = action.meta.arg;

        if (state.premiumDetails) {
          if (subscriptionType === SubscriptionType.Yearly) {
            state.premiumDetails.yearlySubscriptionUrl = url;
          }
          if (subscriptionType === SubscriptionType.Monthly) {
            state.premiumDetails.monthlySubscriptionUrl = url;
          }
        }
      }
    });
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      const { type } = action.meta.arg;

      if (type === DiscoverListsId.Favorites) {
        const { products } = action.payload;
        state.savedProducts = parseSavedProducts(products);
      }
    });
    builder.addMatcher(
      isAnyOf(checkUsernameAvailability.fulfilled, checkUsernameAvailability.pending),
      (state) => {
        state.loginServerErrorMessage = undefined;
      },
    );
    builder.addMatcher(isAnyOf(finishSignUp.rejected, signUp.rejected), (state, action: any) => {
      const { message } = action.payload.message;
      state.serverStatus = ServerStatus.ERROR;
      state.isLoading = false;
      state.loginServerErrorMessage = message || GENERAL_SERVER_ERROR;
    });

    builder.addMatcher(isAnyOf(finishSignUp.pending, signUp.pending), (state) => {
      state.isLoading = true;
      state.serverStatus = undefined;
    });

    builder.addMatcher(
      isAnyOf(login.pending, facebookLogin.pending, googleLogin.pending),
      (state) => {
        state.isLoading = true;
        state.loginServerErrorMessage = undefined;
        state.serverStatus = ServerStatus.PENDING;
      },
    );

    builder.addMatcher(
      isAnyOf(onProductLikeToggle.pending, onProductLikeToggle.rejected),
      (state, action) => {
        const { productId } = action.meta.arg;

        if (state.savedProducts) {
          const product = state.savedProducts.find((item) => item.id === productId);
          updateProductLikes(product);
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        finishSignUp.fulfilled,
        login.fulfilled,
        submitVerifyCode.fulfilled,
        facebookLogin.fulfilled,
        googleLogin.fulfilled,
        refreshToken.fulfilled,
      ),
      (state, action) => {
        const { accessToken, shopId, orderPopupToShow, orders } = action.payload;

        const isPremiumUser = getIsPremium(accessToken);

        state.token = accessToken;
        state.id = getUserId(accessToken);
        state.username = getUsername(accessToken);
        state.fullName = getFullName(accessToken);
        state.avatar = getAvatar(accessToken);
        state.isPremium = isPremiumUser;
        state.thirdParty = getThirdParty(accessToken);
        state.serverStatus = undefined;
        state.loginServerErrorMessage = undefined;
        state.finishSignUpInformation = null;
        state.serverStatus = undefined;
        state.isLoading = false;
        state.loggedInUserShopId = shopId;
        state.orderIdToShow = orderPopupToShow?.newOrder || null;
        state.totalPendingOrders = orders || 0;
        state.isSuperAdmin = getIsSuperAdmin(accessToken);
        state.premiumDetails = getPremiumDetails(accessToken);
        StorageSrv.token.setInLocal(accessToken);
      },
    );
  },
});

export default UserSlicer.reducer;
