import { nanoid } from '@reduxjs/toolkit';

import {
  IAccountPreferenceResponse,
  IBlockedUsersResponse,
  IGetShippingAddressesResponse,
  IPostNotificationsResponse,
  ISettingsApi,
  IShippingAddress,
  IUpdateSettingsRequest,
  IVacationModeDetails,
  Method,
  NotificationsTypes,
} from 'types';
import { a, fetcher, signKey } from 'api';
import { GetVacationDetailsResponse, SuccessResponse } from 'graphql/generated/graphql';
import { graphQlRequest } from 'api/graphQl';
import { GET_VACATION_MODE_DETAILS_QUERY, VACATION_MODE_MUTATION } from './SettingsQueries';

export class SettingsApi implements ISettingsApi {
  updateSettings(token: string, body: IUpdateSettingsRequest): Promise<void> {
    return fetcher(`chat`, Method.PATCH, token, body);
  }

  updateLanguage(token: string, body: { lang: string }): Promise<void> {
    return fetcher(`users/current`, Method.PATCH, token, body);
  }

  async updateVacationMode(
    token: string,
    body: IVacationModeDetails | null,
  ): Promise<SuccessResponse> {
    const variables = {
      shopMessage: body?.shopMessage,
      autoReplyMessage: body?.autoReplyMessage,
    };

    const res = await graphQlRequest(token).mutate({
      mutation: VACATION_MODE_MUTATION,
      variables,
    });

    return res.data.updateVacationDetails;
  }

  async getVacationModeDetails(token: string): Promise<GetVacationDetailsResponse> {
    const res = await graphQlRequest(token).query({
      query: GET_VACATION_MODE_DETAILS_QUERY,
    });

    return res.data.getVacationDetails;
  }

  getPostNotifications(token: string): Promise<IPostNotificationsResponse> {
    return fetcher(`v3/settings/notifications`, Method.GET, token);
  }

  updatePostNotifications(
    type: NotificationsTypes,
    push: boolean,
    email: boolean | null,
    token: string,
  ): Promise<void> {
    const body = {
      key: type,
      push,
      email: email || undefined,
    };

    return fetcher(`v3/settings/notifications`, Method.POST, token, body);
  }

  getAccountPreferences(token: string): Promise<IAccountPreferenceResponse> {
    return fetcher(`v3/settings/account`, Method.GET, token);
  }

  getBlockedUsers(token: string): Promise<IBlockedUsersResponse[]> {
    return fetcher(`blocks`, Method.GET, token);
  }

  unBlockUser(token: string, userId: string): Promise<void> {
    return fetcher(`blocks/${userId}`, Method.DELETE, token);
  }

  updatePassword(token: string, body: { oldPassword: string; password: string }): Promise<void> {
    return fetcher(`users/current`, Method.PATCH, token, body);
  }

  updateUserCurrency(token: string, currencyCode: string): Promise<void> {
    const body = {
      currencyCode,
    };

    return fetcher(`v2/users/currencies`, Method.PATCH, token, body);
  }

  updateUserDetails(
    token: string,
    fullName?: string,
    username?: string,
    email?: string,
    birthday?: number,
  ): Promise<void> {
    const body = {
      fullName,
      username,
      email,
      birthdate: birthday,
    };

    return fetcher(`v3/settings/update`, Method.POST, token, body);
  }

  deleteShippingAddress(shippingId: string, token: string): Promise<void> {
    return fetcher(`v2/settings/shipping/${shippingId}`, Method.DELETE, token);
  }

  getShippingAddresses(token?: string): Promise<IGetShippingAddressesResponse> {
    return fetcher(`v2/settings/shipping`, Method.GET, token);
  }

  addShippingAddress(shippingAddress: IShippingAddress, token: string): Promise<{ id: string }> {
    const body = {
      country: shippingAddress.country,
      fullName: shippingAddress.fullName,
      streetAddress: shippingAddress.streetAddress,
      other: shippingAddress.apartmentNumber,
      postalCode: shippingAddress.postalCode,
      city: shippingAddress.city,
      state: shippingAddress.state,
      phoneNumber: shippingAddress.phoneNumber,
      default: shippingAddress.isDefault,
    };

    return fetcher(`v2/settings/shipping`, Method.POST, token, body);
  }

  editShippingAddress(shippingAddress: IShippingAddress, token: string): Promise<void> {
    const body = {
      country: shippingAddress.country,
      fullName: shippingAddress.fullName,
      streetAddress: shippingAddress.streetAddress,
      other: shippingAddress.apartmentNumber,
      postalCode: shippingAddress.postalCode,
      city: shippingAddress.city,
      state: shippingAddress.state,
      phoneNumber: shippingAddress.phoneNumber,
      default: shippingAddress.isDefault,
    };

    return fetcher(`v2/settings/shipping/${shippingAddress.id}`, Method.PATCH, token, body);
  }

  setDefaultAddress(id: string, token: string): Promise<void> {
    return fetcher(`v2/settings/shipping/default/${id}`, Method.PUT, token);
  }

  checkPassword(userName: string, password: string): Promise<void> {
    const username = userName.toLowerCase();

    const nonce = nanoid(8);

    const timestamp = signKey(`${username} ${a} ${password}`, nonce);

    return fetcher('authentication', Method.POST, undefined, {
      strategy: 'local',
      username,
      nonce,
      timestamp,
      password,
    });
  }

  deleteAccount(token: string, reason: string, text: string | null): Promise<void> {
    const body = { reason, text };

    return fetcher(`v2/users/delete`, Method.DELETE, token, body);
  }
}
