import { ForgetPasswordPhasesOptions, IFinishSignUpInformation, ServerStatus } from 'types';

export * from './signUp/types';

export * from './finishSignUp/types';

export interface ISignInForm {
  isLoading: boolean;
  username: string | null;
  errorMessage?: string;
  serverStatus?: ServerStatus;
  onSubmit(username: string, password: string): void;
}

export enum SignPageOptions {
  SignIn,
  SignUp,
  FinishSignUp,
  ForgetPassword,
}

export interface ISignIn {
  isLoading: boolean;
  errorMessage?: string;
  username: string | null;
  serverStatus?: ServerStatus;
  onForgetPasswordClick(): void;
  onSignUpClick(): void;
  onLogin(input: { username: string; password: string; rememberMe: boolean }): void;
}

export enum NonSignInPopupSources {
  BuyItem = 'buy_item',
  AddToBasket = 'add_to_basket',
  LikeProduct = 'like_product',
  LikePost = 'like_post',
  Comment = 'comment',
  Share = 'share',
  Repost = 'repost',
  SharePost = 'share_post',
  LogIn = 'log_in',
  JoinNow = 'join_now',
}

export interface ISignUpForm {
  onSubmit(
    username: string,
    password: string,
    firstName: string,
    email: string,
    phoneNumber: string | null,
    botToken: string | null,
  ): void;
  checkUsername(username: string): void;
  isLoading: boolean;
  errorMessage?: string;
}

export interface ISignPage {
  finishSignUpInformation: IFinishSignUpInformation | null;
  phase: SignPageOptions;
  token?: string;
  onResetForm(): void;
  onPhaseChange(phase: SignPageOptions): void;
}

export interface IForgetPassword {
  serverErrorMessage?: string;
  phase: ForgetPasswordPhasesOptions;
  prevPassword: string;
  phone: string;
  username: string;
  onBackClick?: () => void;
  onReset(): void;
  onCodeSubmit(input: { code: string; username: string }): void;
  onPhoneSubmit(input: { phone: string }): void;
  onPasswordUpdate(input: { password: string; prevPassword: string; username: string }): void;
}

export interface IVerifyFooter {
  onClose(): void;
  onResendCode(): void;
  sendCodeViaWhatsUp(): void;
  sendCodeViaCall(): void;
}
