import { ClickableImage, Skeleton, Span, Tabs } from 'tedooo-web-design-system/ui';
import './notifications.scss';
import { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { XIcon } from 'tedooo-web-design-system/icons';
import { useNavigate } from 'react-router-dom';

import { translations } from 'translations/en';
import {
  EmptyStateOptions,
  INotifications,
  IStore2,
  NotificationActionTypes,
  NotificationsTabs,
  popupIdsOptions,
} from 'types';
import {
  getNotifications as getNotificationsAction,
  getOrderNotifications as getOrderNotificationsAction,
} from 'store/notifications/NotificationsActions';
import { NotificationsSlicer } from 'store/notifications/NotificationsSlicer';
import { NotificationsSelector } from 'store/notifications/NotificationsSelector';
import { List } from 'components/list';
import NotificationRow from './NotificationsRow/NotificationRow';
import { EmptyState } from 'components/emptyState';
import { PopupSlicer } from 'store/popup/PopupActions';
import { getNotificationAction, getUserIdFromLink } from './helper';

const { TITLE, ALL, ORDERS } = translations.NOTIFICATIONS;

const NOTIFICATION_TABS = [
  { key: NotificationsTabs.All, label: ALL },
  { key: NotificationsTabs.Orders, label: ORDERS },
];

const LINK_PREFIX = 'tedooo://';

const Notifications: FC<INotifications> = ({
  list,
  groupCounts,
  hasMore,
  isLoading,
  activeTab,
  getOrderNotifications,
  getNotifications,
  onTabChange,
  reset,
  onCloseClick,
  onFollowToggle,
  onCreatePost,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!list) {
      if (activeTab === NotificationsTabs.Orders) {
        getOrderNotifications();
      } else {
        getNotifications();
      }
    }
  }, [activeTab]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onTabClick = useCallback((tab: NotificationsTabs) => {
    onTabChange({ tab });
  }, []);

  const onLoadMore = useCallback(() => {
    if (activeTab === NotificationsTabs.Orders) {
      getOrderNotifications();
    } else {
      getNotifications();
    }
  }, [activeTab]);

  const onNotificationButtonClick = useCallback((notificationId: string, link: string) => {
    const action = getNotificationAction(link);

    if (action === NotificationActionTypes.CreatePost) {
      onCreatePost({ id: popupIdsOptions.createPostPopup });
    } else if (
      action === NotificationActionTypes.Follow ||
      action === NotificationActionTypes.Unfollow
    ) {
      const userId = getUserIdFromLink(link);

      const isFollowing = action === NotificationActionTypes.Unfollow;
      onFollowToggle({ isFollowing, userId, notificationId });
    } else {
      const parsedLink = link.replace(LINK_PREFIX, '');
      navigate(`/${parsedLink}`);
    }
  }, []);

  const onRowClick = useCallback((id: string, link: string) => {
    onNotificationButtonClick(id, link);
    onCloseClick();
  }, []);

  const itemContent = useCallback(
    (index: number) => {
      if (!list || index >= list.length) {
        return (
          <Skeleton
            avatar={{ shape: 'circle' }}
            key={`NOTIFICATION-SKELETON${index}`}
            title
            rows={2}
            className="notification-skeleton"
          />
        );
      }

      const item = list[index];

      return (
        <NotificationRow
          id={item.id}
          isNew={item.isNew}
          titles={item.titles}
          text={item.text}
          buttonDetails={item.buttonDetails}
          date={item.date}
          link={item.link}
          key={item.id}
          prefixImage={item.prefixImage}
          suffixImage={item.suffixImage}
          imageGrid={item.imageGrid}
          onButtonClick={onNotificationButtonClick}
          onRowClick={onRowClick}
        />
      );
    },
    [list, groupCounts, isLoading],
  );

  const renderGroup = useCallback(
    (index: number) => {
      if (!groupCounts[index]) return null;

      const { text } = groupCounts[index];

      return (
        <span key={`HEADER-${text}`} className="sub-title">
          {text}
        </span>
      );
    },
    [groupCounts, list, isLoading],
  );

  const renderEmptyState = useCallback(() => {
    if (activeTab === NotificationsTabs.Orders) {
      return <EmptyState type={EmptyStateOptions.OrdersOverviewNoActiveOrders} />;
    }

    return null;
  }, [activeTab]);

  const parsedGroupCounts = groupCounts.map((group) => group.total);

  return (
    <div className="notifications-container">
      <div className="title-container">
        <ClickableImage icon={<XIcon />} onClick={onCloseClick} />
        <Span text={TITLE} className="title" />
      </div>
      <Tabs onChange={onTabClick} tabs={NOTIFICATION_TABS} />
      <List
        hasMore={hasMore}
        emptyState={renderEmptyState()}
        overscan={1000}
        itemContent={itemContent}
        disableStickyHeader
        totalCount={list?.length || 0}
        data={list}
        loadMore={onLoadMore}
        isGroup
        groupContent={renderGroup}
        groupCounts={parsedGroupCounts}
      />
    </div>
  );
};

const mapDispatchToProps = {
  reset: NotificationsSlicer.actions.reset,
  onTabChange: NotificationsSlicer.actions.onTabChange,
  onFollowToggle: NotificationsSlicer.actions.onFollowToggle,
  onCreatePost: PopupSlicer.actions.show,
  getOrderNotifications: getOrderNotificationsAction,
  getNotifications: getNotificationsAction,
};

const mapStateToProps = (state: IStore2) => {
  const { activeTab, isLoading } = state.notifications;

  const list = NotificationsSelector.notificationsListSelector(state);

  const hasMore = NotificationsSelector.hasMoreNotificationsSelector(state);

  const groupCounts = NotificationsSelector.groupCountsSelector(state);

  return {
    list,
    hasMore,
    activeTab,
    groupCounts,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
