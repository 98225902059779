import { useNavigate } from 'react-router-dom';

import { translations } from 'translations/en';
import './sideBarFooter.scss';
import { ROUTES, TEDOOO_SUPPORT_ROUTE } from 'constant';

const SideBarFooter = () => {
  const navigate = useNavigate();

  const onHelpClick = () => {
    navigate(`/${TEDOOO_SUPPORT_ROUTE}`);
  };

  return (
    <div className="footer-container">
      <span onClick={onHelpClick}>{translations.PAGES.HELP}</span>
      <div className="dot" />
      <a href={`/${ROUTES.PRIVACY}`}>
        <span>{translations.PAGES.PRIVACY_POLICY}</span>
      </a>
      <div className="dot" />
      <a href={`/${ROUTES.TERMS}`}>
        <span>{translations.PAGES.TERMS}</span>
      </a>
    </div>
  );
};

export default SideBarFooter;
