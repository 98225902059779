import { FC, ReactNode } from 'react';
import {
  EyeOff,
  TrashIcon,
  DangerIcon,
  Share,
  Edit,
  Search,
  BellOff,
  Archive,
  Logout,
  UnarchiveIcon,
  BellIcon,
  RemoveTagIcon,
  Shop,
  Message,
  CopyIcon,
} from 'tedooo-web-design-system/icons';
import { Dropdown } from 'tedooo-web-design-system/ui';

import { ChatMenuOptions, MenuOptions, PayoutMenuOptions } from 'types';

export interface IDropdownOptionsMenu {
  onOptionClick(optionKey: string): void;
  options: { key: string; label: string; danger: boolean }[];
  children: ReactNode;
}

const mapMenuOptionToIcon = {
  [MenuOptions.Hide]: <EyeOff />,
  [MenuOptions.HideFromActivity]: <RemoveTagIcon />,
  [MenuOptions.Report]: <DangerIcon />,
  [MenuOptions.Delete]: <TrashIcon />,
  [MenuOptions.Share]: <Share />,
  [MenuOptions.Edit]: <Edit />,
  [MenuOptions.Duplicate]: <CopyIcon />,
  [MenuOptions.GoToShop]: <Shop />,
  [MenuOptions.Message]: <Message />,
  [ChatMenuOptions.Search]: <Search />,
  [ChatMenuOptions.Mute]: <BellOff />,
  [ChatMenuOptions.Unmute]: <BellIcon />,
  [ChatMenuOptions.Leave]: <Logout />,
  [ChatMenuOptions.Archive]: <Archive />,
  [ChatMenuOptions.Unarchive]: <UnarchiveIcon />,
  [PayoutMenuOptions.Deactivate]: <Logout />,
};

const DropdownOptionsMenu: FC<IDropdownOptionsMenu> = ({ onOptionClick, options, children }) => {
  const formattedOptions = options.map((item) => ({
    ...item,
    icon: mapMenuOptionToIcon[item.key],
  }));

  const getPopupContainer = (parentNode: HTMLElement) => parentNode.parentElement || document.body;

  return (
    <Dropdown
      size="medium"
      className="options-menu"
      clickable
      options={formattedOptions}
      onChange={onOptionClick}
      placement="bottomLeft"
      getPopupContainer={getPopupContainer}
    >
      {children}
    </Dropdown>
  );
};

export default DropdownOptionsMenu;
