import {
  FixedPriceOptions,
  ILinkPayoutResponse,
  ProcessingTimeOptions,
  IPackageType,
  IPrice,
  IPriceResponse,
  IShippingAddress,
  IProductDimensions,
  IRecipientAddress,
  IShippingAddressState,
} from 'types';
import { Countries } from 'constant/countries';
import { GetShippingProfilesResponse } from 'graphql/generated/graphql';

export interface IShippingState {
  shippoUrl: string | null;
  isLoading: boolean;
  shippingProfiles: IShippingProfile[];
  availableShippingServices: IShippingServiceGroup[];
  shippingProfileForm: IShippingProfileForm;
  shippingAddressForm: IShippingAddressState;
  mapShippingProfileIdToAffectedListings: { [id: string]: number };
  isLoadingShippingService: boolean;
  isLoadingBuyShippingLabel: boolean;
  isBuyShippingLabelLoading: boolean;
  shippingRates: IShippingRate[] | null;
  disabledShippingRates: IShippingDisabledRate[] | null;
  packagesTypeList: IPackageType[] | null;
  shippingServicePrice: IPrice | null;
  shippingLabelDownloadUrls: IShippingLabelDownloadUrls | null;
  createShippingLabelForm: ICreateShippingLabelForm;
}

export interface ICreateShippingLabelForm {
  packageType: string;
  dimensions: IProductDimensions;
  shippingServiceId: string;
  selectedShippingService: IShippingRate | null;
  hasInsurance: boolean;
  shippingDate: string;
  hasSignature: boolean;
  recipientAddress: IShippingAddress;
}

export interface IShippingLabelDownloadUrls {
  shippingLabelUrl: string;
  packageSlipUrl: string;
  email: string;
}

export interface IShippingProfileForm {
  id: string | null;
  name: string;
  processingTime: { dropdownValue: ProcessingTimeOptions; min: number; max: number };
  shippingPriceOption: ShippingPriceOptions;
  shippingDestinations: IShippingDestination[];
  shippingAddress: IAddressPreview;
}

export interface IShippingApi {
  linkShippo(token: string): Promise<ILinkPayoutResponse>;

  unlinkShippo(token: string): Promise<void>;

  getShippingServices(
    token: string,
    shippingCountry: Countries,
    isCalculated: boolean,
  ): Promise<IGetShippingServicesResponse>;

  validateShippingAddress(
    token: string,
    country: string,
    city: string,
    address: string,
    postalCode: string,
  ): Promise<IValidateShippingAddressResponse>;

  addShippingProfile(token: string, body: IShippingProfileRequest): Promise<{ id: string }>;

  updateShippingProfile(token: string, id: string, body: IShippingProfileRequest): Promise<void>;

  deleteShippingProfile(token: string, id: string): Promise<void>;

  getBasketShippingRates(
    token: string,
    products: IGetShippingRateProduct[],
  ): Promise<IGetBasketShippingRateResponse>;

  getLabelInformation(orderId: string, token: string): Promise<IGetShippingLabelDetailsResponse>;

  getShippingRates(
    orderId: string,
    date: number,
    fromAddress: IShippingRateRequestAddress,
    toAddress: IRecipientAddress,
    dimensions: IProductDimensions,
    withInsurance: boolean,
    withSignature: boolean,
    token: string,
  ): Promise<IGetShippingRatesResponse>;

  buyShippingLabel(
    orderId: string,
    rateId: string,
    token: string,
  ): Promise<IGetShippingLabelUrlsResponse>;

  getPackagesType(token: string): Promise<any>;

  getShippingLabelAndEmail(orderId: string, token: string): Promise<any>;

  sendShippingLabelToEmail(orderId: string, token: string): Promise<any>;

  getShippingProfiles(token: string): Promise<GetShippingProfilesResponse>;
}

export interface IValidateShippingAddressResponse {
  newAddress: string;
  newCity: string;
  newCountry: string;
}

export interface IGetShippingServicesResponse {
  total: number;
  services: IShippingServiceGroup[];
}

export interface IShippingServiceGroup {
  name: string;
  services: IShippingService[];
}

export interface IShippingService {
  id: string;
  name: string;
}

export interface IShippingProfileFixedPrice {
  dropdownValue: FixedPriceOptions;
  firstItem: number;
  additionalItem: number;
}

export interface IShippingProfileDeliveryTime {
  dropdownValue: ProcessingTimeOptions;
  min: number;
  max: number;
}

export interface IShippingProfileDeliveryTimeParserInput {
  min: number;
  max: number;
}

export interface IShippingDestination {
  countries: { type: ShippingCountriesType; values: Countries[] };
  shippingServicesIds: string[];
  isDomesticFreeShipping: boolean;
  isInternationalFreeShipping: boolean;
  deliveryTime: IShippingProfileDeliveryTime;
  fixedPrice: IShippingProfileFixedPrice;
}

export interface IShippingProfileResponse extends IShippingProfileRequest {
  id: string;
}

export interface IShippingProfileRequest {
  name: string;
  origin: IAddressPreview;
  workingDays: { min: number; max: number };
  shippingPriceOption: ShippingPriceOptions;
  shippingDestinations: IShippingDestinationResponse[];
}

export interface IShippingProfilePriceResponse {
  firstItem: number;
  additionalItem: number;
}

export interface IShippingDestinationResponse {
  countries: {
    type: ShippingCountriesType;
    values: Countries[];
  };
  servicesIds: string[];
  isFreeInternational: boolean;
  isFreeDomestic: boolean;
  price?: IShippingProfilePriceResponse;
  deliveryTime?: IShippingProfileDeliveryTime;
}

export interface IAddressPreview {
  country: Countries;
  city: string;
  address: string;
  postalCode: string;
  state: string | null;
}

export enum ShippingPriceOptions {
  CalculatedShipping = 'calculated',
  FixedPrice = 'fixed',
}

export interface IShippingProfile {
  id: string;
  name: string;
  originAddress: IAddressPreview;
  processingTime: { min: number; max: number };
  shippingPriceOption: ShippingPriceOptions;
  shippingDestinations: IShippingDestination[];
}

export enum ShippingCountriesType {
  Specific = 'specific',
  EverywhereElse = 'everywhereElse',
}

export interface IShippingRate {
  id: string;
  attributes: string[];
  providerName: string;
  providerImage: string;
  price: IPrice;
  minimumDeliveryDays: number;
  estimationDeliveryDays: number;
  maximumDeliveryDays: number;
  signaturePrice: IPrice | null;
}

export interface IShippingDisabledRate {
  carrier: string;
  disabledReason: string;
}

export interface IGetShippingRatesResponse {
  rates: IShippingRateResponse[];
  disabledRates: IShippingDisabledRateResponse[];
}

export interface IGetShippingLabelDetailsResponse {
  insurancePrice: number;
  isShippoConnected: boolean;
}

export interface IShippingRateResponse {
  id: string;
  attributes: string[];
  provider: {
    name: string;
    image: string;
  };
  price: IPriceResponse;
  estimation: {
    min: number;
    avg: number;
    max: number;
  };
  signaturePrice: IPriceResponse | null;
}

export interface IShippingDisabledRateResponse {
  carrier: string;
  disabledReason: string;
}

export interface IShippingRateRequestAddress {
  address: string;
  city: string;
  country: string;
  phoneNumber: string;
  postalCode: string;
}

export interface IGetPackagesTypeResponse {
  packageTypes: IPackageTypesResponse[];
}

export interface IPackageTypesResponse {
  name: string;
  carrier: string;
  weightLb: number;
  length: number;
  width: number;
  height: number;
}

export interface IGetShippingLabelUrlsResponse {
  shippingLabelUrl: string | null;
  packageSlipUrl: string | null;
  email: string | null;
}

export interface IGetShippingRateProduct {
  id: string;
  quantity: number;
}

export interface IGetBasketShippingRateResponse {
  rates: IBasketShippingRateResponse[];
}

export interface IGetBasketSupportedItemsResponse {
  statuses: { [productId: string]: ProductAvailabilityItem };
}

export enum ProductAvailabilityItem {
  Available = 'available',
  NotShipping = 'notShipping',
  OutOfStock = 'outOfStock',
  ProductRemoved = 'productRemoved',
}

export interface IBasketShippingRateResponse {
  id: string;
  attributes: BasketRateAttributes[];
  provider: {
    name: string;
    image: string;
  };
  price: IPriceResponse;
  estimatedDeliveryDate: number;
}

export enum BasketRateAttributes {
  BestValue = 'bestValue',
  Fastest = 'fastest',
  Cheapest = 'cheapest',
}
