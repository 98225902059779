import { gql } from '@apollo/client';

export const GET_OFFERS_FOR_REQUEST_QUERY = gql`
  query GetOffersForRequest($requestId: String!, $paginationToken: String, $sort: VisionSorts) {
    getOffersForRequest(requestId: $requestId, paginationToken: $paginationToken, sort: $sort) {
      paginationToken
      offers {
        orderId
        offerId
        partnerInfo {
          id
          username
          avatar
        }
        returnPolicy {
          policy
          title
        }
        price {
          price
          currencyCode
          currency
        }
        imageUrl
        shippingPrice {
          price
          currencyCode
          currency
        }
        returnPolicy {
          policy
          description
        }
        sellerNotes
        category
        deliveryTime {
          max
          min
        }
        bestMatch
        shippingProfileId
        dimensions {
          weightBig
          weightSmall
          width
          height
          length
          metric
        }
        shippingAddress {
          fullName
          shippingAddress {
            city
            country
            state
            address
            postalCode
          }
        }
      }
    }
  }
`;

export const GET_VISION_REQUEST_QUERY = gql`
  query GetVisionRequestById($requestId: String!) {
    getVisionRequestById(requestId: $requestId) {
      id
      orderId
      category
      prompt
      user {
        username
        fullName
      }
      visionImageUrl
      shippingAddress {
        shippingAddress {
          city
          country
          state
          address
          postalCode
        }
      }
      totalOffers
      date
    }
  }
`;

export const DECLINE_OFFER_MUTATION = gql`
  mutation DeclineVisionOffer($offerId: String!, $reason: String!) {
    declineVisionOffer(offerId: $offerId, reason: $reason) {
      success
    }
  }
`;

export const WITHDRAW_OFFER_MUTATION = gql`
  mutation WithdrawVisionOffer($offerId: String!) {
    withdrawVisionOffer(offerId: $offerId) {
      success
    }
  }
`;

export const DELETE_REQUEST_MUTATION = gql`
  mutation DeleteVisionRequest($requestId: String!) {
    deleteVisionRequest(requestId: $requestId) {
      success
    }
  }
`;

export const GET_VISION_OFFER_QUERY = gql`
  query GetVisionOfferById($offerId: String!) {
    getVisionOfferById(offerId: $offerId) {
      orderId
      offerId
      partnerInfo {
        username
        avatar
      }
      returnPolicy {
        policy
        title
      }
      price {
        price
        currencyCode
        currency
      }
      imageUrl
      shippingPrice {
        price
        currencyCode
        currency
      }
      returnPolicy {
        policy
        description
      }
      sellerNotes
      deliveryTime {
        max
        min
      }
      shippingProfileId
      dimensions {
        weightBig
        weightSmall
        width
        height
        length
        metric
      }
      shippingAddress {
        shippingAddress {
          city
          country
          state
          address
          postalCode
        }
      }
    }
  }
`;

export const GET_SELLER_PAYMENT_DETAILS_QUERY = gql`
  query GetShippingRatesForVisionOffer($offerId: String!) {
    getShippingRatesForVisionOffer(offerId: $offerId) {
      paymentOptions {
        stripe {
          id
          imageUrls
          description
          title
          buttonText
        }
        paypal
      }
      rates {
        rateId
        arrivalDate
        name
        tags
        image
        price {
          price
          currencyCode
          currency
        }
      }
      shippingAddress {
        fullName
        shippingAddress {
          city
          country
          state
          address
          postalCode
        }
      }
    }
  }
`;

export const HIDE_VISION_REQUEST_MUTATION = gql`
  mutation HideVisionRequest($requestId: String!) {
    hideVisionRequest(requestId: $requestId) {
      success
    }
  }
`;
