import { FC } from 'react';
import { Button, DatePicker } from 'tedooo-web-design-system/ui';

import { IBirthdayStep } from 'types';
import { translations } from 'translations/en';
import { Image } from 'components/helpers';
import cakeImage from 'photos/cake.png';
import './birthdayStep.scss';
import { addYearsToDate, getDateDiffDays, getDateDiffYears } from 'utils';
import { MINIMUM_USER_AGE } from 'constant';

const BirthdayStep: FC<IBirthdayStep> = ({
  onContinueClick,
  onBackClick,
  birthdayValue,
  onBirthdayChange,
}) => {
  const ONE_YEAR = 1;

  const age = birthdayValue ? getDateDiffYears(birthdayValue, Date.now()) : 0;

  const nextBirthday = birthdayValue ? addYearsToDate(birthdayValue, age + ONE_YEAR) : 0;

  const daysUntilBirthday = birthdayValue ? getDateDiffDays(Date.now(), nextBirthday) : 0;

  return (
    <div className="birthday-container step-container">
      <div className="text-container">
        <Image className="cake-image" src={cakeImage} />
        <span className="title">{translations.ONBOARDING.BIRTHDAY_STEP.WHENS_YOUR_BIRTHDAY}</span>
        <span className="secondary-title">{translations.ONBOARDING.BIRTHDAY_STEP.DONT_WORRY}</span>
      </div>
      <div className="date-picker-wrapper">
        <DatePicker
          placeholder={translations.ONBOARDING.BIRTHDAY_STEP.DATE_OF_BIRTH}
          id="birthDay"
          value={birthdayValue}
          onChange={onBirthdayChange}
          lastDisabledYears={MINIMUM_USER_AGE}
        />
      </div>
      <div className="age-section-text">
        {birthdayValue && (
          <>
            <span className="first-row-age-text">
              You’re <span className="years-span">{age} years</span> old and there’s{' '}
              {daysUntilBirthday}
            </span>
            <span className="second-row-age-text">
              {translations.ONBOARDING.BIRTHDAY_STEP.DAYS_UNTIL_YOUR_BIRTHDAY}
            </span>
          </>
        )}
      </div>
      <Button
        isFullWidth
        type="primary"
        size="large"
        borderRadius="large"
        disabled={!birthdayValue}
        text={translations.COMMON.CONTINUE}
        className="continue-button-birthday"
        onClick={onContinueClick}
      />
      <Button
        isFullWidth
        type="secondary"
        size="large"
        borderRadius="large"
        text={translations.COMMON.BACK}
        className="back-button-birthday"
        onClick={onBackClick}
      />
    </div>
  );
};

export default BirthdayStep;
