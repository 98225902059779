export const ROUTES = {
  HOME: '',
  SIGN: 'sign',
  CREATE_SHOP: 'addbusiness',
  EDIT_SHOP: 'editshopsection',
  NON_SIGNUP_CHECKOUT: 'checkout',
  SHOP: 'shop',
  PRODUCT: 'product',
  MOJOS: 'mojos',
  MOJO: 'mojo',
  SHOPS: 'shops',
  PROFILE: 'profile',
  FOLLOWING: 'following',
  ONE_TIME_PAYMENT: 'p',
  SIMILAR_SHOPS: 'similarshops',
  DISCOVER: 'discover',
  NOTES: 'notes',
  HISTORY: 'history',
  FINISH_SIGN_UP: 'finishsignup',
  APP_CN: 'appcn',
  APP: 'app',
  CHAT: 'chat',
  CHAT_SETTINGS: 'settings',
  EDIT_PROFILE: 'edit',
  CHAT_GROUP: 'group',
  REVIEW: 'review',
  POST: 'posts',
  ORDER: 'offer',
  SETTINGS: 'settings',
  LANGUAGE: 'language',
  USER: 'user',
  SEARCH: 'search',
  ITEMS: 'items',
  ORDERS: 'orders',
  REDIRECT_URL: 'api',
  PRODUCTS: 'products',
  VISIONS: 'visions',
  VISION: 'vision',
  GENERATE_VISION: 'generatevision',
  VISIONS_LIST: 'visionslist',
  MY_REQUESTS: 'requests/buyer',
  BUYER_REQUESTS: 'requests/seller',
  SPECIFIC_VISION_REQUEST: 'request',
  PRIVACY: 'privacy',
  TERMS: 'eula',
  BASKET: 'basket',
};

export const SEARCH_PARAMS = {
  ID: 'id',
  MERCHANT_ID: 'merchantId',
  MERCHANT_ID_IN_PAYPAL: 'merchantIdInPayPal',
  PAYPAL_TOKEN: 'token',
  SHIPPO_SUCCESS: 'success',
  PREMIUM_SUCCESS: 'premiumsuccess',
  PREMIUM_RETURN: 'return',
  SEARCH_TEXT: 'text',
  VISION_CREDITS: 'vision_credits',
  DISCOVER_LIST_PARAMS: {
    TITLE: 'title',
    ID: 'id',
    TOKEN: 'token',
  },
  SHOP_TAB: 'tab',
  POPUP_KEY: 'popup',
};
