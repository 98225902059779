import { FC } from 'react';
import { useDispatch } from 'react-redux';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import './facebookSignInButton.scss';

import { Image } from 'components/helpers';
import facebookIcon from 'photos/facebook-logo.png';
import { facebookLogin } from 'store/user/UserActions';
import { API_KEYS } from 'constant';
import { translations } from 'translations/en';

const FacebookSignInButton: FC = () => {
  const dispatch = useDispatch();

  const onFacebookLogin = (userInfo: ReactFacebookLoginInfo) => {
    const { accessToken: token } = userInfo;

    dispatch(facebookLogin({ token }));
  };

  return (
    <div className="facebook-sign-in-container">
      <FacebookLogin
        appId={API_KEYS.FACEBOOK_CLIENT_ID}
        scope="public_profile,email"
        textButton={translations.SIGN.CONTINUE_WITH_FACEBOOK}
        cssClass="facebook-button"
        disableMobileRedirect
        icon={<Image src={facebookIcon} className="facebook-icon" />}
        callback={onFacebookLogin}
      />
    </div>
  );
};

export default FacebookSignInButton;
