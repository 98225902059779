import { FC } from 'react';
import { FilledStar } from 'tedooo-web-design-system/icons';
import { Span } from 'tedooo-web-design-system/ui';
import './rate.scss';
import classNames from 'classnames';

import { translations } from 'translations/en';
import { toFormat } from 'utils';

interface IRate {
  value: number;
  totalReviews: number;
  shouldDisplayReviews: boolean;
}

const { REVIEWS, REVIEW, NO_REVIEWS } = translations.COMMON;

const Rate: FC<IRate> = ({ value, totalReviews, shouldDisplayReviews }) => {
  const isOneReview = totalReviews === 1;

  const formattedValue = value.toFixed(1);

  const reviewsText = isOneReview ? REVIEW : REVIEWS;

  let formattedReviewsAmount;
  if (shouldDisplayReviews) {
    formattedReviewsAmount = toFormat(totalReviews);
  }

  const totalReviewsText = `${formattedReviewsAmount} ${reviewsText}`;

  const formattedReviewsText = totalReviews ? totalReviewsText : NO_REVIEWS;

  return (
    <div
      className={classNames(
        'rate-container',
        { 'with-reviews': shouldDisplayReviews },
        { 'no-reviews': !totalReviews },
      )}
    >
      {totalReviews !== 0 && (
        <>
          <FilledStar className="icon" />
          <div className="value-container">
            <Span text={formattedValue} className="value" />
            {!shouldDisplayReviews && <span className="value">{translations.COMMON.OF_5}</span>}
          </div>
        </>
      )}

      <div className="dot" />
      {shouldDisplayReviews && <Span text={formattedReviewsText} className="text" />}
    </div>
  );
};

export default Rate;
