import { Fonts } from 'constant/fonts';
import {
  IDiscoverCategory,
  IDiscoverProduct,
  LikeProductSource,
  DiscoverLayoutComponents,
  IDiscoverPageComponent,
  IDiscoverListDetails,
  ProductPageSources,
} from 'types';

export interface IDiscoverPage {
  items: IDiscoverPageComponent[] | null;
  categories: { list: IDiscoverCategory[]; marginTop: number; order: number };
  isLoading: boolean;
  onToggleLikeProduct(input: {
    productId: string;
    isLiked: boolean;
    source: LikeProductSource;
  }): void;
  updateProductPageSource(input: { source: ProductPageSources });
  getDiscoverData(): void;
  showPopup(input: { id: string }): void;
}

export interface IDiscoverSectionTitle {
  text: string;
  color: string;
  hasArrow: boolean;
  font: Fonts;
}

export interface IPremiumBanner {
  marginTop: number;
  onPremiumClick(): void;
}

export interface ISearchContainer {
  marginTop: number;
}

export interface ICategoriesSection {
  categories: IDiscoverCategory[];
  marginTop: number;
  onCategoryClick(category: string): void;
  onSeeAllClick(): void;
}

export interface ILayoutComponentBaseDetails {
  type: DiscoverLayoutComponents;
  listDetails: IDiscoverListDetails | null;
  titles: IDiscoverSectionTitle[];
  products: IDiscoverProduct[];
  backgroundImage: string | null;
  totalRows: number | null;
  gridDetails: IDiscoverLayoutGridDetails | null;
}

export interface IDiscoverLayoutGridDetails {
  topLeft: ILayoutComponentBaseDetails;
  bottomLeft: ILayoutComponentBaseDetails;
  right: ILayoutComponentBaseDetails;
}

export interface IMixedDiscoverLayout {
  type:
    | DiscoverLayoutComponents.MixedLayout1
    | DiscoverLayoutComponents.MixedLayout2
    | DiscoverLayoutComponents.MixedLayout3;
  topLeft: ILayoutComponentBaseDetails;
  bottomLeft: ILayoutComponentBaseDetails;
  right: ILayoutComponentBaseDetails;
  marginTop: number;
}

export interface IMixedDiscoverLayoutProps extends IMixedDiscoverLayout {
  onListClick(options: IDiscoverListDetails): void;
  onLikeClick(productId: string, isLiked: boolean): void;
  onProductClick(id: string): void;
}

export interface IEqualProductGrid {
  type: GridType;
  listDetails: IDiscoverListDetails | null;
  products: IDiscoverProduct[];
  totalRows: number;
  titles: IDiscoverSectionTitle[];
  onLikeClick(productId: string, isLiked: boolean): void;
  onProductClick(id: string): void;
  onListClick(options: IDiscoverListDetails): void;
}

export interface IDiscoverProductsCarousel {
  listDetails: IDiscoverListDetails | null;
  products: IDiscoverProduct[];
  titles: IDiscoverSectionTitle[];
  marginTop: number;
  slidesToShow: number;
  onLikeClick(productId: string, isLiked: boolean): void;
  onProductClick(id: string): void;
  onListClick(options: IDiscoverListDetails): void;
}

export interface ICustomProductsLayout {
  listDetails: IDiscoverListDetails | null;
  products: IDiscoverProduct[];
  titles: IDiscoverSectionTitle[];
  backgroundImage: string | null;
  onListClick(options: IDiscoverListDetails): void;
  onLikeClick(productId: string, isLiked: boolean): void;
  onProductClick(id: string): void;
}

export enum GridType {
  Equal = 'equal',
  OneLarge = 'one-large',
  TwoLarge = 'two-large',
}

export enum MixedLayoutPosition {
  TopLeft = 'top-left',
  BottomLeft = 'bottom-left',
  Right = 'right',
}
