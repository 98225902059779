import { Context, createContext } from 'react';

import { SendMessageRequest, SendMultipleMessages } from './types';

export interface ISocketContext {
  onMultiMessagesSend: (details: SendMultipleMessages) => void;
  onMessagesDelete: (id: string, messagesIds: string[], isGroup: boolean) => void;
  onMessageSend: (details: SendMessageRequest) => void;
  onTypingSend: (id: string, isGroup: boolean) => void;
}

// sendMultiMessages, deleteMessage, sendMessage, sendTyping
// @ts-ignore
export const SocketContext: Context<ISocketContext> = createContext({
  onMultiMessagesSend: () => {},
  onMessageSend: () => {},
  onTypingSend: () => {},
  onMessagesDelete: () => {},
});
