import { NotificationActionTypes } from 'types';

export const getNotificationAction = (url: string): NotificationActionTypes => {
  const urlParts = url.split('/');

  const action = urlParts[2];

  return mapDeepLinksToActions[action] || NotificationActionTypes.Navigate;
};

export const getUserIdFromLink = (url: string): string => {
  const urlParts = url.split('/');

  return urlParts[3];
};

const mapDeepLinksToActions = {
  createpost: NotificationActionTypes.CreatePost,
  follow: NotificationActionTypes.Follow,
  unfollow: NotificationActionTypes.Unfollow,
};
