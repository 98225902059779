import Content from './Content';

export const WithContent =
  (Comp: any): any =>
  () =>
    (
      <Content>
        <Comp />
      </Content>
    );
