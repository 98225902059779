import {
  IOrderActivity,
  IProductDimensions,
  IPrice,
  ITaggedUser,
  OrderActions,
  OrderStatuses,
  PayoutMethods,
  OrderInitialPopupOptions,
  IDiscountDetails,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';

export interface ISpecificOrder {
  orderDetails: IOrderDetails;
  activities: IOrderActivity[];
  initialPopupOption: OrderInitialPopupOptions | null;
  activityPreviewItem: IOrderActivity;
  isPremium: boolean;
  avatar: string;
  token: string;
  showPopup(input: { id: string; orderId: string }): void;
  showMessage(input: { id: number; link: string }): void;
  reset(): void;
  showPopup(input: {
    id: string;
    orderId?: string;
    createPostInitialValues?: { text: string };
    taggedUsers?: ITaggedUser[];
    shopId?: string;
  }): void;
  getOrderDetails(input: { orderId: string; userAvatar: string }): void;
  onWithdrawOrder(input: { orderId: string }): void;
  onAcceptOrder(input: { id: string }): void;
}

export interface IHeader {
  orderNumber: string;
  isInstantCheckout: boolean;
  date: number;
  username: string;
  email: string;
  isAnonymous: boolean;
  shopName: string;
  isDigital: boolean;
  isSeller: boolean;
  orderStatus: OrderStatuses;
  onBackClick(): void;
}

export interface IOrderSidebar {
  orderDetails: IOrderDetails;
  shouldShowWithdrawButton: boolean;
  onTrackOrderClick(): void;
  onWithdrawClick(): void;
  onDownloadLabelAndPackingSlipClick(): void;
}

export enum OrderProgressStep {
  Requested,
  PaymentSent,
  Declined,
  Shipped,
  Delivered,
}

export interface IOrderItem {
  id: string;
  title: string;
  image: string;
  price: IPrice;
  isDigital: boolean;
  originalPrice: IPrice;
  quantity: number;
  customizationRequests: ICustomizationRequest | null;
}

export interface ICustomizationRequest {
  text: string;
  images: string[];
}

export interface IActivityBody {
  items: IOrderItem[];
  hasDigitalFiles: boolean;
  isShopReviewed: boolean;
  subTotalPrice: number;
  shippingPrice: number | null;
  currencyCode: CurrencyCode;
  shippingAddress: string;
  totalDiscount: number;
  totalPrice: number;
  title: string;
  activityStatus: OrderStatuses;
  orderStatus: OrderStatuses;
  isCollapsible: boolean;
  isSeller: boolean;
  discountDetails: IDiscountDetails | null;
  onButtonClick(type: OrderActions): void;
}

export interface IOrderActivitySection {
  activities: IOrderActivity[];
  previewItem: IOrderActivity;
  orderStatus: OrderStatuses;
  isSeller: boolean;
  hasDigitalFiles: boolean;
  expiryDate: number | null;
  isInstantCheckout: boolean;
  hasShippingLabel: boolean;
  isShopReviewed: boolean;
  trackingNumber: string | null;
  shippingAddress: string;
  subTotalPrice: number;
  shippingPrice: number | null;
  totalDiscount: number;
  currencyCode: CurrencyCode;
  lastActivityDate: number;
  totalPrice: number;
  paidPaymentMethod: PayoutMethods | null;
  items: IOrderItem[];
  title: string;
  shopId: string;
  shopName: string;
  partnerUsername: string;
  isAnonymous: boolean;
  selectedShippingService: ISelectedShippingService | null;
  discountDetails: IDiscountDetails | null;
  onButtonClick(type: OrderActions): void;
  onCopyLinkClick(message: string): void;
  onPostClick(text: string, taggedUsers: ITaggedUser[]): void;
}

export interface IPreviewHeader {
  isSeller: boolean;
  avatar: string;
  orderStatus: OrderStatuses;
  selectedShippingService: ISelectedShippingService | null;
  isInstantCheckout: boolean;
  lastActivityDate: number;
  shippingAddress: string;
  expiryDate: number | null;
}

export interface IPreviewBody {
  totalDiscount: number;
  trackingNumber: string | null;
  currencyCode: CurrencyCode;
  subTotalPrice: number;
  shippingPrice: number | null;
  hasDigitalFiles: boolean;
  isInstantCheckout: boolean;
  isSeller: boolean;
  status: OrderStatuses;
  shopId: string;
  shopName: string;
  partnerUsername: string;
  isShopReviewed: boolean;
  hasShippingLabel: boolean;
  selectedShippingService: ISelectedShippingService | null;
  discountDetails: IDiscountDetails | null;
  onButtonClick(type: OrderActions): void;
  onCopyLinkClick(message: string): void;
  onPostClick(text: string, taggedUsers: ITaggedUser[]): void;
}

export interface IOrderDetailsState {
  orderNumber: string;
  shippingAddressName: string;
  totalDiscount: number;
  subTotalPrice: number;
  shippingPrice: number | null;
  totalPrice: number;
  status: OrderStatuses;
  currencyCode: CurrencyCode;
  items: IOrderItem[];
  creationDate: number;
  isSeller: boolean;
  shippingProviderImage: string | null;
  partnerDetails: IOrderPartnerDetails;
  lastActivityDate: number;
  paidPaymentMethod: PayoutMethods | null;
  isInstantCheckout: boolean;
  shopName: string;
  title: string;
  trackingNumber: string | null;
  shopId: string;
  isShopReviewed: boolean;
  linkedPaymentOptions: ILinkedPaymentOption[];
  originalPrice: IPrice;
  payoutOptions: IPayoutOptions[];
  packingSlipUrl: string | null;
  shippingLabelUrl: string | null;
  originAddress: IOriginAddress | null;
  shippingLabelDetails: IShippingLabelDetails | null;
  selectedShippingService: ISelectedShippingService | null;
  recipientAddress: IRecipientAddress | null;
  dimensions: IProductDimensions | null;
  hasDigitalFiles: boolean;
  expiryDate: number | null;
  isAnonymous: boolean;
  discountDetails: IDiscountDetails | null;
}

export interface IOrderDetails extends IOrderDetailsState {
  id: string;
}

export interface ILinkedPaymentOption {
  type: PayoutMethods;
  ids: string[];
  title: string;
  images: string[];
}

export interface IRecipientAddress {
  address: string;
  city: string | null;
  state: string | null;
  country: Countries | null;
  postalCode: string | null;
  phoneNumber: string | null;
}

export interface ISelectedShippingService {
  name: string;
  image: string;
  price: IPrice;
  arrivalDate: number;
  canCreateShippingLabel: boolean;
}

export interface IShippingLabelDetails {
  insurancePrice: number;
}

export interface IOriginAddress {
  address: string;
  city: string;
  country: string;
  postalCode: string;
  phoneNumber: string;
}

export interface IPayoutOptions {
  info: string;
  provider: string;
}

export interface IOrderPartnerDetails {
  username: string;
  avatar: string;
  email: string;
  coverPhoto: string | null;
  id: string;
  fullName: string;
  isAnonymous: boolean;
}

export enum OrderSidebarDropdownOptions {
  ViewProfile = 'viewProfile',
  SendMessage = 'sendMessage',
  Withdraw = 'withdraw',
}
