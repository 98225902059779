import { fetcher } from 'api';
import {
  ICreateShopApi,
  ICreateShopProduct,
  ICreateShopProductFromCsv,
  ICreateShopRequest,
  ICreateShopResponse,
  IdGenericResponse,
  IUpdateShopBody,
  Method,
} from 'types';
import {
  DecodeProductCsvProgressResponse,
  DecodeProductCsvResponse,
} from '../../graphql/generated/graphql';
import { graphQlRequest } from '../../api/graphQl';
import { DECODE_PRODUCTS_CSV, GET_PRODUCT_CSV_PROGRESS } from './CreateShopQueries';
import { splitArrayIntoChunks } from '../../utils';

export class CreateShopApi implements ICreateShopApi {
  createShop(token: string, body: ICreateShopRequest): Promise<ICreateShopResponse> {
    return fetcher(`v4/shops`, Method.POST, token, body);
  }

  async uploadProductsFromCsv(
    shopId: string,
    products: ICreateShopProductFromCsv[],
    token: string,
  ): Promise<string[]> {
    const queue = splitArrayIntoChunks(products, 2);

    const results: string[] = [];

    while (queue.length > 0) {
      const batch = queue.splice(0, 5);

      // eslint-disable-next-line no-await-in-loop
      const batchResults = await Promise.all(
        batch.map((productsBatch) =>
          fetcher(`v3/products/multi/shop/${shopId}`, Method.POST, token, {
            products: productsBatch,
          })
            .then((res) => res.ids)
            .catch(() => productsBatch.map(() => '')),
        ),
      );
      results.push(...batchResults);
    }

    return results.flatMap((res) => res);
  }

  addItemToCatalog(
    token: string,
    shopId: string,
    body: ICreateShopProduct,
  ): Promise<IdGenericResponse> {
    return fetcher(`v3/products/shop/${shopId}`, Method.POST, token, body);
  }

  editSpecificListing(token: string, productId: string, body: ICreateShopProduct): Promise<void> {
    return fetcher(`v3/products/${productId}`, Method.PATCH, token, body);
  }

  getProductCategories(token: string): Promise<{ categories: string[][] }> {
    return fetcher(`v3/products/categories`, Method.GET, token);
  }

  updateShop(token: string, shopId: string, body: IUpdateShopBody): Promise<void> {
    return fetcher(`v3/shops/${shopId}`, Method.PATCH, token, body);
  }

  async getUploadingCsvStatus(
    token: string,
    requestId: string,
  ): Promise<DecodeProductCsvProgressResponse> {
    const variables = { requestId };

    const res = await graphQlRequest(token).query({
      query: GET_PRODUCT_CSV_PROGRESS,
      variables,
    });

    const { data } = res;

    return data.getProductCsvProgress;
  }

  async getProductsFromCsv(
    token: string,
    csv: string,
    currencyCode: string,
  ): Promise<DecodeProductCsvResponse> {
    const variables = { csv, currencyCode };

    const res = await graphQlRequest(token).query({
      query: DECODE_PRODUCTS_CSV,
      variables,
    });

    const { data } = res;

    return data.decodeProductCsv;
  }
}
