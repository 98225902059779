import { FC } from 'react';

import './menuButtons.scss';
import { mapMenuOptionToIcon, mapMenuOptionToRoute, mapMenuOptionToText } from './helper';
import MenuItem from '../menuItem/MenuItem';
import { MenuButtonOptions } from '../types';
import { ROUTES } from 'constant';

export interface IMenuButtons {
  options: MenuButtonOptions[];
  selectedRoute: string;
  totalUnreadMessages: number;
  totalOrders: number;
  onClick(route: string): void;
}

const MenuButtons: FC<IMenuButtons> = ({
  options,
  selectedRoute,
  totalUnreadMessages,
  totalOrders,
  onClick,
}) => {
  const mapPropsToTotalNotifications = {
    [MenuButtonOptions.Home]: 0,
    [MenuButtonOptions.Messaging]: totalUnreadMessages,
    [MenuButtonOptions.Discover]: 0,
    [MenuButtonOptions.Orders]: totalOrders,
    [MenuButtonOptions.Notification]: 0,
  };

  const renderButton = (type: MenuButtonOptions) => {
    const route = mapMenuOptionToRoute[type];

    const text = mapMenuOptionToText[type];

    const icon = mapMenuOptionToIcon[type];

    const totalNotifications = mapPropsToTotalNotifications[type];

    const isRoutePrefix = route !== ROUTES.HOME && selectedRoute.startsWith(route);

    const isSelected = selectedRoute === route || isRoutePrefix;

    return (
      <MenuItem
        text={text}
        Icon={icon}
        key={`${type}-${route}`}
        isSelected={isSelected}
        onClick={() => onClick(route)}
        totalNotifications={totalNotifications}
      />
    );
  };

  return <div className="menu-buttons">{options.map(renderButton)}</div>;
};

export default MenuButtons;
