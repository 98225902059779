import { Notification } from 'tedooo-web-design-system/ui';

import { useTedoooSelector } from 'store/Store';
import { MessageSelector } from 'store/message/MessageSelectors';
import { ServerStatus } from 'types';
import { translations } from 'translations/en';

const { POST_REMOVED_FROM_ACTIVITY_SUCCESS, POST_REMOVED_FROM_ACTIVITY_FAIL } =
  translations.FEED.POST;

const HideFromActivityMessage = () => {
  const serverStatus = useTedoooSelector(MessageSelector.serverStatusSelector);

  if (serverStatus === ServerStatus.SUCCESS) {
    return (
      <Notification
        type="primary-success"
        text={POST_REMOVED_FROM_ACTIVITY_SUCCESS}
        placement="top"
      />
    );
  }
  if (serverStatus === ServerStatus.ERROR) {
    return <Notification type="error" text={POST_REMOVED_FROM_ACTIVITY_FAIL} placement="top" />;
  }

  return null;
};

export default HideFromActivityMessage;
