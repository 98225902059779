import { fetcher } from 'api';
import {
  ICommentItemResponseApi,
  ICommentsResponseApi,
  IGetRepostsResponse,
  IGetShopImageResponse,
  ILikesResponseApi,
  ISpecificPostApi,
  ISpecificPostResponseApi,
  Method,
} from 'types';

export class SpecificPostApi implements ISpecificPostApi {
  onLike(postId: string, isLiked: boolean, token: string): Promise<void> {
    const method = isLiked ? Method.DELETE : Method.POST;

    return fetcher(`posts/like/${postId}`, method, token);
  }

  onLikeComment(commentId: string, isLiked: boolean, token: string): Promise<void> {
    const method = isLiked ? Method.POST : Method.DELETE;

    return fetcher(`v2/posts/like/comment/${commentId}`, method, token, undefined);
  }

  getComments(
    postId: string,
    limit: number,
    maxTime?: number,
    token?: string,
  ): Promise<ICommentsResponseApi> {
    const query: any = { limit };

    if (maxTime) {
      query.maxTime = maxTime;
    }

    return fetcher(`posts/v2/comments/${postId}`, Method.GET, token, undefined, query);
  }

  getReplies(
    postId: string,
    commentId: string,
    token?: string,
  ): Promise<Array<ICommentItemResponseApi>> {
    return fetcher(`posts/replies/${postId}/${commentId}`, Method.GET, token);
  }

  onAddComment(
    postId: string,
    text: string,
    tags: string[],
    token: string,
    image?: string,
  ): Promise<ICommentItemResponseApi> {
    const body: any = {
      tags,
      text,
      image,
    };

    return fetcher(`posts/v2/comment/${postId}`, Method.POST, token, body);
  }

  onAddReply(
    postId: string,
    parentCommentId: string,
    text: string,
    tags: string[],
    token: string,
    image?: string,
  ): Promise<ICommentItemResponseApi> {
    const body = {
      text,
      parentCommentId,
      tags,
      image,
    };

    return fetcher(`posts/v2/comment/${postId}`, Method.POST, token, body);
  }

  getPostLikes(
    postId: string,
    token: string,
    limit: number,
    maxId?: string,
  ): Promise<ILikesResponseApi> {
    const query = {
      maxId,
      limit,
    };

    return fetcher(`posts/like/${postId}`, Method.GET, token, undefined, query);
  }

  getPostReposts(postId: string, token?: string): Promise<IGetRepostsResponse> {
    return fetcher(`posts/reposts/${postId}`, Method.GET, token);
  }

  getCommentLikes(
    commentId: string,
    token: string,
    limit: number,
    maxId?: string,
  ): Promise<ILikesResponseApi> {
    const query = {
      maxId,
      limit,
    };

    return fetcher(`posts/v2/comment/like/${commentId}`, Method.GET, token, undefined, query);
  }

  deleteComment(commentId: string, token: string): Promise<void> {
    return fetcher(`posts/comment/${commentId}`, Method.DELETE, token);
  }

  getSpecificPost(postId: string, token?: string): Promise<ISpecificPostResponseApi> {
    return fetcher(`posts/v2/notification/${postId}`, Method.GET, token);
  }

  getShopImage(shopId: string, token?: string): Promise<IGetShopImageResponse> {
    return fetcher(`v2/shops/image/${shopId}`, Method.GET, token);
  }
}
