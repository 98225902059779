import './mainGoalStep.scss';
import { Button } from 'tedooo-web-design-system/ui';
import { FC } from 'react';

import { translations } from 'translations/en';
import GoalCard from './goalCard/GoalCard';
import { IMainGoalStep, UserProfile } from 'types';
import {
  mapUserProfileToTitle,
  mapUserProfileToDescription,
  mapUserProfileToImage,
} from '../helper';

const MainGoalStep: FC<IMainGoalStep> = ({
  onContinueClick,
  onBackClick,
  selectedCard,
  onCardClick,
}) => {
  const order = [UserProfile.Seller, UserProfile.Buyer];

  const renderGoalCards = () => {
    return order.map((type) => (
      <GoalCard
        key={type}
        profileType={type}
        image={mapUserProfileToImage[type]}
        title={mapUserProfileToTitle[type]}
        description={mapUserProfileToDescription[type]}
        onClick={() => onCardClick(type)}
        isSelected={selectedCard === type}
      />
    ));
  };

  return (
    <div className="main-goal-container step-container">
      <div className="text-container">
        <span className="title">{translations.ONBOARDING.MAIN_GOAL_STEP.TITLE}</span>
        <span className="secondary-title">
          {translations.ONBOARDING.MAIN_GOAL_STEP.SECONDARY_TITLE}
        </span>
      </div>
      <div className="cards-container">{renderGoalCards()}</div>
      <div className="buttons-container">
        <Button
          isFullWidth
          type="primary"
          size="large"
          borderRadius="large"
          disabled={!selectedCard}
          text={translations.COMMON.CONTINUE}
          className="continue-button-birthday"
          onClick={onContinueClick}
        />
        <Button
          isFullWidth
          type="secondary"
          size="large"
          borderRadius="large"
          text={translations.COMMON.BACK}
          className="back-button-birthday"
          onClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default MainGoalStep;
