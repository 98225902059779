import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ISettingsState, ServerStatus } from 'types';
import {
  addShippingAddress,
  checkPassword,
  deleteShippingAddress,
  getAccountPreferences,
  getBlockedUsers,
  getPostNotifications,
  getShippingAddresses,
  getShopInformationSettings,
  getVacationModeDetails,
  setDefaultAddress,
  updateLanguage,
  updateLastSeen,
  updateLiveTranslations,
  updateLocalTime,
  updateNotifications,
  updatePassword,
  updateShippingAddress,
  updateUserCurrency,
  updateUserDetails,
  updateVacationMode,
} from './SettingsActions';
import {
  parseAccountPreferencesResponse,
  parseBlockingResponse,
  parseEmailNotificationsResponse,
  parsePushNotificationsResponse,
  parseShippingAddress,
} from '../apiParser';
import { checkUsernameAvailability } from '../user/UserActions';
import { USERNAME_EXISTS_ERROR_MESSAGE } from '../user/helper';
import { translations } from 'translations/en';
import { isEmptyArrayOrUndefined } from 'utils';

const initialState: ISettingsState = {
  blockedUsers: null,
  pushNotifications: null,
  emailNotifications: null,
  accountPreferences: null,
  passwordServerStatus: null,
  usernameServerErrorMessage: null,
  shippingAddresses: null,
  deleteAccountErrorMessage: null,
  currencyServerStatus: null,
  payouts: null,
  vacationModeDetails: null,
  isLoading: false,
};

export const SettingsSlicer = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    reset: (state) => {
      state.passwordServerStatus = null;
      state.usernameServerErrorMessage = null;
    },
    updateBlockedUsers: (state, action) => {
      state.blockedUsers = action.payload.blockedUsers;
    },
    updateUserCurrencyCode: (state, action) => {
      const { userCurrencyCode } = action.payload;
      state.accountPreferences!.currencyCode = userCurrencyCode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShopInformationSettings.fulfilled, (state, action) => {
      const { payments } = action.payload;
      state.payouts = Object.entries(payments).map(([key]: any) => key);
    });
    builder.addCase(getPostNotifications.fulfilled, (state, action) => {
      const response = action.payload;

      const email = state.accountPreferences?.email || null;
      state.pushNotifications = parsePushNotificationsResponse(response);
      state.emailNotifications = parseEmailNotificationsResponse(response, email);
    });

    builder.addCase(getBlockedUsers.fulfilled, (state, action) => {
      const parsedData = action.payload.map((user) => parseBlockingResponse(user));
      state.blockedUsers = parsedData;
    });

    builder.addCase(getAccountPreferences.fulfilled, (state, action) => {
      const parsedData = parseAccountPreferencesResponse(action.payload);

      if (state.accountPreferences === null) {
        state.accountPreferences = parsedData;
      }
    });

    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      if (state.accountPreferences) {
        state.accountPreferences.language = action.meta.arg.selectedLanguage;
      }
    });

    builder.addCase(updatePassword.rejected, (state) => {
      state.passwordServerStatus = ServerStatus.ERROR;
    });

    builder.addCase(updatePassword.fulfilled, (state) => {
      state.passwordServerStatus = ServerStatus.SUCCESS;
    });

    builder.addCase(updateUserCurrency.fulfilled, (state) => {
      state.currencyServerStatus = ServerStatus.SUCCESS;
    });

    builder.addCase(checkPassword.rejected, (state) => {
      state.deleteAccountErrorMessage =
        translations.SETTINGS.POPUPS.DELETE_ACCOUNT.INCORRECT_PASSWORD;
    });

    builder.addCase(updateNotifications.fulfilled, (state, action) => {
      const {
        type: typeToUpdate,
        emailNotifications: isEmailEnabled,
        pushNotifications: isPushEnabled,
      } = action.meta.arg;

      const { pushNotifications, emailNotifications } = state;

      if (pushNotifications) {
        pushNotifications[typeToUpdate] = isPushEnabled;
      }
      if (emailNotifications) {
        emailNotifications[typeToUpdate] = isEmailEnabled;
      }
    });

    builder.addCase(checkUsernameAvailability.pending, (state) => {
      state.usernameServerErrorMessage = null;
    });

    builder.addCase(checkUsernameAvailability.rejected, (state, action) => {
      const { message } = action.error;
      state.usernameServerErrorMessage = message || USERNAME_EXISTS_ERROR_MESSAGE;
    });

    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      const { email, birthday } = action.meta.arg;

      if (state.accountPreferences) {
        if (birthday) {
          state.accountPreferences.birthday = birthday;
        }
        if (email) {
          state.accountPreferences.email = email;
        }
      }
    });

    builder.addCase(getShippingAddresses.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.shippingAddresses = data.map((address) => parseShippingAddress(address));
    });

    builder.addCase(addShippingAddress.fulfilled, (state, action) => {
      const { shippingAddress } = action.meta.arg;

      const { id } = action.payload;

      const shippingAddressToAdd = { ...shippingAddress, id };

      if (state.shippingAddresses) {
        state.shippingAddresses.forEach((address) => {
          address.isDefault = false;
        });
        state.shippingAddresses.push(shippingAddressToAdd);
        if (state.accountPreferences) {
          state.accountPreferences.defaultAddress = shippingAddressToAdd;
        }
      }
    });

    builder.addCase(updateShippingAddress.fulfilled, (state, action) => {
      const { shippingAddress: updatedShippingAddress } = action.meta.arg;

      if (state.shippingAddresses) {
        state.shippingAddresses = state.shippingAddresses.map((address) => {
          if (address.id === updatedShippingAddress.id) {
            return updatedShippingAddress;
          }

          return address;
        });
      }
    });

    builder.addCase(deleteShippingAddress.fulfilled, (state, action) => {
      const { shippingId } = action.meta.arg;

      if (state.shippingAddresses) {
        const indexToRemove = state.shippingAddresses.findIndex(
          (address) => address.id === shippingId,
        );

        if (indexToRemove !== -1) {
          state.shippingAddresses.splice(indexToRemove, 1);
        }
      }
      if (state.accountPreferences?.defaultAddress?.id === shippingId) {
        state.accountPreferences.defaultAddress = null;
        if (state.shippingAddresses && !isEmptyArrayOrUndefined(state.shippingAddresses)) {
          state.shippingAddresses[0].isDefault = true;
          state.accountPreferences.defaultAddress = state.shippingAddresses[0];
        }
      }
    });

    builder.addCase(getVacationModeDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getVacationModeDetails.fulfilled, (state, action) => {
      const { shopMessage, autoReplyMessage, isActive } = action.payload;
      state.isLoading = false;

      if (isActive) {
        state.vacationModeDetails = {
          shopMessage: shopMessage || '',
          autoReplyMessage: autoReplyMessage || '',
        };
      } else {
        state.vacationModeDetails = null;
      }
    });

    builder.addCase(updateVacationMode.fulfilled, (state, action) => {
      const { details } = action.meta.arg;

      state.vacationModeDetails = details;
    });

    builder.addMatcher(
      isAnyOf(addShippingAddress.fulfilled, updateShippingAddress.fulfilled),
      (state, action) => {
        const { shippingAddress } = action.meta.arg;

        if (state.accountPreferences && shippingAddress.isDefault) {
          if (state.shippingAddresses) {
            const previousDefaultAddress = state.shippingAddresses.find(
              (address) => address.id === state.accountPreferences?.defaultAddress?.id,
            );
            if (previousDefaultAddress) {
              if (previousDefaultAddress.id !== shippingAddress.id) {
                previousDefaultAddress.isDefault = false;
              }
            }
          }
          state.accountPreferences.defaultAddress = shippingAddress;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(setDefaultAddress.pending, setDefaultAddress.rejected),
      (state, action) => {
        const { shippingAddress, previousAddressId } = action.meta.arg;

        const newDefaultAddressId = shippingAddress.id;

        if (state.shippingAddresses) {
          const newDefaultIndex = state.shippingAddresses.findIndex(
            (address) => address.id === newDefaultAddressId,
          );

          const previousDefaultIndex = state.shippingAddresses.findIndex(
            (address) => address.id === previousAddressId,
          );
          if (previousDefaultIndex !== -1) {
            state.shippingAddresses[previousDefaultIndex].isDefault = false;
          }
          if (newDefaultIndex !== -1) {
            state.shippingAddresses[newDefaultIndex].isDefault = true;
          }
        }
      },
    );

    builder.addMatcher(
      isAnyOf(updateLiveTranslations.pending, updateLiveTranslations.rejected),
      (state) => {
        if (state.accountPreferences !== null) {
          state.accountPreferences.isLiveTranslationEnabled =
            !state.accountPreferences.isLiveTranslationEnabled;
        }
      },
    );

    builder.addMatcher(isAnyOf(updateLocalTime.pending, updateLocalTime.rejected), (state) => {
      if (state.accountPreferences !== null) {
        state.accountPreferences.isLocalTimeEnabled = !state.accountPreferences.isLocalTimeEnabled;
      }
    });

    builder.addMatcher(isAnyOf(updateLastSeen.pending, updateLastSeen.rejected), (state) => {
      if (state.accountPreferences !== null) {
        state.accountPreferences.isLastSeenEnabled = !state.accountPreferences.isLastSeenEnabled;
      }
    });
  },
});
