import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IOrdersState, OrderStatuses, PayoutMethods } from 'types';
import {
  getBuyerOrders,
  getSellerOrders,
  getShopViewers,
  loadMoreOrders,
  reviewOrder,
  withdrawOrder,
} from './OrdersActions';
import {
  parseOrderDetails,
  parseOrders,
  parseShopViewers,
  parseUserOrdersStatistics,
} from '../apiParser';
import {
  acceptOrder,
  addShippingCost,
  declineOrder,
  markOrderAsArrived,
  markPaymentAsReceived,
  markPaymentAsSent,
  saveTrackingNumber,
} from '../specificOrder/SpecificOrderActions';
import { addOrderToList, getSpecificOrder, removeOrderFromList } from './helper';
import { getStripePaymentConfirmation } from '../payouts/PayoutsActions';
import { changePage } from 'utils';
import { ROUTES } from 'constant';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { buyShippingLabel } from '../shipping/ShippingActions';

const initialState: IOrdersState = {
  sellerOrders: null,
  buyerOrders: null,
  isSellerMode: true,
  sellerOrderStatistics: null,
  buyerOrderStatistics: null,
  shopViewers: [],
};

export const OrdersSlicer = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    reset: () => initialState,
    initUserMode: (state, action) => {
      const { shopId } = action.payload;
      state.isSellerMode = !!shopId;
    },
    updatePaidPaymentMethod: (state, action) => {
      const { orderId } = action.payload;

      if (state.buyerOrders) {
        const { list } = state.buyerOrders.todoOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.PaymentReceived;
          specificOrder.paidPaymentMethod = PayoutMethods.Paypal;
          removeOrderFromList(state.buyerOrders.todoOrders, orderId);
          addOrderToList(state.buyerOrders.awaitingOrders, specificOrder);
        }
      }
    },
    updateSellerMode: (state) => {
      state.isSellerMode = !state.isSellerMode;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSellerOrders.fulfilled, (state, action) => {
      const { totalCompletedOrders, totalDeclinedOrders } = action.payload;

      const { todoOrders, pastOrders, awaitingOrders, shippedOrders } = parseOrders(action.payload);

      state.sellerOrders = {
        todoOrders,
        pastOrders,
        awaitingOrders,
        shippedOrders,
        totalDeclinedOrders,
        totalCompletedOrders,
      };

      const totalActiveOrders =
        todoOrders.totalCount + awaitingOrders.totalCount + shippedOrders.totalCount;

      state.sellerOrderStatistics = parseUserOrdersStatistics(
        action.payload,
        totalActiveOrders,
        shippedOrders.totalCount,
      );
    });
    builder.addCase(getBuyerOrders.fulfilled, (state, action) => {
      const { totalCompletedOrders, totalDeclinedOrders } = action.payload;

      const { todoOrders, pastOrders, awaitingOrders, shippedOrders } = parseOrders(action.payload);

      state.buyerOrders = {
        todoOrders,
        pastOrders,
        awaitingOrders,
        shippedOrders,
        totalDeclinedOrders,
        totalCompletedOrders,
      };

      const totalActiveOrders =
        todoOrders.totalCount + awaitingOrders.totalCount + shippedOrders.totalCount;

      state.buyerOrderStatistics = parseUserOrdersStatistics(
        action.payload,
        totalActiveOrders,
        shippedOrders.totalCount,
      );
    });
    builder.addCase(loadMoreOrders.fulfilled, (state, action) => {
      const { offers } = action.payload;

      const parsedOrders = offers.map((order) => parseOrderDetails(order));

      const ordersMode = state.isSellerMode ? state.sellerOrders : state.buyerOrders;

      if (ordersMode) {
        ordersMode.pastOrders.list = [...ordersMode.pastOrders.list, ...parsedOrders];
      }
    });
    builder.addCase(getShopViewers.fulfilled, (state, action) => {
      const parsedData = parseShopViewers(action.payload);

      const prevShopViewers = state.shopViewers || [];
      state.shopViewers = [...prevShopViewers, ...parsedData];
    });
    builder.addCase(withdrawOrder.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;

      if (state.buyerOrders) {
        const { list } = state.buyerOrders.awaitingOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          removeOrderFromList(state.buyerOrders.awaitingOrders, orderId);
        }
      }
    });
    builder.addCase(addShippingCost.fulfilled, (state, action) => {
      const { id, shippingPrice, items } = action.meta.arg;

      if (state.sellerOrders) {
        const { list } = state.sellerOrders.todoOrders;

        const specificOrder = getSpecificOrder(list, id);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.Offered;
          specificOrder.subTotalPrice = items.reduce((acc, item) => acc + item.price.value, 0);
          specificOrder.shippingPrice = shippingPrice;
          specificOrder.totalPrice = specificOrder.subTotalPrice + shippingPrice;
          removeOrderFromList(state.sellerOrders.todoOrders, id);
          addOrderToList(state.sellerOrders.awaitingOrders, specificOrder);
        }
      }
    });
    builder.addCase(declineOrder.fulfilled, (state, action) => {
      const { orderId, isSeller } = action.meta.arg;

      if (isSeller) {
        if (state.sellerOrders) {
          const { list } = state.sellerOrders.todoOrders;

          const specificOrder = getSpecificOrder(list, orderId);

          if (specificOrder) {
            specificOrder.status = OrderStatuses.Decline;
            removeOrderFromList(state.sellerOrders.todoOrders, orderId);
            addOrderToList(state.sellerOrders.pastOrders, specificOrder);
          }
        }
      } else if (state.buyerOrders) {
        const { list } = state.buyerOrders.todoOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.Decline;
          removeOrderFromList(state.buyerOrders.todoOrders, orderId);
          addOrderToList(state.buyerOrders.pastOrders, specificOrder);
        }
      }
    });
    builder.addCase(markPaymentAsSent.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      if (state.sellerOrders) {
        const { list } = state.sellerOrders.todoOrders;

        const specificOrder = getSpecificOrder(list, id);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.PaymentSent;
        }
      }
      changePage(`/${ROUTES.ORDER}/${id}`);
    });
    builder.addCase(saveTrackingNumber.fulfilled, (state, action) => {
      const { id, trackingNumber } = action.meta.arg;

      if (state.sellerOrders) {
        const { todoOrders, shippedOrders } = state.sellerOrders;

        const specificOrder =
          getSpecificOrder(todoOrders.list, id) || getSpecificOrder(shippedOrders.list, id);

        if (specificOrder) {
          specificOrder.trackingNumber = trackingNumber;

          if (specificOrder.status !== OrderStatuses.Shipped) {
            specificOrder.status = OrderStatuses.Shipped;
            removeOrderFromList(state.sellerOrders.todoOrders, id);
            addOrderToList(state.sellerOrders.shippedOrders, specificOrder);
            AnalyticsEvents.onSkipCreateShippingLabel();
            AnalyticsEvents.onOrderStatusChange(OrderStatuses.Shipped, specificOrder.id);
          }
        }
      }
    });
    builder.addCase(buyShippingLabel.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;

      const { packageSlipUrl, shippingLabelUrl } = action.payload;

      if (state.sellerOrders) {
        const { todoOrders } = state.sellerOrders;

        const specificOrder = getSpecificOrder(todoOrders.list, orderId);

        if (specificOrder) {
          specificOrder.trackingNumber = null;
          specificOrder.packingSlipUrl = packageSlipUrl;
          specificOrder.shippingLabelUrl = shippingLabelUrl;

          specificOrder.status = OrderStatuses.Shipped;
          removeOrderFromList(state.sellerOrders.todoOrders, orderId);
          addOrderToList(state.sellerOrders.shippedOrders, specificOrder);
        }
      }
    });
    builder.addCase(markOrderAsArrived.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;

      if (state.buyerOrders) {
        const { list } = state.buyerOrders.shippedOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.Complete;
          removeOrderFromList(state.buyerOrders.shippedOrders, orderId);
          addOrderToList(state.buyerOrders.pastOrders, specificOrder);
        }
      }
    });
    builder.addCase(reviewOrder.fulfilled, (state, action) => {
      const { orderId } = action.meta.arg;

      if (state.buyerOrders) {
        const { list } = state.buyerOrders.pastOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          specificOrder.isShopReviewed = true;
        }
      }
    });
    builder.addCase(getStripePaymentConfirmation.fulfilled, (state, action) => {
      const { orderId } = action.payload;

      if (state.buyerOrders) {
        const { list } = state.buyerOrders.todoOrders;

        const specificOrder = getSpecificOrder(list, orderId);

        if (specificOrder) {
          specificOrder.status = OrderStatuses.PaymentReceived;
          specificOrder.paidPaymentMethod = PayoutMethods.Stripe;
          removeOrderFromList(state.buyerOrders.todoOrders, orderId);
          addOrderToList(state.buyerOrders.awaitingOrders, specificOrder);
        }
      }
    });
    builder.addMatcher(
      isAnyOf(markPaymentAsReceived.fulfilled, acceptOrder.fulfilled),
      (state, action) => {
        const { id } = action.meta.arg;

        if (state.sellerOrders) {
          const { list } = state.sellerOrders.todoOrders;

          const specificOrder = getSpecificOrder(list, id);

          if (specificOrder) {
            specificOrder.status = OrderStatuses.PaymentReceived;
          }
        }
      },
    );
  },
});
