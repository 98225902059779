import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ShopReducer } from './ShopReducer';
import { ICheckoutItem, IShippingAddress, IShopState, IStore2 } from 'types';
import {
  parseBasketShippingRates,
  parseGetFollowers,
  parseMojoList,
  parseProducts,
  parseShopResponse,
  parseShopReviews,
} from '../apiParser';
import { onFollowUserToggle } from '../profile/ProfileActions';
import { requestOrder } from '../specificOrder/SpecificOrderActions';
import {
  superAdminDeleteProduct,
  superAdminMarkAsCrystalShop,
} from '../searchResult/SearchResultActions';
import { getRequestToBuyBasketShippingRates } from '../shipping/ShippingActions';
import { onProductLikeToggle } from '../specificProduct/SpecificProductActions';
import { updateProductLikes } from '../specificProduct/helper';
import { MojosApi } from '../mojos/MojosApi';

const initialState: IShopState = {
  id: '',
  isLoading: false,
  details: null,
  fullCatalog: null,
  mojos: null,
  reviews: null,
  followers: [],
  basketShippingRates: null,
  isRatesLoading: false,
  totalSavedProducts: 0,
};

const reducer = new ShopReducer();

const mojoReducer = new MojosApi();

export const getShop = createAsyncThunk(
  'async/getShop',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { id } = input;

    const { token } = state.user;

    return reducer.getShop(id, token);
  },
);

export const getShopInitialReviews = createAsyncThunk(
  'async/getShopInitialReviews',
  async (_, { getState }: any) => {
    const state: IStore2 = getState();

    const id = state.shop.details?.id || '';

    const { token } = state.user;

    return reducer.getInitialReviews(id, token || '');
  },
);

export const getCatalog = createAsyncThunk(
  'async/getCatalog',
  async (input: { shopId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { shopId } = input;

    return reducer.getCatalog(shopId, token || '');
  },
);

export const editCoverPhoto = createAsyncThunk(
  'async/editCoverPhoto',
  async (input: { coverPhoto: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { details } = state.shop;

    const shopId = details?.id || '';

    return reducer.editCoverPhoto(input.coverPhoto, shopId, token || '');
  },
);

export const getFollowers = createAsyncThunk('async/getFollowers', async (_, { getState }: any) => {
  const state: IStore2 = getState();

  const { token } = state.user;

  const { followers } = state.shop;

  const date = followers[followers.length - 1]?.time;

  return reducer.getFollowers(date, token || '');
});

export const getOrderShopId = createAsyncThunk(
  'async/getOrderShopId',
  async (
    input: {
      shippingAddress: IShippingAddress;
      items: ICheckoutItem[];
      selectedDeliveryId: string | null;
      couponCode: string | null;
    },
    { getState }: any,
  ) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const { id } = input.items[0];

    return reducer.getProduct(id, token || '');
  },
);

export const deleteProduct = createAsyncThunk(
  'async/deleteProduct',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.deleteProduct(token, input.id);
  },
);

export const getProductLink = createAsyncThunk(
  'async/getProductLink',
  async (input: { productId: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    return reducer.getProductLink(token, input.productId);
  },
);

export const getShopMojos = createAsyncThunk(
  'async/getShopMojos',
  async (input: { id: string }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token } = state.user;

    const paginationToken = state.shop.mojos?.paginationToken || null;

    const { id } = input;

    const listId = 'USER_ID';

    const paginationId = paginationToken || `1:${id}`;

    return mojoReducer.getMojos(paginationId, listId, token || '');
  },
);

export const reorderCatalog = createAsyncThunk(
  'async/reorderCatalog',
  async (input: { productId: string; newIndex: number }, { getState }: any) => {
    const state: IStore2 = getState();

    const { token = '' } = state.user;

    const shopId = state.shop.details?.id || '';

    const { productId, newIndex } = input;

    return reducer.reorderCatalog(token, productId, newIndex, shopId);
  },
);

export const ShopSlicer = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    reset: () => initialState,
    deleteReview: (state) => {
      if (state.details) {
        state.details.userReview = undefined;
      }
    },
    updateAvatar: (state, action) => {
      if (state.details) {
        state.details.userDetails.avatar = action.payload.avatar;
      }
    },
    resetFollowers: (state) => {
      state.followers = [];
    },
    updateDetails: (state, action) => {
      state.details = parseShopResponse(action.payload.shop);
      state.mojos = null;
      state.fullCatalog = null;
      state.reviews = null;
    },
    reorderCatalog: (state, action) => {
      const { oldIndex, newIndex: destinationIndex } = action.payload;
      let newIndex = destinationIndex;
      if (state.fullCatalog) {
        if (newIndex >= state.fullCatalog.length) {
          newIndex = state.fullCatalog.length - 1;
        }

        state.fullCatalog.splice(newIndex, 0, state.fullCatalog.splice(oldIndex, 1)[0]);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShop.fulfilled, (state, action) => {
      const { payload } = action;
      state.details = parseShopResponse(payload);
    });
    builder.addCase(getShop.pending, (state) => {
      state.details = null;
      state.isLoading = true;
      state.fullCatalog = null;
      state.mojos = null;
      state.reviews = null;
      state.id = '';
    });

    builder.addCase(onFollowUserToggle.pending, (state, action) => {
      const { userId, isFollowing } = action.meta.arg;

      const { details } = state;

      if (details) {
        if (details.userId === userId) {
          details.isFollowing = !isFollowing;
        } else {
          const selectedShop = details.similarShops.find((shop) => shop.userId === userId);

          if (selectedShop) {
            selectedShop.isFollowing = !isFollowing;
          }
        }
      }
    });

    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      if (state.fullCatalog) {
        state.fullCatalog = state.fullCatalog?.filter((item) => item.id !== id);
      }
      if (state.details) {
        state.details.catalog = state.details.catalog.filter((item) => item.id !== id);
      }
    });

    builder.addCase(getCatalog.pending, (state) => {
      state.fullCatalog = null;
    });
    builder.addCase(getCatalog.fulfilled, (state, action) => {
      state.fullCatalog = parseProducts(action.payload);
    });

    builder.addCase(getShopInitialReviews.fulfilled, (state, action) => {
      state.reviews = parseShopReviews(action.payload);
    });

    builder.addCase(getFollowers.fulfilled, (state, action) => {
      const { payload } = action;

      state.followers.push(...parseGetFollowers(payload));
    });
    builder.addCase(getOrderShopId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestOrder.fulfilled, (state) => {
      state.isLoading = false;
      state.basketShippingRates = null;
    });
    builder.addCase(getRequestToBuyBasketShippingRates.pending, (state) => {
      state.isRatesLoading = true;
      state.basketShippingRates = null;
    });
    builder.addCase(getRequestToBuyBasketShippingRates.rejected, (state) => {
      state.isRatesLoading = false;
    });
    builder.addCase(getRequestToBuyBasketShippingRates.fulfilled, (state, action) => {
      const { rates } = action.payload;
      state.isRatesLoading = false;
      state.basketShippingRates = parseBasketShippingRates(rates);
    });
    builder.addCase(superAdminMarkAsCrystalShop.fulfilled, (state) => {
      if (state.details) {
        state.details.isMarkedAsCrystalShop = true;
      }
    });
    builder.addCase(getShopMojos.fulfilled, (state, action) => {
      const { data, paginationToken } = action.payload;

      const prevList = state.mojos?.list || [];

      const list = [...prevList, ...parseMojoList(data)];
      state.mojos = {
        paginationToken: paginationToken || null,
        list,
      };
    });
    builder.addCase(superAdminDeleteProduct.fulfilled, (state, action) => {
      const { productId } = action.meta.arg;

      if (state.fullCatalog) {
        state.fullCatalog = state.fullCatalog.filter((item) => item.id !== productId);
      }
      if (state.details?.catalog) {
        state.details.catalog = state.details.catalog.filter((item) => item.id !== productId);
      }
    });
    builder.addMatcher(
      isAnyOf(onProductLikeToggle.pending, onProductLikeToggle.rejected),
      (state, action) => {
        const { productId } = action.meta.arg;

        if (state.fullCatalog) {
          const product = state.fullCatalog?.find((item) => item.id === productId);
          updateProductLikes(product);
        }
        if (state.details) {
          const product = state.details.catalog?.find((item) => item.id === productId);
          updateProductLikes(product);
        }
      },
    );
    builder.addMatcher(isAnyOf(requestOrder.rejected, getOrderShopId.rejected), (state) => {
      state.isLoading = false;
    });
  },
});
