import { forwardRef, ReactNode, useCallback, useState } from 'react';
import Slider from 'react-slick';
import './multiItemsCarousel.scss';
import classNames from 'classnames';

export interface IMultiItemsCarousel {
  settings: any;
  responsiveArrows: boolean;
  onSlideChange?: (index: number) => void;
  className?: string;
  id?: string;
  children: ReactNode[];
}

export const MultiItemsCarousel = forwardRef<Slider, IMultiItemsCarousel>(
  ({ children, settings, responsiveArrows, onSlideChange, className, id }, ref) => {
    const [dragging, setDragging] = useState(false);

    const handleBeforeChange = useCallback(() => {
      setDragging(true);
    }, [setDragging]);

    const handleAfterChange = useCallback(
      (index: number) => {
        setDragging(false);
        if (onSlideChange) {
          onSlideChange(index);
        }
      },
      [setDragging, children],
    );

    const handleOnItemClick = useCallback(
      (e: any) => {
        if (dragging) e.stopPropagation();
      },
      [dragging],
    );

    return (
      <div className={classNames([className], 'multi-items-carousel-container')} id={id}>
        <Slider
          {...settings}
          ref={ref}
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
          swipeToSlide
          className={classNames({ 'responsive-arrows': responsiveArrows }, settings?.className)}
        >
          {children.map((child, i) => (
            <div onClickCapture={handleOnItemClick} key={i}>
              {child}
            </div>
          ))}
        </Slider>
      </div>
    );
  },
);
