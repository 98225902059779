import { Fonts } from 'constant/fonts';
import { ButtonTypes, NotificationsTabs } from 'types';

export interface INotificationItem {
  id: string;
  isNew: boolean;
  titles: INotificationText[];
  text: INotificationText[];
  date: number;
  link: string | null;
  buttonDetails: INotificationsButton | null;
  buttonAfterClickDetails: INotificationsButton | null;
  prefixImage: INotificationsImageGroup | null;
  suffixImage: INotificationsImageGroup | null;
  imageGrid: INotificationsImage[] | null;
}

export interface INotificationState {
  activeTab: NotificationsTabs;
  allNotifications: {
    newNotifications: INotificationItem[];
    viewedNotifications: INotificationItem[];
    hasMore: boolean;
  } | null;
  orderNotifications: {
    newNotifications: INotificationItem[];
    viewedNotifications: INotificationItem[];
    hasMore: boolean;
  } | null;
  totalNewNotifications: number;
  isLoading: boolean;
}

export interface INotificationItemResponse {
  id: string;
  date?: number;
  link?: string;
  title: INotificationTextResponse[];
  text: INotificationTextResponse[];
  leftImage?: INotificationsImageGroupResponse;
  rightImage?: INotificationsImageGroupResponse;
  button?: INotificationsButtonResponse;
  buttonAfterClick?: INotificationsButtonResponse;
  imageGrid?: INotificationsImageResponse[];
}

export interface INotificationTextResponse {
  text: string;
  modifiers: INotificationsModifierResponse[];
}

export interface INotificationText {
  text: string;
  modifiers: INotificationsModifiers;
}

export interface INotificationsImageGroupResponse {
  images: INotificationsImageResponse[];
  type: ImageGroupTypes;
}

export enum ImageGroupTypes {
  BigAndSmall,
  Single,
  TwoDiagonal,
  ImageWithBadge,
}

export interface INotificationsImageGroup {
  type: ImageGroupTypes;
  images: INotificationsImage[];
}

export interface INotificationsImageResponse {
  url: string;
  userId: string;
  modifiers: INotificationsModifierResponse[];
}

export interface INotificationsImage {
  src: string;
  userId: string;
  modifiers: INotificationsModifiers;
}

export interface INotificationsModifierResponse {
  type: number;
  value: { [key in string]: string };
}

export interface INotificationsButtonResponse {
  variant: number;
  text: string;
  leftIcon?: string;
  rightIcon?: string;
  link?: string;
}

export interface INotificationsButton {
  type: ButtonTypes;
  text: string;
  prefixIcon: string | null;
  suffixIcon: string | null;
  link: string | null;
}

export interface INotificationsResponse {
  hasMore: boolean;
  viewed?: INotificationItemResponse[];
  notViewed?: INotificationItemResponse[];
}

export interface INotificationApi {
  getNotifications(maxId: string | null, token: string): Promise<INotificationsResponse>;
  getOrderNotifications(maxId: string, token: string): Promise<INotificationsResponse>;
}

export interface INotificationsModifiers {
  color?: string;
  font?: Fonts;
  borderRadius?: number;
  link?: string;
}
