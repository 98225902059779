import {
  OffersForRequestResponse,
  ShippingRatesForVisionOfferResponse,
  SuccessResponse,
  VisionOffer,
  VisionRequest,
} from 'graphql/generated/graphql';
import {
  IAddressPreview,
  IBasketShippingRate,
  ILinkedPaymentOption,
  IVisionOffer,
  IVisionRequest,
} from 'types';

export interface ISpecificVisionRequestState {
  offers: IVisionOffer[] | null;
  request: IVisionRequest | null;
  paginationToken: string | null;
  sortType: OffersSortType;
  isOffersLoading: boolean;
  paymentSellerDetails: {
    fullName: string;
    shippingAddress: IAddressPreview;
    linkedPaymentOptions: ILinkedPaymentOption[];
    rates: IBasketShippingRate[];
  };
  selectedOffer: IVisionOffer | null;
}

export interface ISpecificVisionRequestApi {
  getOffersForRequest(
    token: string,
    requestId: string,
    paginationToken: string | null,
    sortType: OffersSortType,
  ): Promise<OffersForRequestResponse>;
  getVisionRequest(token: string, requestId: string): Promise<VisionRequest>;
  declineOffer(token: string, offerId: string, reason: string): Promise<SuccessResponse>;
  withdrawOffer(token: string, offerId: string): Promise<SuccessResponse>;
  deleteRequest(token: string, requestId: string): Promise<SuccessResponse>;
  hideVisionRequest(token: string, requestId: string): Promise<SuccessResponse>;
  getVisionOffer(token: string, offerId: string): Promise<VisionOffer>;
  getSellerPaymentDetails(
    token: string,
    offerId: string,
  ): Promise<ShippingRatesForVisionOfferResponse>;
}

export enum OffersSortType {
  Date = 'Date',
  Price = 'Price',
  DeliveryTime = 'DeliveryTime',
}
