import { fetcher } from 'api';
import { IProfileInformationResponse, IProfilePageApi, IProfileViewsResponse, Method } from 'types';

export class ProfileApi implements IProfilePageApi {
  getUserPage(userId: string, token: string): Promise<any> {
    return fetcher(`v3/userprofile/info/${userId}`, Method.GET, token);
  }

  getSuggestedShops(userId: string, token: string): Promise<any> {
    return fetcher(`v3/userprofile/web/info/${userId}`, Method.GET, token);
  }

  getProfileInformation(userId: string, token: string): Promise<IProfileInformationResponse> {
    return fetcher(`v2/userprofile/web/${userId}`, Method.GET, token);
  }

  onFollowToggle(userId: string, isFollowing: boolean, token: string): Promise<void> {
    const method = isFollowing ? Method.DELETE : Method.POST;

    return fetcher(`follow/${userId}`, method, token);
  }

  getProfileViews(token: string, maxTime?: number): Promise<IProfileViewsResponse> {
    const query = {
      maxTime,
    };

    return fetcher(`services/v2/profileviews`, Method.GET, token, undefined, query);
  }
}
