import { INotificationItem } from 'types';
import { isToday, isYesterday } from 'utils';

export const getTotalTodayNotifications = (list: INotificationItem[]) => {
  return list.filter((notification) => isToday(notification.date)).length;
};

export const getTotalYesterdayNotifications = (list: INotificationItem[]) => {
  return list.filter((notification) => isYesterday(notification.date)).length;
};
