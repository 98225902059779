import { useDispatch } from 'react-redux';

import { useTedoooSelector } from 'store/Store';
import { PopupSlicer } from 'store/popup/PopupActions';
import { popupIdsOptions } from 'store/popup/types';
import { NonSignInPopupSources } from '../pages/signPage/types';

export const useRequireToken = (callback: any, source?: NonSignInPopupSources) => {
  const dispatch = useDispatch();

  const { token } = useTedoooSelector((state) => state.user);

  const { productId } = useTedoooSelector((state) => state.popup);

  const onLogin = () =>
    dispatch(PopupSlicer.actions.show({ id: popupIdsOptions.signInPopup, productId, source }));

  if (!token) {
    return onLogin;
  }

  return callback;
};
