import {
  ICustomizationRequest,
  IOrderDetailsState,
  IOrderItem,
  IOrderItemUpdateStatusRequest,
} from 'types';

export const parseCustomizations = (customizations: ICustomizationRequest | null) => {
  if (!customizations) return undefined;

  return {
    note: customizations.text,
    images: customizations.images,
  };
};

export const parseItemsToUpdateStatusRequest = (
  items: IOrderItem[],
): IOrderItemUpdateStatusRequest[] => {
  return items.map((item) => ({
    id: item.id,
    title: item.title,
    image: item.image,
    quantity: item.quantity,
    price: Math.floor(Number(item.price.value.toFixed(2)) * 100),
    currency: { symbol: item.price.currencySymbol, code: item.price.currencyCode },
    sale: Number((item.originalPrice.value - item.price.value).toFixed(2)) * 100,
    customization: parseCustomizations(item.customizationRequests),
  }));
};

export const parseItemsToRequestOrderItems = (items: IOrderItem[]) => {
  return items.map((item) => ({
    id: item.id,
    quantity: item.quantity,
    customization: parseCustomizations(item.customizationRequests),
  }));
};

export const getSubTotalItemsPrice = (orderDetails: IOrderDetailsState): number => {
  return orderDetails.items.reduce((acc, item) => acc + item.price.value, 0);
};

export const getOrderTotalPrice = (
  orderDetails: IOrderDetailsState,
  shippingPrice: number | null,
): number => {
  const subTotalWithCoupon = orderDetails.discountDetails
    ? orderDetails.discountDetails.finalPrice
    : null;

  return (subTotalWithCoupon || orderDetails.subTotalPrice) + (shippingPrice || 0);
};
