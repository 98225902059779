import './interestItemButton.scss';
import { FC } from 'react';
import classNames from 'classnames';

import { IInterestItemButtonProps } from 'types';

const InterestItemButton: FC<IInterestItemButtonProps> = ({
  interestId,
  title,
  text,
  isSelected,
  onItemClick,
}) => {
  const onClick = () => onItemClick({ interestId, title });

  return (
    <div className={classNames('interest-item-button', { isSelected })} onClick={onClick}>
      <span className="interests-button-text">{text}</span>
    </div>
  );
};

export default InterestItemButton;
