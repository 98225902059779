import { CSSProperties, FC, ReactElement } from 'react';

interface ISpan {
  text: string | number | ReactElement | ReactElement[];
  className?: string;
  onClick?: any;
  dataTestId?: string;
  style?: CSSProperties;
}

export const Span: FC<ISpan> = ({ text, dataTestId, className, onClick, style }) => {
  return (
    <span data-testid={dataTestId} className={className} style={{ ...style }} onClick={onClick}>
      {text}
    </span>
  );
};
