import { createSlice } from '@reduxjs/toolkit';

import {
  CheckoutSteps,
  ICheckoutForNonSignupUsersState,
  ICheckoutItem,
  IDiscountDetails,
  IShippingAddress,
  ProductAvailabilityItem,
} from 'types';
import { getPaymentsForNonSignupUser, getShippingRatesForNonSignupUser } from './CheckoutActions';
import { parseCheckoutLinkedPayoutOptions, parseShippingRates } from '../apiParser';
import { getBasketSupportedItems, getCheckoutIdForProduct } from '../basket/BasketActions';
import {
  checkCouponForItem,
  getBasketCheckoutDetails,
  getBasketPaymentsDetails,
} from '../payouts/PayoutsActions';
import {
  CheckCouponCodeResponseError,
  CheckCouponCodeResponseSuccess,
} from '../../graphql/generated/graphql';
import { getCouponCodeErrorMessageFromResponse, getDiscountDetailsFromResponse } from './helper';

const initialState: ICheckoutForNonSignupUsersState = {
  shopId: '',
  shippingAddress: null,
  supportedCountries: [],
  checkoutId: null,
  isShippingAddressLoading: false,
  products: null,
  isShippingError: false,
  payments: null,
  rates: null,
  step: CheckoutSteps.ShippingAddress,
  discountDetails: null,
  isCouponCodeLoading: false,
  couponCodeErrorMessage: null,
};

export const CheckoutSlicer = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    reset: () => initialState,
    updateShippingAddress: (state, action: { payload: { shippingAddress: IShippingAddress } }) => {
      const { shippingAddress } = action.payload;
      state.shippingAddress = {
        state: shippingAddress.state,
        postalCode: shippingAddress.postalCode,
        country: shippingAddress.country,
        name: shippingAddress.fullName,
        email: shippingAddress.email,
        city: shippingAddress.city,
        streetAddress: shippingAddress.streetAddress,
        other: shippingAddress.apartmentNumber,
        phoneNumber: shippingAddress.phoneNumber,
      };
      state.rates = null;
      state.payments = null;
      state.step = CheckoutSteps.ShippingMethods;
    },
    onRateSelect: (state, action: { payload: { id: string } }) => {
      const { id } = action.payload;

      const rates = state.rates || [];

      // update selection
      state.rates = rates.map((rate) => ({
        ...rate,
        isSelected: rate.id === id,
      }));
    },

    removeCouponCode: (state) => {
      state.isCouponCodeLoading = false;
      state.couponCodeErrorMessage = null;
      state.discountDetails = null;
    },
    removeCouponCodeError: (state) => {
      state.couponCodeErrorMessage = null;
    },

    updateStep: (state, action: { payload: { step: CheckoutSteps } }) => {
      const { step } = action.payload;
      state.step = step;
    },
    updateShippingError: (state, action: { payload: { isShippingError: boolean } }) => {
      const { isShippingError } = action.payload;
      state.isShippingError = isShippingError;
    },
    updateInitialDetails: (
      state,
      action: {
        payload: {
          discountDetails: IDiscountDetails | null;
          shopId: string;
          products: ICheckoutItem[];
          countries: string[];
          checkoutId: string | null;
          shippingAddress: IShippingAddress | null;
        };
      },
    ) => {
      const { products, shippingAddress, countries, checkoutId, shopId, discountDetails } =
        action.payload;

      state.shopId = shopId;
      state.discountDetails = discountDetails || null;
      state.products = products.map((product) => ({ ...product }));
      state.supportedCountries = countries;
      if (shippingAddress) {
        state.shippingAddress = {
          state: shippingAddress.state,
          postalCode: shippingAddress.postalCode,
          country: shippingAddress.country,
          name: shippingAddress.fullName,
          email: shippingAddress.email,
          city: shippingAddress.city,
          streetAddress: shippingAddress.streetAddress,
          other: shippingAddress.apartmentNumber,
          phoneNumber: shippingAddress.phoneNumber,
        };
        state.step = CheckoutSteps.ShippingMethods;
      } else {
        state.shippingAddress = null;
      }
      state.checkoutId = checkoutId || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShippingRatesForNonSignupUser.fulfilled, (state, action) => {
      const { rates, payments } = action.payload;

      state.rates = parseShippingRates(rates || []);
      state.payments = parseCheckoutLinkedPayoutOptions(payments);
    });

    builder.addCase(getBasketCheckoutDetails.fulfilled, (state, action) => {
      const { rates, payments } = action.payload;

      state.rates = rates ? parseShippingRates(rates) : null;
      state.payments = parseCheckoutLinkedPayoutOptions(payments);
    });
    builder.addCase(getBasketPaymentsDetails.fulfilled, (state, action) => {
      const { payments } = action.payload;

      state.payments = parseCheckoutLinkedPayoutOptions(payments);
    });
    builder.addCase(getPaymentsForNonSignupUser.fulfilled, (state, action) => {
      const { payments } = action.payload;
      state.payments = parseCheckoutLinkedPayoutOptions(payments);
    });

    builder.addCase(getCheckoutIdForProduct.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.checkoutId = id;
    });

    builder.addCase(getBasketSupportedItems.fulfilled, (state, action) => {
      const { statuses } = action.payload;

      if (state.products) {
        state.products.forEach((product) => {
          product.status = statuses[product.id];
        });
      }

      if (state.products) {
        const isAnyProductCantShipToCountry = state.products.some(
          (product) => product.status === ProductAvailabilityItem.NotShipping,
        );

        if (isAnyProductCantShipToCountry) {
          state.step = CheckoutSteps.ShippingAddress;
        } else {
          state.step = CheckoutSteps.ShippingMethods;
        }
      }
    });
    builder.addCase(checkCouponForItem.pending, (state) => {
      state.isCouponCodeLoading = true;
    });
    builder.addCase(checkCouponForItem.rejected, (state) => {
      state.isCouponCodeLoading = false;
      state.couponCodeErrorMessage = 'Internal Error';
    });
    builder.addCase(checkCouponForItem.fulfilled, (state, action) => {
      const { couponCode } = action.meta.arg;

      const res: CheckCouponCodeResponseError | CheckCouponCodeResponseSuccess = action.payload;
      state.isCouponCodeLoading = false;

      const discountDetails = getDiscountDetailsFromResponse(res, couponCode);

      const errorMessage = getCouponCodeErrorMessageFromResponse(res);

      if (discountDetails) state.discountDetails = discountDetails;
      if (errorMessage) state.couponCodeErrorMessage = errorMessage;
    });
  },
});
