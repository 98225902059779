import '../sidebarSection.scss';
import { Button, Span } from 'tedooo-web-design-system/ui';
import { ArrowRight } from 'tedooo-web-design-system/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import CircleLikeButton from 'components/circleLikeButton/CircleLikeButton';
import { translations } from 'translations/en';
import { MultiItemsCarousel } from 'components/carousels';
import { Image } from 'components/helpers';
import { favoritesSliderSettings } from './favoritesCarouselSettings';
import { ROUTES } from 'constant';
import TotalSavesTag from 'components/totalSavesTag/TotalSavesTag';
import { IProductsInSearchResults, DiscoverListsId } from 'types';
import { buildDiscoverPageQuery } from 'utils';

interface IFavoritesSection {
  products: IProductsInSearchResults[];
  onSaveProduct(input: { productId: string; isLiked: boolean; source: string }): void;
}

const FavoritesSection: FC<IFavoritesSection> = ({ products, onSaveProduct }) => {
  const navigate = useNavigate();

  const onProductClick = (id: string) => {
    navigate(`/${ROUTES.PRODUCT}/${id}`);
  };

  const onShowMoreClick = () => {
    const query = buildDiscoverPageQuery({
      id: DiscoverListsId.Favorites,
      title: translations.PRODUCTS_PAGE.FAVORITES_TITLE,
      paginationToken: null,
    });
    navigate(`/${ROUTES.PRODUCTS}${query}`);
  };

  const onSaveClick = (id: string, isLiked: boolean) => {
    onSaveProduct({ productId: id, isLiked, source: 'products' });
  };

  return (
    <div className="section sidebar-section">
      <div className="section-header">
        <Span
          text={translations.FEED.SIDE_BAR.SAVED_PRODUCTS_SECTION.TITLE}
          className="sub-title"
        />
      </div>
      <MultiItemsCarousel settings={favoritesSliderSettings} responsiveArrows>
        {products.map((product, i) => (
          <div className="product-container" key={i} onClick={() => onProductClick(product.id)}>
            <TotalSavesTag totalLikes={product.totalLikes} />
            <Image src={product.image} />
            <CircleLikeButton
              isLiked={product.isLiked}
              onClick={() => onSaveClick(product.id, product.isLiked)}
            />
          </div>
        ))}
      </MultiItemsCarousel>
      <div className="section-footer">
        <Button
          type="tertiary"
          size="medium"
          borderRadius="small"
          text={translations.FEED.SIDE_BAR.SAVED_PRODUCTS_SECTION.SEE_ALL}
          suffixIcon={<ArrowRight />}
          onClick={onShowMoreClick}
        />
      </div>
    </div>
  );
};

export default FavoritesSection;
