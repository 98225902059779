import { CartItem } from '../graphql/generated/graphql';

export enum PersistedKeys {
  Menu = 'menu',
  Discover = 'discover',
  GenerateVision = 'generateVision',
  Config = 'config',
  User = 'user',
  Shipping = 'shipping',
  CreateShop = 'createShop',
  Mentions = 'mentions',
  Shop = 'shop',
  Listing = 'listing',
  Feed = 'feed',
  Settings = 'settings',
  Root = 'root',
}

export const mapPersistedKeysToStorageKeyName: { [key in PersistedKeys]: string } = {
  [PersistedKeys.Menu]: 'persist:menu',
  [PersistedKeys.GenerateVision]: 'persist:generateVision',
  [PersistedKeys.Discover]: 'persist:discover',
  [PersistedKeys.User]: 'persist:user',
  [PersistedKeys.Shipping]: 'persist:shipping',
  [PersistedKeys.CreateShop]: 'persist:createShop',
  [PersistedKeys.Mentions]: 'persist:mentions',
  [PersistedKeys.Shop]: 'persist:shop',
  [PersistedKeys.Listing]: 'persist:listing',
  [PersistedKeys.Feed]: 'persist:feed',
  [PersistedKeys.Root]: 'persist:root',
  [PersistedKeys.Config]: 'persist:config',
  [PersistedKeys.Settings]: 'persist:settings',
};

const getOriginalPriceFromProduct = (product: CartItem): number => {
  if (product.originalPrice) return product.originalPrice * product.quantity;

  return product.price.price * product.quantity;
};

export const getSubTotalPrice = (cart: CartItem[]): number => {
  return cart.reduce((acc, item) => acc + getOriginalPriceFromProduct(item), 0) / 100;
};

export const getTotalDiscount = (cart: CartItem[]): number => {
  const subTotal = cart.reduce((acc, item) => acc + item.price.price * item.quantity, 0) / 100;

  return getSubTotalPrice(cart) - subTotal;
};
