import { CurrencyCode } from 'constant/currencies';
import {
  IAppConfigResponse,
  SignPageOptions,
  ServerStatus,
  IProductsInSearchResults,
  PremiumPlanOptions,
  PremiumStatus,
} from 'types';

export interface IFacebookResponse {
  data: {
    email: string;
    id: string;
    name: string;
    picture: string;
  };
}

export enum FinishSignUpRejectOptions {
  NotMember = 400,
}

export interface IUserActions {
  getAppConfigurations(token: string): Promise<IAppConfigResponse>;
  refreshToken(token: string): Promise<{ accessToken: string }>;
  login(username: string, password: string): Promise<{ accessToken: string }>;
  googleLogin(token: string): Promise<any>;
  facebookLogin(token: string): Promise<IFacebookResponse>;
  updateAvatar(token: string, src: string): Promise<void>;
  getPremiumPlanDetails(token: string): Promise<IGetPremiumPlanDetailsResponse>;
  updateUserDetails(token: string, userId: string, name?: string, avatar?: string): Promise<void>;
  updateBio(token: string, bio: string): Promise<void>;
  signUp(
    firstName: string,
    username: string,
    password: string,
    email: string,
    phoneNumber: string,
    botToken: string,
  ): Promise<any>;
  sendCodeViaWhatsApp(): Promise<any>;
  updateUserToBuyer(token: string): Promise<void>;
  submitVerifyCode(codeNumber: string): Promise<any>;
  sendCodeViaCall(): Promise<any>;
  resendCode(): Promise<any>;
  getLoggedInUserInfo(token: string): Promise<IMeResponse>;
  finishSignUp(
    token: string,
    endpoint: string,
    name: string,
    email: string,
    picture: string,
    username: string,
  ): Promise<any>;
}

export interface IMyPost {
  date: number;
  id: string;
  image: string | null;
  text: string;
}

export enum FinishSignUpOptions {
  Facebook = 'facebook',
  Google = 'google',
}

export interface IFinishSignUpInformation {
  email: string;
  id: string;
  image: string;
  name: string;
  token: string;
  type: FinishSignUpOptions;
}

export enum UserTypes {
  Buyer,
  Seller,
}

export interface IUserState {
  token?: string;
  avatar: string | null;
  loginDefaultUsername: string | null;
  isShippoConnected: boolean;
  thirdParty: 'Google' | 'Facebook' | null;
  username: string | null;
  fullName: string | null;
  isLoading: boolean;
  phase: SignPageOptions;
  type: UserTypes;
  isPremium: boolean;
  isAvatarLoading: boolean;
  id: string | null;
  loginServerErrorMessage?: string;
  isCodeSent: boolean;
  serverStatus?: ServerStatus;
  totalFollowers: number;
  totalFollowing: number;
  totalProfileViews: number;
  totalPendingOrders: number;
  finishSignUpInformation: IFinishSignUpInformation | null;
  shopDetails: {
    name: string;
    id: string;
    coverPhoto: string | null;
    productImages: Array<string>;
  } | null;
  posts: Array<IMyPost>;
  bio: string | null;
  loggedInUserShopId: string | null;
  shouldShowOnboarding: boolean;
  orderIdToShow: string | null;
  savedProducts: IProductsInSearchResults[] | null;
  isSuperAdmin: boolean;
  premiumDetails: IPremiumDetails | null;
}

export interface IPremiumDetails {
  nextBillingDate: number;
  premiumStatus: PremiumStatus;
  userPremiumPlan: PremiumPlanOptions | null;
  payoutProvider: string | null;
  managePlanUrl: string;
  monthlySubscriptionUrl: string;
  yearlySubscriptionUrl: string;
  isSubscriptionAutoRenew: boolean | null;
}

export interface ILoginResponse {
  accessToken: string;
  shopId?: string;
  orders: number;
  notifications: number;
  orderPopupToShow?: { newOrder: string };
  currencyCode?: CurrencyCode;
}

export interface IRecentPostResponse {
  date: number;
  id: string;
  image?: string;
  text: string;
}

export interface IUserShopInfo {
  id: string;
  coverPhoto: string | null;
  productImages: Array<string>;
  name: string;
}

export interface ISellerInformation {
  shop?: IUserShopInfo;
}

export interface IMeResponse {
  followers: number;
  following: number;
  posts: Array<IRecentPostResponse>;
  profileViews: number;
  bio?: string;
  sellerInformation?: ISellerInformation;
  buyerInformation?: Object;
}

export interface ISignUpRequest {
  firstName: string;
  username: string;
  password: string;
  email: string;
  phoneNumber: string;
  botToken: string | null;
}

export interface IGoogleLoginRequest {
  token: string;
  imageUrl: string;
  name: string;
  email: string;
}

export interface IFinishSignUpRequest {
  username: string;
}

export interface IGetPremiumPlanDetailsResponse {
  last4Digits?: string;
  subUntil: number;
  plan: PremiumPlanOptions;
  autoRenew?: boolean;
  subscriptionId?: string;
  purchaseToken?: string;
  type?: string;
  canRefer?: boolean;
}
