import {
  IBasketShippingRate,
  IPrice,
  BasketRateAttributes,
  ProductAvailabilityItem,
  ICustomizationRequest,
  ILinkedPaymentOption,
  IShippingAddress,
  PaymentSource,
  ICheckoutItem,
  IDiscountDetails,
} from 'types';
import { CurrencyCode } from 'constant/currencies';
import { Countries } from 'constant/countries';

export enum CheckoutSteps {
  ShippingAddress,
  ShippingMethods,
  Pay,
}

export interface IShippingAddressInput {
  name: string;
  email: string;
  country: Countries;
  city: string;
  streetAddress: string;
  postalCode: string;
  other: string | null;
  phoneNumber: string;
  state: string | null;
}

export interface IShippingAddressSection {
  address: IShippingAddressInput | null;
  isShippingError: boolean;
  isDigital: boolean;
  checkoutId: string | null;
  isShippingAddressLoading: boolean;
  onStepUpdate(input: { step: CheckoutSteps });
  onSubmit(address: IShippingAddressInput);
  getPayment(input: { checkoutId: string; email: string; fullName: string }): void;
}

export interface ISelectedShippingRateSection {
  rate: IBasketShippingRate;
  onEdit(): void;
}

export interface IShippingAddressFormInput {
  name: string;
  email: string;
  streetAddress: string;
  postalCode: string;
  other?: string;
  city: string;
}

export interface IShippingMethodSection {
  onShippingRateChange(rateId: string): void;
  onSubmit(): void;
  shippingRates: IBasketShippingRate[] | null;
}

export interface IShippingRateOption {
  rate: IBasketShippingRate;
  isSelected: boolean;
  onClick(id: string): void;
}

export interface ICheckoutPageWrapper {
  shopId: string;
  token: string | null;
  products: ICheckoutItem[] | null;
  isAnyProductCantShipToCountry: boolean;
  isDigital: boolean;
  hasError: boolean;
  supportedCountries: string[];
  step: CheckoutSteps;
  parsedAddress: IShippingAddress | null;
  isShippingAddressLoading: boolean;
  shippingAddress: IShippingAddressInput | null;
  shippingRates: IBasketShippingRate[] | null;
  selectedRate: IBasketShippingRate | null;
  isShippingError: boolean;
  checkoutId: string | null;
  productsPrice: IProductsPrice | null;
  discountDetails: IDiscountDetails | null;
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  onRemoveCouponCode(): void;
  updateShippingError(input: { isShippingError: boolean }): void;
  onShippingAddressUpdate(input: { shippingAddress: IShippingAddress });
  updateShippingAddressWithoutValidation(input: { shippingAddress: IShippingAddress });
  onStepUpdate(input: { step: CheckoutSteps });
  onRateSelect(input: { id: string });
  onShippingAddressValidate(): void;
  onReset(): void;
  getPayment(input: { checkoutId: string; email: string; fullName: string }): void;
  showPopup(input: { id: string }): void;
  onRemoveBuyNowItem(): void;
  onPayoutsReset(): void;
  onRemoveCouponCodeError(): void;
}

export interface ICheckoutPage {
  shopId: string;
  token: string | null;
  items: ICheckoutItem[] | null;
  hasError: boolean;
  shippingAddress: IShippingAddressInput | null;
  productsPrice: IProductsPrice | null;
  step: CheckoutSteps;
  shippingRates: IBasketShippingRate[] | null;
  parsedAddress: IShippingAddress | null;
  isDigital: boolean;
  checkoutId: string | null;
  onStepUpdate(input: { step: CheckoutSteps });
  isShippingAddressLoading: boolean;
  onRateSelect(input: { id: string });
  isShippingError: boolean;
  selectedRate: IBasketShippingRate | null;
  onEditAddressClick(): void;
  onShippingAddressSubmit(address: IShippingAddressInput): void;
  getPayment(input: { checkoutId: string; email: string; fullName: string }): void;
  discountDetails: IDiscountDetails | null;
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  onRemoveCouponCode(): void;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  onRemoveCouponCodeError(): void;
}

export interface ICheckoutPageSmallScreen {
  shopId: string;
  token: string | null;
  items: ICheckoutItem[];
  productsPrice: IProductsPrice | null;
  shippingAddress: IShippingAddressInput | null;
  step: CheckoutSteps;
  checkoutId: string | null;
  hasError: boolean;
  shippingRates: IBasketShippingRate[] | null;
  parsedAddress: IShippingAddress | null;
  isDigital: boolean;
  onStepUpdate(input: { step: CheckoutSteps });
  isShippingAddressLoading: boolean;
  onRateSelect(input: { id: string });
  isShippingError: boolean;
  onEditAddressClick(): void;
  onShippingAddressSubmit(address: IShippingAddressInput): void;
  getPayment(input: { checkoutId: string; email: string; fullName: string }): void;
  discountDetails: IDiscountDetails | null;
  isCouponCodeLoading: boolean;
  couponCodeErrorMessage: string | null;
  onRemoveCouponCode(): void;
  onCheckCouponCode(input: { couponCode: string; shopId: string; cartPrice: number }): void;
  onRemoveCouponCodeError(): void;
}

export interface INonSignupCheckoutItem {
  id: string;
  title: string;
  price: IPrice;
  originalPrice: IPrice;
  isDigital: boolean;
  quantity: number;
  image: string;
  status: ProductAvailabilityItem;
  customizationRequest: ICustomizationRequest | null;
}

export interface IPaymentsWrapper {
  couponCode: string | null;
  checkoutId: string | null;
  productsPrice: IProductsPrice | null;
  onStripePaymentFinish(input: {
    username: string;
    avatar: string;
    hasDigitalFiles: boolean;
    source: PaymentSource;
    id: string;
    price: IPrice;
    selectedRateAttribute: BasketRateAttributes | null;
  }): void;
  products: ICheckoutItem[] | null;
  shippingRates: IBasketShippingRate[] | null;
  payments: ILinkedPaymentOption[] | null;
  getStripeSessionDetails(input: {
    checkoutId: string;
    shippingRateId: string | null;
    couponCode: string | null;
  }): void;
  getPaypalCheckoutUrl(input: {
    checkoutId: string;
    shippingRateId: string | null;
    couponCode: string | null;
  }): void;
  showPopup(input: { id: string; orderId?: string; link?: string }): void;
}

export enum CheckoutHeaderStatus {
  Past = 'past',
  Current = 'current',
  Future = 'future',
}

export interface ICheckoutSectionHeader {
  shouldDisplayIndex: boolean;
  status: CheckoutHeaderStatus;
  text: string;
  index: number;
}

export interface IProductsPrice {
  shippingPrice: number | null;
  totalDiscount: number;
  subTotal: IPrice;
  currencyCode: CurrencyCode;
  totalPrice: IPrice;
}

export interface IAddCouponCodeTextField {
  errorMessage: string | null;
  isLoading: boolean;
  shouldDisplayRemoveButton: boolean;
  onRemoveCoupon(): void;
  onSubmit(code: string): void;
  onTextChange(text: string): void;
}
