import { Countries, shippingCountriesOptions } from 'constant/countries';
import {
  FixedPriceOptions,
  IShippingServiceGroup,
  ProcessingTimeOptions,
  ShippingPriceOptions,
} from 'types';
import { mapShippingPriceTypeToDropdownLabel } from '../../helper';

export const getRemainingCountries = (disabledCountries: Countries[]): Countries[] => {
  const remainingCountries: Countries[] = [];

  shippingCountriesOptions.forEach((region) => {
    region.items?.forEach((country) => {
      if (!disabledCountries.includes(country)) {
        remainingCountries.push(country);
      }
    });
  });

  return remainingCountries;
};

const processingTimeOptionsOrder = [
  ProcessingTimeOptions.ThreeDays,
  ProcessingTimeOptions.FiveDays,
  ProcessingTimeOptions.SevenDays,
  ProcessingTimeOptions.Custom,
];

export const PROCESSING_TIME_DROPDOWN_OPTIONS = processingTimeOptionsOrder.map((option) => ({
  value: option,
  label: option,
}));

const fixedPriceOptionsOrder = [FixedPriceOptions.Fixed, FixedPriceOptions.FreeShipping];

export const FIXED_PRICE_DROPDOWN_OPTIONS = fixedPriceOptionsOrder.map((option) => ({
  value: option,
  label: option,
}));

const shippingPriceOptionsOrder = [
  ShippingPriceOptions.CalculatedShipping,
  ShippingPriceOptions.FixedPrice,
];

export const SHIPPING_PRICE_DROPDOWN_OPTIONS = shippingPriceOptionsOrder.map((option) => ({
  value: option,
  label: mapShippingPriceTypeToDropdownLabel[option],
}));

export const parseShippingServicesToAccordion = (shippingServices: IShippingServiceGroup[]) => {
  return shippingServices.map((service) => ({
    title: service.name,
    items: service.services,
  }));
};

export const parseCountriesToAccordion = () => {
  return shippingCountriesOptions.map((group) => ({
    title: group.title,
    items: group.items?.map((country) => ({ id: country, name: country })),
  }));
};
