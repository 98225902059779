import { createAsyncThunk } from '@reduxjs/toolkit';

import { MentionsReducer } from './MentionsReducer';

const reducer = new MentionsReducer();

export const searchTaggedUsers = createAsyncThunk(
  'async/searchTaggedUsers',
  async (input: { text: string }, { getState }: any) => {
    const { token } = getState().user;

    const { text } = input;

    return reducer.searchTaggedUsers(text, token);
  },
);
