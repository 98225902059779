import { ISendVisionOfferState, IVisionOffer } from 'types';

export const onEditOffer = (offer: IVisionOffer): ISendVisionOfferState => {
  return {
    id: offer.id,
    tariffNumber: offer.tariffNumber || '',
    comments: offer.sellerNote,
    shippingProfileId: offer.shippingProfileId,
    shippingPrice: offer.shippingPrice.value,
    price: offer.price,
    returnPolicy: offer.returnPolicy.type,
    dimensions: offer.dimensions,
    request: null,
    shippingPriceError: null,
  };
};
