import { createSlice } from '@reduxjs/toolkit';

import { ISpecificVisionRequestState, OffersSortType, VisionOffersTab } from 'types';
import {
  declineVisionOffer,
  getOffersForVisionRequest,
  getSellerPaymentDetails,
  getVisionOffer,
  getVisionRequest,
} from './SpecificVisionRequestActions';
import {
  parseAddressPreview,
  parseCheckoutLinkedPayoutOptions,
  parseShippingRates,
  parseVisionOffer,
  parseVisionOffers,
  parseVisionRequest,
} from '../apiParser';
import { Countries } from 'constant/countries';

const initialState: ISpecificVisionRequestState = {
  offers: null,
  request: null,
  paginationToken: null,
  sortType: OffersSortType.Date,
  isOffersLoading: false,
  paymentSellerDetails: {
    fullName: '',
    shippingAddress: {
      country: Countries.UnitedStates,
      state: null,
      address: '',
      city: '',
      postalCode: '',
    },
    linkedPaymentOptions: [],
    rates: [],
  },
  selectedOffer: null,
};

export const SpecificVisionRequestSlicer = createSlice({
  name: 'specificVisionRequest',
  initialState,
  reducers: {
    reset: () => initialState,
    onRateChange: (state, action) => {
      const { id } = action.payload;

      const previousSelectedRate = state.paymentSellerDetails.rates.find((rate) => rate.isSelected);

      if (previousSelectedRate) {
        previousSelectedRate.isSelected = false;
      }

      const newSelectedRate = state.paymentSellerDetails.rates.find((rate) => rate.id === id);

      if (newSelectedRate) {
        newSelectedRate.isSelected = true;
      }
    },
    onSortChange: (state, action) => {
      const { type } = action.payload;
      state.sortType = type;
      state.offers = null;
      state.paginationToken = null;
    },
    onViewOffer: (state, action) => {
      const { id } = action.payload;
      state.selectedOffer = state.offers?.find((offer) => offer.id === id) || null;
    },
    onOfferDeleted: (state, action) => {
      const { id } = action.payload;

      if (state.offers) {
        state.offers = state.offers.filter((offer) => offer.id !== id);
      }
    },
    onOfferChange: (state, action) => {
      const { offer } = action.payload;

      if (state.offers) {
        const parsedOffer = parseVisionOffer(offer, VisionOffersTab.Pending);

        const selectedOfferIndex = state.offers.findIndex((o) => o.id === offer.offerId);

        if (selectedOfferIndex !== -1) {
          state.offers[selectedOfferIndex] = parsedOffer;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOffersForVisionRequest.pending, (state) => {
      state.isOffersLoading = true;
    });
    builder.addCase(getOffersForVisionRequest.fulfilled, (state, action) => {
      const { paginationToken, offers } = action.payload;
      state.isOffersLoading = false;
      state.paginationToken = paginationToken || null;

      const prevList = state.offers || [];

      const parsedOffers = parseVisionOffers(offers, VisionOffersTab.Pending);
      state.offers = [...prevList, ...parsedOffers];
    });
    builder.addCase(getVisionRequest.fulfilled, (state, action) => {
      state.request = parseVisionRequest(action.payload);
    });
    builder.addCase(getSellerPaymentDetails.fulfilled, (state, action) => {
      const { rates, paymentOptions, shippingAddress } = action.payload;
      state.paymentSellerDetails = {
        fullName: shippingAddress.fullName,
        shippingAddress: parseAddressPreview(shippingAddress.shippingAddress),
        rates: parseShippingRates(rates),
        linkedPaymentOptions: parseCheckoutLinkedPayoutOptions(paymentOptions),
      };
    });
    builder.addCase(declineVisionOffer.fulfilled, (state, action) => {
      const { offerId } = action.meta.arg;

      if (state.offers) {
        state.offers = state.offers.filter((offer) => offer.id !== offerId);
      }
    });
    builder.addCase(getVisionOffer.fulfilled, (state, action) => {
      state.selectedOffer = parseVisionOffer(action.payload, VisionOffersTab.Pending);
    });
  },
});
