import { createSlice } from '@reduxjs/toolkit';

import {
  IOrderDetails,
  ISpecificOrderState,
  OrderInitialPopupOptions,
  OrderStatuses,
  PayoutMethods,
} from 'types';
import {
  addShippingCost,
  checkTrackingNumber,
  getSpecificOrder,
  getTrackingInformation,
  markPaymentAsReceived,
  requestOrder,
  saveTrackingNumber,
  declineOrder,
  markOrderAsArrived,
  markPaymentAsSent,
  getOrderDigitalFiles,
  acceptOrder,
} from './SpecificOrderActions';
import { parseShippingLabelDetails, parseSpecificOrder } from '../apiParser';
import { changePage, isEmptyArray } from 'utils';
import { reviewOrder, withdrawOrder } from '../orders/OrdersActions';
import { ROUTES } from 'constant';
import { getStripePaymentConfirmation } from '../payouts/PayoutsActions';
import { AnalyticsEvents } from 'services/AnalyticsEvents';
import { buyShippingLabel, getLabelInformation } from '../shipping/ShippingActions';
import { CurrencyCode } from '../../constant/currencies';
import { getOrderTotalPrice, getSubTotalItemsPrice } from './helper';

export const DEFAULT_ORDER_DETAILS: IOrderDetails = {
  id: '',
  orderNumber: '',
  title: '',
  status: OrderStatuses.Requested,
  shippingPrice: null,
  shippingAddressName: '',
  creationDate: 0,
  shippingProviderImage: null,
  lastActivityDate: 0,
  paidPaymentMethod: null,
  isInstantCheckout: false,
  expiryDate: null,
  packingSlipUrl: null,
  shippingLabelUrl: null,
  isSeller: false,
  partnerDetails: {
    id: '',
    avatar: '',
    username: '',
    email: '',
    fullName: '',
    coverPhoto: '',
    isAnonymous: false,
  },
  trackingNumber: null,
  hasDigitalFiles: false,
  shopName: '',
  subTotalPrice: 0,
  totalPrice: 0,
  totalDiscount: 0,
  currencyCode: CurrencyCode.USD,
  items: [],
  shopId: '',
  isShopReviewed: false,
  linkedPaymentOptions: [],
  originalPrice: { value: 0, currencyCode: CurrencyCode.USD, currencySymbol: '$' },
  payoutOptions: [],
  originAddress: null,
  shippingLabelDetails: null,
  selectedShippingService: null,
  recipientAddress: null,
  dimensions: null,
  isAnonymous: false,
  discountDetails: null,
};

const initialState: ISpecificOrderState = {
  id: null,
  orderDetails: null,
  activities: [],
  initialPopupOption: null,
  trackingNumberMessage: null,
  carrierOptions: null,
  trackingInformation: {
    activities: [],
    shippingProviderImage: null,
  },
  digitalFiles: null,
  email: null,
};

export const SpecificOrderSlicer = createSlice({
  name: 'specificOrder',
  initialState,
  reducers: {
    reset: () => initialState,
    updatePaidPaymentMethod: (state, action) => {
      const { username, avatar, hasDigitalFiles } = action.payload;

      if (hasDigitalFiles) {
        state.initialPopupOption = OrderInitialPopupOptions.DigitalFiles;
      }

      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.PaymentReceived;
        state.orderDetails.paidPaymentMethod = PayoutMethods.Paypal;

        const newActivity = {
          id: '',
          status: OrderStatuses.PaymentReceived,
          date: Date.now(),
          username,
          avatar,
          isUserActivity: true,
          isCollapsible: false,
        };

        state.activities.unshift(newActivity);
      }
    },
    resetCarrierOptions: (state) => {
      state.carrierOptions = null;
      state.trackingNumberMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpecificOrder.fulfilled, (state, action) => {
      const { userAvatar } = action.meta.arg;

      const { activities, orderDetails } = parseSpecificOrder(action.payload, userAvatar);

      state.activities = activities.filter((activity) => activity.status !== OrderStatuses.Decline);
      state.orderDetails = orderDetails;
      state.id = orderDetails.id;
    });
    builder.addCase(checkTrackingNumber.fulfilled, (state, action) => {
      const couriers = action.payload;

      state.carrierOptions = couriers;
      if (isEmptyArray(couriers)) {
        state.trackingNumberMessage = 'Tracking number is invalid';
      } else {
        state.trackingNumberMessage = null;
      }
    });
    builder.addCase(getTrackingInformation.fulfilled, (state, action) => {
      const { activities, companyImage } = action.payload;

      state.trackingInformation = {
        activities,
        shippingProviderImage: companyImage || null,
      };
    });
    builder.addCase(requestOrder.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.id = id;
      state.initialPopupOption = OrderInitialPopupOptions.WaitForSeller;
    });
    builder.addCase(withdrawOrder.fulfilled, (state) => {
      if (state.orderDetails) {
        changePage(`/${ROUTES.ORDERS}`);
      }
    });
    builder.addCase(addShippingCost.fulfilled, (state, action) => {
      const { items, shippingPrice, avatar, username } = action.meta.arg;

      const { orderDetails } = state;

      if (orderDetails) {
        orderDetails.status = OrderStatuses.Offered;
        orderDetails.items = items;
        orderDetails.shippingPrice = shippingPrice;

        orderDetails.subTotalPrice = getSubTotalItemsPrice(orderDetails);
        orderDetails.totalPrice = getOrderTotalPrice(orderDetails, shippingPrice);

        const newActivity = {
          id: '',
          status: OrderStatuses.Offered,
          date: Date.now(),
          username,
          avatar,
          isUserActivity: true,
          isCollapsible: true,
        };

        state.activities.unshift(newActivity);
      }
    });
    builder.addCase(declineOrder.fulfilled, (state) => {
      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.Decline;
      }
    });
    builder.addCase(markPaymentAsReceived.fulfilled, (state, action) => {
      const { username, avatar } = action.meta.arg;

      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.PaymentReceived;

        const newActivity = {
          id: '',
          status: OrderStatuses.PaymentReceived,
          date: Date.now(),
          username,
          avatar,
          isUserActivity: true,
          isCollapsible: false,
        };
        state.activities.unshift(newActivity);
      }
    });
    builder.addCase(acceptOrder.fulfilled, (state) => {
      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.PaymentReceived;

        const newActivity = {
          id: '',
          status: OrderStatuses.PaymentReceived,
          date: Date.now(),
          username: state.orderDetails.partnerDetails.username,
          avatar: state.orderDetails.partnerDetails.avatar,
          isUserActivity: false,
          isCollapsible: false,
        };
        state.activities.unshift(newActivity);
      }
    });
    builder.addCase(markPaymentAsSent.fulfilled, (state, action) => {
      const { username, avatar, id } = action.meta.arg;

      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.PaymentSent;

        const newActivity = {
          id: '',
          status: OrderStatuses.PaymentSent,
          date: Date.now(),
          username,
          avatar,
          isUserActivity: true,
          isCollapsible: false,
        };
        state.activities.unshift(newActivity);
        changePage(`/${ROUTES.ORDER}/${id}`);
      }
    });
    builder.addCase(saveTrackingNumber.fulfilled, (state, action) => {
      const { trackingNumber, username, avatar } = action.meta.arg;

      if (state.orderDetails && state.id) {
        state.orderDetails.trackingNumber = trackingNumber;

        if (state.orderDetails.status !== OrderStatuses.Shipped) {
          state.orderDetails.status = OrderStatuses.Shipped;
          AnalyticsEvents.onOrderStatusChange(OrderStatuses.Shipped, state.id);

          const newActivity = {
            id: '',
            status: OrderStatuses.Shipped,
            date: Date.now(),
            username,
            avatar,
            isUserActivity: true,
            isCollapsible: false,
            fullName: '',
          };
          state.activities.unshift(newActivity);
        }
      }
    });
    builder.addCase(buyShippingLabel.fulfilled, (state, action) => {
      const { username, avatar } = action.meta.arg;

      const { shippingLabelUrl, packageSlipUrl } = action.payload;

      if (state.orderDetails && state.id) {
        state.orderDetails.shippingLabelUrl = shippingLabelUrl;
        state.orderDetails.packingSlipUrl = packageSlipUrl;

        state.orderDetails.trackingNumber = null;

        if (state.orderDetails.status !== OrderStatuses.Shipped) {
          state.orderDetails.status = OrderStatuses.Shipped;
          AnalyticsEvents.onOrderStatusChange(OrderStatuses.Shipped, state.id);

          const newActivity = {
            id: '',
            status: OrderStatuses.Shipped,
            date: Date.now(),
            username,
            fullName: '',
            avatar,
            isUserActivity: true,
            isCollapsible: false,
          };

          state.activities.unshift(newActivity);
        }
      }
    });
    builder.addCase(markOrderAsArrived.fulfilled, (state) => {
      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.Complete;
      }
    });
    builder.addCase(reviewOrder.fulfilled, (state) => {
      if (state.orderDetails) {
        state.orderDetails.isShopReviewed = true;
      }
    });
    builder.addCase(getStripePaymentConfirmation.fulfilled, (state, action) => {
      const { username, avatar, hasDigitalFiles } = action.meta.arg;

      if (hasDigitalFiles) {
        state.initialPopupOption = OrderInitialPopupOptions.DigitalFiles;
      }

      if (state.orderDetails) {
        state.orderDetails.status = OrderStatuses.PaymentReceived;
        state.orderDetails.paidPaymentMethod = PayoutMethods.Stripe;

        const newActivity = {
          id: '',
          status: OrderStatuses.PaymentReceived,
          date: Date.now(),
          username,
          avatar,
          fullName: '',
          isUserActivity: true,
          isCollapsible: false,
        };

        state.activities.unshift(newActivity);
      }
    });
    builder.addCase(getLabelInformation.fulfilled, (state, action) => {
      if (state.orderDetails) {
        state.orderDetails.shippingLabelDetails = parseShippingLabelDetails(action.payload);
      }
    });
    builder.addCase(getOrderDigitalFiles.fulfilled, (state, action) => {
      const { files, email } = action.payload;
      state.digitalFiles = files;
      state.email = email || null;
    });
  },
});
