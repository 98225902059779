import { Notification } from 'tedooo-web-design-system/ui';
import { FilledErrorCircle } from 'tedooo-web-design-system/icons';
import './visionsPage.scss';

import { translations } from 'translations/en';

const GenerateVisionErrorMessage = () => {
  return (
    <Notification
      icon={<FilledErrorCircle />}
      duration={12}
      type="error"
      text={translations.VISIONS.ERROR_MESSAGE}
      placement="top"
      className="generate-vision-error-message"
    />
  );
};

export default GenerateVisionErrorMessage;
