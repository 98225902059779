import './nonSignInSidebar.scss';
import { FC } from 'react';
import { Button } from 'tedooo-web-design-system/ui';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GoogleSignInButton from '../../signPage/googleSignIn/GoogleSignInButton';
import FacebookSignInButton from '../../signPage/facebookSignInButton/FacebookSignInButton';
import { UserSlicer } from 'store/user/UserActions';
import { SignPageOptions } from 'types';
import { ROUTES } from 'constant';
import { translations } from 'translations/en';

const { TITLE, DESCRIPTION, CREATE_ACCOUNT_BUTTON } = translations.FEED.LOGGED_OUT_SIDE_BAR;

const NonSignInSidebar: FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSignUpClick = () => {
    dispatch(UserSlicer.actions.changeSignPhase(SignPageOptions.SignUp));
    navigate(`/${ROUTES.SIGN}`);
  };

  return (
    <div className="non-sign-in-sidebar-container">
      <div className="text-container">
        <span className="title">{TITLE}</span>
        <span className="description">{DESCRIPTION}</span>
      </div>
      <div className="login-buttons-container">
        <FacebookSignInButton />
        <GoogleSignInButton />
        <Button
          className="create-account-button"
          type="secondary"
          size="large"
          borderRadius="large"
          text={CREATE_ACCOUNT_BUTTON}
          onClick={onSignUpClick}
          isFullWidth
        />
      </div>
    </div>
  );
};

export default NonSignInSidebar;
