import { createSlice } from '@reduxjs/toolkit';

import { IImageContainerTypes, IListingState } from 'types';
import { addShippingProfile } from '../shipping/ShippingActions';
import { getShopInformation } from '../createShop/CreateShopActions';
import { DEFAULT_LISTING, resetForm, updateListingSlicer } from './helper';
import { updateListing } from '../../pages/createShop/stepForms/listingForm/helper';

const initialState: IListingState = DEFAULT_LISTING;

export const ListingSlicer = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setListingToEditId: (state, action) => {
      const { id } = action.payload;
      state.listingIdToEdit = id;
    },
    setListingToDuplicateId: (state, action) => {
      const { id } = action.payload;
      state.listingIdToDuplicate = id;
    },
    resetForm: (_, action) => resetForm(action.payload),
    editListing: (_, action) => updateListing(action.payload.item, false),
    duplicateListing: (_, action) => updateListing(action.payload.item, true),
    addImage: (state, action) => {
      const { newImages } = action.payload;

      const firstEmptyIndex = state.images.findIndex((image) => !image.listingImage);

      const listToUpdate = [...state.images];

      if (firstEmptyIndex !== -1) {
        listToUpdate.map((container, i) => {
          if (i >= firstEmptyIndex) {
            container.listingImage = newImages.shift();
          }

          return container;
        });
      }
      if (newImages.length > 0) {
        newImages.forEach((listingImage: string) => {
          listToUpdate.push({ type: IImageContainerTypes.Extra, listingImage });
        });
      }
      state.images = listToUpdate;
    },
    removeImage: (state, action) => {
      const { index } = action.payload;

      if (state.images[index].type !== IImageContainerTypes.Extra) {
        const item = state.images[index];
        item.listingImage = null;

        const updatedImages = state.images.filter((_, currentIndex) => currentIndex !== index);
        updatedImages.push(item);
        state.images = updatedImages;
      } else {
        state.images = state.images.filter((_, i) => i !== index);
      }
    },
    updateTitle: (state, action) => {
      const { title } = action.payload;
      state.title = title;
    },
    updateDescription: (state, action) => {
      const { description } = action.payload;
      state.description = description;
    },
    updateIsDigital: (state, action) => {
      const { isDigital } = action.payload;
      state.isDigital = isDigital;
    },
    addFiles: (state, action) => {
      const { files } = action.payload;

      const previousFiles = state.digitalFiles || [];
      state.digitalFiles = [...previousFiles, ...files];
    },
    removeFile: (state, action) => {
      const { key } = action.payload;
      state.digitalFiles = state.digitalFiles.filter((file) => file.key !== key);
    },
    updateProductCategory: (state, action) => {
      const { category } = action.payload;
      state.category = category;
    },
    submitKeywords: (state, action) => {
      const { keywords } = action.payload;
      state.keywords = keywords;
    },
    updatePrice: (state, action) => {
      const { price } = action.payload;
      state.price = price;
    },
    updateSaleAmount: (state, action) => {
      const { value } = action.payload;
      state.saleAmount = value;
    },
    updatePriceAfterDiscount: (state, action) => {
      const { price } = action.payload;
      state.priceAfterDiscount = price;
    },
    updateQuantity: (state, action) => {
      const { quantity } = action.payload;
      state.quantity = quantity;
    },
    updateDiscountType: (state, action) => {
      const { type } = action.payload;
      state.discountType = type;
    },
    updateIsMetric: (state, action) => {
      const { isMetric } = action.payload;
      state.dimensions.isMetric = isMetric;
    },
    updateLength: (state, action) => {
      const { length } = action.payload;
      state.dimensions.length = length;
    },
    updateWidth: (state, action) => {
      const { width } = action.payload;
      state.dimensions.width = width;
    },
    updateHeight: (state, action) => {
      const { height } = action.payload;
      state.dimensions.height = height;
    },
    updateWeightBig: (state, action) => {
      const { weight } = action.payload;
      state.dimensions.weightBig = weight;
    },
    updateWeightSmall: (state, action) => {
      const { weight } = action.payload;
      state.dimensions.weightSmall = weight;
    },
    updateShippingProfile: (state, action) => {
      const { id } = action.payload;
      state.shippingProfileId = id;
    },
    updateTariffNumber: (state, action) => {
      const { tariff } = action.payload;
      state.tariffNumber = tariff;
    },
    updateReturnPolicy: (state, action) => {
      const { returnPolicy } = action.payload;
      state.returnPolicy = returnPolicy;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addShippingProfile.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.shippingProfileId = id;
    });

    builder.addCase(getShopInformation.fulfilled, (state, action) => {
      const id = state.listingIdToDuplicate || state.listingIdToEdit;

      const isDuplicate = !!state.listingIdToDuplicate;

      return updateListingSlicer(id, isDuplicate, action.payload);
    });
  },
});
