import { FC } from 'react';
import { connect } from 'react-redux';

import { DrawerIdsOptions, IStore2 } from 'types';
import { DrawerSlicer } from 'store/drawers/DrawerActions';
import MenuDrawer from 'components/menu/profile/menuDrawer/MenuDrawer';
import MobileMoreButtonDrawer from 'pages/shopPage/header/mobileMoreButtonPopup/MobileMoreButtonDrawer';

export interface IDrawers {
  id: DrawerIdsOptions | null;
}

const Drawers: FC<IDrawers> = ({ id }) => {
  if (id === null) {
    return null;
  }

  return (
    <>
      {id === DrawerIdsOptions.Menu && <MenuDrawer />}
      {id === DrawerIdsOptions.ShopMoreDrawer && <MobileMoreButtonDrawer />}
    </>
  );
};

const mapStateToProps = (state: IStore2) => {
  const { id } = state.drawer;

  return { id };
};

const mapDispatchToProps = {
  hide: DrawerSlicer.actions.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawers);
