import {
  CreateShopSteps,
  ICreateShopCatalogItem,
  ICreateShopProduct,
  ICreateShopProductFromCsv,
  ICreateShopRequest,
  ICreateShopState,
  IImageContainer,
  IImageContainerTypes,
  ILegacyPayoutMethod,
  IProductDimensions,
  IProductDimensionsResponse,
  PaypalErrorResponse,
  ReturnPolicyType,
} from 'types';
import { CurrencyCode, mapCurrencyToSymbol } from 'constant/currencies';
import {
  getDescriptionError,
  getNameError,
  isEmptyArray,
  validateEmail,
  validatePhoneNumber,
} from 'utils';
import { LegacyPaymentMethods } from 'constant/paymentMethods';
import { DecodeProductCsvResponseProduct } from '../../graphql/generated/graphql';

const DEFAULT_QUANTITY = 300;

export const imageContainers: IImageContainer[] = [
  { type: IImageContainerTypes.Main, listingImage: null },
  { type: IImageContainerTypes.Side, listingImage: null },
  { type: IImageContainerTypes.Top, listingImage: null },
  { type: IImageContainerTypes.Upside, listingImage: null },
];

export const parseProduct = (item: ICreateShopCatalogItem): ICreateShopProduct => {
  const filteredImages = item.images.filter((imageContainer) => imageContainer.listingImage);

  const tariff = item.tariffNumber ? item.tariffNumber : undefined;

  const dimensions = getDimensions(item.dimensions);

  const quantity = item.quantity !== null ? item.quantity : DEFAULT_QUANTITY;

  const digitalFiles = isEmptyArray(item.digitalFiles)
    ? undefined
    : item.digitalFiles.map((file) => file.key);

  const shippingInformation = item.isDigital
    ? undefined
    : {
        profileId: item.shippingProfileId || '',
        returnPolicy: item.returnPolicy || ReturnPolicyType.NoReturn,
        dimensions,
      };

  return {
    images: filteredImages.map((image) => image.listingImage || ''),
    title: item.title,
    description: item.description,
    selectedCategory: item.category,
    isDigital: item.isDigital,
    keywords: item.keywords,
    digitalFiles,
    quantity,
    price: {
      price: Math.round(item.priceAfterDiscount * 100),
      currency: mapCurrencyToSymbol[item.currency],
      currencyCode: item.currency,
    },
    originalPrice: Math.round(item.price * 100),
    tariff,
    shippingInformation,
  };
};

export const parseLegacyPaymentMethods = (legacyPayoutMethods: ILegacyPayoutMethod[]) => {
  return legacyPayoutMethods
    .map((method) => ({
      provider: method.type,
      link: method.link,
    }))
    .filter((method) => method.link !== '');
};

export const parseToCreateShopRequest = (
  shopName: string,
  shopDescription: string,
  location: string,
  coverPhoto: string,
  phoneNumber: string,
  email: string,
  shopId: string | null,
  currencyCode: CurrencyCode,
  legacyPayoutMethods: ILegacyPayoutMethod[],
): ICreateShopRequest => {
  const parsedPayoutMethods = parseLegacyPaymentMethods(legacyPayoutMethods);

  return {
    shop: {
      id: shopId,
      name: shopName,
      description: shopDescription,
      foundedCountry: location,
      coverPhoto,
      email,
      phoneNumber,
      paymentProviders: parsedPayoutMethods,
      companyType: 'Crafter',
      currencyCode,
    },
  };
};

export const PAYMENT_METHODS_ORDER = [
  LegacyPaymentMethods.Paypal,
  LegacyPaymentMethods.Square,
  LegacyPaymentMethods.CashApp,
  LegacyPaymentMethods.AmazonPay,
  LegacyPaymentMethods.Venmo,
  LegacyPaymentMethods.BankTransfer,
  LegacyPaymentMethods.Wise,
  LegacyPaymentMethods.Sofort,
  LegacyPaymentMethods.Revolut,
  LegacyPaymentMethods.Bpay,
  LegacyPaymentMethods.Poli,
  LegacyPaymentMethods.Interac,
  LegacyPaymentMethods.Sepa,
  LegacyPaymentMethods.FasterPayments,
  LegacyPaymentMethods.Adyen,
  LegacyPaymentMethods.Skrill,
  LegacyPaymentMethods.Netteler,
  LegacyPaymentMethods.Zelle,
  LegacyPaymentMethods.PayDirekt,
  LegacyPaymentMethods.BanContact,
];

export const mapPaypalErrorCodeToAmplitudeMessage = {
  [PaypalErrorResponse.PaymentNotReceivable]: 'cannot receive payments',
  [PaypalErrorResponse.EmailNotVerified]: 'verify email',
};

export const getDimensions = (dimensions: IProductDimensions): IProductDimensionsResponse => {
  const { height, length, weightBig, width, isMetric, weightSmall } = dimensions;

  return {
    width: Math.floor(Number(width.toFixed(2)) * 100),
    height: Math.floor(Number(height.toFixed(2)) * 100),
    length: Math.floor(Number(length.toFixed(2)) * 100),
    weightBig: Math.floor(Number(weightBig.toFixed(2)) * 100),
    weightSmall: Math.floor(Number(weightSmall.toFixed(2)) * 100),
    isMetric,
  };
};

export const getDraftInitialStep = (state: ICreateShopState) => {
  const isShopNameValid = !!state.shopName && !getNameError(state.shopName);

  const isShopDescriptionValid =
    !!state.shopDescription && !getDescriptionError(state.shopDescription);

  const isPhoneNumberValid = !!state.phoneNumber && validatePhoneNumber(state.phoneNumber);

  const isEmailValid = !!state.email && validateEmail(state.email);

  if (!isShopNameValid || !isShopDescriptionValid) {
    return CreateShopSteps.Initial;
  }
  if (!isPhoneNumberValid || !isEmailValid) {
    return CreateShopSteps.ContactDetails;
  }
  if (state.isPaypalEnabled || state.isStripeEnabled) {
    return CreateShopSteps.Payouts;
  }

  return CreateShopSteps.LegacyPayments;
};

export const getTotalCompletedDraftSteps = (state: ICreateShopState) => {
  let counter = 0;

  const isShopNameValid = !!state.shopName && !getNameError(state.shopName);

  const isShopDescriptionValid =
    !!state.shopDescription && !getDescriptionError(state.shopDescription);

  const isPhoneNumberValid = !!state.phoneNumber && validatePhoneNumber(state.phoneNumber);

  const isEmailValid = !!state.email && validateEmail(state.email);

  const hasConnectedPaymentMethods =
    !isEmptyArray(state.payoutMethods) || !isEmptyArray(state.legacyPayoutMethods);

  if (isShopNameValid && isShopDescriptionValid) {
    counter++;
  }
  if (state.currencyCode) {
    counter++;
  }
  if (isEmailValid && isPhoneNumberValid) {
    counter++;
  }
  if (hasConnectedPaymentMethods) {
    counter++;
  }

  return counter;
};

export const updateDraft = (draft: ICreateShopState): ICreateShopState => {
  const currentStep = getDraftInitialStep(draft);

  const shouldDisplayInFeed = draft.draftDetails ? !!draft.draftDetails?.shouldDisplayInFeed : true;

  return {
    ...draft,
    step: currentStep,
    draftDetails: {
      totalStepsCompleted: getTotalCompletedDraftSteps(draft),
      currentStep,
      shouldDisplayInFeed,
    },
  };
};

export const csvProductToCreateShopProduct = (
  csvProducts: DecodeProductCsvResponseProduct,
): ICreateShopProductFromCsv | null => {
  if (
    !csvProducts.title ||
    !csvProducts.description ||
    !csvProducts.price ||
    csvProducts.images.length === 0
  ) {
    return null;
  }

  return {
    images: csvProducts.images,
    title: csvProducts.title,
    description: csvProducts.description,
    quantity: csvProducts.quantity != null ? csvProducts.quantity : undefined,
    keywords: csvProducts.tags || [],
    price: csvProducts.price,
  };
};

export const filterItemsByUploadedImages = (
  productFromCsv: ICreateShopProductFromCsv[],
  successImages: string[],
): ICreateShopProductFromCsv[] => {
  const items = productFromCsv.map((item) => {
    const filteredImages = item.images.filter((image) => image && successImages.includes(image));

    return {
      ...item,
      images: filteredImages,
    };
  });

  return items.filter((item) => item.images.length > 0);
};

export const listContainsItemWithSameTitleAndDescription = (
  list: ICreateShopProductFromCsv[],
  item: ICreateShopProductFromCsv,
) => {
  return list.some((i) => i.title === item.title && i.description === item.description);
};

export const getCreateShopCatalogItemFromCsv = (
  products: DecodeProductCsvResponseProduct[],
  curItems: ICreateShopProductFromCsv[],
): ICreateShopProductFromCsv[] => {
  const updatedProducts = products
    .map(csvProductToCreateShopProduct)
    .filter((item) => !!item && !listContainsItemWithSameTitleAndDescription(curItems, item));

  return updatedProducts as ICreateShopProductFromCsv[];
};
